import PropTypes from 'prop-types';
import { forwardRef, useCallback, useMemo } from 'react';
import useStateUrl from '../../../../helpers/useStateUrl';

export const withStateUrl = (Component) => {
  const StateUrlWrapper = forwardRef(
    ({ initialValue = '', onChange, param = '', ...props }, ref) => {
      const { multi } = props;
      const {
        value: valueUrl,
        updateValue,
        reset: resetValue,
      } = useStateUrl({
        initialValue,
        key: param,
      });

      const handleChange = useCallback(
        (value) => {
          onChange(value);

          if (multi) {
            const ids = (
              Array.isArray(value)
                ? value.map(({ value }) => value)
                : [value?.value]
            ).filter((id) => id != null);

            if (ids?.length) {
              updateValue(ids.join(','));
            } else {
              resetValue();
            }
            return;
          }
          if (value?.value) {
            updateValue(value.value);
          } else {
            resetValue();
          }
        },
        [updateValue, resetValue, onChange, multi]
      );

      const value = useMemo(() => {
        if (multi) {
          return valueUrl ? valueUrl.split(',') : [];
        }
        return valueUrl;
      }, [valueUrl, multi]);

      return (
        <Component ref={ref} {...props} value={value} onChange={handleChange} />
      );
    }
  );

  StateUrlWrapper.propTypes = {
    initialValue: PropTypes.string,
    param: PropTypes.string,
    onChange: PropTypes.func,
  };

  StateUrlWrapper.defaultProps = {
    onChange: () => null,
  };

  return StateUrlWrapper;
};

export default withStateUrl;
