import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo } from 'react';
import useUser from '../../../../helpers/useUser';
import { READ_VIRTUAL_HUMANS } from './virtualHumanList.query';
import LoadingPreview from '../Loading/LoadingPreview';
import PreviewItem from '../PreviewItem/PreviewItem';
import { VirtualHumansList, StyledNoResults } from './VirtualHumanList.styled';
import _ from 'lodash';
import useFlashMessage from '../../../FlashMessage';
import { CREATE_UNIT } from '../../courseForm.query';
import WithOnboardingHandler from '../../../../../../../HOCs/WithOnboardingHandler';
import wrapUnitType from '../../helpers/wrapUnitType';
import tracker from '../../../../helpers/tracker';

export const VirtualHumanList = ({
  updateProgress,
  filter: titleFilter,
  groupId,
  setLoading,
}) => {
  const { addFlashMessage } = useFlashMessage();

  const { OrganisationID } = useUser();

  const variables = useMemo(() => {
    const filter = {
      TitleOrAdminTitle: { contains: titleFilter || '' },
      Organisation: { ID: { eq: OrganisationID } },
    };

    if (groupId) {
      filter.OrganisationGroups = { ID: { eq: groupId } };
      return { filter };
    }

    return { filter };
  }, [OrganisationID, groupId, titleFilter]);

  const { data, loading } = useQuery(READ_VIRTUAL_HUMANS, {
    variables,
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  const virtualhumans = useMemo(
    () =>
      loading
        ? [...Array(3)]
        : data.readVirtualHumans.edges.map(({ node }) => node),
    [data, loading]
  );

  const [createNewUnit] = useMutation(CREATE_UNIT);
  const addVH = useCallback(
    async (vh, module) => {
      const unit = module.Units.edges.find(
        ({ node }) => node.VirtualHuman.ID === vh.ID
      );

      if (unit) {
        addFlashMessage(
          `Virtual Human '${vh.Title}' already exists in module '${module.Title}'.`,
          'error'
        );
        return;
      }

      try {
        const SortOrder =
          Math.max(
            ...module.Units.edges.map(({ node }) => node.SortOrder),
            module.Units.edges.length
          ) + 1;

        const variables = {
          Input: {
            VirtualHumanID: vh.ID,
            ModuleID: module.ID,
            SortOrder,
            Type: 'VirtualHuman',
          },
        };

        const ID = `new-unit-${(Math.random() * 100).toFixed(0)}`;
        const createUnit = {
          ID,
          SortOrder,
          Assessment: false,
          PassMark: 0,
          Simulation: null,
          VirtualHuman: vh,
          Type: 'VirtualHuman',
          __typename: 'Unit',
          Module: {
            ID: module.ID,
            __typename: 'Module',
            Units: {
              edges: [...module.Units.edges, wrapUnitType(ID)],
              __typename: 'UnitsConnection',
            },
          },
        };

        await createNewUnit({
          variables,
          optimisticResponse: {
            createUnit,
          },
        });
      } catch (e) {
        addFlashMessage(
          `Failed to add virtual human '${vh.Title}' to module '${module.Title}'.`,
          'error'
        );
        return;
      }
      updateProgress('add_course_unit');
      tracker.track('virtualhuman_added_to_module', {
        simulation_id: vh.ID,
        module_id: module.ID,
      });
      addFlashMessage(
        `Added virtual human '${vh.Title}' to module '${module.Title}'.`
      );
    },
    [createNewUnit, addFlashMessage, updateProgress]
  );

  return (
    <VirtualHumansList>
      {virtualhumans.map((virtualhuman, index) =>
        loading ? (
          <LoadingPreview key={index} />
        ) : (
          <PreviewItem
            key={virtualhuman.ID}
            addItem={addVH}
            type="VirtualHuman"
            loading={loading}
            data={virtualhuman}
          />
        )
      )}
      {!loading && !virtualhumans.length && (
        <StyledNoResults>No virtual humans found.</StyledNoResults>
      )}
    </VirtualHumansList>
  );
};

export default WithOnboardingHandler(VirtualHumanList);
