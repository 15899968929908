import React from 'react';
import PropTypes from 'prop-types';
// import Highcharts from 'highcharts';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { ParseRawHighchartDBData } from '../../utility/ParseUtils';
import {
  StyledDataBlock,
  StyledDataBlockHeader,
  StyledDataBlockHeaderText,
} from '../../styled-components/StyledDataBlocks';
import { GetFuncSortByNum } from '../../utility/Sort';

const BarChartTotalViewsOverTimeHighstock = (props) => {
  let scores = null;
  let scoreData = null;

  if (props.scores) {
    scores = ParseRawHighchartDBData(props.scores, true);
  }
  const views = ParseRawHighchartDBData(props.views, true);
  const viewsDays = ParseRawHighchartDBData(props.viewsDays).map((viewsDay) =>
    new Date(viewsDay).getTime()
  );
  if (props.scores) {
    scoreData = scores.reduce((carry, score, index) => {
      carry.push([viewsDays[index], score]);
      return carry;
    }, []);
    scoreData.sort(GetFuncSortByNum(0)); // 0 is the index
  }
  let viewsData = views.reduce((carry, viewCount, index) => {
    carry.push([viewsDays[index], viewCount]);
    return carry;
  }, []);
  // both need sorting by the date for highcharts
  viewsData.sort(GetFuncSortByNum(0));
  let series = [
    {
      name: props.sessions ? 'Completed Sessions' : 'Views',
      type: 'column',
      color: 'var(--primary-color)',
      data: viewsData,
      // tooltip: {
      //   headerFormat: '<b>{point.x}</b><br/>',
      //   pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
      // },
    },
  ];
  let yAxis = [
    {
      min: 0,
      title: {
        text: props.sessions ? 'Completed Sessions' : 'Total views',
      },
      opposite: false,
    },
  ];
  if (props.scores) {
    series.push({
      name: 'Score',
      type: 'line',
      yAxis: 1,
      data: scoreData,
      marker: {
        enabled: true,
        radius: 4,
      },
      tooltip: {
        valueSuffix: '%',
      },
    });
    yAxis.push({
      min: 0,
      max: 100,
      type: 'line',
      title: {
        text: 'Average score',
      },
      opposite: true,
    });
  }
  return (
    <StyledDataBlock>
      <StyledDataBlockHeader>
        {props.title && (
          <StyledDataBlockHeaderText>{props.title}</StyledDataBlockHeaderText>
        )}
        {!props.title && (
          <StyledDataBlockHeaderText>
            {props.sessions ? 'Completed Sessions' : 'Simulation Views'}
            {props.scores ? ' and Scores' : ''}
          </StyledDataBlockHeaderText>
        )}
      </StyledDataBlockHeader>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={'stockChart'}
        options={{
          // chart: {
          //   type: 'column'
          // },
          // default to 1month
          rangeSelector: {
            enabled: false,
          },
          navigator: {
            enabled: false,
          },
          xAxis: {
            type: 'datetime',
            // dateTimeLabelFormats: { // don't display the dummy year
            //   month: '%e. %b',
            //   year: '%b'
            // },
            title: {
              text: 'Date',
            },
          },
          // xAxis: {
          //   categories: ParseRawHighchartDBData(props.viewsDays).map(viewsDay => [new Date(viewsDay)])
          // },
          yAxis,
          legend: {
            align: 'right',
            x: -30,
            verticalAlign: 'top',
            y: 25,
            floating: true,
            backgroundColor:
              (Highcharts.theme && Highcharts.theme.background2) || 'white',
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: false,
          },
          plotOptions: {
            column: {
              stacking: 'normal',
              dataLabels: {
                enabled: false,
                color:
                  (Highcharts.theme && Highcharts.theme.dataLabelsColor) ||
                  'white',
              },
            },
          },
          credits: {
            enabled: false,
          },
          series,
        }}
      />
    </StyledDataBlock>
  );
};

BarChartTotalViewsOverTimeHighstock.propTypes = {
  viewsDays: PropTypes.string.isRequired,
  views: PropTypes.string.isRequired,
  scores: PropTypes.string,
  title: PropTypes.string,
};

export default BarChartTotalViewsOverTimeHighstock;
