import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

// can deal with provided score of both e.g. 50% and 50
const VirtiCircularProgressbar = (props) => {
  // null, undefined become 0
  const raw = Number(`${props.percentage || ''}`.split('%')[0]);
  // Infinity, NaN become 0
  const numberedPerc = isFinite(raw) ? raw : 0;
  let color = 'var(--negative-color)';
  if (numberedPerc >= 60) color = 'var(--primary-color)';
  else if (numberedPerc >= 40) color = 'var(--warning-color)';
  else if (props.percentage === null || props.percentage === undefined)
    color = 'var(--neutral-color)';
  if (props.uploadBar) color = 'var(--primary-color)';

  // Display passed prop if valid
  const displayValue = () => {
    if (
      props.percentage === null ||
      props.percentage === undefined ||
      props.percentage === ''
    )
      return '-';
    if (!isFinite(raw)) return '0%';
    if (props.percentage === 100) return '100%';
    return `${Math.round(numberedPerc * 10) / 10}${
      props.percentage && (props.percentage + '').includes('%') ? '' : '%'
    }`;
  };

  return (
    <CircularProgressbar
      value={numberedPerc}
      text={displayValue()}
      strokeWidth={7}
      styles={buildStyles({
        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
        strokeLinecap: 'butt',
        textSize: '1.5rem',
        // Colors
        pathColor: color,
        textColor: 'initial',
        trailColor: '#d6d6d6',
      })}
    />
  );
};

VirtiCircularProgressbar.defaultProps = {
  uploadBar: false,
};

VirtiCircularProgressbar.propTypes = {
  percentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  uploadBar: PropTypes.bool,
};

export default VirtiCircularProgressbar;
