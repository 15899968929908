import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LoadingPageIndicator from '../../../../../../../components/LoadingPageIndicator';
import PageLoadError from '../../../../../../../components/PageLoadError';
import RedirectWithPrevState from '../../../../../../../components/RedirectWithPrevState';
import StyledPage, {
  StyledPageContentInner,
} from '../../../../../../../components/StyledPage';
import {
  StyledDataBlock,
  BlockWrapper,
  StyledDataBlockBody,
  StyledDataBlockHeader,
  StyledDataBlockHeaderText,
  StyledDataBlocks,
  StyledDataItem,
  StyledDataLabel,
} from '../../../../../../../styled-components/StyledDataBlocks';
import StyledLink from '../../../../../../../styled-components/StyledLink';
import BarChartTotalViewsOverTimeHighstock from '../../../../../../../components/charts/BarChartTotalViewsOverTimeHighstock';
import ScorePercentage from '../../../../../../../styled-components/StyledScorePercentage';
import withUser from '../../../../../../../HOCs/WithUser';
import { READ_VIRTUAL_HUMAN } from '../../../../../../../queries/VirtualHumanQueries';
import {
  VIRTUAL_HUMAN_USER_ANALYTICS,
  READ_MEMBER,
} from './VirtualHumanUserAnalyticsPage.query';
import { READ_VIRTUAL_HUMAN_SESSIONS } from '../VirtualHumanAnalytics.query';
import { usePaginationUrl } from '../../../../components/PaginationControl/helpers/usePaginationUrl';
import useStateUrl from '../../../../helpers/useStateUrl';
import VirtualHumanAnalyticsSubmissionsTable from '../../../../components/Analytics/VirtualHuman/VirtualHumanAnalyticsSubmissionsTable/VirtualHumanAnalyticsSubmissionsTable';
import PaginationControl from '../../../../components/PaginationControl';
import * as Styled from '../VirtualHumanAnalytics.styled';
import Select from '../../../../components/form/Select';
import { useCurrentOrganisation } from '../../../../helpers/useCurrentOrganisation';

const StyledUserTitleWrapper = styled.div`
  display: flex;
  margin-bottom: 2rem;
  > div {
    font-size: 2rem;
    font-family: var(--header-font);
    margin-top: 2rem;
  }
`;

const finishedOptions = [
  {
    value: undefined,
    label: 'All Sessions',
  },
  {
    value: true,
    label: 'Complete Sessions',
  },
  {
    value: false,
    label: 'Incomplete Sessions',
  },
];

const VirtualHumanUserAnalyticsPage = (props) => {
  const { loading, error, data } = useQuery(READ_VIRTUAL_HUMAN, {
    variables: {
      ID: props.match.params.ssVirtualHumanID,
      organisationID: props.userOrganisationID,
    },
  });
  const {
    loading: memberLoading,
    error: memberError,
    data: memberData,
  } = useQuery(READ_MEMBER, {
    variables: {
      ID: props.match.params.userID,
    },
  });

  useCurrentOrganisation(data?.readOneVirtualHuman?.OrganisationID);

  const { page, pageSize, changePage, controlProps, resetPage } =
    usePaginationUrl({
      pageSize: 15,
    });

  const { value: finishedGET, updateValue: setFinishedGET } = useStateUrl({
    key: 'finished',
    initialValue: '',
  });
  const initialFinishedGETValue =
    finishedGET === 'false' ? finishedOptions[2] : finishedOptions[1];
  const [currentFinishedValue, setCurrentFinishedValue] = useState(
    ['true', 'false'].includes(finishedGET)
      ? initialFinishedGETValue
      : finishedOptions[0]
  );
  const handleFinishedChange = useCallback(
    (newVal) => {
      resetPage();
      setCurrentFinishedValue(newVal);
      setFinishedGET(newVal.value);
    },
    [setFinishedGET, resetPage]
  );
  const variables = useMemo(() => {
    let Finished = currentFinishedValue?.value;
    if (finishedGET === 'false') {
      Finished = false;
    } else if (finishedGET === 'true') {
      Finished = true;
    }
    return {
      ID: props.match.params.ssVirtualHumanID,
      limit: pageSize,
      offset: (page - 1) * pageSize,
      ...(typeof Finished !== 'undefined'
        ? {
            Finished: {
              eq: Finished,
            },
          }
        : {}),
      filterBy: {
        Member: {
          ID: {
            eq: +props.match.params.userID,
          },
        },
      },
    };
  }, [
    page,
    pageSize,
    finishedGET,
    props.match.params.ssVirtualHumanID,
    props.match.params.userID,
    currentFinishedValue?.value,
  ]);

  const {
    loading: analyticsLoading,
    error: analyticsError,
    data: analyticsQueryData,
  } = useQuery(VIRTUAL_HUMAN_USER_ANALYTICS, {
    variables: {
      VirtualHumanID: props.match.params.ssVirtualHumanID,
      UserID: props.match.params.userID,
    },
  });
  const {
    loading: sessionsLoading,
    error: sessionsError,
    data: sessionsQueryData,
  } = useQuery(READ_VIRTUAL_HUMAN_SESSIONS, {
    variables,
  });

  const sessionsData =
    sessionsLoading ||
    !sessionsQueryData ||
    !sessionsQueryData.readOneVirtualHuman ||
    sessionsQueryData.readOneVirtualHuman.length === 0
      ? null
      : sessionsQueryData.readOneVirtualHuman.AnalyticsSubmissions.edges.map(
          (edge) => edge.node
        );

  if (!props.userID) {
    return <RedirectWithPrevState to="/login" />;
  }
  if (loading || memberLoading || analyticsLoading)
    return <LoadingPageIndicator />;
  if (error || memberError || analyticsError)
    return <PageLoadError graphQLErrorObj={error} />;
  if (
    !data.readOneVirtualHuman ||
    !analyticsQueryData.userVirtualHumanAnalytics ||
    !memberData.readOneMember
  ) {
    return <RedirectWithPrevState to="/virtual-humans" />;
  }
  const vhData = data.readOneVirtualHuman;
  const vhType = vhData?.Type ?? 'default';
  const analyticsData = analyticsQueryData.userVirtualHumanAnalytics;
  const isFromVHArea = props.location.pathname.indexOf('/virtual-humans') === 0;
  const backLink = isFromVHArea
    ? `/virtual-humans/${vhData.ID}/sessions`
    : `/users/${memberData.readOneMember.ID}/analytics/virtual-humans`;
  const backText = `Back to ${
    isFromVHArea ? vhData.Title : memberData.readOneMember.Name
  }`;

  return (
    <StyledPage pageKey={'virtual-human-user-analytics'}>
      <StyledPageContentInner>
        <StyledLink to={backLink}>{backText}</StyledLink>
        <StyledUserTitleWrapper>
          <div>
            <StyledLink to={`/virtual-humans/${vhData.ID}/analytics`}>
              {vhData.Title}
            </StyledLink>{' '}
            -{' '}
            <StyledLink
              to={`/users/${memberData.readOneMember.ID}/analytics/virtual-humans`}
            >
              {memberData.readOneMember.Name}
            </StyledLink>
          </div>
        </StyledUserTitleWrapper>
        <BlockWrapper first={true}>
          <StyledDataBlocks>
            <StyledDataBlock>
              <StyledDataBlockHeader>
                <StyledDataBlockHeaderText>
                  Time Spent
                </StyledDataBlockHeaderText>
              </StyledDataBlockHeader>
              <StyledDataBlockBody>
                <div>
                  <StyledDataItem>
                    {analyticsData.TotalViews || 0}
                  </StyledDataItem>
                  <StyledDataLabel>
                    <FontAwesomeIcon icon="eye" /> Total Sessions
                  </StyledDataLabel>
                </div>
                <div>
                  <StyledDataItem>
                    {analyticsData.TotalTimeSpent || '-'}
                  </StyledDataItem>
                  <StyledDataLabel>
                    <FontAwesomeIcon icon="clock" /> Total Time Spent
                  </StyledDataLabel>
                </div>
              </StyledDataBlockBody>
            </StyledDataBlock>
            <StyledDataBlock>
              <StyledDataBlockHeader>
                <StyledDataBlockHeaderText>
                  Performance
                </StyledDataBlockHeaderText>
              </StyledDataBlockHeader>
              <StyledDataBlockBody>
                {vhType === 'medical' && (
                  <div>
                    <StyledDataItem>
                      <ScorePercentage
                        align="center"
                        score={analyticsData.AverageDiagnosisScore}
                      />
                    </StyledDataItem>
                    <StyledDataLabel>
                      <FontAwesomeIcon icon="star" /> Correct Diagnosis
                    </StyledDataLabel>
                  </div>
                )}
                <div>
                  <StyledDataItem>
                    {analyticsData.AverageTimeSpent || '-'}
                  </StyledDataItem>
                  <StyledDataLabel>
                    <FontAwesomeIcon icon="clock" /> Average Time Spent
                  </StyledDataLabel>
                </div>
              </StyledDataBlockBody>
            </StyledDataBlock>
          </StyledDataBlocks>
        </BlockWrapper>
        {/* <BlockWrapper>
          <TableKnowledgeGainAvg
            firstCorrect={analyticsData.AverageScoreFirst}
            latestCorrect={analyticsData.AverageScoreLast}
            firstCorrectLabel="First Correct %"
            latestCorrectLabel="Latest Correct %"
            showNAForScores={!vhData.Quiz}
          />
        </BlockWrapper> */}
        <BlockWrapper>
          <BarChartTotalViewsOverTimeHighstock
            scores={analyticsData.DailyTotalViewsScores}
            views={analyticsData.DailyTotalViewsViews}
            viewsDays={analyticsData.DailyTotalViewsDays}
            title="Sessions and Scores"
          />
        </BlockWrapper>
        <BlockWrapper>
          <StyledDataBlock>
            <StyledDataBlockHeader>
              <StyledDataBlockHeaderText>
                Recent Sessions
              </StyledDataBlockHeaderText>
              <Styled.CompleteSessionsWrapper>
                <Select
                  value={currentFinishedValue}
                  options={finishedOptions}
                  placeholder="All Sessions"
                  onChange={handleFinishedChange}
                />
              </Styled.CompleteSessionsWrapper>
            </StyledDataBlockHeader>
            <VirtualHumanAnalyticsSubmissionsTable
              analyticsSubmissions={sessionsData}
              vhData={sessionsQueryData?.readOneVirtualHuman}
            />
          </StyledDataBlock>
        </BlockWrapper>
        <Styled.PagesContainer>
          <PaginationControl
            {...controlProps}
            total={
              sessionsQueryData?.readOneVirtualHuman?.AnalyticsSubmissions
                ?.pageInfo?.totalCount
            }
          />
        </Styled.PagesContainer>
      </StyledPageContentInner>
    </StyledPage>
  );
};

VirtualHumanUserAnalyticsPage.propTypes = {
  userID: PropTypes.number,
  match: PropTypes.object.isRequired,
  userOrganisationID: PropTypes.number.isRequired,
};

export default withUser(VirtualHumanUserAnalyticsPage);
