import { useCallback, useState } from 'react';
import VHQuery from './VHQuery';

/**
 * @typedef {import('./useGenerateVHDetails.types').vhDetails} vhDetails
 * @typedef {import('./useGenerateVHDetails.types').AIVHGeneratingData} AIVHGeneratingData
 */

const useGenerateVHDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const generate = useCallback(
    async (/** @type {{vhDetails: vhDetails}} */ input, useBio = false) => {
      setError(null);
      setIsLoading(true);
      try {
        /** @type {{data: {data: AIVHGeneratingData}}} */
        const result = await VHQuery(`ai-generate-vh`, 'POST', {
          ...input,
          useBio,
        });
        setIsLoading(false);
        return result;
      } catch (err) {
        setError(err);
        setIsLoading(false);
      }
    },
    []
  );
  return [generate, { isLoading, error }];
};
export default useGenerateVHDetails;
