import { useLazyQuery } from '@apollo/client';
import React, { useCallback, useMemo, useState } from 'react';
import Button from '../../../../../../../components/buttons/Button';
import {
  StyledDataBlockHeader,
  StyledDataBlockHeaderText,
} from '../../../../../../../styled-components/StyledDataBlocks';
import {
  S_table,
  S_tbody,
  S_td,
  S_tdLeft,
  S_th,
  S_thLeft,
  S_tr,
} from '../../../../../../../styled-components/StyledTable';
import { SelectOrganisation } from '../../../form/Select';
import LoadingPlaceholder from '../../../LoadingPlaceholder';
import {
  StyledChevron,
  StyledShowMore,
} from '../Feedback/FeedbackUsage.styled';
import { READ_MEMBERS_ONBOARDING_PAGINATED } from './OnboardingUsage.query';
import {
  RestyledBlock,
  SelectOrganisationWrapper,
  StyledError,
} from './OnboardingUsage.styled';
import ProgressPercent from './ProgressPercent';
import StepList from './StepList';

const limit = 100;

export default function OnboardingUsage() {
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [members, setMembers] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);

  // run the special org onboarding query based on the selected org
  const [getMembers, { loading, hasFired, data }] = useLazyQuery(
    READ_MEMBERS_ONBOARDING_PAGINATED,
    {
      fetchPolicy: 'network-only',
      onCompleted: (result) => {
        setMembers([
          ...members,
          ...(result?.readMembers?.edges || []).map(({ node }) => node),
        ]);
      },
    }
  );

  const handleChange = useCallback(
    (org) => {
      setSelectedOrg(org);
      setMembers([]);
      setPageNumber(1);

      getMembers({
        variables: {
          organisationID: org.value,
          limit: limit,
          offset:
            org.value === selectedOrg?.value ? limit * (pageNumber - 1) : 0,
        },
      });
    },
    [getMembers, pageNumber, selectedOrg?.value]
  );

  const handleClick = useCallback(() => {
    setPageNumber(pageNumber + 1);
    // setting pageNumber here but still a race condition, so need to add into the offset query field as well
    getMembers({
      variables: {
        organisationID: selectedOrg?.value,
        limit: limit,
        offset: limit * pageNumber,
      },
    });
  }, [pageNumber, getMembers, selectedOrg?.value]);

  const placeholders = useMemo(() => {
    return [...Array(10)].map((_, index) => (
      <S_tr key={`${index}-placeholder-row`}>
        {[...Array(4)].map((_, index) => (
          <S_tdLeft key={index + 'placeholder'}>
            <LoadingPlaceholder />
          </S_tdLeft>
        ))}
      </S_tr>
    ));
  }, []);

  // only members that have started the new onboarding are shown
  const membersWithOnboardData = useMemo(() => {
    return members.filter(({ OnboardProgress }) => {
      const parsed = JSON.parse(OnboardProgress);
      return parsed?.progress;
    });
  }, [members]);

  return (
    <RestyledBlock>
      <StyledDataBlockHeader>
        <StyledDataBlockHeaderText>Onboarding Usage</StyledDataBlockHeaderText>
      </StyledDataBlockHeader>
      <SelectOrganisationWrapper>
        <SelectOrganisation
          value={selectedOrg}
          onChange={handleChange}
          placeholder="Select Organisation..."
        />
      </SelectOrganisationWrapper>

      <S_table>
        <S_tbody>
          <S_tr>
            <S_thLeft>User</S_thLeft>
            <S_thLeft>Last Seen</S_thLeft>
            <S_thLeft>Steps</S_thLeft>
            <S_th>Progress</S_th>
          </S_tr>
          {!loading &&
            membersWithOnboardData.map((node) => (
              <S_tr key={node.ID}>
                <S_tdLeft>{node.Name}</S_tdLeft>
                <S_tdLeft>{node.LastSeen}</S_tdLeft>
                <S_td>
                  <StepList progressJSON={node.OnboardProgress} />
                </S_td>
                <S_td>
                  <ProgressPercent progress={node.OnboardProgress} />
                </S_td>
              </S_tr>
            ))}

          {loading && placeholders}
        </S_tbody>
      </S_table>
      {!loading && hasFired && !members.length && (
        <StyledError> No users exist in this organisation.</StyledError>
      )}
      <StyledShowMore>
        <Button
          disabled={loading || !data?.readMembers?.pageInfo?.hasNextPage}
          type="secondary"
          onClick={handleClick}
        >
          Show More
          <StyledChevron icon="chevron" type="outline" />
        </Button>
      </StyledShowMore>
    </RestyledBlock>
  );
}
