import { gql } from '@apollo/client';

export const READ_GROUPS = gql`
  query readGroupTable(
    $limit: Int!
    $offset: Int!
    $filter: VirtiGroupFilterFields
    $sort: VirtiGroupSortFields
  ) {
    readVirtiGroups(
      offset: $offset
      limit: $limit
      filter: $filter
      sort: $sort
    ) {
      pageInfo {
        totalCount
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ID
          Name
          Simulations {
            pageInfo {
              totalCount
            }
          }
          VirtualHumans {
            pageInfo {
              totalCount
            }
          }
          Courses {
            pageInfo {
              totalCount
            }
          }
          Users {
            pageInfo {
              totalCount
            }
          }
        }
      }
    }
  }
`;
