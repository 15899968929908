import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import VHScenarioStep from './VHCreationSteps/VHScenarioStep';
import { useQuery, useMutation } from '@apollo/client';
import {
  CREATE_VH,
  READ_ENVIRONMENTS,
  READ_TEMPLATES,
} from './VHCreationWizard.query';
import useFlashMessage from '../../apps/core/src/components/FlashMessage';
import useUser from '../../apps/core/src/helpers/useUser';
import { Loading } from '@virtidev/toolbox';
import WithConfirmationBox from '../../HOCs/WithConfirmationBox';
import VHNameLocaleStep from './VHCreationSteps/VHNameLocaleStep';
import tracker from '../../apps/core/src/helpers/tracker';
import * as Styled from './VHCreationWizard.styled';
import { useHistory } from 'react-router-dom';

const VH_CREATION_STEPS = {
  SCENARIO: 1,
  LOCALE_SELECTION: 2,
};

const VHCreationWizard = ({ cancelAction, onVHCreated }) => {
  const [currentStep, setCurrentStep] = useState(VH_CREATION_STEPS.SCENARIO);
  const [creationDetails, setCreationDetails] = useState({});
  const { OrganisationID } = useUser();
  let history = useHistory();

  const {
    data: templatesData,
    loading: templatesLoading,
    error: errorTemplates,
  } = useQuery(READ_TEMPLATES);

  const {
    data: environmentsData,
    loading: environmentsLoading,
    error: environmentsError,
  } = useQuery(READ_ENVIRONMENTS);

  const loading = templatesLoading || environmentsLoading;
  const error = errorTemplates || environmentsError;

  const getRandomAvatar = useCallback(
    (environmentID) => {
      const eligibleAvatars = environmentsData?.readEnvironments?.nodes?.find(
        (node) => node.ID === environmentID
      )?.Avatars?.nodes;
      if (!eligibleAvatars || eligibleAvatars.length === 0) {
        return null;
      }
      const latestAvatars = eligibleAvatars?.filter(
        (avatar) => avatar.AvatarGeneration >= 2
      );
      const avatars =
        latestAvatars.length > 0 ? latestAvatars : eligibleAvatars;
      return avatars[Math.floor(Math.random() * eligibleAvatars.length)];
    },
    [environmentsData]
  );

  const SaveDetails = useCallback(
    (detailsObj) => {
      const updated = Object.assign(creationDetails, detailsObj);
      const environmentID = updated?.Template?.VirtualHuman?.EnvironmentID;
      const existingAvatarEnvironmentID = updated?.Avatar?.EnvironmentID;
      if (environmentID && environmentID !== existingAvatarEnvironmentID) {
        const avatar = getRandomAvatar(environmentID);
        updated.Avatar = avatar;
      }
      setCreationDetails(updated);
    },
    [creationDetails, getRandomAvatar]
  );

  const { addFlashMessage } = useFlashMessage();

  const handleScenarioConfirm = (vhPartialData) => {
    const templateEdge = templatesData.readVirtualHumanTemplates?.edges?.find(
      (edge) => edge.node.VirtualHuman.Type === vhPartialData.Type
    );
    if (!templateEdge?.node) {
      throw new Error('No template found for given type');
    }
    SaveDetails({ Template: templateEdge.node, ...vhPartialData });
    setStep(VH_CREATION_STEPS.LOCALE_SELECTION);
  };

  const [createVH, { loading: creating }] = useMutation(CREATE_VH, {
    update: (cache, { data }) => {
      cache.evict({ fieldName: 'readVirtualHumans' });
    },
    onCompleted: (data) => {
      addFlashMessage('Successfully created Virtual Human');
      tracker.track('vh_create_without_ai_completed');
      if (onVHCreated) {
        onVHCreated();
      }
      const vhID = data?.copyVirtualHuman?.ID;
      if (vhID) {
        history.push(`/virtual-humans/${vhID}`);
      }
    },
    onError: () => {
      addFlashMessage('Error retrieving vh data', 'error');
    },
  });

  const handleCreateVirtualHuman = (vhPartialData) => {
    const vhData = { ...creationDetails, ...vhPartialData };
    const variables = {
      VirtualHumanID: vhData.Template.VirtualHuman.ID,
      OrganisationID,
      Title: vhData.Title,
      AdminTitle: vhData.AdminTitle,
      AvatarID: creationDetails.Avatar?.ID,
      CreationMethod: 'wizard_create',
    };
    variables.Locale = vhData.Locale.BCP47Code;
    variables.VHVoiceID = vhData.Voice.ID;
    createVH({
      variables,
    });
  };

  const setStep = (step) => {
    if (step > VH_CREATION_STEPS.length || step < VH_CREATION_STEPS.SCENARIO) {
      console.error(
        "Step being set in VH Creation Wizard isn't within the current available steps."
      );
      return;
    }
    setCurrentStep(step);
  };

  const getModalTitle = (step) => {
    if (creating) {
      return <Styled.ModalTitleSaving>Saving</Styled.ModalTitleSaving>;
    }
    switch (step) {
      case VH_CREATION_STEPS.SCENARIO:
        return <Styled.ModalTitle>Select scenario</Styled.ModalTitle>;

      case VH_CREATION_STEPS.LOCALE_SELECTION:
        return <Styled.ModalTitle>Name your Virtual Human</Styled.ModalTitle>;

      default:
        return <></>;
    }
  };

  const getModalContent = (step) => {
    if (creating) {
      return (
        <Styled.SavingContent>
          <Loading size="large" />
        </Styled.SavingContent>
      );
    }
    switch (step) {
      case VH_CREATION_STEPS.SCENARIO:
        return <VHScenarioStep confirmAction={handleScenarioConfirm} />;

      case VH_CREATION_STEPS.LOCALE_SELECTION:
        return (
          <VHNameLocaleStep
            creationDetails={creationDetails}
            confirmAction={handleCreateVirtualHuman}
            cancelAction={cancelAction}
            loading={loading}
          />
        );

      default:
        return <>default step</>;
    }
  };

  const errorState =
    !loading &&
    (error ||
      !templatesData?.readVirtualHumanTemplates ||
      !environmentsData?.readEnvironments);

  return (
    <Styled.Modal
      show={true}
      onHide={creating ? null : cancelAction}
      title={getModalTitle(currentStep)}
      className={`step-${currentStep} ${creating ? 'saving' : ''}`}
    >
      {errorState ? (
        <div>Error retrieving data.</div>
      ) : (
        getModalContent(currentStep)
      )}
    </Styled.Modal>
  );
};

VHCreationWizard.propTypes = {
  cancelAction: PropTypes.func.isRequired,
  onVHCreated: PropTypes.func,
};

export default WithConfirmationBox(VHCreationWizard);
