import { useLazyQuery } from '@apollo/client';
import Checkbox from '@core/components/form/Checkbox';
import FieldError from '@core/components/form/FieldError';
import { CHECK_EMAIL } from '@core/components/Signup/RegisterForm/components/EmailForm.query';
import { emailFormSchema } from '@core/components/Signup/RegisterForm/helpers/register-form.schema';
import {
  GoLoginMessage,
  Link,
  PrivacyField,
  RegisterFormInner,
} from '@core/components/Signup/RegisterForm/RegisterForm.styled';
import { Button, Loading, TextInput } from '@virtidev/toolbox';
import { FC, useCallback } from 'react';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import { EmailFormInput } from '@core/components/Signup/RegisterForm/helpers/register-form.types';

/**
 * @type {FC<{
 *    onSubmit: () => void,
 * }>}
 */
export const EmailForm = ({ children, onSubmit }) => {
  /** @type {UseFormReturn<EmailFormInput>} */
  const { register, getValues, trigger, clearErrors, setError } =
    useFormContext();

  const [checkEmail, { loading }] = useLazyQuery(CHECK_EMAIL, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const submitForm = useCallback(
    async (e) => {
      if (e) {
        e.preventDefault();
      }
      const values = getValues();
      try {
        emailFormSchema.parse(values);

        const {
          data: { checkEmail: exists },
        } = await checkEmail({ variables: { email: values.Email } });

        if (exists) {
          setError(
            'Email',
            { message: 'Email is already in-use.' },
            { shouldFocus: true }
          );
          return;
        }

        clearErrors();
        onSubmit();
      } catch (e) {
        trigger(undefined, { shouldFocus: true });
      }
    },
    [trigger, clearErrors, getValues, onSubmit, setError, checkEmail]
  );

  return (
    <RegisterFormInner onSubmit={submitForm}>
      <div>
        <TextInput
          {...register('Email')}
          placeholder="Work email address"
          suffix={loading && <Loading />}
        />
        <FieldError name="Email" />
      </div>
      <div>
        <TextInput
          type="password"
          {...register('Password')}
          placeholder="Password"
        />
        <FieldError name="Password" />
      </div>
      <PrivacyField>
        <Checkbox {...register('AcceptPrivacy')} id="accept-privacy" />
        <label className="description" htmlFor="accept-privacy">
          I agree to the{' '}
          <Link to="/privacy-policy" target="_blank">
            Privacy Policy
          </Link>{' '}
          and{' '}
          <Link to="/terms-conditions" target="_blank">
            Terms of Service.
          </Link>
        </label>
        <FieldError name="AcceptPrivacy" className="error" />
      </PrivacyField>
      {children}
      <Button color="secondary" type="submit" loading={loading}>
        Continue
      </Button>
      <GoLoginMessage>
        Already signed up? <Link to="/login">Sign in.</Link>
      </GoLoginMessage>
    </RegisterFormInner>
  );
};

export default EmailForm;
