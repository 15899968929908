import styled, { css, keyframes } from 'styled-components';
import { Animation } from '@virtidev/toolbox';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const AnimatedContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 1500;
  opacity: 1;
  transition: opacity 0.3s;
  animation: ${fadeIn} 1s;
  animation-iteration-count: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${({ show }) =>
    !show &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`;

export const StyledAnimation = styled(Animation)`
  min-height: 200px;
  min-width: 200px;
  height: 30vh;
  width: calc(30vh * 1920 / 1080);
  margin: -5vh 0;
`;

export const ProgressBar = styled.div`
  position: relative;
  min-width: 250px;
  width: 30vh;
  height: 6px;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 50ms;
  overflow: hidden;
  border-radius: 6px;
  background-color: #ddd;
`;

export const Bar = styled.div`
  max-width: 100%;
  transition: width 0.2s;
  position: absolute;
  height: 100%;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.color.secondary};
`;
