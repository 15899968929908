import React from 'react';
import PropTypes from 'prop-types';
import CardMenuItem from '../CardMenuItem/CardMenuItem';

export const CardDeleteButton = ({ onClick }) => {
  return (
    <CardMenuItem onClick={onClick} iconName="bin" iconType="outline">
      Delete
    </CardMenuItem>
  );
};

CardDeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CardDeleteButton;
