import { Button } from '@virtidev/toolbox';
import styled from 'styled-components';

export const DeleteButtonWrapper = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.spacing.sm};
  top: 0;
`;

export const ConfirmationWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
`;

export const MiniButton = styled(Button)`
  padding-inline: ${({ theme }) => theme.spacing.sm};
`;
