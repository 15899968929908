import { useMemo, useState } from 'react';
import LoadingPlaceholder, {
  MockIcon,
  MockText,
} from '../../../LoadingPlaceholder';
import ResourceItem from './ResourceItem';
import { MockResource, MockResourceContainer } from './ResourceItem.styled';
import { StyledResourceList } from './Resources.styled';
import UploadResource from './UploadResource';

export default function Resources({ course }) {
  const [uploading, setUploading] = useState(false);

  const resources = useMemo(() => {
    if (!course) {
      return [];
    }
    const reversedData = [...course.Resources.edges].reverse();
    return reversedData;
  }, [course]);

  return (
    <>
      <UploadResource courseId={course?.ID} setUploading={setUploading} />
      <StyledResourceList>
        {uploading && (
          <MockResourceContainer>
            <LoadingPlaceholder>
              <MockResource>
                <MockText />
                <MockIcon />
              </MockResource>
            </LoadingPlaceholder>
          </MockResourceContainer>
        )}
        {resources.map(({ node: resource }) => (
          <ResourceItem
            key={resource.ID}
            resource={resource}
            courseId={course?.ID}
          />
        ))}
      </StyledResourceList>
    </>
  );
}
