import { gql } from '@apollo/client';

export const READ_SIMULATION_FOR_SELECT = gql`
  query readSimulations($filter: String, $OrganisationID: ID) {
    readSimulations(
      filter: {
        Title: { contains: $filter }
        Organisation: { ID: { eq: $OrganisationID } }
      }
    ) {
      edges {
        node {
          ID
          Title
        }
      }
    }
  }
`;
