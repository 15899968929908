import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './Icon.styled'

const Icon = props => {
  return (
    <Styled.Container width={props.width} height={props.height}>
      <Styled.Icon color={props.color}>{props.icon}</Styled.Icon>
    </Styled.Container>
  )
}

Icon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.node
};

export default Icon;
