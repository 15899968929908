import styled, { css } from 'styled-components';
import {
  StyledImageLink,
  StyledThumbnail,
  StyledUnitCard,
  StyledUnitCardInfoContainer,
  StyledUnitImageContainer,
  StyledUnitTitle,
  StyledUnitTitleContainer,
  TitleBox,
  TitleSpan,
} from './MyCourseUnit';
import { FALLBACK, Icon } from '@virtidev/toolbox';
import { useCallback, useMemo } from 'react';
import { getResizeImage } from '../apps/core/src/helpers/imageResizing/getResizeImage';

const TitleContainer = styled(StyledUnitTitleContainer)`
  padding: 0;
  border: 0;
`;

const InfoContainer = styled(StyledUnitCardInfoContainer)`
  display: block;
`;

const CardContainer = styled(StyledUnitCard)`
  display: inline-flex;
  margin-top: 2rem;
  width: 100%;
  max-width: 200px;

  ${({ locked }) =>
    locked &&
    css`
      filter: grayscale(1) brightness(0.9);
      * {
        pointer-events: none;
      }
    `}
`;

export const UnitCard = ({ unit, courseID, onClick, ...props }) => {
  const content =
    unit.Type === 'VirtualHuman' ? unit.VirtualHuman : unit.Simulation;
  const type = unit.Type === 'VirtualHuman' ? 'virtual-humans' : 'simulations';
  const { ID: contentID, Title } = content;

  const thumbnailUrl = useMemo(() => {
    if (unit.ImageMedia?.TusID) {
      return getResizeImage(unit.ImageMedia.TusID);
    }
    if (unit.Avatar?.ImageMedia?.TusID) {
      return getResizeImage(unit.Avatar.ImageMedia.TusID);
    }
    return (
      unit.ThumbnailURL ||
      unit.ImageMedia?.URL ||
      unit.FirstVideo?.nodes?.[0]?.PosterURL
    );
  }, [unit]);

  const url = useMemo(
    () => ({
      pathname: `/my-courses/${courseID}/${type}/${contentID}`,
      search: `unitID=${unit.ID}`,
    }),
    [contentID, courseID, type, unit.ID]
  );

  const handleOnClick = useCallback(
    (e) => {
      if (onClick) {
        e.stopPropagation();
        e.preventDefault();
        onClick(url);
      }
    },
    [url, onClick]
  );

  return (
    <CardContainer {...props}>
      <StyledImageLink to={url} onClick={handleOnClick}>
        <StyledUnitImageContainer>
          <StyledThumbnail
            src={thumbnailUrl || FALLBACK}
            alt={`${Title} preview`}
          />
        </StyledUnitImageContainer>
      </StyledImageLink>
      <InfoContainer>
        <TitleContainer>
          <StyledUnitTitle to={url} onClick={handleOnClick}>
            <Icon
              icon={unit.Type === 'VirtualHuman' ? 'virtual-human' : 'video'}
              color="var(--secondary-color, #46beaf)"
            />
            <TitleBox>
              <TitleSpan>{Title}</TitleSpan>
            </TitleBox>
          </StyledUnitTitle>
        </TitleContainer>
      </InfoContainer>
    </CardContainer>
  );
};

export default UnitCard;
