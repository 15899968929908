import React, { useMemo } from 'react';
import { Modal } from '@virtidev/toolbox';
import Label from '../../../../form/Label';
import * as Styled from '../../../VirtualHumanForm.styled';
import {
  CanEditVirtualHumanFeatured,
  CanEditVirtualHumanGroups,
  CanEditVirtualHumanHiddenInList,
  CanPublishVirtualHumans,
  CanReadyForPublishVirtualHumans,
} from '../../../../../../../../utility/Permissions';
import GroupCheckboxesForVirtualHuman from '../../../../../../../../forms/GroupCheckboxesForVirtualHuman';
import { FormProvider, useForm } from 'react-hook-form';
import useAutosave from '@core/helpers/useAutosave';
import { useMutation } from '@apollo/client';
import { EDIT_VIRTUAL_HUMAN_WITH_ENVIRONMENT_RESPONSE } from '@base/queries/VirtualHumanQueries';
import { useFlashMessage } from '@core/components/FlashMessage';
import { ControlledSelect } from '@core/components/form/Select';
import { NoteArea } from '@core/components/VirtualHumanForm/components/VirtualHumanEditors/AccessModal/AccessModal.styled';

export default function AccessModal({ show, onHide, virtualhuman }) {
  const { addFlashMessage } = useFlashMessage();
  const formMethods = useForm({
    defaultValues: {
      Status: virtualhuman?.Stataus,
      Featured: virtualhuman?.Featured,
      CoursesOnly: virtualhuman?.CoursesOnly,
    },
  });
  const { watch } = formMethods;
  const Status = watch('Status');
  const publishedStatuses = useMemo(() => {
    let statuses = [{ value: 'Unpublished', label: 'Unpublished' }];
    if (CanReadyForPublishVirtualHumans()) {
      statuses.push({
        value: 'Ready',
        label: 'Ready to Publish',
      });
    }
    if (CanPublishVirtualHumans() || Status === 'Published') {
      statuses.push({
        value: 'Published',
        label: 'Published',
      });
    }
    return statuses;
  }, [Status]);

  const [updateVH] = useMutation(EDIT_VIRTUAL_HUMAN_WITH_ENVIRONMENT_RESPONSE, {
    onError: () => {
      addFlashMessage('Failed to save virtual human', 'error');
    },
  });

  const handleSubmit = useMemo(() => {
    return async (values) => {
      const Input = {
        ID: virtualhuman.ID,
        Status: values.Status,
        Featured: values.Featured,
        CoursesOnly: values.CoursesOnly,
      };
      await updateVH({
        variables: {
          Input,
        },
      });
    };
  }, [updateVH, virtualhuman?.ID]);

  useAutosave(formMethods, handleSubmit);

  return (
    <Modal
      title="Manage virtual human access"
      show={show}
      onHide={onHide}
      render={() => (
        <FormProvider {...formMethods}>
          <div>
            <Label htmlFor="published-status">Status</Label>
            <ControlledSelect
              id="published-status"
              disabled={!CanPublishVirtualHumans() && Status === 'Published'}
              name="Status"
              options={publishedStatuses}
            />
          </div>
          {CanEditVirtualHumanFeatured() && (
            <div>
              <Styled.InputLabelTooltipWrapper>
                <Styled.ControlledToggleSwitch
                  name="Featured"
                  label="Featured"
                />
                <Styled.HelpTooltip>
                  Force this simulation to show towards the top of the list of
                  VH simulations."
                </Styled.HelpTooltip>
              </Styled.InputLabelTooltipWrapper>
            </div>
          )}
          {CanEditVirtualHumanHiddenInList() && (
            <div>
              <Styled.InputLabelTooltipWrapper>
                <Styled.ControlledToggleSwitch
                  name="CoursesOnly"
                  label="Hide in Listing"
                />
                <Styled.HelpTooltip>
                  Only show this virtual human as part of courses in the app.
                </Styled.HelpTooltip>
              </Styled.InputLabelTooltipWrapper>
            </div>
          )}
          <NoteArea>
            Public sharing has now moved! You can find a share link in the top
            corner of any virtual human page.
          </NoteArea>
          {CanEditVirtualHumanGroups() && (
            <Styled.AccessCheckboxes>
              <GroupCheckboxesForVirtualHuman
                virtualhuman={virtualhuman}
                virtualHumanID={virtualhuman ? virtualhuman.ID : null}
                checkedGroupIDs={
                  virtualhuman
                    ? virtualhuman.OrganisationGroups.edges.map(
                        (groupEdge) => groupEdge.node.ID
                      )
                    : []
                }
              />
            </Styled.AccessCheckboxes>
          )}
        </FormProvider>
      )}
    />
  );
}
