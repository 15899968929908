import { Icon, Select, TextInput } from '@virtidev/toolbox';
import { register } from 'heatmap.js';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm, useFormContext, useWatch } from 'react-hook-form';
import Details from '../../../apps/core/src/components/FeedbackForms/Question/Details/Details';
import { ControlledSelect } from '../../../apps/core/src/components/form/Select';
import {
  StyledCustomisedInputEditInputWrapper,
  StyledCustomisedInputHeader,
} from '../../../styled-components/StyledCustomisedInputs';
import Label from '../../../styled-components/StyledLabel';
import {
  ScaleWrapper,
  StyledLabel,
  StyledLikertForm,
  StyledLikertFormOption,
  StyledNumberRow,
  StyledNumberScale,
  StyledOptionPoleText,
} from './LikertScaleV2.styled';
import MockLikertScale from './MockLikertScale';

const typeOptions = [
  {
    value: { FirstLabel: 'Very Unlikely', LastLabel: 'Very Likely' },
    label: 'Likelihood',
  },
  {
    value: { FirstLabel: 'Strongly Disagree', LastLabel: 'Strongly Agree' },
    label: 'Agreement',
  },
  {
    value: { FirstLabel: 'Very Unhappy', LastLabel: 'Very Happy' },
    label: 'Happiness',
  },
];

const lengthOptions = [
  { value: 5, label: '5' },
  { value: 7, label: '7' },
  { value: 10, label: '10' },
  { value: 11, label: '11' },
];

export default function LikertScaleV2({ setValue, ...props }) {
  // This is to force the dropdown to reset when the user changes the text fields,
  // as passing null as the field value doesn't clear the selection.
  const [presetDropdownKey, setPresetDropdownKey] = useState(0);

  const [scaleTypeFirst, scaleTypeLast, firstLabel, lastLabel] =
    props.getValues([
      'scaleType.FirstLabel',
      'scaleType.LastLabel',
      'FirstLabel',
      'LastLabel',
    ]);

  // Update the text fields when a preset is chosen
  useEffect(() => {
    if (scaleTypeFirst && scaleTypeLast) {
      setValue('FirstLabel', scaleTypeFirst);
      setValue('LastLabel', scaleTypeLast);
    }
  }, [scaleTypeFirst, scaleTypeLast, setValue]);

  // Clear the selected preset if the user changes the text fields
  useEffect(() => {
    if (!(scaleTypeFirst || scaleTypeLast)) {
      return;
    }
    if (firstLabel !== scaleTypeFirst || lastLabel !== scaleTypeLast) {
      setValue('scaleType', null);
      setPresetDropdownKey((key) => key + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstLabel, lastLabel, setValue]);

  return (
    <StyledCustomisedInputEditInputWrapper>
      <Details
        editing={props.editing}
        register={props.register}
        typeTitle="Linear Scale Question"
        info=""
        icon="likertscale"
      >
        <Label>Length</Label>
        <ControlledSelect
          name="Length"
          menuPosition="fixed"
          noSearch
          options={lengthOptions}
          placeholder="Select scale length"
        />
        <br></br>
        <Label>Presets</Label>
        <ControlledSelect
          key={presetDropdownKey}
          name="scaleType"
          options={typeOptions}
          noSearch
          placeholder="Autofill options..."
        />
        <ScaleWrapper>
          <TextInput
            placeholder="First Label"
            {...props.register('FirstLabel')}
          />
          <StyledNumberScale>
            <StyledNumberRow>
              {[...Array(props.question.Length)].map((item, index) => {
                return <div key={index + 1}>{index + 1}</div>;
              })}
            </StyledNumberRow>
            <StyledNumberRow>
              {[...Array(props.question.Length)].map((item, index) => {
                return <div key={index + 1}>○</div>;
              })}
            </StyledNumberRow>
          </StyledNumberScale>
          <TextInput
            placeholder="Last Label"
            {...props.register('LastLabel')}
          />
        </ScaleWrapper>
      </Details>
      {!props.editing && (
        <MockLikertScale question={props.question} value={null} />
      )}
    </StyledCustomisedInputEditInputWrapper>
  );
}
