import React from 'react'
import PropTypes from 'prop-types';

const NoSearchResults = props => {
  return (
    <div className={props.className}>{props.children}</div>
  )
}

NoSearchResults.defaultProps = {
  children: 'No results found.'
};

NoSearchResults.propTypes = {
  children: PropTypes.string,
};

export default NoSearchResults