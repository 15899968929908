import { useMutation, useQuery } from '@apollo/client';
import { Button, Select, TextInput } from '@virtidev/toolbox';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';
import Label from '../../../../../../../../../../styled-components/StyledLabel';
import {
  DELETE_AVATARS,
  READ_AVATARS,
  UPDATE_AVATAR,
} from '../../CxAvatarsEditor.query';
import { useFlashMessage } from '../../../../../../FlashMessage';
import UploadImage from '../../../../../../CourseForm/components/UploadImage/UploadImage';
import ConfirmationModal from '../../../../../../../../../../components/modals/ConfirmationModal';
import { READ_VOICES } from '../../../CxVoicesEditor/CxVoicesEditor.query';
import ControlledToggleSwitch from '../../../../../../form/ToggleSwitch/ControlledToggleSwitch';
import { genderOptions } from '../../../../VHGenderSelector.data';

const SelectedAvatarEditor = ({ selectedAvatar }) => {
  const formMethods = useForm();
  const { register, reset, getValues, formState, setValue, watch } =
    formMethods;

  const { addFlashMessage } = useFlashMessage();
  const [updateAvatarMutation] = useMutation(UPDATE_AVATAR, {
    onCompleted: () => {
      addFlashMessage('Updated avatar');
    },
    onError: () => {
      addFlashMessage('Failed to update avatar', 'error');
    },
    refetchQueries: [{ query: READ_AVATARS }],
  });

  useEffect(() => {
    const genderReactSelectVal = genderOptions.find(
      (opt) => opt.value === selectedAvatar.Gender
    );
    setVoiceGender(genderReactSelectVal ?? genderOptions[0]);
    reset(selectedAvatar);
  }, [reset, selectedAvatar]);

  const updateAvatar = useCallback(() => {
    // if (!formState.isDirty) return;
    updateAvatarMutation({
      variables: {
        input: {
          ID: selectedAvatar.ID,
          Name: getValues('Name'),
          DisplayName: getValues('DisplayName'),
          Age: getValues('Age'),
          Gender: getValues('Gender'),
          ImageMediaID: getValues('ImageMediaID'),
          AvatarMediaID: getValues('AvatarMediaID'),
          DefaultVoiceID: getValues('DefaultVoiceID'),
          IsPreview: getValues('IsPreview'),
          IsDevelopment: getValues('IsDevelopment'),
          AvatarGeneration: getValues('AvatarGeneration'),
        },
      },
    });
  }, [getValues, selectedAvatar?.ID, updateAvatarMutation]);

  const debouncedUpdateAvatar = useDebouncedCallback(updateAvatar, 1000);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (!name) return;
      const debouncedChangeValues = ['Name', 'DisplayName', 'Age'];
      if (debouncedChangeValues.includes(name)) {
        debouncedUpdateAvatar();
      } else {
        console.log('update avatar');
        updateAvatar();
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, debouncedUpdateAvatar, updateAvatar]);

  const [showConfirm, setShowConfirm] = useState(false);
  const onClickDelete = useCallback(() => {
    setShowConfirm(true);
  }, []);

  const [deleteAvatar, { isLoading: isDeleting }] = useMutation(
    DELETE_AVATARS,
    {
      refetchQueries: [{ query: READ_AVATARS }],
    }
  );

  const onCloseConfirm = useCallback(() => {
    // setShowConfirm(false);
    deleteAvatar({ variables: { ids: [selectedAvatar.ID] } });
  }, [deleteAvatar, selectedAvatar?.ID]);

  const [voiceGender, setVoiceGender] = useState(genderOptions[0]);

  const handleVoiceGenderChange = useCallback(
    (newGender) => {
      setVoiceGender(newGender);
      setValue('Gender', newGender.value);
    },
    [setValue]
  );

  const { data: voicesData } = useQuery(READ_VOICES, {
    variables: { limit: 5000 },
  });

  return (
    <FormProvider {...formMethods}>
      <form>
        <h2>Selected Avatar</h2>
        <div>
          <ControlledToggleSwitch name="IsPreview">
            IsPreview
          </ControlledToggleSwitch>
        </div>
        <div>
          <ControlledToggleSwitch name="IsDevelopment">
            IsDevelopment
          </ControlledToggleSwitch>
        </div>
        <div>
          <Label htmlFor="avatar-codename">Code Name</Label>
          <TextInput id="avatar-codename" {...register('Name')} />
        </div>
        <div>
          <Label htmlFor="avatar-displayname">Display Name</Label>
          <TextInput id="avatar-displayname" {...register('DisplayName')} />
        </div>
        <div>
          <Label htmlFor="avatar-age">Age</Label>
          <TextInput id="avatar-age" {...register('Age')} />
        </div>
        <div>
          <Label htmlFor="avatar-gender">Gender</Label>
          <Select
            id="avatar-gender"
            value={voiceGender}
            onChange={handleVoiceGenderChange}
            options={genderOptions}
          />
        </div>
        <div>
          <Label htmlFor="avatar-voice">Default Voice</Label>
          <select id="avatar-voice" {...register('DefaultVoiceID')}>
            <option value="0">None</option>
            {voicesData?.readVoices.nodes.map((voice) => (
              <option key={voice.ID} value={voice.ID}>
                {voice.Name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <Label>
            Avatar Generation (what iteration of avatars e.g. new = 2 vs old
            avatars = 1)
          </Label>
          <TextInput id="avatar-generation" {...register('AvatarGeneration')} />
        </div>
        <div>
          <Label>Image Media (fallback thumbnail)</Label>
          <UploadImage
            url={selectedAvatar?.ImageMedia?.URL}
            mediaTusID={selectedAvatar?.ImageMedia?.TusID}
            name="ImageMediaID"
            key={selectedAvatar.ID}
          />
        </div>
        <div>
          <Label>Avatar Media (transparent avatar picker)</Label>
          <UploadImage
            url={selectedAvatar?.AvatarMedia?.URL}
            mediaTusID={selectedAvatar?.AvatarMedia?.TusID}
            name="AvatarMediaID"
            key={selectedAvatar.ID}
          />
        </div>
      </form>
      {showConfirm && (
        <ConfirmationModal
          onConfirm={onCloseConfirm}
          onCancel={() => setShowConfirm(false)}
          verificationTextRequired={selectedAvatar?.Name ?? ''}
          loading={isDeleting}
        >
          <>
            DANGER: Make sure this avatar is not used by ANY virtual humans
            before deletion.{' '}
            {selectedAvatar?.Name && (
              <>
                Type the avatar name <strong>{selectedAvatar.Name}</strong> to
                confirm.
              </>
            )}
          </>
        </ConfirmationModal>
      )}
      <Button color="danger" icon="alert" onClick={onClickDelete}>
        Delete
      </Button>
    </FormProvider>
  );
};

export default SelectedAvatarEditor;
