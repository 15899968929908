import React, { FC } from 'react';
import SubtitleItem from '../SubtitleItem/SubtitleItem';
import SubtitlePlaceholder from '../SubtitlePlaceholder/SubtitlePlaceholder';
import { StyledList } from './SubtitleList.styled';

/**
 * @typedef {import('../../../../models/video-media.types').Subtitle} Subtitle
 */

/**
 * @type {FC<{
 *    loading: boolean,
 *    videoID: string,
 *    subtitles: Subtitle[]
 * }>}
 */
export const SubtitleList = ({ loading, videoID, subtitles }) => {
  return (
    <StyledList>
      {loading && subtitles.length === 0 && (
        <>
          <SubtitlePlaceholder />
          <SubtitlePlaceholder />
        </>
      )}
      {subtitles.map((subtitle) => {
        if (subtitle.ID.startsWith('new')) {
          return <SubtitlePlaceholder key={subtitle.ID} />;
        }
        return (
          <SubtitleItem
            subtitle={subtitle}
            videoID={videoID}
            key={subtitle.ID}
          />
        );
      })}
    </StyledList>
  );
};

export default SubtitleList;
