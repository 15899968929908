import { Modal } from '@virtidev/toolbox';
import styled from 'styled-components';

export const ScoreOverviewModal = styled(Modal)`
  .modal-box {
    background-color: ${({ theme }) => theme.color.white};
    width: auto;
  }
  .modal-content {
    padding: 0;
    max-width: 600px;
    & {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
`;
