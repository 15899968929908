import { FC } from 'react';
import { TagContainer } from '@core/components/DataListCard/components/Content360Tag/Content360Tag.styled';

/**
 * @type {FC<{
 *    content360?: boolean,
 * }>}
 */
export const Content360Tag = ({ content360 }) => (
  <TagContainer $content360={content360}>
    {content360 ? '360' : '2D'}
  </TagContainer>
);

export default Content360Tag;
