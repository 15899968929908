import { Icon } from '@virtidev/toolbox';
import AITrainingTabCountIndicator from '../../AITrainingTabCountIndicator/AITrainingTabCountIndicator';

const VHTypeNavigation = {
  DETAILS: {
    testId: 'virtual-human-tab-details',
    isActive: (location) => {
      // match /virtual-humans/number with nothing afterwards
      const regex = /\/virtual-humans\/[0-9]+$/g;
      const found = location.pathname.match(regex);
      return found?.length > 0;
    },
    to: (vh) => `/virtual-humans/${vh.ID}`,
    title: 'Details',
  },
  BUILDER: {
    testId: 'virtual-human-tab-builder',
    isActive: (location) => location.pathname.includes('/conversation'),
    to: (vh) =>
      `/virtual-humans/${vh.ID}/builder/patient/${vh.ExternalID}/conversation`,
    title: 'Conversation',
  },
  EVALUATIONS: {
    testId: 'virtual-human-tab-evaluations',
    isActive: (location) => location.pathname.includes('/evaluations'),
    to: (vh) =>
      `/virtual-humans/${vh.ID}/builder/patient/${vh.ExternalID}/evaluations`,
    title: 'Evaluations',
  },
  BRANCHING_BUILDER: {
    testId: 'virtual-human-tab-branching-builder',
    isActive: (location) =>
      location.pathname.includes('/branching-conversation'),
    to: (vh) =>
      `/virtual-humans/${vh.ID}/builder/patient/${vh.ExternalID}/branching-conversation`,
    title: 'Branching Builder',
  },
  DIAGNOSIS: {
    testId: 'virtual-human-tab-diagnosis',
    isActive: (location) => location.pathname.includes('diagnosis'),
    to: (vh) =>
      `/virtual-humans/${vh.ID}/builder/patient/${vh.ExternalID}/diagnosis`,
    title: 'Diagnosis',
  },
  EXAMS: {
    testId: 'virtual-human-tab-exams',
    isActive: (location) => location.pathname.includes('exams'),
    to: (vh) =>
      `/virtual-humans/${vh.ID}/builder/patient/${vh.ExternalID}/exams`,
    title: 'Exams',
  },
  INVESTIGATIONS: {
    testId: 'virtual-human-tab-investigations',
    isActive: (location) => location.pathname.includes('investigations'),
    to: (vh) =>
      `/virtual-humans/${vh.ID}/builder/patient/${vh.ExternalID}/investigations`,
    title: 'Investigations',
  },
  OBJECTIVES: {
    testId: 'virtual-human-tab-objectives',
    isActive: (location) => location.pathname.includes('objectives'),
    to: (vh) =>
      `/virtual-humans/${vh.ID}/builder/patient/${vh.ExternalID}/objectives`,
    title: 'Objectives',
  },
  COMPLETIONS: {
    testId: 'virtual-human-tab-completions',
    isActive: (location) => location.pathname.includes('completions'),
    to: (vh) => `/virtual-humans/${vh.ID}/completions`,
    analytics: true,
    title: 'Completions',
  },
  ANALYTICS: {
    testId: 'virtual-human-tab-analytics',
    isActive: (location) => {
      return location.pathname.indexOf('/analytics') > -1;
    },
    to: (vh) => `/virtual-humans/${vh.ID}/analytics`,
    title: 'Analytics',
    extraContent: (externalVhId) => <Icon icon="expand" />,
  },
  OBJECTIVES_ANALYTICS: {
    testId: 'virtual-human-tab-objective-analytics',
    isActive: (location) => location.pathname.includes('objective-analytics'),
    to: (vh) => `/virtual-humans/${vh.ID}/objective-analytics`,
    analytics: true,
    title: 'Objective Analytics',
  },
  SESSIONS: {
    testId: 'virtual-human-tab-sessions',
    isActive: (location) => location.pathname.includes('sessions'),
    to: (vh) => `/virtual-humans/${vh.ID}/sessions`,
    analytics: true,
    title: 'Sessions',
  },
  ACTIONS: {
    testId: 'virtual-human-tab-actions',
    isActive: (location) => location.pathname.includes('actions'),
    to: (vh) =>
      `/virtual-humans/${vh.ID}/builder/patient/${vh.ExternalID}/actions`,
    title: 'Actions',
  },
  VITALS: {
    testId: 'virtual-human-tab-vitals',
    isActive: (location) => location.pathname.includes('vitals'),
    to: (vh) =>
      `/virtual-humans/${vh.ID}/builder/patient/${vh.ExternalID}/vitals`,
    title: 'Vitals',
  },
  PREVIEW: {
    testId: 'virtual-human-tab-preview',
    isActive: (location) => location.pathname.includes('preview'),
    to: (vh) => `/virtual-humans/${vh.ID}/preview`,
    title: 'Preview',
  },
  STATES: {
    testId: 'virtual-human-tab-states',
    isActive: (location) => location.pathname.includes('states'),
    to: (vh) =>
      `/virtual-humans/${vh.ID}/builder/patient/${vh.ExternalID}/states`,
    title: 'States',
    statesEditorFeature: true,
  },
  AI_FALLBACKS: {
    testId: 'virtual-human-tab-ai-fallbacks',
    isActive: (location) => location.pathname.includes('ai-fallbacks'),
    to: (vh) =>
      `/virtual-humans/${vh.ID}/builder/patient/${vh.ExternalID}/ai-fallbacks`,
    title: 'Character',
    vhAdminAIFallbacksFeature: true,
  },
  AI_GENERATOR: {
    testId: 'virtual-human-tab-generate-via-ai',
    isActive: (location) => location.pathname.includes('generate-via-ai'),
    to: (vh) =>
      `/virtual-humans/${vh.ID}/builder/patient/${vh.ExternalID}/generate-via-ai`,
    title: 'AI Generator',
    vhAiGeneratorFeature: true,
  },
  TRAINING: {
    testId: 'virtual-human-tab-training',
    isActive: (location) => location.pathname.includes('unmatched-questions'),
    to: (vh) =>
      `/virtual-humans/${vh.ID}/builder/patient/${vh.ExternalID}/unmatched-questions`,
    title: 'Training',
    vhUnmatchedQuestionsTrainingPageFeature: true,
    extraContent: (externalVhId) => (
      <AITrainingTabCountIndicator vhId={externalVhId} />
    ),
  },
};
export default VHTypeNavigation;
