import { gql } from '@apollo/client';

export const READ_ORGANISATION_INVITE_CODES = gql`
  query readOrganisationInviteCodes($ID: ID!) {
    readOneOrganisation(filter: { ID: { eq: $ID } }) {
      ID
      InviteCodeAdmin
      InviteCodeUser
    }
  }
`;

export const BULK_CREATE_USERS = gql`
  mutation bulkCreateUsers(
    $OrganisationID: ID!
    $Emails: [String]!
    $SendWelcomeEmail: Boolean!
    $UserType: String!
    $GroupID: ID
  ) {
    bulkCreateUsers(
      OrganisationID: $OrganisationID
      Emails: $Emails
      SendWelcomeEmail: $SendWelcomeEmail
      UserType: $UserType
      GroupID: $GroupID
    ) {
      Created
      Message
      Member {
        ID
        Email
        SendWelcomeEmail
      }
    }
  }
`;
