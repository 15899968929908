import styled, { css } from 'styled-components';
import { Icon } from '@virtidev/toolbox';
import { DropUnitArea as BaseDropUnitArea } from '../CourseUnit/CourseUnit.styled';

export const ModuleContainer = styled.div`
  position: relative;
  border-radius: var(--accordion-border-radius);
`;

export const ModuleBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  margin-right: var(--module-action-gap);
`;

export const ModuleBody = styled.div`
  ${({ opened }) =>
    opened
      ? css`
          overflow: auto;
          max-height: calc(2 * 100vh);
          padding: calc(var(--accordion-border-radius) + 16px) 16px 16px;
        `
      : css`
          overflow: hidden;
          max-height: 0;
          padding: 0 16px;
        `}
  margin-top: calc(-1 * var(--accordion-border-radius));
  margin-bottom: var(--accordion-border-radius);
  border: 1px solid #c3c3c3;
  transition: all 0.3s;
  border-radius: 0 0 var(--accordion-border-radius)
    var(--accordion-border-radius);
`;

export const UnitsHolder = styled.div`
  display: flex;
  flex-direction: column;
  overflow: ${({ opened }) => (opened ? 'auto' : 'hidden')};
  max-height: ${({ opened }) => (opened ? '100vh' : 0)};
  transition: max-height 0.3s;
  margin-top: 1px;
  padding-top: 7px;
`;

export const ToggleIcon = styled(Icon)`
  cursor: pointer;
  transform: ${({ opened }) => (opened ? 'rotate(270deg)' : 'rotate(90deg)')};
`;

export const ActionsContainer = styled.div`
  position: absolute;
  left: calc(100% + 10px);
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: transparent;
  gap: 5px;
`;

export const Action = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border: 0;
  padding: 0;
  border-radius: 8px;
  background: transparent;
  cursor: pointer;

  &:focus {
    box-shadow: var(--input-focus-box-shadow);
  }

  &:hover {
    filter: brightness(115%);
  }

  &:active {
    filter: brightness(85%);
  }

  &:disabled,
  &[disabled] {
    filter: grayscale();
  }
`;

export const EditAction = styled(Action)`
  color: #c3c3c3;

  &:focus,
  &:hover,
  &:active {
    color: var(--primary-color, #528fbf);
  }
`;

export const DeleteAction = styled(Action)`
  color: #c3c3c3;

  &:focus,
  &:hover,
  &:active {
    color: var(--error-color, #e15040);
  }
`;

export const DropUnitArea = styled(BaseDropUnitArea)`
  && {
    padding: 16px;
    margin-bottom: 0;
    margin-right: var(--module-action-gap);
  }
`;

export const EmptyListContainer = styled.div`
  padding: 0 0 16px;
`;

export const EmptyListBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 16px;
  background: #ccc;
  border: 2px solid #ccc;
  border-radius: var(--accordion-border-radius);
  margin-right: var(--module-action-gap);
  color: #fff;
  gap: 0.5rem;
`;

export const DropModuleArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  padding: 6px 16px;
  ${({ top }) =>
    top
      ? css`
          margin-bottom: 16px;
        `
      : css`
          margin-top: 6px;
        `};
  border: 2px dashed #cccccc;
  border-radius: var(--accordion-border-radius);
  margin-right: var(--module-action-gap);
`;

export const DropSimArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 8px;
  z-index: 10;
  border: 2px dashed #cccccc;
  border-radius: var(--accordion-border-radius);
  background: #fff;
  margin-right: var(--module-action-gap);
`;
