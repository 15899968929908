import React, { useMemo, FC } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { ApolloError } from '@apollo/client';

const StyledError = styled.div`
  text-align: center;
  margin-top: 2rem;
`;

/**
 * @type {FC<{
 *    message?: string,
 *    graphQLErrorObj?: ApolloError,
 * }>}
 */
const PageLoadError = ({ message, graphQLErrorObj }) => {
  const errorMessage = useMemo(() => {
    if (message) {
      return message;
    }

    if (graphQLErrorObj?.graphQLErrors) {
      const messages = _.uniq(
        graphQLErrorObj?.graphQLErrors.map((error) => error.message)
      );
      return (
        <>
          <p>The following errors found:</p>
          <ul>
            {messages.map((item) => (
              <li>{item}</li>
            ))}
          </ul>
        </>
      );
    }

    return 'There was an issue contacting the server. Please try again or refresh the page.';
  }, [message, graphQLErrorObj?.graphQLErrors]);

  return <StyledError>{errorMessage}</StyledError>;
};

export default PageLoadError;
