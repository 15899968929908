import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledOnboardingResetButton,
  StyledOnboardingSkipButton,
} from '../styled-components/StyledOnboardingDashboard';
import { useMutation } from '@apollo/client';
import {
  EDIT_ONBOARD_PROGRESS,
  READ_ONBOARD_PROGRESS,
} from '../queries/UserQueries';
import withUser from '../HOCs/WithUser';
import WithConfirmationBox from '../HOCs/WithConfirmationBox';

function OnboardingSkipSectionButton(props) {
  const [mutateUpdateOnboarding, { loading: mutating }] = useMutation(
    EDIT_ONBOARD_PROGRESS
  );

  const skipSection = () => {
    const parsedData = props.parsedData;

    const skipData = props.tasks.reduce((acc, task) => {
      parsedData.progress[task.Key] = true;
      return acc;
    }, parsedData);

    mutateUpdateOnboarding({
      variables: {
        Input: {
          ID: props.userID,
          OnboardProgress: JSON.stringify(skipData),
        },
      },
    });
  };

  return (
    <StyledOnboardingSkipButton
      type="secondary"
      disabled={props.completed || mutating}
      onClick={() =>
        props.confirm(
          () => {
            skipSection();
          },
          'Are you sure you want to skip this section?',
          `All tasks in this section will be skipped.`,
          'delete'
        )
      }
    >
      Skip This Section
    </StyledOnboardingSkipButton>
  );
}

OnboardingSkipSectionButton.propTypes = {
  userID: PropTypes.number,
  parsedData: PropTypes.object,
  tasks: PropTypes.array,
};

export default WithConfirmationBox(withUser(OnboardingSkipSectionButton));
