import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';

import withUser from '../HOCs/WithUser';
import RedirectWithPrevState from '../components/RedirectWithPrevState';
import Axios from 'axios';
import { GetAIToken } from '../utility/LoginUtility';
import { getVirtualHumanAPI } from '../utility/VirtualHumanUtility';
import { useQuery } from '@apollo/client';
import { READ_VIRTUAL_HUMAN } from '../queries/VirtualHumanQueries';
import PageLoadError from '../components/PageLoadError';
import { useCurrentOrganisation } from '../apps/core/src/helpers/useCurrentOrganisation';

const StyledH1 = styled.h4`
  font-size: 1.9rem;
  margin-bottom: 0.5rem;
`;
const StyledH4 = styled.h4`
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
`;
const StyledH5 = styled.h5`
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
`;
const StyledDiv = styled.div`
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
`;
const ConversationItem = (props) => {
  const children = props.allItems.filter((item) =>
    props.currentItem.children.includes(item.id)
  );
  return (
    <div
      style={{
        marginLeft: props.currentItem.id === props.rootItem.id ? '' : '2rem',
        marginBottom: '1rem',
      }}
    >
      {props.currentItem.id !== props.rootItem.id &&
        props.currentItem.parentId === props.rootItem.id && (
          <hr style={{ borderTop: '1px solid black' }} />
        )}
      <StyledH4 style={{ marginTop: '.6rem' }}>
        {props.currentItem.name}
      </StyledH4>
      {props.currentItem.id !== props.rootItem.id && (
        <>
          <StyledH5>Questions:</StyledH5>
          {props.currentItem.questions.map((question) => (
            <StyledDiv>{question}</StyledDiv>
          ))}
        </>
      )}
      <StyledH5 style={{ marginTop: '.5rem' }}>Response:</StyledH5>
      <StyledDiv style={{ marginBottom: '.6rem' }}>
        {props.currentItem.response}
      </StyledDiv>
      {children.map((child) => (
        <ConversationItem
          key={child.id}
          allItems={props.allItems}
          currentItem={child}
          rootItem={props.rootItem}
        />
      ))}
    </div>
  );
};
ConversationItem.propTypes = {
  allItems: PropTypes.array.isRequired,
  currentItem: PropTypes.object.isRequired,
  rootItem: PropTypes.object.isRequired,
};

const VirtualHumanConversationTextPage = (props) => {
  const [state, setState] = useState({ conversationItems: [], error: null });
  const { data, loading, error } = useQuery(READ_VIRTUAL_HUMAN, {
    variables: {
      ID: props.match.params.ssVirtualHumanID,
      organisationID: props.userOrganisationID,
    },
  });

  useCurrentOrganisation(data?.readOneVirtualHuman?.OrganisationID);

  useEffect(() => {
    Axios.get(
      getVirtualHumanAPI() + '/api/patient/' + props.match.params.patientId,
      {
        headers: {
          Authorization: 'Bearer ' + GetAIToken(),
        },
      }
    )
      .catch(function (error) {
        // handle error
        console.log(error);
        setState((s) => {
          return {
            ...s,
            error,
          };
        });
      })
      .then((response) => {
        setState((s) => {
          return {
            ...s,
            conversationItems: _.get(response, 'data.conversationItems', []),
          };
        });
      });
  }, [props.match.params.patientId]);

  if (!props.userID) {
    return <RedirectWithPrevState to="/login" />;
  }
  if (state.error) {
    return <div>{state.error.message}</div>;
  }
  const rootItem = state.conversationItems.find(
    (item) =>
      item.parentId === null ||
      (item.questions && item.questions[0] === '__INITIAL__')
  );
  if (error) return <PageLoadError error={error} />;
  if (!rootItem || loading) return <div>Loading...</div>;
  return (
    <div style={{ textAlign: 'left' }}>
      <StyledH1>{data?.readOneVirtualHuman?.Title}</StyledH1>
      <ConversationItem
        allItems={state.conversationItems}
        currentItem={rootItem}
        rootItem={rootItem}
      />
    </div>
  );
};

VirtualHumanConversationTextPage.propTypes = {
  userID: PropTypes.number,
  match: PropTypes.object.isRequired,
  userOrganisationID: PropTypes.number.isRequired,
};

export default withUser(VirtualHumanConversationTextPage);
