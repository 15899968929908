import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  VHAIFileContainer,
  VHAIFileIcon,
  VHAIFileMenu,
  VHAIFileMenuItem,
  VHAIFileMenuLink,
  VHAIFileName,
  VHAIFileSize,
} from '../../VHAIFiles.styled';
import { formatFilesize, Loading } from '@virtidev/toolbox';
import IconTxt from '../../icons/icon-txt.svg';
import IconDoc from '../../icons/icon-doc.png';
import IconPdf from '../../icons/icon-pdf.png';
import useFlashMessage from '@core/components/FlashMessage';

export const VHAIFileItem = ({ file, onDelete }) => {
  const { addFlashMessage } = useFlashMessage();
  const [deleting, setDeleting] = useState(false);
  const icon = useMemo(() => {
    switch (file.type) {
      case 'text/plain': {
        return IconTxt;
      }
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/msword': {
        return IconDoc;
      }
      case 'application/pdf': {
        return IconPdf;
      }
      default:
        return null;
    }
  }, [file.type]);

  const removeFile = useCallback(async () => {
    setDeleting(true);
    try {
      await onDelete(file.id);
    } catch (e) {
      setDeleting(false);
      console.error(e);
      addFlashMessage('An error occured while removing the file', 'error');
      return;
    }

    addFlashMessage('File removed');
  }, [addFlashMessage, file.id, onDelete]);

  return (
    <VHAIFileContainer>
      {icon && <VHAIFileIcon src={icon} />}
      <VHAIFileName>{file.name}</VHAIFileName>{' '}
      {!!file.size && (
        <VHAIFileSize>{formatFilesize(file.size || 0)}</VHAIFileSize>
      )}
      <VHAIFileMenu
        placement="bottom-end"
        autoClose
        icon={deleting ? false : 'dots'}
        iconContent={deleting && <Loading />}
      >
        {file.tusUrl && (
          <VHAIFileMenuLink
            target="_blank"
            href={file.tusUrl}
            title={file.name || file.tusUrl}
            download={file.name || '(Knowledge file)'}
          >
            Download
          </VHAIFileMenuLink>
        )}
        <VHAIFileMenuItem onClick={removeFile}>Remove</VHAIFileMenuItem>
      </VHAIFileMenu>
    </VHAIFileContainer>
  );
};

export default VHAIFileItem;
