import { Button } from '@virtidev/toolbox';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  margin: 2rem;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledTitle = styled.h1`
  font-size: 1.2rem;
  margin-top: 1rem;
`;

export const StyledButton = styled(Button)`
  border-radius: 22px;
  width: 120px;
  margin-top: 1rem;
  margin-left: auto;
  order: 2;
`;

export const StyledInvalidList = styled.ul`
  list-style: initial;
  line-height: 1.2rem;
  display: grid;
  gap: 0.5rem;
  margin-left: 1.5rem;
`;
export const StyledInvalidListItem = styled.li`
  color: var(--alert-message-text-color-warning);
`;
