import { gql } from '@apollo/client';

export const GENERATE_GROUP_INVITE_CODES = gql`
  mutation generateGroupInviteCodes($Input: UpdateVirtiGroupInput!) {
    updateGroup(input: $Input) {
      ID
      InviteCodeAdmin
      InviteCodeUser
    }
  }
`;

export const GENERATE_ORG_INVITE_CODES = gql`
  mutation generateOrganisationInviteCodes($Input: UpdateOrganisationInput!) {
    updateOrganisation(input: $Input) {
      ID
      InviteCodeAdmin
      InviteCodeUser
    }
  }
`;
