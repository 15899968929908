import { gql } from '@apollo/client';

export const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $ID: ID
    $Token: String
    $Password: String!
    $OldPassword: String
  ) {
    changePassword(
      MemberID: $ID
      Token: $Token
      Password: $Password
      OldPassword: $OldPassword
    )
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($Email: String!) {
    forgotPassword(Email: $Email)
  }
`;

export const CHANGE_USER_PASSWORD = gql`
  mutation changeUserPassword($ID: ID!, $Password: String!) {
    changeUserPassword(MemberID: $ID, Password: $Password)
  }
`;
