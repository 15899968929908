import styled from 'styled-components';

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-self: flex-end;
  max-width: 100%;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.p3};
`;