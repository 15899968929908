import { useDrag, useDrop } from 'react-dnd';

export const useUnitDragDrop = ({ data, index, onSort, moduleId, units }) => {
  const [{ dragging, enabled }, dragRef, dragViewRef] = useDrag(
    () => ({
      type: 'UnitSort',
      item: { data, index, moduleId, units },
      canDrag: () => !data.ID.includes('new'),
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult();
        if (item && dropResult) {
          onSort(item, dropResult);
        }
      },
      collect: (monitor) => ({
        dragging: monitor.isDragging(),
        enabled: !data.ID.includes('new'),
      }),
    }),
    [data, index, onSort, moduleId, units]
  );

  const [{ hover, above, type }, dropRef] = useDrop(
    () => ({
      accept: 'UnitSort',
      drop: () => ({ data, index, moduleId, units }),
      canDrop: (item) =>
        !data.ID.includes('new') &&
        item.data.ID !== data.ID &&
        !units.find(
          (unit) =>
            unit.ID !== item.data.ID &&
            (
              (unit.Type === 'Simulation' && unit.Simulation.ID === item.data.Simulation.ID) ||
              (unit.Type === 'VirtualHuman' && unit.VirtualHuman.ID === item.data.VirtualHuman.ID)
            )
        ),
      collect: (monitor) => {
        const item = monitor.getItem();

        return item
          ? {
              type: item?.data?.Type,
              hover: monitor.isOver() && monitor.canDrop(),
              above: moduleId !== item.moduleId || index < item.index,
            }
          : {};
      },
    }),
    [data, index, moduleId, units]
  );

  return {
    dragging,
    enabled,
    dragRef,
    dragViewRef,
    hover,
    above,
    type,
    dropRef,
  };
};
