import { useCallback, useState, useEffect, useRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import UploadTUSHandler from '../../../../../../../components/upload/UploadTUSHandler';
import { useMutation } from '@apollo/client';
import { getResizeImage } from '../../../../helpers/imageResizing/getResizeImage';
import getConfig from '../../../../../../../lib/config';
import useUser from '../../../../helpers/useUser';
import { CREATE_MEDIA } from '@core/components/Media/components/MediaUploaders/MediaUploader.query';

export const UploadImage = ({
  url,
  mediaTusID,
  name,
  loading,
  updateThumbnail,
  width,
  height,
  aspectRatio,
  ...props
}) => {
  const [remount, setRemount] = useState(2);
  const [src, setSrc] = useState(url);
  const [innerMediaTusID, setInnerMediaTusID] = useState(mediaTusID);

  const {
    field: { onChange },
  } = useController({ name });
  const uploaderRef = useRef();

  const { Organisation } = useUser();

  const [createMedia] = useMutation(CREATE_MEDIA);

  useEffect(() => {
    // takes the mediaID and returns a resized image URL
    const resizedImageURL = getResizeImage(innerMediaTusID);
    setSrc(resizedImageURL || url);
  }, [innerMediaTusID, url]);

  useEffect(() => {
    if (mediaTusID) {
      setInnerMediaTusID(mediaTusID);
    }
  }, [mediaTusID]);

  const handleSuccess = useCallback(
    async (upload, TusID) => {
      const { data } = await createMedia({
        variables: {
          Input: {
            URL: `${getConfig('REACT_APP_CLOUDFRONT_URL')}${TusID}`,
            TusID,
            Title: upload.file.name,
            OrganisationID: Organisation.ID,
            Filename: TusID,
            Bucket: getConfig('REACT_APP_S3_BUCKET'),
          },
        },
      });

      onChange(data.createMedia.ID);
      updateThumbnail(data.createMedia.URL);
      setInnerMediaTusID(data.createMedia.TusID);
      setRemount(remount + 1);
    },
    [
      remount,
      setRemount,
      updateThumbnail,
      createMedia,
      Organisation,
      onChange,
      setInnerMediaTusID,
    ]
  );

  const handleRemove = useCallback(() => {
    setSrc(null);
    updateThumbnail(null);
    setInnerMediaTusID(null);

    setRemount(remount + 1);

    onChange('0');
  }, [onChange, remount, setSrc, setRemount, updateThumbnail]);

  if (loading) {
    return <div style={{ height: 250 }} />;
  }

  return (
    <UploadTUSHandler
      {...props}
      key={remount}
      width={width}
      height={height}
      ref={uploaderRef}
      mediaType="image"
      multiple={false}
      dragHereText={`Upload new Image`}
      name="new-simulation"
      onSuccess={handleSuccess}
      imageSrc={src}
      loaderSize="small"
      needsOwnNavigationPrompt={true}
      onRemoveImg={handleRemove}
      aspectRatio={aspectRatio}
    />
  );
};

UploadImage.defaultProps = {
  loading: false,
  updateThumbnail: () => null,
  width: 320,
  height: 180,
};

UploadImage.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  updateThumbnail: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  aspectRatio: PropTypes.string,
  mediaTusId: PropTypes.number,
};

export default UploadImage;
