import {
  PanelButtons,
  PanelContent,
  PanelTitle,
  QuickButton,
  QuickPanel,
} from '../QuickActions.styled';
import QuickIcon from './QuickIcon';

export const SimulationAction = () => (
  <QuickPanel>
    <QuickIcon icon="vr" />
    <PanelContent>
      <PanelTitle>Simulations</PanelTitle>
      <PanelButtons>
        <QuickButton to="/simulations/new">Create</QuickButton>
        <QuickButton design="outline" to="/simulations">
          Browse
        </QuickButton>
      </PanelButtons>
    </PanelContent>
  </QuickPanel>
);

export default SimulationAction;
