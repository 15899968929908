import styled from 'styled-components';

export const StyledLikertScaleModel = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 2rem auto;
  text-align: center;
`;
export const StyledNumberScale = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  font-size: 1.2rem;
`;

export const StyledLikertForm = styled.form`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 80%;
  margin: auto;
  background-color: var(--primary-bg-color);
  padding: 1rem;
  border-radius: var(--card-border-radius);
`;

export const StyledLikertFormOption = styled.div`
  flex: 1;
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 1rem;
  place-items: center;
  padding: 2rem 0rem;
  text-align: center;
  input {
    position: relative !important;
    opacity: 1 !important;
    display: inline !important;
  }
`;

export const StyledOptionPoleText = styled.div`
  display: grid;
  place-items: center;
  text-align: center;
  font-size: 0.9rem;
  width: 8rem;
  max-width: 100%;
`;

export const StyledCustomisedInputHeader = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const StyledLabel = styled.div`
  margin-bottom: 2rem;
  word-break: break-word;
`;

export const StyledNumberRow = styled.div`
  display: flex;
  gap: 20px;
`;

export const ScaleWrapper = styled.div`
  margin: 40px 0px;
  display: grid;
  grid-template-columns: 2fr 3fr 2fr;
  place-items: center;
`;
