import React from 'react';
import DeleteQuestion from './DeleteQuestion/DeleteQuestion';
import DuplicateQuestion from './DuplicateQuestion/DuplicateQuestion';
import { StyledButtonsWrapper } from './QuestionActions.styled';

export default function QuestionActions({
  question,
  setEditing,
  onDelete,
  formID,
  activePage,
  setLoading,
}) {
  // waiting to see if we can delete the sort order button as it will have to be rewritten
  return (
    <StyledButtonsWrapper>
      <DuplicateQuestion
        question={question}
        setEditing={setEditing}
        formID={formID}
        activePage={activePage}
        setLoading={setLoading}
      />

      <DeleteQuestion questionID={question.ID} onClick={onDelete} />
    </StyledButtonsWrapper>
  );
}
