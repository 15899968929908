import { useMemo } from 'react';
import useGetVHsWithEvaluationsOrObjectives from './useGetVHsWithEvaluationsOrObjectives';

/**
 * @type {(vhId: number) => {hasEvaluationsOrObjectives: boolean; loading: boolean; error: any;}}
 */
const useGetVHHasEvaluationsOrObjectives = (vhId) => {
  const { vhIdsWithEvaluationsOrObjectives, loading, error } =
    useGetVHsWithEvaluationsOrObjectives([vhId]);
  const hasEvaluationsOrObjectives = useMemo(() => {
    return (
      vhIdsWithEvaluationsOrObjectives &&
      vhIdsWithEvaluationsOrObjectives.includes(vhId)
    );
  }, [vhIdsWithEvaluationsOrObjectives, vhId]);
  return { hasEvaluationsOrObjectives, loading, error };
};

export default useGetVHHasEvaluationsOrObjectives;
