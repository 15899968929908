import React from 'react';
import { Icon } from '@virtidev/toolbox';
import { useLocation } from 'react-router-dom';
import { Tab, TabNav } from '../styled-components/StyledTabNavV2';

export default function FormsNav(props) {
  const location = useLocation();

  return (
    <TabNav>
      <Tab
        $isActive={
          !location.pathname.includes('questions') &&
          !location.pathname.includes('submissions') &&
          !location.pathname.includes('analytics')
        }
        to={`/feedback-forms/${props.formID}`}
      >
        Form Overview
      </Tab>
      <Tab
        data-testid="sim-analytics-tab"
        to={`/analytics-data/forms/${props.formID}`}
      >
        Analytics <Icon icon="expand" />
      </Tab>
    </TabNav>
  );
}
