import React from 'react';
import styled from 'styled-components';
import StyledLink from '../styled-components/StyledLink';
import withUser from '../HOCs/WithUser';
import RedirectWithPrevState from '../components/RedirectWithPrevState';

const Styled404 = styled.div`
  text-align: center;
  font-size: 1rem;
  margin: 8rem 0rem;

  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  div {
    margin-bottom: 2rem;
  }
  display: grid;
  place-items: center;
`;

const StyledCard = styled.div`
  border: var(--input-border);
  width: 30rem;
  max-width: 100%;
  border-radius: var(--card-border-radius);
  box-shadow: var(--card-box-shadow);
  padding: 5rem;
`;

function NotFoundPage(props) {
  if (!props.userID) {
    return <RedirectWithPrevState to="/login" />;
  }
  return (
    <Styled404>
      <StyledCard>
        {' '}
        <h1>404</h1>
        <div>Sorry, that page doesn't exist.</div>
        <StyledLink to="/">Back to Dashboard</StyledLink>
      </StyledCard>
    </Styled404>
  );
}

export default withUser(NotFoundPage);
