import getConfig from '../../../../../lib/config';

const btoa = (payload) => {
  if (window.btoa) {
    try {
      return window.btoa(payload);
    } catch (e) { }
  }

  return Buffer.from(payload, 'utf8').toString('base64');
};

export const getResizeImage = (TusID, resizeParams) => {
  // can expand this to take params for edits
  if (!TusID) {
    return null;
  }

  const resize = resizeParams
    ? resizeParams
    : {
        resize: {
          width: 600,
          height: 338,
        },
      };

  const requestJson = {
      bucket: getConfig("REACT_APP_S3_BUCKET"),
    key: TusID,
    edits: resize,
  };

  // using btoa as this is the example Adam provided, though vscode says it's deprecated
  const encodedString = btoa(JSON.stringify(requestJson));
  const requestUrl = `${getConfig("REACT_APP_CLOUDFRONT_RESIZING_URL")}${encodedString}`;

  return requestUrl;
};
