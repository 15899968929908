import { Loading } from '@virtidev/toolbox';
import React, { useEffect, useState, useMemo } from 'react';

import { Savebar } from '../../../Page';
import AddNewQuestionButton from '../../Question/AddNewQuestionButton/AddNewQuestionButton';
import ShareModal from '../../ShareModal/ShareModal';
import AutosaveIndicator from '../../../form/AutosaveIndicator/AutosaveIndicator';
import EditDetails from '../EditDetails/EditDetails';
import EditPages from '../EditPages/EditPages';
import EditQuestions from '../EditQuestions/EditQuestions';
import { ContentWrapper, SaveBarContent } from './EditFeedbackForm.styled';

export default function EditFeedbackForm({
  data,
  setParentLoading,
  loading: dataLoading,
  isModal,
}) {
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(null);

  useEffect(() => {
    if (setParentLoading) {
      setParentLoading(loading);
    }
  }, [loading, setParentLoading]);

  const feedbackForm = useMemo(() => {
    if (!data) {
      return null;
    }

    return data.readOneFeedbackForm;
  }, [data]);

  useEffect(() => {
    // in case data changes
    if (activePage && feedbackForm) {
      setActivePage(feedbackForm?.Pages?.nodes[activePage.SortOrder]);
    }
    // eslint-disable-next-line
  }, [loading, data]);

  const editProps = {
    feedbackForm,
    loading,
    setLoading,
    activePage,
    setActivePage,
  };

  if (!feedbackForm) {
    if (dataLoading) {
      return <Loading />;
    }
    return <div>Something wrong with the form</div>;
  }

  return (
    <ContentWrapper>
      <EditDetails {...editProps} />
      <EditPages {...editProps} />
      <EditQuestions {...editProps} />
      {activePage && <AddNewQuestionButton {...editProps} />}

      {!isModal && (
        <Savebar>
          <SaveBarContent>
            {data && <ShareModal formData={data.readOneFeedbackForm} />}
            <AutosaveIndicator loading={loading} />
          </SaveBarContent>
        </Savebar>
      )}
    </ContentWrapper>
  );
}
