import styled, { css } from 'styled-components';

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border: var(--card-border, 1px solid #c3c3c3);
  padding: var(--content-padding, 16px);
  overflow: auto;
`;

export const Card = styled.div`
  display: flex;
  ${({ horizontal }) =>
    horizontal
      ? css`
          flex-direction: row;
          gap: var(--content-padding);
        `
      : css`
          flex-direction: column;
        `}
  flex: ${({ grow }) => (grow ? 1 : null)};
  align-items: stretch;
  border-radius: var(--card-border-radius, 24px);
  background-color: var(--card-bg-color, #fff);
  box-shadow: var(--card-box-shadow);
  padding: var(--content-padding);
`;

export const CardTitle = styled.h2`
  font-size: 1.4rem;
  margin-bottom: 0.8rem;
`;
