import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import GroupTabNav from '../components/GroupTabNav';

import { StyledPageContentInner } from '../components/StyledPage';
import WithGroupPageTemplate from '../HOCs/templates/WithGroupPageTemplate';
import PaginatedSimulationsList from '../components/PaginatedSimulationsList';
import { READ_SIMULATIONS_PAGINATED } from '../queries/SimulationQueries';

const GroupSimulationsPage = props => {
  const [pagination, setPagination] = useState({ limit: 12, page: 1 });
  return (
    <>
      <GroupTabNav groupID={props.data.readOneVirtiGroup.ID} />
      <StyledPageContentInner>
        <PaginatedSimulationsList
          dataQuery={READ_SIMULATIONS_PAGINATED}
          dataQueryVariables={{
            filter: {
              OrganisationGroups: {
                ID: { eq: props.data.readOneVirtiGroup.ID },
              },
            },
          }}
          queryName="readSimulations"
          paginationState={pagination}
          changePageCallback={(newPage) =>
            setPagination({ ...pagination, page: newPage })
          }
          userOrganisationID={props.userOrganisationID}
        />
      </StyledPageContentInner>
    </>
  );
}

GroupSimulationsPage.propTypes = {
  userOrganisationID: PropTypes.number.isRequired,
};

export default WithGroupPageTemplate(GroupSimulationsPage, 'group-simulations', false);