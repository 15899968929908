import styled, { css } from 'styled-components';

export const ItemTitle = styled.h5`
  text-align: center;
  align-self: flex-end;
  justify-self: center;
  font-size: 0.8rem;
  color: var(--tertiary-font-color);
  text-transform: uppercase;
`;

export const ItemContent = styled.div`
  ${({ theme: { fontWeight, spacing } }) => css`
    align-self: flex-start;
    justify-self: center;
    font-weight: ${fontWeight.partBold};

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    gap: ${spacing.xs};
  `}
`;

export const DataContainer = styled.div`
  flex: 1;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-around;
  gap: 6px;
  padding: 0.8rem 1rem;
  border-bottom: var(--primary-border);
`;
