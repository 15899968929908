import styled, { css } from 'styled-components';
import { TextLabelTypes } from './TextLabelUtil';

export const Container = styled.div`
  display: flex;
  font-family: var(--text-font);
`;

export const Text = styled.p`
  font-family: inherit;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '1rem')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'normal')};
  color: ${(props) => (props.color ? props.color : 'black')};
  font-size: 1rem;
  color: var(--pd-dark-text-color);
  ${(props) =>
    props.maxLines &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: ${props.maxLines}; /* number of lines to show */
      -webkit-box-orient: vertical;
    `}
  ${(props) =>
    props.textLabelType === TextLabelTypes.GREY_BOLD &&
    css`
      font-weight: bold;
      font-size: 0.7rem;
      color: var(--pd-dark-text-color);
    `}
    
  ${(props) =>
    props.textLabelType === TextLabelTypes.LARGE_DARK &&
    css`
      font-size: 1.3rem;
      font-weight: bold;
      color: var(--pd-dark-text-color);
    `}
  ${(props) =>
    props.textLabelType === TextLabelTypes.DARK_BOLD &&
    css`
      color: var(--pd-dark-text-color);
      font-size: 0.8rem;
      font-weight: bold;
    `}
    ${(props) =>
    props.textLabelType === TextLabelTypes.SMALL_LIGHT_GREY &&
    css`
      color: #989898;
      line-height: 1rem;
      font-size: 0.85rem;
    `}
    ${(props) =>
    props.textLabelType === TextLabelTypes.SMALL_WHITE &&
    css`
      color: #fff;
      line-height: 1rem;
      font-size: 0.8rem;
    `}
`;
