import React from 'react';
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';
import Icon from '../icons/Icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LinkWithPrevState from '../LinkWithPrevState';
import { Loading } from '@virtidev/toolbox';

const btnStyle = css`
  ${({ theme, type, bgColor, bgColorHover, disabled, noBg }) => css`
    background-color: ${bgColor ? bgColor : 'var(--primary-button-bg-color)'};
    color: var(--primary-button-text-color);
    padding: var(--primary-button-padding);
    display: inline-block;
    border-radius: var(--primary-button-border-radius);
    border: none;
    text-decoration: none;
    font-size: 1rem;
    width: auto;
    cursor: pointer;
    box-sizing: border-box;
    vertical-align: middle;
    ${type === 'primary' &&
    css`
      background-image: var(--primary-button-gradient);
      &:hover {
        background-image: var(--primary-button-gradient-hover);
      }
    `}
    ${type === 'new-primary' &&
    css`
      background-color: ${theme.color.secondary};
      color: #fff;
      &:hover {
        background-image: var(--primary-button-gradient-hover);
      }
    `}
  ${type === 'material-white' &&
    css`
      font-size: 0.9rem;
      padding: 1rem 0;
      background: var(--secondary-bg-color);
      background-color: var(--card-bg-color);
      box-shadow: var(--card-box-shadow);
      color: var(--material-button-text-color);
      font-size: 0.9rem;
      padding: 1rem 1.5rem;
    `}
  ${type === 'secondary' &&
    css`
      background-color: var(--secondary-button-bg-color);
      color: var(--secondary-button-text-color);
      border: var(--primary-border);
    `}
  ${type === 'secondary' &&
    disabled &&
    css`
      background-color: var(--secondary-button-bg-color-disabled);
      color: var(--secondary-button-text-color-disabled);
      box-shadow: none;
    `}
  ${type === 'tertiary' &&
    css`
      background-color: var(--tertiary-button-bg-color);
      color: var(--tertiary-button-text-color);
      box-shadow: 0 0 1px 0 var(--card-box-shadow-color);
    `}
  ${type === 'tertiary' &&
    disabled &&
    css`
      background-color: var(--tertiary-button-bg-color-disabled);
      color: var(--tertiary-button-text-color-disabled);
      box-shadow: none;
    `}
  ${type === 'material-grey' &&
    css`
      color: var(--material-grey-btn-color);
      background-color: var(--material-grey-btn-bg-color);
      &:hover {
        color: var(--material-grey-btn-color-hover);
      }
      &:active {
        background-color: var(--material-grey-btn-bg-color-active);
      }
    `}
  ${type === 'tertiary' &&
    disabled &&
    css`
      background-color: var(--material-grey-btn-bg-color-disabled);
      color: var(--material-grey-btn-text-color-disabled);
    `}
  ${type === 'gridded-box' &&
    css`
      background: none;
      border: none;
      border-radius: 0;
      &:first-child {
        border-right: var(--primary-border);
      }
      border-top: var(--primary-border);
      color: #7e8490;
      padding: 1.5rem 0;
    `}
  &:hover {
      background-color: ${bgColorHover
        ? bgColorHover
        : 'var(--primary-button-bg-color-hover)'};
      color: var(--primary-button-text-color-hover);
      ${type === 'secondary' &&
      css`
        background-color: var(--secondary-button-bg-color-hover);
        color: var(--secondary-button-text-color-hover);
      `}
      ${type === 'tertiary' &&
      css`
        background-color: var(--tertiary-button-bg-color-hover);
        color: var(--tertiary-button-text-color-hover);
      `}
    ${noBg &&
      css`
        text-decoration: underline;
      `}
    }
    &:active {
      ${type === 'primary' &&
      !noBg &&
      css`
        background-color: var(--primary-button-bg-color-active);
      `}
      ${type === 'secondary' &&
      !noBg &&
      css`
        background-color: var(--secondary-button-bg-color-active);
      `}
    ${type === 'tertiary' &&
      !noBg &&
      css`
        background-color: var(--tertiary-button-bg-color-active);
      `}
    }
    ${type === 'delete' &&
    css`
      background-color: var(--delete-button-bg-color);
      color: var(--delete-button-text-color);
      path[fill='#7a8ea2'] {
        fill: var(--delete-button-text-color);
      }
      &:hover {
        & {
          background-color: var(--delete-button-bg-color-hover);
        }
      }
      &:active {
        color: var(--delete-button-bg-color-active);
      }
    `}
    ${disabled &&
    css`
      background-color: var(--button-disabled-bg-color);
      color: var(--button-disabled-text-color);
      background-image: none;
      cursor: default;
      &:hover {
        background-image: none;
        background-color: var(--button-disabled-bg-color);
        color: var(--button-disabled-text-color);
      }
    `}
  ${noBg &&
    css`
      background: none;
      color: var(--primary-button-text-color-without-bg);
      &:hover {
        background: none;
        color: var(--primary-button-text-color-without-bg);
        span {
          text-decoration: underline;
        }
      }
    `}
  ${bgColor &&
    css`
      background-color: ${bgColor};
    `}
  ${type === 'link' &&
    css`
      background: none;
      color: var(--link-text-color);
      &:hover {
        & {
          background: none;
        }
        color: var(--link-text-color-hover);
        text-decoration: underline;
      }
      &:active {
        color: var(--link-text-color-active);
      }
    `}
  
  ${type === 'cancel' &&
    css`
      background-color: var(--cancel-button-bg-color);
      color: var(--cancel-button-text-color);
      &:hover {
        & {
          background-color: var(--cancel-button-bg-color-hover);
        }
      }
      &:active {
        color: var(--cancel-button-bg-color-active);
      }
    `}
  /*** note that disabled colouring has not been applied/checked yet for svg icons in non primary buttons */
  path[fill="#7a8ea2"] {
      fill: var(--primary-color);
      ${disabled &&
      css`
        fill: var(--button-disabled-text-color);
      `}
      ${type === 'delete' &&
      css`
        fill: var(--delete-button-text-color);
      `}
    }
    &:hover {
      path[fill='#7a8ea2'] {
        fill: #fff
          ${noBg &&
          css`
            fill: var(--primary-color-light);
          `}
          ${disabled &&
          css`
            fill: var(--button-disabled-text-color);
          `};
      }
    }
    ${type === 'tertiary' &&
    css`
      svg {
        width: 1rem;
        height: 1.3rem;
        position: relative;
        top: -1px;
      }
      path[fill='#7a8ea2'] {
        fill: #fff;
      }
      &:hover {
        path[fill='#7a8ea2'] {
          fill: #fff;
        }
      }
    `}
    ${type === 'material-grey' &&
    css`
      svg {
        position: relative;
        top: 4px;
      }
      path[fill='#7a8ea2'] {
        fill: var(--material-grey-btn-color);
      }
      &:hover {
        path[fill='#7a8ea2'] {
          fill: var(--material-grey-btn-color-hover);
        }
      }
    `}
  `}
`;
// necessary for consistent anchor/button styling
const StyledButtonContent = styled.div`
  ${({ type, flexType }) => css`
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: ${flexType};
    align-items: center;
    font-weight: 500;
    /* font-family: 'Open Sans'; */
    ${type === 'secondary' &&
    css`
      font-weight: 500;
    `}
  `}
`;

// this exists so that swapping the content with a loading spinner doesn't change the height of the button
const StyledButtonNonLoadingContent = styled.div`
  ${({ loading }) => css`
    align-items: center;
    justify-content: center;
    display: flex;
    flex: 1;
    ${loading &&
    css`
      opacity: 0;
    `}
  `}
`;

const StyledIconWrapper = styled.span`
  ${({ iconFontSize, childrenExist, iconOffsetY }) => css`
    position: relative;
    ${iconFontSize &&
    css`
      font-size: ${iconFontSize};
    `}
    ${childrenExist &&
    css`
      margin-right: 0.5rem;
    `}
  ${iconOffsetY &&
    css`
      top: ${iconOffsetY};
    `}
  `}
`;

const StyledButton = styled.button`
  ${btnStyle}
  ${({ loading }) => css`
    ${loading &&
    css`
      pointer-events: none;
    `}
  `}
`;

const StyledBtnLoaderWrapper = styled.div`
  position: absolute;
  left: 50%;
`;
const StyledBtnLoader = styled(Loading)`
  position: relative;
  left: -50%;
`;

const Button = ({
  children,
  id,
  onClick,
  type,
  disabled,
  icon,
  iconType,
  iconSize,
  iconFontSize,
  className,
  flexType,
  iconAllWhite,
  iconResizeWidth,
  iconResizeHeight,
  iconCustomWidth,
  iconCustomHeight,
  bgColor,
  noBg,
  loading,
  title,
  iconOffsetY,
  dataTestId,
  onMouseEnter,
  onMouseOut,
  ...props
}) => (
  <StyledButton
    {...props}
    id={id}
    data-testid={dataTestId}
    className={className}
    type={type}
    disabled={disabled}
    bgColor={bgColor}
    noBg={noBg}
    onMouseEnter={onMouseEnter}
    onMouseOut={onMouseOut}
    // have to do number instead of bool to avoid react dom/styled-component warnings
    loading={loading ? 1 : 0}
    title={title}
    onClick={(e) => {
      if (disabled) {
        e.preventDefault();
        return;
      }
      if (onClick) {
        onClick(e);
      }
    }}
  >
    {/** have to do number instead of bool to avoid react dom/styled-component warnings */}
    <StyledButtonContent type={type} flexType={flexType}>
      <StyledButtonNonLoadingContent loading={loading ? 1 : 0}>
        {icon && (
          <StyledIconWrapper
            iconOffsetY={iconOffsetY}
            childrenExist={!!children}
            iconFontSize={iconFontSize}
          >
            {iconType && iconType === 'fa' && (
              <FontAwesomeIcon style={{ fontSize: iconSize }} icon={icon} />
            )}
            {(!iconType || iconType !== 'fa') && (
              <Icon
                size={iconSize}
                resizeWidth={iconResizeWidth !== false}
                resizeHeight={iconResizeHeight !== false}
                customWidth={iconCustomWidth}
                customHeight={iconCustomHeight}
                allWhite={iconAllWhite !== false}
                type={iconType}
                name={icon}
              />
            )}
          </StyledIconWrapper>
        )}
        {children}
      </StyledButtonNonLoadingContent>
      {loading && (
        <StyledBtnLoaderWrapper>
          <StyledBtnLoader />
        </StyledBtnLoaderWrapper>
      )}
    </StyledButtonContent>
  </StyledButton>
);

Button.defaultProps = {
  type: 'primary',
  noBg: false,
  disabled: false,
  flexType: 'center',
  iconSize: 'small',
  iconFontSize: 'initial',
  loading: false,
  title: '',
  iconOffsetY: null,
};

Button.propTypes = {
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  flexType: PropTypes.string,
  iconSize: PropTypes.string,
  iconFontSize: PropTypes.string,
  iconCustomWidth: PropTypes.string,
  iconCustomHeight: PropTypes.string,
  bgColor: PropTypes.string,
  loading: PropTypes.bool,
  title: PropTypes.string,
  iconOffsetY: PropTypes.string,
};

const StyledButtonLink = styled(LinkWithPrevState)`
  ${btnStyle}
`;

export const StyledButtonLinkExternal = styled.a`
  ${btnStyle}
`;

const ButtonLink = ({
  children,
  id,
  type,
  to,
  disabled,
  onClick,
  icon,
  iconType,
  iconSize,
  iconFontSize,
  className,
  flexType,
  iconAllWhite,
  iconResizeWidth,
  iconResizeHeight,
  iconCustomWidth,
  iconCustomHeight,
  bgColor,
  loading,
  iconOffsetY,
  dataTestId,
  ...props
}) => (
  <StyledButtonLink
    id={id}
    data-testid={dataTestId}
    className={className}
    onClick={(e) => {
      if (disabled) {
        e.preventDefault();
        return;
      }
      if (onClick) {
        onClick(e);
      }
    }}
    disabled={disabled}
    type={type}
    to={to}
    bgColor={bgColor}
  >
    <StyledButtonContent type={type} flexType={flexType} {...props}>
      <StyledButtonNonLoadingContent loading={loading ? 1 : 0}>
        {icon && (
          <StyledIconWrapper
            iconOffsetY={iconOffsetY}
            childrenExist={!!children}
            iconFontSize={iconFontSize}
          >
            {iconType && iconType === 'fa' && <FontAwesomeIcon icon={icon} />}
            {(!iconType || iconType !== 'fa') && (
              <Icon
                size={iconSize}
                resizeWidth={iconResizeWidth !== false}
                resizeHeight={iconResizeHeight !== false}
                customWidth={iconCustomWidth}
                customHeight={iconCustomHeight}
                allWhite={iconAllWhite !== false}
                type={iconType}
                name={icon}
              />
            )}
          </StyledIconWrapper>
        )}
        {children}
      </StyledButtonNonLoadingContent>
      {loading && (
        <StyledBtnLoaderWrapper>
          <StyledBtnLoader />
        </StyledBtnLoaderWrapper>
      )}
    </StyledButtonContent>
  </StyledButtonLink>
);

ButtonLink.defaultProps = {
  type: 'primary',
  noBg: false,
  disabled: false,
  flexType: 'center',
  iconSize: 'small',
  iconFontSize: 'initial',
  loading: false,
  title: '',
  iconOffsetY: null,
};

ButtonLink.propTypes = {
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  flexType: PropTypes.string,
  iconSize: PropTypes.string,
  iconFontSize: PropTypes.string,
  iconCustomWidth: PropTypes.string,
  iconCustomHeight: PropTypes.string,
  to: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  iconOffsetY: PropTypes.string,
};

const StyledSubmitBtnWrapper = styled.div`
  margin-top: 2rem;
  text-align: right;
  padding: 1.5rem var(--content-side-padding) 0 var(--content-side-padding);
  /* > div {
    max-width: var(--form-max-width);
  } */
`;

const SubmitBtnWrapper = ({ children }) => (
  <StyledSubmitBtnWrapper>
    <div>{children}</div>
  </StyledSubmitBtnWrapper>
);

export default Button;
export { Button, ButtonLink, SubmitBtnWrapper };
