import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from '@virtidev/toolbox';
import { CircularButton } from '../QuestionActions.styled';

const StyledRemoveBtnWrapper = styled.div``;

function DeleteQuestion(props) {
  return (
    <StyledRemoveBtnWrapper className={props.className}>
      <CircularButton onClick={props.onClick} title="Delete Question">
        <Icon icon="bin" />
      </CircularButton>
    </StyledRemoveBtnWrapper>
  );
}

export default DeleteQuestion;
