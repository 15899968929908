const Breakpoints = {
  sidebarContractMaxWidth: '700px',
  sidebarContractWidthCheck: 150, // used to check whether to contract or expand the sidebar (below this width it will expand, above it will contract),
  xl: '1920px',
  large: '1200px',
  med: '992px',
  small: '768px',
};

export default Breakpoints;
