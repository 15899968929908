import { LoadingPlaceholder, MockImage, MockText } from '@virtidev/toolbox';
import styled from 'styled-components';

export const CourseLoadingPlaceholder = styled(LoadingPlaceholder)`
  display: flex;
  padding: 0;
  flex-direction: column;
`;

export const MockCourseImage = styled(MockImage)`
  height: 229px;
  width: 100%;
  margin: 0;
  border-radius: 0;
`;

export const MockTitle = styled(MockText)`
  height: 1.4rem;
  margin: 0;
`;
