import React from 'react';
import _ from 'lodash';
import { StyledDataBlocks } from '../../../../../styled-components/StyledDataBlocks';
import Activity from './components/Activity';
import Retention from './components/Retention';
import DailyActiveUsers from './components/DailyActiveUsers';
import ActivityByOrg from './components/ActivityByOrg/ActivityByOrg';
import MonthlyActiveUsers from './components/MonthlyActiveUsers';

export const fieldLabelMap = {
  DisableLeaderboard: 'Display Leaderboard',
};

const CXAnalytics = () => {
  return (
    <>
      <StyledDataBlocks>
        <Activity />
        <Retention />
      </StyledDataBlocks>
      <DailyActiveUsers />
      <MonthlyActiveUsers />
      <ActivityByOrg />
    </>
  );
};

export default CXAnalytics;
