import { useEffect, useMemo, useRef, useState, FC } from 'react';
import { createPortal } from 'react-dom';
import { SplashScreen } from '@virtidev/toolbox';
import {
  AnimatedContainer,
  StyledAnimation,
  ProgressBar,
  Bar,
} from './LoadingSplash.styled';

/**
 * @type {FC<{
 *    show?: boolean,
 *    immediate?: boolean,
 * }>}
 */
export const LoadingSplash = ({ show, immediate }) => {
  const [portalRef, setPortal] = useState();
  const [showProgress, setShowProgress] = useState(false);
  const [progressCount, setProgressCount] = useState(0);
  const timerRef = useRef();

  const progressDone = useMemo(() => {
    const progress = (Math.atan(progressCount) / (Math.PI / 2)) * 100;

    return show ? Math.min(progress, 99) : 100;
  }, [progressCount, show]);

  useEffect(() => {
    let portal = document.querySelector('#virti-loading-portal');

    if (!portal) {
      portal = document.createElement('div');
      portal.id = 'virti-loading-portal';
      portal.style.zIndex = 100;

      document.body.appendChild(portal);
    }
    setPortal(portal);
  }, []);

  useEffect(() => {
    if (!show) {
      return;
    }
    const timer = setTimeout(() => {
      setShowProgress(true);
    }, 2900);

    return () => clearTimeout(timer);
  }, [show]);

  useEffect(() => {
    if (!showProgress || progressDone >= 100) {
      return;
    }
    if (!show) {
      setProgressCount(100);
      return;
    }

    const timer = setTimeout(() => {
      if (progressDone < 60) {
        setProgressCount(progressCount + 0.1);
      } else {
        setProgressCount(progressCount + 0.5);
      }
    }, 100);

    return () => clearTimeout(timer);
  }, [progressCount, showProgress, progressDone, show]);

  useEffect(() => {
    if (show || !portalRef) {
      clearTimeout(timerRef.current);
      return;
    }

    if (immediate) {
      portalRef.remove();
      setPortal(null);
      return;
    }

    timerRef.current = setTimeout(() => {
      portalRef.remove();
      setPortal(null);
    }, 300);
  }, [show, immediate, portalRef]);

  if (!portalRef) {
    return null;
  }

  return createPortal(
    <AnimatedContainer show={show}>
      <StyledAnimation data={SplashScreen} />
      <ProgressBar show={showProgress}>
        <Bar style={{ width: `${progressDone}%` }} />
      </ProgressBar>
    </AnimatedContainer>,
    portalRef
  );
};

export default LoadingSplash;
