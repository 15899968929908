import { useApolloClient, useQuery, useReactiveVar } from '@apollo/client';
import {
  SIMULATION_AUTHOR,
  SIMULATION_AUTHOR_FRAGMENT,
} from './permissions.query';
import { canViewUserSideVirtualHumansVar } from '../../../../ApolloReactiveVars';
import useUser from './useUser';

export const useCanViewUserSideVirtualHumans = () => {
  const aiToken = localStorage.getItem('ai_token');
  const canView = useReactiveVar(canViewUserSideVirtualHumansVar);
  return !!aiToken && canView;
};

export const useSimulationAuthor = (simulationId, { skip }) => {
  const client = useApolloClient();
  let simulation = null;

  try {
    // cache policies library could crash if fragment expired
    simulation = client.cache.readFragment({
      id: `Simulation:${simulationId}`,
      fragment: SIMULATION_AUTHOR_FRAGMENT,
    });
  } catch (e) {}

  const { data, loading } = useQuery(SIMULATION_AUTHOR, {
    variables: {
      ID: simulationId,
    },
    skip: simulation || !simulationId || skip,
  });

  return {
    data: simulation || data?.readOneSimulation,
    loading,
  };
};

export const useCanEditVirtualHumans = () => {
  const { UserType } = useUser();
  const access = ['admin', 'super', 'customeradmin', 'contentcreator'].includes(
    UserType
  );
  return access;
};
