import { useState } from 'react';
import VHQuery from './VHQuery';
import getConfig from '../../lib/config';

const useCopyActionLibrary = ({ onError, onComplete }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const copyLibrary = async (
    fromOrgId,
    toOrgId,
    vhAPIEndpointOverride = undefined
  ) => {
    try {
      setIsLoading(true);
      const res = await VHQuery(
        `action-blueprints/copy-to-organisation?apiToken=${getConfig("REACT_APP_VH_DUPLICATE_LIBRARY_TOKEN")}`,
        'POST',
        {
          oldOrgId: fromOrgId,
          newOrgId: toOrgId,
        },
        vhAPIEndpointOverride
      );
      setIsLoading(false);
      if (onComplete) {
        onComplete(res);
      }
    } catch (err) {
      setError(err);
      setIsLoading(false);
      if (onError) {
        onError(err);
      }
    }
  };
  return { copyLibrary, isLoading, error };
};
export default useCopyActionLibrary;
