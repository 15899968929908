import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LinkWithPrevState from './LinkWithPrevState';
import { GetUnitCountFromCourse } from '../utility/SimulationHelpers';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { ButtonLink } from './buttons/Button';
import Breakpoints from '../themes/Breakpoints';
import { Datetime, FALLBACK, Image } from '@virtidev/toolbox';

const StyledItem = styled.div`
  border-radius: var(--card-border-radius);
  background-color: var(--card-bg-color);
  box-shadow: var(--card-box-shadow);
  position: relative;
  display: flex;
  flex-direction: column;
`;

const StyledImageLink = styled(LinkWithPrevState)`
  position: relative;
  ${'' /* min-height: 10.8rem; */}
  max-height: 12rem;
  overflow: hidden;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 229px;
  max-height: 229px;
  &:hover {
    opacity: var(--image-link-opacity-hover);
  }
  &:active {
    opacity: var(--image-link-opacity-active);
  }
`;

const StyledTitleWrapper = styled.div`
  padding: 1.3rem 2rem;
  font-size: 1.4rem;
  border-bottom: var(--primary-border);
`;
const StyledTitle = styled.h3`
  color: var(--primary-font-color);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
  }
`;
const StyledEnrolledWrapper = styled.div`
  svg {
    width: 31px;
    height: 31px;
    position: relative;
    left: -5px;
    bottom: 5px;
  }
  /* grid-row: 1 / 3;
  grid-column: 2; */
`;
// const StyledEnrolledWrapper = styled.div`
//   text-align: center;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 4rem;
//   width: 4rem;
//   border-radius: 2rem;
//   border: 0.4rem solid var(--high-avg-score-color);
//   cursor: default;
//   margin-top: 0.8rem;
//   margin-left: auto;
//   margin-right: auto;
// `

// TODO: redo this when images are in
const StyledThumbnail = styled(Image)`
  width: 100%;
  display: block;
  height: 229px;
  max-height: 229px;
  object-fit: cover;
  border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
`;

const StyledContent = styled.div`
  padding: 1rem;
  display: grid;
`;

const StyledDataWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;

const StyledDataHeader = styled.h5`
  text-align: center;
  font-size: 0.7rem;
  margin-bottom: 0.5rem;
  color: var(--tertiary-font-color);
  text-transform: uppercase;
`;

const StyledData = styled.div`
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
`;

const CourseList = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(3, calc(33.33333333% - 1.4rem));
  @media (max-width: ${Breakpoints.large}) {
    grid-template-columns: repeat(2, calc(50% - 1.4rem));
  }
  @media (max-width: ${Breakpoints.med}) {
    grid-template-columns: 1fr;
  }
  grid-gap: 2rem;
`;

const Subtitle = styled.small`
  font-size: 0.7rem;
  color: #333;
`;

const DataWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
`;
const EnrolledWrapper = styled.div`
  height: 1.2rem;
  width: 1.2rem;
  margin-left: auto;
  margin-right: auto;
`;
const StyledLinksWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  > a:nth-child(1) {
    border-radius: 0 0 0 var(--card-border-radius);
  }
  > a:nth-child(2) {
    border-radius: 0 0 var(--card-border-radius) 0;
  }
`;
const StyledLastEdited = styled.div`
  color: var(--tertiary-font-color);
  margin-top: 0.7rem;
  font-size: 0.8rem;
`;

const DataItem = (props) => (
  <div>
    <StyledDataHeader>{props.title}</StyledDataHeader>
    <StyledData>{props.data}</StyledData>
  </div>
);
const CourseItem = (props) => {
  const published = props.data.Status === 'Published';

  return (
    <StyledItem>
      <StyledImageLink to={`/courses/${props.data.ID}`}>
        <StyledThumbnail
          src={props.data?.ImageMedia?.URL || FALLBACK}
          alt={props.data.Title || ''}
        />
      </StyledImageLink>
      <StyledTitleWrapper>
        <LinkWithPrevState to={`/courses/${props.data.ID}`}>
          <StyledTitle>
            {props.data.Title ? props.data.Title : 'Untitled Course'}
            {!published && <Subtitle>(Unpublished)</Subtitle>}
          </StyledTitle>
        </LinkWithPrevState>
        {!!props.data.LastEdited && (
          <StyledLastEdited>
            Last Edited on{' '}
            <Datetime datetime={props.data.LastEdited} long noTimeDisplay />
          </StyledLastEdited>
        )}
      </StyledTitleWrapper>
      <StyledContent>
        <StyledDataWrapper>
          <DataItem title="Users" data={props.data.TotalStudents} />
          <DataItem
            title="Simulations"
            data={GetUnitCountFromCourse(props.data)}
          />
          <DataItem title="Completions" data={props.data.TotalCompletions} />
          <StyledEnrolledWrapper>
            <StyledDataHeader>Enrolled</StyledDataHeader>
            <EnrolledWrapper>
              <CircularProgressbar
                value={
                  (props.data.TotalSuccessful / props.data.TotalStudents) * 100
                }
                strokeWidth={26}
                styles={buildStyles({
                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: 'butt',
                  textSize: '1.5rem',
                  // Colors
                  pathColor: 'var(--primary-color)',
                  trailColor: 'var(--primary-color-lighter)',
                })}
              />
            </EnrolledWrapper>
          </StyledEnrolledWrapper>
        </StyledDataWrapper>
      </StyledContent>
      <StyledLinksWrapper>
        <ButtonLink
          dataTestId="course-link"
          type="gridded-box"
          to={`/courses/${props.data.ID}`}
          iconType="fa"
        >
          Edit
        </ButtonLink>
        <ButtonLink
          type="gridded-box"
          to={`/courses/${props.data.ID}/analytics`}
          iconType="fa"
        >
          Analytics
        </ButtonLink>
      </StyledLinksWrapper>
    </StyledItem>
  );
};

CourseItem.propTypes = {
  data: PropTypes.shape({
    ID: PropTypes.string,
    Title: PropTypes.string,
    Description: PropTypes.string,
  }),
};

export { CourseList };
export default CourseItem;
