import { gql } from '@apollo/client';

export const DELETE_SELF = gql`
  mutation deleteSelf($Reason: String) {
    deleteSelf(Reason: $Reason) {
      ID
      DeleteRequestID
    }
  }
`;
