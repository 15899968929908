import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import StyledLabel from './StyledLabel';
import HelpTooltip from '../components/HelpTooltip';
import CharLengthIndicator from '../components/CharLengthIndicator';

const StyledInputWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  ${(props) =>
    props.inline &&
    css`
      display: inline;
    `}
  ${(props) =>
    props.labelPosition === 'left' &&
    css`
      label {
        margin-bottom: 0;
        margin-right: 1rem;
      }
    `}
`;
const StyledInnerWrapper = styled.div`
  display: flex;
  ${(props) =>
    props.fillWidth &&
    css`
      flex: 1;
    `}
`;

const StyledRangeText = styled.div`
  position: absolute;
  right: 0;
  top: 2.1rem;
  cursor: default;
  max-width: var(--range-input-text-width);
  overflow: hidden;
`;

const StyledInput = styled.input`
  padding: var(--secondary-button-padding);
  border-radius: var(--secondary-button-border-radius);
  font-size: 0.9rem;
  font-family: var(--text-font);
  border: var(--input-border);
  height: var(--input-height);
  color: var(--text-input-text-color);
  && {
    // overrides global style that's being applied somewhere :/ (possibly virtual human related)
    background-color: var(--text-input-bg-color);
  }
  ${(props) =>
    props.white &&
    css`
      && {
        // overrides global style that's being applied somewhere :/ (possibly virtual human related)
        background-color: #fff;
      }
    `}
  border-radius: 4px;
  ::placeholder {
    color: var(--text-input-placeholder-text-color);
  }
  width: 100%;
  ~ .character-counter {
    opacity: 0;
    visibility: hidden;
  }
  &:focus {
    outline: var(--input-focus-color) auto;
    ~ .character-counter {
      opacity: 1;
      visibility: initial;
    }
  }
  ${(props) =>
    props.styleType === 'title' &&
    css`
      background: none;
      border: none;
      border-bottom: 1px solid #eaeaea;
      border-radius: 0;
      font-size: 1.5rem;
      padding: 0 0.1rem;
      height: 2.5rem;
    `}
  ${(props) =>
    props.styleType === 'alt' &&
    css`
      && {
        // overrides global style that's being applied somewhere :/ (possibly virtual human related)
        background-color: var(--input-alt-bg-color);
      }
      border: var(--input-alt-border);
      border-radius: var(--input-alt-border-radius);
      ::placeholder {
        color: var(--input-alt-placeholder-text-color);
      }
    `}
  ${(props) =>
    props.type === 'number' &&
    css`
      padding-right: 10px;
    `}
  ${(props) =>
    props.filterStyle &&
    css`
      color: var(--filter-button-text-color);
      background: var(--filter-button-bg);
    `}
  ${(props) =>
    props.invalid &&
    css`
      border-color: var(--validation-error-input-border-color);
    `}
  ${(props) =>
    props.align &&
    css`
      text-align: ${props.align};
    `}
  ${(props) =>
    props.disabled &&
    css`
      && {
        // overrides global style that's being applied somewhere :/ (possibly virtual human related)
        background-color: var(--disabled-form-input-bg-color);
      }
    `}
  ${(props) =>
    props.charCounterRendered &&
    css`
      margin-right: var(--char-counter-input-margin-right);
    `}
  ${(props) =>
    props.type === 'range' &&
    css`
      margin-right: var(--range-input-margin-right);
      background-color: var(--input-bg-color);
      ${(props) =>
        props.reversedRange &&
        css`
          transform: rotate(180deg);
        `}
    `}
`;

const StyledUnderText = styled.div`
  position: absolute;
  bottom: -1rem;
  right: 0;
  font-size: 0.8rem;
  margin-right: 0;
  color: var(--tertiary-font-color);
`;

const StyledTextInput = React.forwardRef((props, ref) => {
  const charCounterRendered = props.showCharCounter && props.maxLength;
  const [val, setVal] = useState(props.value);

  useEffect(() => {
    setVal(props.value);
  }, [props.value]);

  const updateVal = (e) => {
    /* Make update synchronous, to avoid caret jumping when the value doesn't change asynchronously */
    setVal(e.target.value);
    /* Make the real update afterwards */
    if (props.type === 'number') {
      if (typeof props.min === 'number' && e.target.value < props.min) {
        e.target.value = props.min;
      }
      if (typeof props.max === 'number' && e.target.value > props.max) {
        e.target.value = props.max;
      }
    }
    props.onChange(e);
  };

  return (
    <StyledInputWrapper
      inline={props.inline}
      labelPosition={props.labelPosition}
      className={props.className}
    >
      {props.label && (
        <StyledLabel
          bold={props.labelBold}
          htmlFor={props.id}
          tooltipSide={props.labelTooltipSide}
          tooltip={props.labelTooltip}
        >
          {props.label}
        </StyledLabel>
      )}
      <StyledInnerWrapper fillWidth={props.fillWidth}>
        <StyledInput
          draggable={true}
          onDragStart={(event) => event.preventDefault()}
          styleType={props.styleType}
          reversedRange={props.reversedRange}
          data-testid={props.dataTestId}
          white={props.white}
          disabled={props.disabled}
          invalid={props.invalid}
          type={props.type}
          id={props.id}
          onChange={updateVal}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          onKeyPress={props.onKeyPress}
          placeholder={props.placeholder}
          value={val}
          filterStyle={props.filterStyle}
          required={props.required}
          align={props.align}
          maxLength={props.maxLength}
          charCounterRendered={charCounterRendered}
          step={props.step}
          ref={ref}
          min={props.min}
          max={props.max}
          name={props.name}
          autoFocus={props.autoFocus}
        />
        {props.tooltip && (
          <HelpTooltip text={props.tooltip} side={props.tooltipSide} />
        )}
        {props.type === 'range' && (
          <StyledRangeText>
            {props.customRangeTextFormatFunc
              ? props.customRangeTextFormatFunc(props.value)
              : props.value}
          </StyledRangeText>
        )}
        {charCounterRendered && (
          <CharLengthIndicator
            className="character-counter"
            minLength={props.minLength}
            maxLength={props.maxLength}
            currentLength={props.value ? props.value.length : 0}
            optimumLength={props.optimumLength || props.maxLength / 4}
          />
        )}
        {props.underText && (
          <StyledUnderText>{props.underText}</StyledUnderText>
        )}
      </StyledInnerWrapper>
    </StyledInputWrapper>
  );
});

StyledTextInput.defaultProps = {
  placeholder: '',
  value: '',
  type: 'text',
  invalid: false,
  fillWidth: false,
  labelPosition: 'top',
  inline: false,
  required: false,
  disabled: false,
  labelBold: true,
  minLength: 0,
  showCharCounter: true,
};

StyledTextInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  type: PropTypes.string,
  invalid: PropTypes.bool,
  fillWidth: PropTypes.bool,
  labelPosition: PropTypes.string,
  inline: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  labelBold: PropTypes.bool,
  tooltip: PropTypes.string,
  tooltipSide: PropTypes.string,
  align: PropTypes.string,
  labelTooltip: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  showCharCounter: PropTypes.bool,
  underText: PropTypes.string,
  dataTestId: PropTypes.string,
  labelTooltipSide: PropTypes.string,
  step: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  reversedRange: PropTypes.bool,
  white: PropTypes.bool,
};

StyledTextInput.displayName = 'StyledTextInput';

export default StyledTextInput;
