import { useMutation } from '@apollo/client';
import { Button, LoadingPlaceholder } from '@virtidev/toolbox';
import React, { useCallback, useMemo, useState } from 'react';
import WithConfirmationBox from '@base/HOCs/WithConfirmationBox';
import {
  S_td,
  S_tdLeft,
  S_thLeft,
  S_tr,
} from '@base/styled-components/StyledTable';
import { useLoginAsUser } from '@base/utility/LoginUtility';
import { GetPercentColors } from '@base/utility/PercentUtility';
import useFlashMessage from '@core/components/FlashMessage';
import { getTrialRemainder } from '@core/components/Signup/legacy/helpers/getTrialRemainder';
import { UPDATE_ORGANISATION } from './SignupUsage.query';

function TableRow({
  ID,
  name,
  created,
  signupType,
  member,
  expiry,
  confirm,
  refetchQueries,
}) {
  const { loginAsUser, loading: loadingToken } = useLoginAsUser();
  const [expiryFormat, setExpiryFormat] = useState(false);
  const [updating, setUpdating] = useState(false); //loading for editing the org

  const handleExpiryDateClick = useCallback(() => {
    setExpiryFormat(!expiryFormat);
  }, [expiryFormat]);

  const onLoginClick = (ID) => {
    loginAsUser(ID);
  };

  const { addFlashMessage } = useFlashMessage();

  const contactedSales = useMemo(() => {
    if (!member?.OnboardProgress) return 'No';
    const parsed = JSON.parse(member.OnboardProgress);

    if (parsed?.welcomeProgress?.contactedSales) {
      return 'Yes';
    } else {
      return 'No';
    }
  }, [member?.OnboardProgress]);

  const remainder = useMemo(() => {
    if (expiry) {
      return getTrialRemainder(expiry);
    }
  }, [expiry]);

  const [updateOrganisation, { loading }] = useMutation(UPDATE_ORGANISATION, {
    onCompleted: () => addFlashMessage('Organisation updated!'),
  });

  const archiveOrganisation = useCallback(() => {
    confirm(
      () => {
        setUpdating(true);
        updateOrganisation({
          variables: {
            Input: {
              ID,
              Archived: true,
            },
          },
          refetchQueries,
        });
      },
      null,
      'Are you sure you want to archive this organisation? This can only be undone via the Silverstripe CMS.',
      'delete'
    );
  }, [ID, updateOrganisation, confirm, refetchQueries]);

  return (
    <S_tr key={ID}>
      {updating ? (
        [...Array(10)].map((item, index) => {
          return (
            <S_thLeft key={index + 'dataitem'}>
              <LoadingPlaceholder />
            </S_thLeft>
          );
        })
      ) : (
        <>
          <S_thLeft>{name}</S_thLeft>
          <S_tdLeft>{created}</S_tdLeft>
          <S_td>{signupType || '–'}</S_td>

          <S_td
            title={expiry}
            style={{
              color: GetPercentColors(remainder, true, 1, 4),
              cursor: 'pointer',
            }}
            onClick={handleExpiryDateClick}
          >
            {!expiryFormat ? (remainder <= 0 ? 'Expired' : remainder) : expiry}
          </S_td>

          <S_tdLeft>{member?.Name ?? 'N/A'}</S_tdLeft>

          <S_td
            style={{
              color: GetPercentColors(
                member?.WelcomeCourseProgress ?? 0,
                true,
                1,
                99
              ),
            }}
          >
            {member?.WelcomeCourseProgress !== undefined
              ? `${member.WelcomeCourseProgress}%`
              : 'N/A'}
          </S_td>
          <S_td>{contactedSales}</S_td>

          <S_td>
            {member && (
              <Button
                onClick={() => {
                  onLoginClick(member?.ID);
                }}
                style={{ borderRadius: '24px' }}
                disabled={loadingToken || loading}
              >
                Login
              </Button>
            )}
          </S_td>
          <S_td>
            <Button
              style={{
                borderRadius: '24px',
                background: 'var(--secondary-color)',
              }}
              type="link"
              to={`/cx-dashboard/organisations?viewingOrgID=${ID}`}
              disabled={loading}
            >
              View
            </Button>
          </S_td>
          <S_td>
            <Button
              style={{
                borderRadius: '24px',
                background: 'var(--negative-color)',
              }}
              onClick={archiveOrganisation}
              disabled={loading}
            >
              Archive
            </Button>
          </S_td>
        </>
      )}
    </S_tr>
  );
}

export default WithConfirmationBox(TableRow);
