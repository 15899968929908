import { gql } from '@apollo/client';

export const CREATE_RESOURCE = gql`
  mutation createResource($Input: CreateResourceInput!) {
    createResource(input: $Input) {
      ID
      Title
      Type
      Course {
        ID
        Resources {
          edges {
            node {
              ID
            }
          }
        }
      }
    }
  }
`;

export const READ_COURSE_RESOURCES = gql`
  query readOneCourse($ID: ID!) {
    readOneCourse(filter: { ID: { eq: $ID } }) {
      ID
      Resources {
        edges {
          node {
            ID
            Title
            Type
          }
        }
      }
    }
  }
`;
