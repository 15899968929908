import { gql } from '@apollo/client';

export const COPY_SIMULATION = gql`
  mutation copySimulation($simulationID: ID!, $organisationID: ID!) {
    copySimulation(
      SimulationID: $simulationID
      OrganisationID: $organisationID
    ) {
      ID
    }
  }
`;
