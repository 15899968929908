import styled, { css } from 'styled-components';
import { Icon, TextInput } from '@virtidev/toolbox';
import ToggleSwitch from '../../../form/ToggleSwitch/ToggleSwitch';

export const UnitContainer = styled.div`
  position: relative;
  margin-right: var(--module-action-gap);
  padding-bottom: 16px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 16px;
  height: 50px;
  align-items: center;
  padding: 0 16px;
  border: 1px solid #c3c3c3;
  border-radius: var(--accordion-border-radius);
  background: ${({ dragging }) =>
    dragging ? 'var(--neutral-color-lighter)' : '#fff'};
  position: relative;
  z-index: 1;

  &:hover {
    background: var(--primary-color-lighter);
  }
`;

export const TitleContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 16px;
  align-self: stretch;
  align-items: center;
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`;

export const TitleText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 150px;
  line-height: 1.5;
  flex: 1;
`;

export const UnitContent = styled.div`
  ${({ opened }) =>
    opened
      ? css`
          overflow: auto;
          max-height: 200px;
          padding: 16px 10px 10px;
        `
      : css`
          overflow: hidden;
          max-height: 0;
          padding: 0 10px;
        `}
  transition: all 0.3s;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border: 1px solid #c3c3c3;
  margin-top: calc(-1 * var(--accordion-border-radius));
  margin-bottom: var(--accordion-border-radius);
  border-radius: 0 0 var(--accordion-border-radius)
    var(--accordion-border-radius);
  z-index: 0;
`;

export const AssessmentToggle = styled(ToggleSwitch)`
  flex: 1;
`;

export const PassInput = styled(TextInput).attrs({
  suffix: '%',
})`
  ${({ theme: { spacing } }) => css`
    && {
      width: 4.5rem;

      .input {
        text-align: end;
        padding: ${spacing.xs} 0 ${spacing.xs} ${spacing.xs};
      }

      .suffix {
        padding: ${spacing.xs};
      }
    }
  `}
`;

export const DragIcon = styled(Icon)`
  cursor: ${({ dragging }) => (dragging ? 'grabbing' : 'grab')};
`;

export const DropUnitArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  border: 2px dashed #cccccc;
  border-radius: var(--accordion-border-radius);
  background: #fff;
  ${({ top }) =>
    top
      ? css`
          margin-bottom: 14px;
        `
      : css`
          margin-top: 20px;
        `}
`;
