import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Styled from './ScreenCapturePage.styled';
import styled from 'styled-components';
import { withApollo } from '@apollo/client/react/hoc';

import StyledPage, {
  PageContent,
  StyledPageContentInner,
  StyledTopArea,
} from '../../../../../components/StyledPage';
import MediaTabNav from '../../../../../components/MediaTabNav';
import StyledPageHeader from '../../../../../styled-components/StyledPageHeader';
import RedirectWithPrevState from '../../../../../components/RedirectWithPrevState';
import withFlashMessaging from '../../../../../HOCs/WithFlashMessaging';
import withUser from '../../../../../HOCs/WithUser';
import Button from '../../../../../components/buttons/Button';
import { useAnimationFrame } from '../../../../../utility/AnimationHooks';
import { useEffectOnlyOnce } from '../../../../../utility/CustomHooks';
import WithConfirmationBox from '../../../../../HOCs/WithConfirmationBox';
import InputDeviceSelect from '../../components/ScreenCapture/InputDeviceSelect/InputDeviceSelect';
import VideoResult from '../../components/ScreenCapture/VideoResult/VideoResult';
import { Icon, ToggleSwitch } from '@virtidev/toolbox';
import NavigationPromptManual from '../../../../../components/NavigationPromptManual';

const StyledStyledTopArea = styled(StyledTopArea)`
  > div {
    * {
      margin-left: initial;
    }
    > div > div {
      margin-left: 1rem;
    }
  }
`;
const ScreenCapturePage = (props) => {
  // const [uploading, setUploading] = useState(false);
  const [showWebcam, setShowWebcam] = useState(true);
  const [micEnabled, setMicEnabled] = useState(true);
  const [startRecordTime, setStartRecordTime] = useState(0);
  const [endRecordTime, setEndRecordTime] = useState(0);
  const [noiseSuppression, setNoiseSuppression] = useState(true);
  const [echoCancellation, setEchoCancellation] = useState(true);
  const [capturing, setCapturing] = useState(false);
  const capturingRef = useRef(false);
  const [recording, setRecording] = useState(false);
  const [audioInputDeviceId, setAudioInputDeviceId] = useState('');
  const [videoInputDeviceId, setVideoInputDeviceId] = useState('');
  var [blob, setBlob] = useState(null);
  var [chunks, setChunks] = useState(null);
  const chunksRef = useRef([]);
  const canvasRef = useRef();
  const screenCaptureRef = useRef(null);
  const userMediaCaptureRef = useRef(null);
  const cobwebRef = useRef(null);
  const pumpkinRef = useRef(null);
  const ghostRef = useRef(null);

  // cleanup - had to add a capturingRef to access streams in the cleanup
  useEffectOnlyOnce(() => {
    return () => {
      stopAllStreams();
      handleStopCapture();
    };
  });

  useEffect(() => {
    capturingRef.current = capturing;
  }, [capturing]);

  const stopStreamTracks = useCallback((stream) => {
    if (!stream) return;
    stream.getTracks().forEach((track) => track.stop());
  }, []);

  const stopAllStreams = useCallback(() => {
    stopStreamTracks(capturingRef.current?.userMedia);
    stopStreamTracks(capturingRef.current?.userMediaCaptureVideoOnlyStream);
    stopStreamTracks(capturingRef.current?.screen);
    stopStreamTracks(capturingRef.current?.screenCaptureVideoOnlyStream);
    stopStreamTracks(capturingRef.current?.canvas);
  }, [capturingRef, stopStreamTracks]);

  const stopElemCapture = useCallback((videoElem) => {
    if (videoElem?.srcObject) {
      let tracks = videoElem.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
      videoElem.srcObject = null;
    }
  }, []);

  const handleStopCapture = useCallback(() => {
    stopElemCapture(screenCaptureRef.current);
    stopElemCapture(userMediaCaptureRef.current);
    console.log('stop capture');
    if (canvasRef.current) {
      const context = canvasRef.current.getContext('2d');
      context.clearRect(
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );
    }
    stopAllStreams();
    setCapturing(false);
  }, [stopElemCapture, stopAllStreams]);

  const updateUserInputMedia = async (
    videoDeviceId = null,
    audioDeviceId = null
  ) => {
    if (!capturing) return;
    const userMedia = await setupUserMedia(videoDeviceId, audioDeviceId);
    setCapturing({
      ...capturing,
      ...userMedia,
    });
  };

  const getVideoConstraints = useCallback(() => {
    return {
      video: true,
      audio: {
        echoCancellation,
        noiseSuppression,
        sampleRate: 44100,
      },
    };
  }, [echoCancellation, noiseSuppression]);

  const setupUserMedia = useCallback(
    // optionally accept deviceIds to override local state (used to avoid race condition where ids not yet set when this function called)
    async (videoDeviceId = null, audioDeviceId = null) => {
      stopStreamTracks(capturing?.userMedia);
      stopStreamTracks(capturing?.userMediaCaptureVideoOnlyStream);
      let userMediaCaptureStream = null;
      let userMediaCaptureVideoOnlyStream;
      userMediaCaptureStream = await navigator.mediaDevices.getUserMedia({
        ...getVideoConstraints(),
        video: { deviceId: videoDeviceId ?? videoInputDeviceId },
        audio: { deviceId: audioDeviceId ?? audioInputDeviceId },
      });
      if (showWebcam && videoDeviceId !== '') {
        userMediaCaptureVideoOnlyStream = new MediaStream();
        userMediaCaptureStream.getTracks().forEach((track) => {
          if (track.kind === 'video') {
            userMediaCaptureVideoOnlyStream.addTrack(track);
          }
        });
        userMediaCaptureRef.current.srcObject = userMediaCaptureVideoOnlyStream;
      }
      return {
        userMedia: userMediaCaptureStream,
        userMediaCaptureVideoOnlyStream,
      };
    },
    [
      audioInputDeviceId,
      getVideoConstraints,
      showWebcam,
      videoInputDeviceId,
      capturing.userMedia,
      capturing.userMediaCaptureVideoOnlyStream,
      stopStreamTracks,
    ]
  );

  const handleCapture = useCallback(async () => {
    let screenCaptureStream;
    if (!screenCaptureRef.current) return;
    try {
      screenCaptureStream = await navigator.mediaDevices.getDisplayMedia(
        getVideoConstraints()
      );
      screenCaptureStream.oninactive = handleStopCapture;
    } catch (err) {
      console.error('Error: ' + err);
      // possible permission denied
      return;
    }
    // we don't want to capture the audio into the <video> (we want to use it later in final stream combination)
    let screenCaptureVideoOnlyStream = new MediaStream();
    screenCaptureStream.getTracks().forEach((track) => {
      if (track.kind === 'video') {
        screenCaptureVideoOnlyStream.addTrack(track);
      }
    });
    screenCaptureRef.current.srcObject = screenCaptureVideoOnlyStream;

    const userMedia = await setupUserMedia();

    const canvasCaptureStream = canvasRef.current.captureStream();
    setCapturing({
      screen: screenCaptureStream,
      screenCaptureVideoOnlyStream,
      canvas: canvasCaptureStream,
      ...userMedia,
    });
  }, [handleStopCapture, getVideoConstraints, setupUserMedia]);

  // paint canvas
  const timeRef = useRef(0);
  useAnimationFrame((delta) => {
    if (!canvasRef.current) return;
    const context = canvasRef.current.getContext('2d');
    timeRef.current += delta;
    if (capturing?.screen) {
      if (
        screenCaptureRef.current.readyState ===
        screenCaptureRef.current.HAVE_ENOUGH_DATA
      ) {
        const divided =
          screenCaptureRef.current.videoWidth /
          screenCaptureRef.current.videoHeight;
        let comparedTo16by9 = false;
        if (Math.abs(divided - 16 / 9) > 0.05) {
          if (divided > 16 / 9) {
            // screensharing is wider than 16:9
            canvasRef.current.width = screenCaptureRef.current.videoWidth;
            canvasRef.current.height =
              (screenCaptureRef.current.videoWidth / 16) * 9;
            comparedTo16by9 = 'wider';
          } else {
            // screensharing is narrower than 16:9
            canvasRef.current.width =
              (screenCaptureRef.current.videoHeight / 9) * 16;
            canvasRef.current.height = screenCaptureRef.current.videoHeight;
            comparedTo16by9 = 'narrower';
          }
        } else {
          canvasRef.current.width = screenCaptureRef.current.videoWidth;
          canvasRef.current.height = screenCaptureRef.current.videoHeight;
        }
        context.drawImage(
          screenCaptureRef.current,
          comparedTo16by9 === 'narrower'
            ? Math.abs(
                canvasRef.current.width - screenCaptureRef.current.videoWidth
              ) / 2
            : 0,
          comparedTo16by9 === 'wider'
            ? Math.abs(
                canvasRef.current.height - screenCaptureRef.current.videoHeight
              ) / 2
            : 0,
          screenCaptureRef.current.videoWidth,
          screenCaptureRef.current.videoHeight
        );
      }
    }
    if (capturing?.userMedia && showWebcam && videoInputDeviceId !== '') {
      const webcamWidth = userMediaCaptureRef.current.videoWidth / 2;
      const webcamHeight = userMediaCaptureRef.current.videoHeight / 2;
      const webcamOffset = 32;
      context.drawImage(
        userMediaCaptureRef.current,
        canvasRef.current.width - webcamWidth - webcamOffset,
        canvasRef.current.height - webcamHeight - webcamOffset,
        webcamWidth,
        webcamHeight
      );
      context.filter = 'none';
    }
  });
  const handleRecord = () => {
    if (!capturing.canvas) {
      return;
    }
    // combine final streams to combine the canvas video with all captured audio (from screen share and from user media)
    let finalStream = new MediaStream(capturing.canvas);

    // setup audio context so that we can merge two different audio tracks (merging as two audio tracks doesn't work, need to pass in as one track)
    const audioContext = new AudioContext();
    // plug in system audio if it exists
    let systemAudio;
    capturing.screen.getTracks().forEach((track) => {
      if (track.kind === 'audio') {
        systemAudio = audioContext.createMediaStreamSource(capturing.screen);
      }
    });
    let micAudio;
    // don't use mic audio if no mic selected and mic enabled
    if (audioInputDeviceId !== '' && micEnabled) {
      capturing.userMedia.getTracks().forEach((track) => {
        if (track.kind === 'audio') {
          micAudio = audioContext.createMediaStreamSource(capturing.userMedia);
        }
      });
    }

    var dest = audioContext.createMediaStreamDestination();
    if (systemAudio) {
      systemAudio.connect(dest);
    }
    if (micAudio) {
      micAudio.connect(dest);
    }
    // only add audio track if audio exists
    if (micAudio || systemAudio) {
      finalStream.addTrack(dest.stream.getTracks()[0]);
    }

    const mediaRecorder = new MediaRecorder(finalStream, {
      audioBitsPerSecond: 128000,
      videoBitsPerSecond: 2500000,
      mimeType: 'video/webm',
    });
    setStartRecordTime(new Date().getTime());
    mediaRecorder.start();
    chunksRef.current = [];
    mediaRecorder.ondataavailable = function (e) {
      chunksRef.current.push(e.data);
    };
    mediaRecorder.onstop = function (e) {
      setEndRecordTime(new Date().getTime());
      const blob = new Blob(chunksRef.current, { type: 'video/webm' });
      setBlob(blob);
      setChunks(chunksRef.current);
      chunksRef.current = [];
    };
    setRecording(mediaRecorder);
  };
  const handleStopRecord = () => {
    recording.stop();
    setRecording(false);
  };

  const handleAudioInputChange = (deviceId) => {
    setAudioInputDeviceId(deviceId);
    updateUserInputMedia(null, deviceId);
  };
  const handleVideoInputChange = (deviceId) => {
    setVideoInputDeviceId(deviceId);
    updateUserInputMedia(deviceId, null);
  };

  if (!props.userID) {
    return <RedirectWithPrevState to="/login" />;
  }
  return (
    <StyledPage pageKey="screen-capture">
      <Styled.HalloweenImage
        ref={cobwebRef}
        src="/images/halloween/cobweb.png"
      />
      <Styled.HalloweenImage
        ref={pumpkinRef}
        src="/images/halloween/pumpkin.png"
      />
      <Styled.HalloweenImage ref={ghostRef} src="/images/halloween/ghost.png" />
      <NavigationPromptManual
        when={!!blob}
        message="You have an unsaved video, are you sure you want to leave?"
      />
      <StyledPageContentInner flex="initial">
        <StyledStyledTopArea gridCols="40% 1fr">
          <StyledPageHeader>Media</StyledPageHeader>
        </StyledStyledTopArea>
        <MediaTabNav />
        <PageContent>
          <Styled.ScreenCaptureArea>
            <Styled.TogglesArea>
              <Styled.InputDeviceSelects>
                <InputDeviceSelect
                  disabled={!!recording}
                  onChange={handleAudioInputChange}
                  kind="audioinput"
                />
                <InputDeviceSelect
                  disabled={!!recording}
                  onChange={handleVideoInputChange}
                  kind="videoinput"
                />
              </Styled.InputDeviceSelects>

              <ToggleSwitch
                disabled={!!recording}
                label="Record Webcam"
                name="show-webcam"
                value={showWebcam}
                onChange={(e) => setShowWebcam(e.target.checked)}
              />
              <ToggleSwitch
                disabled={!!recording}
                label="Record Microphone"
                name="mic-enabled"
                value={micEnabled}
                onChange={(e) => setMicEnabled(e.target.checked)}
              />
              {/* <ToggleSwitch
                disabled={!!recording}
                label="Noise Suppression"
                name="noise-suppression"
                value={noiseSuppression}
                onChange={(e) => setNoiseSuppression(e.target.checked)}
              />
              <ToggleSwitch
                disabled={!!recording}
                label="Echo Cancellation"
                name="echo-cancellation"
                value={echoCancellation}
                onChange={(e) => setEchoCancellation(e.target.checked)}
              /> */}
            </Styled.TogglesArea>
            <Styled.CaptureCanvasArea>
              <Styled.CaptureCanvasWrapper>
                <Styled.CaptureVideo ref={screenCaptureRef} autoPlay />
                <Styled.CaptureVideo ref={userMediaCaptureRef} autoPlay />
                <Styled.CaptureCanvas ref={canvasRef} />
              </Styled.CaptureCanvasWrapper>
              <Styled.ControlButtons>
                {capturing?.screen && (
                  <Button type="new-primary" onClick={handleStopCapture}>
                    Stop Sharing
                  </Button>
                )}
                {!capturing?.screen && (
                  <Button type="new-primary" onClick={handleCapture}>
                    Share Screen
                  </Button>
                )}
                {!recording && capturing?.screen && (
                  <Styled.RecordButton
                    type="new-primary"
                    onClick={() => {
                      if (blob) {
                        props.confirm(
                          () => {
                            handleRecord();
                          },
                          null,
                          'Record a new video? Your old video will be replaced.'
                        );
                      } else {
                        handleRecord();
                      }
                    }}
                  >
                    <Icon icon="dot" type="solid" />
                    <span>Start Recording</span>
                  </Styled.RecordButton>
                )}
                {recording && (
                  <Styled.RecordButton
                    type="new-primary"
                    onClick={handleStopRecord}
                  >
                    <Icon icon="stop" type="solid" />
                    <span>Stop Recording</span>
                  </Styled.RecordButton>
                )}
              </Styled.ControlButtons>
            </Styled.CaptureCanvasArea>
          </Styled.ScreenCaptureArea>
          <VideoResult
            blob={blob}
            chunks={chunks}
            recordedDurationMs={endRecordTime - startRecordTime}
          />
        </PageContent>
      </StyledPageContentInner>
    </StyledPage>
  );
};

export default WithConfirmationBox(
  withFlashMessaging(withApollo(withUser(ScreenCapturePage)))
);
