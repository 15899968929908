import { gql } from '@apollo/client';

export const READ_VOICES = gql`
  query readVoices($filter: VoiceFilterFields) {
    readVoices(
      limit: 1000
      sort: { Featured: DESC, Name: ASC }
      filter: $filter
    ) {
      nodes {
        ID
        Name
        Age
        Code
        Service
        Locale
        Featured
        Gender
        Deprecated
      }
    }
  }
`;
