import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@virtidev/toolbox';
import { useUploadSRT } from '../../../Subtitles/useUploadAndSaveSRT';
import { SanitizeFileName } from '../../../../../../../../utility/FileUtility';
import { useMutation } from '@apollo/client';
import { EDIT_SUBTITLES } from './SRTUploader.query';
import useFlashMessage from '../../../../FlashMessage';
import getConfig from '../../../../../../../../lib/config';

const SRTUploader = (props) => {
  const startUploadSRT = useUploadSRT();
  const { getSrt, video, subtitle, onSaved } = props;
  const { addFlashMessage } = useFlashMessage();

  const [loading, setLoading] = useState(false);
  const [updateSubs] = useMutation(EDIT_SUBTITLES, {
    onCompleted: () => {
      setLoading(false);
      addFlashMessage('Updated subtitles successfully.');
      if (onSaved) {
        onSaved();
      }
    },
    onError: () => {
      addFlashMessage('Failed to update subtitles.', 'error');
      setLoading(false);
    },
  });

  const handleClick = useCallback(() => {
    setLoading(true);
    const srtBlob = getSrt();
    const file = new File(
      [srtBlob],
      `${SanitizeFileName(video.Title)}-${SanitizeFileName(video.Title)}.srt`
    );
    startUploadSRT(file, {
      onTUSSuccess: (upload, tusID) => {
        updateSubs({
          variables: {
            input: {
              ID: subtitle.ID,
              URL: `${getConfig('REACT_APP_CLOUDFRONT_URL')}${tusID}`,
            },
          },
        });
      },
      onTUSError: () => {
        setLoading(false);
      },
    });
  }, [video.Title, startUploadSRT, getSrt, updateSubs, subtitle.ID]);

  return (
    <Button
      dataTestId="save-subtitles-btn"
      disabled={!props.isDirty || loading}
      loading={loading}
      onClick={handleClick}
    >
      Upload &amp; Save
    </Button>
  );
};

SRTUploader.propTypes = {
  // saving: PropTypes.bool.isRequired,
  // setSaving: PropTypes.func.isRequired,
  // dirty: PropTypes.bool.isRequired,
  // captionLanguage: PropTypes.string.isRequired,
  // captionType: PropTypes.string.isRequired,
  getSrt: PropTypes.func.isRequired,
  isDirty: PropTypes.bool.isRequired,
  subtitle: PropTypes.object.isRequired,
  video: PropTypes.object.isRequired,
};

export default SRTUploader;
