import {
  VHAIFileIcon,
  VHAIFileMenu,
  VHAIMockText,
  VHAIPlaceholder,
} from '@core/components/VirtualHumans/CreateVirtualHumanWithAiForm/components/VHAIFiles/VHAIFiles.styled';
import { MockImage } from '@virtidev/toolbox';

export const VHAIFilePlaceholder = () => {
  return (
    <VHAIPlaceholder>
      <VHAIFileMenu disabled icon="dots" />
      <VHAIFileIcon as={MockImage} />
      <VHAIMockText />
      <VHAIMockText $small />
    </VHAIPlaceholder>
  );
};

export default VHAIFilePlaceholder;
