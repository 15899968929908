import React, { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import '../../node_modules/react-dropzone-component/styles/filepicker.css';
import '../../node_modules/dropzone/dist/min/dropzone.min.css';
import WithVirtualHumanPageTemplate from '../HOCs/templates/WithVirtualHumanPageTemplate';
import VirtualHumanTabNav from '../components/VirtualHumanTabNav';
import { StyledPageContentInner } from '../components/StyledPage';
import Axios from 'axios';
import { getVirtualHumanAPI } from '../utility/VirtualHumanUtility';
import { GetAIToken } from '../utility/LoginUtility';
import { useMounted } from '../utility/CustomHooks';
import { VIRTUAL_HUMAN_ENVIRONMENTS } from '../queries/VirtualHumanQueries';
import { useQuery } from '@apollo/client';
import { useCurrentOrganisation } from '../apps/core/src/helpers/useCurrentOrganisation';
import VirtualHumanForm from '../apps/core/src/components/VirtualHumanForm/VirtualHumanForm';

const VirtualHumanPage = (props) => {
  const [condition, setCondition] = useState(null);
  const mounted = useMounted();

  useCurrentOrganisation(props.data?.readOneVirtualHuman?.OrganisationID);

  const { data, loading, error } = useQuery(VIRTUAL_HUMAN_ENVIRONMENTS, {
    variables: {
      SimulationID: props.ID,
    },
  });
  useEffect(() => {
    if (!_.get(props, 'data.readOneVirtualHuman.ExternalID') || condition) {
      return;
    }
    if (props.data?.readOneVirtualHuman.Type !== 'medical') {
      return;
    }
    Axios.get(
      getVirtualHumanAPI() +
        '/api/condition/' +
        props.data.readOneVirtualHuman.ExternalID,
      {
        headers: {
          Authorization: 'Bearer ' + GetAIToken(),
        },
      }
    )
      .catch(function (error) {
        // handle error
        console.error(error);
      })
      .then((response) => {
        if (mounted.current) {
          setCondition(response.data.condition);
        }
      })
      .catch((e) => {
        console.error('Could not access response, server likely down');
        console.error(e);
        if (mounted.current) {
          setCondition({ diagnosisPhrase: 'N/A' });
        }
      });
  }, [condition, mounted, props]);

  return (
    <>
      <VirtualHumanTabNav virtualHumanData={props.data.readOneVirtualHuman} />
      <StyledPageContentInner>
        {data?.readEnvironments && (
          <VirtualHumanForm
            userOrganisationID={props.userOrganisationID}
            mutationType="edit"
            dataObj={props.data.readOneVirtualHuman}
            refetchVirtualHuman={props.refetchVirtualHuman}
            condition={condition}
            environments={data.readEnvironments}
          />
        )}
      </StyledPageContentInner>
    </>
  );
};

VirtualHumanPage.propTypes = {
  userID: PropTypes.number,
  match: PropTypes.object.isRequired,
  userOrganisationID: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  refetchVirtualHuman: PropTypes.func.isRequired,
};

export default WithVirtualHumanPageTemplate(
  VirtualHumanPage,
  'virtual-human-edit-page',
  true,
  true,
  true
);
