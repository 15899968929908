import styled from 'styled-components';

export const RangeContainer = styled.div`
  position: relative;
  width: 100%;
  flex: 1;
  padding: 0 0 12px;
`;

export const RangeInput = styled.input.attrs({ type: 'range' })`
  input[type='range']& {
    appearance: none;
    width: 100%;
    background: #d3d3d3;
    margin: 6px 0;
    height: 12px;
    outline: none;
    border-radius: 20px;
    padding: 0;
    cursor: pointer;
  }

  input[type='range']&::-webkit-slider-thumb {
    appearance: none;
    background: #fff;
    cursor: ew-resize;
    box-shadow: 0 0 2px 0 #555;
  }

  input[type='range']&::-webkit-slider-runnable-track {
    appearance: none;
    background: transparent;
  }
`;

export const RangeValue = styled.div`
  position: absolute;
  bottom: 0;
  font-size: 0.8rem;
  text-align: center;
  transform: translate(-50%);
  font-weight: 500;
`;
