import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import {
  lastCompletedOnboardingTaskVar,
  secondarySidebarContentVar,
  setShowOnboardDialogueVar,
  setShowOnboardProgressVar,
  showOnboardDialogueVar,
  sidebarContractedVar,
} from '../ApolloReactiveVars';
import WithOnboardingHandler from '../HOCs/WithOnboardingHandler';
import { StyledProgressBar } from '../styled-components/StyledOnboardingDashboard';
import { useNextStepLink, useTaskLink } from '../utility/OnboardingHooks';
import { CanViewOnboarding } from '../utility/Permissions';
import Button from './buttons/Button';

const StyledDialogue = styled.div`
  ${({ theme, sidebarContracted }) => css`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1003;
    text-align: center;
    margin: 0rem auto 0rem auto;
    background-color: ${theme.color.primary};

    transition: margin-left var(--sidebar-transition-time, 0.3s);
    margin-left: ${sidebarContracted
      ? 'var(--sidebar-width-contracted)'
      : 'var(--sidebar-width-expanded)'};
    font-size: 0.9rem;
    color: white;
    padding: 0.2rem 2rem 0rem 2rem;
  `}
`;

const StyledOptions = styled.div`
  display: grid;
  grid-template-columns: ${({ twoButton }) => (twoButton ? '1fr 1fr' : '1fr')};
  width: 100%;
  place-items: end;
  gap: 1rem;
`;

const StyledLinkButton = styled(Link)`
  background: var(--secondary-color, #46beaf);
  padding: var(--primary-button-padding);
  border-radius: 24px;
  color: white !important;
  text-decoration: none;
  font-weight: 500;
  :hover {
    opacity: 0.9;

    text-decoration: none;
  }
  :active {
    background-color: var(--primary-button-bg-color-active);
  }
`;

const StyledDismissButton = styled(Button)`
  max-width: 100%;
  margin: auto;
  background: var(--primary-color, #528fbf);
  border: none;
  border-radius: 24px;
  color: white !important;
  text-decoration: none;
  :hover {
    background: var(--primary-color, #528fbf);
    opacity: 0.8;
    text-decoration: none;
  }
  position: absolute;
  top: 0;
  right: 0;
`;

const StyledStepComplete = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  padding: 1rem 0rem 1rem 0rem;
`;

const StyledCongrats = styled.div`
  display: grid;
  grid-template-rows: 1fr 1.5fr;
  place-items: center start;
  color: white;
  width: 100%;
  text-align: left;
`;

const StyledProgressWithLabel = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
  gap: 0.5rem;
  place-items: center start;
  color: var(--primary-color, #528fbf);
  font-size: 0.7rem;
  margin-top: -0.3rem;
`;

const StyledMargin = styled.div`
  margin: 7rem;
`;

function OnboardingDialogue(props) {
  const sidebarContracted = useReactiveVar(sidebarContractedVar);
  const subContent = useReactiveVar(secondarySidebarContentVar);
  const [dismissed, setDismissed] = useState(false);

  const showOnboardDialog = useReactiveVar(showOnboardDialogueVar);

  const nextStepLink = useNextStepLink(
    props?.parsedData?.progress,
    props?.onboardingData
  );

  const link = useTaskLink(nextStepLink, props.parsedData);

  const lastTask = useReactiveVar(lastCompletedOnboardingTaskVar);

  const onboardingFeatureIsActive = CanViewOnboarding();

  useEffect(() => {
    // set reactive var to false so the flash messages display
    !onboardingFeatureIsActive && setShowOnboardDialogueVar(false);
  }, [onboardingFeatureIsActive]);

  return (
    <>
      {onboardingFeatureIsActive &&
        showOnboardDialog &&
        !props.parsedData?.onboardingDismissed &&
        !dismissed && (
          <StyledDialogue
            sidebarContracted={sidebarContracted && !subContent}
            id="onboarding-dialogue"
          >
            <StyledStepComplete>
              <StyledCongrats>
                {props.totalProgress !== 100
                  ? `${
                      lastTask?.Title || 'Step'
                    } complete! Great job, you've completed another onboarding step!`
                  : "Congratulations – you've completed your onboarding!"}

                <StyledProgressWithLabel>
                  <StyledProgressBar
                    value={props.totalProgress}
                    max="100"
                    width={'100%'}
                  />{' '}
                  PROGRESS
                </StyledProgressWithLabel>
              </StyledCongrats>

              <StyledOptions>
                <StyledLinkButton to="/onboarding-dashboard">
                  Onboarding Dashboard
                </StyledLinkButton>

                {props.totalProgress !== 100 && (
                  <StyledDismissButton
                    onClick={() => {
                      setShowOnboardDialogueVar(false);
                    }}
                  >
                    X
                  </StyledDismissButton>
                )}

                {props.totalProgress === 100 && (
                  <StyledDismissButton
                    type="secondary"
                    onClick={() => {
                      setDismissed(true);
                      setShowOnboardProgressVar(false);
                      props.setDismissOnboarding(true);
                    }}
                  >
                    X
                  </StyledDismissButton>
                )}
              </StyledOptions>
            </StyledStepComplete>
          </StyledDialogue>
        )}
      {onboardingFeatureIsActive &&
        showOnboardDialog &&
        !props.parsedData?.onboardingDismissed &&
        !dismissed && <StyledMargin showUpdate={true}></StyledMargin>}
    </>
  );
}

export default WithOnboardingHandler(OnboardingDialogue);
