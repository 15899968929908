import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { HelpLabel, Select } from '@virtidev/toolbox';
import VoiceSelector from '../../../../apps/core/src/components/VirtualHumans/VoiceSelector/VoiceSelector';
import useGetVHData from '../../../../utility/VHHooks/useGetVHData';
import { useLocaleOptions } from '../../../../apps/core/src/helpers/useLocaleOptions';

/**
 * @typedef {import('@virtidev/toolbox').OptionValue} OptionValue
 */

const VHLocaleSelectionWithDefaultVoice = ({
  onLocaleChange,
  onVoiceChange,
  initialVoiceData,
  initialLocaleCode,
  voiceStringBackup,
  vhType,
  disabled,
  gender,
  label,
}) => {
  const [localeOptions, setLocaleOptions] = useState(
    /** @type {OptionValue[]} */ ([])
  );
  const { data, loading, error } = useGetVHData(`languages`);
  const [selectedLocaleCode, setSelectedLocaleCode] = useState(
    /** @type {OptionValue | null} */ (null)
  );
  const langsToFilter = useLocaleOptions();

  // hardcode to supported languages for now
  const languages = useMemo(() => {
    const langsToFilterCodes = langsToFilter.map((lang) => lang.value);
    const langsData =
      data?.data.filter((lang) =>
        langsToFilterCodes.includes(lang.BCP47Code)
      ) ?? [];
    langsData.sort((a, b) => (a.name > b.name ? 1 : -1));
    return langsData;
  }, [data?.data, langsToFilter]);

  // update locale options once loaded and set to a default
  useEffect(() => {
    const newLocaleOptions = languages.map((lang) => ({
      label: lang.name,
      value: lang.BCP47Code,
    }));
    setLocaleOptions(newLocaleOptions);
  }, [languages]);

  const [selectedVoiceData, setSelectedVoiceData] = useState(null);

  const setSelectedLocaleViaCode = useCallback(
    (BCP47Code, resetVoiceData = true) => {
      const data = languages.find((lang) => lang.BCP47Code === BCP47Code);
      const option = localeOptions.find((option) => option.value === BCP47Code);
      if (!option) {
        return;
      }
      setSelectedLocaleCode(option);
      onLocaleChange(data);
      if (resetVoiceData) {
        setSelectedVoiceData(null);
        onVoiceChange(null);
      }
    },
    [
      languages,
      localeOptions,
      onLocaleChange,
      setSelectedVoiceData,
      onVoiceChange,
    ]
  );

  const handleVoiceChange = useCallback(
    (voice) => {
      setSelectedVoiceData(voice);
      onVoiceChange(voice);
    },
    [setSelectedVoiceData, onVoiceChange]
  );

  const handleLocaleChange = useCallback(
    (newLocale) => {
      setSelectedLocaleViaCode(newLocale.value);
    },
    [setSelectedLocaleViaCode]
  );

  useEffect(() => {
    setSelectedLocaleViaCode(initialLocaleCode, false);
  }, [initialLocaleCode, setSelectedLocaleViaCode]);

  useEffect(() => {
    setSelectedVoiceData(initialVoiceData);
  }, [initialVoiceData]);

  return (
    <>
      <Select
        menuPortalTarget={document.body}
        options={localeOptions}
        value={selectedLocaleCode}
        onChange={handleLocaleChange}
        disabled={disabled}
        label={label}
      />
      {selectedLocaleCode?.value && (
        <VoiceSelector
          key={selectedLocaleCode?.value} // reset if locale changes to unset value
          disabled={!selectedLocaleCode?.value || disabled}
          filterLocaleCode={selectedLocaleCode?.value}
          onChange={handleVoiceChange}
          voiceID={selectedVoiceData?.ID}
          voiceString={voiceStringBackup}
          vhType={vhType}
          autoVoiceSelection={true}
          gender={gender}
        />
      )}
    </>
  );
};

VHLocaleSelectionWithDefaultVoice.propTypes = {
  onLocaleChange: PropTypes.func.isRequired,
  onVoiceChange: PropTypes.func.isRequired,
  initialVoiceData: PropTypes.object,
  initialLocaleCode: PropTypes.string,
  voiceStringBackup: PropTypes.string,
  vhType: PropTypes.string,
  disabled: PropTypes.bool,
  gender: PropTypes.string,
  label: PropTypes.string,
};

export default VHLocaleSelectionWithDefaultVoice;
