export const LocalStorageGet = (key, fallback = '') => {
  let val = fallback;
  if (localStorage.getItem(key)) {
    try {
      val = JSON.parse(localStorage.getItem(key));
    } catch (e) {
      console.error(e);
      val = fallback;
    }
  }
  return val;
};
