import styled, { css } from 'styled-components';

export const ProgressLoaderWrapper = styled.div`
  margin: 0 auto;
  width: 210px;
`;
export const ProgressLoaderText = styled.div`
  ${({ theme: { spacing, font } }) =>
    css`
      margin-top: ${spacing.sm};
      ${font.label}
    `}
`;
