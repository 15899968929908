export const getTopTenViews = (titlesAndViews) => {
  return titlesAndViews
    .sort(function (a, b) {
      return a.views - b.views;
    })
    .reverse()
    .reduce((acc, views, index) => {
      if (index < 10) {
        acc.push(views);
      }
      return acc;
    }, []);
};
