import styled from 'styled-components';

export const RadioSelectWrapper = styled.div`
  fieldset {
    all: unset;
    display: flex;
    gap: ${({ theme }) => theme.spacing.md};
    flex-wrap: wrap;
  }
  legend {
    display: none;
  }
  label {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.sm};
    padding: ${({ theme }) => `${theme.spacing.p3} ${theme.spacing.p5}`};
    border-radius: ${({ theme }) => theme.borderRadius.md};
    border: 1px solid ${({ theme }) => theme.color.turquoise10};
    ${({ theme }) => theme.font.body};
    line-height: 140%; /* 22.4px */
    cursor: pointer;

    &:focus,
    &:hover {
      border-color: ${({ theme }) => theme.color.turquoise};
    }

    &:has(input:checked) {
      border-color: ${({ theme }) => theme.color.turquoise20};
      background-color: ${({ theme }) => theme.color.turquoise20};
      cursor: default;
    }

    &:has(input) {
      input {
        display: none;
      }
    }
  }
`;
