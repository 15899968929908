import styled, { css } from 'styled-components';

export const StyledVideo = styled.video`
  max-width: 100%;
  width: 600px;
  margin: auto;
  background-color: #000;
  max-height: 50vh;
`;

export const Splitter = styled.div`
  width: 2px;
  height: 100%;
  background: #ccc;
  margin-left: auto;
  margin-right: auto;
`;

export const UploaderListBox = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.md};
  border: 2px solid #ccc;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  margin-top: 1rem;
  color: var(--neutral-color);
  > * {
    min-width: 300px;
  }
`;
