import { gql } from '@apollo/client';

export const GET_VIDEO_WITH_SUBTITLES = gql`
  query readSubtitlePage($ID: ID!, $SubtitleID: ID!) {
    readOneVideoMedia(filter: { ID: { eq: $ID } }) {
      ID
      TranscodingStatus
      URL
      EditorURL
      SourceURL
      Title
      Created
      Content360
      Archived
      Subtitles(filter: { ID: { eq: $SubtitleID } }) {
        nodes {
          ID
          Title
          Language
          Type
          URL
        }
      }
    }
  }
`;
