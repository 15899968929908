import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tab, TabNav } from '../styled-components/StyledTabNav';

export default class GroupTabNav extends Component {
  render() {
    return (
      <React.Fragment>
        <TabNav>
          <Tab
            isActive={
              !window.location.pathname.includes('analytics') &&
              !window.location.pathname.includes('simulations') &&
              !window.location.pathname.includes('courses') &&
              !window.location.pathname.includes('virtual-humans')
            }
            to={`/groups/${this.props.groupID}`}
          >
            Users
          </Tab>
          <Tab
            isActive={window.location.pathname.includes('simulations')}
            to={`/groups/${this.props.groupID}/simulations`}
          >
            Simulations
          </Tab>
          <Tab
            isActive={window.location.pathname.includes('courses')}
            to={`/groups/${this.props.groupID}/courses`}
          >
            Courses
          </Tab>
          <Tab
            isActive={window.location.pathname.includes('virtual-humans')}
            to={`/groups/${this.props.groupID}/virtual-humans`}
          >
            Virtual Humans
          </Tab>
          <Tab
            isActive={window.location.pathname.includes('analytics')}
            to={`/groups/${this.props.groupID}/analytics`}
          >
            Analytics
          </Tab>
        </TabNav>
      </React.Fragment>
    );
  }
}

GroupTabNav.propTypes = {
  groupID: PropTypes.string.isRequired,
};
