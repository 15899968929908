import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export const useDebouncedSave = (dbValue, { onUpdate, enter = true }) => {
  const [value, setValue] = useState(String(dbValue) || '');
  const updateRef = useRef(onUpdate);

  updateRef.current = onUpdate;

  useEffect(() => {
    setValue(dbValue);
  }, [dbValue, setValue]);

  const debounceUpdate = useMemo(() => _.debounce(updateRef.current, 1000), []);

  const handleInputChange = useCallback(
    (e) => {
      setValue(e.target.value);
      debounceUpdate(e.target.value);
    },
    [debounceUpdate, setValue]
  );

  const handleComplete = useCallback(() => {
    debounceUpdate.flush();
  }, [debounceUpdate]);

  const handleSubmit = useCallback(
    (e) => {
      if (!enter) {
        return;
      }
      if (e.which === 13 || e.keyCode === 13) {
        handleComplete();
      }
    },
    [handleComplete, enter]
  );

  return {
    onBlur: handleComplete,
    onChange: handleInputChange,
    onKeyDown: handleSubmit,
    value,
    setValue,
  };
};
