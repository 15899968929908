import styled from 'styled-components';

export const ChartContainer = styled.div`
  height: ${({ size }) => size || '1.5rem'};
  width: ${({ size }) => size || '1.5rem'};
  margin: 0 auto;

  svg {
    width: ${({ size }) => size || '1.5rem'};
    height: ${({ size }) => size || '1.5rem'};
  }
`;
