import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {
  StyledDataBlock,
  StyledDataBlockHeader,
  StyledDataBlockHeaderText,
} from '../../styled-components/StyledDataBlocks';
import { getTopTenViews } from '../../utility/ChartHelpers';

function BarChartMostPopular(props) {
  const parsedViews = JSON.parse(props.views);
  const parsedTitles = JSON.parse(props.titles);

  const titlesAndViews = parsedViews.map((views, index) => {
    return {
      views,
      title: parsedTitles[index],
    };
  });

  const topTenViews = getTopTenViews(titlesAndViews);

  return (
    <StyledDataBlock>
      <StyledDataBlockHeader>
        <StyledDataBlockHeaderText>{props.title}</StyledDataBlockHeaderText>
      </StyledDataBlockHeader>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'bar',
          },
          title: {
            text: '',
          },
          credits: {
            enabled: false,
          },
          legend: {
            enabled: false,
          },
          plotOptions: {},
          tooltip: {
            pointFormat:
              '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.views}</b><br/>',
          },
          xAxis: {
            type: 'category',
          },
          yAxis: {
            title: {
              text: props.viewType,
            },
          },
          colors: [
            {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
              stops: [
                [0, 'var(--secondary-color)'],
                [1, 'var(--primary-color'],
              ],
            },
          ],
          series: [
            {
              borderRadius: 5,
              name: props.viewType,
              colorByPoint: true,
              data: topTenViews.map((item) => {
                return {
                  name: item.title,
                  y: item.views,

                  views: item.views,
                };
              }),
            },
          ],
        }}
      />
    </StyledDataBlock>
  );
}

BarChartMostPopular.propTypes = {
  views: PropTypes.string.isRequired,
  titles: PropTypes.string.isRequired,
};

export default BarChartMostPopular;
