import { ChatSidebarTabs } from '../../../slices/chat/ChatSlice';
import {
  completeSession,
  endSession,
  fetchGreeting,
  skipGreeting,
} from '../../../slices/chat/ChatThunks';
import { endAnalyticsSession } from '../../../slices/SilverstripeAPISlice';
import VHTypeNavigation from './VHTypeNavigation';
import VhTypes from './VHTypes';

const VhFreeformV3Config = {
  type: VhTypes.FREEFORM_V3,
  navigationLocations: [
    VHTypeNavigation.DETAILS,
    VHTypeNavigation.EVALUATIONS,
    VHTypeNavigation.AI_FALLBACKS,
    VHTypeNavigation.PREVIEW,
    VHTypeNavigation.COMPLETIONS,
    VHTypeNavigation.ANALYTICS,
    VHTypeNavigation.OBJECTIVES_ANALYTICS,
    VHTypeNavigation.SESSIONS,
  ],
  usesSessionReportAsScore: true,
  finishButtonText: (vhDBVh) => 'End conversation',
  finishPromptText: (vhDBVh) => 'Proceed',
  endsConversationPromptText: (vhDBVh) =>
    "You've completed the conversation. Would you like to end the session and view your score?",
  postObjectivesCompletePromptText: (vhDBVh) =>
    `You have completed all the objectives! Would you like to end the session?`,
  finishWarningText: (vhDBVh) =>
    'You will not be able to ask any more questions if you proceed. You will be able to scroll through your conversation and view the results.',
  onFinishSession: (dispatch, vhDBVH) => {
    dispatch(
      completeSession({
        patientId: vhDBVH.id,
      })
    );
  },
  onFinalResultsSaved: (dispatch) => {
    dispatch(endAnalyticsSession());
    dispatch(endSession());
  },
  getGreetingThunk: (vhDBVH) =>
    vhDBVH?.skipGreeting ? skipGreeting : fetchGreeting,
  chatSidebarTabs: {
    CHAT: ChatSidebarTabs.CHAT,
    ACTIONS: ChatSidebarTabs.ACTIONS,
    RESULTS: ChatSidebarTabs.RESULTS,
  },
  showHints: true,
  actionIcon: 'vh-actions',
  sidebarVisibleAtStart: false,
  usesCondition: false,
  usesDialogflow: false,
};
export default VhFreeformV3Config;
