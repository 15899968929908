import { forwardRef, FC, PropsWithChildren } from 'react';
import { Container } from './FieldContainer.styled';

/**
 * @type {FC<PropsWithChildren<{
 *    horizontal?: boolean,
 *    [attr: string]: any,
 * }>>}
 */
export const FieldContainer = forwardRef(({ horizontal, ...props }, ref) => (
  <Container ref={ref} {...props} $horizontal={horizontal} />
));

export default FieldContainer;
