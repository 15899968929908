import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ConfirmationWrapper,
  DeleteButtonWrapper,
  MiniButton,
} from './DeleteButton.styled';

const DeleteButton = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <DeleteButtonWrapper>
      <MiniButton
        onClick={() => setIsOpen(true)}
        icon="bin"
        color="transparent-primary"
        title="Delete"
        aria-label="Delete"
        style={{ opacity: isOpen ? 0 : 1 }}
      ></MiniButton>
      <ConfirmationWrapper aria-live="assertive">
        {isOpen && (
          <>
            <MiniButton
              onClick={props.onDelete}
              icon="tick"
              color="transparent-primary"
              title="Confirm delete"
              aria-label="Confirm delete"
            ></MiniButton>
            <MiniButton
              onClick={() => setIsOpen(false)}
              icon="cross"
              color="transparent-primary"
              title="Cancel delete"
              aria-label="Cancel delete"
            ></MiniButton>
          </>
        )}
      </ConfirmationWrapper>
    </DeleteButtonWrapper>
  );
};

DeleteButton.propTypes = {
  onDelete: PropTypes.func,
};

export default DeleteButton;
