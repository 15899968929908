export const formFields = {
  virtualHumanRole: {
    label: 'Virtual human role',
    formOptions: { required: true },
    helpText: '',
    placeholders: {
      sales: 'Potential Customer',
      customerService: 'Customer',
      hr: 'Team lead',
      medicalEmpathy: 'Patient',
    },
  },
  learnerRole: {
    label: 'User role',
    formOptions: { required: true },
    helpText: '',
    placeholders: {
      sales: 'Sales Representative',
      customerService: 'Internet service provider field technician',
      hr: 'Manager',
      medicalEmpathy: 'Perioperative Nurse',
    },
  },
  scenarioSynopsis: {
    label: 'Describe the scenario',
    formOptions: { required: true },
    helpText:
      'Give a summary of the context and goals of the scenario, referring to the virtual human and learner by the roles you gave above.',
    placeholders: {
      sales: `In this scenario, a sales representative for Easy Hire has a discovery call with a potential customer, Anne, the head of HR of a national supermarket chain. The sales representative should qualify the lead using the MEDDIC sales qualification framework, and understand Anne's pain points.`,
      customerService:
        "A field technician for NexaLink, a popular internet service provider, has been called out to a customer's property because they are having issues with their newly installed internet. After running some tests, the field technician realizes he needs to order a replacement modem, which will take a couple days, so he needs to communicate that the issue cannot be resolved today, but will be fixed as soon as possible.",
      hr: 'A team lead at a warehouse is approaching her manager because she is having a difficult time getting her associates to complete their tasks properly. This is the first time the team lead has managed people in her career. The manager must demonstrate situational leadership while being empathetic to the team lead.',
      medicalEmpathy:
        'A perioperative nurse is seeing a patient for a pre-operation check-in. The patient is an older woman who is having a hip replacement and she is a bit worried about the surgery. The nurse needs to assess the patient to ensure she is ready to go into surgery, and alleviate her concerns.',
    },
  },
  virtualHumanSynopsis: {
    label: 'Describe the virtual human',
    formOptions: { required: false },
    helpText:
      'Describe some background information about the virtual human character, such as their interests or personality. This helps the virtual human to give natural and interesting responses.',
    placeholders: {
      sales: `Anne is based in Atlanta, Georgia
She has over 25 years of HR experience, but only started working for Great Foods 3 months ago
She controls the budget, but has 3 other stakeholders she needs buy-in from, her CEO, her CFO, and her head of IT. 
The most important issue for her is to fill roles more effectively.`,
      customerService: `Trevor works in sales, and lives in a house in Denver Colorado with his wife and two children. 
He usually works on his laptop in the kitchen, and does a lot of video conferencing.
Since he installed the new internet a few weeks ago his video calls have been dropping and he has to reconnect frequently and it's starting to affect his work.`,
      hr: `Kailey is 23 years old and is the team lead at a fulfilment center in New Jersey. 
She was recently promoted a few weeks ago into her first leadership position
She likes her job and wants to continue managing, but has been having a difficult time
She has approached her team about their behavior, but they have been standoffish and challenged her authority.`,
      medicalEmpathy: `Nancy lives alone, with a cat named Caesar in Olympia, Washington
Her daughter and two 6 year old twin grand-daughters live nearby and will be helping her recover.
She has followed all the pre-op instructions, her last meal was the night before, and she’s only had a small sip of water in the morning
She is an avid hiker, and is hoping to return to hiking with her best friend Joan after her surgery`,
    },
  },
  additionalContext: {
    label: 'Additional context',
    formOptions: { required: false },
    helpText: '',
    placeholders: {
      sales: `# Happy Care Co. core values:

- Honesty
- Integrity
- Friendliness
- Respect

# Happy Care Co. sales process:

1. Build rapport
2. Identify needs
3. Present solution
4. Overcome objections
5. Close sale

# Happy Care Co. health insurance plans:

- Basic: $100/month
- Standard: $200/month
- Premium: $300/month

# Happy Care Co. health insurance benefits:

- Basic: $50,000 cover
- Standard: $100,000 cover
- Premium: $200,000 cover

# Happy Care Co. health insurance excess:

- Basic: $1,000
- Standard: $500
- Premium: $0

# Happy Care Co. health insurance waiting period:

- Basic: 6 months
- Standard: 3 months
- Premium: 1 month

# Happy Care Co. health insurance extras:

- Basic: None
- Standard: Dental
- Premium: Dental, Optical, Physiotherapy`,
      hr: '',
    },
  },
  scenarioName: {
    label: 'Scenario name',
    formOptions: { required: true },
    helpText: '',
    placeholders: {
      sales: '',
      customerService: '',
      hr: '',
      medicalEmpathy: '',
    },
  },
  scenarioDescription: {
    label: 'Scenario description',
    formOptions: { required: false },
    helpText: 'Learners will see this when they begin the roleplay session',
    placeholders: {
      sales: '',
      customerService: '',
      hr: '',
      medicalEmpathy: '',
    },
  },
  scenarioObjective: {
    label: 'Scenario context',
    formOptions: { required: true },
    helpText: 'This gives the virtual human context for the roleplay scenario',
    placeholders: {
      sales: '',
      customerService: '',
      hr: '',
      medicalEmpathy: '',
    },
  },
  learnerObjectives: {
    label: 'Learner objectives',
    formOptions: { required: true },
    helpText:
      'Enter the behaviours the learner should exhibit during the roleplay session (one per line). In the next step we will generate phrases a learner can say to hit these objectives.',
    placeholders: {
      sales: '',
      customerService: '',
      hr: '',
      medicalEmpathy: '',
    },
  },
  virtualHumanName: {
    label: 'Name',
    formOptions: { required: false },
    helpText: '',
    placeholders: {
      sales: 'Anne',
      customerService: 'Trevor',
      hr: 'Kailey',
      medicalEmpathy: 'Nancy',
    },
  },
  virtualHumanAge: {
    label: 'Age',
    formOptions: {
      required: false,
      min: { value: 5, message: 'Age must be between 5 - 120' },
      max: { value: 120, message: 'Age must be between 5 - 120' },
      valueAsNumber: true,
    },
    helpText: '',
    placeholders: {
      sales: '',
      customerService: '',
      hr: '',
      medicalEmpathy: '',
    },
  },
  virtualHumanInitialGreeting: {
    label: 'Greeting',
    formOptions: { required: false },
    helpText:
      'The role play scenario will start with the virtual human saying this phrase.',
    placeholders: {
      sales: '',
      customerService: '',
      hr: '',
      medicalEmpathy: '',
    },
  },
  virtualHumanBiography: {
    label: 'Bio',
    formOptions: { required: false },
    helpText:
      'Biographical context that helps add depth to the virtual human to give it more natural and interesting responses.',
    placeholders: {
      sales: '',
      customerService: '',
      hr: '',
      medicalEmpathy: '',
    },
  },
  virtualHumanPersonalityTraits: {
    label: 'Personality traits',
    formOptions: { required: false },
    helpText:
      'List (one per line) personality traits that will affect the tone of virtual human responses.',
    placeholders: {
      sales: '',
      customerService: '',
      hr: '',
      medicalEmpathy: '',
    },
  },
  virtualHumanHobbies: {
    label: 'Hobbies',
    formOptions: { required: false },
    helpText:
      'List (one per line) hobbies to help make responses more natural and interesting.',
    placeholders: {
      sales: '',
      customerService: '',
      hr: '',
      medicalEmpathy: '',
    },
  },
  objectiveLabel: {
    label: 'Objective',
    formOptions: { required: true },
    helpText: '',
  },
  objectiveInputs: {
    label: 'Accepted learner responses',
    formOptions: { required: true },
    helpText:
      'List (one per line) examples of what a learner could say to satisfy this objective.',
  },
  objectiveResponse: {
    label: 'Response from virtual human',
    formOptions: { required: true },
    helpText:
      'This is what the virtual human will say after this objective is completed.',
  },
  virtualHumanChattiness: {
    label: 'Chattiness',
    formOptions: { required: true, min: -100, max: 100, valueAsNumber: true },
    helpText: '',
  },
  virtualHumanConfidence: {
    label: 'Confidence',
    formOptions: { required: true, min: -100, max: 100, valueAsNumber: true },
    helpText: '',
  },
  virtualHumanAggressiveness: {
    label: 'Aggressiveness',
    formOptions: { required: true, min: -100, max: 100, valueAsNumber: true },
    helpText: '',
  },
  virtualHumanOpenness: {
    label: 'Openness',
    formOptions: { required: true, min: -100, max: 100, valueAsNumber: true },
    helpText: '',
  },
  fileIds: {
    label: 'Upload files to add additional context',
    formOptions: {},
    helpText:
      'Add any files that you want to be taken into account when building your scenario. This could include a role play outline, a script of the conversation, a transcript of a real conversation, a lesson plan, a product fact sheet, a description of your company, or any other training materials.',
  },
};
