import _ from 'lodash';
import { useMemo, useState } from 'react';
import Modal from '@base/components/Modal';
import Pagination from '@base/components/Pagination';
import { useOverviewShowing } from '../../OverviewShowing.state';
import { ButtonsContainer } from '../../SimulationOverview.styled';
import Button from '../Button';
import {
  ContentContainer,
  ContentHolder,
  StyledImage,
} from './OverviewPagesModal.styled';

export const OverviewPagesModal = ({ pages, onComplete }) => {
  const { show, hideModal } = useOverviewShowing();
  const [pageNum, setPageNum] = useState(1);

  const content = useMemo(() => {
    const content = _.get(pages, `${pageNum - 1}.Content`);

    if (!content) {
      return null;
    }

    return { __html: content };
  }, [pages, pageNum]);

  const title = useMemo(
    () => _.get(pages, `${pageNum - 1}.Title`),
    [pages, pageNum]
  );

  const image = useMemo(() => {
    const url = _.get(pages, `${pageNum - 1}.Media.URL`);

    if (!url) {
      return null;
    }

    return <StyledImage src={url} alt={title} />;
  }, [pages, pageNum, title]);

  return (
    <Modal visible={show} hideModal={hideModal} title={title}>
      <ContentContainer>
        <ContentHolder dangerouslySetInnerHTML={content} />
        {image}
      </ContentContainer>
      <Pagination
        changePageCallback={setPageNum}
        perPage={1}
        page={pageNum}
        totalCount={pages.length}
        showPrevNext={false}
      />
      <ButtonsContainer>
        <Button onClick={onComplete} icon="play">
          Play
        </Button>
      </ButtonsContainer>
    </Modal>
  );
};

export default OverviewPagesModal;
