import _ from 'lodash';
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { StyledWrapper } from '../CXAnalytics.styled';
import CX_ANALYTICS from '../cxAnalytics.query';

// todo migrate these files
import StyledLabel from '../../../../../../styled-components/StyledLabel';
import {
  S_table,
  S_tbody,
  S_td,
  S_thLeft,
  S_tr,
} from '../../../../../../styled-components/StyledTable';
import { StyledDataBlock } from '../../../../../../styled-components/StyledDataBlocks';

export const Retention = () => {
  const { data, loading, error } = useQuery(CX_ANALYTICS);
  const retentionStr = _.get(data, 'cxAnalytics.Retention', '{}');
  const retentionArray = useMemo(() => {
    const retention = JSON.parse(retentionStr);
    const retentionKeys = Object.keys(retention);

    return retentionKeys.map((key) => {
      const roundedRate = Math.round(retention[key]);
      const day = key.slice(0, -3) + ' Day';
      const keyValuePair = { rate: roundedRate, day };
      return keyValuePair;
    });
  }, [retentionStr]);

  return (
    <StyledWrapper>
      <StyledDataBlock>
        <StyledLabel>Retention</StyledLabel>
        <S_table>
          <S_tbody>
            {retentionArray.map((item) => {
              return (
                <S_tr key={item.day}>
                  <S_thLeft>{item.day}</S_thLeft>
                  <S_td>{item.rate}%</S_td>
                </S_tr>
              );
            })}
          </S_tbody>
        </S_table>
      </StyledDataBlock>
    </StyledWrapper>
  );
};

export default Retention;
