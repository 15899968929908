import { gql } from '@apollo/client';

export const READ_VIRTUAL_HUMAN_VOICE = gql`
  query readOneVirtualHuman($ID: ID!) {
    readOneVirtualHuman(filter: { ID: { eq: $ID } }) {
      ID
      Voice
      VHVoice {
        ID
        Locale
        Code
        Name
        Service
      }
    }
  }
`;
