import { isBusinessEmail } from '@core/components/Signup/RegisterForm/helpers/isBusinessEmail';
import zod from 'zod';

export const emailFormSchema = zod.object({
  Email: zod
    .string()
    .email({ message: 'A business email is required.' })
    .refine(isBusinessEmail, { message: 'A business email is required.' }),
  Password: zod
    .string()
    .min(8, { message: 'Password must be at least 8 characters.' }),
  AcceptPrivacy: zod.boolean().refine((val) => val === true, {
    message: 'Please agree before continuing to sign up.',
  }),
});

export const nameFormSchema = zod.object({
  FirstName: zod.string().min(1, { message: 'First name is required.' }),
  Surname: zod.string().min(1, { message: 'Last name is required.' }),
  OrganisationName: zod.string(),
});

export const nameOrgFormSchema = nameFormSchema.extend({
  OrganisationName: zod
    .string()
    .min(1, { message: 'Company name is required.' }),
});

export const registerFormSchema = emailFormSchema.extend(nameFormSchema.shape);

export const registerOrgFormSchema = emailFormSchema.extend(
  nameOrgFormSchema.shape
);
