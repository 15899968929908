import { Icon } from '@virtidev/toolbox';
import React, { FC, useCallback } from 'react';
import { useTheme } from 'styled-components';
import { Wrapper } from './SubtitleButton.styled';

/**
 * @typedef {import('../../../../models/video-media.types').VideoMedia} VideoMedia
 */

/**
 * @type {FC<{
 *    video?: VideoMedia,
 *    onOpen?: (video: VideoMedia | undefined) => void,
 * }>}
 */
export const SubtitleButton = ({ video, onOpen }) => {
  const theme = useTheme();

  const handleOpen = useCallback(() => {
    if (onOpen) {
      onOpen(video);
    }
  }, [onOpen, video]);

  return (
    <Wrapper onClick={handleOpen}>
      <Icon
        icon="captions"
        color={
          video?.Subtitles?.pageInfo?.totalCount
            ? theme.color.primary
            : theme.color.lightGrey
        }
        size="30px"
      />
    </Wrapper>
  );
};

export default SubtitleButton;
