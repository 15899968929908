import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useReactiveVar } from '@apollo/client';
import Breakpoints from '../themes/Breakpoints';
import Button from './buttons/Button';
import {
  secondarySidebarContentVar,
  sidebarContractedVar,
} from '../ApolloReactiveVars';

const StyledBar = styled.div`
  position: fixed;
  bottom: 0;
  transition: all var(--sidebar-anim-duration);
  height: var(--savebar-height);
  background-color: var(--savebar-bg-color);
  display: flex;
  justify-content: ${(props) => (props.center ? 'center' : 'flex-end')};
  gap: 1rem;
  align-items: center;
  padding: 15px;
  border-top: 1px solid #ccc;
  z-index: 20;

  ${(props) =>
    !props.sidebarContracted &&
    css`
      width: calc(100% - var(--sidebar-width-expanded));

      left: var(--sidebar-width-expanded);
    `}
  ${(props) =>
    props.sidebarContracted &&
    css`
      width: calc(100% - var(--sidebar-width-contracted));
      left: var(--sidebar-width-contracted);
    `}
`;

const SaveBarBtnNoBG = styled(Button)`
  background: none;
  border: none;
  cursor: pointer;
  font-family: var(--text-font);
  color: var(--secondary-font-color);
  ${(props) =>
    props.disabled &&
    css`
      color: var(--disabled-savebar-btn-nobg-font-color);
    `}
  &:hover {
    background: none;
    text-decoration: underline;
  }
`;

const SaveBarBtnWithBG = styled(Button)`
  color: var(--primary-font-color);
  background-color: var(--secondary-button-bg-color);
  border-radius: var(--secondary-button-border-radius);
  border: none;
  padding: var(--secondary-button-padding);
  cursor: pointer;
  font-family: var(--text-font);
  font-size: 1rem;
  margin-left: 1rem;
  font-weight: 500;

  &:hover {
    background-color: var(--secondary-button-bg-color-hover);
    color: var(--primary-font-color);
  }
  ${(props) =>
    props.disabled &&
    css`
      background-color: var(--disabled-savebar-btn-bg-color);
      color: var(--disabled-savebar-btn-font-color);
      cursor: default;
      &:hover {
        background-color: var(--disabled-savebar-btn-bg-color);
        color: var(--disabled-savebar-btn-font-color);
      }
    `}
`;

const SaveBar = (props) => {
  const sidebarContracted = useReactiveVar(sidebarContractedVar);
  const contentName = useReactiveVar(secondarySidebarContentVar);

  return (
    <StyledBar
      center={props.center}
      color={props.color}
      sidebarContracted={sidebarContracted && !contentName}
    >
      {props.children}
    </StyledBar>
  );
};

SaveBar.propTypes = {
  color: PropTypes.string,
  center: PropTypes.bool,
};

export { SaveBarBtnNoBG, SaveBarBtnWithBG, SaveBar };
export default SaveBar;
