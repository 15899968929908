import React from 'react';

export const getCircle = (selected, correct) => {
  if (selected) {
    return (
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="7.5"
          cy="7.5"
          r="7.5"
          fill={correct ? 'var(--positive-color)' : 'var(--negative-color)'}
        />
      </svg>
    );
  } else {
    return (
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5ZM2.0473 7.5C2.0473 10.5114 4.48856 12.9527 7.5 12.9527C10.5114 12.9527 12.9527 10.5114 12.9527 7.5C12.9527 4.48856 10.5114 2.0473 7.5 2.0473C4.48856 2.0473 2.0473 4.48856 2.0473 7.5Z"
          fill={correct ? 'var(--positive-color)' : 'var(--negative-color)'}
        />
      </svg>
    );
  }
};

export const getGreyCircle = (selected) => {
  if (selected) {
    return (
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="7.5" cy="7.5" r="7.5" fill="#afafaf" />
      </svg>
    );
  } else {
    return (
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5ZM2.0473 7.5C2.0473 10.5114 4.48856 12.9527 7.5 12.9527C10.5114 12.9527 12.9527 10.5114 12.9527 7.5C12.9527 4.48856 10.5114 2.0473 7.5 2.0473C4.48856 2.0473 2.0473 4.48856 2.0473 7.5Z"
          fill="#afafaf"
        />
      </svg>
    );
  }
};
