import styled, { css, createGlobalStyle } from 'styled-components';
import { Unity as UnityBase } from 'react-unity-webgl';

export const UnityWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background-color: var(--unity-loading-bg-color);
  user-select: none;
`;

export const Unity = styled(UnityBase)`
  height: 100%;
  width: 100%;
`;

export const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: auto;
  right: auto;
  border: 0;
`;

export const FrameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  min-width: 300px;
  min-height: 169px;
  background: black;
  width: 100%;
  height: 100%;
  ${({ $show }) =>
    $show || $show == null
      ? css`
          position: relative;
        `
      : css`
          position: absolute;
          visibility: hidden;
          pointer-events: none;
        `}
`;

export const GlobalEmbedStyles = createGlobalStyle`
  html, body, #root {
    height: 100%;
    width: 100%;
  }

  html::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`;

export const StyledMessage = styled.div`
  padding: 2rem;
  text-align: center;
  line-height: 1.5rem;
`;
