import { gql } from '@apollo/client';

export const UPDATE_PAGE_TITLE = gql`
  mutation updatePageTitle($input: UpdateFeedbackPageInput!) {
    updateFeedbackPage(input: $input) {
      ID
      Title
    }
  }
`;
