import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from '@apollo/client/react/hoc';
import { Redirect, withRouter } from 'react-router-dom';

import {
  GetUserData,
  StoreLogin,
  GetAccessToken,
  GetAIToken,
} from '../utility/LoginUtility';
import withFlashMessaging from '../HOCs/WithFlashMessaging';
import withUser from '../HOCs/WithUser';
import FlashMessageHolder from '../components/FlashMessageHolder';
import LoadingPageIndicator from '../components/LoadingPageIndicator';
import { userModeVar } from '../ApolloReactiveVars';
import { readOneMemberBasic } from '../query-functions/MemberQueryFunctions';

class SetOrganisationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      completedOrganisationSetting: false,
    };
  }

  componentDidMount = async () => {
    if (!this.props.userID) {
      const message = 'Session expired.';
      this.props.history.push(`/?msg=${encodeURI(message)}`);
      return;
    }
    const userData = await readOneMemberBasic(
      this.props.client,
      this.props.userID
    );
    await StoreLogin(
      GetAccessToken(),
      GetAIToken(),
      userData?.data?.readOneMember,
      this.props.client,
      this.props.match.params.id
    );
    this.setState({
      completedOrganisationSetting: true,
    });
  };

  render() {
    const userMode = userModeVar();

    return (
      <React.Fragment>
        <FlashMessageHolder />
        <LoadingPageIndicator />
        {this.state.completedOrganisationSetting && (
          <Redirect
            to={{
              pathname: userMode === 'admin' ? '/' : '/home',
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

SetOrganisationPage.propTypes = {
  userID: PropTypes.number.isRequired,
  userOrganisationID: PropTypes.number.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  addFlashMessage: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
};

export default withFlashMessaging(
  withRouter(withApollo(withUser(SetOrganisationPage)))
);
