import { useMutation } from '@apollo/client';
import { Button } from '@virtidev/toolbox';
import React, { useCallback, useMemo } from 'react';
import useFlashMessage from '../../FlashMessage';
import { UPDATE_MEMBER } from './Actions.query';

const RestoreMember = ({
  selectedIDs,
  setSelectedIDs,
  setListModified,
  // why do we need this button prop?
  button,
}) => {
  const { addFlashMessage } = useFlashMessage();

  const [updateMember] = useMutation(UPDATE_MEMBER, {
    onCompleted: () => {
      setSelectedIDs([]);
      setListModified(true);
      addFlashMessage(
        `User${selectedIDs.length > 1 ? 's' : ''} restored successfully.`
      );
    },
  });

  const handleClick = useCallback(() => {
    selectedIDs.forEach((ID) => {
      updateMember({
        variables: {
          Input: {
            ID,
            Disabled: false,
          },
        },
        update: (cache, result) => {
          cache.evict({ fieldName: 'readMembers' });
        },
      });
    });
  }, [updateMember, selectedIDs]);

  const Wrapper = useMemo(() => button || Button, [button]);

  return (
    <Wrapper title="Restore user" onClick={handleClick}>
      Restore User{selectedIDs.length > 1 ? 's' : ''}
    </Wrapper>
  );
};

export default RestoreMember;
