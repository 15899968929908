import styled from 'styled-components';

export const SimulationsList = styled.div`
  flex: 1;
  overflow-y: scroll;
`;

export const StyledNoResults = styled.div`
  margin-top: 3rem;
`;
