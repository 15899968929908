import { Icon } from '@virtidev/toolbox';
import React from 'react';
import {
  StyledCustomisedInputHeader,
  StyledLabel,
  StyledLikertForm,
  StyledLikertFormOption,
  StyledOptionPoleText,
} from './LikertScaleV2.styled';

export default function MockLikertScale({ question, value }) {
  return (
    <>
      <StyledCustomisedInputHeader>
        <Icon icon="likertscale" size="35px" color="#ccc" />
      </StyledCustomisedInputHeader>
      <StyledLabel>{question.Title}</StyledLabel>
      <StyledLikertForm>
        <StyledOptionPoleText>{question.FirstLabel}</StyledOptionPoleText>
        {[...Array(question.Length)].map((item, index) => {
          return (
            <StyledLikertFormOption key={index + question.ID + 'mockOption'}>
              <label>{index + 1}</label>
              <input
                type="radio"
                value={index + 1}
                checked={index + 1 === value}
                readOnly
              />
            </StyledLikertFormOption>
          );
        })}
        <StyledOptionPoleText>{question.LastLabel}</StyledOptionPoleText>
      </StyledLikertForm>
    </>
  );
}
