import { useMutation } from '@apollo/client';
import { Button, Icon } from '@virtidev/toolbox';
import React, { useCallback } from 'react';
import { useFlashMessaging } from '../../../../../../../HOCs/WithFlashMessaging';
import useUser from '../../../../helpers/useUser';
import { CREATE_FEEDBACK_FORM } from './CreateNewFeedbackForm.query';
import tracker from '../../../../helpers/tracker';

// this is used on sim, course, and vh pages, not the feedback forms main list

export default function CreateNewFeedbackForm({
  handleAddToContent,
  field,
  setLoading,
  setShowModal,
}) {
  const { addFlashMessage } = useFlashMessaging();
  const { Organisation } = useUser();

  const [addForm, { data, error }] = useMutation(CREATE_FEEDBACK_FORM, {
    refetchQueries: ['readFeedbackForms', 'readFeedbackFormsSelect'],
  });

  const handleClick = useCallback(() => {
    setLoading(true);
    addForm({
      variables: {
        Input: {
          Title: 'New Form',
          OrganisationID: Organisation.ID,
        },
      },

      onCompleted: (data) => {
        handleAddToContent(data.createFeedbackForm.ID, field);
        setShowModal(true);
        tracker.track('form_created', {
          form_id: data.createFeedbackForm.ID,
        });
      },
      onError: () =>
        addFlashMessage(
          'Something went wrong – form could not be created.',
          'error'
        ),
    });
  }, [
    Organisation.ID,
    addFlashMessage,
    addForm,
    field,
    handleAddToContent,
    setLoading,
    setShowModal,
  ]);

  return (
    <Button
      style={{ color: 'inherit', paddingLeft: '0', paddingRight: '0' }}
      size="small"
      color="transparent"
      onClick={handleClick}
      title="Create new Feedback Form"
    >
      <Icon icon="plus" />
    </Button>
  );
}
