import React from 'react';
import {
  SectionTitle,
  SectionWrapper,
  SelectFieldsWrapper,
  SelectFieldWrapper,
} from './SelectSection.styled';

export default function SelectSection({ title, selectBefore, selectAfter }) {
  return (
    <SectionWrapper>
      <SectionTitle>{title}</SectionTitle>
      <SelectFieldsWrapper>
        <SelectFieldWrapper>
          <div>Before</div>
          {selectBefore}
        </SelectFieldWrapper>
        <SelectFieldWrapper>
          <div>After</div>
          {selectAfter}
        </SelectFieldWrapper>
      </SelectFieldsWrapper>
    </SectionWrapper>
  );
}
