import React, { useCallback, useMemo, useState, FC } from 'react';
import styled, { useTheme } from 'styled-components';
import { UPDATE_IMAGE, UPDATE_VIDEO } from '../../queries/AssetQueries';
import withFlashMessaging from '../../HOCs/WithFlashMessaging';
import { GetFormattedTime } from '../../utility/TimeFormatting';
import WithConfirmationBox from '../../HOCs/WithConfirmationBox';
import { EllipsisMenuButton } from '../EllipsisMenu';
import Modal from '../Modal';
import StyledLink, { LinkContext } from '../../styled-components/StyledLink';
import { useMutation } from '@apollo/client';
import useFlashMessage from '../../apps/core/src/components/FlashMessage';
import { flatMap, flowRight } from 'lodash';
import { Icon } from '@virtidev/toolbox';

/**
 * @typedef {import('../../apps/core/src/models/simulation.types').Simulation} Simulation
 * @typedef {import('../../apps/core/src/models/simulation.types').Video} Video
 * @typedef {import('../../apps/core/src/models/simulation.types').Interaction} Interaction
 * @typedef {import('./DeleteMediaButtonInMenu.types').DeleteMediaButtonInMenuProps} DeleteMediaButtonInMenuProps
 * @typedef {import('./DeleteMediaButtonInMenu.types').HOCProps} HOCProps
 *
 * @typedef {{
 *    ID: string;
 *    VideoID: string;
 *    Title: string;
 *    Timestamp?: number;
 * }} LinkedItem
 */

const StyledListWrapper = styled.div`
  margin-bottom: 1rem;
`;
const StyledCannotDeleteInfo = styled.div`
  margin-bottom: 1rem;
  line-height: 1.5rem;
`;
const StyledList = styled.ul``;
const StyledItem = styled.li`
  margin-bottom: 0.5rem;
`;
const CannotDeleteWarningIcon = styled(Icon)`
  margin-left: 0.6rem;
`;

/**
 * @type {FC<DeleteMediaButtonInMenuProps & HOCProps>}
 */
const DeleteMediaButtonInMenu = ({
  setConfirmAsLoading,
  onDelete,
  closeConfirm,
  file,
  confirm,
  type,
}) => {
  const { color } = useTheme();
  const [simsListModalOpen, setSimsListModalOpen] = useState(false);
  const { addFlashMessage } = useFlashMessage();

  const [mutateDeleteMedia, { loading }] = useMutation(
    type === 'video' ? UPDATE_VIDEO : UPDATE_IMAGE,
    {
      onError: (err) => {
        console.error(err);
        setConfirmAsLoading(false);
        addFlashMessage('There was an error deleting the file', 'error');
      },
      onCompleted: (data) => {
        addFlashMessage('File deleted', 'success');
        onDelete?.(file?.ID);
        closeConfirm();
      },
      refetchQueries: ['readMediaPageMedia', 'readMediaPageVideo'],
      variables: {
        Input: {
          ID: file.ID,
          Archived: true,
        },
      },
    }
  );

  /** @type {LinkedItem[]} */
  const simulations = useMemo(
    () =>
      flatMap(
        file.Videos.nodes.map(
          /** @param {Video} simVideo */
          (simVideo) =>
            simVideo.Simulations.nodes.map(
              /** @param {Simulation} simulation */
              (simulation) => ({
                ID: simulation.ID,
                VideoID: simVideo.ID,
                Title: simulation.Title,
              })
            )
        )
      ),
    [file]
  );

  /** @type {LinkedItem[]} */
  const events = useMemo(
    () =>
      flatMap(
        (type === 'video'
          ? file.EmbeddedInEvents.nodes
          : file.EmbeddedImageInEvents.nodes
        ).map(
          /** @param {Interaction} interaction */
          (interaction) => ({
            ID: interaction.ID,
            VideoID: interaction.VideoID,
            Title: interaction.Label,
            Timestamp: interaction.Timestamp,
          })
        )
      ),
    [file, type]
  );

  const resources = file.Resources?.nodes || [];

  const canDelete =
    simulations.length === 0 && events.length === 0 && resources.length === 0;

  const openDelete = useCallback(
    (e) => {
      e?.stopPropagation?.();

      if (!canDelete) {
        setSimsListModalOpen(true);
        return;
      }
      confirm(
        () => {
          setConfirmAsLoading(true);
          mutateDeleteMedia();
        },
        '',
        'Delete file?'
      );
    },
    [canDelete, mutateDeleteMedia, setConfirmAsLoading, confirm]
  );

  return (
    <>
      <EllipsisMenuButton loading={loading} onClick={openDelete}>
        <Icon icon="bin" type="outline" size="1.3rem" color={color.blue20} />
        {type === 'video' ? 'Delete video' : 'Delete image'}
        {!canDelete && (
          <CannotDeleteWarningIcon
            icon="alert"
            size="1.3rem"
            color={color.warning}
          />
        )}
      </EllipsisMenuButton>
      {simsListModalOpen && (
        <Modal
          visible
          hideModal={() => setSimsListModalOpen(false)}
          title={
            type === 'video'
              ? 'Video cannot be deleted'
              : 'Image cannot be deleted'
          }
        >
          {simulations.length > 0 && (
            <StyledListWrapper>
              <StyledCannotDeleteInfo>
                This file must be removed from the following{' '}
                <strong>simulations</strong> before the file can be deleted:
              </StyledCannotDeleteInfo>
              <StyledList>
                {simulations.map((simulation) => (
                  <StyledItem key={simulation.ID}>
                    <StyledLink
                      to={`/simulations/${simulation.ID}/videos/${simulation.VideoID}/interactions`}
                    >
                      {simulation.Title}
                    </StyledLink>
                  </StyledItem>
                ))}
              </StyledList>
            </StyledListWrapper>
          )}
          {events.length > 0 && (
            <StyledListWrapper>
              <StyledCannotDeleteInfo>
                This file must be removed from the following simulation{' '}
                {type === 'video' ? (
                  <strong>video hotspots</strong>
                ) : (
                  <strong>image hotspots</strong>
                )}{' '}
                (or hotspots should be deleted) before the file can be deleted:
              </StyledCannotDeleteInfo>
              <StyledList>
                {events.map((event) => (
                  <StyledItem key={event.ID}>
                    <StyledLink
                      to={`/simulations/${event.ID}/videos/${event.VideoID}/interactions`}
                    >
                      Hotspot {GetFormattedTime(event.Timestamp)}{' '}
                      {event.Title ? '(' + event.Title + ')' : ''}
                    </StyledLink>
                  </StyledItem>
                ))}
              </StyledList>
            </StyledListWrapper>
          )}
          {resources.length > 0 && (
            <StyledListWrapper>
              <StyledCannotDeleteInfo>
                This file must be removed from the following{' '}
                <strong>resources</strong> before the file can be deleted:
              </StyledCannotDeleteInfo>
              <StyledList>
                {resources.map((resource) => (
                  <StyledItem key={resource.ID}>
                    {resource.Title}{' '}
                    {!!Number(resource.SimulationID) && (
                      <LinkContext>
                        (in Simulation{' '}
                        <StyledLink
                          to={`/simulations/${resource.Simulation.ID}`}
                        >
                          {resource.Simulation.Title}
                        </StyledLink>
                        )
                      </LinkContext>
                    )}
                    {!!Number(resource.CourseID) && (
                      <LinkContext>
                        (in Course{' '}
                        <StyledLink to={`/courses/${resource.Course.ID}`}>
                          {resource.Course.Title}
                        </StyledLink>
                        )
                      </LinkContext>
                    )}
                  </StyledItem>
                ))}
              </StyledList>
            </StyledListWrapper>
          )}
        </Modal>
      )}
    </>
  );
};

DeleteMediaButtonInMenu.propTypes = {};

export default /** @type {FC<DeleteMediaButtonInMenuProps>} */ flowRight(
  WithConfirmationBox,
  withFlashMessaging
)(DeleteMediaButtonInMenu);
