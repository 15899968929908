import styled, { css } from 'styled-components';

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const TitleWrapper = styled.div`
  ${({ theme: { spacing } }) => css`
    display: flex;
    gap: ${spacing.sm};
    align-items: center;
    margin-bottom: 5px;
  `}
`;

export const Title = styled.h4`
  ${({ theme: { color } }) => css`
    color: ${color.midnight5};

    /* Label ? */
    font-family: Cabin;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  `}
`;

export const Tag = styled.span`
  ${({ theme: { color, spacing } }) => css`
    padding: ${spacing.xs} ${spacing.sm};
    border-radius: 8px;
    color: ${color.midnight};

    /* Small Note sm */
    font-family: Cabin;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `}
`;

export const TagTeal = styled(Tag)`
  ${({ theme: { color } }) => css`
    background: ${color.turquoise10};
  `}
`;

export const TagPink = styled(Tag)`
  ${({ theme: { color } }) => css`
    background: ${color.pink10};
  `}
`;

export const TagBlue = styled(Tag)`
  ${({ theme: { color } }) => css`
    background: ${color.blue10};
  `}
`;

export const ScenarioButton = styled.button`
  ${({ theme: { spacing, borderRadius, color } }) => css`
    padding: 20px ${spacing.lg};
    border-radius: ${borderRadius.md};
    display: block;
    text-align: left;
    width: 100%;
    border: none;
    background: ${color.blue50};
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
      background: ${color.blue40};
    }

    &:focus-visible {
      outline: 2px solid ${color.blue};
      outline-offset: 0;
    }

    &:disabled {
      background: ${color.blue50};
      cursor: not-allowed;

      ${Title}, ${Description} {
        color: ${color.midnight20};
      }
    }
  `}
`;

export const LimitNotice = styled.p`
  ${({ theme: { color } }) => css`
    color: ${color.midnight10};
    margin-left: auto;

    /* Note ? */
    font-family: Cabin;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `}
`;

export const Description = styled.p`
  ${({ theme: { color } }) => css`
    color: ${color.midnight10};

    /* Body md */
    font-family: Cabin;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
  `}
`;
