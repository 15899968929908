import { gql } from '@apollo/client';

export const CREATE_VH = gql`
  mutation copyVirtualHuman(
    $VirtualHumanID: ID!
    $OrganisationID: ID!
    $Title: String!
    $AdminTitle: String
    $Locale: String
    $VHVoiceID: ID
    $CreationMethod: String
    $AvatarID: ID
  ) {
    copyVirtualHuman(
      VirtualHumanID: $VirtualHumanID
      OrganisationID: $OrganisationID
      Title: $Title
      AdminTitle: $AdminTitle
      Locale: $Locale
      VHVoiceID: $VHVoiceID
      CreationMethod: $CreationMethod
      AvatarID: $AvatarID
    ) {
      ID
      Title
      ExternalID
    }
  }
`;

export const READ_TEMPLATES = gql`
  query readVirtualHumanTemplates {
    readVirtualHumanTemplates {
      edges {
        node {
          ID
          Title
          VirtualHuman {
            ID
            Created
            LastEdited
            Title
            Description
            OrganisationID
            Status
            ThumbnailURL
            Avatar {
              ID
              Name
              Gender
            }
            Voice
            Featured
            CoursesOnly
            ExternalID
            Type
            EnvironmentID
          }
        }
      }
    }
  }
`;

export const READ_ENVIRONMENTS = gql`
  query readEnvironments($filter: EnvironmentFilterFields) {
    readEnvironments(sort: { DisplayName: ASC }, filter: $filter) {
      nodes {
        ID
        Name
        Avatars(
          filter: { IsDevelopment: { eq: false }, IsPreview: { eq: false } }
        ) {
          nodes {
            ID
            Name
            Gender
            AvatarGeneration
          }
        }
      }
    }
  }
`;