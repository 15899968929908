import { gql } from '@apollo/client';

export const pseudoOptionsFragment = `
  ID
  Action
  Text
  Feedback
  Description
  Correct
  RedirectVideoID
  RedirectEventID
  Points
`;

export const pseudoInteractionFragment = `
  ID
  Title
  Type
  Action
  Timestamp
  PosX
  PosY
  Description
  Duration
  Countdown
  Content
  ImageMediaID
  ImageMedia {
    ID
    TusID
    URL
  }
  RedirectVideoID
  RedirectEventID
  AudioMediaID
  AudioMedia {
    ID
    TusID
    URL
  }
  SimulationID
  VideoID
  AudioURL
  PauseVideo
  Label
  ShowLabel
  ShowOverlay
  Size
  Frameless
  Opacity
  HotspotScale
  Sticky
  IconType
  QuestionType
  AnswersOrdered
  BookmarkVisible
  EmbedVideoMedia {
    ID
    # VideoID
    Title
    URL
    TusID
    Content360
    EditorURL
    TranscodingStatus
  }
  Options {
    nodes {
      ${pseudoOptionsFragment}
    }
  }
`;

export const EDIT_INTERACTION = gql`
  mutation updateEvent($Input: UpdateEventInput!) {
    updateEvent(input: $Input) {
      ID
      Action
      Timestamp
      PosX
      PosY
      Content
      Duration
      Countdown
      RedirectVideoID
      RedirectEventID
      PauseVideo
      Sticky
      Label
      ShowLabel
      ShowOverlay
      IconType
      Size
      Frameless
      EmbedVideoMediaID
      EmbedVideoMedia {
        ID
        EditorURL
        SourceURL
        TranscodingStatus
      }
      AudioMediaID
      AudioMedia {
        ID
        TusID
        URL
      }
      ImageMediaID
      ImageMedia {
        ID
        TusID
        URL
      }
      Opacity
      HotspotScale
      Description
      QuestionType
      AnswersOrdered
      BookmarkVisible
    }
  }
`;
export const ADD_INTERACTION = gql`
  mutation createEvent($Input: CreateEventInput!, $ID: ID) {
    createEvent(input: $Input) {
      ${pseudoInteractionFragment}
      Video {
        ID
        Events(filter: { SimulationID: { eq: $ID } }) {
          nodes {
            ID
          }
        }
      }
    }
  }
`;

export const DELETE_INTERACTIONS = gql`
  mutation deleteEvents($IDs: [ID]!) {
    deleteEvents(ids: $IDs)
  }
`;

export const GET_COACHING_REPORT = gql`
  query readOneAnalyticsSubmission($ID: ID!) {
    readOneAnalyticsSubmission(filter: { ID: { eq: $ID } }) {
      ID
      Created
      Score
      Points
      Time
      TimeSpent
      Member {
        ID
        Name
      }
      AnalyticsEvents(Type: "ANSWER") {
        Type
        AnalyticsSubmissionID
        OptionID
        Points
        OptionPoints
        Event {
          ID
          Title
          Description
          QuestionType
          Options {
            nodes {
              ID
              Text
              Description
              Feedback
              Correct
              Points
              Action
              RedirectVideoID
            }
          }
        }
        Option {
          ID
          Text
          Description
          Feedback
          Correct
          Points
        }
      }
    }
  }
`;

