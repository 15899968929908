// differs from StyledTabs in that it's route-based not page-based
import styled, { css } from 'styled-components';
import LinkWithPrevState from '../components/LinkWithPrevState';

const StyledTabNav = styled.nav`
  /* background-color: var(--secondary-bg-color); */
  border-bottom: var(--primary-border);
  display: flex;
  /* padding: 0 var(--content-side-padding); */
`;

const StyledTab = styled(LinkWithPrevState)`
  ${({ theme, isActive, disabled }) => css`
    padding: 0.5rem 1rem;
    cursor: pointer;
    text-align: center;
    padding: 1.2rem 0.5rem;
    margin-left: 2rem;
    display: block;
    color: var(--tab-text-color);

    &:active {
      color: var(--tab-active-click-text-color);
    }
    ${isActive &&
    css`
      border-bottom: 3px solid ${theme.color.primary};
      font-weight: 500;
      color: var(--tab-active-text-color);
    `}
    ${disabled &&
    css`
      color: var(--tab-disabled-text-color);
      pointer-events: none;
    `}
  `}
`;

export { StyledTab as Tab, StyledTabNav as TabNav };
