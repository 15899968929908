import { Icon } from '@virtidev/toolbox';
import styled from 'styled-components';

export const FieldHelpWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm};
  padding: ${({ theme }) =>
    `calc(${theme.spacing.sm} - 3px) ${theme.spacing.sm}`};
  margin-block: 2px;
  font-size: ${({ theme }) => `calc(${theme.fontSize.xs} + 1px)`};
  color: ${({ theme }) => theme.color.secondaryDark};
  background-color: ${({ theme }) =>
    `color-mix(in srgb, ${theme.color.secondary} 10%, white)`};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
`;

export const InfoIcon = styled(Icon).attrs({
  size: '16px',
  icon: 'information',
})`
  color: ${({ theme }) => theme.color.secondary};
  margin-top: 2px;
`;
