import { gql } from '@apollo/client';

export const READ_ORGANISATIONS_CX_FEEDBACK = gql`
  query readOrganisationsCXFeedback($limit: Int!, $offset: Int!) {
    readOrganisations(offset: $offset, limit: $limit) {
      pageInfo {
        totalCount
        hasNextPage
      }
      edges {
        node {
          ID
          Name
          FeedbackFormSubmissionsCount
          FeedbackForms {
            pageInfo {
              totalCount
            }
          }
        }
      }
    }
  }
`;
