import styled, { css } from 'styled-components';

export const ToggleWrapper = styled.div``;

export const NoteArea = styled.div`
  ${({ theme: { color, borderRadius, spacing } }) => css`
    padding: ${spacing.lg};
    background: ${color.turquoise20};
    border-radius: ${borderRadius.sm};
    color: ${color.midnight10};
  `}
`;
