import { FC } from 'react';
import {
  Marker,
  RegisterInfoContainer,
  RegisterInfoDescription,
  RegisterInfoList,
  RegisterInfoLogo,
  RegisterInfoTitle,
} from '@core/components/Signup/RegisterInfo/RegisterInfo.styled';

/**
 * @type {FC<{
 *    hideDetails?: boolean,
 *    hideTablet?: boolean,
 *    trialLength?: number,
 * }>}
 */
export const RegisterInfo = ({
  hideDetails,
  hideTablet,
  trialLength,
  ...props
}) => (
  <RegisterInfoContainer
    {...props}
    $hideDetails={hideDetails}
    $hideTablet={hideTablet}
  >
    <RegisterInfoLogo src="/images/virti-light.svg" />
    <RegisterInfoTitle>
      Start your {trialLength ? trialLength : 30}-day free trial
    </RegisterInfoTitle>
    <RegisterInfoDescription>
      See how easy it is to get started and how quickly you can scale immersive
      learning.
    </RegisterInfoDescription>
    <RegisterInfoList>
      <li>
        <Marker />
        <span>Create Virtual Humans with AI</span>
      </li>
      <li>
        <Marker />
        <span>Design immersive video content</span>
      </li>
      <li>
        <Marker />
        <span>Build immersive training courses</span>
      </li>
      <li>
        <Marker />
        <span>Analyze objective data in real-time</span>
      </li>
      <li>
        <span>Plus much more...</span>
      </li>
    </RegisterInfoList>
  </RegisterInfoContainer>
);

export default RegisterInfo;
