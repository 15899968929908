import { Button, Card } from '@virtidev/toolbox';
import styled, { css } from 'styled-components';

export const QuickActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  margin-bottom: 16px;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
    gap: 32px;
  }
`;

export const StyledSectionHeader = styled.h2`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.spacing.md};
    font-size: ${theme.fontSize.lg};
    font-weight: ${theme.fontWeight.partBold};
  `}
`;

export const QuickPanel = styled(Card).attrs({
  horizontal: true,
  grow: true,
})``;

export const StyledButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 275px);
  flex: 1;
  gap: 16px;
`;

export const PanelTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.lg};
`;

export const StyledLockWrapper = styled.div`
  position: absolute;
  top: 35px;
  right: 20px;
`;

export const PanelContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm};
`;

export const PanelButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.md};
`;

export const QuickButton = styled(Button).attrs(() => ({
  type: 'link',
  size: 'small',
  color: 'turquoise',
}))``;
