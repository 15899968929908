import { useMutation, useQuery } from '@apollo/client';
import { Select } from '@virtidev/toolbox';
import React, { useCallback, useMemo, useState } from 'react';
import useGetVHCounts from '../../../../../../components/VirtualHumanCreationWizard/useGetVHCounts';
import useUser from '../../../helpers/useUser';
import useFlashMessage from '../../FlashMessage';
import { SelectOrganisation } from '../../form/Select';
import SelectCourse from '../../form/Select/components/SelectCourse/SelectCourse';
import {
  COPY_COURSE,
  READ_COURSE_VIRTUAL_HUMANS,
  READ_ORG_VH_API,
} from './CopyCourse.query';
import {
  StyledButton,
  StyledTitle,
  StyledWrapper,
  StyledInvalidListItem,
  StyledInvalidList,
} from './CopyCourse.styled';
import usePublishStrategy from '../helpers/usePublishStrategy';

export default function CopyCourse() {
  const { Organisation } = useUser();
  const { publishStrategy, publishStrategySelectProps } = usePublishStrategy();
  const [selectedOrg, setSelectedOrg] = useState({
    value: Organisation.ID,
    label: Organisation.Name,
  });
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedTargetOrg, setSelectedTargetOrg] = useState(null);

  const { addFlashMessage } = useFlashMessage();

  const handleOrgChange = useCallback((value) => {
    setSelectedOrg(value);
  }, []);

  const handleCourseChange = useCallback((value) => {
    setSelectedCourse(value);
  }, []);

  const handleTargetOrgChange = useCallback((value) => {
    setSelectedTargetOrg(value);
  }, []);

  const { data: sourceOrgData, loading: loadingSourceOrg } = useQuery(
    READ_ORG_VH_API,
    {
      skip: !selectedOrg?.value,
      variables: {
        ID: selectedOrg?.value,
      },
    }
  );
  const { data: targetOrgData, loading: loadingTargetOrg } = useQuery(
    READ_ORG_VH_API,
    {
      skip: !selectedTargetOrg?.value,
      variables: {
        ID: selectedTargetOrg?.value,
      },
    }
  );

  const [copyCourse, { loading }] = useMutation(COPY_COURSE, {
    variables: {
      CourseID: selectedCourse?.value,
      OrganisationID: selectedTargetOrg?.value,
      PublishStrategy: publishStrategy,
    },
    onCompleted: () => {
      addFlashMessage(
        `Successfully copied ${selectedCourse.label} to ${selectedTargetOrg.label}`
      );
      refetch();
    },
    onError: (err) => {
      addFlashMessage('Something went wrong', 'error');
      console.error(err);
    },
  });

  const handleCopy = useCallback(() => {
    if (!(selectedCourse && selectedTargetOrg)) {
      addFlashMessage('Please fill out all fields', 'error');
      return;
    }
    copyCourse();
  }, [selectedCourse, selectedTargetOrg, copyCourse, addFlashMessage]);

  // determine whether a course can be copied based on the limits on the target org
  const {
    branchingTotalCount: targetBranchingTotalCount,
    freeformTotalCount: targetFreeformTotalCount,
    branchingMax: targetBranchingMax,
    freeformMax: targetFreeformMax,
    isLoading: loadingTargetVHCounts,
    freeAgentsCount,
    refetch,
  } = useGetVHCounts(selectedTargetOrg?.value);

  const { data, loading: isLoadingCourse } = useQuery(
    READ_COURSE_VIRTUAL_HUMANS,
    {
      skip: !selectedCourse?.value,
      variables: {
        ID: selectedCourse?.value,
      },
    }
  );
  const virtualHumanUnits = useMemo(() => {
    return data?.readOneCourse.Modules.nodes.reduce((carry, courseModule) => {
      const unitNodes = courseModule.Units.edges
        .map((unitEdge) => unitEdge.node)
        .filter((unitNode) => unitNode.ID > 0);
      return carry.concat(unitNodes);
    }, []);
  }, [data]);

  const sourceCourseBranchingTotalCount = (virtualHumanUnits ?? []).filter(
    (unit) => unit.VirtualHuman.ID > 0 && unit.VirtualHuman.Type === 'branching'
  ).length;
  const sourceCourseFreeformTotalCount = (virtualHumanUnits ?? []).filter(
    (unit) => unit.VirtualHuman.ID > 0 && unit.VirtualHuman.Type !== 'branching'
  ).length;

  const willBreachTargetOrgBranchingVHLimit =
    !loadingTargetVHCounts &&
    // check for > 0 since we don't want to prevent course duplication if target org is over its limit but no VHs are in the course
    sourceCourseBranchingTotalCount > 0 &&
    selectedTargetOrg?.value > 0 &&
    targetBranchingTotalCount + sourceCourseBranchingTotalCount >
      targetBranchingMax;
  const willBreachTargetOrgFreeformVHLimit =
    !loadingTargetVHCounts &&
    selectedTargetOrg?.value > 0 &&
    // check for > 0 since we don't want to prevent course duplication if target org is over its limit but no VHs are in the course
    sourceCourseFreeformTotalCount > 0 &&
    targetFreeformTotalCount + sourceCourseFreeformTotalCount >
      targetFreeformMax;
  const notEnoughAgents =
    !loadingTargetVHCounts && sourceCourseFreeformTotalCount > freeAgentsCount;
  const mismatchAPI =
    targetOrgData &&
    sourceOrgData &&
    (sourceCourseBranchingTotalCount > 0 ||
      sourceCourseFreeformTotalCount > 0) &&
    (!sourceOrgData?.readOneOrganisation?.VirtualHumanAPI ||
      !targetOrgData?.readOneOrganisation?.VirtualHumanAPI ||
      sourceOrgData?.readOneOrganisation?.VirtualHumanAPI !==
        targetOrgData?.readOneOrganisation?.VirtualHumanAPI);

  return (
    <StyledWrapper>
      <StyledTitle>Select Org to copy from:</StyledTitle>
      <SelectOrganisation
        value={selectedOrg}
        onChange={handleOrgChange}
        placeholder="Select Organisation..."
        disabled
      />
      <StyledTitle>Select Course:</StyledTitle>
      <SelectCourse
        value={selectedCourse}
        onChange={handleCourseChange}
        placeholder="Select Course..."
      />
      <StyledTitle>Select Org to copy to:</StyledTitle>
      <SelectOrganisation
        value={selectedTargetOrg}
        onChange={handleTargetOrgChange}
        placeholder="Select Target Organisation..."
      />
      <StyledTitle>Select publish strategy:</StyledTitle>
      <Select {...publishStrategySelectProps} />
      <StyledButton
        disabled={
          loading ||
          isLoadingCourse ||
          willBreachTargetOrgBranchingVHLimit ||
          willBreachTargetOrgFreeformVHLimit ||
          notEnoughAgents ||
          loadingTargetVHCounts
        }
        onClick={handleCopy}
      >
        Copy
      </StyledButton>
      <StyledInvalidList>
        {willBreachTargetOrgBranchingVHLimit && (
          <StyledInvalidListItem>
            The target organisation's branching virtual human limit needs to be
            raised to accommodate the selected source organisation's branching
            virtual humans. Currently this organisation is at{' '}
            <strong>
              {targetBranchingTotalCount}/{targetBranchingMax} branching VHs
            </strong>{' '}
            - copying this course would put it to{' '}
            <strong>
              {targetBranchingTotalCount + sourceCourseBranchingTotalCount}/
              {targetBranchingMax}
            </strong>
          </StyledInvalidListItem>
        )}
        {willBreachTargetOrgFreeformVHLimit && (
          <StyledInvalidListItem>
            The target organisation's freeform virtual human limit needs to be
            raised to accommodate the selected source organisation's freeform
            virtual humans. Currently this organisation is at{' '}
            <strong>
              {targetFreeformTotalCount}/{targetFreeformMax} freeform VHs
            </strong>{' '}
            - copying this course would put it to{' '}
            <strong>
              {targetFreeformTotalCount + sourceCourseFreeformTotalCount}/
              {targetFreeformMax}
            </strong>
          </StyledInvalidListItem>
        )}
        {notEnoughAgents && (
          <StyledInvalidListItem>
            There {freeAgentsCount === 1 ? 'is' : 'are'}{' '}
            <strong>{freeAgentsCount}</strong> free agent
            {freeAgentsCount === 1 ? '' : 's'}, and{' '}
            <strong>{sourceCourseFreeformTotalCount}</strong>{' '}
            {sourceCourseFreeformTotalCount === 1 ? 'is' : 'are'} needed to be
            able to copy the freeform virtual humans from the source course.
            Please contact those able to add more agents.
          </StyledInvalidListItem>
        )}
        {mismatchAPI && (
          <StyledInvalidListItem>
            The selected course has virtual humans but there is a virtual human
            API mismatch between the two organisations.{' '}
            {!sourceOrgData?.readOneOrganisation.VirtualHumanAPI &&
              'There is no virtual human API set for the source organisation. '}
            {sourceOrgData?.readOneOrganisation.VirtualHumanAPI && (
              <>
                The source organisation API is{' '}
                <strong>
                  {sourceOrgData?.readOneOrganisation.VirtualHumanAPI}
                </strong>
                .{' '}
              </>
            )}
            {!targetOrgData?.readOneOrganisation.VirtualHumanAPI &&
              'There is no virtual human API set for the target organisation. '}
            {targetOrgData?.readOneOrganisation.VirtualHumanAPI && (
              <>
                The target organisation API is{' '}
                <strong>
                  {targetOrgData?.readOneOrganisation.VirtualHumanAPI}
                </strong>
                .{' '}
              </>
            )}
          </StyledInvalidListItem>
        )}
      </StyledInvalidList>
    </StyledWrapper>
  );
}
