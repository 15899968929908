import React from 'react';
import CardMenuItem from '../CardMenuItem/CardMenuItem';

export const CardCopyButton = ({ onCopy, ...props }) => {
  return (
    <CardMenuItem {...props} onClick={onCopy} iconName="duplicate">
      Duplicate
    </CardMenuItem>
  );
};

export default CardCopyButton;
