import React, { useCallback, useState } from 'react';
import GenerateInviteCodes from './GenerateInviteCodes/GenerateInviteCodes';
import { StyledCopyButton, StyledLinks } from './InviteCodes.styled';
import CopyLink from './CopyLink';
import useUser from '@core/helpers/useUser';
import tracker from '@core/helpers/tracker';

export default function OrganisationInviteCodes(props) {
  const [copyAdminAlert, setCopyAdminAlert] = useState(false);
  const [copyUserAlert, setCopyUserAlert] = useState(false);

  const { Organisation } = useUser();

  const handleClick = useCallback(
    (codeType) => {
      const link = () => {
        if (codeType === 'admin') {
          setCopyAdminAlert(true);
          setCopyUserAlert(false);

          return `${window.location.origin}/signup-link/admin/${props.inviteCodeAdmin}`;
        } else if (codeType === 'user') {
          setCopyAdminAlert(false);
          setCopyUserAlert(true);
          return `${window.location.origin}/signup-link/user/${props.inviteCodeUser}`;
        }
      };

      navigator.clipboard.writeText(link());
      tracker.track('invite_link_copied', {
        invite_type: codeType,
      });

      setTimeout(() => {
        setCopyAdminAlert(false);
        setCopyUserAlert(false);
      }, 2000);
    },
    [props.inviteCodeAdmin, props.inviteCodeUser]
  );

  if (props.groupID && !props.inviteCodeUser) {
    return <GenerateInviteCodes groupID={props.groupID} />;
  }

  if (!props.groupID && !props.inviteCodeAdmin) {
    return <GenerateInviteCodes orgID={Organisation.ID} />;
  }

  return (
    <StyledLinks>
      {!props.groupID && (
        <StyledCopyButton
          clicked={copyAdminAlert}
          onClick={() => handleClick('admin')}
        >
          <CopyLink>
            {copyAdminAlert ? 'Copied!' : 'Copy Admin Invite Link'}
          </CopyLink>
        </StyledCopyButton>
      )}
      <br></br>
      {!props.loading && props.inviteCodeUser && (
        <StyledCopyButton
          clicked={copyUserAlert}
          onClick={() => handleClick('user')}
        >
          <CopyLink>
            {copyUserAlert ? 'Copied!' : 'Copy User Invite Link'}
          </CopyLink>
        </StyledCopyButton>
      )}
    </StyledLinks>
  );
}
