import styled, { css } from 'styled-components';

export const ClickableVoice = styled.div`
  ${({ $active, theme: { color } }) => css`
    border-left: 2px solid rgba(0, 0, 0, 0);
    padding: 1rem;
    border-bottom: 1px solid ${color.midnight30};
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${$active &&
    css`
      background-color: ${color.pink30};
      border-left: 2px solid ${color.pink};
    `}

    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: ${color.pink30};
    }
  `}
`;

export const VoiceName = styled.div`
  ${({ $active, theme: { color } }) =>
    $active &&
    css`
      color: ${color.pink};
    `}
`;

export const Texts = styled.div``;

export const VoicePreview = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Tags = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  gap: 0.5rem;
`;
export const Tag = styled.li`
  text-transform: capitalize;
  padding-right: 0.5rem;
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.color.midnight10};
  border-right: 1px solid ${({ theme }) => theme.color.midnight30};
  &:last-child {
    border: none;
  }
`;
