import { OneDecimalPlace } from './NumberFormatting';

export const GetLetteredVideoFormattedTime = function (ms) {
  const seconds = Math.round(ms / 1000);
  if (seconds < 60) return seconds + 's';
  const minutes = Math.floor(seconds / 60);
  const secondsRemainder = seconds - minutes * 60;
  if (minutes < 60)
    return (
      minutes +
      'm ' +
      (Math.floor(secondsRemainder) + '').padStart(2, '0') +
      's'
    );
  const hours = Math.floor(seconds / 60 / 60);
  const minutesRemainder = minutes - hours * 60;
  return (
    hours +
    'h ' +
    (minutesRemainder + '').padStart(2, '0') +
    'm ' +
    (Math.floor(secondsRemainder) + '').padStart(2, '0') +
    's'
  );
};

// decimalised means that the time should return with deciseconds e.g. 0:40.6
// note: always rounds down
export const GetFormattedTime = function (ms, decimalised = false) {
  if (isNaN(ms) || !ms) return '0:00';

  // rounded to e.g. 550 to 6
  const roundedDeciSecondRemainder = Math.round((ms % 1000) / 100);
  ms = ms - roundedDeciSecondRemainder;
  const deciSecondStr =
    decimalised === true ? '.' + roundedDeciSecondRemainder : '';

  const seconds = ms / 1000;
  if (seconds < 60) {
    if (decimalised) {
      return `0:${(Math.floor(seconds) + '').padStart(2, '0')}${deciSecondStr}`;
    }
    return `0:${(Math.round(seconds) + '').padStart(2, '0')}`;
  }
  const minutes = Math.floor(seconds / 60);
  const secondsRemainder = seconds - minutes * 60;
  if (minutes < 60)
    return (
      minutes +
      ':' +
      (Math.floor(secondsRemainder) + '').padStart(2, '0') +
      deciSecondStr
    );
  const hours = Math.floor(seconds / 60 / 60);
  const minutesRemainder = minutes - hours * 60;
  return (
    hours +
    ':' +
    (Math.floor(minutesRemainder) + '').padStart(2, '0') +
    ':' +
    (Math.floor(secondsRemainder) + '').padStart(2, '0') +
    deciSecondStr
  );
};

export const GetFormattedTimeLong = function (ms) {
  if (isNaN(ms) || !ms) return '0s';
  const seconds = Math.round(ms / 1000);
  if (seconds < 60) return Math.floor(seconds) + 's';
  const minutes = Math.floor(seconds / 60);
  const secondsRemainder = seconds - minutes * 60;
  if (minutes < 60)
    return (
      minutes +
      (minutes === 1 ? 'min ' : 'mins ') +
      (Math.floor(secondsRemainder) + 's')
    ).trim();
  const hours = Math.floor(seconds / 60 / 60);
  const minutesRemainder = minutes - hours * 60;
  return (
    hours +
    (hours === 1 ? 'hr ' : 'hrs ') +
    minutesRemainder +
    (minutes === 1 ? 'min ' : 'mins ') +
    (Math.floor(secondsRemainder) + 's')
  ).trim();
};

// currently only converts hours/mins/seconds
// usesDeciseconds means that the time that is formatted uses deciseconds e.g. 0:40.6
export const FormattedTimeToS = function (
  formattedTime,
  usesDeciseconds = false
) {
  // reversed to put seconds at beginning etc.
  let split = (formattedTime + '').split(':').reverse();
  let deciseconds = 0;
  if (usesDeciseconds && (formattedTime + '').includes('.')) {
    split = (formattedTime + '').split('.')[0].split(':').reverse();
    deciseconds = Number((formattedTime + '').split('.')[1]);
  }
  const seconds = split.reduce((carry, time, index) => {
    if (index === 0) {
      carry += Number(time); // seconds
    } else if (index === 1) {
      carry += Number(time) * 60; // minutes
    } else if (index === 2) {
      carry += Number(time) * 60 * 60; // hours
    }
    // else if (index === 3){
    //   carry += Number(time) * 60 * 60 * 24; // days? not tested
    // }
    return carry;
  }, 0);
  return seconds + deciseconds / 10;
};
export const FormattedTimeToMS = function (formattedTime) {
  return FormattedTimeToS(formattedTime) * 1000;
};

const validFormattedPreDecimal = function (splitPreDecimal) {
  return (
    splitPreDecimal.length > 1 &&
    splitPreDecimal[splitPreDecimal.length - 1].length === 2 &&
    splitPreDecimal.every((val) => val.length < 3 && isNumeric(val))
  );
};

const validFormattedPostDecimal = function (decimalString) {
  return isNumeric(decimalString);
};

export const IsValidFormattedTime = function (
  formattedTime,
  usesDeciseconds = false
) {
  const split = formattedTime.split(':');
  const decimalisedSplit = formattedTime.split('.');
  if (usesDeciseconds && decimalisedSplit.length === 2) {
    const preDecimal = decimalisedSplit[0].split(':');
    const validPreDecimal = validFormattedPreDecimal(preDecimal);
    const validPostDecimal = validFormattedPostDecimal(decimalisedSplit[1]);
    return validPreDecimal && validPostDecimal;
  }
  return validFormattedPreDecimal(split);
};

// takes an input e.g. 0:4:1 and tries to turn it into valid format e.g. 00:04:10
// usesDeciseconds allows 0:4:1.5
export const AttemptValidFormattedTime = function (
  formattedTime,
  usesDeciseconds = false
) {
  const decimalisedSplit = formattedTime.split('.');
  let split = formattedTime.split(':').reverse();
  let decimalStr = '';
  if (usesDeciseconds && decimalisedSplit.length === 2) {
    split = decimalisedSplit[0].split(':').reverse();
    decimalStr = OneDecimalPlace(Number('0.' + decimalisedSplit[1])) + '';
    decimalStr = decimalStr.substr(1); // take out the 0 to append to the time formatted
  }
  const fixedSplit = split.map((val, index) => {
    if (isNumeric(val) && val.length === 1 && index === 0) {
      // seconds
      return val + '0';
    }
    if (isNumeric(val) && val.length > 2) {
      return val[0] + '' + val[1];
    }
    return val;
  });
  const fixed = fixedSplit.reverse().join(':') + decimalStr;
  if (IsValidFormattedTime(fixed, usesDeciseconds)) return fixed;
  return false;
};

function isNumeric(s) {
  return !isNaN(s - parseFloat(s));
}

export const MinutesToHHMM = (seconds) => {
  const m = seconds % 60;
  const h = (seconds - m) / 60;
  const HHMM =
    Math.floor(h).toString() +
    'h' +
    (m < 10 ? '0' : '') +
    Math.floor(m).toString() +
    'm';
  return HHMM;
};
export const GetSubtitleTime = function (_seconds, type) {
  if (isNaN(_seconds) || !_seconds) {
    return type === 'srt' ? '00:00:00,000' : '00:00:00.000';
  }
  let milliseconds = Math.round(_seconds * 1000);

  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  milliseconds = milliseconds % 1000;
  seconds = seconds % 60;
  minutes = minutes % 60;
  return (
    (hours < 10 ? '0' : '') +
    hours +
    ':' +
    (minutes < 10 ? '0' : '') +
    minutes +
    ':' +
    (seconds < 10 ? '0' : '') +
    seconds +
    (type === 'srt' ? ',' : '.') +
    (milliseconds < 100 ? '0' : '') +
    (milliseconds < 10 ? '0' : '') +
    milliseconds
  );
};

const isUnder60 = function (doubleUnitNumStr) {
  return (
    doubleUnitNumStr &&
    doubleUnitNumStr.length === 2 &&
    Number(doubleUnitNumStr) < 60
  );
};

export const isValidSubtitleTime = function (subTime, type) {
  if (!subTime) return false;
  const split = subTime.split(':');
  if (split.length !== 3) return false;
  for (let i = 0; i < split; i++) {
    if (split[i].length !== 2 || !isNumeric(split[i]) || !isUnder60(split[i]))
      return false;
  }
  const split2 = split[2].split(type === 'srt' ? ',' : '.');
  if (split2.length !== 2) return false;
  if (split2[0].length !== 2 || !isNumeric(split2[0]) || !isUnder60(split2[0]))
    return false;
  if (split2[1].length !== 3 || !isNumeric(split2[1])) return false;
  return true;
};
