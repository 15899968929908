import styled from 'styled-components';
import { S_tr } from '../../../../../../styled-components/StyledTable';

export const TableRow = styled(S_tr)`
  cursor: pointer;
  &:hover {
    opacity: 0.7;
    background: #fff;
  }
`;

export const Entry = styled.div`
  padding: 1rem;
  background: var(--neutral-color-lighter);
  border-radius: 8px;
`;

export const EntryItem = styled.div`
  margin-bottom: 15px;
`;

export const EntryTitle = styled.h1`
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 5px;
`;

export const EntryCode = styled.code`
  font-family: monospace;
  font-size: 0.8rem;
  word-break: break-word;
`;
