import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Loading } from '@virtidev/toolbox';
import * as Styled from './VHAvailabilityCounter.styled';
import { usePopper } from 'react-popper';

const VHAvailabilityCounter = ({
  loading,
  error,
  freeformMax,
  freeformTotalCount,
}) => {
  const [opened, setOpened] = useState(false);
  const [refDom, setRefDom] = useState(null);
  const [popperDom, setPopperDom] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  useEffect(() => {
    const close = (e) => {
      if (!opened) {
        return;
      }
      if (refDom.contains(e.target)) {
        return;
      }
      setOpened(false);
    };
    document.addEventListener('click', close);

    return () => document.removeEventListener('click', close);
  }, [opened, setOpened, refDom, popperDom]);

  const { styles, attributes, update } = usePopper(refDom, popperDom, {
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
    ],
    placement: 'bottom-start',
  });
  useEffect(() => {
    if (opened) {
      // trigger an update on popper in case this component was hidden beforehand
      update();
    }
  }, [opened, update]);
  return (
    <>
      <Styled.CounterWrapper ref={setRefDom} onClick={() => setOpened(true)}>
        <Icon icon="virtual-human" type="solid" color="#757575" size="1.2rem" />
        {loading && <Loading />}
        {!loading && !error && (
          <p>
            {freeformTotalCount} / {freeformMax}
          </p>
        )}
        {error && 'N/A'}
      </Styled.CounterWrapper>
      <Styled.CounterTooltip
        ref={setPopperDom}
        style={styles.popper}
        opened={opened}
        {...attributes.popper}
      >
        <Styled.CounterTooltipContent>
          <div>
            <em>
              {freeformTotalCount}/{freeformMax}
            </em>{' '}
            freeform virtual humans in use.
          </div>
        </Styled.CounterTooltipContent>
        <div ref={setArrowElement} style={styles.arrow} />
      </Styled.CounterTooltip>
    </>
  );
};

VHAvailabilityCounter.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  freeformMax: PropTypes.number.isRequired,
  freeformTotalCount: PropTypes.number.isRequired,
};

export default VHAvailabilityCounter;
