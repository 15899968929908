import { Icon } from '@virtidev/toolbox';
import styled, { css } from 'styled-components';

export const Box = styled.label`
  ${({ $size, $disabled, theme }) => css`
    position: relative;
    height: ${$size === 'small' ? '16px' : '28px'};
    width: ${$size === 'small' ? '16px' : '28px'};
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid ${theme.color.lightGrey};
    background: #fff;
    cursor: pointer;

    ${$disabled &&
    css`
      filter: grayscale();
      background: #eee;
    `}

    &:focus, &:focus-within {
      border: 1px solid ${theme.color.primary};
    }
  `}
`;

export const Checkmark = styled(Icon).attrs((props) => ({
  icon: 'tick',
  size: props.$size === 'small' ? '8px' : '15px',
}))`
  display: none;
  color: #fff;
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
`;

export const Input = styled.input.attrs({
  type: 'checkbox',
})`
  position: absolute;
  height: 1px;
  width: 1px;
  height: 0;
  width: 0;
  opacity: 0;

  &:checked ~ ${Background} {
    background: var(--primary-color, #528fbf);

    ${Checkmark} {
      display: block;
    }
  }
`;
