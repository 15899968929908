import React, { FC, useCallback } from 'react';
import { EllipsisMenuButton } from '../EllipsisMenu';
import { Icon } from '@virtidev/toolbox';
import { useTheme } from 'styled-components';

/**
 * @typedef {import('../../apps/core/src/models/video-media.types').VideoMedia} VideoMedia
 */

/**
 * @type {FC<{
 *    ID: string,
 *    onEdit: (videoId: string) => void,
 *    type: 'video' | 'image'
 * }>}
 */
const EditMediaTitleButtonInMenu = ({ ID, onEdit, type }) => {
  const { color } = useTheme();
  const editVideo = useCallback(() => onEdit(ID), [onEdit, ID]);

  return (
    <EllipsisMenuButton onClick={editVideo}>
      <Icon icon="pen" type="outline" size="1.3rem" color={color.blue20} />{' '}
      {type === 'image' ? 'Edit image name' : 'Edit video name'}
    </EllipsisMenuButton>
  );
};

EditMediaTitleButtonInMenu.propTypes = {};

export default EditMediaTitleButtonInMenu;
