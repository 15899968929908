import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Matching, RunValidations, GetErrors, GetInvalidFields, MinLength } from '../utility/FormValidations';
import ValidationMessages from '../components/ValidationMessages'
import {
  StyledLoggedOutPage,
  StyledForm,
  StyledTextInput,
  StyledSubmitBtn,
  StyledLogoWrapper,
  StyledLogo,
  StyledHeader,
  StyledPageImage,
  StyledLoggedOutPageContentWrapper,
} from '../styled-components/StyledLoggedOutPage';

const StyledParagraph = styled.p`
  margin-bottom: 2rem;
`;

class ChangePasswordForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      validations: [],
      invalidFields: [],
      pass1: '',
      pass2: ''
    };
  }

  validations = [
    Matching('pass1', 'pass2', 'New Password', 'New Password Repeated'),
    MinLength('pass1', 'New Password', true, 8),
    MinLength('pass2', 'New Password Again', true, 8)
  ];

  _validate = (callback) => {
    const newValidations = RunValidations(this.validations, this.state);
    const invalidFields = GetInvalidFields(newValidations);
    this.setState({
      validations: newValidations,
      invalidFields
    }, () => {
      if (callback) {
        const isInvalid = this.state.validations.some(validation => !validation.valid);
        callback(!isInvalid);
      }
    });
  }

  _submitForm = (e) => {
    e.preventDefault();
    this._validate(valid => {
      if (!valid) return;
      this.props.submitCallback(this.state.pass1, this.state.pass2);
    });
  }

  render() {
    return (
      <StyledLoggedOutPage>
        <StyledLoggedOutPageContentWrapper>
          <StyledForm onSubmit={this._submitForm}>
            <StyledLogoWrapper>
              <StyledLogo />
            </StyledLogoWrapper>
            <StyledHeader>Change Password</StyledHeader>
            <StyledParagraph>
              Please enter your new password below.
            </StyledParagraph>
            <StyledTextInput
              invalid={this.state.invalidFields.includes('pass1')}
              label="New Password"
              name="new-pass-1"
              id="new-pass-1"
              type="password"
              // styleType="alt"
              required={true}
              onChange={event => this.setState({ pass1: event.target.value })}
              placeholder="New Password"
              value={this.state.pass1}
              underText="Minimum 8 characters"
            />
            <StyledTextInput
              invalid={this.state.invalidFields.includes('pass2')}
              label="New Password Again"
              name="new-pass-2"
              id="new-pass-2"
              type="password"
              // styleType="alt"
              required={true}
              onChange={event => this.setState({ pass2: event.target.value })}
              placeholder="New Password Again"
              value={this.state.pass2}
              underText="Minimum 8 characters"
            />
            <StyledSubmitBtn loading={this.props.sending}>Change Password</StyledSubmitBtn>
            <ValidationMessages errors={GetErrors(this.state.validations)} />
          </StyledForm>
        </StyledLoggedOutPageContentWrapper>
        {/* <StyledPageImage src="/images/background-password.png" /> */}
      </StyledLoggedOutPage>
    );
  }
}

ChangePasswordForm.propTypes = {
  submitCallback: PropTypes.func.isRequired,
  sending: PropTypes.bool.isRequired,
};

export default ChangePasswordForm;