import React, { useCallback, forwardRef, ComponentType } from 'react';
import { addFlashMessage as addFlashMessageVar } from './FlashMessage.state';
import { useMounted } from '../../helpers/useMounted';

/**
 * @typedef {import('./FlashMessage.types').AddFlashMessageCallback} AddFlashMessageCallback
 */

export const useFlashMessage = () => {
  /**
   * @type {AddFlashMessageCallback}
   */
  const addFlashMessage = useCallback(
    (text, messageType = 'success', showOnNextPage = false) => {
      addFlashMessageVar({
        text,
        messageType,
        showOnNextPage,
        activatedAtLocation: window.location.pathname,
      });
    },
    []
  );

  return { addFlashMessage };
};

/**
 * @type {(WrappedComponent: ComponentType<any>) => ComponentType<any>}
 */
export const withFlashMessage = (WrappedComponent) => {
  const FlashMessage = forwardRef((props, ref) => {
    const flashMessageProps = useFlashMessage();

    return <WrappedComponent {...props} {...flashMessageProps} ref={ref} />;
  });

  return FlashMessage;
};

export default useFlashMessage;
