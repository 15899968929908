import { useFormState } from 'react-hook-form';
import { ErrorContainer } from './FieldError.styled';

export const FieldError = ({ name, ...props }) => {
  const { errors } = useFormState({ name });

  const error = errors[name];

  if (!error) {
    return null;
  }

  if (error.message) {
    return <ErrorContainer {...props}>{error.message}</ErrorContainer>;
  }

  if (error.type === 'required') {
    return <ErrorContainer {...props}>This is required.</ErrorContainer>;
  }

  return (
    <ErrorContainer {...props}>
      Something is wrong with the value given.
    </ErrorContainer>
  );
};

export default FieldError;
