import { Button } from '@virtidev/toolbox';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  margin: 2rem;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledTitle = styled.h1`
  font-size: 1.2rem;
  margin-top: 1rem;
`;

export const StyledButton = styled(Button)`
  border-radius: 22px;
  width: 120px;
  margin-top: 1rem;
  margin-left: auto;
  order: 2;
`;
