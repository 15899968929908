import styled, { css, DefaultTheme } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 0;
  background: none;
  border-radius: 8px;
  font-size: 1rem;
  padding: 0.8rem 1.2rem;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    background: #ebf8ff;
  }
`;

export const StyledVideo = styled.video`
  width: 600px;
  margin: auto;
`;

export const StyledFileInput = styled.input`
  display: none;
  margin-bottom: 1rem;
`;

export const StyledInputWrapper = styled.label`
  ${
    /** @param {{ $selected: boolean, theme: DefaultTheme }} p */ ({
      theme,
      $selected,
    }) => css`
      overflow: hidden;
      text-overflow: ellipsis;

      border-color: ${$selected ? theme.color.secondary : theme.color.darkGrey};
      color: ${$selected ? theme.color.contrast.secondary : '#777'};

      height: 50px;
      width: 100%;
      border: 1px solid;
      border-radius: 11px;
      display: grid;
      place-items: center;

      padding: 6px 12px;
      cursor: pointer;
      margin: 1rem 0rem;
      &:hover {
        border-color: ${theme.color.secondary};
      }
    `
  }
`;

export const UploaderSection = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  position: relative;
`;

export const SelectWrapper = styled.div`
  width: 100%;
  color: black;
  text-align: left;
  margin: 1rem 0rem 0.5rem 0rem;
`;

export const LoadingHolder = styled.div`
  position: absolute;
  top: -${({ theme }) => theme.spacing.md};
  right: -${({ theme }) => theme.spacing.md};
  background: ${({ theme }) => theme.color.contrast.base};
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.md};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.sm};
  z-index: 5;
`;
