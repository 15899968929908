export const publicRoutes = [
  '/login',
  '/logout',
  '/change-password',
  '/forgot-password',
  '/forgot-code',
  '/activate-account',
  '/signup-link',
  '/embed',
  '/my-virtual-humans/embed/',
  { path: '/register', exact: true },
];
