import { useMutation } from '@apollo/client';
import { Button, Modal, Select } from '@virtidev/toolbox';
import { produce } from 'immer';
import React, { useCallback, useMemo, useState } from 'react';
import { useFlashMessage } from '../../../FlashMessage';
import { ModalContent } from '../../../Users/Actions/AddMembersToGroupModal.styled';
import {
  MEMBER_ORGS_FRAGMENT,
  REMOVE_MEMBER_FROM_ORG,
} from './RemoveMemberFromOrg.query';

const RemoveMemberFromOrgModal = ({
  selectedIDs,
  setListModified,
  showModal,
  setShowModal,
  organisations,
}) => {
  const [org, setOrg] = useState(null);
  const { addFlashMessage } = useFlashMessage();
  const orgOptions = useMemo(() => {
    return organisations.map((org) => {
      return { value: org.ID, label: org.Name };
    });
  }, [organisations]);

  const [removeMember] = useMutation(REMOVE_MEMBER_FROM_ORG);

  const handleSubmit = useCallback(() => {
    removeMember({
      variables: {
        MemberID: selectedIDs[0],
        OrganisationID: org,
      },
      onCompleted: () => {
        addFlashMessage(`Removed member from organisation!`);
        // setOrg(null);
        // setSelectedIDs([]);
        setListModified(true);
        setShowModal(false);
      },
      onError: (err) => {
        console.error(err);
        addFlashMessage('Something went wrong', 'error');
      },
      update: (cache) => {
        const data = cache.readFragment({
          id: `Member:${selectedIDs[0]}`,
          fragment: MEMBER_ORGS_FRAGMENT,
        });

        const newData = produce(data, (state) => {
          state.Organisations.nodes = state.Organisations.nodes.filter(
            (node) => {
              return node.ID !== org;
            }
          );

          return state;
        });

        cache.writeFragment({
          id: `Member:${selectedIDs[0]}`,
          fragment: MEMBER_ORGS_FRAGMENT,
          data: newData,
        });
      },
    });
  }, [
    removeMember,
    selectedIDs,
    org,
    addFlashMessage,
    setListModified,
    setShowModal,
  ]);

  const handleChange = useCallback((e) => {
    setOrg(e.value);
  }, []);

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      title={`Select organisation to remove user${
        selectedIDs.length > 1 ? 's' : ''
      } from`}
      footerRender={() =>
        !!orgOptions.length && (
          <Button onClick={handleSubmit} style={{ borderRadius: '22px' }}>
            Submit
          </Button>
        )
      }
      render={() =>
        orgOptions.length <= 1 ? (
          <ModalContent>
            User cannot be removed from their only organisation. You may wish to
            suspend the user instead.
          </ModalContent>
        ) : (
          <ModalContent>
            <Select
              options={orgOptions}
              onChange={handleChange}
              menuPosition="fixed"
            />
          </ModalContent>
        )
      }
    />
  );
};

export default RemoveMemberFromOrgModal;
