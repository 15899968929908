import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Footer = styled.div`
  padding-top: 10px;
  border-top: 1px solid var(--neutral-color-lighter);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FormLink = styled(Link)`
  font-size: 0.9rem;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-top: 10px;
`;
