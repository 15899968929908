import { MockText } from '@virtidev/toolbox';
import {
  EmptyList,
  LoadingPlaceholder,
  UsageList,
} from '../DashboardUsagePanel.styled';
import UsageSignupsItem from './UsageSignupsItem';

export const UsageSignupsTable = ({ data }) => {
  if (!data) {
    return (
      <UsageList>
        {Array.from(Array(3)).map((_, i) => (
          <div key={`placeholder-${i}`}>
            <LoadingPlaceholder>
              <MockText />
            </LoadingPlaceholder>
          </div>
        ))}
      </UsageList>
    );
  }

  if (!data.length) {
    return <EmptyList>No recent sign ups found.</EmptyList>;
  }

  return (
    <UsageList>
      {data.map((item) => (
        <UsageSignupsItem key={item.ID} item={item} />
      ))}
    </UsageList>
  );
};

export default UsageSignupsTable;
