import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo } from 'react';
import useUser from '../../../../helpers/useUser';
import { READ_SIMULATIONS } from './simulationList.query';
import LoadingPreview from '../Loading/LoadingPreview';
import PreviewItem from '../PreviewItem/PreviewItem';
import { SimulationsList, StyledNoResults } from './SimulationList.styled';
import _ from 'lodash';
import useFlashMessage from '../../../FlashMessage';
import { CREATE_UNIT } from '../../courseForm.query';
import WithOnboardingHandler from '../../../../../../../HOCs/WithOnboardingHandler';
import wrapUnitType from '../../helpers/wrapUnitType';
import tracker from '../../../../helpers/tracker';

export const SimulationList = ({
  updateProgress,
  filter: titleFilter,
  groupId,
  setLoading,
}) => {
  const { addFlashMessage } = useFlashMessage();

  const { OrganisationID } = useUser();

  const variables = useMemo(() => {
    const filter = {
      TitleOrAdminTitle: { contains: titleFilter || '' },
      Organisation: { ID: { eq: OrganisationID } },
    };

    if (groupId) {
      filter.OrganisationGroups = { ID: { eq: groupId } };
      return { filter };
    }

    return { filter };
  }, [OrganisationID, groupId, titleFilter]);

  const { data, loading } = useQuery(READ_SIMULATIONS, {
    variables,
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  const simulations = useMemo(
    () =>
      loading && data
        ? [...Array(3)]
        : (data?.readSimulations?.edges || []).map(({ node }) => node),
    [data, loading]
  );

  const [createNewUnit] = useMutation(CREATE_UNIT);
  const addSim = useCallback(
    async (sim, module) => {
      const unit = module.Units.edges.find(
        ({ node }) => node.Simulation.ID === sim.ID
      );

      if (unit) {
        addFlashMessage(
          `Simulation '${sim.Title}' already exists in module '${module.Title}'.`,
          'error'
        );
        return;
      }

      try {
        const SortOrder =
          Math.max(
            ...module.Units.edges.map(({ node }) => node.SortOrder),
            module.Units.edges.length
          ) + 1;

        const variables = {
          Input: {
            SimulationID: sim.ID,
            ModuleID: module.ID,
            SortOrder,
            Type: 'Simulation',
          },
        };

        const ID = `new-unit-${(Math.random() * 100).toFixed(0)}`;
        const createUnit = {
          ID,
          SortOrder,
          Assessment: false,
          PassMark: 0,
          Simulation: sim,
          Type: 'Simulation',
          VirtualHuman: null,
          __typename: 'Unit',
          Module: {
            ID: module.ID,
            __typename: 'Module',
            Units: {
              edges: [...module.Units.edges, wrapUnitType(ID)],
              __typename: 'UnitsConnection',
            },
          },
        };

        await createNewUnit({
          variables,
          optimisticResponse: {
            createUnit,
          },
        });
      } catch (e) {
        addFlashMessage(
          `Failed to add simulation '${sim.Title}' to module '${module.Title}'.`,
          'error'
        );
        return;
      }
      updateProgress('add_course_unit');
      tracker.track('simulation_added_to_module', {
        simulation_id: sim.ID,
        module_id: module.ID,
      });
      addFlashMessage(
        `Added simulation '${sim.Title}' to module '${module.Title}'.`
      );
    },
    [createNewUnit, addFlashMessage, updateProgress]
  );

  return (
    <SimulationsList>
      {simulations.map((simulation, index) =>
        loading ? (
          <LoadingPreview key={index} />
        ) : (
          <PreviewItem
            key={simulation.ID}
            addItem={addSim}
            type="Simulation"
            loading={loading}
            data={simulation}
          />
        )
      )}
      {!loading && !simulations.length && (
        <StyledNoResults>No simulations found.</StyledNoResults>
      )}
    </SimulationsList>
  );
};

export default WithOnboardingHandler(SimulationList);
