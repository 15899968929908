import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useClickOutsideCallback } from '../../utility/EventHooks';
import {
  StyledCustomisedInputHeader,
  StyledLabel,
} from '../../styled-components/StyledCustomisedInputs';
import styled from 'styled-components';

import Details from '../../apps/core/src/components/FeedbackForms/Question/Details/Details';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CREATE_FEEDBACK_OPTION,
  READ_QUESTION,
} from '../../apps/core/src/components/FeedbackForms/Question/Option/Option.query';
import Option from '../../apps/core/src/components/FeedbackForms/Question/Option/Option';
import { Button, Icon } from '@virtidev/toolbox';

export const StyledCheckboxForm = styled.form`
  display: grid;

  width: 80%;
  margin: auto;
  background-color: var(--primary-bg-color);
  padding: 1rem 2rem;
  border-radius: var(--card-border-radius);
`;

export const StyledCheckboxFormOption = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 1rem;
  place-items: start;
  max-width: 20rem;
  padding: 1.5rem 0rem;
  text-align: center;
`;

const StyledOptionsTitle = styled.div`
  margin: 2rem 0rem 1rem 0rem;
  font-weight: 500;
`;

const StyledButtonWrapper = styled.div`
  margin: 1rem 0rem 2rem 0rem;
`;

const StyledPreviewLabel = styled.label`
  word-wrap: break-word;
  width: 15rem;
  max-width: 100%;
  text-align: left;
  line-height: 1.3rem;
`;

function CustomisedInputCheckbox(props) {
  //CONSIDER CONSOLIDATING THE CHECKBOX AND RADIO LOGIC SOMEHOW IF IT WORKS THE SAME
  const [previewChecked, setPreviewChecked] = useState('');
  const [questionData, setQuestionData] = useState(props.question);

  const questionRef = useRef();

  const [createFeedbackOption] = useMutation(CREATE_FEEDBACK_OPTION);

  const [readQuestion, { loading: loadingQuestion }] =
    useLazyQuery(READ_QUESTION);

  const handleNewData = useCallback(() => {
    // this this a bit of a workout around to update the UI with the new option
    // would like to move to just reading the cache, but currently readOneQuestion doesn' work in the cache
    readQuestion({
      variables: {
        filter: { ID: { eq: props.question.ID } },
      },
      onCompleted: (data) => {
        setQuestionData(data.readOneQuestion);
      },
    });
  }, [props.question.ID, readQuestion]);

  const handleAddNewOption = () => {
    props.setLoading(true);
    createFeedbackOption({
      variables: {
        input: {
          QuestionID: props.question.ID,
          Value: questionData.Options.nodes.length.toString(),
        },
      },
      onCompleted: () => {
        props.setLoading(false);
        handleNewData();
      },
      // update: (cache, result) => {
      //   console.log(result);

      //   const qData = cache.readQuery({
      //     query: READ_QUESTION, // NEED TO CHANGE THIS TO READ PAGE???
      //     variables: {
      //       filter: { ID: { eq: questionData.ID } },
      //     },
      //   });

      //   const newData = produce(qData, (state) => {
      //     state.readOneQuestion.Options.nodes = [
      //       ...state.readOneQuestion.Options.nodes,
      //       result.data.createFeedbackOption,
      //     ];
      //   });

      //   console.log(newData);

      //   cache.writeQuery({
      //     query: READ_QUESTION,
      //     variables: {
      //       ID: questionData.ID,
      //     },
      //     data: newData,
      //   });
      // },
    });
  };

  useClickOutsideCallback(questionRef, () => {
    setTimeout(() => {
      props.setEditing(false);
    }, 100);
  });

  return (
    <>
      {props.editing ? (
        <div>
          <Details
            editing={props.editing}
            register={props.register}
            typeTitle="Checkbox Question"
            info="Respondents can select multiple options."
            icon="checkbox"
          >
            <StyledOptionsTitle>Options</StyledOptionsTitle>
            {questionData?.Options.nodes.map((option) => {
              return (
                <Option
                  key={`option${option.ID}`}
                  id={`option${option.ID}`}
                  option={option}
                  setLoading={props.setLoading}
                  handleNewData={handleNewData} // this needs to change soon
                />
              );
            })}
            <StyledButtonWrapper>
              {questionData?.Options.nodes.length < 15 && (
                <Button
                  loading={loadingQuestion}
                  onClick={() => handleAddNewOption()}
                >
                  Add Option
                </Button>
              )}
            </StyledButtonWrapper>
          </Details>
        </div>
      ) : (
        <div>
          {' '}
          <StyledCustomisedInputHeader>
            <Icon icon="checkbox" size="35px" color="#ccc" />
          </StyledCustomisedInputHeader>
          <StyledLabel>{props.question.Title}</StyledLabel>
          <StyledCheckboxForm>
            {' '}
            {questionData.Options.nodes.map((item, index) => {
              return (
                <StyledCheckboxFormOption key={index + item.Name}>
                  <input
                    type="checkbox"
                    id={item.Name || 'checkbox' + index}
                    checked={previewChecked === index}
                    onChange={() => {
                      setPreviewChecked(index);
                    }}
                  />
                  <StyledPreviewLabel>{item.Name}</StyledPreviewLabel>
                </StyledCheckboxFormOption>
              );
            })}
          </StyledCheckboxForm>
        </div>
      )}
    </>
  );
}

CustomisedInputCheckbox.propTypes = {
  question: PropTypes.object,
  removeQuestion: PropTypes.func,
  disabled: PropTypes.bool,
  onQuestionEdit: PropTypes.func,
  otherQuestions: PropTypes.array,
  index: PropTypes.number,
};

export default CustomisedInputCheckbox;
