import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';
import { Redirect } from 'react-router-dom';
import FlashMessageHolder from '../components/FlashMessageHolder';
import { ACTIVATE_ACCOUNT } from '../queries/UserQueries';
import AccountActivationForm from '../forms/AccountActivationForm';
import withFlashMessaging from '../HOCs/WithFlashMessaging';

class AccountActivationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sending: false,
      sent: false,
    };
    this.activateAccount = null;
  }

  _submitActivationForm = (Email, Password, FirstName, Surname) => {
    if (!this.activateAccount) return;
    this.setState({
      sending: true,
    });
    this.activateAccount({
      variables: { Email, Password, FirstName, Surname },
    });
  };

  _newSubmit = (Email, Password, FirstName, Surname) => {
    this.activateAccount({
      variables: { Email, Password, FirstName, Surname },
    });
  };

  _sentEmail = () => {
    this.setState({
      sending: false,
      sent: true,
    });
  };

  render() {
    if (this.state.sent) {
      return <Redirect to={'/login'} />;
    }
    return (
      <div>
        <FlashMessageHolder />
        <Mutation
          mutation={ACTIVATE_ACCOUNT}
          onCompleted={() => {
            this._sentEmail();
            this.props.addFlashMessage(
              'You have successfully activated your account.',
              'success'
            );
          }}
          onError={(error) => {
            this.setState({
              sending: false,
            });
            const message =
              error?.graphQLErrors?.[0]?.message ||
              error?.networkError?.message ||
              'Unknown error';
            this.props.addFlashMessage(message, 'error');
          }}
        >
          {(activateAccount, { data }) => {
            this.activateAccount = activateAccount;
            return (
              <AccountActivationForm
                sending={this.state.sending}
                submitCallback={this._newSubmit}
                sent={this.state.sent}
              />
            );
          }}
        </Mutation>
      </div>
    );
  }
}

AccountActivationPage.propTypes = {
  userID: PropTypes.number,
  match: PropTypes.object.isRequired,
};

export default withFlashMessaging(AccountActivationPage);
