import { gql } from '@apollo/client';

export const CREATE_FEEDBACK_FORM = gql`
  mutation createFeedbackForm($Input: CreateFeedbackFormInput!) {
    createFeedbackForm(input: $Input) {
      ID
      Title
    }
  }
`;
