import { useQuery } from '@apollo/client';
import {
  READ_ENVIRONMENTS,
  READ_TEMPLATES,
  READ_VOICES,
} from './useVirtualHumanDefaults.query';
import { useMemo } from 'react';
import { useFeature } from '../../../LaunchDarkly';

/**
 * This function returns the default settings for a virtual human based on the provided locale and gender.
 *
 * @param {string} locale - The locale for the virtual human.
 * @param {"male" | "female" | ""} gender - The gender of the virtual human.
 * @param {number} age - The age of the virtual human.
 */
const useVirtualHumanDefaults = (locale, gender, age) => {
  const skip = !(locale && gender && age);

  const showAvatarEnvironmentsInPreview = useFeature(
    'show-avatar-environment-previews'
  );
  const showAvatarEnvironmentsInDevelopment = useFeature(
    'show-in-dev-avatars-environments'
  );

  const {
    data: voicesData,
    loading: voicesLoading,
    error: voicesError,
  } = useQuery(READ_VOICES, {
    variables: {
      filter: {
        Locale: { startswith: locale },
        Service: { ne: 'google' },
      },
    },
    skip,
  });

  const filterVars = useMemo(() => {
    return {
      environmentFilter: {
        ...(!showAvatarEnvironmentsInPreview
          ? { IsPreview: { eq: false } }
          : {}),
        ...(!showAvatarEnvironmentsInDevelopment
          ? { IsDevelopment: { eq: false } }
          : {}),
      },
      avatarFilter: {
        ...(!showAvatarEnvironmentsInPreview
          ? { IsPreview: { eq: false } }
          : {}),
        ...(!showAvatarEnvironmentsInDevelopment
          ? { IsDevelopment: { eq: false } }
          : {}),
      },
    };
  }, [showAvatarEnvironmentsInDevelopment, showAvatarEnvironmentsInPreview]);

  const {
    data: environmentsData,
    loading: environmentsLoading,
    error: environmentsError,
  } = useQuery(READ_ENVIRONMENTS, {
    variables: filterVars,
    skip,
  });

  const {
    data: templatesData,
    loading: templatesLoading,
    error: templatesError,
  } = useQuery(READ_TEMPLATES, { skip });

  const loading = voicesLoading || environmentsLoading || templatesLoading;
  const error = voicesError || environmentsError || templatesError;

  const data = useMemo(() => {
    if (loading || error || skip) return undefined;

    let environment = environmentsData?.readEnvironments?.nodes.find(
      (env) => env.Name === 'Office' && env.Avatars.nodes.length > 0
    );
    if (!environment) {
      environment = environmentsData?.readEnvironments?.nodes.find(
        (env) =>
          env.Name !== 'ExaminationRoom' &&
          env.Name !== 'PatientRoom' &&
          env.Avatars.nodes.length > 0 &&
          env.Avatars.nodes.some((node) => node.AvatarGeneration === '2')
      );
    }
    if (!environment) {
      environment = environmentsData?.readEnvironments?.nodes[0];
    }

    const avatars = environment.Avatars.nodes.filter(
      (avatarNode) => avatarNode.AvatarGeneration === '2' || avatarNode.Age < 14
    );

    function getSortBestMatch(isVoice = false) {
      return (a, b) => {
        // Gender takes precedence
        const aGender = `${a.Gender || ''}`.toLowerCase();
        const bGender = `${b.Gender || ''}`.toLowerCase();
        const lGender = gender.toLowerCase();

        if (aGender !== bGender) {
          if (aGender === lGender) {
            return -1;
          }
          if (bGender === lGender) {
            return 1;
          }
        }

        // Then Age
        if (isNaN(a.Age) && !isNaN(b.Age)) {
          return 1;
        }
        if (!isNaN(a.Age) && isNaN(b.Age)) {
          return -1;
        }
        if (!isNaN(a.Age) && !isNaN(b.Age)) {
          // If age is within the 'average adult voice' range, prioritise featured voices within that range
          const minAdultAverageAge = 18;
          const maxAdultAverageAge = 55;
          if (
            isVoice &&
            age >= minAdultAverageAge &&
            age <= maxAdultAverageAge
          ) {
            const aIsAverageAge =
              a.Age >= minAdultAverageAge && a.Age <= maxAdultAverageAge;
            const bIsAverageAge =
              b.Age >= minAdultAverageAge && b.Age <= maxAdultAverageAge;
            if (aIsAverageAge !== bIsAverageAge) {
              return aIsAverageAge ? -1 : 1;
            } else if (
              aIsAverageAge &&
              bIsAverageAge &&
              a.Featured !== b.Featured
            ) {
              return a.Featured ? -1 : 1;
            }
          }

          // Otherwise match the closest age
          const aDiff = Math.abs(parseInt(a.Age, 10) - age);
          const bDiff = Math.abs(parseInt(b.Age, 10) - age);
          return aDiff - bDiff;
        }

        // Then Featured
        if (a.Featured !== b.Featured) {
          return a.Featured ? -1 : 1;
        }

        // Finally, random
        return Math.random() - 0.5;
      };
    }

    const avatarID = avatars.toSorted(getSortBestMatch())?.[0]?.ID;
    const voiceID = voicesData?.readVoices?.nodes.toSorted(
      getSortBestMatch(true)
    )?.[0]?.ID;

    return {
      environmentID: environment.ID,
      avatarID,
      voiceID,
      vhSourceID: templatesData?.readVirtualHumanTemplates?.edges?.find(
        (edge) => edge.node.VirtualHuman.Type === 'freeform_v3'
      )?.node?.VirtualHuman.ID,
    };
  }, [
    loading,
    error,
    skip,
    environmentsData?.readEnvironments?.nodes,
    voicesData?.readVoices?.nodes,
    templatesData?.readVirtualHumanTemplates?.edges,
    age,
    gender,
  ]);

  return { ...data, loading, error };
};

export default useVirtualHumanDefaults;
