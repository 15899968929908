import { useReactiveVar } from '@apollo/client';
import { produce } from 'immer';
import _ from 'lodash';
import { createContext, forwardRef, useContext, useMemo } from 'react';
import { userVar } from '../../../../ApolloReactiveVars';
import { User } from '../models/user.types';

/**
 * For reference only, this can be an enum in typescript.
 *
 * The purpose is to list all possible user types that can be expected
 */
export const UserType = {
  user: 'user',
  contentcreator: 'contentcreator',
  admin: 'admin',
  super: 'super',
  customeradmin: 'customeradmin',
};

/**
 * @param {string} userRaw 
 * @returns {User}
 */
export const parseUser = (userRaw) => {
  return produce(/** @type {User} */(userRaw || {}), (item) => {
    if (isNaN(Number(item.ID))) {
      item.ID = null;
    }

    if (isNaN(item.OrganisationID)) {
      item.OrganisationID = null;
      item.Organisation = {};
    }

    // if user type is unknown, assume regular user
    if (!item.UserType || !Object.keys(UserType).includes(item.UserType)) {
      item.UserType = 'user';
    }

    if (typeof item.OnboardProgress === 'string') {
      item.OnboardProgress = JSON.parse(item.OnboardProgress);
    }
    if (!item.OnboardProgress) {
      item.OnboardProgress = null;
    }

    item.Organisations =
      item?.Organisations?.nodes || item?.Organisations || [];

    // ...etc... for userOrganisationName, userAccountID
    // refer to WithUser file
  });
};

// to get the non-memoized version of the user data, handy for using in functions
export const getUser = () => {
  const userRaw = userVar();
  try {
    return parseUser(userRaw);
  } catch (e) {}

  return null;
};

const UserContext = createContext(/** @type {User} */({}));

export const UserProvider = ({ children }) => {
  const userRaw = useReactiveVar(userVar);

  const user = useMemo(() => parseUser(userRaw) || {}, [userRaw]);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const user = useContext(UserContext);

  return user;
};

export const withUser = (WrappedComponent) => {
  const UserProvider = forwardRef((props, ref) => {
    const user = useUser();

    return (
      <WrappedComponent
        {...props}
        ref={ref}
        user={user}
        // userID for backwards compatibility
        userID={Number(user.ID)}
      />
    );
  });

  return UserProvider;
};

export default useUser;
