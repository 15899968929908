import styled from 'styled-components';

export const Canvas = styled.canvas`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  filter: grayscale(0.8);
`;
