import SplashPage from '@core/templates/components/SplashPage/SplashPage';
import React, { forwardRef, useState } from 'react';

export const withSplashPageTemplate =
  (pageProps = {}) =>
  (Component) => {
    const PageTemplate = forwardRef((props, ref) => {
      const [expanded, setExpanded] = useState(false);
      return (
        <SplashPage
          ref={ref}
          {...pageProps}
          expanded={expanded}
          setExpanded={setExpanded}
        >
          <Component {...props} expanded={expanded} setExpanded={setExpanded} />
        </SplashPage>
      );
    });

    return PageTemplate;
  };

export default withSplashPageTemplate;
