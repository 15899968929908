import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';
import {
  DELETE_MEMBER,
  READ_MEMBERS,
  READ_MEMBER,
} from '../../queries/UserQueries';
import WithFlashMessaging from '../../HOCs/WithFlashMessaging';
import WithConfirmationBox from '../../HOCs/WithConfirmationBox';
import { EllipsisMenuButton } from '../EllipsisMenu';
import Icon from '../icons/Icon';
import useUser from '../../apps/core/src/helpers/useUser';
import { useHistory } from 'react-router-dom';

const _updateCacheWithDeletedUser = (
  cache,
  data,
  userID,
  userOrganisationID,
  addFlashMessage
) => {
  try {
    if (cache.data?.ROOT_QUERY?.readMembers) {
      const { readMembers } = cache.readQuery({ query: READ_MEMBERS });
      const newMembers = {
        ...readMembers,
        edges: readMembers.edges.filter((edge) => edge.node.ID !== userID),
      };
      cache.writeQuery({
        query: READ_MEMBERS,
        data: { readMembers: newMembers },
        variables: {
          OrganisationID: userOrganisationID,
        },
      });
    }
    cache.writeQuery({
      query: READ_MEMBER,
      data: { readOneMember: null },
      variables: {
        ID: userID,
        OrganisationID: userOrganisationID,
      },
    });
  } catch (e) {
    console.error(e);
  }
  addFlashMessage('User has been suspended successfully', 'success', true);
};

const DeleteUserBtn = (props) => {
  const user = useUser();
  const history = useHistory();
  return (
    <Mutation
      mutation={DELETE_MEMBER}
      variables={{ ID: props.userID }}
      onError={() => {
        props.addFlashMessage(
          'There was an error when attempting to suspend the user',
          'error'
        );
      }}
      update={(cache, data) =>
        _updateCacheWithDeletedUser(
          cache,
          data,
          props.userID,
          props.userOrganisationID,
          props.addFlashMessage
        )
      }
    >
      {(deleteMembers, { loading, error }) => {
        return (
          <EllipsisMenuButton
            className={props.className}
            loading={loading}
            onClick={(event) => {
              event.preventDefault();
              props.confirm(
                async () => {
                  await deleteMembers();

                  if (user.ID === Number(props.userID)) {
                    const message = encodeURIComponent(
                      'Your account has been suspended.'
                    );
                    history.push({
                      pathname: '/logout',
                      search: `?msg=${message}`,
                    });
                  }
                },
                '',
                user.ID === Number(props.userID)
                  ? 'Suspend your own account?'
                  : 'Suspend this user?',
                'delete'
              );
            }}
          >
            <Icon type="lineawesome" name="trash-solid" /> Suspend User
          </EllipsisMenuButton>
        );
      }}
    </Mutation>
  );
};

DeleteUserBtn.propTypes = {
  userID: PropTypes.string.isRequired,
  addFlashMessage: PropTypes.func.isRequired,
  userOrganisationID: PropTypes.number.isRequired,
  confirm: PropTypes.func.isRequired,
};

export default WithConfirmationBox(WithFlashMessaging(DeleteUserBtn));
