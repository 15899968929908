import styled, { keyframes, css } from 'styled-components';

export const FlavourTextWrapper = styled.div`
  padding: 1.2rem;
  border-radius: 19px;
  font-size: 2rem;
`;

const revealDot = keyframes`
  0% {
    opacity: 0;
  }
  24% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
`;
const revealDot2 = keyframes`
  0% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
`;
const revealDot3 = keyframes`
  0% {
    opacity: 0;
  }
  74% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
`;

export const FlavourText = styled.div``;

export const FlavourEllipsis = styled.span`
  span {
    animation: ${revealDot} 1s steps(2, end) infinite;
  }
  span:nth-child(1) {
  }
  span:nth-child(2) {
    animation-name: ${revealDot2};
  }
  span:nth-child(3) {
    animation-name: ${revealDot3};
  }
`;
export const FlavourEllipsisDot = styled.span``;
