import styled from 'styled-components';
import newTheme from '../../../../../themes/new-theme';

export const Container = styled.div`
  ${newTheme}
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
`;

export const Splash = styled.img`
  width: 40%;
  height: 100%;
  object-fit: cover;
  object-position: right center;
`;

export const FormContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 30%;
  align-items: center;
  justify-content: center;
  background: #fff;
`;

export const Heading = styled.h1`
  font-size: 2rem;
  margin-bottom: 24px;
  text-align: center;
`;

export const Gap = styled.div`
  flex: 1;
  height: 10%;
  max-height: 100px;
  min-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 60%;
  max-width: 600px;
  margin: 0 auto;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const MessageContainer = styled.div`
  margin-bottom: 20px;
`;

export const ErrorPoint = styled.div`
  margin: 5px 20px;
  color: #f00;
`;
