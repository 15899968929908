import styled from 'styled-components';
import {
  DropdownLinksWrapper,
  DropdownLink,
  DropdownOnClickLink,
  DropdownLinkContent,
} from './DropdownLinks';
import useUser from '../apps/core/src/helpers/useUser';
import { useMemo } from 'react';
import { getResizeImage } from '../apps/core/src/helpers/imageResizing/getResizeImage';
import fallbackLogo from '../components/icons/avatar-default.jpeg';

const StyledLogo = styled.div`
  margin-right: 0.5rem;
  width: 1.6rem;
  height: 1.6rem;

  > img {
    width: 100%;
    height: 100%;
    border-radius: 60px;
  }
`;

const Dropdown = styled(DropdownLinksWrapper)`
  margin: 0 0.8rem;
`;

const StyledOrganisation = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  font-size: 0.9rem;
  min-width: 12rem;
`;

export const NavDropdown = () => {
  const { Organisation, Organisations } = useUser();

  const thereAreDropdownLinks = Organisations.length > 1;

  const logoSrc = useMemo(() => {
    if (Organisation?.LogoMedia?.TusID) {
      return getResizeImage(Organisation.LogoMedia.TusID, {
        resize: {
          width: 50,
          height: 50,
        },
      });
    }
    return Organisation?.LogoMedia?.URL || fallbackLogo;
  }, [Organisation]);

  const sortedOrgs = useMemo(() => {
    return [...(Organisations || [])]
      .filter((org) => !org.Archived)
      .filter((org) => parseInt(org.ID) !== Organisation?.ID)
      .sort((a, b) => a.Name.localeCompare(b.Name) || a.ID - b.ID);
  }, [Organisations, Organisation]);

  return (
    <Dropdown
      id="edit-organisation-dropdown"
      thereAreDropdownLinks={thereAreDropdownLinks}
    >
      <DropdownOnClickLink thereAreDropdownLinks={thereAreDropdownLinks}>
        <StyledOrganisation>
          <StyledLogo>
            <img
              alt={`${
                Organisation?.Name || '(no organisation)'
              } is currently open`}
              src={logoSrc}
            />
          </StyledLogo>
          {Organisation?.Name || ''}
        </StyledOrganisation>
        {thereAreDropdownLinks && (
          <DropdownLinkContent>
            {sortedOrgs.map((organisation) => (
              <DropdownLink
                key={organisation.ID}
                to={`/set-organisation/${organisation.ID}`}
              >
                {organisation.Name}
              </DropdownLink>
            ))}
          </DropdownLinkContent>
        )}
      </DropdownOnClickLink>
    </Dropdown>
  );
};

export default NavDropdown;
