import {
  AssetAction,
  AssetCard,
  AssetImageContainer,
  AssetSelectBox,
  AssetSelectIcon,
  AssetSelectIconContainer,
  AssetTitle,
  AssetTypeContainer,
  AssetTypeIcon,
} from '@core/components/AssetSelectModal/AssetSelectModal.styled';
import { Image } from '@virtidev/toolbox';
import { useCallback, useMemo } from 'react';

export const ImageCard = ({
  image,
  selected,
  disabled,
  onSelect,
  multiSelect,
}) => {
  const { ID, Content360, URL, Title } = image;

  const handleSelect = useCallback(() => {
    onSelect(ID);
  }, [onSelect, ID]);

  const actionLabel = useMemo(() => {
    if (multiSelect) {
      return selected ? 'Remove' : 'Add';
    }
    if (selected) {
      return null;
    }
    return 'Select';
  }, [multiSelect, selected]);

  return (
    <AssetCard
      onClick={handleSelect}
      $selected={selected}
      $disabled={disabled}
      $multiSelect={multiSelect}
    >
      {Content360 && (
        <AssetTypeContainer>
          <AssetTypeIcon />
        </AssetTypeContainer>
      )}
      {actionLabel && <AssetAction>{actionLabel}</AssetAction>}
      <AssetImageContainer>
        <AssetSelectBox />
        <AssetSelectIconContainer>
          <AssetSelectIcon />
        </AssetSelectIconContainer>
        <Image src={URL} cover />
      </AssetImageContainer>
      <AssetTitle>{Title}</AssetTitle>
    </AssetCard>
  );
};

export default ImageCard;
