import React from 'react';
import PropTypes from 'prop-types';
import Button from './buttons/Button';
import WithOnboardingHandler from '../HOCs/WithOnboardingHandler';
import { StyledDismissedMessage } from '../styled-components/StyledOnboardingDashboard';
import WithConfirmationBox from '../HOCs/WithConfirmationBox';
import { CanViewOnboarding } from '../utility/Permissions';

function OnboardingDismissedMessage(props) {
  return (
    <>
      {props.parsedData.onboardingDismissed && (
        <StyledDismissedMessage>
          Your onboarding has been dismissed. Would you like to restart?
          <Button
            disabled={props.loading}
            type="new-secondary"
            color={'#fff'}
            onClick={() =>
              props.confirm(
                () => {
                  props.resetOnboarding();
                },
                'Please confirm:',
                `Pressing ok will restart the onboarding process. You can dismiss this at any time.`
              )
            }
          >
            Restart Onboarding
          </Button>
        </StyledDismissedMessage>
      )}

      {!CanViewOnboarding() && (
        <StyledDismissedMessage>
          Your organisation does not have access to the onboarding feature.{' '}
          <br></br> If you think this is a mistake, please contact your
          administrator.
        </StyledDismissedMessage>
      )}
    </>
  );
}

OnboardingDismissedMessage.propTypes = {
  loading: PropTypes.bool,
  confirm: PropTypes.func.isRequired,
  resetOnboarding: PropTypes.func.isRequired,
};

export default WithConfirmationBox(
  WithOnboardingHandler(OnboardingDismissedMessage)
);
