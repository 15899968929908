import { useMutation } from '@apollo/client';
import AssetSelectModal from '@core/components/AssetSelectModal/AssetSelectModal';
import { useFlashMessage } from '@core/components/FlashMessage';
import { useFeature } from '@core/components/LaunchDarkly';
import getFileID from '@core/components/Media/components/MediaUploaders/helpers/getFileID';
import MixedMediaUploader from '@core/components/Media/components/MediaUploaders/MixedMediaUploader';
import {
  CREATE_SIM_MEDIA,
  CREATE_SIM_VIDEO,
  CREATE_WITH_EXISTING_MEDIA,
  CREATE_WITH_EXISTING_VIDEO,
} from '@core/components/Simulation/CreateSimForm/CreateSimForm.query';
import { TitleContainer } from '@core/components/Simulation/CreateSimForm/CreateSimForm.styled';
import tracker from '@core/helpers/tracker';
import useUser from '@core/helpers/useUser';
import { Button } from '@virtidev/toolbox';
import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

export const CreateSimForm = () => {
  const history = useHistory();
  const { addFlashMessage } = useFlashMessage();
  const [stillImageSimFeature] = useFeature(['image-simulation-support']);
  const { OrganisationID } = useUser();
  const [loading, setLoading] = useState(false);
  const [selecting, setSelecting] = useState(false);

  const [createSimVideo] = useMutation(CREATE_SIM_VIDEO, {
    update: (cache) => {
      cache.evict({ fieldName: 'readSimulations' });
    },
  });

  const [createSimMedia] = useMutation(CREATE_SIM_MEDIA, {
    update: (cache) => {
      cache.evict({ fieldName: 'readSimulations' });
    },
  });

  const [createWithExistingMedia] = useMutation(CREATE_WITH_EXISTING_MEDIA, {
    update: (cache) => {
      cache.evict({ fieldName: 'readSimulations' });
    },
  });

  const [createWithExistingVideo] = useMutation(CREATE_WITH_EXISTING_VIDEO, {
    update: (cache) => {
      cache.evict({ fieldName: 'readSimulations' });
    },
  });

  const acceptedTypes = useMemo(
    () => (stillImageSimFeature ? ['video', 'image'] : ['video']),
    [stillImageSimFeature]
  );

  const showSelect = useCallback(() => setSelecting(true), []);

  const hideSelect = useCallback(() => setSelecting(false), []);

  const handleUpload = useCallback(
    async (upload, { content360, type }) => {
      setLoading(true);
      let simId = null;

      if (type === 'video') {
        const result = await createSimVideo({
          variables: {
            URL: upload.url,
            Filename: upload.file.name,
            TusID: getFileID(upload.url),
            OrganisationID,
            Content360: content360,
          },
        });

        simId = result?.data?.createSimulationFromURL?.ID;
      }

      if (type === 'image') {
        const result = await createSimMedia({
          variables: {
            URL: upload.url,
            Filename: upload.file.name,
            TusID: getFileID(upload.url),
            OrganisationID,
            Content360: content360,
          },
        });

        simId = result?.data?.createSimulationFromMediaURL?.ID;
      }

      if (!simId) {
        return;
      }
      tracker.track('simulation_created', {
        sim_id: simId,
      });
      addFlashMessage('Simulation created', 'success', true);
      hideSelect();

      setTimeout(() => history.push(`/simulations/${simId}`), 200);
    },
    [
      OrganisationID,
      addFlashMessage,
      createSimMedia,
      createSimVideo,
      hideSelect,
      history,
    ]
  );

  const handleComplete = useCallback(async () => {
    setLoading(false);
  }, []);

  const handleError = useCallback(() => {
    setLoading(false);
  }, []);

  const handleSelectedMedia = useCallback(
    /* extend to support media proper later */
    async (item, type) => {
      setLoading(true);
      let simId = null;

      if (type === 'video') {
        const { data } = await createWithExistingVideo({
          variables: { videoID: item.ID },
        });
        simId = data?.createSimulationFromVideoMedia?.ID;
      }
      if (type === 'image') {
        const { data } = await createWithExistingMedia({
          variables: { mediaID: item.ID },
        });
        simId = data?.createSimulationFromMedia?.ID;
      }
      setLoading(false);

      if (!simId) {
        addFlashMessage(
          `Failed to create simulation with '${item.Title}'`,
          'error'
        );
        return;
      }

      addFlashMessage('Simulation created', 'success', true);
      hideSelect();

      setTimeout(() => history.push(`/simulations/${simId}`), 200);
    },
    [
      addFlashMessage,
      createWithExistingMedia,
      createWithExistingVideo,
      hideSelect,
      history,
    ]
  );

  return (
    <>
      <MixedMediaUploader
        title={
          <TitleContainer>
            <span id="upload-media-header">
              What type of media do you want to add?
            </span>
            <Button color="turquoise" design="transparent" onClick={showSelect}>
              Choose existing media
            </Button>
          </TitleContainer>
        }
        name="asset-uploader"
        acceptedTypes={acceptedTypes}
        warnPrompt
        onError={handleError}
        onCancel={handleError}
        onSuccess={handleUpload}
        onComplete={handleComplete}
        loading={loading}
      />
      <AssetSelectModal
        show={selecting}
        onHide={hideSelect}
        onConfirm={handleSelectedMedia}
        loading={loading}
        acceptedTypes={acceptedTypes}
      />
    </>
  );
};

export default CreateSimForm;
