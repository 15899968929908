import { useFlashMessage } from '@core/components/FlashMessage';
import { sendPostRequest } from '@vh/services/PDService';
import { useCallback, useState } from 'react';

/**
 * @typedef {import('../../../../models/vh-server/vh-server-coaching-session-report.types').CoachingSessionReport} CoachingSessionReport
 * @typedef {import('../../../../models/vh-server/vh-server-coaching-session-report.types').CoachingSessionReportObjective} CoachingSessionReportObjective
 */

/**
 * @type {({onComplete, onError}: {
 *  onStarted?: () => void;
 *  onComplete?: (report: CoachingSessionReport & {objectiveReports: CoachingSessionReportObjective[];}) => void;
 *  onError?: (e: any) => void;
 * }) => {
 *  generatingReport: boolean;
 *  generateReport: (assessmentId: number, regenerate?: boolean, shareToken?: string) => void
 * }}
 */
export const useGenerateSessionReport = ({
  onComplete,
  onError,
  onStarted,
}) => {
  const { addFlashMessage } = useFlashMessage();
  const [generatingReport, setGeneratingReport] = useState(false);
  const generateReport = useCallback(
    async (assessmentId, regenerate = false, shareToken = undefined) => {
      if (generatingReport) {
        addFlashMessage('A report is already being generated', 'warning');
        return;
      }
      setGeneratingReport(true);
      try {
        if (onStarted) {
          onStarted();
        }
        const response = await sendPostRequest(
          `assessments/${assessmentId}/coaching-session-report`,
          {
            regenerate,
          },
          null,
          false,
          shareToken
        );
        if (response?.data?.data) {
          if (onComplete) {
            console.log('response?.data?.data');
            console.log(response?.data?.data);
            onComplete(response.data.data);
          }
        } else {
          addFlashMessage('Failed to parse response', 'error');
        }
      } catch (e) {
        addFlashMessage('Failed to generate report', 'error');
        if (onError) {
          onError(e);
        }
      } finally {
        setGeneratingReport(false);
      }
    },
    [generatingReport, addFlashMessage, onStarted, onComplete, onError]
  );
  return { generateReport, generatingReport };
};
