import { gql } from '@apollo/client';

export const UPDATE_FEEDBACK_DETAILS = gql`
  mutation updateFeedbackDetails($input: UpdateFeedbackFormInput!) {
    updateFeedbackForm(input: $input) {
      ID
      Title
      AdminTitle
      Description
    }
  }
`;
