import { Icon } from '@virtidev/toolbox';
import React from 'react';
import { StyledLinkAndTitle, StyledLinkText } from './InviteCodes.styled';

export const CopyLink = ({ children }) => {
  return (
    <StyledLinkAndTitle>
      <Icon icon="link" type="outline" />
      <StyledLinkText>{children}</StyledLinkText>
    </StyledLinkAndTitle>
  );
};

export default CopyLink;
