import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LinkWithPrevState from '../LinkWithPrevState';
import { ButtonLink } from '../buttons/Button';
import StyledScorePercentage from '../../styled-components/StyledScorePercentage';
import Breakpoints from '../../themes/Breakpoints';
import { Datetime, FALLBACK, Icon, Loading } from '@virtidev/toolbox';
import { getResizeImage } from '../../apps/core/src/helpers/imageResizing/getResizeImage';

const StyledItem = styled.div`
  border-radius: var(--card-border-radius);
  background-color: var(--card-bg-color);
  box-shadow: var(--card-box-shadow);
  position: relative;
`;

const StyledImageLink = styled(LinkWithPrevState)`
  position: relative;
  display: block;
  &:hover {
    opacity: var(--image-link-opacity-hover);
  }
  &:active {
    opacity: var(--image-link-opacity-active);
  }
`;

const StyledTitleWrapper = styled.div`
  padding: 1.3rem 2rem;
  font-size: 1.4rem;
  border-bottom: var(--primary-border);
`;
const StyledTitle = styled.h3`
  color: var(--primary-font-color);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 0 3px 0;
  &:hover {
    text-decoration: underline;
  }
`;
const StyledPublishedState = styled.div`
  pointer-events: none;
  font-size: 1.2rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem 0.6rem;
  background-color: #000000cc;
  border-radius: 20px;
  span:first-child {
    margin-right: 0.5rem;
  }
`;

const PublishedStatusIconWrapper = styled.span`
  ${({ theme, isPublished }) => css`
    background-color: ${isPublished
      ? theme.color.primary
      : theme.color.lightGrey};
    display: inline-block;
    border-radius: 60px;
    padding: 0.5rem;
  `}
`;

// TODO: redo this when images are in
const StyledThumbnail = styled.img`
  width: 100%;
  display: block;
  height: 229px;
  max-height: 229px;
  object-fit: cover;
  border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
`;

const StyledTypeIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: transparent;
  border-radius: 50%;
  pointer-events: none;
`;

const StyledContent = styled.div`
  padding: 1rem;
  display: grid;
`;

const StyledDataWrapper = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.gridTemplateCols ?? '1fr 1fr 1fr 1fr'};
`;

const StyledDataHeader = styled.h5`
  text-align: center;
  font-size: 0.7rem;
  margin-bottom: 0.5rem;
  color: var(--tertiary-font-color);
  text-transform: uppercase;
`;

const StyledData = styled.div`
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
`;

const VirtualHumansList = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(3, calc(33.33333333% - 1.4rem));
  grid-gap: 2rem;
  @media (max-width: ${Breakpoints.large}) {
    grid-template-columns: repeat(2, calc(50% - 1.4rem));
  }
  @media (max-width: ${Breakpoints.med}) {
    grid-template-columns: 1fr;
  }
`;

const StyledAvgScoreWrapper = styled.div`
  grid-row: 1 / 3;
  grid-column: 2;
`;
const StyledAvgScore = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: 4rem;
  border-radius: 2rem;
  border: 0.4rem solid var(--high-avg-score-color);
  cursor: default;
  margin-top: 0.8rem;
  margin-left: auto;
  margin-right: auto;
  ${(props) =>
    props.score <= 50 &&
    css`
      border-color: var(--mid-avg-score-color);
    `}
  ${(props) =>
    props.score <= 20 &&
    css`
      border-color: var(--low-avg-score-color);
    `}
  ${(props) =>
    props.score === null &&
    css`
      border-color: var(--disabled-form-input-bg-color);
      cursor: help;
    `}
`;
const StyledLinksWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  > a:nth-child(1) {
    border-radius: 0 0 0 var(--card-border-radius);
  }
  > a:nth-child(2) {
    border-radius: 0 0 var(--card-border-radius) 0;
  }
`;

const StyledLastEdited = styled.div`
  color: var(--tertiary-font-color);
  margin-top: 0.7rem;
  font-size: 0.8rem;
`;

const ReStyledScorePercentage = styled(StyledScorePercentage)`
  font-weight: 500;
  font-size: 1.2rem;
`;

const PublishedStatus = (props) => {
  // show normal published/unpublished status unless there's something notable about transcoding status to show
  let statusText = props.status;
  if (statusText === 'Success') statusText = 'Unpublished';
  let icon = 'eye-slash';
  if (statusText === 'Published') icon = 'eye';
  return (
    // <div><Icon name=""></Icon> Published</div>
    <StyledPublishedState>
      <span>{statusText}</span>
      <PublishedStatusIconWrapper isPublished={statusText === 'Published'}>
        {icon === 'spinner' ? <Loading /> : <FontAwesomeIcon icon={icon} />}
      </PublishedStatusIconWrapper>
    </StyledPublishedState>
  );
};

const TypeIcon = (props) => {
  switch (props.type) {
    case 'medical':
      return <Icon icon="medical" type="solid" color="white" size="1.4rem" />;

    case 'branching':
      return <Icon icon="branch" type="outline" color="white" size="1.4rem" />;

    default:
      return (
        <Icon icon="speech-bubble" type="solid" color="white" size="1.4rem" />
      );
  }
};

const DataItem = (props) => (
  <div>
    <StyledDataHeader>{props.title}</StyledDataHeader>
    <StyledData>{props.data}</StyledData>
  </div>
);

const VirtualHumanItem = (props) => {
  // const patientLink = `/virtual-human-builder/patient/${props.data.ExternalID}/conversation`;
  const analyticsLink = `/virtual-humans/${props.data.ID}/analytics`;
  const editLink = `/virtual-humans/${props.data.ID}`;
  const thumbnailUrl = useMemo(() => {
    if (props.data?.ImageMedia?.TusID) {
      return getResizeImage(props.data?.ImageMedia?.TusID);
    } else if (props.data.Avatar?.ImageMedia?.URL) {
      return getResizeImage(props.data.Avatar?.ImageMedia?.TusID);
    }

    return props.data.ThumbnailURL || FALLBACK;
  }, [props.data]);
  return (
    <div>
      <StyledItem>
        <StyledImageLink to={editLink}>
          <StyledThumbnail src={thumbnailUrl} />
        </StyledImageLink>
        <StyledTitleWrapper>
          <LinkWithPrevState to={editLink}>
            <StyledTitle>{`${props.data.Title}${
              props.condition ? ' (' + props.condition + ')' : ''
            }`}</StyledTitle>
          </LinkWithPrevState>
          <StyledLastEdited>
            Last Edited on{' '}
            <Datetime
              datetime={props.data.LastEdited}
              long
              noTimeDisplay
              emptyDisplay="-"
            />
          </StyledLastEdited>
          <PublishedStatus status={props.data.Status} />
          <StyledTypeIconWrapper>
            <TypeIcon type={props.data.Type} />
          </StyledTypeIconWrapper>
        </StyledTitleWrapper>
        <StyledContent>
          <StyledDataWrapper
            gridTemplateCols={
              props.data.Type === 'medical' ? '1fr 1fr 1fr 1fr' : '1fr 1fr 1fr'
            }
          >
            <DataItem title="Users" data={props.data.TotalUsers} />
            <DataItem title="Sessions" data={props.data.Views} />
            <DataItem title="Completions" data={props.data.TotalCompletions} />
            {props.data.Type === 'medical' && (
              <div>
                <StyledDataHeader>Dx Correct</StyledDataHeader>
                <ReStyledScorePercentage
                  align="center"
                  fallback="-"
                  score={props.data.AverageDiagnosisScore}
                />
              </div>
            )}
          </StyledDataWrapper>
        </StyledContent>
        <StyledLinksWrapper>
          <ButtonLink
            dataTestId="simulation-link"
            type="gridded-box"
            to={editLink}
          >
            Edit
          </ButtonLink>
          {props.data.TranscodingStatus !== 'Transcoding' && (
            <ButtonLink type="gridded-box" to={analyticsLink}>
              Analytics
            </ButtonLink>
          )}
        </StyledLinksWrapper>
      </StyledItem>
    </div>
  );
};

VirtualHumanItem.propTypes = {
  data: PropTypes.shape({
    ID: PropTypes.string.isRequired,
    Title: PropTypes.string.isRequired,
    Views: PropTypes.number.isRequired,
    TotalUsers: PropTypes.number.isRequired,
    TotalCompletions: PropTypes.number.isRequired,
    AverageDiagnosisScore: PropTypes.string.isRequired,
  }),
  condition: PropTypes.string,
};

export { VirtualHumansList };
export default VirtualHumanItem;
