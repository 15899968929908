import { gql } from '@apollo/client';

export const DASHBOARD_MAIN = gql`
  query dashboard($organisationID: ID!) {
    dashboard(OrganisationID: $organisationID) {
      SystemNotice
      SystemNoticeStartDate
      SystemNoticeEndDate
      LastUpdated
      FeatureUpdates {
        ID
        Created
        Title
        ContentShort
        ContentLong
        VideoURL
        StartDate
        EndDate
      }
    }
  }
`;

export const DASHBOARD_STUDENT = gql`
  query dashboardStudent($OrgID: ID!) {
    readOneOrganisation(filter: { ID: { eq: $OrgID } }) {
      ID
      Name
      Description
      LogoMedia {
        TusID
        URL
      }
      FeaturedSimulations: Simulations(
        filter: {
          Featured: { eq: true }
          CoursesOnly: { ne: true }
          Status: { eq: "Published" }
        }
        limit: 3
        offset: 0
      ) {
        edges {
          node {
            ID
            Created
            Status
            Likes
            isLiked
            Title
            Description
            Content360
            Featured
            TotalViews
            isFavourite
            isLiked
            DownloadVersion
            LastEdited
            LastSaved
            ImageMediaID
            ImageMedia {
              TusID
              URL
            }
            FirstVideo: Videos(limit: 1) {
              nodes {
                PosterURL
              }
            }
          }
        }
      }
      Simulations: Simulations(
        limit: 6
        offset: 0
        filter: { Status: { eq: "Published" }, CoursesOnly: { ne: true } }
        sort: { Created: DESC }
      ) {
        edges {
          node {
            ID
            Created
            Status
            Likes
            isLiked
            Title
            Description
            Content360
            Featured
            TotalViews
            isFavourite
            isLiked
            DownloadVersion
            LastEdited
            LastSaved
            ImageMediaID
            ImageMedia {
              TusID
              URL
            }
            FirstVideo: Videos(limit: 1) {
              nodes {
                PosterURL
              }
            }
          }
        }
      }
      Courses(
        limit: 6
        offset: 0
        filter: { Status: { eq: "Published" } }
        sort: { Created: DESC }
      ) {
        edges {
          node {
            ID
            Status
            Likes
            Views
            isLiked
            Title
            Popularity
            Description
            MemberProgress
            ImageMedia {
              TusID
              URL
            }
          }
        }
      }
      VirtualHumanAPI
      VirtualHumans: VirtualHumans(
        limit: 6
        offset: 0
        filter: { Status: { eq: "Published" }, CoursesOnly: { ne: true } }
        sort: { Created: DESC }
      ) {
        pageInfo {
          totalCount
        }
        edges {
          node {
            ID
            Created
            LastEdited
            AuthorID
            Title
            Description
            Status
            ThumbnailURL
            Views
            Likes
            Featured
            CoursesOnly
            DownloadVersion
            ExternalID
            Avatar {
              ID
              Name
              Age
              Gender
              ImageMedia {
                ID
                URL
                TusID
              }
            }
            ImageMedia {
              ID
              URL
              TusID
            }
          }
        }
      }
    }
  }
`;
