import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/client/react/components';

import withUser from '../HOCs/WithUser';
import StyledPage, {
  StyledTopArea,
  StyledPageContentInner,
  PageContentCard,
} from '../components/StyledPage';
import UserProfileForm from '../forms/UserProfileForm';
import ChangePasswordProfileForm from '../forms/ChangePasswordProfileForm';
import SignOutAllDevicesForm from '../forms/SignOutAllDevicesForm';
import { READ_MEMBER } from '../queries/UserQueries';
import LoadingPageIndicator from '../components/LoadingPageIndicator';
import PageLoadError from '../components/PageLoadError';
import RedirectWithPrevState from '../components/RedirectWithPrevState';
import StyledPageHeader from '../styled-components/StyledPageHeader';

class UserProfilePage extends Component {
  render() {
    if (!this.props.userID) {
      return <RedirectWithPrevState to="/login" />;
    }
    return (
      <StyledPage hasSaveBar={true}>
        <StyledPageContentInner>
          <StyledTopArea>
            <StyledPageHeader>Account Settings</StyledPageHeader>
          </StyledTopArea>
          <PageContentCard>
            <Query
              variables={{
                ID: this.props.userID,
                OrganisationID: this.props.userOrganisationID,
              }}
              query={READ_MEMBER}
            >
              {({ loading, error, data }) => {
                if (loading) return <LoadingPageIndicator />;
                if (error) return <PageLoadError graphQLErrorObj={error} />;
                return (
                  <>
                    <UserProfileForm
                      showGroups={false}
                      dataObject={data.readOneMember}
                    />
                    <ChangePasswordProfileForm />
                    <SignOutAllDevicesForm />
                  </>
                );
              }}
            </Query>
          </PageContentCard>
        </StyledPageContentInner>
      </StyledPage>
    );
  }
}

UserProfilePage.propTypes = {
  userID: PropTypes.number,
  userOrganisationID: PropTypes.number,
};

export default withUser(UserProfilePage);
