import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { StyledPageContentInner } from '../components/StyledPage';
import { READ_ORGANISATIONS_CX } from '../queries/OrganisationQueries';
import withUser from '../HOCs/WithUser';
import CXDashboardTabNav from '../components/CXDashboardTabNav';
import WithCXDashboardPageTemplate from '../HOCs/templates/WithCXDashboardPageTemplate';
import { useQuery } from '@apollo/client';
import { CX_ANALYTICS } from '../queries/CXQueries';
import LoadingPageIndicator from '../components/LoadingPageIndicator';
import PageLoadError from '../components/PageLoadError';
import CXAnalytics from '../apps/core/src/components/CXAnalytics';

const CXAnalyticsPage = (props) => {
  // so a refresh can happen occasionally to bring forward updated organisation data
  const { loading, error, data } = useQuery(CX_ANALYTICS);
  const {
    loading: loadingOrgs,
    error: errorOrgs,
    data: dataOrgs,
  } = useQuery(READ_ORGANISATIONS_CX);

  return (
    <>
      <CXDashboardTabNav />
      <StyledPageContentInner>
        {error && <PageLoadError error={error} />}
        {errorOrgs && <PageLoadError error={errorOrgs} />}
        {loading && <LoadingPageIndicator />}
        {!loading &&
          !loadingOrgs &&
          data?.cxAnalytics &&
          dataOrgs?.readOrganisations?.edges && (
            <CXAnalytics
              dataObj={data.cxAnalytics}
              orgsDataObj={dataOrgs.readOrganisations.edges}
            />
          )}
      </StyledPageContentInner>
    </>
  );
};

CXAnalyticsPage.propTypes = {
  // userOrganisationID: PropTypes.string.isRequired,
};

export default withUser(WithCXDashboardPageTemplate(CXAnalyticsPage));
