import EmailForm from '@core/components/Signup/RegisterForm/components/EmailForm';
import NameForm from '@core/components/Signup/RegisterForm/components/NameForm';
import {
  RegisterFormContainer,
  RegisterFormLogo,
  RegisterFormTitle,
} from '@core/components/Signup/RegisterForm/RegisterForm.styled';
import RegisterInfo from '@core/components/Signup/RegisterInfo/RegisterInfo';
import { FC, useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  registerOrgFormSchema,
  registerFormSchema,
} from '@core/components/Signup/RegisterForm/helpers/register-form.schema';
import { RegisterFormInput } from '@core/components/Signup/RegisterForm/helpers/register-form.types';
import { ErrorContainer } from '@core/components/form/FieldError/FieldError.styled';

/**
 * @type {FC<{
 *    title?: string | null,
 *    error?: string | null,
 *    onSubmit: (input: RegisterFormInput) => void,
 *    trialLength?: number,
 *    existingOrg?: boolean,
 *    loading?: boolean
 * }>}
 */
export const RegisterForm = ({
  loading,
  title,
  error,
  onSubmit,
  trialLength,
  existingOrg,
}) => {
  const [stage, setStage] = useState(
    /** @type {"email" | "name"} */
    ('email')
  );

  /** @type {RegisterFormInput} */
  const defaultValues = {
    Email: '',
    Password: '',
    AcceptPrivacy: false,
    FirstName: '',
    Surname: '',
    OrganisationName: '',
  };
  const formMethods = useForm({
    defaultValues,
    resolver: zodResolver(
      existingOrg ? registerFormSchema : registerOrgFormSchema
    ),
  });

  const { handleSubmit: submit } = formMethods;

  const saveEmail = useCallback(() => {
    setStage('name');
  }, []);

  const submitForm = useMemo(
    () => submit((input) => onSubmit(input)),
    [onSubmit, submit]
  );

  return (
    <FormProvider {...formMethods}>
      {!existingOrg && (
        <RegisterInfo
          hideDetails={stage === 'name'}
          trialLength={trialLength}
        />
      )}
      <RegisterFormContainer>
        <RegisterFormLogo src="/images/virti-dark.svg" />
        <RegisterFormTitle>{title || 'Create account'}</RegisterFormTitle>
        {stage === 'email' ? (
          <EmailForm onSubmit={saveEmail}>
            {error && <ErrorContainer>{error}</ErrorContainer>}
          </EmailForm>
        ) : (
          <NameForm
            loading={loading}
            onSubmit={submitForm}
            existingOrg={existingOrg}
          >
            {error && <ErrorContainer>{error}</ErrorContainer>}
          </NameForm>
        )}
      </RegisterFormContainer>
    </FormProvider>
  );
};

export default RegisterForm;
