import zod from 'zod';

export const validateRegistration = zod.object({
  FirstName: zod.any(),
  Surname: zod.any(),
  Email: zod.any(),
  ExternalID: zod.any(),
  Hash: zod.any(),
  Password: zod.string().min(8, { message: 'Password is required' }),
});

export const validateAPIURL = zod.object({
  Endpoint: zod.string().url(),
});
