import { useMutation } from '@apollo/client';
import { produce } from 'immer';
import { useCallback, useEffect, useState } from 'react';
import { READ_FEEDBACK_FORM } from '../../../../../../queries/FeedbackFormQueries';
import useFlashMessage from '../../FlashMessage';
import QuestionV2 from '../Question/QuestionV2';
import { SORT_QUESTIONS } from './Container.query';

export const Container = ({ questions, updatePageValue, formID, ...props }) => {
  const [cards, setCards] = useState(questions);
  const [reordered, setReordered] = useState(false);

  const { addFlashMessage } = useFlashMessage();

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setCards((prevCards) =>
      produce(prevCards, (state) => {
        state.splice(dragIndex, 1);
        state.splice(hoverIndex, 0, prevCards[dragIndex]);
      })
    );
    setReordered(true);
  }, []);

  const [sortQuestions] = useMutation(SORT_QUESTIONS, {
    onCompleted: () => {
      props.setLoading(false);
    },
    onError: () => {
      props.setLoading(false);
      setCards(questions);
      addFlashMessage(
        'Something went wrong - could not be reordered.',
        'error'
      );
    },
    update: (cache, { data }) => {
      cache.evict({ fieldName: 'readOneFeedbackForm' });
    },
  });

  const handleOnDrop = useCallback(() => {
    props.setLoading(true);
    if (reordered) {
      const sortedIDs = cards.map(({ ID }) => ID);

      sortQuestions({
        variables: {
          FeedbackPageID: props.activePage.ID,
          QuestionIDs: sortedIDs,
        },
      });

      setReordered(false);
    }
  }, [reordered, cards, sortQuestions, props]);

  useEffect(() => {
    setCards(questions);
  }, [questions]);

  return (
    <>
      {cards.map((question, index) => {
        const formInput = question;
        const placeholder = question.ID.includes('placeholder');
        return (
          <div key={formInput.ID}>
            <QuestionV2
              type={formInput.Type}
              question={question}
              placeholder={placeholder}
              otherQuestions={questions}
              moveCard={moveCard}
              handleOnDrop={handleOnDrop}
              index={index}
              formID={formID}
              {...props}
            />
            {/* <Question
              type={formInput.Type}
              question={question}
              placeholder={placeholder}
              otherQuestions={questions}
              moveCard={moveCard}
              index={index}
              {...props}
            /> */}
          </div>
        );
      })}
    </>
  );
};
