import React from 'react';
import * as Styled from './VHVitals.styled';
import Vital from '../../../molecules/Vital/Vital';
import { useSelector } from 'react-redux';
import { currentVHStateSelector } from '../../../../slices/chat/ChatSlice';
import { useFeature } from '../../../../../../core/src/components/LaunchDarkly';

const VHVitals = (props) => {
  const currentState = useSelector(currentVHStateSelector);
  const vhBigUiFeature = useFeature('vh-big-ui');
  if (!currentState || !currentState?.vitals.length) return <></>;
  const vitals = currentState.vitals;
  return (
    <Styled.Wrapper $vhBigUiFeature={vhBigUiFeature}>
      {vitals.map((vital) => {
        return <Vital key={vital.id} vital={vital} />;
      })}
    </Styled.Wrapper>
  );
};

VHVitals.propTypes = {};

export default VHVitals;
