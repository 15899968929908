import { useApolloClient } from '@apollo/client';
import { produce } from 'immer';
import { useEffect, useState } from 'react';
import { StoreLogin } from '../../../../utility/LoginUtility';
import useUser from './useUser';

export const useCurrentOrganisation = (orgId, skip = false) => {
  const user = useUser();
  const client = useApolloClient();

  useEffect(() => {
    if (skip || !orgId) {
      return;
    }

    const { ID, OrganisationID } = user;
    if (!ID || !OrganisationID) {
      return;
    }

    const org = user.Organisations.find(({ ID }) => ID === orgId);

    if (orgId && !org) {
      console.error(
        `Unable to switch current organisation to one you do not belong to: ${orgId}`
      );
      return;
    }

    const data = produce(user, (draft) => {
      draft.Organisations = {
        nodes: user.Organisations,
      };
    });

    if (orgId !== OrganisationID) {
      setTimeout(() => {
        StoreLogin(null, null, data, client, orgId);
      });
    }
  }, [user, orgId, client, skip]);
};

export const withCurrentOrganisation = (Component) => {
  const CurrentOrganisationSwitcher = (props) => {
    const [orgId, setOrgId] = useState(null);

    useCurrentOrganisation(orgId);

    return <Component {...props} setCurrentOrg={setOrgId} />;
  };

  return CurrentOrganisationSwitcher;
};
