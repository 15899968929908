import { forwardRef, FC, HTMLAttributes } from 'react';
import { StyledComponentPropsWithAs } from 'styled-components';
import { Container } from './Label.styled';

/**
 * @type {FC<StyledComponentPropsWithAs<{
 *    horizontal?: boolean,
 * } & HTMLAttributes<HTMLLabelElement>>>}
 */
export const Label = forwardRef(({ horizontal, ...props }, ref) => (
  <Container ref={ref} {...props} $horizontal={horizontal} />
));

export default Label;
