import { useQuery } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import Button from '../../../../../../../components/buttons/Button';

import {
  StyledDataBlock,
  StyledDataBlockHeader,
  StyledDataBlockHeaderText,
} from '../../../../../../../styled-components/StyledDataBlocks';
import {
  S_scrollableWrapper,
  S_table,
  S_tbody,
  S_tdLeft,
  S_thLeft,
  S_thSticky,
  S_tr,
} from '../../../../../../../styled-components/StyledTable';
import LoadingPlaceholder from '../../../LoadingPlaceholder';
import { READ_ORGANISATIONS_CX_FEEDBACK } from './FeedbackUsage.query';
import {
  ReStyledToggle,
  StyledChevron,
  StyledShowMore,
} from './FeedbackUsage.styled';

export default function FeedbackUsage() {
  const [result, setResult] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [filterZeroForms, setFilterZeroForms] = useState(true);

  const limit = 100;

  const { data, loading, error } = useQuery(READ_ORGANISATIONS_CX_FEEDBACK, {
    variables: {
      limit: limit,
      offset: limit * (pageNumber - 1),
    },
    onCompleted: (newData) => {
      setResult([
        ...result,
        ...(newData
          ? newData.readOrganisations.edges.map(({ node }) => node)
          : []),
      ]);
    },
  });

  const handleClick = useCallback(() => {
    setPageNumber(pageNumber + 1);
  }, [pageNumber]);

  const handleToggle = useCallback(() => {
    setFilterZeroForms(!filterZeroForms);
  }, [filterZeroForms]);

  return (
    <div>
      <StyledDataBlock>
        <StyledDataBlockHeader>
          <StyledDataBlockHeaderText>
            Feedback Form Usage
          </StyledDataBlockHeaderText>
          <ReStyledToggle
            size="medium"
            label="Filter Organisations With 0 Forms:"
            name="filterZeroForms"
            value={filterZeroForms}
            checked={filterZeroForms}
            onChange={handleToggle}
          />
        </StyledDataBlockHeader>
        <S_scrollableWrapper>
          <S_table>
            <S_tbody>
              <S_tr>
                <S_thSticky>Organisation</S_thSticky>
                <S_thSticky>Feedback Forms</S_thSticky>
                <S_thSticky>Form Submissions</S_thSticky>
              </S_tr>
              {error && (
                <tr>
                  <td colSpan={3} style={{ textAlign: 'center' }}>
                    Something went wrong...
                  </td>
                </tr>
              )}
              {result
                .filter(({ FeedbackForms }) => {
                  return (
                    !filterZeroForms || FeedbackForms.pageInfo.totalCount > 0
                  );
                })
                .map((node) => (
                  <S_tr key={node.ID}>
                    <S_thLeft>{node.Name}</S_thLeft>
                    <S_tdLeft>
                      {node.FeedbackForms.pageInfo.totalCount}
                    </S_tdLeft>
                    <S_tdLeft>{node.FeedbackFormSubmissionsCount}</S_tdLeft>
                  </S_tr>
                ))}

              {loading &&
                [...Array(10)].map((item, index) => (
                  <S_tr key={index}>
                    <S_thLeft>
                      <LoadingPlaceholder />
                    </S_thLeft>
                    <S_tdLeft>
                      <LoadingPlaceholder />
                    </S_tdLeft>
                    <S_tdLeft>
                      <LoadingPlaceholder />
                    </S_tdLeft>
                  </S_tr>
                ))}
            </S_tbody>
          </S_table>
        </S_scrollableWrapper>
        <StyledShowMore>
          <Button
            disabled={
              loading || !data?.readOrganisations?.pageInfo?.hasNextPage
            }
            type="secondary"
            onClick={handleClick}
          >
            Show More
            <StyledChevron icon="chevron" type="outline" />
          </Button>
        </StyledShowMore>
      </StyledDataBlock>
    </div>
  );
}
