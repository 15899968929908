import styled from 'styled-components';

export const Header = styled.h1`
  min-width: 250px;
  transition: width var(--sidebar-anim-duration);
  padding: 1rem 1.5rem 1rem 1.5rem;
  font-size: 1.6rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
