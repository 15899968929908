import { gql } from '@apollo/client';

export const READ_ORGANISATIONS = gql`
  query readOrganisations(
    $filter: OrganisationFilterFields
    $limit: Int
    $offset: Int
    $sort: OrganisationSortFields
  ) {
    readOrganisations(
      filter: $filter
      limit: $limit
      offset: $offset
      sort: $sort
    ) {
      pageInfo {
        totalCount
      }
      edges {
        node {
          ID
          Name
          Licenses
          TotalUsers
          TotalAdmins
          CustomerStage
          Partner
        }
      }
    }
  }
`;
