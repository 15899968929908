import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';
import ChangePasswordForm from '../forms/ChangePasswordForm';
import withFlashMessaging from '../HOCs/WithFlashMessaging';
import FlashMessageHolder from '../components/FlashMessageHolder';
import { CHANGE_PASSWORD } from '../queries/PasswordQueries';
import { withUser } from '../apps/core/src/helpers/useUser';

class ChangePasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sending: false,
      changed: false,
      error: null,
    };
    this.mutateChangePassword = null;

    this.query = new URLSearchParams(props.location.search);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.query = new URLSearchParams(nextProps.location.search);
    }
  }

  _changedPassword = (data) => {
    this.props.addFlashMessage(
      'Successfully changed password.',
      'success',
      true,
      '/change-password'
    );
    this.setState({
      changed: true,
    });
  };

  _errorOnChange = (error) => {
    this.props.addFlashMessage(
      _.get(
        error,
        'graphQLErrors[0].message',
        'There was an error updating your password.'
      ),
      'error'
    );
    this.setState({
      sending: false,
    });
  };

  _submitForm = (email, password) => {
    const id = this.props.userID || this.query?.get?.('id');
    if (!this.mutateChangePassword || this.state.sending || !id) return;
    this.setState(
      {
        sending: true,
      },
      () => {
        this.mutateChangePassword({
          variables: {
            Password: password,
            Token: this.query?.get?.('t'),
            ID: parseInt(id),
          },
        });
      }
    );
  };

  render() {
    const id = this.props.userID || this.query?.get?.('id');
    if (this.state.changed || !id) {
      return <Redirect to={'/login'} />;
    }
    return (
      <div>
        <FlashMessageHolder />
        <Mutation
          mutation={CHANGE_PASSWORD}
          onError={this._errorOnChange}
          onCompleted={this._changedPassword}
        >
          {(mutateChangePassword, { data }) => {
            this.mutateChangePassword = mutateChangePassword;
            return (
              <ChangePasswordForm
                sending={this.state.sending}
                submitCallback={this._submitForm}
              />
            );
          }}
        </Mutation>
      </div>
    );
  }
}

ChangePasswordPage.propTypes = {
  userID: PropTypes.number,
  location: PropTypes.object.isRequired,
  addFlashMessage: PropTypes.func.isRequired,
};

export default withFlashMessaging(withUser(ChangePasswordPage));
