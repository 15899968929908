import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ConfirmationModal } from '@virtidev/toolbox';
import { RadioSelectWrapper } from './RadioSelect.styled';

const RadioSelect = (props) => {
  return (
    <RadioSelectWrapper>
      <fieldset disabled={!!props.disabled}>
        <legend>{props.legend}</legend>
        {props.options.map((option) => (
          <label key={option.value}>
            <input
              type="radio"
              name={props.name}
              value={option.value}
              checked={props.value === option.value}
              onChange={props.onChange}
            />
            {option.label}
          </label>
        ))}
      </fieldset>
    </RadioSelectWrapper>
  );
};

RadioSelect.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  legend: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

export default RadioSelect;
