import { gql } from '@apollo/client';

export const SAVE_REGISTER_SIM = gql`
  mutation saveRegisterSim($input: UpdateSimulationInput!) {
    updateSimulation(input: $input) {
      ID
      ShowRegister
    }
  }
`;

export const SEND_EMAIL_SHARE_SIM = gql`
  mutation sendEmailShareSim($simId: ID!, $emails: [String]!) {
    emailSimulationShareLink(SimulationID: $simId, Emails: $emails)
  }
`;
