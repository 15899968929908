import _ from 'lodash';
import { StyledWrapper } from '../../CXAnalytics.styled';
import { useQuery } from '@apollo/client';
import CX_ANALYTICS from '../../cxAnalytics.query';

// todo migrate these files
import { StyledDataBlock } from '../../../../../../../styled-components/StyledDataBlocks';
import {
  S_table,
  S_tbody,
  S_td,
  S_thLeft,
  S_tr,
} from '../../../../../../../styled-components/StyledTable';
import StyledLabel from '../../../../../../../styled-components/StyledLabel';
import HelpTooltip from '../../../../../../../components/HelpTooltip';

export const Activity = ({ OrgID }) => {
  const { data, loading, error } = useQuery(CX_ANALYTICS);

  const { TotalUsers, ActiveUsers, MAU, Licenses } = _.get(
    data,
    'cxAnalytics',
    {}
  );
  return (
    <StyledWrapper>
      <StyledDataBlock>
        <StyledLabel>Activity</StyledLabel>
        <S_table>
          <S_tbody>
            <S_tr>
              <S_thLeft>Total Users</S_thLeft>
              <S_td>{TotalUsers}</S_td>
            </S_tr>
            <S_tr>
              <S_thLeft>Active Users</S_thLeft>
              <S_td>{ActiveUsers}</S_td>
            </S_tr>
            <S_tr>
              <S_thLeft>Last Month Unique Users</S_thLeft>
              <S_td>{MAU}</S_td>
            </S_tr>
            <S_tr>
              <S_thLeft>Licenses Sold</S_thLeft>
              <S_td>{Licenses}</S_td>
            </S_tr>
          </S_tbody>
        </S_table>
      </StyledDataBlock>
    </StyledWrapper>
  );
};

export default Activity;
