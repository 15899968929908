import { gql } from '@apollo/client';

export const READ_AVATARS = gql`
  query readAvatars {
    readAvatars {
      nodes {
        ID
        Name
        DisplayName
        Age
        Gender
        IsPreview
        IsDevelopment
        AvatarGeneration
        ImageMedia {
          ID
          TusID
          URL
        }
        AvatarMedia {
          ID
          TusID
          URL
        }
        DefaultVoice {
          ID
          Name
        }
        DefaultVoiceID
      }
    }
  }
`;

export const UPDATE_AVATAR = gql`
  mutation updateAvatar($input: UpdateAvatarInput!) {
    updateAvatar(input: $input) {
      ID
      Name
      DisplayName
      Age
      Gender
      IsPreview
      IsDevelopment
      AvatarGeneration
      ImageMedia {
        ID
        TusID
        URL
      }
      DefaultVoice {
        ID
        Name
      }
      DefaultVoiceID
    }
  }
`;

export const CREATE_AVATAR = gql`
  mutation createAvatar($input: CreateAvatarInput!) {
    createAvatar(input: $input) {
      ID
      Name
      DisplayName
      Age
      Gender
      IsPreview
      IsDevelopment
      AvatarGeneration
      ImageMedia {
        ID
        TusID
        URL
      }
      DefaultVoice {
        ID
        Name
      }
      DefaultVoiceID
    }
  }
`;

export const DELETE_AVATARS = gql`
  mutation deleteAvatars($ids: [ID]!) {
    deleteAvatars(ids: $ids)
  }
`;
