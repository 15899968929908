import { useMutation } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { SelectUser } from '@core/components/form/Select';
import useFlashMessage from '@core/components/FlashMessage';
import {
  ADD_SIMULATION_EDITOR,
  REMOVE_SIMULATION_EDITOR,
} from './simulationEditors.query';

export const SimulationEditors = ({
  simulationId,
  usersData,
  loading,
  ...props
}) => {
  const { addFlashMessage } = useFlashMessage();
  const [addUser] = useMutation(ADD_SIMULATION_EDITOR);
  const [removeUser] = useMutation(REMOVE_SIMULATION_EDITOR);

  const selected = useMemo(() => {
    if (!usersData || !usersData.edges.length) {
      return [];
    }
    return usersData.edges.map(({ node: { ID, Name } }) => ({
      value: ID,
      label: Name,
    }));
  }, [usersData]);

  const handleAdd = useCallback(
    async (userId, title) => {
      await addUser({
        variables: {
          SimulationID: simulationId,
          MemberID: userId,
        },
        optimisticResponse: {
          addSimulationEditor: {
            ID: simulationId,
            __typename: 'Simulation',
            Editors: {
              edges: [
                ...usersData.edges,
                {
                  node: {
                    ID: userId,
                    Name: title,
                    __typename: 'Member',
                  },
                  __typename: 'EditorsEdge',
                },
              ],
              __typename: 'EditorsConnection',
            },
          },
        },
      });

      addFlashMessage(`Added editor '${title}' to simulation`);
    },
    [usersData, simulationId, addFlashMessage, addUser]
  );

  const handleRemove = useCallback(
    async (userId, title) => {
      if (!userId) {
        return;
      }
      await removeUser({
        variables: {
          SimulationID: simulationId,
          MemberID: userId,
        },
        optimisticResponse: {
          removeSimulationEditor: {
            ID: simulationId,
            __typename: 'Simulation',
            Editors: {
              edges: usersData.edges.filter(
                ({ node }) => Number(node.ID) !== Number(userId)
              ),
              __typename: 'EditorsConnection',
            },
          },
        },
      });

      addFlashMessage(`Removed editor '${title}' from simulation`);
    },
    [usersData, simulationId, addFlashMessage, removeUser]
  );

  return (
    <SelectUser
      {...props}
      value={selected}
      onAdd={handleAdd}
      onRemove={handleRemove}
      loading={loading}
      placeholder="Search..."
      multi
    />
  );
};

export default SimulationEditors;
