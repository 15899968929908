import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Select from '../../../../../../styled-components/StyledSelect';
import { useEffectOnlyOnce } from '../../../../../../utility/CustomHooks';
import { useIsMounted } from '../../../../../../utility/EventHooks';

const kinds = {
  audioinput: {
    name: 'Audio Input',
  },
  videoinput: {
    name: 'Video Input',
  },
};

const InputDeviceSelect = ({ kind, onChange, disabled }) => {
  const [devices, setDevices] = useState([]);
  const [currentDevice, setCurrentDevice] = useState('');
  const dummyStreamRef = useRef(null);
  const isMounted = useIsMounted();

  useEffectOnlyOnce(() => {
    async function getMedia() {
      const userMediaCaptureStream = await navigator.mediaDevices.getUserMedia({
        audio: kind === 'audioinput',
        video: kind === 'videoinput',
      });
      // setDummyStreamForEnablingDevicesListable(userMediaCaptureStream);
      dummyStreamRef.current = userMediaCaptureStream;
      navigator.mediaDevices
        .enumerateDevices()
        .then((deviceInfos) => {
          let devices = [];
          for (let i = 0; i !== deviceInfos.length; ++i) {
            const deviceInfo = deviceInfos[i];
            if (deviceInfo.kind === kind) {
              devices.push(deviceInfo);
            }
          }
          if (isMounted) {
            setDevices(devices);
            if (devices.length > 0) {
              setCurrentDevice(devices[0].deviceId);
              onChange(devices[0].deviceId);
            }
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
    getMedia();
    return () => {
      if (dummyStreamRef.current) {
        dummyStreamRef.current.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  });
  return (
    <Select
      onChange={(e) => {
        setCurrentDevice(e.target.value);
        onChange(e.target.value);
      }}
      disabled={disabled}
      value={currentDevice}
      label={kinds[kind].name}
    >
      <option value="">No {kinds[kind].name}</option>
      {devices.map((device) => (
        <option key={device.deviceId} value={device.deviceId}>
          {device.label}
        </option>
      ))}
    </Select>
  );
};

InputDeviceSelect.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  kind: PropTypes.string.isRequired,
};

export default InputDeviceSelect;
