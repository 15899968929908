import { useQuery } from '@apollo/client';
import {
  LoadingPlaceholder,
  TextInput,
  useDebouncedSave,
  useStateUrl,
} from '@virtidev/toolbox';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import {
  StyledDataBlock,
  StyledDataBlockHeader,
  StyledDataBlockHeaderText,
} from '../../../../../../../styled-components/StyledDataBlocks';
import {
  S_scrollableWrapper,
  S_table,
  S_tbody,
  S_thLeft,
  S_thSticky,
  S_tr,
} from '../../../../../../../styled-components/StyledTable';
import Label from '../../../form/Label';
import PaginationControl from '../../../PaginationControl';
import { usePaginationUrl } from '../../../PaginationControl/helpers/usePaginationUrl';

import { READ_ORGANISATIONS_SIGNUP_DATA } from './SignupUsage.query';
import TableRow from './TableRow';

const CenteredWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export default function SignupUsage() {
  const { page, pageSize, controlProps, resetPage } = usePaginationUrl({
    pageSize: 20,
  });
  const { value: filter, updateValue: setFilter } = useStateUrl({
    key: 'name',
    initialValue: '',
  });

  const handleFilterChange = useCallback(
    (value) => {
      resetPage();
      setFilter(value || '');
    },
    [setFilter, resetPage]
  );

  const debouncedProps = useDebouncedSave(filter, {
    onUpdate: handleFilterChange,
  });

  const variables = {
    limit: pageSize,
    offset: (page - 1) * pageSize,
    sort: { Created: 'DESC' },
    name: filter,
  };

  const { data, loading, error } = useQuery(READ_ORGANISATIONS_SIGNUP_DATA, {
    variables,
  });

  const refetchQueries = [
    {
      query: READ_ORGANISATIONS_SIGNUP_DATA,
      variables,
    },
  ];

  const organisations = useMemo(() => {
    if (!loading && data) {
      return data.readOrganisations.edges;
    } else {
      return [];
    }
  }, [data, loading]);

  return (
    <StyledDataBlock>
      <StyledDataBlockHeader>
        <StyledDataBlockHeaderText>Signup Usage </StyledDataBlockHeaderText>
        <StyledDataBlock
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Label horizontal>Name search</Label>
          <TextInput {...debouncedProps} />
        </StyledDataBlock>
      </StyledDataBlockHeader>
      <S_scrollableWrapper>
        <S_table>
          <S_tbody>
            <S_tr>
              <S_thSticky>Organisation</S_thSticky>
              <S_thSticky>Created</S_thSticky>
              <S_thSticky>SignupType</S_thSticky>
              <S_thSticky>Days Remaining</S_thSticky>

              <S_thSticky>Lead User</S_thSticky>

              <S_thSticky>Welcome Course Completion</S_thSticky>
              <S_thSticky>Contacted Sales</S_thSticky>
              <S_thSticky>Login as Lead User</S_thSticky>
              <S_thSticky>View</S_thSticky>
              <S_thSticky>Archive</S_thSticky>
            </S_tr>
            {error && (
              <tr>
                <td colSpan={11} style={{ textAlign: 'center' }}>
                  Something went wrong...
                </td>
              </tr>
            )}
            {!loading &&
              organisations.map(({ node }) => {
                return (
                  <TableRow
                    key={node.ID}
                    ID={node.ID}
                    name={node.Name}
                    signupType={node.SignupType}
                    member={
                      node.Members.edges.length > 0
                        ? node.Members.edges[0].node
                        : undefined
                    }
                    created={node.Created}
                    expiry={node.TrialExpiryDate}
                    refetchQueries={refetchQueries}
                  />
                );
              })}

            {loading &&
              [...Array(10)].map((item, index) => (
                <S_tr key={index + 'row'}>
                  {[...Array(10)].map((item, index) => {
                    return (
                      <S_thLeft key={index + 'dataitem'}>
                        <LoadingPlaceholder />
                      </S_thLeft>
                    );
                  })}
                </S_tr>
              ))}
          </S_tbody>
        </S_table>
      </S_scrollableWrapper>
      <CenteredWrapper>
        <PaginationControl
          {...controlProps}
          total={data?.readOrganisations?.pageInfo?.totalCount}
        />
      </CenteredWrapper>
    </StyledDataBlock>
  );
}
