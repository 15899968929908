import { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import withUser from '../HOCs/WithUser';
import StyledPage, {
  StyledPageContentInnerNoFlex,
  StyledTopArea,
} from '../components/StyledPage';
import GroupAddButtonAndForm from '../components/button-modal-forms/GroupAddButtonAndForm';
import RedirectWithPrevState from '../components/RedirectWithPrevState';
import StyledPageHeader from '../styled-components/StyledPageHeader';
import GroupTable from '../apps/core/src/components/Users/GroupTable/GroupTable';

const StyledGroupAddButtonAndForm = styled(GroupAddButtonAndForm)``;

class GroupsPage extends Component {
  render() {
    if (!this.props.userID) {
      return <RedirectWithPrevState to="/login" />;
    }
    return (
      <StyledPage pageKey="groups">
        <StyledPageContentInnerNoFlex>
          <StyledTopArea gridCols="40% 1fr">
            <StyledPageHeader>Groups</StyledPageHeader>
            <div>
              <StyledGroupAddButtonAndForm buttonType="material-white" />
            </div>
          </StyledTopArea>
          <GroupTable />
        </StyledPageContentInnerNoFlex>
      </StyledPage>
    );
  }
}

GroupsPage.propTypes = {
  userID: PropTypes.number,
  match: PropTypes.object.isRequired,
  userOrganisationID: PropTypes.number,
};

export default withUser(GroupsPage);
