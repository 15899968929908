import Axios from 'axios';
import { GetAccessToken, GetAIToken } from '../../../../utility/LoginUtility';
import { getVirtualHumanAPI } from '../../../../utility/VirtualHumanUtility';
import tracker from '../../../core/src/helpers/tracker';

export const sendGetRequest = (path, share_token = null) => {
  return Axios.get(`${getVirtualHumanAPI()}/api/${path}`, {
    headers: {
      Authorization: 'Bearer ' + GetAIToken(),
      ...(share_token ? { share_token } : {}),
    },
  });
};

export const sendDeleteRequest = (path) => {
  return Axios.post(`${getVirtualHumanAPI()}/api/${path}`, {
    headers: {
      Authorization: 'Bearer ' + GetAIToken(),
    },
  });
};

export const sendPostRequest = (
  path,
  formDataObj = null,
  ChatSessionId = null,
  rawResponse = false,
  share_token = null
) => {
  return Axios.post(`${getVirtualHumanAPI()}/api/${path}`, formDataObj, {
    headers: {
      Authorization: 'Bearer ' + GetAIToken(),
      ...(ChatSessionId ? { ChatSessionId } : {}),
      ...(share_token ? { share_token } : {}),
    },
    ...(rawResponse
      ? {
          transformResponse: (r) => r, //null transform (we do not want to parse as JSON);
        }
      : {}),
  });
};

export const sendSSGraphQLRequest = (
  endpoint,
  query,
  includeMixpanelID = true
) => {
  const mixpanelHeaderValue = JSON.stringify({
    channel: 'web_app',
    distinct_id: tracker?.get_distinct_id?.(),
  });
  return Axios({
    url: endpoint,
    method: 'post',
    data: {
      query,
    },
    headers: {
      Authorization: 'Bearer ' + GetAccessToken(),
      'Content-Type': 'application/json',
      ...(includeMixpanelID ? { 'X-Virti-Mixpanel': mixpanelHeaderValue } : {}),
    },
  });
};
