import { gql } from '@apollo/client';

export const READ_GROUPS_FOR_ACCESS = gql`
  query readVirtiGroupsSelect($filter: VirtiGroupFilterFields) {
    readVirtiGroups(filter: $filter) {
      nodes {
        ID
        Name
      }
    }
  }
`;

export const CREATE_GROUP = gql`
  mutation createGroupSelect($Input: CreateVirtiGroupInput!) {
    createVirtiGroup(input: $Input) {
      ID
      Name
    }
  }
`;
