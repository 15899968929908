import { gql } from '@apollo/client';

export const READ_COURSES = gql`
  query readCourses($filter: CourseFilterFields, $limit: Int, $offset: Int) {
    readCourses(filter: $filter, limit: $limit, offset: $offset) {
      pageInfo {
        totalCount
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ID
          Title
        }
      }
    }
  }
`;
