export const GetPercentColors = (
  number,
  upIsGood,
  negativeThreshold,
  warningThreshold
) => {
  if (upIsGood) {
    if (!negativeThreshold) {
      if (number > 0) {
        return 'var(--positive-color)';
      } else if (number === 0) {
        return 'var(--neutral-color)';
      } else {
        return 'var(--negative-color)';
      }
    } else {
      if (number > negativeThreshold && number < warningThreshold) {
        return 'var(--warning-color-2)';
      } else if (number > warningThreshold) {
        return 'var(--positive-color)';
      } else if (number === negativeThreshold) {
        return 'var(--neutral-color)';
      } else {
        return 'var(--negative-color)';
      }
    }
  } else {
    if (!negativeThreshold) {
      if (number < 0) {
        return 'var(--positive-color)';
      } else if (number === 0) {
        return 'var(--neutral-color)';
      } else {
        return 'var(--negative-color)';
      }
    } else {
      if (number < negativeThreshold && number > warningThreshold) {
        return 'var(--warning-color-2)';
      } else if (number === 0) {
        return 'var(--neutral-color)';
      } else if (number < warningThreshold) {
        return 'var(--positive-color)';
      } else {
        return 'var(--negative-color)';
      }
    }
  }
};
