import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { StyledWrapper } from '../../CXAnalytics.styled';
import { BlockWrapper } from '../../../../../../../styled-components/StyledDataBlocks';
import CxActivityByOrgTable from '../../../../../../../components/CxActivityByOrgTable';
import { READ_ORGANISATIONS_CX } from '../../../../../../../queries/OrganisationQueries';

export const ActivityByOrg = () => {
  const { loading, error, data } = useQuery(READ_ORGANISATIONS_CX);

  const organisations = _.get(data, 'readOrganisations.edges');

  if (!organisations) {
    return null;
  }

  return (
    <StyledWrapper>
      <BlockWrapper>
        <CxActivityByOrgTable orgsDataObj={organisations} />
      </BlockWrapper>
    </StyledWrapper>
  );
};

export default ActivityByOrg;
