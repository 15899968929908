import React from 'react';
import PropTypes from 'prop-types';
// import ReactQuill, { Quill } from 'react-quill';
import styled, { css } from 'styled-components';
// import 'react-quill/dist/quill.snow.css';

import StyledLabel from '../styled-components/StyledLabel';

import StyledTextArea from '../styled-components/StyledTextArea';

// this element temporarily the default while we transition into allowing for Quill-based html tags
const nonQuillTemporaryElement = props => (
  <StyledTextArea
    className={props.className}
    label={props.label}
    placeholder={props.placeholder}
    name={props.name}
    id={props.id}
    value={props.value || ''}
    labelTooltip={props.labelTooltip}
    rows={props.rows}
    cols={props.cols}
    maxLength={props.maxLength}
    dataTestId={props.dataTestId}
    onChange={(e) => props.onChange(e.target.value)} disabled={props.disabled}
  />
)
export default nonQuillTemporaryElement;



// // setup italic and bold to use b and i tags
// let Inline = Quill.import('blots/inline');
// class ItalicBlot extends Inline { }
// ItalicBlot.blotName = 'italic';
// ItalicBlot.tagName = 'i';
// Quill.register('formats/italic', ItalicBlot);
// class BoldBlot extends Inline { }
// BoldBlot.blotName = 'bold';
// BoldBlot.tagName = 'b';
// Quill.register('formats/bold', BoldBlot);

// const StyledInputWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
// `

// const StyledReactQuill = styled(ReactQuill)`
//   .ql-toolbar {
//     border: var(--input-border);
//     border-radius: 0.2rem 0.2rem 0 0;
//     background-color: #fff;
//   }
//   .ql-container {
//     background-color: #fff;
//     border-radius: 0 0 0.2rem 0.2rem;
//     font-size: 0.8rem;
//     font-family: var(--text-font);
//     border: var(--input-border);
//     &:focus {
//       outline: var(--input-focus-color) auto;    
//     }
//     ${props => props.invalid && css`
//       border-color: var(--validation-error-input-border-color);
//     `}
//   }
//   .ql-editor {
//     min-height: ${props => props.minHeight ? props.minHeight : '8.5rem'};
//     i {
//       font-style: italic;
//     }
//     b {
//       font-weight: bold;
//     }
//     ${props => props.disabled && css`
//       background-color: var(--disabled-form-input-bg-color);
//     `}
//   }
// `

// const TextEditorArea = props => {
//   return (
//     <StyledInputWrapper className={props.className}>
//       {props.label && (
//         <StyledLabel tooltip={props.labelTooltip} htmlFor={props.id}>{props.label}</StyledLabel>
//       )}
//       <StyledReactQuill
//         minHeight={props.minHeight}
//         value={props.value}
//         onChange={props.onChange}
//         readOnly={props.disabled}/**used by react quill */
//         disabled={props.disabled}/**used for styling */
//         id={props.id}
//         theme="snow"
//         formats={[
//           "bold",
//           "italic",
//         ]}
//         modules={{
//           toolbar: [
//             ['bold', 'italic'],
//           ]
//         }}
//       />
//     </StyledInputWrapper>
//   )
// }

// TextEditorArea.defaultProps = {
//   value: '',
//   invalid: false,
//   rows: '5'
// };

// TextEditorArea.propTypes = {
//   id: PropTypes.string.isRequired,
//   label: PropTypes.string,
//   name: PropTypes.string.isRequired,
//   value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   onChange: PropTypes.func.isRequired,
//   onKeyPress: PropTypes.func,
//   minHeight: PropTypes.string,
//   disabled: PropTypes.bool,
//   labelTooltip: PropTypes.string,
//   // type: PropTypes.string,
//   // invalid: PropTypes.bool,
//   // tooltip: PropTypes.string,
//   // tooltipSide: PropTypes.string,
// };

// // export default TextEditorArea;