import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${(props) =>
    props.$vhBigUiFeature &&
    css`
      overflow: auto;
      display: grid;
      grid-template-rows: repeat(5, 1fr);
      grid-row-gap: 0.4rem;
      padding: 1.5rem;
    `}
  ${(props) =>
    !props.$vhBigUiFeature &&
    css`
      display: grid;
      grid-template-rows: repeat(5, 1fr);
      grid-row-gap: 0.4rem;
      background: rgba(255, 255, 255, 0.7);
      padding: 0.6rem;
      border-radius: 20px 0 0 20px;
      position: absolute;
      top: 8rem;
      right: 3rem;
      @media screen and (max-width: 1500px) {
        top: 6rem;
      }
    `}
`;
