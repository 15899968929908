import { ApolloProvider } from '@apollo/client';
import { publicRoutes } from '@core/helpers/publicRoutes';
import _ from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import GenerateClient from '../../../../../ApolloClient';
import LoadingSplash from './components/LoadingSplash';

export const VirtiApolloProvider = ({ children }) => {
  const [loaded, setLoaded] = useState(true);
  const hasLoaded = useRef(false);

  const [client, setClient] = useState(null);
  const { pathname } = useLocation();

  const publicPage = useMemo(
    () => publicRoutes.find((route) => matchPath(pathname, route)),
    [pathname]
  );

  useEffect(() => {
    const newClient = GenerateClient({
      onLoading: (loading) => {
        if (!loading || !hasLoaded.current) {
          setLoaded(!loading);
        }

        if (!loading) {
          hasLoaded.current = true;
        }
      },
    });

    setClient(newClient);
    // disables popping up the loading screen if no queries are
    // fired in the first 2 seconds
    setTimeout(() => {
      if (!hasLoaded.current) {
        hasLoaded.current = true;
      }
    }, 2000);
  }, []);

  useEffect(() => {
    if (!loaded && !publicPage) {
      document.body.classList.add('not-loaded');
      return;
    }

    document.body.classList.remove('not-loaded');
  }, [loaded, publicPage]);

  if (!client) {
    return null;
  }

  return (
    <ApolloProvider client={client}>
      <LoadingSplash show={!loaded && !publicPage} immediate={publicPage} />
      {children}
    </ApolloProvider>
  );
};

export default VirtiApolloProvider;
