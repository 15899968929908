import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingIndicator from './LoadingIndicator';
import { Loading } from '@virtidev/toolbox';

const StyledTranscodedIndicator = styled.div`
  color: var(--primary-color);
  margin-top: 0.5rem;
`;
const StyledCheck = styled(FontAwesomeIcon)`
  position: relative;
  top: 2px;
`;
const StyledTranscodingIndicator = styled.div`
  margin-top: 0.5rem;
  > div {
    text-align: left;
    display: inline-block;
    vertical-align: middle;
  }
`;
const StyledTranscodeError = styled.div`
  color: var(--warning-color-2);
  text-align: left;
  margin-top: 0.5rem;
`;
const StyledTranscodeInfo = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.5rem;
`;

const TranscodingStatus = (props) => {
  if (props.transcodingStatus === 'Success') {
    return (
      <StyledTranscodedIndicator>
        <StyledCheck icon="check-circle" />
        <StyledTranscodeInfo>Transcoded</StyledTranscodeInfo>
      </StyledTranscodedIndicator>
    );
  }
  if (props.transcodingStatus === 'Transcoding') {
    return (
      <StyledTranscodingIndicator>
        <LoadingIndicator size="small" />
        <StyledTranscodeInfo>
          <Loading /> Transcoding...
        </StyledTranscodeInfo>
      </StyledTranscodingIndicator>
    );
  }

  if (props.transcodingStatus !== 'NoUpload') {
    return (
      <StyledTranscodeError>
        <FontAwesomeIcon icon="exclamation-triangle" />
        <StyledTranscodeInfo>Error transcoding this video.</StyledTranscodeInfo>
      </StyledTranscodeError>
    );
  }

  return null;
};

TranscodingStatus.propTypes = {
  transcodingStatus: PropTypes.string,
};

export default TranscodingStatus;