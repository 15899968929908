import { MockIcon, MockText } from "../../../LoadingPlaceholder";
import { MockModule } from "./Loading.styled";

export const LoadingModule = () => {
  return (
    <MockModule>
      <MockIcon />
      <MockText />
      <MockIcon />
    </MockModule>
  );
};

export default LoadingModule;
