import { memo, useMemo } from 'react';
import Icon from '../../../../../../components/icons/Icon';
import { StyledDragPreview } from './Question.styled';

export const BoxDragPreview = memo(function BoxDragPreview({
  title,
  type,
  width,
}) {
  const iconSize = useMemo(() => {
    if (type === 'radiolist' || type === 'checkbox') {
      return 'small';
    } else {
      return 'med';
    }
  }, [type]);

  return (
    <StyledDragPreview width={width}>
      <Icon name={type} type="formtools" size={iconSize} /> {title}
    </StyledDragPreview>
  );
});
