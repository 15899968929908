import { useQuery } from '@apollo/client';
import { LoadingPlaceholder, SortableTh, Table } from '@virtidev/toolbox';
import { useMemo, useState } from 'react';
import useUser from '../../../helpers/useUser';
import { useDebouncedSave } from '../../form/hooks/useDebouncedSave';
import { usePageScroll } from '../../Page';
import PaginationControl from '../../PaginationControl';
import { usePaginationUrl } from '../../PaginationControl/helpers/usePaginationUrl';
import { READ_GROUPS } from './GroupTable.query';
import {
  FiltersWrapper,
  PaginationControlWrapper,
  StyledTextInput,
  TableWrapper,
} from './GroupTable.styled';
import useSortUrl from '../../../helpers/useSortUrl';
import { groupTable as sortOptions } from '../../../helpers/sortCollections';
import StyledLink from '../../../../../../styled-components/StyledLink';

export default function GroupTable() {
  const { OrganisationID } = useUser();
  const { scrollTop } = usePageScroll();
  const { sortField, sortDirection, updateSort } = useSortUrl(sortOptions);
  const [searchFilter, setSearchFilter] = useState('');

  const { page, pageSize, controlProps, resetPage } = usePaginationUrl({
    onChange: scrollTop,
    pageSize: 20,
  });

  const debouncedProps = useDebouncedSave(searchFilter, {
    onUpdate: (value) => {
      setSearchFilter(value);
      resetPage();
    },
    enter: true,
  });

  const variables = {
    limit: pageSize,
    offset: (page - 1) * pageSize,
    filter: {
      Name: { contains: searchFilter },
      Organisation: { ID: { eq: OrganisationID } },
    },
    sort: {
      [sortField]: sortDirection,
    },
  };

  const { data, loading, error } = useQuery(READ_GROUPS, {
    variables,
  });

  const groups = useMemo(() => {
    if (!data && loading) {
      return [...Array(20)];
    }
    return data?.readVirtiGroups?.edges || [];
  }, [data, loading]);

  const handleSort = (sortField, sortDirection) => {
    updateSort(sortField, sortDirection);
    resetPage();
  };

  return (
    <TableWrapper>
      <FiltersWrapper>
        <StyledTextInput
          placeholder="Search by name..."
          disabled={loading}
          {...debouncedProps}
        />
      </FiltersWrapper>

      <Table>
        <thead>
          <tr>
            <SortableTh
              sortField="Name"
              onSort={handleSort}
              currentSort={{ sortField, sortDirection }}
            >
              Name
            </SortableTh>
            <th>Users</th>
            <th>Courses</th>
            <th>Simulations</th>
            <th>Virtual Humans</th>
          </tr>
        </thead>
        <tbody>
          {groups.map((item, i) => {
            if (loading) {
              return (
                <tr key={i + 1}>
                  {[...Array(5)].map((_, j) => (
                    <td key={j + 1}>
                      <LoadingPlaceholder style={{ margin: 0 }} />
                    </td>
                  ))}
                </tr>
              );
            }
            const { node: group } = item;
            return (
              <tr key={group.ID}>
                <td>
                  <StyledLink to={`/groups/${group.ID}`}>
                    {group.Name}
                  </StyledLink>
                </td>
                <td>{group.Users?.pageInfo?.totalCount}</td>
                <td>{group.Courses?.pageInfo?.totalCount}</td>
                <td>{group.Simulations?.pageInfo?.totalCount}</td>
                <td>{group.VirtualHumans?.pageInfo?.totalCount}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <PaginationControlWrapper>
        <PaginationControl
          {...controlProps}
          total={data?.readVirtiGroups?.pageInfo?.totalCount || 0}
        />
      </PaginationControlWrapper>
    </TableWrapper>
  );
}
