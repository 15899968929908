import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import styled from 'styled-components';

import SimLogAnswerDetails from './SimLogAnswerDetails';
import PropTypes from 'prop-types';
import {
  ContainsMedia,
  GetEventBoxTagColor,
} from '../utility/SimLogEventFormat';

const StyledLogEventBox = styled.div`
  width: 20rem;
  display: grid;
  grid-template-columns: 1rem auto;
  margin: 1rem 5rem 1rem 0rem;
  padding: 0rem 0rem 1rem 0rem;
  text-align: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  opacity: 1;
  margin: ${(props) =>
    props.alignAsAnswer ? '1rem 12rem 1rem 0rem' : '1rem 2rem 1rem 10rem'};
  @media (max-width: 1250px) {
    width: 15rem;
    margin: ${(props) =>
      props.alignAsAnswer ? '1rem 6rem 1rem 0rem' : '1rem 2rem 1rem 5rem'};
  }
`;
const StyledColorTag = styled.div`
  background-color: ${(props) => props.color};
  width: 100%;
  height: 3rem;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;
const StyledEventDetails = styled.div`
  padding: 1.5rem 2rem 1rem 2rem;
`;
const StyledBoxType = styled.div`
  font-size: 1.3rem;
  padding-bottom: 1rem;
`;
const StyledBoxAction = styled.div`
  font-size: 0.8rem;
  padding-top: 1rem;
  color: var(--primary-font-color-light);
`;

const StyledBoxVideoTitle = styled.div`
  font-size: 0.8rem;

  color: var(--primary-font-color-light);
`;

const StyledMediaWrapper = styled.div`
  img,
  audio,
  video {
    width: 80%;
  }

  padding: 1rem 0rem;
  display: grid;
  place-items: center;
`;
const StyledModuleExpandArrowContainer = styled.span`
  cursor: pointer;
  padding: 0rem 0rem 0rem 0rem;

  transform: rotate(-90deg)
    ${(props) => (props.expanded ? 'rotate3d(0, 1, 0, 180deg)' : '')};
  transition: transform 0.3s;
`;
const StyledClickable = styled.button`
  text-transform: capitalize;
  font-size: 1.3rem;
  padding: 0rem 0rem 0rem 0rem;
  border-radius: 6px 6px 0 0;
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: left;
  border: none;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  font-family: var(--text-font);
  font-weight: 500;
  border-radius: var(--card-border-radius);
`;
const StyledQuestionText = styled.div`
  line-height: 1.3rem;
  color: var(--primary-font-color-light);
`;

export default function SimLogEventBox(props) {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <StyledLogEventBox alignAsAnswer={props.event.type !== 'Answer'}>
      <StyledColorTag color={GetEventBoxTagColor(props.event.type)} />
      <div>
        <StyledEventDetails>
          <StyledBoxType>{props.event.type} </StyledBoxType>
          <StyledBoxVideoTitle>
            {props.event.type === 'Start Video' && props.event.videoTitle}
          </StyledBoxVideoTitle>
          <StyledQuestionText>
            {props.event.title && props.event.title}
          </StyledQuestionText>

          {props.event.type === 'Hotspot' && (
            <StyledBoxAction>Action Type: {props.event.action}</StyledBoxAction>
          )}
          {props.event.type === 'Answer' && (
            <SimLogAnswerDetails {...props.event} />
          )}
        </StyledEventDetails>
        {!collapsed && (
          <StyledMediaWrapper>
            {props.event.imageURL && (
              <img alt={props.event.type} src={props.event.imageURL}></img>
            )}
            {props.event.audioURL && (
              <audio controls>
                <source src={props.event.audioURL} />
              </audio>
            )}
            {props.event.videoURL && (
              <video controls>
                <source src={props.event.videoURL} />
              </video>
            )}
          </StyledMediaWrapper>
        )}
        {ContainsMedia(props.event) ? (
          <StyledClickable onClick={() => setCollapsed(!collapsed)}>
            <StyledModuleExpandArrowContainer expanded={!collapsed}>
              <FontAwesomeIcon
                color="var(--primary-button-text-color-without-bg)"
                icon="chevron-left"
                size="lg"
              />
            </StyledModuleExpandArrowContainer>
          </StyledClickable>
        ) : null}
      </div>
    </StyledLogEventBox>
  );
}

SimLogEventBox.propTypes = {
  event: PropTypes.object.isRequired,
};
