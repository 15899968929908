import { useMutation, useQuery } from '@apollo/client';
import { useMemo, useEffect, forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import useUser from '../../../../../helpers/useUser';
import { CREATE_GROUP, READ_GROUPS_FOR_ACCESS } from './selectGroup.query';
import Select from '../../Select';
import _ from 'lodash';
import { useSelectFilter } from '../../helpers/useSelectFilter';
import withController from '../../helpers/withController';
import { useFlashMessage } from '../../../../FlashMessage';
import withStateUrl from '../../helpers/withStateUrl';

export const SelectGroup = forwardRef(
  ({ options, groupQueryFilter, loading, ...props }, ref) => {
    const { OrganisationID } = useUser();
    const { addFlashMessage } = useFlashMessage();
    const [nameFilter, filterProps] = useSelectFilter();
    const { onChange } = props;

    const filter = useMemo(
      () => ({
        ...groupQueryFilter,
        Name: { contains: nameFilter },
        Organisation: { ID: { eq: OrganisationID } },
      }),
      [groupQueryFilter, nameFilter, OrganisationID]
    );

    const { data: groupData, loading: groupLoading } = useQuery(
      READ_GROUPS_FOR_ACCESS,
      {
        variables: {
          filter,
        },
      }
    );

    const [createGroup] = useMutation(CREATE_GROUP);

    const groups = useMemo(() => {
      if (!groupData) {
        return [];
      }
      return groupData.readVirtiGroups.nodes.map(({ ID, Name }) => ({
        value: ID,
        label: Name,
      }));
    }, [groupData]);

    useEffect(() => {
      const { inputValue, ref } = filterProps;
      if (!groupLoading && inputValue) {
        ref.current.focus();
        ref.current.onMenuOpen();
      }
      // I do not want this to activate with inputValue change
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupLoading]);

    const allOptions = useMemo(
      () => [...options, ...groups],
      [options, groups]
    );

    // add a create group mutation, taking in the title entered... and updating the cache

    const handleCreateOption = useCallback(
      (option) => {
        createGroup({
          variables: {
            Input: {
              Name: option,
              OrganisationID,
            },
          },
          onCompleted: (data) => {
            const { Name, ID } = data.createVirtiGroup;
            addFlashMessage(`Created new group: ${Name}`);
            onChange({ value: ID, label: Name });
          },
          onError: (err) => {
            console.error(err);
            addFlashMessage('Group could not be created', 'error');
          },
          refetchQueries: ['readVirtiGroups', READ_GROUPS_FOR_ACCESS],
        });
      },
      [createGroup, addFlashMessage, OrganisationID, onChange]
    );

    return (
      <Select
        ref={ref}
        {...props}
        {...filterProps}
        options={allOptions}
        loading={groupLoading || loading}
        onCreateOption={handleCreateOption}
      />
    );
  }
);

SelectGroup.defaultProps = {
  options: [],
  loading: false,
  creatable: false,
  onChange: () => null,
  groupQueryFilter: {},
};

SelectGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.any),
  loading: PropTypes.bool,
  creatable: PropTypes.bool,
  onChange: PropTypes.func,
  groupQueryFilter: PropTypes.object,
};

export const ControlledSelectGroup = withController(SelectGroup);
export const StateUrlSelectGroup = withStateUrl(SelectGroup);
export default SelectGroup;
