import { Button, Icon } from '@virtidev/toolbox';
import React, { useCallback, useState, FC } from 'react';
import { useMutation } from '@apollo/client';
import { StyledFileTitle, StyledSubtitleItem } from './SubtitleItem.styled';
import { DELETE_SUBTITLE } from './SubtitleItem.query';
import SubtitlePlaceholder from '../SubtitlePlaceholder/SubtitlePlaceholder';
import useFlashMessage from '../../../FlashMessage';
import { VIDEO_FRAGMENT } from '../Subtitles.query';
import { produce } from 'immer';

/**
 * @typedef {import('../../../../models/video-media.types').Subtitle} Subtitle
 * @typedef {import('../../../../models/video-media.types').VideoMedia} VideoMedia
 */

/**
 * @type {FC<{
 *    subtitle: Subtitle,
 *    videoID: string,
 * }>}
 */
export const SubtitleItem = ({ subtitle, videoID }) => {
  const [deleting, setDeleting] = useState(false);

  const { addFlashMessage } = useFlashMessage();

  const [deleteSubtitle] = useMutation(DELETE_SUBTITLE, {
    variables: {
      IDs: [subtitle.ID],
    },
    onCompleted: () => {
      addFlashMessage(`Subtitle deleted!`);
    },
    refetchQueries: ['readVideoSubtitles'],
    update: (cache) => {
      const id = `VideoMedia:${videoID}`;
      /** @type {VideoMedia | null} */
      const video = cache.readFragment({
        id,
        fragment: VIDEO_FRAGMENT,
      });

      if (!video) {
        return;
      }
      const newVideo = produce(video, (draft) => {
        const count = draft.Subtitles.pageInfo.totalCount;
        if (count > 0) {
          draft.Subtitles.pageInfo.totalCount = count - 1;
        }
        draft.Subtitles.nodes = draft.Subtitles.nodes.filter(
          ({ ID }) => ID !== subtitle.ID
        );
      });

      cache.writeFragment({
        id,
        fragment: VIDEO_FRAGMENT,
        data: newVideo,
      });
    },
  });

  const handleClick = useCallback(() => {
    setDeleting(true);
    deleteSubtitle();
  }, [deleteSubtitle]);

  return deleting ? (
    <SubtitlePlaceholder />
  ) : (
    <StyledSubtitleItem>
      <Icon icon="captions" outline color="var(--primary-color)" size="30px" />
      <StyledFileTitle href={subtitle?.URL} title={subtitle?.Title}>
        {subtitle?.Title}
      </StyledFileTitle>
      <div>{subtitle?.Language}</div>
      <Button
        color="secondary"
        type="link"
        icon="pen"
        to={`/media/videos/${videoID}/subtitles/${subtitle.ID}`}
      />
      <Button color="danger" icon="bin" onClick={handleClick} />
    </StyledSubtitleItem>
  );
};

export default SubtitleItem;
