import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';
import _ from 'lodash';
import { produce } from 'immer';
import StyledTextInput from '../styled-components/StyledTextInput';
import {
  EDIT_MEMBER,
  ADD_MEMBER,
  SAVE_AREAS_OF_INTEREST,
  READ_MEMBER,
} from '../queries/UserQueries';
import styled from 'styled-components';
import { DeleteCache } from '../utility/QueryUtility';

import GroupCheckboxesForUser from './GroupCheckboxesForUser/GroupCheckboxesForUser';
import {
  MinLength,
  IsEmail,
  RunValidations,
  GetErrors,
  GetInvalidFields,
} from '../utility/FormValidations';
import ValidationMessages from '../components/ValidationMessages';
import WithFlashMessaging from '../HOCs/WithFlashMessaging';
import withUser from '../HOCs/WithUser';
import Button from '../components/buttons/Button';
import StyledLabel from '../styled-components/StyledLabel';
import NavigationPrompt from '../components/NavigationPromptManual';
import ChangePasswordSuperAdminForm from '../forms/ChangePasswordSuperAdminForm';
import SaveBar from '../components/SaveBar';
import Breakpoints from '../themes/Breakpoints';
import WithOnboardingHandler from '../HOCs/WithOnboardingHandler';
import tracker from '../apps/core/src/helpers/tracker';

const StyledForm = styled.div`
  display: grid;
  grid-template-columns: 19rem auto;
  grid-column-gap: 2rem;
  @media (max-width: ${Breakpoints.small}) {
    grid-template-columns: 1fr;
  }
`;

const StyledInputsWrapper = styled.div`
  padding: 2rem;
  background-color: var(--card-bg-color);
  box-shadow: var(--card-box-shadow);
`;

const StyledInputsSubwrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  margin-bottom: 1rem;
  @media (max-width: ${Breakpoints.large}) {
    grid-template-columns: 1fr;
  }
`;

const StyledCategoriesSelectWrapper = styled.div`
  margin-bottom: 1rem;
  @media (min-width: ${Breakpoints.large}) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;

class UserForm extends Component {
  validations = [
    IsEmail('Email', 'Email', true),
    MinLength('FirstName', 'First Name', true, 1),
    MinLength('Surname', 'Surname', true, 1),
  ];

  editableValues = [
    { dbName: 'FirstName', label: 'First Name' },
    { dbName: 'Surname', label: 'Surname' },
    { dbName: 'Email', label: 'Email' },
  ];

  newMemberDefaultExtras = {
    LoginCode: null,
  };

  constructor(props) {
    super(props);

    this.state = this.editableValues.reduce(
      (carry, editableValue) => {
        carry[editableValue.dbName] =
          props.dataObject && props.dataObject[editableValue.dbName] !== null
            ? props.dataObject[editableValue.dbName]
            : '';
        return carry;
      },
      {
        validations: [],
        invalidFields: [],
        submittingForm: false,
      }
    );
  }
  componentDidMount() {
    this.setState({
      prevFormData: this._getFormData(),
    });
  }

  _revalidateIfInvalid = () => {
    if (this.state.invalidFields.length > 0) {
      this._validate();
    }
  };

  _validate = (callback) => {
    const newValidations = RunValidations(this.validations, this.state);
    const invalidFields = GetInvalidFields(newValidations);
    this.setState(
      {
        validations: newValidations,
        invalidFields,
      },
      () => {
        if (callback) {
          const isInvalid = this.state.validations.some(
            (validation) => !validation.valid
          );
          callback(!isInvalid);
        }
      }
    );
  };

  _submitForm = (e, mutateMember) => {
    e.preventDefault();
    this._validate((valid) => {
      if (!valid) return;
      this.setState({
        submittingForm: true,
      });
      const input = this.editableValues.reduce((carry, editableValue) => {
        carry[editableValue.dbName] = this.state[editableValue.dbName];
        return carry;
      }, {});
      let refetches = [];
      let variables = { Input: input };
      if (this.props.mutationType === 'edit') {
        variables.Input.ID = this.props.dataObject.ID;
        // refetches.push({ query: READ_MEMBER, variables: { ID: variables.ID } });
      } else {
        // refetches.push({ query: READ_MEMBERS });
        // refetches.push({ query: READ_MEMBERS_PAGINATED });
        variables.Input.OrganisationID = this.props.userOrganisationID;
        variables.Input.SendWelcomeEmail = true;
      }
      // variables.Input.RoleID = this.state.userRole;

      // NOTE: whatever this does may have to differ based on whether parent categories' IDs need to be passed in or not (those are invisible and not in state atm)
      mutateMember({
        variables,
        // refetchQueries: refetches,
      });
    });
  };

  _textInputChange = (newValue, editableValueObject) => {
    let updated = {};
    updated[editableValueObject.dbName] = newValue;
    this.setState(updated, this._revalidateIfInvalid);
  };

  _textTrim = (newValue, editableValueObject) => {
    let updated = {};
    updated[editableValueObject.dbName] = newValue.trim();
    this.setState(updated, this._revalidateIfInvalid);
  };

  _getFormData = () => {
    return this.editableValues.reduce((carry, editableValue) => {
      carry[editableValue.dbName] = this.state[editableValue.dbName];
      return carry;
    }, {});
  };

  _userIsPartOfOrganisation = function (groupID, userGroupEdges) {
    if (!userGroupEdges) return false;
    return userGroupEdges.some((groupEdge) => groupEdge.node.ID === groupID);
  };

  _onCompleteMutations = (data) => {
    this.setState({
      submittingForm: false,
      prevFormData: this._getFormData(),
    });
    if (this.props.submitCallback) {
      this.props.submitCallback(data);
    }
    if (this.props.mutationType === 'edit') {
      this.props.addFlashMessage('User updated');
    } else {
      // add new member and send an email with login code
      this.props.addFlashMessage('User added');
    }
  };

  render() {
    const dirty = !_.isEqual(this.state.prevFormData, this._getFormData());
    return (
      <>
        {!this.props.ignoreRedirectPrompt && (
          <NavigationPrompt
            when={dirty}
            message="You have unsaved changes, are you sure you want to leave?"
          />
        )}
        <Mutation
          errorPolicy="all"
          onCompleted={(data) => {
            this.props.updateProgress('upload_users');
            if (this.props.mutationType === 'add') {
              tracker.track('user_created', {
                user_id: data.createMember.ID,
              });
            }
            if (this.props.mutationType === 'edit') {
              tracker.track('user_edited', {
                user_id: data.updateMember.ID,
              });
            }
            this._onCompleteMutations(data);
          }}
          onError={(error) => {
            this.setState({
              submittingForm: false,
            });
            const message = error.toString();
            if (
              message.includes('identical identifier') ||
              message.includes('Cannot read properties')
            ) {
              this.props.addFlashMessage(
                'A user exists with that email address',
                'error'
              );
            } else {
              this.props.addFlashMessage(
                'There was an error processing the form',
                'error'
              );
            }
          }}
          mutation={
            this.props.mutationType === 'edit' ? EDIT_MEMBER : ADD_MEMBER
          }
          update={
            this.props.mutationType === 'add'
              ? DeleteCache('readMembers')
              : null
          }
        >
          {(mutateMember, { mutationData }) => (
            <StyledForm>
              <GroupCheckboxesForUser
                userID={this.props.dataObject ? this.props.dataObject.ID : null}
                user={
                  this.props.mutationType === 'edit'
                    ? this.props.dataObject
                    : null
                }
                loggedUserType={this.props.user.UserType}
                loggedUserID={this.props.userID}
                checkedGroupIDs={
                  this.props.dataObject
                    ? this.props.dataObject.OrganisationGroups.edges.map(
                        (edge) => edge.node.ID
                      )
                    : []
                }
              />
              <div>
                {/**div here to prevent shaded div from expanding to the height of grid container */}
                <StyledInputsWrapper>
                  <StyledInputsSubwrapper>
                    {this.editableValues.map((editableValue) => {
                      return (
                        <div key={editableValue.dbName}>
                          {(typeof editableValue.componentMethodName ===
                            'string' &&
                            this[editableValue.componentMethodName]()) || (
                            <StyledTextInput
                              id={editableValue.dbName}
                              name={editableValue.dbName}
                              label={editableValue.label}
                              onBlur={(e) => {
                                if (editableValue.dbName === 'Email') {
                                  this._textTrim(e.target.value, editableValue);
                                }
                              }}
                              onChange={(e) => {
                                e.preventDefault();
                                this._textInputChange(
                                  e.target.value,
                                  editableValue
                                );
                              }}
                              value={
                                this.state[editableValue.dbName] !== null
                                  ? this.state[editableValue.dbName]
                                  : ''
                              }
                              placeholder={editableValue.label}
                              invalid={this.state.invalidFields.includes(
                                editableValue.dbName
                              )}
                            />
                          )}
                        </div>
                      );
                    })}
                    {this.props.mutationType === 'edit' && (
                      <>
                        <StyledCategoriesSelectWrapper>
                          <StyledLabel>VR Login Code</StyledLabel>
                          {this.props.dataObject.LoginCode}
                        </StyledCategoriesSelectWrapper>
                      </>
                    )}
                  </StyledInputsSubwrapper>
                  <ValidationMessages
                    errors={GetErrors(this.state.validations)}
                  />
                </StyledInputsWrapper>

                {this.props.includeChangePasswordSuperAdminForm && (
                  <ChangePasswordSuperAdminForm
                    userID={this.props.dataObject.ID}
                  />
                )}
                <SaveBar>
                  <Button
                    disabled={!dirty}
                    dataTestId="save-bar-btn"
                    loading={this.state.submittingForm}
                    onClick={(event) => {
                      this._submitForm(event, mutateMember);
                    }}
                  >
                    {this.props.mutationType === 'edit'
                      ? 'Save Current User'
                      : 'Save New User'}
                  </Button>
                </SaveBar>
              </div>
            </StyledForm>
          )}
        </Mutation>
      </>
    );
  }
}

UserForm.defaultProps = {
  mutationType: 'add',
  dataObject: null,
  submitCallback: null,
  ignoreRedirectPrompt: false,
  includeChangePasswordSuperAdminForm: false,
};

UserForm.propTypes = {
  dataObject: PropTypes.object,
  submitCallback: PropTypes.func,
  mutationType: PropTypes.string,
  userOrganisationID: PropTypes.number.isRequired,
  addFlashMessage: PropTypes.func.isRequired,
  ignoreRedirectPrompt: PropTypes.bool,
  includeChangePasswordSuperAdminForm: PropTypes.bool,
};

export default WithOnboardingHandler(WithFlashMessaging(withUser(UserForm)));
