import React, { useMemo } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import {
  StyledDataBlockHeader,
  StyledDataBlockHeaderText,
} from '../styled-components/StyledDataBlocks';
import LoadingPageIndicator from './LoadingPageIndicator';
import PageLoadError from './PageLoadError';
import StyledLink from '../styled-components/StyledLink';
import { READ_LEARNER_BADGES } from '../queries/LearnerQueries';
import useUser from '../apps/core/src/helpers/useUser';
import { useQuery } from '@apollo/client';
import Badge from '@core/components/Badge/Badge';

const StyledItemsListWrapper = styled.div`
  margin-top: 1rem;
`;

const StyledLinkWrapper = styled.div`
  margin-top: 1rem;
  display: flexbox;
`;

const StyledBadgesWrapper = styled.div`
  display: grid;
  /* grid-template-columns: repeat(3, minmax(300px, 1fr)); */
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 2rem;
  width: 100%;
  /* margin-top: 1rem; */
`;

const MyBadges = () => {
  const { ID } = useUser();

  const { data, error, loading } = useQuery(READ_LEARNER_BADGES, {
    variables: { ID },
  });

  const badges = useMemo(() => data?.readMemberBadges?.nodes, [data]);

  return (
    <>
      <StyledItemsListWrapper>
        <StyledDataBlockHeader>
          <StyledDataBlockHeaderText>
            Your Most Recent Badges
          </StyledDataBlockHeaderText>
        </StyledDataBlockHeader>
      </StyledItemsListWrapper>
      {loading && <LoadingPageIndicator />}
      {!loading && error && <PageLoadError message={error.message} />}
      <StyledBadgesWrapper>
        {badges?.map((badge) => (
          <Badge
            key={badge.ID}
            title={badge.Title}
            created={badge.Created}
            unlocked
          />
        ))}
        {badges?.length === 0 && (
          <StyledLinkWrapper>
            <StyledLink to="/my-simulations">
              Complete Simulations to start earning!
            </StyledLink>
          </StyledLinkWrapper>
        )}
      </StyledBadgesWrapper>
    </>
  );
};

MyBadges.propTypes = {};

export default MyBadges;
