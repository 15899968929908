import styled from 'styled-components';
import { Icon, ToggleSwitch } from '@virtidev/toolbox';

export const StyledShowMore = styled.div`
  margin: 2rem 0rem 1rem 0rem;
  display: grid;
  place-items: center;
`;

export const StyledChevron = styled(Icon)`
  margin-left: 0.5rem;
  transform: rotate(90deg);
`;

export const ReStyledToggle = styled(ToggleSwitch)`
  display: flex;
  flex-direction: row;
  place-items: center;
  gap: 1rem;
`;
