import { useLazyQuery, useQuery } from '@apollo/client';
import { forwardRef, useCallback, useRef, useState } from 'react';
import useUser from '../../../helpers/useUser';
import { READ_ORGANISATION } from './withTusEndpoint.query';
import getConfig from '../../../../../../lib/config';

const REACT_APP_TUS_UPLOAD_ENDPOINT_VIDEO = getConfig(
  'REACT_APP_TUS_UPLOAD_ENDPOINT_VIDEO'
);
const REACT_APP_TUS_UPLOAD_ENDPOINT_OTHER_MEDIA = getConfig(
  'REACT_APP_TUS_UPLOAD_ENDPOINT_OTHER_MEDIA'
);

export const withTusEndpoint = (WrappedComponent) => {
  const TusEndpointProvider = forwardRef((props, ref) => {
    const { Organisation } = useUser();

    const [getEndpoint, { data }] = useLazyQuery(READ_ORGANISATION);
    const dataRef = useRef(data);
    dataRef.current = data;

    const getTusEndpoint = useCallback(
      async (mediaType) => {
        if (!mediaType?.startsWith('video')) {
          return REACT_APP_TUS_UPLOAD_ENDPOINT_OTHER_MEDIA;
        }

        let orgEndpoint = dataRef.current?.readOneOrganisation?.TUSEndpoint;
        if (!orgEndpoint && Organisation?.ID) {
          const {
            data: { readOneOrganisation },
          } = await getEndpoint({
            variables: {
              ID: Organisation?.ID,
            },
          });
          orgEndpoint = readOneOrganisation?.TUSEndpoint;
        }

        return orgEndpoint || REACT_APP_TUS_UPLOAD_ENDPOINT_VIDEO;
      },
      [Organisation?.ID, getEndpoint]
    );

    return (
      <WrappedComponent {...props} ref={ref} getTusEndpoint={getTusEndpoint} />
    );
  });

  return TusEndpointProvider;
};
