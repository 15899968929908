import {
  PanelButtons,
  PanelContent,
  PanelTitle,
  QuickButton,
  QuickPanel,
} from '../QuickActions.styled';
import QuickIcon from './QuickIcon';

export const VirtualHumanAction = () => (
  <QuickPanel>
    <QuickIcon icon="virtual-human" $type="virtualhuman" />
    <PanelContent>
      <PanelTitle>Virtual Humans</PanelTitle>
      <PanelButtons>
        <QuickButton to="/virtual-humans">Browse</QuickButton>
      </PanelButtons>
    </PanelContent>
  </QuickPanel>
);

export default VirtualHumanAction;
