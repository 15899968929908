import { Fragment } from 'react';
import { ItemContent, ItemTitle, DataContainer } from './DataItems.styled';

export const DataItems = ({ items }) => {
  if (!items || !items.length) {
    return <DataContainer />;
  }

  return (
    <DataContainer>
      {items.filter(Boolean).map(({ title, data }, index) => (
        <Fragment key={`title-${title || index}`}>
          <ItemTitle key={`title-${title || index}`}>{title}</ItemTitle>
          <ItemContent key={`content-${title || index}`}>{data}</ItemContent>
        </Fragment>
      ))}
    </DataContainer>
  );
};

export default DataItems;
