import React from 'react';
import { Query } from '@apollo/client/react/components';
import styled from 'styled-components';

import withUser from '../WithUser';
import StyledPage from '../../components/StyledPage';
import { READ_MEMBER_WITH_BASIC_GROUP_DATA } from '../../queries/UserQueries';
import LoadingPageIndicator from '../../components/LoadingPageIndicator';
import PageLoadError from '../../components/PageLoadError';
import RedirectWithPrevState from '../../components/RedirectWithPrevState';
import HeaderArea from '../../components/HeaderArea';
import DeleteUserBtnInMenu from '../../components/buttons/DeleteUserBtnInMenu';
import StyledActiveUserIndicator from '../../styled-components/StyledActiveUserIndicator';
import { CanViewUsersList } from '../../utility/Permissions';

const ReStyledActiveUserIndicator = styled(StyledActiveUserIndicator)`
  position: absolute;
  bottom: 1.2rem;
  left: 4.5rem;
  width: 20px;
  height: 20px;
`;

const WithUserPageTemplate = (
  WrappedComponent,
  pageKey = undefined,
  hasSaveBar = false
) => {
  class UserPageTemplate extends React.Component {
    render() {
      if (!this.props.userID) {
        return <RedirectWithPrevState to="/login" />;
      }
      return (
        <StyledPage
          pageKey={pageKey}
          hasSaveBar={hasSaveBar}
          goBackToLinkText="all users"
          goBackToLink="/users"
        >
          <Query
            variables={{
              ID: this.props.match.params.id,
              organisationID: this.props.userOrganisationID,
            }}
            query={READ_MEMBER_WITH_BASIC_GROUP_DATA}
          >
            {({ loading, error, data }) => {
              if (loading) return <LoadingPageIndicator />;
              if (error) return <PageLoadError graphQLErrorObj={error} />;
              if (!data.readOneMember) {
                return <RedirectWithPrevState to="/users" />;
              }
              if (!CanViewUsersList()) {
                return <RedirectWithPrevState to="/simulations" />;
              }
              return (
                <React.Fragment>
                  {/* <StyledH1WithOtherContent title={data.readOneMember.Name ? data.readOneMember.Name : '(Unnamed)'}>
                    <StyledDeleteUserBtn userID={data.readOneMember.ID} />
                  </StyledH1WithOtherContent> */}
                  <HeaderArea
                    imageSrc={data.readOneMember.AvatarMedia.URL}
                    title={data.readOneMember.Name}
                    subtitle={data.readOneMember.Email}
                    // loading={mutatingTitle}
                    type="course"
                    editTitleMenuButtons={
                      <DeleteUserBtnInMenu
                        userOrganisationID={this.props.userOrganisationID}
                        userID={data.readOneMember.ID}
                      />
                    }
                  >
                    <ReStyledActiveUserIndicator
                      lastSeen={data.readOneMember.LastSeen}
                    />
                  </HeaderArea>
                  <div>{data.readOneMember.Status}</div>
                  <WrappedComponent {...this.props} data={data} />
                </React.Fragment>
              );
            }}
          </Query>
        </StyledPage>
      );
    }
  }
  return withUser(UserPageTemplate);
};
export default WithUserPageTemplate;
