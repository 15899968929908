import { Modal as ToolboxModal } from '@virtidev/toolbox';
import styled, { css } from 'styled-components';

export const Modal = styled(ToolboxModal)`
  .modal-box {
    min-width: min(90%, 500px);
    max-width: min(90%, 600px);
    width: auto;
  }
  .modal-content {
    padding-top: 2px; // Leave room for focus outline
  }
  &.saving {
    .modal-box {
      min-width: min(90%, 240px);
    }
  }
`;

export const ModalTitle = styled.h2`
  ${({ theme: { color } }) => css`
    color: ${color.midnight};
    padding: 24px 0 0 16px;

    /* H2 lg */
    font-family: Ubuntu;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  `}
`;

export const ModalTitleSaving = styled(ModalTitle)`
  padding: 16px 0 0 0;
  text-align: center;
`;

export const SavingContent = styled.div`
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing.xl};
`;
