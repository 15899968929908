import styled, { css, DefaultTheme } from 'styled-components';
import { readableColor } from 'polished';

// TODO: replace with new theme when rebrand happens
export const rebrand = {
  color: {
    // Blue
    primary: '#528fbf',
    // Blue tinted
    primaryLight: '#a3bcd9',
    // Pink
    pink: '#dd6b9c',
  },
  page: {
    splash: {
      background: '#2b334b',
      color: readableColor('#2b334b'),
      square: {
        md: 'clamp(15px, 9vw, 140px)',
        sm: `clamp(8px, 4.5vw, 70px)`,
      },
    },
  },
};

export const PageBody = styled.div`
  flex: 1;
  /* flexbox will ensure body takes at least 450px of the page width */
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${rebrand.page.splash.color};
  background-color: ${rebrand.page.splash.background};
  overflow: hidden;
`;

export const PageContent = styled.div`
  ${
    /**
     * @param {{ theme: DefaultTheme, $expanded?: boolean }} p
     */
    ({ theme, $expanded }) => css`
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      flex: 1;
      align-items: stretch;
      justify-content: center;
      color: ${rebrand.page.splash.color};
      background-color: ${rebrand.page.splash.background};
      min-width: 350px;
      max-width: ${$expanded ? '3840px' : '1920px'};
      min-height: 350px;
      max-height: ${$expanded ? '2160px' : '1080px'};
      transition: max-width 300ms, max-height 300ms;

      padding: ${rebrand.page.splash.square.md} 0
        calc(${rebrand.page.splash.square.md} * 2);

      @media screen and (min-width: 992px) {
        padding: ${rebrand.page.splash.square.md};
        flex-direction: row-reverse;
      }
    `
  }
`;

export const SquareContainer = styled.div`
  ${
    /**
     * @param {{ theme: DefaultTheme, $expanded?: boolean }} p
     */
    ({ theme, $expanded }) => css`
      position: absolute;
      top: 50%;
      left: 50%;
      min-height: 350px;
      height: 100%;
      width: 100%;
      transform: translate(-50%, -50%);
      pointer-events: none;
      z-index: 2;
      transition: width 200ms, height 200ms;
      overflow: visible;
    `
  }
`;

export const Square = styled.div`
  ${
    /**
     * @param {{ $small?: boolean, $dark?: boolean}} p
     */
    ({ $small, $dark }) => css`
      position: absolute;

      background: ${$dark ? rebrand.color.primary : rebrand.color.primaryLight};
      ${$small
        ? css`
            width: ${rebrand.page.splash.square.sm};
            height: ${rebrand.page.splash.square.sm};
          `
        : css`
            width: ${rebrand.page.splash.square.md};
            height: ${rebrand.page.splash.square.md};
          `}
    `
  }
`;
