import { useMutation } from '@apollo/client';
import { Button } from '@virtidev/toolbox';
import React, { useMemo } from 'react';
import { useFlashMessaging } from '../../../../../../HOCs/WithFlashMessaging';
import { RESEND_LOGIN_CODES } from '../../../../../../queries/UserQueries';
import useUser from '../../../helpers/useUser';

export default function ResendWelcomeEmail({
  selectedIDs,
  setSelectedIDs,
  // why do we need this button prop?
  button,
}) {
  const { OrganisationID } = useUser();

  const { addFlashMessage } = useFlashMessaging();

  const SelectedButton = useMemo(() => button || Button, [button]);

  const [resendLoginCodes, { data }] = useMutation(RESEND_LOGIN_CODES, {
    variables: {
      UserIDs: selectedIDs,
      OrganisationID,
    },
    onCompleted: () => {
      setSelectedIDs([]);
      addFlashMessage('Login code sent!');
    },
    onError: (err) => {
      console.error(err);
      addFlashMessage('Something went wrong', 'error');
    },
  });

  return (
    <SelectedButton onClick={resendLoginCodes}>Resend Invite</SelectedButton>
  );
}
