import React from 'react';
import withUser from '../WithUser';
import RedirectWithPrevState from '../../components/RedirectWithPrevState';
import HeaderArea from '../../components/HeaderArea';
import StyledPage from '../../components/StyledPage';
import { CanSeeCXDashboard } from '../../utility/Permissions';

const WithCXDashboardPageTemplate = (WrappedComponent, pageKey = undefined) => {
  class CXDashboardPageTemplate extends React.Component {
    render() {
      if (!this.props.userID) {
        return <RedirectWithPrevState to="/login" />;
      }
      if (!CanSeeCXDashboard()) {
        return <RedirectWithPrevState to="/" />;
      }
      return (
        <StyledPage pageKey={pageKey}>
          <HeaderArea title="Customer Dashboard" showImg={false} />
          <WrappedComponent {...this.props} />
        </StyledPage>
      );
    }
  }
  return withUser(CXDashboardPageTemplate);
};
export default WithCXDashboardPageTemplate;
