import { useCallback, useState } from 'react';
import VHQuery from './VHQuery';

/**
 * @typedef {import('./useGenerateVHDetails.types').AIVHAllData} AIVHAllData
 * @typedef {import('./useGenerateVHDetails.types').AIVHGeneratingData} AIVHGeneratingData
 */

const useGenerateVHConversationItems = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const generate = useCallback(
    async (
      /** @type {{vhData: AIVHAllData}} */ input,
      vhAPIEndpointOverride = undefined
    ) => {
      setError(null);
      setIsLoading(true);
      try {
        /** @type {{data: {data: {generatedVH: AIVHGeneratingData}}}} */
        const result = await VHQuery(
          `ai-generate-vh-conversation-items`,
          'POST',
          input,
          vhAPIEndpointOverride
        );
        setIsLoading(false);
        return result;
      } catch (err) {
        setError(err);
        setIsLoading(false);
      }
    },
    []
  );
  return [generate, { isLoading, error }];
};
export default useGenerateVHConversationItems;
