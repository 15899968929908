import { useMutation } from '@apollo/client';
import { parseISO } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { DELETE_COURSES } from '../../../DataListCard/components/CardDeleteButton/CardDeleteButton.query';
import EnrolledChart from '../../../DataListCard/components/EnrolledChart/EnrolledChart';
import DataListCard from '../../../DataListCard/DataListCard';
import { addFlashMessage } from '../../../FlashMessage/FlashMessage.state';

const countCourseSims = (course) => {
  const count = course.Modules.edges.reduce(
    (prev, { node: module }) => prev + module.Units.edges.length,
    0
  );
  return count;
};

export const CourseDataCard = ({ course, onDelete, editTags, ...props }) => {
  const {
    ID,
    OrganisationGroups,
    TotalStudents,
    TotalCompletions,
    TotalSuccessful,
  } = course;

  const dataPoints = useMemo(
    () => [
      { title: 'Users', data: TotalStudents },
      {
        title: 'Content',
        data: countCourseSims(course),
      },
      {
        title: 'Completions',
        data: TotalCompletions,
      },
      {
        title: 'Enrolled',
        data: (
          <EnrolledChart
            data={TotalStudents ? (TotalSuccessful / TotalStudents) * 100 : 0}
          />
        ),
      },
    ],
    [TotalStudents, course, TotalCompletions, TotalSuccessful]
  );

  const [deleteCourse] = useMutation(DELETE_COURSES, {
    variables: {
      IDs: [ID],
    },
    onError: () => {
      addFlashMessage('Error: The course could not be deleted.', 'error');
    },
  });

  const handleDelete = useCallback(async () => {
    await deleteCourse();
    onDelete(course);
  }, [deleteCourse, onDelete, course]);

  const isNew = !ID || ID.includes('new');

  const parsedGroups = useMemo(
    () => OrganisationGroups.edges.map(({ node }) => node),
    [OrganisationGroups]
  );

  const published = useMemo(() => {
    return course.Status === 'published';
  }, [course.Status]);

  return (
    <DataListCard
      {...props}
      {...course}
      onDelete={handleDelete}
      editLink={!isNew ? `/courses/${ID}` : ''}
      analyticsLink={!isNew ? `/courses/${ID}/analytics` : ''}
      itemLink={!isNew ? `/courses/${ID}` : ''}
      visibleTo={parsedGroups}
      dataPoints={dataPoints}
      published={published}
      cardType="course"
      editTags={editTags}
    />
  );
};

export default CourseDataCard;
