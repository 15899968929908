import styled from 'styled-components';

export const CompleteSessionsWrapper = styled.div`
  width: 250px;
`;

export const PagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
