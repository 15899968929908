import styled from 'styled-components';
import { Card } from '@virtidev/toolbox';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  gap: var(--content-padding);
`;

export const SimulationsContainer = styled(Card)`
  min-height: 100%;
  height: calc(100vh - 140px - (var(--nav-bar-height) * 0.8));
  flex: 2;
  width: 66%;
  min-width: 800px;
  flex-direction: row;
`;

export const DetailsContainer = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 33%;
  gap: var(--content-padding);
  min-width: 300px;
`;

export const DetailsCard = styled(Card)``;

export const SavebarHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px 0 5px;
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--content-padding);

  > * {
    flex: 1;
  }
`;

export const CreateContainer = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: var(--content-padding);
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const LabelTooltipWrapper = styled.span`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
