import { gql } from '@apollo/client';
import { pseudoVideoMediaFragment } from './SimulationQueries';

export const pseudoMediaFragment = `
  ID
  Title
  URL
  Width
  Height
  Length
  TusID
  Filename
`;

export const READ_VIDEO_MEDIAS = gql`
  query readVideos(
    $limit: Int
    $offset: Int
    $filter: VideoMediaFilterFields
  ) {
    readVideoMedias(
      offset: $offset
      limit: $limit
      filter: $filter
      sort: { Created: DESC }
    ) {
      pageInfo {
        totalCount
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ${pseudoVideoMediaFragment}

        }
      }
    }
  }
`;

export const READ_VIDEOS_WITH_ASSOCIATIONS = gql`
  query readMediaPageVideo(
    $limit: Int
    $offset: Int
    $sort: VideoMediaSortFields
    $filter: VideoMediaFilterFields
  ) {
    readVideoMedias(filter: $filter, limit: $limit, offset: $offset, sort: $sort) {
      pageInfo {
        totalCount
        hasNextPage
        hasPreviousPage
      }
      nodes {
        ${pseudoVideoMediaFragment}
        Tags {
          nodes {
            ID
            Name
          }
        }
        Videos {
          nodes {
            ID
            Simulations {
              nodes {
                ID
                Title
              }
            }
          }
        }
        EmbeddedInEvents {
          nodes {
            ID
            Label
            Timestamp
            SimulationID
            VideoID
          }
        }
      }
    }
  }
`;

export const UPDATE_VIDEO = gql`
mutation updateVideoMedia($Input: UpdateVideoMediaInput!) {
  updateVideoMedia(input: $Input) {
    ${pseudoVideoMediaFragment}
  }
}
`;

export const UPDATE_IMAGE = gql`
mutation updateMedia($Input: UpdateMediaInput!) {
  updateMedia(input: $Input) {
    ${pseudoMediaFragment}
  }
}
`;

export const ADD_VIDEO_FROM_URL = gql`
  mutation createVideoMediaFromURL(
    $URL: String!
    $Filename: String!
    $TusID: String!
    $OrganisationID: ID!
    $StartClip: Float
    $ClipLength: Float
    $Content360: Boolean
  ) {
    createVideoMediaFromURL(
      URL:$URL Filename:$Filename
      TusID: $TusID
      OrganisationID: $OrganisationID
      StartClip: $StartClip
      ClipLength: $ClipLength
      Content360: $Content360
    ) {
      ${pseudoVideoMediaFragment}
    }
  }
`;

export const ADD_MEDIA_FROM_URL = gql`
  mutation createMediaFromURL(
    $URL: String!
    $Filename: String!
    $TusID: String!
    $OrganisationID: ID!
    $Content360: Boolean
  ) {
    createMediaFromURL(
      URL: $URL
      Filename: $Filename
      TusID: $TusID
      OrganisationID: $OrganisationID
      Content360: $Content360
    ) {
      ID
      URL
      Title
      Created
      TusID
      Width
      Height
      Content360
      Archived
    }
  }
`;

export const GET_VIDEO = gql`
  query readTranscodingStatus($ID: ID!) {
    readOneVideoMedia(filter: { ID: { eq: $ID } }) {
      ID
      TranscodingStatus
      EditorURL
      SourceURL
    }
  }
`;
