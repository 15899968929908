import { Icon, IconProps } from '@virtidev/toolbox';
import styled, { css } from 'styled-components';

export const QuickIcon = styled(Icon).attrs(
  /**
   * @return {Partial<IconProps>}
   */
  () => ({
    size: '2.75rem',
  })
)`
  ${({ theme: { color }, $type }) => css`
    color: ${($type === 'course' && color.turquoise) ||
    ($type === 'virtualhuman' && color.pink) ||
    color.blue};
  `};
`;

export default QuickIcon;
