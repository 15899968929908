import styled, { css } from 'styled-components';

export const CounterTooltipContent = styled.div`
  max-width: 250px;
  white-space: pre-wrap;
  display: grid;
  gap: 1rem;
  font-size: 0.9rem;
`;
export const CounterTooltip = styled.div`
  ${({ theme, opened }) => css`
    cursor: pointer;
    user-select: none;
    opacity: 0;
    transition: opacity 0.2s;
    color: #fff;
    background-color: ${theme.color.primary};
    padding: 16px;
    border-radius: 16px;
    line-height: 1.2rem;
    em {
      font-weight: bold;
    }
    z-index: 1004;

    ${opened
      ? css`
          opacity: 1;
        `
      : css`
          pointer-events: none;
        `}
  `}
`;

export const CounterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.4rem;
  border: 1px solid #cccccc;
  border-radius: 24px;
  gap: 0.5rem;
  background-color: #fff;
  height: 44px;
  cursor: help;
  position: relative;
  user-select: none;
`;
