import styled, { css } from 'styled-components';

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.p6};
  padding: ${({ theme }) => theme.spacing.sm} 0 0 0;
`;

export const Actions = styled.div`
  display: flex;
  gap: 12px;
  justify-content: right;
`;
