import React from 'react';
import { StyledPercentTag } from '../styled-components/StyledPercentTag';
import { StyledDoubleTd } from '../styled-components/StyledTable';
import { GetPercentColors } from '../utility/PercentUtility';

export default function PercentDataItem(props) {
  const rounded = Math.round(props.number);
  const roundedAbsolute = Math.round(props.absolute * 100) / 100;
  const arrow = () => {
    if (rounded > 0) {
      return '⬆';
    } else if (rounded === 0) {
      return '';
    } else {
      return '⬇';
    }
  };

  return (
    <StyledDoubleTd>
      {roundedAbsolute}
      <StyledPercentTag color={GetPercentColors(rounded, true)}>
        {rounded}%{arrow()}
      </StyledPercentTag>
    </StyledDoubleTd>
  );
}
