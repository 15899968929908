import { gql } from '@apollo/client';

export const READ_VIRTUAL_HUMAN_AVATARS = gql`
  query readAvatars($filter: AvatarFilterFields) {
    readAvatars(sort: { DisplayName: ASC }, filter: $filter) {
      nodes {
        ID
        Name
        DisplayName
        Age
        Gender
        AvatarGeneration
        ImageMedia {
          ID
          TusID
          URL
        }
        AvatarMedia {
          ID
          TusID
          URL
        }
        DefaultVoice {
          ID
          Name
        }
        DefaultVoiceID
      }
    }
  }
`;

export const READ_ENVIRONMENTS = gql`
  query readEnvironments($filter: EnvironmentFilterFields) {
    readEnvironments(sort: { DisplayName: ASC }, filter: $filter) {
      nodes {
        ID
        Name
        DisplayName
        Description
        ImageMedia {
          ID
          URL
          TusID
        }
        Avatars {
          nodes {
            ID
            Name
          }
        }
      }
    }
  }
`;
