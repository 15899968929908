import _ from 'lodash';
import { Titlebar } from '../../../components/Page';
import SimulationsList from '../../../components/SimulationsList/SimulationsList';
import { withSimulationAccess } from '../../../helpers/permissions';
import { withPageTemplate } from '../../../templates';

const SimulationsListPage = () => {
  return (
    <>
      <Titlebar>Simulations</Titlebar>
      <SimulationsList />
    </>
  );
};

export default _.flowRight(
  withSimulationAccess,
  withPageTemplate({ pageKey: 'simulation' })
)(SimulationsListPage);
