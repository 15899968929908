import { Loading } from '@virtidev/toolbox';
import _ from 'lodash';
import Page from '@core/templates/components/StandardPage/Page';
import { CenterContent } from '@core/templates/components/StandardPage/Page.styled';
import { withPagePartContainer } from '@core/components/Page/PagePart.state';

export const LoadingPage = () => {
  return (
    <Page pageKey="no-access">
      <CenterContent>
        <Loading />
      </CenterContent>
    </Page>
  );
};

export default withPagePartContainer(LoadingPage);
