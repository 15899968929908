import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { StyledPageContentInner } from '../../../../../../../components/StyledPage';
import WithGroupPageTemplate from '../../../../../../../HOCs/templates/WithGroupPageTemplate';
import GroupTabNav from '../../../../../../../components/GroupTabNav';
import PaginatedVirtualHumansList from '../../../../../../../components/PaginatedVirtualHumansList';
import { READ_VIRTUAL_HUMANS_PAGINATED } from '../../../../../../../queries/VirtualHumanQueries';

const GroupVirtualHumansPage = (props) => {
  const [pagination, setPagination] = useState({ limit: 12, page: 1 });

  return (
    <>
      <GroupTabNav groupID={props.data.readOneVirtiGroup.ID} />
      <StyledPageContentInner>
        <PaginatedVirtualHumansList
          dataQuery={READ_VIRTUAL_HUMANS_PAGINATED}
          dataQueryVariables={{
            filterBy: {
              Organisation: { ID: { eq: props.userOrganisationID } },
              OrganisationGroups: {
                ID: { eq: props.data.readOneVirtiGroup.ID },
              },
              Status: { eq: 'Published' },
            },
          }}
          queryName="readVirtualHumans"
          paginationState={pagination}
          changePageCallback={(newPage) =>
            setPagination({
              pagination: { ...pagination, page: newPage },
            })
          }
          userOrganisationID={props.userOrganisationID}
        />
      </StyledPageContentInner>
    </>
  );
};

GroupVirtualHumansPage.propTypes = {
  userOrganisationID: PropTypes.number.isRequired,
};

export default WithGroupPageTemplate(
  GroupVirtualHumansPage,
  'group-virtual-humans',
  false
);
