import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import LinkWithPrevState from './LinkWithPrevState';
import { useClickOutsideCallback } from '../utility/EventHooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from './buttons/Button';

const StyledDropdownLinksWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.8rem;
  border-right: var(--primary-border);
  font-size: 0.9rem;
  padding: 0.5rem 0;
  background-color: #fff;
  border-radius: 40px;
  margin: 0 1rem;
  border: var(--input-border);
  user-select: none;
  cursor: pointer;
  ${(props) =>
    !props.thereAreDropdownLinks &&
    css`
      pointer-events: none;
    `}
  &:hover {
    background-color: var(--input-bg-color-hover);
  }
  /* &:active {
    background-color: var(--input-bg-color);
  } */
  ${(props) =>
    props.isOpen &&
    css`
      > div > div:first-child > div:last-child {
        display: flex;
      }
    `}
  .chevron-icon {
    transform: rotate(${(props) => (props.isOpen ? 90 : 270)}deg);
  }
`;

const DropdownLinksWrapper = (props) => {
  const [isOpen, setOpen] = useState(false);
  const wrapperRef = useRef(null);
  useClickOutsideCallback(wrapperRef, () => setOpen(false));

  const handleClick = useCallback(
    (e) => {
      if (e.target.tagName !== 'INPUT') {
        setOpen(!isOpen);
      }
    },
    [isOpen, setOpen]
  );

  useEffect(() => {
    if (!wrapperRef.current) {
      return;
    }
    wrapperRef.current.parentNode.style.zIndex = isOpen ? 1010 : null;
  }, [isOpen]);

  return (
    <StyledDropdownLinksWrapper
      id={props.id}
      ref={wrapperRef}
      isOpen={isOpen}
      onClick={handleClick}
      thereAreDropdownLinks={props.thereAreDropdownLinks}
    >
      {props.children}
    </StyledDropdownLinksWrapper>
  );
};

DropdownLinksWrapper.defaultProps = {
  thereAreDropdownLinks: false,
};
DropdownLinksWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  thereAreDropdownLinks: PropTypes.bool,
};

const StyledOnClickLink = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: right;
`;

const StyledChevron = styled.div`
  margin-right: 1rem;
`;

const DropdownOnClickLink = (props) => {
  return (
    <StyledOnClickLink thereAreDropdownLinks={props.thereAreDropdownLinks}>
      <div>{props.children}</div>
      {props.thereAreDropdownLinks && (
        <StyledChevron className="chevron-icon">
          <FontAwesomeIcon icon="chevron-left" />
        </StyledChevron>
      )}
    </StyledOnClickLink>
  );
};

DropdownOnClickLink.defaultProps = {
  thereAreDropdownLinks: false,
};

DropdownOnClickLink.propTypes = {
  children: PropTypes.node.isRequired,
  thereAreDropdownLinks: PropTypes.bool,
};

const StyledDropdownLinkContent = styled.div`
  position: absolute;
  display: none;
  top: calc(100% + 1px);
  left: 0;
  right: 0;
  background-color: var(--primary-bg-color);
  color: var(--primary-font-color);
  flex-direction: column;
  z-index: 1006;
  box-shadow: var(--card-box-shadow);
  border-radius: var(--card-border-radius);
  max-height: calc(100vh - 100px - (var(--nav-bar-height)));
  overflow-y: auto;
  a:last-child {
    border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
  }
  a:last-child {
    border: none;
  }
`;

const DropdownLinkContent = (props) => {
  return (
    <StyledDropdownLinkContent id={props.id}>
      {props.children}
    </StyledDropdownLinkContent>
  );
};

const StyledDropdown = styled(LinkWithPrevState)`
  color: var(--primary-color);
  padding: 1rem 2rem;
  border-bottom: var(--primary-border);
  line-height: 1.3rem;
  &:hover {
    background-color: #f5f5f5;
  }
  &:active {
    background-color: #fff;
  }
`;

const DropdownLink = (props) => {
  return <StyledDropdown to={props.to}>{props.children}</StyledDropdown>;
};

const DropdownButton = styled(Button)`
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 0;
  font-weight: light !important;
  color: var(--primary-color, #528fbf);
  font-size: 0.9rem;
  padding: 1rem 0rem;
`;

DropdownLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export {
  DropdownLinksWrapper,
  DropdownOnClickLink,
  DropdownLinkContent,
  DropdownLink,
  DropdownButton,
};
