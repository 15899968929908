import { gql } from '@apollo/client';

export const READ_MODULES = gql`
  query readCourseModules($ID: ID!) {
    readOneCourse(filter: { ID: { eq: $ID } }) {
      ID
      Modules {
        nodes {
          ID
          Title
          Description
          SortOrder
          Units {
            edges {
              node {
                ID
                Type
                SortOrder
                PassMark
                Assessment
                Simulation {
                  ID
                  Title
                }
                VirtualHuman {
                  ID
                  Title
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const DELETE_MODULES = gql`
  mutation deleteModules($ids: [ID]!) {
    deleteModules(ids: $ids)
  }
`;

export const SORT_MODULES = gql`
  mutation sortOrderCourseModules($ModuleIDs: [ID]!, $CourseID: ID!) {
    sortOrderCourseModules(ModuleIDs: $ModuleIDs, CourseID: $CourseID) {
      ID
      Modules {
        nodes {
          ID
          SortOrder
        }
      }
    }
  }
`;

export const SORT_UNITS = gql`
  mutation sortOrderModuleUnits($UnitIDs: [ID]!, $ModuleID: ID!) {
    sortOrderModuleUnits(ModuleID: $ModuleID, UnitIDs: $UnitIDs) {
      ID
      Course {
        ID
        Modules {
          nodes {
            ID
            Units {
              edges {
                node {
                  ID
                }
              }
            }
          }
        }
      }
    }
  }
`;
