export const buttons = [{
  type: 'week',
  count: 1,
  text: '1w'
}, {
  type: 'month',
  count: 1,
  text: '1m'
}, {
  type: 'month',
  count: 3,
  text: '3m'
}, {
  type: 'month',
  count: 6,
  text: '6m'
}, {
  type: 'ytd',
  text: 'YTD'
}, {
  type: 'year',
  count: 1,
  text: '1y'
}, {
  type: 'all',
  text: 'All'
}];

export const defaultRangeSelector = {
  selected: 1,
  buttons,
}