import { gql } from '@apollo/client';

export const SET_TAGS = gql`
  mutation setTags(
    $tagIds: [ID]!
    $simId: ID = null
    $courseId: ID = null
    $videoId: ID = null
    $vhId: ID = null
    $mediaId: ID = null
  ) {
    setTags(
      TagIDs: $tagIds
      SimulationID: $simId
      CourseID: $courseId
      VideoMediaID: $videoId
      VirtualHumanID: $vhId
      MediaID: $mediaId
    ) {
      ID
      Name
    }
  }
`;

export const CREATE_TAG = gql`
  mutation createTag($input: CreateContentTagInput!) {
    createContentTag(input: $input) {
      ID
      Name
    }
  }
`;

export const SIM_TAG_FRAGMENT = gql`
  fragment simTag on Simulation {
    ID
    Tags {
      nodes {
        ID
        Name
      }
    }
  }
`;

export const COURSE_TAG_FRAGMENT = gql`
  fragment courseTag on Course {
    ID
    Tags {
      nodes {
        ID
        Name
      }
    }
  }
`;

export const VIDEO_TAG_FRAGMENT = gql`
  fragment videoTag on VideoMedia {
    ID
    Tags {
      nodes {
        ID
        Name
      }
    }
  }
`;

export const IMAGE_TAG_FRAGMENT = gql`
  fragment mediaTag on Media {
    ID
    Tags {
      nodes {
        ID
        Name
      }
    }
  }
`;

export const VH_TAG_FRAGMENT = gql`
  fragment vhTag on VirtualHuman {
    ID
    Tags {
      nodes {
        ID
        Name
      }
    }
  }
`;
