import { gql } from '@apollo/client';

export const EDIT_CX_ORGANISATION = gql`
  mutation updateOrganisation($input: UpdateOrganisationInput!) {
    updateOrganisation(input: $input) {
      ID
      Name
      Description
      AccountID
      VirtualHumanAPI
      DisableLeaderboard
      Licenses
      InviteCodeUser
      InviteCodeAdmin
      VirtualHumanFreeformLimit
      VirtualHumanBranchingLimit
      TUSEndpoint
      Archived
      Medical
    }
  }
`;

export const READ_PLATFORM_LICENSES = gql`
  query readPlatformLicenses($filter: PlatformLicenseFilterFields) {
    readPlatformLicenses(filter: $filter) {
      edges {
        node {
          ID
          Type
          StartDate
          ExpiryDate
          Notes
          Organisation {
            ID
            Name
          }
        }
      }
    }
  }
`;
