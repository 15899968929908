import { gql } from '@apollo/client';

export const READ_VOICES = gql`
  query readVoices($filter: VoiceFilterFields) {
    readVoices(sort: { Featured: DESC, Name: ASC }, filter: $filter) {
      nodes {
        ID
        Name
        Code
        Service
        Locale
        Featured
        Gender
      }
    }
  }
`;

export const READ_ENVIRONMENTS = gql`
  query readEnvironments(
    $environmentFilter: EnvironmentFilterFields
    $avatarFilter: AvatarFilterFields
  ) {
    readEnvironments(sort: { DisplayName: ASC }, filter: $environmentFilter) {
      nodes {
        ID
        Name
        DisplayName
        IsPreview
        IsDevelopment
        Avatars(filter: $avatarFilter) {
          nodes {
            ID
            Name
            Gender
            Age
            IsPreview
            IsDevelopment
            AvatarGeneration
          }
        }
      }
    }
  }
`;

export const READ_TEMPLATES = gql`
  query readVirtualHumanTemplates {
    readVirtualHumanTemplates {
      edges {
        node {
          ID
          Title
          VirtualHuman {
            ID
            Type
          }
        }
      }
    }
  }
`;
