import { produce } from 'immer';

export const rebuildAccessData = (data) => {
  return produce(data, (courseData) => {
    courseData.__typename = 'Course';

    courseData.OrganisationGroups = { nodes: courseData.GroupIDs };
    delete courseData.GroupIDs;
    courseData.PublishNotificationSent = null;
    courseData.PublishNotificationCount = 0;

    return courseData;
  });
};

export default rebuildAccessData;
