import { Select } from '@virtidev/toolbox';
import withController from './helpers/withController';
import { withStateUrl } from './helpers/withStateUrl';

export { Select };

export const ControlledSelect = withController(Select);
export const StateUrlSelect = withStateUrl(Select);

export default Select;
