import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 12px;
  display: flex;
  padding: 0.6rem;
  @media screen and (max-width: 1500px) {
    padding: 0.3rem;
  }
  align-items: center;
  width: 100%;
  ${(props) =>
    props.$vhBigUiFeature &&
    css`
      background-color: ${(props) => props.theme.color.lightGrey};
      height: 58px;
    `}
`;
export const Title = styled.div`
  font-size: 1.1rem;
`;
export const Value = styled.div``;
export const Metric = styled.div`
  color: ${(props) => (props.color ? props.color : '#111B32')};
  font-size: 0.8rem;
  font-weight: 700;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.color ? props.color : 'transparent')};
  border-radius: 8px;
  width: 3rem;
  height: 3rem;
  svg {
    width: 100%;
    height: 100%;
    padding: 0.5rem;
  }
`;

export const DataContainer = styled.div`
  display: grid;
  grid-row-gap: 0.2rem;
  padding: 0 0.5rem;
  width: 100px;
`;
