import React from 'react';
import styled, { css } from 'styled-components';

const StyledHeader = styled.h1`
  ${({ theme: { fontSize, fontWeight, fontFamilyTitle } }) => css`
    font-size: ${fontSize.xxl};
    font-weight: ${fontWeight.partBold};
    font-family: ${fontFamilyTitle};
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
  `}
`;

const StyledPageHeader = ({ children, ...props }) => {
  return <StyledHeader {...props}>{children}</StyledHeader>;
};

export default StyledPageHeader;
