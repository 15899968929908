import { gql } from '@apollo/client';

export const UPDATE_ENVIRONMENT_AVATARS = gql`
  mutation ($EnvironmentID: ID!, $AvatarIDs: [ID]!) {
    updateEnvironmentAvatars(
      EnvironmentID: $EnvironmentID
      AvatarIDs: $AvatarIDs
    ) {
      ID
      Name
      DisplayName
      Avatars {
        nodes {
          ID
          Name
          DisplayName
        }
      }
    }
  }
`;
