import React, { FC, HTMLAttributes } from 'react';

import { Icon, IconProps } from '@virtidev/toolbox';
import { MenuItemButton, MenuItemText } from './ActionMenuButton.styled';

/**
 * @type {FC<HTMLAttributes<HTMLButtonElement> & {
 *    icon: string,
 *    iconType: IconProps["type"]
 * }>}
 */
export const ActionMenuButton = ({
  onClick,
  icon,
  iconType,
  children,
  ...props
}) => {
  return (
    <MenuItemButton {...props} onClick={onClick}>
      {icon && <Icon icon={icon} type={iconType ?? 'outline'} />}
      <MenuItemText>{children}</MenuItemText>
    </MenuItemButton>
  );
};

export default ActionMenuButton;
