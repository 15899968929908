import modernizr from '../../../../lib/modernizr-custom-es6number';

export const withEs6Support = (Component) => {
  const Es6SupportWrapper = (props) => {
    if (!modernizr.es6number) {
      return (
        <div className="fallback-message-container">
          <img
            className="fallback-message-logo"
            alt="Virti"
            src="/images/virti-dark.svg"
          />
          <img
            className="fallback-message-graphic"
            alt="virti-wellbeing"
            src="/images/content-wellbeing.png"
          />
          <p className="fallback-message-content">
            It seems your browser is not supported.
            <br />
            To ensure that you get the best experience, please consider using a
            more modern browser, such as
            <a
              target="blank"
              href="https://www.google.com/chrome/"
              className="fallback-message-link"
            >
              Google Chrome
            </a>
            .
          </p>
        </div>
      );
    }

    return <Component {...props} />;
  };

  return Es6SupportWrapper;
};

export default withEs6Support;
