import MixedMediaUploader from '@core/components/Media/components/MediaUploaders/MixedMediaUploader';
import styled, { css } from 'styled-components';

export const Uploader = styled(MixedMediaUploader)`
  ${({ theme: { spacing }, $hidden }) => css`
    margin-bottom: ${spacing.md};
    max-height: 400px;
    transition: max-height 300ms;
    overflow: auto;

    ${$hidden &&
    css`
      max-height: 0;
      overflow: hidden;
    `}
  `}
`;
