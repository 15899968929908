import { makeVar, useReactiveVar } from '@apollo/client';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useEffectOnlyOnce, usePrevious } from '../CustomHooks';
import VHQuery from './VHQuery';
import getConfig from '../../lib/config';

export const freeAgentsIndexedByAPIEndpointVar = makeVar({});

// need to use a global variable because otherwise race conditions mean that it fires off multiple requests on mount
window.loadingFreeAgentCount = {};

const useGetFreeAgentsCount = (apiEndpoint = null) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const freeAgentsIndexedByAPIEndpoint = useReactiveVar(
    freeAgentsIndexedByAPIEndpointVar
  );

  // we need to separate the loading state so that previous still-processing loads do not prevent new ones
  const APIObjKey = useMemo(
    () => apiEndpoint ?? getConfig('REACT_APP_PUBLIC_URL'),
    [apiEndpoint]
  );
  const latestAPIObjKeyRef = useRef(false);
  const loadingObjJKey = useMemo(() => 'loading_' + APIObjKey, [APIObjKey]);

  const requestFreeAgents = useCallback(async () => {
    if (window.loadingFreeAgentCount[loadingObjJKey]) {
      return;
    }
    window.loadingFreeAgentCount[loadingObjJKey] = true;
    setIsLoading(true);

    const data = await VHQuery(
      `agents?free=true`,
      // &organisationId=${OrganisationID}`,
      'GET',
      null,
      apiEndpoint ?? undefined
    );

    freeAgentsIndexedByAPIEndpointVar({
      ...freeAgentsIndexedByAPIEndpoint,
      [APIObjKey]: data.data,
    });

    window.loadingFreeAgentCount[loadingObjJKey] = false;
    setIsLoading(false);
  }, [freeAgentsIndexedByAPIEndpoint, loadingObjJKey, APIObjKey, apiEndpoint]);

  // retrieve cached apollo reactive var agent data for this api endpoint or get from vh db if no cache
  const getData = useCallback(async () => {
    try {
      if (!freeAgentsIndexedByAPIEndpoint[APIObjKey]) {
        await requestFreeAgents();
      }
      setIsLoading(false);
    } catch (e) {
      setError(error);
      setIsLoading(false);
      window.loadingFreeAgentCount[loadingObjJKey] = false;
      // failed to request agents, therefore set to empty (and only if there's no difference between the request and the latest org+api values)
      if (latestAPIObjKeyRef.current === APIObjKey) {
        freeAgentsIndexedByAPIEndpointVar({
          ...freeAgentsIndexedByAPIEndpoint,
          [APIObjKey]: [],
        });
      }
    }
  }, [
    error,
    freeAgentsIndexedByAPIEndpoint,
    requestFreeAgents,
    APIObjKey,
    loadingObjJKey,
  ]);

  // ensure window var is false on unmount so we don't get stuck not requesting
  useEffectOnlyOnce(() => {
    return () => {
      window.loadingFreeAgentCount = {};
    };
  });

  const prevAPIObjKey = usePrevious(APIObjKey);

  useEffect(() => {
    if (prevAPIObjKey !== APIObjKey) {
      latestAPIObjKeyRef.current = APIObjKey;
      getData();
    }
  }, [getData, prevAPIObjKey, APIObjKey]);

  return {
    freeAgentsCount: freeAgentsIndexedByAPIEndpoint[APIObjKey]
      ? freeAgentsIndexedByAPIEndpoint[APIObjKey].length
      : 0,
    isLoading,
    error,
    refetch: requestFreeAgents,
  };
};

export default useGetFreeAgentsCount;
