import styled from 'styled-components';
import BaseButton from '@base/components/buttons/Button';
import { Card as VirtiCard } from '@virtidev/toolbox';

export const Button = styled(BaseButton)`
  width: 100%;
  max-width: 12rem;
`;

export const CardTitle = styled.h2`
  color: var(--primary-font-color);
  font-size: 1.3rem;
  font-weight: normal;
  margin: 1rem 0;
`;

export const Card = styled(VirtiCard)``;

export const OverviewContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const DescriptionContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  ${Card} {
    flex: 1;
  }
`;

export const ButtonContainer = styled.div`
  flex: 1;
  margin-top: var(--content-side-padding);

  & + & {
    margin-left: var(--content-side-padding);
  }

  ${Card} {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--content-side-padding) calc(var(--content-side-padding) / 2);
  }
`;

export const ButtonText = styled.span`
  margin-left: 1rem;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
