import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './TextLabel.styled';

const TextLabel = (props) => {
  return (
    <Styled.Container>
      <Styled.Text textLabelType={props.type} maxLines={props.maxLines}>
        {props.children}
      </Styled.Text>
    </Styled.Container>
  );
};

TextLabel.propTypes = {
  type: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  maxLines: PropTypes.number,
};

export { TextLabelTypes } from './TextLabelUtil';
export default TextLabel;
