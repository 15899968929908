import { ButtonDropdown, ButtonDropdownButton } from '@virtidev/toolbox';
import { useState } from 'react';
import WithConfirmationBox from '../../../../../../HOCs/WithConfirmationBox';
import AddMembersToOrgModal from '../../Cx/UserTable/AddMembersToOrgModal/AddMembersToOrgModal';
import { ActionsWrapper } from './Actions.styled';
import AddMembersToGroupModal from './AddMembersToGroupModal';
import ChangeMemberType from './ChangeMemberType';
import DeleteMember from './DeleteMember';
import ResendWelcomeEmail from './ResendWelcomeEmail';
import RestoreMember from './RestoreMember';

const Actions = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [showOrgModal, setShowOrgModal] = useState(false);

  return (
    <>
      <ActionsWrapper>
        <ButtonDropdown
          htmlId="dropdown"
          buttonText={`Bulk Actions (${props.selectedIDs.length})`}
        >
          {props.disabledFilter ? (
            <RestoreMember {...props} button={ButtonDropdownButton} />
          ) : (
            <DeleteMember {...props} button={ButtonDropdownButton} />
          )}
          <ChangeMemberType
            {...props}
            button={ButtonDropdownButton}
            newUserType={'contentcreator'}
          />
          <ChangeMemberType
            {...props}
            button={ButtonDropdownButton}
            newUserType={'admin'}
          />
          <ChangeMemberType
            {...props}
            button={ButtonDropdownButton}
            newUserType={'user'}
          />
          <ResendWelcomeEmail {...props} button={ButtonDropdownButton} />
          {props.cxList ? (
            <ButtonDropdownButton onClick={() => setShowOrgModal(true)}>
              Add to Org
            </ButtonDropdownButton>
          ) : (
            <ButtonDropdownButton onClick={() => setShowModal(true)}>
              Add to Group
            </ButtonDropdownButton>
          )}
        </ButtonDropdown>
      </ActionsWrapper>
      {props.cxList ? (
        <AddMembersToOrgModal
          {...props}
          showModal={showOrgModal}
          setShowModal={setShowOrgModal}
        />
      ) : (
        <AddMembersToGroupModal
          {...props}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </>
  );
};

export default WithConfirmationBox(Actions);
