import { useReactiveVar } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import _, { camelCase } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { previewFeaturesVar } from '../../../../../../ApolloReactiveVars';
import getDevFeature from '../helpers/getDevFeature';

/**
 * @type {(featureNamesInput: string[] | string) => boolean[]}
 */
export const useFeature = (featureNamesInput) => {
  const flags = useFlags();
  const [featureNames, setFeatureNames] = useState(featureNamesInput);
  const previewFeatures = useReactiveVar(previewFeaturesVar);

  // memoize the array reference by checking value in this effect
  useEffect(() => {
    if (_.isEqual(featureNames, featureNamesInput)) {
      return;
    }
    setFeatureNames(featureNamesInput);
  }, [featureNamesInput, featureNames]);

  const featureState = useMemo(() => {
    const features = Array.isArray(featureNames)
      ? featureNames
      : [featureNames];

    const featureFlags = features.map((featureName) => {
      if (window.location.hostname === 'localhost') {
        const feature = getDevFeature(featureName);

        if (feature != null) {
          return feature;
        }
      }

      // Launch darkly transforms flag names to camel case
      const flagName = camelCase(featureName);

      if (
        previewFeatures.includes(featureName) &&
        featureName !== 'preview-features-mode' &&
        featureName !== 'unity-build-path'
      ) {
        return true;
      }

      return flags[flagName] || false;
    });

    return Array.isArray(featureNames) ? featureFlags : featureFlags[0];
  }, [featureNames, previewFeatures, flags]);

  return featureState;
};

export default useFeature;
