import { useMutation } from '@apollo/client';
import WithOnboardingHandler from '@base/HOCs/WithOnboardingHandler';
import {
  ADD_MEDIA_FROM_URL,
  ADD_VIDEO_FROM_URL,
} from '@base/queries/AssetQueries';
import { useFlashMessage } from '@core/components/FlashMessage';
import { useFeature } from '@core/components/LaunchDarkly';
import { Uploader } from '@core/components/Media/components/MediaPageUploader/MediaPageUploader.styled';
import getFileID from '@core/components/Media/components/MediaUploaders/helpers/getFileID';
import tracker from '@core/helpers/tracker';
import useUser from '@core/helpers/useUser';
import { Icon } from '@virtidev/toolbox';
import { flowRight } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

export const MediaPageUploader = ({ hidden, updateProgress }) => {
  const [stillImageSimFeature] = useFeature(['image-simulation-support']);
  const { addFlashMessage } = useFlashMessage();
  const { OrganisationID } = useUser();
  const [uploading, setUploading] = useState(false);

  const acceptedTypes = useMemo(
    () => (stillImageSimFeature ? ['video', 'image'] : ['video']),
    [stillImageSimFeature]
  );

  const [mutateAddVideo, { loading: addingVideo }] = useMutation(
    ADD_VIDEO_FROM_URL,
    {
      refetchQueries: ['readMediaPageVideo'],
    }
  );

  const [mutateAddMedia, { loading: addingImage }] = useMutation(
    ADD_MEDIA_FROM_URL,
    {
      refetchQueries: ['readMediaPageMedia'],
    }
  );

  const handleUpload = useCallback(
    async (upload, { content360, type }) => {
      setUploading(true);
      if (type === 'video') {
        const result = await mutateAddVideo({
          variables: {
            URL: upload.url,
            Filename: upload.file.name,
            TusID: getFileID(upload.url),
            OrganisationID,
            Content360: content360,
          },
        });
        const videoId = result?.data?.createVideoFromURL?.ID;
        if (videoId) {
          tracker.track('video_uploaded', {
            video_id: videoId,
          });
        }
      }
      if (type === 'image') {
        // mutateAddMedia
        const result = await mutateAddMedia({
          variables: {
            URL: upload.url,
            Filename: upload.file.name,
            TusID: getFileID(upload.url),
            OrganisationID,
            Content360: content360,
          },
        });
        const mediaId = result?.data?.createMediaFromURL?.ID;
        if (mediaId) {
          tracker.track('media_uploaded', {
            media_id: mediaId,
          });
        }
      }
    },
    [OrganisationID, mutateAddMedia, mutateAddVideo]
  );

  const handleComplete = useCallback(async () => {
    setUploading(false);
    updateProgress('upload_media');
    addFlashMessage('Upload successful', 'success');
    // setUploading(false);
  }, [addFlashMessage, updateProgress]);

  const handleError = useCallback(() => {
    addFlashMessage('Failed to upload file', 'error');
    setUploading(false);
  }, [addFlashMessage]);

  return (
    <Uploader
      title={
        <>
          What type of media do you want to add? <Icon icon="help" />
        </>
      }
      name="asset-uploader"
      acceptedTypes={acceptedTypes}
      loading={uploading || addingImage || addingVideo}
      warnPrompt
      multiple
      onSuccess={handleUpload}
      onComplete={handleComplete}
      onError={handleError}
      $hidden={hidden}
    />
  );
};

export default flowRight(WithOnboardingHandler)(MediaPageUploader);
