import { useMemo } from 'react';
import useStateUrl from '../../../helpers/useStateUrl';

const defaultOptions = { key: 'page', pageSize: 12, onChange: () => null };

export const usePaginationUrl = (options = {}) => {
  const config = { ...defaultOptions, ...options };
  const { value, updateValue, reset } = useStateUrl(config);
  const { pageSize } = config;

  const page = Math.max(Number(value), 1);

  const result = useMemo(
    () => ({
      page,
      pageSize,
      changePage: updateValue,
      resetPage: reset,
      pageQuery: {
        limit: pageSize,
        offset: (page - 1) * pageSize,
      },
      controlProps: {
        onPageChange: updateValue,
        page,
        pageSize,
      },
    }),
    [page, updateValue, reset, pageSize]
  );

  return result;
};
