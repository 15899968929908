import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';
import {
  DELETE_GROUPS,
  READ_GROUPS,
  READ_GROUP,
} from '../../queries/GroupQueries';
import WithFlashMessaging from '../../HOCs/WithFlashMessaging';
import WithConfirmationBox from '../../HOCs/WithConfirmationBox';
import { EllipsisMenuButton } from '../EllipsisMenu';
import Icon from '../icons/Icon';
import { withRouter } from 'react-router-dom';

const _updateCacheWithDeletedGroup = (
  cache,
  data,
  groupID,
  userOrganisationID
) => {
  const { readVirtiGroups } = cache.readQuery({ query: READ_GROUPS });
  const newGroups = {
    ...readVirtiGroups,
    edges: readVirtiGroups.edges.filter((edge) => edge.node.ID !== groupID),
  };
  cache.writeQuery({
    query: READ_GROUPS,
    data: { readVirtiGroups: newGroups },
    variables: {
      OrganisationID: userOrganisationID,
    },
  });
  cache.writeQuery({
    query: READ_GROUP,
    data: { readOneVirtiGroup: null },
    variables: {
      ID: groupID,
    },
  });
};

const DeleteGroupBtn = (props) => {
  return (
    <Mutation
      mutation={DELETE_GROUPS}
      variables={{ IDs: [props.groupID] }}
      onError={() => {
        props.addFlashMessage(
          'There was an error when attempting to delete the group',
          'error'
        );
      }}
      update={(cache, data) => {
        props.history.push({
          pathname: '/groups',
        });
        _updateCacheWithDeletedGroup(
          cache,
          data,
          props.groupID,
          props.userOrganisationID
        );
        props.addFlashMessage('Group has been deleted', 'success');
      }}
      onCompleted={() => {
        props.closeConfirm();
      }}
    >
      {(deleteGroups, { loading, error }) => {
        return (
          <EllipsisMenuButton
            className={props.className}
            loading={loading}
            onClick={(event) => {
              event.preventDefault();
              props.confirm(
                () => {
                  deleteGroups();
                },
                '',
                'Delete this group?',
                'delete'
              );
            }}
          >
            <Icon type="lineawesome" name="trash-solid" /> Delete Group
          </EllipsisMenuButton>
        );
      }}
    </Mutation>
  );
};

DeleteGroupBtn.propTypes = {
  groupID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  addFlashMessage: PropTypes.func.isRequired,
  userOrganisationID: PropTypes.number.isRequired,
  confirm: PropTypes.func.isRequired,
  closeConfirm: PropTypes.func.isRequired,
};

export default withRouter(
  WithConfirmationBox(WithFlashMessaging(DeleteGroupBtn))
);
