import { useCallback } from 'react';
import Checkbox from '../../../../form/Checkbox';
import {
  TagItem,
  TagItemName,
  TagListContainer,
} from '../../CardTooltipTags/CardTooltipTags.styled';

const noop = () => null;

export const TagList = ({ selectedTags, tagLoading, updateTags, tagsList }) => {
  const handleAdd = useCallback(
    (e) => {
      e && e.stopPropagation();
      if (!updateTags) {
        return null;
      }
      const tagId = e.currentTarget.name;

      updateTags((selected) => {
        const newSelected = [...selected, tagId];

        return newSelected;
      });
    },
    [updateTags]
  );

  const handleRemove = useCallback(
    (e) => {
      e && e.stopPropagation();
      if (!updateTags) {
        return null;
      }
      const tagId = e.currentTarget.name;

      updateTags((selected) => {
        const newSelected = selected.filter((id) => id !== tagId);

        return newSelected;
      });
    },
    [updateTags]
  );

  if (!tagsList?.length && !tagLoading) {
    return (
      <TagListContainer>
        ({updateTags ? 'No tags available' : 'No tags assigned'})
      </TagListContainer>
    );
  }

  return (
    <TagListContainer>
      {tagsList.map(
        ({ ID, Name }) =>
          (selectedTags.includes(ID) || ID === 'new-tag') && (
            <TagItem
              key={ID}
              name={ID}
              disabled={ID === 'new-tag'}
              onClick={handleRemove}
            >
              {updateTags && (
                <Checkbox
                  name={ID}
                  checked
                  size="small"
                  onChange={noop}
                  disabled={ID === 'new-tag'}
                />
              )}
              <TagItemName>{Name}</TagItemName>
            </TagItem>
          )
      )}
      {tagsList.map(
        ({ ID, Name }) =>
          !selectedTags.includes(ID) &&
          ID !== 'new-tag' && (
            <TagItem key={ID} name={ID} onClick={handleAdd}>
              {updateTags && <Checkbox size="small" onChange={noop} />}
              <TagItemName>{Name}</TagItemName>
            </TagItem>
          )
      )}
    </TagListContainer>
  );
};

export default TagList;
