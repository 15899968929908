import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import React from 'react';

const StyledH1 = styled.h1`
  font-size: 1.2rem;
  font-weight: 500;
  padding: 1.5rem var(--content-side-padding);
  ${(props) =>
    props.removeBottomPadding &&
    css`
      padding-bottom: 0;
    `}
`;

const StyledH1WithOtherContentWrapper = styled.div`
  padding: 1.5rem var(--content-side-padding);
  background-color: var(--secondary-bg-color);
  ${(props) =>
    props.centered &&
    css`
      text-align: center;
    `}
`;

const InlineH1 = styled.h1`
  font-size: 1.2rem;
  font-weight: 500;
  display: inline;
  line-height: 3rem;
`;

// sometimes we may want to include the header but with other content
// e.g. group page has an "edit group name" button next to it
const StyledH1WithOtherContent = props => {
  return (
    <StyledH1WithOtherContentWrapper centered={props.centered}>
      <InlineH1>{props.title}</InlineH1>
      {props.children}
    </StyledH1WithOtherContentWrapper>
  );
}
StyledH1WithOtherContent.propTypes = {
  centered: false
}
StyledH1WithOtherContent.propTypes = {
  title: PropTypes.string.isRequired,
  centered: PropTypes.bool,
  children: PropTypes.node,
};

export default StyledH1;
export { StyledH1, InlineH1, StyledH1WithOtherContent, StyledH1WithOtherContentWrapper }