import { makeVar } from '@apollo/client';

// todo: change to localStorage for more permanence

const closedKey = 'deepLinkClosed';
const closed = sessionStorage.getItem(closedKey);

export const deepLinkClosedVar = makeVar(closed ? new Date(closed) : null);

export const setDeepLinkClosedVar = (newValue) => {
  newValue ?
    sessionStorage.setItem(closedKey, newValue.toISOString())
    : sessionStorage.removeItem(closedKey);

  deepLinkClosedVar(newValue);
};
