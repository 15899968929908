import { gql } from '@apollo/client';

export const READ_SIMULATIONS = gql`
  query readSimulations($filter: SimulationFilterFields) {
    readSimulations(filter: $filter, limit: 50) {
      edges {
        node {
          ID
          Title
          AdminTitle
          ImageMedia {
            ID
            TusID
            URL
          }
          FirstVideo: Videos(limit: 1) {
            nodes {
              ID
              PosterURL
            }
          }
        }
      }
    }
  }
`;
