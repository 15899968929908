import { gql } from '@apollo/client';

export const CREATE_MEDIA = gql`
  mutation createMedia($Input: CreateMediaInput!) {
    createMedia(input: $Input) {
      ID
      Title
      URL
      Width
      Height
      Length
      TusID
      Filename
    }
  }
`;
