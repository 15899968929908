import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LoadingPageIndicator from '../components/LoadingPageIndicator';
import PageLoadError from '../components/PageLoadError';
import withUserPageTemplate from '../HOCs/templates/WithUserPageTemplate';
import { StyledPageContentInner } from '../components/StyledPage';
import RedirectWithPrevState from '../components/RedirectWithPrevState';
import UserTabNav from '../components/UserTabNav';
import {
  StyledDataBlock,
  StyledDataBlockBody,
  StyledDataBlockHeader,
  StyledDataBlockHeaderText,
  StyledDataBlocks,
  StyledDataItem,
  StyledDataLabel,
  BlockWrapper,
} from '../styled-components/StyledDataBlocks';
import BarChartTotalViewsOverTimeHighstock from '../components/charts/BarChartTotalViewsOverTimeHighstock';
import ScorePercentage from '../styled-components/StyledScorePercentage';
import { USER_VIRTUAL_HUMANS_ANALYTICS } from '../queries/VirtualHumanQueries';
import VirtualHumanScoresTable from '../components/VirtualHumanScoresTable';

const UserVirtualHumanAnalyticsPage = (props) => {
  const { loading, error, data } = useQuery(USER_VIRTUAL_HUMANS_ANALYTICS, {
    variables: {
      UserID: props.data.readOneMember.ID,
    },
    fetchPolicy: 'cache-and-network',
  });
  const analyticsData = data?.virtualHumanUserAnalytics;
  if (!props.userID) {
    return <RedirectWithPrevState to="/login" />;
  }
  if (loading || !analyticsData) {
    return (
      <>
        <UserTabNav userID={props.data.readOneMember.ID} />
        <StyledPageContentInner>
          <LoadingPageIndicator />
        </StyledPageContentInner>
      </>
    );
  }
  if (error) {
    return (
      <>
        <UserTabNav userID={props.data.readOneMember.ID} />
        <StyledPageContentInner>
          <PageLoadError graphQLErrorObj={error} />
        </StyledPageContentInner>
      </>
    );
  }
  return (
    <>
      <UserTabNav userID={props.data.readOneMember.ID} />
      <StyledPageContentInner>
        <BlockWrapper>
          <StyledDataBlocks>
            <StyledDataBlock>
              <StyledDataBlockHeader>
                <StyledDataBlockHeaderText>
                  Time Spent
                </StyledDataBlockHeaderText>
              </StyledDataBlockHeader>
              <StyledDataBlockBody>
                <div>
                  <StyledDataItem>
                    {analyticsData.TotalViews || 0}
                  </StyledDataItem>
                  <StyledDataLabel>
                    <FontAwesomeIcon icon="eye" /> Total Sessions
                  </StyledDataLabel>
                </div>
                <div>
                  <StyledDataItem>
                    {analyticsData.TotalTimeSpent || '-'}
                  </StyledDataItem>
                  <StyledDataLabel>
                    <FontAwesomeIcon icon="clock" /> Total Time Spent
                  </StyledDataLabel>
                </div>
              </StyledDataBlockBody>
            </StyledDataBlock>
            <StyledDataBlock>
              <StyledDataBlockHeader>
                <StyledDataBlockHeaderText>
                  Performance
                </StyledDataBlockHeaderText>
              </StyledDataBlockHeader>
              <StyledDataBlockBody>
                <div>
                  <StyledDataItem>
                    <ScorePercentage
                      align="center"
                      score={analyticsData.AverageScore}
                    />
                  </StyledDataItem>
                  <StyledDataLabel>
                    <FontAwesomeIcon icon="star" /> Average Score
                  </StyledDataLabel>
                </div>
                <div>
                  <StyledDataItem>
                    {analyticsData.AverageTimeSpent || '-'}
                  </StyledDataItem>
                  <StyledDataLabel>
                    <FontAwesomeIcon icon="clock" /> Average Time Spent
                  </StyledDataLabel>
                </div>
              </StyledDataBlockBody>
            </StyledDataBlock>
          </StyledDataBlocks>
        </BlockWrapper>
        <BlockWrapper>
          <BarChartTotalViewsOverTimeHighstock
            scores={analyticsData.DailyTotalViewsScores}
            viewsDays={analyticsData.DailyTotalViewsDays}
            views={analyticsData.DailyTotalViewsViews}
            title="Sessions and Scores"
          />
        </BlockWrapper>
        <BlockWrapper>
          <StyledDataBlock>
            <StyledDataBlockHeader>
              <StyledDataBlockHeaderText>
                Recent Sessions
              </StyledDataBlockHeaderText>
            </StyledDataBlockHeader>
            <VirtualHumanScoresTable
              scores={analyticsData.SimulationScores}
              userId={props.data.readOneMember.ID}
              nameColumn="virtual-human"
              useUserAnalyticsLink={true}
            />
          </StyledDataBlock>
        </BlockWrapper>
      </StyledPageContentInner>
    </>
  );
};

UserVirtualHumanAnalyticsPage.propTypes = {
  userID: PropTypes.number,
  data: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withUserPageTemplate(
  UserVirtualHumanAnalyticsPage,
  'virtual-human-analytics'
);
