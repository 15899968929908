import React from 'react';
import CXDashboardTabNav from '../components/CXDashboardTabNav';
import {
  CxEnvironmentsEditor,
  CxCopyVH,
  CxVoicesEditor,
  CxCopyActionBlueprints,
  CxListAgentUsage,
  CxAvatarsEditor,
} from '../apps/core/src/components/Cx/VirtualHuman';
import WithCXDashboardPageTemplate from '../HOCs/templates/WithCXDashboardPageTemplate';
import CxOrgVHLangList from '../apps/core/src/components/Cx/VirtualHuman/components/CxOrgVHLangList/CxOrgVHLangList';

const CXVHEditPage = () => {
  return (
    <>
      <CXDashboardTabNav />
      <CxCopyVH />
      <CxEnvironmentsEditor />
      <CxAvatarsEditor />
      <CxVoicesEditor />
      <CxCopyActionBlueprints />
      <CxListAgentUsage />
      <CxOrgVHLangList />
    </>
  );
};

export default WithCXDashboardPageTemplate(CXVHEditPage);
