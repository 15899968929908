import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './VideoTrimmer.styled';
import StyledTextInput from '../../../../../../styled-components/StyledTextInput';
import { useDrag } from 'react-dnd';
import {
  useInterval,
  usePrevious,
} from '../../../../../../utility/CustomHooks';
import { ToDecimalPlace } from '../../../../../../utility/NumberFormatting';
import WaveformCanvas from './WaveformCanvas/WaveformCanvas';

const VideoTrimmer = ({
  onVideoTimeChange,
  videoDuration,
  videoCursor,
  videoRef,
  blob,
  onTrimChange,
  disabled,
}) => {
  const outerBarRef = useRef(null);
  const [minTrim, setMinTrim] = useState(0);
  const [maxTrim, setMaxTrim] = useState(0);
  const [userEditedTrim, setUserEditedTrim] = useState(0);

  useEffect(() => {
    console.log('video duration change');
    console.log(videoDuration);
    // finalVideoDuration prevents weird bug when uploading (probably related to video element updating duration when dom updates in certain ways)
    if (videoDuration > 0 && !userEditedTrim) {
      setMinTrim(0);
      setMaxTrim(videoDuration);
    }
  }, [videoDuration, userEditedTrim]);

  // reset whether use has edited trim with new videos
  useEffect(() => {
    setUserEditedTrim(false);
  }, [blob]);

  const handleBarClick = (e) => {
    let rect = e.target.getBoundingClientRect();
    let x = e.clientX - rect.left; //x position within the element.
    if (e.target.id === 'trim-area') {
      // extra calculation for when clicking inside the trim area
      x += e.target.offsetLeft;
    }
    let fractionOfDuration = x / outerBarRef.current.offsetWidth;
    let newTime = fractionOfDuration * videoDuration;
    onVideoTimeChange(newTime);
  };

  const getDisplayData = () => {
    if (outerBarRef.current) {
      const cursorX =
        (videoCursor / videoDuration) * outerBarRef?.current.offsetWidth;
      const minTrimX =
        (minTrim / videoDuration) * outerBarRef?.current.offsetWidth;
      const trimWidth =
        (maxTrim / videoDuration) * outerBarRef?.current.offsetWidth - minTrimX;
      return {
        cursorX,
        minTrimX,
        trimWidth,
      };
    }
    return {
      cursor: 0,
      minTrimX: 0,
      trimWidth: 1,
    };
  };

  const [displayData, setDisplayData] = useState(getDisplayData());

  // const [collected, drag, dragPreview] = useDrag(() => ({
  //   type: 'trimMin',
  //     // item: data,
  //     end: (item, monitor) => {
  //       const dropResult = monitor.getDropResult();
  //       if (item && dropResult) {
  //         addSim(item, dropResult);
  //       }
  //     },
  //     collect: (monitor) => ({
  //       dragging: monitor.isDragging(),
  //     }),
  // }))

  const [draggingTrimMin, setDraggingTrimMin] = useState(false);
  const [draggingTrimMax, setDraggingTrimMax] = useState(false);
  const intervalCallbackRef = useRef(null);
  // const [intervalCallback, setIntervalCallback] = useState(false);
  const runBarDragCallback = (e) => {
    if (draggingTrimMin || draggingTrimMax) {
      setUserEditedTrim(true);
      let rect = e.target.getBoundingClientRect();
      let x = e.clientX - rect.left; //x position within the element.
      if (e.target.id === 'trim-area') {
        x += e.target.offsetLeft;
      }
      const newTrimFraction = x / outerBarRef?.current.offsetWidth;
      const newTrim = newTrimFraction * videoDuration;
      const minVideoDuration = 0.25;
      if (draggingTrimMin) {
        if (Math.abs(newTrim - minTrim) > 0.01) {
          // for some reason there was a bug here where it was corrupting the number if it was all in one call :/
          // so had to separate single line call into variables
          let preVal = Math.min(maxTrim, newTrim);
          if (Math.abs(preVal - maxTrim) < minVideoDuration) {
            // ensure at least min video duration
            preVal = maxTrim - minVideoDuration;
          }
          const newVal = ToDecimalPlace(preVal, 3);
          setMinTrim(newVal);
        }
      } else if (draggingTrimMax) {
        if (Math.abs(newTrim - maxTrim) > 0.01) {
          let preVal = Math.max(minTrim, newTrim);
          if (Math.abs(preVal - minTrim) < minVideoDuration) {
            // ensure at least min video duration
            preVal = minTrim + minVideoDuration;
          }
          const newVal = ToDecimalPlace(preVal, 3);
          setMaxTrim(newVal);
        }
      }
    }
  };

  // trigger props update event whenever trim changes
  const prevMinTrim = usePrevious(minTrim);
  const prevMaxTrim = usePrevious(maxTrim);
  useEffect(() => {
    const diffMin = Math.abs(prevMinTrim - minTrim);
    const diffMax = Math.abs(prevMaxTrim - maxTrim);
    if (onTrimChange && (diffMin > 0.01 || diffMax > 0.01)) {
      onTrimChange(minTrim, maxTrim);
    }
  }, [minTrim, maxTrim, onTrimChange, prevMinTrim, prevMaxTrim]);

  // interval and callback ref used to make it a lot more performant
  useInterval(() => {
    if (videoDuration === Infinity && videoRef.current) {
      // fallback to ensure that once video is loaded then we set the max trim
      setMaxTrim(videoRef.current.duration);
    }
    if (intervalCallbackRef.current) {
      intervalCallbackRef.current();
      intervalCallbackRef.current = null;
    }

    // also check for resize change
    const newDisplayData = getDisplayData();
    if (
      displayData.cursorX !== newDisplayData.cursorX ||
      displayData.trimWidth !== newDisplayData.trimWidth ||
      displayData.minTrimX !== newDisplayData.minTrimX
    ) {
      setDisplayData(newDisplayData);
    }
  }, 10);
  const handleBarOnDragOver = (e) => {
    if (e.target.id !== 'trim-area' && e.target.id !== 'outer-bar') return;
    intervalCallbackRef.current = () => runBarDragCallback(e);
    // setIntervalCallback(() => runBarDragCallback(e));
  };
  const handleTrimMaxOnDragStart = (e) => {
    setDraggingTrimMax(true);
  };
  const handleTrimMaxOnDragEnd = () => {
    setDraggingTrimMax(false);
  };
  const handleTrimMinOnDragStart = (e) => {
    setDraggingTrimMin(true);
  };
  const handleTrimMinOnDragEnd = () => {
    setDraggingTrimMin(false);
  };

  return (
    <Styled.Trimmer>
      <Styled.OuterBar
        id="outer-bar"
        ref={outerBarRef}
        onClick={handleBarClick}
        onDragOver={handleBarOnDragOver}
      >
        <WaveformCanvas
          blob={blob}
          videoRef={videoRef}
          videoDuration={videoDuration}
        />
        <Styled.TrimArea
          id="trim-area"
          style={{
            left: displayData.minTrimX,
            width: displayData.trimWidth,
          }}
          disabled={disabled}
        >
          <Styled.TrimDragMin
            draggable={!disabled}
            onDragEnd={handleTrimMinOnDragEnd}
            onDrag={handleTrimMinOnDragStart}
          />
          <Styled.TrimDragMax
            draggable={!disabled}
            onDragEnd={handleTrimMaxOnDragEnd}
            onDrag={handleTrimMaxOnDragStart}
          />
        </Styled.TrimArea>
        <Styled.Cursor style={{ left: displayData.cursorX }} />
      </Styled.OuterBar>
      <Styled.TrimInputs>
        <StyledTextInput
          label="Trim Start (seconds):"
          name="trim-start"
          value={minTrim}
          id="trim-start"
          type="number"
          disabled={disabled}
          onChange={(e) =>
            setMinTrim(Math.max(0, Math.min(e.target.value, maxTrim)))
          }
        />
        <StyledTextInput
          label="Trim End (seconds):"
          name="trim-end"
          value={maxTrim}
          id="trim-end"
          type="number"
          disabled={disabled}
          onChange={(e) =>
            setMaxTrim(
              Math.min(videoDuration, Math.max(e.target.value, minTrim))
            )
          }
        />
      </Styled.TrimInputs>
    </Styled.Trimmer>
  );
};

VideoTrimmer.propTypes = {
  videoDuration: PropTypes.number.isRequired,
  videoCursor: PropTypes.number.isRequired,
  videoRef: PropTypes.object.isRequired,
  onVideoTimeChange: PropTypes.func.isRequired,
  onTrimChange: PropTypes.func,
  blob: PropTypes.object,
  disabled: PropTypes.bool,
};

export default VideoTrimmer;
