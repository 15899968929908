import { useMutation } from '@apollo/client';
import { Icon } from '@virtidev/toolbox';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import WithConfirmationBox from '../../../HOCs/WithConfirmationBox';
import { useFlashMessaging } from '../../../HOCs/WithFlashMessaging';
import { EllipsisMenuButton } from '../../EllipsisMenu';
import { COPY_SIMULATION } from './CopySimulation.query';

export const CopySimulationButton = ({
  simulationID,
  organisationID,
  confirm,
}) => {
  const { addFlashMessage } = useFlashMessaging();
  const [copySim] = useMutation(COPY_SIMULATION, {
    variables: {
      simulationID,
      organisationID,
    },
  });

  const handleCopy = useCallback(() => {
    confirm(
      async () => {
        const { data } = await copySim();

        if (!data?.copySimulation) {
          addFlashMessage(
            'There was an error when duplicating this Simulation.',
            'error'
          );
          return;
        }
        addFlashMessage(
          <>
            Simulation duplicated successfully, you can{' '}
            {/* can't use <Link> or <LinkWithPrevState> for some reason... */}
            <a href={`/simulations/${data.copySimulation.ID}`}>find it here</a>.
          </>,
          'success'
        );
      },
      'Duplicate this Simulation?',
      'Are you sure you want to create a duplicate of this Simulation?',
      'new-primary'
    );
  }, [confirm, copySim, addFlashMessage]);

  return (
    <EllipsisMenuButton type="button" onClick={handleCopy}>
      <Icon icon="duplicate" size="1.3rem" />
      Duplicate Simulation
    </EllipsisMenuButton>
  );
};

export default WithConfirmationBox(CopySimulationButton);
