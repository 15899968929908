import { FC, useCallback, Dispatch, SetStateAction } from 'react';
import {
  secondarySidebarContentVar,
  setSecondarySidebarContentVar,
} from '../../../../../../../ApolloReactiveVars';
import { useCanViewUserSideVirtualHumans } from '../../../../helpers/permissions.hooks';
import ButtonItem from '../ButtonItem/ButtonItem';
import LinkItem from '../LinkItem/LinkItem';

/**
 * @type {FC<{
 *    setShowInviteUsersModal: Dispatch<SetStateAction<boolean>>,
 * }>}
 */
export const UserSidebar = ({ setShowInviteUsersModal }) => {
  const canViewVHs = useCanViewUserSideVirtualHumans();

  const openSubContent = useCallback((contentName) => {
    if (secondarySidebarContentVar() === contentName) {
      setSecondarySidebarContentVar(null);
      return;
    }
    setSecondarySidebarContentVar(contentName);
  }, []);

  const openMyProfile = useCallback(() => {
    openSubContent('my-profile');
  }, [openSubContent]);

  const showInvite = useCallback(
    (e) => {
      if (e?.preventDefault) {
        e.preventDefault();
      }
      setShowInviteUsersModal(true);
    },
    [setShowInviteUsersModal]
  );

  return (
    <>
      <LinkItem icon="dashboard" exact to="/home">
        Home
      </LinkItem>
      <LinkItem icon="course" to="/my-courses">
        My Courses
      </LinkItem>
      <LinkItem icon="vr" iconType="outline" to="/my-simulations">
        My Simulations
      </LinkItem>
      {canViewVHs && (
        <LinkItem icon="virtual-human" to="/my-virtual-humans">
          My Virtual Humans
        </LinkItem>
      )}
      <ButtonItem
        icon="user"
        iconType="outline"
        contentName="my-profile"
        to="/my-profile/"
        onClick={openMyProfile}
        sublinks={[
          { to: '/my-profile/badges', label: 'Badges' },
          { to: '/my-profile/simulations', label: 'Simulations' },
          canViewVHs && {
            to: '/my-profile/virtual-humans',
            label: 'Virtual Humans',
          },
        ]}
      >
        My Profile
      </ButtonItem>
    </>
  );
};

export default UserSidebar;
