import { gql } from '@apollo/client';

export const READ_VIDEOS = gql`
  query readAssetSelectVideo(
    $limit: Int
    $offset: Int
    $sort: VideoMediaSortFields
    $filter: VideoMediaFilterFields
  ) {
    readVideoMedias(
      offset: $offset
      limit: $limit
      sort: $sort
      filter: $filter
    ) {
      pageInfo {
        totalCount
        hasNextPage
        hasPreviousPage
      }
      nodes {
        ID
        PosterURL
        TranscodingStatus
        EditorURL
        SourceURL
        Title
        Length
        Content360
      }
    }
  }
`;

export const READ_IMAGES = gql`
  query readAssetSelectImage(
    $limit: Int
    $offset: Int
    $sort: MediaSortFields
    $filter: MediaFilterFields
  ) {
    readMedias(
      FileType: "image"
      offset: $offset
      limit: $limit
      sort: $sort
      filter: $filter
    ) {
      pageInfo {
        totalCount
        hasNextPage
        hasPreviousPage
      }
      nodes {
        ID
        URL
        Title
        Length
        Content360
      }
    }
  }
`;
