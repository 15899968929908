import { gql } from '@apollo/client';

export const READ_ORGANISATION = gql`
  query readOrganisationTUSEndpoint($ID: ID!) {
    readOneOrganisation(filter: { ID: { eq: $ID } }) {
      ID
      TUSEndpoint
    }
  }
`;
