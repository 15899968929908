import FlashMessageHolder from '@base/components/FlashMessageHolder';
import { PageScrollContext } from '@core/components/Page';
import usePageTemplateScroll from '@core/components/Page/helpers/usePageTemplateScroll';
import { withPagePartContainer } from '@core/components/Page/PagePart.state';
import {
  PageBody,
  PageContent,
  rebrand,
  Square,
  SquareContainer,
} from '@core/templates/components/SplashPage/SplashPage.styled';
import {
  PageContainer,
  PageGlobalStyle,
} from '@core/templates/components/StandardPage/Page.styled';
import { FC } from 'react';

/**
 * @type {FC<{
 *    expanded?: boolean,
 *    pageKey?: string,
 * }>}
 */
export const SplashPage = (props) => {
  const { setContentRef, pageScrollContext } = usePageTemplateScroll();
  const { expanded } = props;

  const {
    page: {
      splash: { square },
    },
  } = rebrand;

  return (
    <PageContainer {...props}>
      <PageGlobalStyle />
      <PageBody>
        <PageContent ref={setContentRef} $expanded={expanded}>
          <FlashMessageHolder />
          <PageScrollContext.Provider value={pageScrollContext}>
            {props.children}
          </PageScrollContext.Provider>
          <SquareContainer $expanded={expanded}>
            {/* top left */}
            <Square $dark style={{ top: 0, left: 0 }} />
            <Square style={{ top: square.md, left: 0 }} />
            <Square style={{ top: 0, left: square.md }} />
            <Square
              $dark
              style={{
                top: `calc(-1 * ${square.md})`,
                left: `calc(-1 * ${square.md})`,
              }}
            />
            <Square
              $small
              style={{
                top: `calc(-1 * (${square.md} + ${square.sm}))`,
                left: `calc(-1 * (${square.md} + ${square.sm}))`,
              }}
            />
            <Square
              $small
              style={{
                top: `calc(-1 * ${square.md})`,
                left: `calc(-2 * ${square.md})`,
              }}
            />

            {/* top right */}
            <Square style={{ top: 0, right: 0 }} />
            <Square
              $small
              style={{
                top: square.md,
                right: square.md,
              }}
            />
            <Square
              style={{
                top: `calc(-1 * ${square.md})`,
                right: `calc(-1 * ${square.md})`,
              }}
            />
            <Square
              $dark
              style={{
                top: `calc(-1 * ${square.md})`,
                right: `calc(-2 * ${square.md})`,
              }}
            />
            <Square
              style={{
                top: 0,
                right: `calc(-2 * ${square.md})`,
              }}
            />

            {/* bottom left */}
            <Square $dark style={{ bottom: 0, left: 0 }} />
            <Square
              $small
              style={{
                bottom: square.md,
                left: square.md,
              }}
            />
            <Square
              $small
              style={{
                bottom: `calc(-1 * ${square.sm})`,
                left: `calc(-1 * ${square.md})`,
              }}
            />
            <Square
              style={{
                bottom: `calc(-1 * (${square.md} + ${square.sm}))`,
                left: `calc(-2 * ${square.md})`,
              }}
            />
            <Square
              $small
              style={{
                bottom: `calc(-1 * ${square.sm})`,
                left: `calc(6 * ${square.md})`,
              }}
            />
            <Square
              $small
              style={{
                bottom: `calc(-2 * ${square.sm})`,
                left: `calc(6 * ${square.md} - ${square.sm})`,
              }}
            />
            <Square
              $small
              style={{
                bottom: `calc(-2 * ${square.sm})`,
                left: `calc(6 * ${square.md} + ${square.sm})`,
              }}
            />
            <Square
              $small
              style={{
                bottom: `calc(-3 * ${square.sm})`,
                left: `calc(6 * ${square.md} + 2 * ${square.sm})`,
              }}
            />

            {/* bottom right */}
            <Square $small style={{ bottom: 0, right: 0 }} />
            <Square $dark style={{ bottom: 0, right: square.sm }} />
            <Square $small style={{ bottom: square.md, right: 0 }} />
            <Square
              $dark
              style={{
                bottom: `calc(-1 * ${square.sm})`,
                right: `calc(-1 * ${square.md})`,
              }}
            />
            <Square
              $small
              style={{
                bottom: `calc(-1 * ${square.md})`,
                right: `calc(-1 * (${square.md} + ${square.sm}))`,
              }}
            />
            <Square
              $small
              style={{
                bottom: `calc(-1 * (${square.md} + ${square.sm}))`,
                right: `calc(-1 * ${square.md})`,
              }}
            />
          </SquareContainer>
        </PageContent>
      </PageBody>
    </PageContainer>
  );
};

SplashPage.defaultProps = {};

SplashPage.propTypes = {};

export default withPagePartContainer(SplashPage);
