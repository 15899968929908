import { gql } from '@apollo/client';

export const pseudoGroupFragment = `
  ID
  Name
  OrganisationID
  InviteCodeAdmin
  InviteCodeUser
`;

export const READ_GROUPS = gql`
  query readVirtiGroups($organisationID: ID) {
    readVirtiGroups(filter: {Organisation: { ID: { eq: $organisationID } }}) {
      edges {
        node {
          ${pseudoGroupFragment}
        }
      }
    }
  }
`;

export const READ_GROUP_NAMES = gql`
  query readVirtiGroupsSelect($organisationID: ID) {
    readVirtiGroups(filter: { Organisation: { ID: { eq: $organisationID } } }) {
      edges {
        node {
          ID
          Name
        }
      }
    }
  }
`;

export const READ_GROUPS_PAGINATED = gql`
  query readVirtiGroupsPaginated(
    $limit: Int!
    $offset: Int!
    $filter: String
    $organisationID: ID
  ) {
    readVirtiGroups(
      offset: $offset
      limit: $limit
      filter: {
        Name: { contains: $filter }
        Organisation: { ID: { eq: $organisationID } }
      }
    ) {
      pageInfo {
        totalCount
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ID
          Name
          SimulationCount
          UserCount
          # OrganisationID
          InviteCodeAdmin
          InviteCodeUser
          Simulations {
            pageInfo {
              totalCount
            }
          }
          VirtualHumans {
            pageInfo {
              totalCount
            }
          }
          Courses {
            pageInfo {
              totalCount
            }
          }
        }
      }
    }
  }
`;

export const ADD_GROUP = gql`
  mutation createGroup($Input: CreateVirtiGroupInput!) {
    createVirtiGroup(input: $Input) {
      ${pseudoGroupFragment}
    }
  }
`;

export const READ_GROUP = gql`
  query readOneVirtiGroup($ID: ID!) {
    readOneVirtiGroup(filter: { ID: { eq: $ID } }) {
      ${pseudoGroupFragment}
    }
  }
`;
export const READ_GROUP_WITH_PAGINATED_USERS = gql`
  query readOneVirtiGroupWithUsers(
    $ID: ID!
    $nameFilter: String
    $limit: Int!
    $offset: Int!
  ) {
    readOneVirtiGroup(filter: { ID: { eq: $ID } }) {
      ${pseudoGroupFragment}
      Users(
        Name: $nameFilter
        limit: $limit
        offset: $offset
      ) {
        pageInfo {
          totalCount
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            ID
            Email
            Name
            LastSeen
            AvatarMedia {
              URL
            }
          }
        }
      }
    }
  }
`;

export const EDIT_GROUP = gql`
  mutation updateVirtiGroup($Input: UpdateVirtiGroupInput!) {
    updateVirtiGroup(input: $Input) {
      ${pseudoGroupFragment}
    }
  }
`;

export const DELETE_GROUPS = gql`
  mutation deleteVirtiGroups($IDs: [ID]!) {
    deleteVirtiGroups(ids: $IDs)
  }
`;

export const GROUP_ANALYTICS = gql`
  query groupAnalytics($GroupID: ID!) {
    groupAnalytics(GroupID: $GroupID) {
      TotalCompletions
      TotalTimeWatched
      AverageReactionTime
      AverageScore
      DailyTotalViewsDays
      DailyTotalViewsViews
      DailyTotalViewsScores
    }
  }
`;

export const UPDATE_USER_TYPE = gql`
  mutation bulkUpdateUsers($MemberID: ID!, $UserType: String) {
    bulkUpdateUsers(MemberIDs: [$MemberID], UserType: $UserType) {
      ID
      UserType
    }
  }
`;
