import React, { useCallback, useMemo } from 'react';
import { Icon } from '@virtidev/toolbox';
import { StyledStep } from './OnboardingUsage.styled';

export default function StepList({ progressJSON }) {
  const parsedSteps = useMemo(() => {
    const parsed = progressJSON && JSON.parse(progressJSON);
    const progress = parsed && parsed.progress;
    const progressKeys = progress && Object.keys(progress);

    const usableProgress =
      progressKeys &&
      progressKeys.map((key) => {
        const keyValueObject = {
          key: key,
          completed: progress[key],
        };
        return keyValueObject;
      });

    return usableProgress;
  }, [progressJSON]);

  return (
    <div>
      {parsedSteps.map((step) => {
        return (
          <StyledStep key={step.key}>
            <div>
              {step.completed ? (
                <Icon
                  icon="checkbox"
                  type="outline"
                  color="var(--secondary-color)"
                  size="0.7rem"
                />
              ) : (
                <Icon
                  icon="error"
                  type="outline"
                  color="var(--negative-color)"
                  size="0.7rem"
                />
              )}
            </div>
            <div>{step.key}</div>
          </StyledStep>
        );
      })}
    </div>
  );
}
