import React from 'react';
import PropTypes from 'prop-types';
import WithOnboardingHandler from '../HOCs/WithOnboardingHandler';
import {
  StyledCenterAlignWrapper,
  StyledOnboardingResetButton,
  StyledSettingsTitle,
  StyledSettingsWrapper,
} from '../styled-components/StyledOnboardingDashboard';
import {
  DropdownButton,
  DropdownLinkContent,
  DropdownLinksWrapper,
  DropdownOnClickLink,
} from './DropdownLinks';
import Button from './buttons/Button';
import WithConfirmationBox from '../HOCs/WithConfirmationBox';

function OnboardingSettings(props) {
  return (
    <StyledCenterAlignWrapper>
      <StyledSettingsWrapper>
        <StyledOnboardingResetButton
          disabled={props.loading}
          type="secondary"
          color={'#fff'}
          onClick={() =>
            props.confirm(
              () => {
                props.resetOnboarding();
              },
              'Are you sure you want to reset onboarding?',
              `Any content you've created during onboarding will still exist, but your recorded progress will be lost.`,
              'delete'
            )
          }
        >
          Reset Onboarding
        </StyledOnboardingResetButton>
        <StyledOnboardingResetButton
          disabled={props.loading}
          type="secondary"
          onClick={() =>
            props.confirm(
              () => {
                props.setDismissOnboarding(true);
              },
              'Are you sure you want to dismiss onboarding?',
              `You will lose your onboarding progress. Onboarding can be restarted from the beginning.`,
              'delete'
            )
          }
        >
          Dismiss Onboarding
        </StyledOnboardingResetButton>
        {/* <DropdownLinksWrapper thereAreDropdownLinks={true}>
          <DropdownOnClickLink thereAreDropdownLinks={false}>
            <StyledSettingsTitle>
              Settings
              <Icon type="outline" icon="cog" />
            </StyledSettingsTitle>
            <DropdownLinkContent>
              <DropdownButton
                disabled={props.loading}
                type="secondary"
                color={'#fff'}
                onClick={() =>
                  props.confirm(
                    () => {
                      props.resetOnboarding();
                    },
                    'Are you sure you want to reset onboarding?',
                    `Any content you've created during onboarding will still exist, but your recorded progress will be lost.`,
                    'delete'
                  )
                }
              >
                Reset Onboarding
              </DropdownButton>
              <DropdownButton
                disabled={props.loading}
                type="secondary"
                onClick={() =>
                  props.confirm(
                    () => {
                      props.setDismissOnboarding(true);
                    },
                    'Are you sure you want to dismiss onboarding?',
                    `The onboarding sidebar will disappear. Onboarding can be resumed at a later time.`,
                    'delete'
                  )
                }
              >
                Dismiss Onboarding
              </DropdownButton>
            </DropdownLinkContent>
          </DropdownOnClickLink>
        </DropdownLinksWrapper> */}
      </StyledSettingsWrapper>
    </StyledCenterAlignWrapper>
  );
}

OnboardingSettings.propTypes = {};

export default WithConfirmationBox(WithOnboardingHandler(OnboardingSettings));
