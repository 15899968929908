import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Breakpoints from '../themes/Breakpoints';
import { getResizeImage } from '../apps/core/src/helpers/imageResizing/getResizeImage';
import LearnerDataCard from '@core/components/DataListCard/LearnerDataCard';

export const VirtualHumansList = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(3, calc(33.33333333% - 1.4rem));
  grid-gap: 2rem;
  @media (max-width: ${Breakpoints.large}) {
    grid-template-columns: repeat(2, calc(50% - 1.4rem));
  }
  @media (max-width: ${Breakpoints.med}) {
    grid-template-columns: 1fr;
  }
`;

const MyVirtualHumanItem = (props) => {
  const patientLink = `/my-virtual-humans/${props.data.ID}/play`;

  const thumbnailUrl = useMemo(() => {
    if (props.data?.Thumbnail?.TusID) {
      return getResizeImage(props.data?.Thumbnail?.TusID);
    }
    return props.data.ThumbnailURL;
  }, [props.data]);

  return (
    <LearnerDataCard
      to={patientLink}
      thumbnailUrl={thumbnailUrl}
      title={props.data.Title}
    />
  );
};

MyVirtualHumanItem.propTypes = {
  data: PropTypes.shape({
    ID: PropTypes.string.isRequired,
    Title: PropTypes.string.isRequired,
    AverageScore: PropTypes.string,
    Diagnosis: PropTypes.string,
  }),
};

export default MyVirtualHumanItem;
