import { gql } from '@apollo/client';

export const ADD_SIMULATION_EDITOR = gql`
  mutation addSimulationEditor($MemberID: ID!, $SimulationID: ID!) {
    addSimulationEditor(MemberID: $MemberID, SimulationID: $SimulationID) {
      ID
      Editors {
        edges {
          node {
            ID
            Name
          }
        }
      }
    }
  }
`;

export const REMOVE_SIMULATION_EDITOR = gql`
  mutation removeSimulationEditor($MemberID: ID!, $SimulationID: ID!) {
    removeSimulationEditor(MemberID: $MemberID, SimulationID: $SimulationID) {
      ID
      Editors {
        edges {
          node {
            ID
            Name
          }
        }
      }
    }
  }
`;
