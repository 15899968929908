import { useCallback, useEffect, useRef, useState } from 'react';
import { GetFormattedTime } from '../../../../utility/TimeFormatting';

// works but unused
// export const useSecondsSince = function (startTime) {
//   const [secondsSince, setSecondsSince] = useState(0);
//   useEffect(() => {
//     const interval = setInterval(() => {
//       const timeSince = new Date().getTime() - startTime;
//       setSecondsSince(Math.round(timeSince / 1000));
//     }, 100); // interval lower than 1000 since float comparison might lead to some updates to timer not updating in proper time sync
//     return () => clearInterval(interval);
//   })
//   return secondsSince;
// }

export const useStopwatchTimer = function (
  startTime,
  totalTimeSeconds,
  callback
) {
  // this timer has become slightly broken and needs refactoring/recreating but haven't had time to prioritise
  // when it still essentially works for triggering once - mainly due to it being an iframe so state is always
  // reset when navigating different sessions
  // in branching VHs it was triggering twice for some reason. hasFinishedonce used to prevent the second callback.
  const hasFinishedOnce = useRef(false);

  const [timerState, setTimerState] = useState({
    running: false,
    progress: 0,
    msRemaining: totalTimeSeconds * 1000,
    formattedTimeRemaining: GetFormattedTime(totalTimeSeconds * 1000),
    finished: false,
  });
  const running = useRef(false);
  // const [timerInterval, setTimerInterval] = useState(null);
  // const startTimer = useCallback(() => {
  //   clearInterval(timerInterval);
  //   const interval = setInterval(() => {
  //     const curTime = new Date().getTime();
  //     const endTime = startTime + (totalTimeSeconds * 1000);
  //     const msRemaining = endTime - curTime;
  //     setSecondsRemaining(msRemaining);
  //     if (startTime && totalTimeSeconds && msRemaining < 0) {
  //       callback();
  //       clearInterval(interval);
  //     }
  //   }, 50);
  //   setTimerInterval(interval);
  // }, [callback, startTime, totalTimeSeconds, timerInterval]);

  useEffect(() => {
    running.current = timerState.running;
  }, [timerState.running]);

  useEffect(() => {
    if (!running.current) return;

    const timeoutCallback = () => {
      if (!running.current) return;
      const curTime = new Date().getTime();
      const endTime = startTime + totalTimeSeconds * 1000;
      const msRemaining = endTime - curTime;
      const secondsRemaining = Math.round(msRemaining / 1000);
      if (
        startTime &&
        totalTimeSeconds &&
        msRemaining < 0 &&
        !timerState.finished
      ) {
        // timer finished
        setTimerState((t) => {
          return {
            ...t,
            ...{
              finished: true,
              running: false,
              msRemaining: 0,
              progress: 100,
              formattedTimeRemaining: GetFormattedTime(
                timerState.running
                  ? Math.max(0, msRemaining)
                  : totalTimeSeconds * 1000
              ),
            },
          };
        });
        if (!hasFinishedOnce.current) {
          callback();
        }
        hasFinishedOnce.current = true;
      } else {
        // only update if minimum time has passed
        const minMsPassedToUpdate = 1000;
        if (
          Math.abs(timerState.msRemaining - msRemaining) >= minMsPassedToUpdate
        ) {
          setTimerState((t) => {
            return {
              ...t,
              ...(t.running && {
                msRemaining,
                progress:
                  ((totalTimeSeconds * 1000 - msRemaining) /
                    (totalTimeSeconds * 1000)) *
                  100,
                formattedTimeRemaining: GetFormattedTime(
                  timerState.running
                    ? Math.max(0, msRemaining)
                    : totalTimeSeconds * 1000
                ),
              }),
            };
          });
        }
        // timeout lower than 1000 since float comparison might lead to some updates to timer not updating in proper time sync
        const timer = setTimeout(timeoutCallback, 50);
        return timer;
      }
    };
    const timer = timeoutCallback();
    return () => {
      clearTimeout(timer);
    };
  }, [
    callback,
    startTime,
    totalTimeSeconds,
    timerState.finished,
    timerState.running,
    timerState.msRemaining,
  ]);

  return [
    timerState,
    () =>
      setTimerState((t) => {
        return { ...t, running: true };
      }), // startTimer function
    () =>
      setTimerState((t) => {
        return {
          ...t,
          running: false,
        };
      }), // stopTimer function
  ];
};

export const useInterval = (callback, delay) => {
  const savedCallbackRef = useRef();

  useEffect(() => {
    savedCallbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler = (...args) => savedCallbackRef.current(...args);

    if (delay !== null) {
      const intervalId = setInterval(handler, delay);
      return () => clearInterval(intervalId);
    }
  }, [delay]);
};
