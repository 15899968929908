import { useQuery } from '@apollo/client';
import { READ_HAS_CONTENT_QUEUED_FOR_COPY } from './useHasPendingContent.query';

export const useHasPendingContent = (organisationId, variables = {}) => {
  const { data, loading } = useQuery(READ_HAS_CONTENT_QUEUED_FOR_COPY, {
    variables: {
      ID: organisationId,
      ...variables,
    },
    fetchPolicy: 'cache-and-network',
  });

  return {
    data: data?.readOneOrganisation,
    loading,
  };
};
