import React from 'react';
import SelectVirtualHuman from '../../../form/Select/components/SelectVirtualHuman/SelectVirtualHuman';
import SelectSection from '../SelectSection/SelectSection';

export default function VirtualHumansSection({
  getValues,
  handleAdd,
  handleRemove,
}) {
  const beforeField = `VirtualHumansBefore`;
  const afterField = `VirtualHumansAfter`;

  return (
    <SelectSection
      title="Virtual Humans"
      selectBefore={
        <SelectVirtualHuman
          value={getValues(beforeField)}
          multi
          onAdd={(value, label) => handleAdd(value, label, beforeField)}
          onRemove={(value, label) => handleRemove(value, label, beforeField)}
        />
      }
      selectAfter={
        <SelectVirtualHuman
          multi
          value={getValues(afterField)}
          onAdd={(value, label) => handleAdd(value, label, afterField)}
          onRemove={(value, label) => handleRemove(value, label, afterField)}
        />
      }
    />
  );
}
