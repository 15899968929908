import styled, { css } from 'styled-components';

export const S_table = styled.table`
  width: 100%;
  margin: 1rem 0;
`;

export const S_thead = styled.thead``;
export const S_tbody = styled.tbody``;
export const S_scrollableWrapper = styled.div`
  position: relative;
  overflow: auto;
  height: 37rem;

  overflow-x: auto;
`;
export const S_td = styled.td`
  text-align: center;
  padding: 1rem;
  border-bottom: var(--primary-border);
  color: ${(props) => props.color};
`;

export const S_tdLeft = styled(S_td)`
  text-align: left;
`;
export const S_th = styled.th`
  text-align: center;
  padding: 1rem;
  border-bottom: var(--primary-border);
  font-weight: 500;
  text-transform: uppercase;
  font-size: 0.7rem;
`;
export const S_thSticky = styled.th`
  text-align: left;
  padding: 1rem;
  border-bottom: var(--primary-border);
  font-weight: 900;
  text-transform: uppercase;
  font-size: 0.8rem;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1003;
`;
export const S_thLeft = styled(S_th)`
  text-align: left;
`;
export const S_tr = styled.tr`
  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
      user-select: none;
      &:hover {
        background: var(--primary-color-lighter);
      }
    `}
`;

export const StyledDoubleTd = styled.td`
  text-align: left;
  width: 7rem;
  padding: 1rem;
  border-bottom: var(--primary-border);
  white-space: nowrap;
`;
