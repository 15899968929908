import { Icon, Tooltip } from '@virtidev/toolbox';
import * as Styled from './VHTypeIcon.styled';

const TypeIcon = ({ type }) => {
  let tooltip = 'This is a freeform virtual human';
  let icon = 'speech-bubble';
  let iconType = 'solid';
  if (type === 'freeform_v3') {
    tooltip = tooltip.replace('freeform', 'freeform (v3)');
  }
  if (type === 'medical') {
    tooltip = tooltip.replace('freeform', 'medical');
    icon = 'medical';
  }
  if (type === 'branching') {
    tooltip = tooltip.replace('freeform', 'branching');
    icon = 'branch';
    iconType = 'outline';
  }
  return (
    <Styled.VHTypeIcon title={tooltip}>
      <Styled.VHTypeTooltip
        iconColor="#FFF"
        icon={icon}
        iconType={iconType}
        placement="bottom-start"
      >
        {tooltip}
      </Styled.VHTypeTooltip>
      {type === 'freeform_v3' && (
        <Styled.FreeformV3Indicator>V3</Styled.FreeformV3Indicator>
      )}
    </Styled.VHTypeIcon>
  );
};

export default TypeIcon;
