import { InnerScrollbar } from '../../EnvironmentAvatarSelectors.styled';
import { Button } from '@virtidev/toolbox';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  max-height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 0.5rem;
  overflow-y: auto;
  padding: 1rem;
  position: absolute;
  top: 0;
  overflow-y: auto;
  ${InnerScrollbar}
`;

export const SelectionButton = styled(Button)``;
