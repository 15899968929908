import { Infobox, Modal, TextInput } from '@virtidev/toolbox';
import styled, { css } from 'styled-components';

export const CreateWithAiModal = styled(Modal)`
  .modal-box {
    width: min(90%, ${({ $maxWidth }) => $maxWidth || '500px'});
  }
  .modal-footer {
    padding: ${({ theme }) => theme.spacing.p6};
    border-top: 1px solid ${({ theme }) => theme.color.midnight30};
  }
  .modal-header {
    ${({ theme: { spacing } }) => css`
      padding-left: ${spacing.p8};
    `}
  }
  ${({ $isWelcomeStep }) =>
    $isWelcomeStep &&
    css`
      .modal-header {
        height: 0;
      }
      .modal-content {
        padding-top: 0;
        padding-bottom: 0;
      }
      .modal-footer {
        border-top: none;
      }
    `}
`;

export const FormWrapper = styled.div`
  &,
  form {
    display: flex;
    flex-direction: column;
    gap: 44px;
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.p5};
`;

export const FieldError = styled.label`
  color: var(--negative-color);
  font-size: ${({ theme }) => theme.fontSize.xs};
  padding-top: 2px;
  margin-left: auto;
`;

export const LabelWrapper = styled.div`
  display: flex;
  // justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
`;

export const Label = styled.label`
  font-size: 1rem;
  font-weight: 500;
`;

export const Text = styled.p`
  font-size: ${({ theme }) => theme.fontSize.md};
  color: ${({ theme }) => theme.color.midnight10};

  /* Body md */
  font-family: Cabin;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
`;

export const IntroText = styled(Text)`
  margin-bottom: ${({ theme }) => theme.spacing.p4};
`;

export const ReviewText = styled(Text)`
  color: ${({ theme }) => theme.color.midnight10};
  margin-top: -1em;
`;

export const LoadingWrapper = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  text-align: center;
  text-wrap: balance;
`;

export const ErrorText = styled(Text)`
  color: ${({ theme }) => theme.color.error};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
`;

export const Link = styled.a`
  color: ${(props) => props.theme.color.primary};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  &:visited {
    color: ${(props) => props.theme.color.primaryLight};
  }
  &:hover {
    text-decoration: underline;
  }
  &:active {
    color: ${(props) => props.theme.color.primaryDark};
  }
`;

export const ModalHeading = styled.h2`
  /* H2 lg */
  font-family: ${({ theme }) => theme.fontFamilyTitle};
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: ${({ theme }) => theme.color.midnight};
  padding-top: ${({ theme }) => theme.spacing.p5};
`;

export const WelcomeModalHeading = styled(ModalHeading)`
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  padding-top: ${({ theme }) => theme.spacing.p4};
`;
export const WelcomeModalSubHeading = styled.h3`
  color: ${({ theme }) => theme.color.midnight};
  margin-bottom: ${({ theme }) => theme.spacing.p1};

  /* Label ? */
  font-family: Cabin;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
export const WelcomeModal = styled.div`
  @media (min-width: 768px) {
    padding: 0 10px 10px 10px;
    display: flex;
    gap: 10%;
    align-items: center;
  }
`;
export const WelcomeModalImages = styled.div`
  width: 40%;
  padding-top: 40px;
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`;
export const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.p4};
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;
export const ModalFooterActions = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.p4};
`;
export const ModalFooterText = styled(Text)`
  padding: 0 10px;
  @media (min-width: 768px) {
    padding: 0 0 0 18px;
  }
`;
export const WelcomeModalContentSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.p4};

  @media (min-width: 768px) {
    width: 50%;
  }
`;
export const InfoBox = styled(Infobox)`
  padding: ${({ theme }) => theme.spacing.p5};
  border-radius: ${({ theme }) => theme.spacing.p4};
`;
export const TextAreaSmall = styled(TextInput)`
  textarea {
    // Temp workaround for limited height options
    max-height: 80px;
  }
`;
