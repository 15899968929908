import styled, { css } from 'styled-components';

export const ActionBar = styled.div`
  ${({ theme: { spacing } }) => css`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    direction: rtl;
    gap: ${spacing.sm};

    > * {
      direction: ltr;
    }
  `}
`;
