import { useReactiveVar } from '@apollo/client';
import { Button, Icon } from '@virtidev/toolbox';
import { differenceInDays } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import {
  deepLinkClosedVar,
  setDeepLinkClosedVar,
} from '../../state/DeepLink.state';
import {
  CloseButton,
  Container,
  Content,
  IconContainer,
  Subtext,
  Title,
} from './DeepLinkBanner.styled';

// days to not disturb the user after closing
const CLOSED_GRACE = 10;

export const DeepLinkBanner = ({ deepLink }) => {
  const closedDate = useReactiveVar(deepLinkClosedVar);
  const [hide, setHide] = useState(false);

  const goToLink = useCallback(() => {
    window.onbeforeunload = null;
    window.location = deepLink;
  }, [deepLink]);

  const dismissBanner = useCallback(() => {
    setDeepLinkClosedVar(new Date());
  }, []);

  const hideBanner = useCallback(() => {
    setHide(true);
  }, []);

  const closed = useMemo(() => {
    if (!closedDate) {
      return false;
    }
    const diff = differenceInDays(new Date(), closedDate);

    return diff < CLOSED_GRACE;
  }, [closedDate]);

  if (closed || hide) {
    return null;
  }
  return (
    <Container>
      <CloseButton onClick={hideBanner}>
        <Icon icon="cross" />
      </CloseButton>
      <IconContainer>
        <Icon icon="virti" size="30px" />
      </IconContainer>
      <Content>
        <Title>Use the Virti App</Title>
        <Subtext>Giving you the best experience for learning</Subtext>
      </Content>
      <Button color="primary" onClick={goToLink}>
        View
      </Button>
      <CloseButton onClick={dismissBanner}>Dismiss</CloseButton>
    </Container>
  );
};

export default DeepLinkBanner;
