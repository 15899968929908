import { forwardRef } from 'react';
import { RangeContainer, RangeInput, RangeValue } from './RangeSlider.styled';

export const RangeSlider = forwardRef(
  ({ className, percent, ...props }, ref) => {
    const { value, min, max } = props;
    const offset = ((value - min) / (max - min)) * 100;

    return (
      <RangeContainer className={className}>
        <RangeInput ref={ref} {...props} />
        <RangeValue
          style={{
            left: `min(max(${offset * 0.95 + 2.5}%, 12px), 100% - 12px)`,
          }}
        >
          {value}
          {percent ? '%' : ''}
        </RangeValue>
      </RangeContainer>
    );
  }
);

RangeSlider.defaultProps = {
  min: 0,
  max: 100,
};

export default RangeSlider;
