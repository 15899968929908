import { gql } from '@apollo/client';

export const READ_MEMBER_ONBOARD_PROGRESS = gql`
  query readOneMemberOnboardProgress($ID: ID!) {
    readOneMember(filter: { ID: { eq: $ID } }) {
      ID
      OnboardProgress
      CompletedSimulations {
        pageInfo {
          totalCount
        }
      }
    }
  }
`;

export const UPDATE_MEMBER_WELCOME_PROGRESS = gql`
  mutation updateMember($Input: UpdateMemberInput!) {
    updateMember(input: $Input) {
      ID
      OnboardProgress
    }
  }
`;
