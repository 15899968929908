import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import StyledLabel from '../styled-components/StyledLabel';
import ScrollbarSnippet from '../css-snippets/ScrollbarSnippet';
import HelpTooltip from '../components/HelpTooltip';
import _ from 'lodash';
import { Tooltip } from '@virtidev/toolbox';

const StyledSectionTitle = styled(StyledLabel)`
  margin-bottom: 1.5rem;
`;

const StyledSectionTitleCheckboxes = styled(StyledLabel)`
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  font-weight: 500;
`;

const StyledCheckboxWrapper = styled.div`
  position: relative;
  margin-bottom: 2rem;
`;
const StyledCheckboxSubwrapperLabel = styled.label`
  display: block;
  position: relative;
  padding-left: 2.5rem;
  margin-top: ${(props) =>
    props.marginTopLabel ? props.marginTopLabel : '1rem'};
  line-height: 1.6rem;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  user-select: none;
  ${(props) =>
    props.centerAll &&
    css`
      padding-left: 0;
      margin-bottom: 0;
    `}
  ${(props) =>
    props.disabled &&
    css`
      color: var(--disabled-checkbox-text-color);
    `}
  &:hover input ~ .checkmark {
    background-color: var(--checkbox-bg-color-hover);
    ${(props) =>
      props.disabled &&
      css`
        background-color: var(--disabled-checkbox-color);
      `}
  }
`;
const StyledSeparatedChecked = styled.div`
  border-bottom: var(--primary-border);
  padding-bottom: 1rem;
  margin-right: 1rem;
`;

const StyledLabelText = styled.span`
  display: inline-block;
  font-size: 1rem;
  vertical-align: middle;
  position: relative;
  top: -1px;
`;

const StyledCheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  height: 0;
  width: 0;
  &:checked ~ .checkmark {
    /* background-color: #2196F3; */
  }
  &:checked ~ .checkmark:after {
    display: block;
  }
`;

const StyledCheckMark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 1.6rem;
  width: 1.6rem;
  border-radius: 6px;
  background-color: ${(props) =>
    props.greyFormBG
      ? 'var(--checkbox-bg-color-grey-form)'
      : 'var(--checkbox-bg-color)'};
  ${(props) =>
    props.checked &&
    css`
      background-color: var(--checked-checkbox-bg-color);
    `}
  ${(props) =>
    props.disabled &&
    css`
      background-color: var(--disabled-checkbox-color);
    `}
  ${(props) =>
    props.centerAll &&
    css`
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin-left: auto;
      margin-right: auto;
      margin-top: auto;
      margin-bottom: auto;
    `}
  &:after {
    content: '';
    position: absolute;
    display: none;
  }
  &:after {
    left: 8px;
    top: 2px;
    width: 11px;
    height: 17px;
    border: solid var(--checkbox-tick-color);
    border-width: 0 4px 4px 0;
    transform: rotate(45deg);
    border-radius: 3px;
  }
`;
const StyledRadioCheck = styled(StyledCheckMark)`
  border-radius: 60px;
  &:after {
    left: 50%;
    right: 50%;
    top: 50%;
    bottom: 50%;
    border: none;
    background: #fff;
    border-radius: 60px;
    transform: translateX(-50%) translateY(-50%);
    width: 10px;
    height: 10px;
  }
`;

const StyledCheckboxContent = styled.div`
  ${(props) =>
    props.scrollableHeight &&
    css`
      overflow-y: auto;
      max-height: ${props.scrollableHeight};
      ${ScrollbarSnippet}
    `}
`;

const preventSubmissionOnKeypress = (e) => {
  if (e.which === 13 /* Enter */) {
    e.preventDefault();
  }
};

const StyledHelpTooltip = styled(Tooltip)`
  float: right;
  position: relative;
`;

const Checkbox = (props) => {
  const tooltip = () => (
    <StyledHelpTooltip icon="help" iconSize="1.5rem">
      {props.tooltip}
    </StyledHelpTooltip>
  );
  return (
    <StyledCheckboxSubwrapperLabel
      centerAll={props.centerAll}
      htmlFor={props.htmlID}
      disabled={props.disabled}
      className={props.className}
      greyFormBG={props.greyFormBG}
      marginTopLabel={props.marginTopLabel}
    >
      <StyledLabelText>{props.label}</StyledLabelText>
      {props.tooltip && props.tooltipInLabel && tooltip()}
      <StyledCheckboxInput
        data-obj-id={props.ID}
        onChange={(e) =>
          !props.disabled && props.onChange ? props.onChange(e) : null
        }
        onKeyPress={preventSubmissionOnKeypress}
        name={props.name}
        id={props.htmlID}
        type={props.type ? props.type : 'checkbox'}
        checked={props.checked}
        value={props.value ? props.value : ''}
        disabled={props.disabled}
        data-testid={props.dataTestId}
      />
      {props.type === 'radio' && (
        <StyledRadioCheck
          checked={props.checked}
          disabled={props.disabled}
          centerAll={props.centerAll}
          greyFormBG={props.greyFormBG}
          className="checkmark"
        />
      )}
      {props.type !== 'radio' && (
        <StyledCheckMark
          checked={props.checked}
          disabled={props.disabled}
          centerAll={props.centerAll}
          greyFormBG={props.greyFormBG}
          className="checkmark"
        />
      )}
      {props.tooltip && !props.tooltipInLabel && tooltip(false)}
    </StyledCheckboxSubwrapperLabel>
  );
};

Checkbox.defaultProps = {
  disabled: false,
  greyFormBG: false,
  checked: false,
  tooltipInLabel: false,
};

Checkbox.propTypes = {
  htmlID: PropTypes.string.isRequired,
  label: PropTypes.string,
  ID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  centerAll: PropTypes.bool,
  disabled: PropTypes.bool,
  greyFormBG: PropTypes.bool,
  tooltip: PropTypes.string,
  tooltipInLabel: PropTypes.bool,
};

class StyledCheckboxes extends Component {
  constructor(props) {
    super(props);
    // console.log(props.data)
    this.state = this._getState(props);
  }

  _getState = (props) => {
    return {
      checked: {
        ...props.data.reduce((carry, obj) => {
          return { ...carry, [obj.ID]: obj.checked };
        }, {}),
      },
    };
  };

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.data, this.props.data)) {
      this.setState(this._getState(this.props));
    }
  }

  _onCheckChange = (event) => {
    const objID = event.target.getAttribute('data-obj-id');
    this.setState(
      {
        checked: { ...this.state.checked, [objID]: !this.state.checked[objID] },
      },
      () => {
        if (!this.props.onCheckboxStateChange) return;
        this.props.onCheckboxStateChange({
          newState: this.state,
          itemID: objID,
          newValue: this.state.checked[objID],
        });
      }
    );
  };

  render() {
    const checkedVals = this.props.data.filter(
      (obj) => this.state.checked[obj.ID]
    );
    return (
      <StyledCheckboxWrapper data-testid={this.props.dataTestId}>
        {this.props.title && typeof this.props.title === 'string' && (
          <StyledSectionTitleCheckboxes
            loading={this.props.loading}
            tooltip={this.props.tooltip}
          >
            {this.props.title}
          </StyledSectionTitleCheckboxes>
        )}
        {this.props.title &&
          typeof this.props.title !== 'string' &&
          this.props.title}
        <StyledCheckboxContent scrollableHeight={this.props.scrollableHeight}>
          {this.props.separatedOptions.length > 0 && (
            <StyledSeparatedChecked>
              {this.props.separatedOptions.map((separatedOption) => (
                <Checkbox
                  key={separatedOption.label}
                  label={separatedOption.label}
                  ID={separatedOption.ID}
                  name={separatedOption.name}
                  htmlID={separatedOption.htmlID}
                  onChange={() => {
                    const IDs = Object.keys(this.state.checked);
                    const checkedIDs = IDs.filter(
                      (ID) => this.state.checked[ID]
                    );
                    separatedOption.onChange(checkedIDs);
                  }}
                  checked={separatedOption.checked}
                  disabled={separatedOption.disabled}
                  greyFormBG={this.props.greyFormBG}
                />
              ))}
            </StyledSeparatedChecked>
          )}
          {/** if we've marked that we want to separate the checked values then we put them here at the top */}
          {this.props.separateChecked && checkedVals.length > 0 && (
            <StyledSeparatedChecked>
              {checkedVals.map((obj) => (
                <React.Fragment key={obj.ID}>
                  <Checkbox
                    label={obj.label}
                    ID={obj.ID}
                    name={this.props.name}
                    htmlID={obj.htmlID}
                    onChange={this._onCheckChange}
                    checked={this.state.checked[obj.ID]}
                    disabled={this.props.disabled}
                    greyFormBG={this.props.greyFormBG}
                  />
                </React.Fragment>
              ))}
            </StyledSeparatedChecked>
          )}
          {/** if we marked that we want to separate the checked values then we take them out of this bit */}
          {this.props.data
            .filter((obj) =>
              this.props.separateChecked ? !this.state.checked[obj.ID] : true
            )
            .map((obj) => (
              <React.Fragment key={obj.ID}>
                <Checkbox
                  label={obj.label}
                  ID={obj.ID}
                  name={this.props.name}
                  htmlID={obj.htmlID}
                  onChange={this._onCheckChange}
                  checked={this.state.checked[obj.ID]}
                  disabled={this.props.disabled}
                  greyFormBG={this.props.greyFormBG}
                />
              </React.Fragment>
            ))}
        </StyledCheckboxContent>
      </StyledCheckboxWrapper>
    );
  }
}

StyledCheckboxes.defaultProps = {
  disabled: false,
  loading: false,
  greyFormBG: false,
  scrollableHeight: null,
  separateChecked: false,
  separatedOptions: [],
};

StyledCheckboxes.propTypes = {
  onCheckboxStateChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  title: PropTypes.node,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      checked: PropTypes.bool.isRequired,
      label: PropTypes.string.isRequired,
      htmlID: PropTypes.string.isRequired,
      ID: PropTypes.string.isRequired,
    })
  ),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  greyFormBG: PropTypes.bool,
  scrollableHeight: PropTypes.string,
  separateChecked: PropTypes.bool,
  separatedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      ID: PropTypes.string,
      name: PropTypes.string,
      htmlID: PropTypes.string,
      onChange: PropTypes.func,
      checked: PropTypes.bool,
      disabled: PropTypes.bool,
    })
  ),
  renderTitleAs: PropTypes.string,
};

const StyledRadio = (props) => {
  return (
    <StyledCheckboxWrapper
      data-testid={props.dataTestId}
      className={props.className}
    >
      {props.title && (
        <StyledSectionTitle loading={props.loading ? 1 : 0}>
          {props.title}
        </StyledSectionTitle>
      )}
      <StyledCheckboxContent scrollableHeight={props.scrollableHeight}>
        {props.data.map((obj) => (
          <React.Fragment key={obj.ID}>
            <Checkbox
              label={obj.label}
              ID={obj.ID}
              type="radio"
              value={obj.value}
              onChange={(e) => props.onChange(e.target.value)}
              name={props.name}
              htmlID={obj.htmlID}
              checked={props.value === obj.value}
              disabled={props.disabled}
            />
          </React.Fragment>
        ))}
      </StyledCheckboxContent>
    </StyledCheckboxWrapper>
  );
};

StyledRadio.propTypes = {
  disabled: false,
  loading: false,
  scrollableHeight: null,
};

StyledRadio.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      htmlID: PropTypes.string.isRequired,
      ID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.string.isRequired,
    })
  ),
  scrollableHeight: PropTypes.string,
};

export { Checkbox, StyledRadio };
export default StyledCheckboxes;
