import { TextInput } from '@virtidev/toolbox';
import { useFormContext } from 'react-hook-form';
import FieldContainer from '../../form/FieldContainer';

export const DeleteAccountForm = () => {
  const { register } = useFormContext();

  return (
    <>
      <p>We're sad to see you go, and thank you for signing up to Virti!</p>
      <p>
        If you could provide a reason for why you'd like to delete your account,
        we'd greatly appreciate a response.
      </p>
      <FieldContainer>
        <TextInput {...register('reason')} multiline />
      </FieldContainer>
    </>
  );
};

export default DeleteAccountForm;
