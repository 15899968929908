import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@virtidev/toolbox';
import * as Styled from './CardMenuItem.styled';

export const CardMenuItem = ({
  onDelete,
  ID,
  cardType,
  verificationTextRequired,
  onClick,
  iconName,
  iconType,
  children,
  ...props
}) => {
  return (
    <Styled.MenuItemButton {...props} onClick={onClick}>
      <Icon
        icon={iconName ?? 'bin'}
        type={iconType ?? 'outline'}
        size="16px"
        color="var(--neutral-color)"
      />
      <Styled.MenuItemText>{children}</Styled.MenuItemText>
    </Styled.MenuItemButton>
  );
};

CardMenuItem.propTypes = {};

export default CardMenuItem;
