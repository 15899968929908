export const IsChrome = function () {
  // please note,
  // that IE11 now returns undefined again for window.chrome
  // and new Opera 30 outputs true for window.chrome
  // but needs to check if window.opr is not undefined
  // and new IE Edge outputs to true now for window.chrome
  // and if not iOS Chrome check
  // so use the below updated condition
  var isChromium = window.chrome;
  var winNav = window.navigator;
  var vendorName = winNav.vendor;
  var isOpera = typeof window.opr !== 'undefined';
  var isIEedge = winNav.userAgent.indexOf('Edge') > -1;
  var isIOSChrome = winNav.userAgent.match('CriOS');

  if (isIOSChrome) {
    // is Google Chrome on IOS
    return true;
  } else if (
    isChromium !== null &&
    typeof isChromium !== 'undefined' &&
    vendorName === 'Google Inc.' &&
    isOpera === false &&
    isIEedge === false
  ) {
    return true;
  } else {
    return false;
  }
};

export const IsFirefox = function () {
  return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
};

export const IsChromiumEdge = function () {
  return navigator.userAgent.toLowerCase().indexOf('edg/') > -1;
};

export const WeSupportUnity = function () {
  return IsChrome() || IsFirefox() || IsChromiumEdge();
};

export const IsSafari = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent
);

/**
 * Determine if this is a mobile operating system.
 *
 * @returns {String | null}
 */
export const IsMobile = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (!userAgent) {
    return null;
  }

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (
    (/iPad|iPhone|iPod/.test(userAgent) &&
      !(/** @type {any} */ (window).MSStream)) ||
    (userAgent.includes('Mac') && 'ontouchend' in document)
  ) {
    return 'iOS';
  }

  return null;
};

export const IsLinux = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (!userAgent) {
    return null;
  }

  return /linux/i.test(userAgent);
};