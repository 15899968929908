import styled from 'styled-components';

export const Icon = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    fill: ${(props) => (props.color ? props.color : '#C5CFD7')};
    path {
      fill: ${(props) => (props.color ? props.color : '#C5CFD7')};
    }
  }
`;
export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '100%')};
  border: 0;
  background-color: transparent;
`;
