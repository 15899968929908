import { useMutation } from '@apollo/client';
import { Button } from '@virtidev/toolbox';
import React, { useCallback, useMemo } from 'react';
import useFlashMessage from '../../FlashMessage';
import { BULK_UPDATE_MEMBERS } from './Actions.query';

const ChangeMemberType = ({
  selectedIDs,
  setSelectedIDs,
  setListModified,
  newUserType,
  // why do we need this button prop?
  button,
}) => {
  const parsedLabel = useMemo(() => {
    if (newUserType === 'contentcreator') {
      return 'Content Creator';
    } else return newUserType[0].toUpperCase() + newUserType.substring(1);
  }, [newUserType]);

  const { addFlashMessage } = useFlashMessage();

  const [bulkUpdateUsers] = useMutation(BULK_UPDATE_MEMBERS, {
    onCompleted: () => {
      setSelectedIDs([]);
      setListModified(true);
      addFlashMessage(
        `User${selectedIDs.length > 1 ? 's' : ''} changed successfully.`
      );
    },
  });

  const handleClick = useCallback(() => {
    bulkUpdateUsers({
      variables: {
        MemberIDs: selectedIDs,
        UserType: newUserType,
      },
      update: (cache, result) => {
        cache.evict({ fieldName: 'readMembers' });
      },
    });
  }, [bulkUpdateUsers, selectedIDs, newUserType]);

  const Wrapper = useMemo(() => button || Button, [button]);

  return (
    <Wrapper title="Change user type" onClick={handleClick}>
      Make {parsedLabel}
    </Wrapper>
  );
};

export default ChangeMemberType;
