import { useCallback, useMemo } from 'react';
import { GetFormattedTime } from '@base/utility/TimeFormatting';
import { Image } from '@virtidev/toolbox';
import {
  AssetAction,
  AssetCard,
  AssetImageContainer,
  AssetSelectBox,
  AssetSelectIcon,
  AssetSelectIconContainer,
  AssetTimestamp,
  AssetTitle,
  AssetTypeContainer,
  AssetTypeIcon,
} from '@core/components/AssetSelectModal/AssetSelectModal.styled';

export const VideoCard = ({
  video,
  onSelect,
  disabled,
  selected,
  multiSelect,
}) => {
  const { ID, Content360, PosterURL, Title, Length } = video;

  const handleSelect = useCallback(() => {
    onSelect(ID);
  }, [onSelect, ID]);

  const actionLabel = useMemo(() => {
    if (multiSelect) {
      return selected ? 'Remove' : 'Add';
    }
    return selected ? null : 'Select';
  }, [multiSelect, selected]);

  const formattedLength = useMemo(
    () => GetFormattedTime(Length * 1000),
    [Length]
  );

  return (
    <AssetCard
      onClick={handleSelect}
      $selected={selected}
      $disabled={disabled}
      $multiSelect={multiSelect}
    >
      {Content360 && (
        <AssetTypeContainer>
          <AssetTypeIcon />
        </AssetTypeContainer>
      )}
      {actionLabel && <AssetAction>{actionLabel}</AssetAction>}
      <AssetImageContainer>
        <AssetSelectBox />
        <AssetSelectIconContainer>
          <AssetSelectIcon />
        </AssetSelectIconContainer>
        <Image src={PosterURL} cover alt={`Thumbnail for ${Title}`} />
      </AssetImageContainer>
      <div>
        <AssetTitle>{Title}</AssetTitle>
        <AssetTimestamp>{formattedLength}</AssetTimestamp>
      </div>
    </AssetCard>
  );
};

export default VideoCard;
