import { Suspense, useEffect, useMemo, useState, lazy } from 'react';
import { useReactiveVar } from '@apollo/client';
import useUser from '../../helpers/useUser';
import { EggContainer } from './EasterEgg.styled';
import { CanSeeCXDashboard } from '../../../../../utility/Permissions';
import { sidebarContractedVar } from '../../../../../ApolloReactiveVars';
import { useLocation } from 'react-router';
import { inIframe } from '../../../../../utility/WindowUtility';
import useFeature from '../LaunchDarkly';
import { getEaster } from './helpers/getEaster';
import { addDays } from 'date-fns';

const Halloween = lazy(() => import('./components/Halloween'));
const Christmas = lazy(() => import('./components/Christmas'));
const Easter = lazy(() => import('./components/Easter/Easter'));
const AprilFools = lazy(() => import('./components/AprilFools'));
const LunarNewYear = lazy(() =>
  import('./components/LunarNewYear/LunarNewYear')
);

export const EasterEgg = () => {
  const sidebarContracted = useReactiveVar(sidebarContractedVar);
  const [easterEggFeature] = useFeature(['easter-egg-logo']);
  const [key, setKey] = useState('');
  const { ID } = useUser();
  const { pathname, search } = useLocation();

  const iframe = useMemo(() => inIframe(), []);
  const today = new Date();

  const halloween =
    new Date(today.getFullYear(), 9, 29, 0, 0, 0) <= today &&
    new Date(today.getFullYear(), 10, 1, 8, 59, 59) >= today;

  const christmas =
    new Date(today.getFullYear(), 11, 16, 0, 0, 0) <= today &&
    new Date(today.getFullYear(), 11, 26, 11, 59, 59) >= today;

  const [eMonth, eDay] = getEaster(today.getFullYear());
  const easter =
    addDays(new Date(today.getFullYear(), eMonth - 1, eDay, 0, 0, 0), -2) <=
      today &&
    addDays(new Date(today.getFullYear(), eMonth - 1, eDay, 11, 59, 59), 1) >=
      today;

  const aprilfools =
    !easter &&
    new Date(today.getFullYear(), 3, 1, 0, 0, 0) <= today &&
    new Date(today.getFullYear(), 3, 2, 11, 59, 59) >= today;

  const lunar =
    new Date(2024, 1, 10, 0, 0, 0) <= today &&
    new Date(2024, 1, 21, 0, 0, 0) >= today;

  // refresh on user log in/out
  useEffect(() => {
    setKey(`${ID}+${pathname}+${search}`);
  }, [ID, pathname, search]);

  if (iframe || (!CanSeeCXDashboard() && !easterEggFeature)) {
    return null;
  }

  return (
    <EggContainer key={key} sidebarContracted={sidebarContracted}>
      <Suspense fallback="">
        {halloween && <Halloween />}
        {christmas && <Christmas />}
        {easter && <Easter />}
        {aprilfools && <AprilFools />}
        {lunar && <LunarNewYear />}
      </Suspense>
    </EggContainer>
  );
};

export default EasterEgg;
