import {
  Loading,
  MockIcon,
  MockText,
  Card as VirtiCard,
} from '@virtidev/toolbox';
import classNames from 'classnames';
import styled, { css } from 'styled-components';

export const Content = styled.div`
  padding: 0 0 1.5rem;
  display: ${
    /** @param {{ $hidden?: boolean }} p */
    ({ $hidden }) => ($hidden ? 'none' : 'flex')
  };
  flex-direction: column;
  gap: 2rem;
`;

export const Block = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 2rem;
  flex-wrap: wrap;

  > * {
    flex: 1;
  }
`;

export const Card = styled(VirtiCard)`
  .body {
    padding: 1rem 1.8rem;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0 1.3rem 0;
`;

export const HeaderText = styled.h2`
  color: var(--primary-font-color);
  font-size: 1.3rem;
  font-weight: normal;
`;

export const CardData = styled.div`
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding: 1rem 0;
  gap: 2rem;
`;

export const CardDataValue = styled.div.attrs(({ $loading }) => {
  if ($loading) {
    return {
      children: <Loading />,
    };
  }
  return {};
})`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 200;
  padding: 0.5rem;
`;

export const CardDataLabel = styled.div`
  color: #ccc;
`;

export const LoadingHolder = styled.div`
  position: relative;
  z-index: 0;
  display: inline-flex;
  width: 100%;
  max-width: 18rem;

  justify-content: center;
  align-items: center;

  ${MockIcon} {
    margin: 0 auto;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;
  }

  ${MockText} {
    margin-top: 0;
  }
`;

export const LoadingStat = styled(LoadingHolder)`
  width: 80%;
`;

const statCss = css`
  ${
    /** @param {{ $wide?: boolean }} p */
    ({ $wide }) =>
      $wide
        ? css`
            width: 9rem;

            ${LoadingStat} {
              max-width: 8rem;
            }
          `
        : css`
            width: 6rem;

            ${LoadingStat} {
              max-width: 3rem;
            }
          `
  }
`;

export const EmptyMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 1rem;
  margin: 0 auto;
`;

export const StatCell = styled.td.attrs((attrs) => ({
  className: classNames(attrs.className, 'middle'),
}))`
  ${statCss}
`;

export const StatHeader = styled.th.attrs((attrs) => ({
  className: classNames(attrs.className, 'middle'),
}))`
  ${statCss}
`;
