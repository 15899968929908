import { LoadingFlash, MockIcon, MockText } from '@virtidev/toolbox';
import styled from 'styled-components';
import { S_td, S_tr } from '../../../../../../../styled-components/StyledTable';
import { Actions, LoadingHolder, LoadingStat } from '../../Analytics.styled';

const PlaceholderIcon = styled.div`
  margin: 0 auto;
  width: 1.5rem;
  height: 1.5rem;
`;

export const VirtualHumanSubmissionLoadingRow = ({
  showPosition,
  nameColumn,
  medicalScores,
}) => (
  <S_tr>
    {showPosition && (
      <S_td>
        <LoadingStat>
          <MockIcon />
          <LoadingFlash />
        </LoadingStat>
      </S_td>
    )}
    {nameColumn === 'user' && (
      <S_td>
        <LoadingHolder>
          <MockText />
          <LoadingFlash />
        </LoadingHolder>
      </S_td>
    )}
    {nameColumn === 'virtual-human' && (
      <S_td>
        <LoadingHolder>
          <MockText />
          <LoadingFlash />
        </LoadingHolder>
      </S_td>
    )}
    <S_td>
      <LoadingStat>
        <MockText />
        <LoadingFlash />
      </LoadingStat>
    </S_td>
    <S_td>
      <LoadingStat>
        <MockText />
        <LoadingFlash />
      </LoadingStat>
    </S_td>
    <S_td>
      <LoadingStat>
        <MockText />
        <LoadingFlash />
      </LoadingStat>
    </S_td>
    {medicalScores && (
      <S_td>
        <LoadingStat>
          <MockIcon />
          <LoadingFlash />
        </LoadingStat>
      </S_td>
    )}
    <S_td>
      <Actions>
        <PlaceholderIcon />
        <LoadingHolder>
          <MockIcon />
          <LoadingFlash />
        </LoadingHolder>
      </Actions>
    </S_td>
  </S_tr>
);

export default VirtualHumanSubmissionLoadingRow;
