import { gql } from '@apollo/client';

export const EDIT_VIDEO = gql`
  mutation updateVideo($Input: UpdateVideoInput!) {
    updateVideo(input: $Input) {
      ID
      Subtitles {
        Language
      }
    }
  }
`;

export const ADD_SUBTITLES = gql`
  mutation createSubtitle($Input: CreateSubtitleInput!) {
    createSubtitle(input: $Input) {
      ID
      URL
      Type
      Language
      Title
      VideoMedia {
        ID
        Subtitles {
          pageInfo {
            totalCount
          }
          nodes {
            ID
          }
        }
      }
    }
  }
`;

export const READ_VIDEO_SUBTITLES = gql`
  query readVideoSubtitles($ID: ID!) {
    readOneVideoMedia(filter: { ID: { eq: $ID } }) {
      ID
      Subtitles {
        pageInfo {
          totalCount
        }
        nodes {
          ID
          URL
          Type
          Language
          Title
        }
      }
    }
  }
`;

export const VIDEO_FRAGMENT = gql`
  fragment video on VideoMedia {
    ID
    Subtitles {
      pageInfo {
        totalCount
      }
      nodes {
        ID
      }
    }
  }
`;
