import styled, { css } from 'styled-components';
import ReactDatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { inputCss, inputContainerCss } from '@virtidev/toolbox';

export const DatepickerWrapper = styled.div`
  ${({ theme: { color } }) => css`
    ${inputContainerCss}

    .react-datepicker {
      border-radius: 24px;
      font-size: 0.9rem;

      &__close-icon {
        &:after {
          color: var(--neutral-color);
          background: none;
          content: 'X ';
        }
      }

      &__current-month {
        font-size: 1rem;
        margin: 2px 0 10px 0;
      }

      &__header {
        border-radius: 24px 24px 0 0;
      }

      &__navigation {
        &:focus {
          box-shadow: var(--input-focus-box-shadow, 0 0 5px 1px #51cbee);
        }

        &:hover {
          box-shadow: var(--input-hover-box-shadow, 0 0 2px 1px #71dbee);
        }
        &-icon::before {
          height: 13px;
          width: 13px;
        }

        &--previous {
          left: 10px;
        }

        &--next {
          right: 10px;
        }
      }

      &__day-name,
      &__day {
        border-radius: 8px;
        width: 2.5rem;
        line-height: 2rem;
      }

      &__day {
        &--selected {
          background-color: var(--primary-color);
        }

        &--keyboard-selected {
          background-color: var(--secondary-color);
        }

        &--today {
          box-shadow: 0 0 2px 2px var(--secondary-color);
        }
      }
    }
  `}
`;

export const StyledDatepicker = styled(ReactDatePicker)`
  ${inputCss}
`;
