import { gql } from '@apollo/client';

export const READ_ORGANISATION_VH_CAPS = gql`
  query readOrganisationVHCaps($limit: Int, $offset: Int) {
    readOrganisations(
      limit: $limit
      offset: $offset
      sort: { VirtualHumanFreeformLimit: DESC }
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        totalCount
      }
      edges {
        node {
          ID
          Name
          VirtualHumanFreeformLimit
          VirtualHumanAPI
          VirtualHumanBranchingLimit
          BranchingVirtualHumans: VirtualHumans(
            filter: { Type: { eq: "branching" }, Disabled: { eq: false } }
          ) {
            pageInfo {
              totalCount
            }
          }
          MedicalVirtualHumans: VirtualHumans(
            filter: { Type: { eq: "medical" }, Disabled: { eq: false } }
          ) {
            pageInfo {
              totalCount
            }
          }
          DefaultVirtualHumans: VirtualHumans(
            filter: { Type: { eq: "default" }, Disabled: { eq: false } }
          ) {
            pageInfo {
              totalCount
            }
          }
        }
      }
    }
  }
`;
