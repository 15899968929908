import { Button } from '@virtidev/toolbox';
import styled from 'styled-components';

export const StyledTrialBanner = styled.div`
  width: 100%;
  padding: 2rem;
  box-shadow: var(--card-box-shadow);
  border-radius: 20px;
  background: var(--primary-color);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
`;

export const StyledStrong = styled.strong`
  font-size: 2rem;
  font-weight: 400;
`;

export const ContactSalesButton = styled(Button)`
  border-radius: 24px;
  background: var(--secondary-color);
`;
