import React from 'react';
import _ from 'lodash';
import StyledPage, {
  StyledPageContentInner,
  StyledTopArea,
} from '../components/StyledPage';
import StyledPageHeader from '../styled-components/StyledPageHeader';
import RedirectWithPrevState from '../components/RedirectWithPrevState';
import useUser from '../apps/core/src/helpers/useUser';
import MyBadges from '../components/MyBadges';
import { withLoggedInAccess } from '../apps/core/src/helpers/permissions';

const MyBadgesPage = () => {
  const { ID } = useUser();

  if (!ID) {
    return <RedirectWithPrevState to="/login" />;
  }
  return (
    <StyledPage pageKey="profile">
      <StyledPageContentInner>
        <StyledTopArea>
          <StyledPageHeader>Badges</StyledPageHeader>
        </StyledTopArea>
        <MyBadges />
      </StyledPageContentInner>
    </StyledPage>
  );
};

MyBadgesPage.propTypes = {};

export default withLoggedInAccess(MyBadgesPage);
