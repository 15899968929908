import { produce } from 'immer';
import _ from 'lodash';

export const cleanupAccessData = (data) => {
  return produce(data, (temp) => {
    const newData = _.pick(temp, [
      'StartDate',
      'ID',
      'DueDate',
      'Unlocked',
      'NotifyUsers',
    ]);

    newData.GroupIDs = temp.OrganisationGroups.nodes;

    return newData;
  });
};

export default cleanupAccessData;
