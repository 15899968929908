import { Icon, IconProps } from '@virtidev/toolbox';
import styled from 'styled-components';

export const StyledDashboardBanner = styled.div`
  width: 100%;
  padding: 0.75rem 2rem;
  box-shadow: var(--card-box-shadow);
  border-radius: 20px;
  background: white;
  display: flex;
  gap: 1rem;
  align-items: center;
  font-size: 1rem;
`;

export const BannerIcon = styled(Icon).attrs(
  /**
   * @return {Partial<IconProps>}
   */
  () => ({
    size: '1.3rem',
  })
)`
  color: ${({ theme }) => theme.color.primary};
  margin-left: -0.1rem;
`;
