import { forwardRef } from 'react';
import Page from '@core/templates/components/ContentPage/ContentPage';

export const withContentTemplate =
  (pageProps = {}) =>
  (Component) => {
    const PageTemplate = forwardRef((props, ref) => (
      <Page ref={ref} {...pageProps}>
        <Component {...props} />
      </Page>
    ));

    return PageTemplate;
  };

export default withContentTemplate;
