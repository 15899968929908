import _ from 'lodash';
import { Titlebar } from '../../../components/Page';
import VRPairing from '../../../components/VRPairing/VRPairing';
import { withLoggedInAccess } from '../../../helpers/permissions';
import { withContentTemplate } from '../../../templates';

const VRLoginPage = () => {
  return (
    <>
      <Titlebar>Pair VR Device</Titlebar>
      <VRPairing />
    </>
  );
};

export default _.flowRight(
  withLoggedInAccess,
  withContentTemplate({ pageKey: 'account' })
)(VRLoginPage);
