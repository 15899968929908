import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Modal from '../Modal';
import Button from '../buttons/Button';
import { withRouter } from 'react-router';
import StyledTextInput from '../../styled-components/StyledTextInput';
import * as Styled from './ConfirmationModal.styled';

const ConfirmationModal = (props) => {
  const [verificationInputText, setVerificationInputText] = useState('');
  return (
    <Modal
      visible={true}
      hideModal={props.onCancel}
      title={
        props.customTitle && props.customTitle !== ''
          ? props.customTitle
          : 'Please confirm'
      }
    >
      {props.children && <Styled.Message>{props.children}</Styled.Message>}
      {!props.children && (
        <Styled.Message>
          <div>
            Are you sure you want to navigate away from this page? All your
            unsaved changes will be lost.
          </div>
          <div>
            Press OK to continue, or Cancel to stay on the current page.
          </div>
        </Styled.Message>
      )}
      {props.verificationTextRequired && (
        <Styled.ConfirmationTextWrapper>
          <Styled.ConfirmationTextMessage>
            Please type <strong>{props.verificationTextRequired}</strong> to
            confirm deletion:
          </Styled.ConfirmationTextMessage>
          <StyledTextInput
            value={verificationInputText}
            name="group-name"
            id="group-name"
            onChange={(e) => {
              setVerificationInputText(e.target.value);
            }}
          />
        </Styled.ConfirmationTextWrapper>
      )}
      <Styled.ButtonWrapper>
        <Button
          dataTestId="modal-cancel-btn"
          disabled={props.loading}
          type="cancel"
          onClick={props.onCancel}
        >
          {props.customCancel}
        </Button>
        <Button
          dataTestId="modal-confirm-btn"
          id="modal-confirm-btn"
          loading={props.loading}
          onClick={props.onConfirm}
          disabled={
            props.verificationTextRequired
              ? verificationInputText !== props.verificationTextRequired
              : false
          }
        >
          {props.customOK}
        </Button>
      </Styled.ButtonWrapper>
    </Modal>
  );
};

// const ConfirmationModal = props => (
//   <StyledConfirmationModal title={props.customTitle} visible={true}>
//     <div>
//       <Button onClick={props.onCancel}>{props.customCancel}</Button>
//       <Button onClick={props.onConfirm}>{props.customOK}</Button>
//     </div>
//   </StyledConfirmationModal>
// )

ConfirmationModal.defaultProps = {
  customCancel: 'Cancel',
  customOK: 'OK',
  loading: false,
  buttonType: null,
};

ConfirmationModal.propTypes = {
  customTitle: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  buttonType: PropTypes.string,
  children: PropTypes.node,
  verificationTextRequired: PropTypes.string,
};

export default ConfirmationModal;
