import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';
import {
  DELETE_SIMULATIONS,
  READ_SIMULATION_UNITS,
} from '../../queries/SimulationQueries';
import WithFlashMessaging from '../../HOCs/WithFlashMessaging';
import WithConfirmationBox from '../../HOCs/WithConfirmationBox';
import { EllipsisMenuButton } from '../EllipsisMenu';
import Icon from '../icons/Icon';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';

const DeleteSimulationBtn = (props) => {
  const [deleteSimulations, { loading }] = useMutation(DELETE_SIMULATIONS, {
    variables: { IDs: [props.simulationID] },
    onError: (err) => {
      console.error(err);
      props.addFlashMessage(
        'There was an error when attempting to delete the simulation',
        'error'
      );
    },
    onCompleted: () => history.push('/simulations'),
    update: (cache) => {
      cache.evict({ fieldName: 'readSimulations' });
      cache.evict({ fieldName: 'readVideos' });
      props.addFlashMessage(
        'Simulation has been deleted successfully',
        'success',
        true
      );
    },
    notifyOnNetworkStatusChange: true,
  });

  const [getSimulationUnits, { loading: unitsLoading }] = useLazyQuery(
    READ_SIMULATION_UNITS,
    {
      variables: {
        ID: props.simulationID,
        notifyOnNetworkStatusChange: true,
      },
    }
  );

  const history = useHistory();

  return (
    <EllipsisMenuButton
      disabled={loading || unitsLoading}
      className={props.className}
      noBg={true}
      loading={loading || unitsLoading}
      onClick={async (event) => {
        event.preventDefault();
        const { data: unitData } = await getSimulationUnits();

        const units = unitData?.readOneSimulation?.Units?.nodes || [];
        const modules = units
          .map(
            ({ Module }) =>
              `<li>${Module.Course.Title || 'Untitled Course'} - ${
                Module.Title || 'Untitled Module'
              }</li>`
          )
          .join('');

        const warning =
          units.length > 0
            ? `Are you sure about deleting this? <br/> The following will also lose this Simulation: <ul> ${modules}</ul>`
            : 'Delete this simulation?';

        props.confirm(
          () => {
            deleteSimulations();
          },
          units.length > 0
            ? `There are ${units.length} Units using this simulation`
            : '',
          warning,
          'delete',
          props.simulationTitle
        );
      }}
    >
      <Icon type="lineawesome" name="trash-solid" /> Delete Simulation
    </EllipsisMenuButton>
  );
};

DeleteSimulationBtn.propTypes = {
  simulationID: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  addFlashMessage: PropTypes.func.isRequired,
  userOrganisationID: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  confirm: PropTypes.func.isRequired,
};

export default WithConfirmationBox(WithFlashMessaging(DeleteSimulationBtn));
