import styled from 'styled-components';
import { Button } from '@virtidev/toolbox';

export const StyledButtonsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const CircularButton = styled(Button)`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0px;
  background: var(--neutral-color-lighter);
  color: var(--neutral-color);
  &:hover {
    background: #ccc;
  }
`;
