import { createContext, useContext } from 'react';
import { PageScrollValue } from '@core/components/Page/context/PageScroll.types';

export const PageScrollContext = createContext(
  /** @type {PageScrollValue} */ ({
    scrollTop: () => undefined,
    scrollIntoView: () => undefined,
    scrollTo: () => undefined,
    contentRef: null,
  })
);

export const usePageScroll = () => {
  return useContext(PageScrollContext);
};

export default PageScrollContext;
