import styled, { css } from 'styled-components';
import VirtualHumanEmbed from './components/VirtualHumanEmbed';

export const StyledMessage = styled.div`
  padding: 2rem;
  text-align: center;
  line-height: 1.5rem;
`;

export const VirtualHuman = styled(VirtualHumanEmbed)`
  ${({ show }) =>
    show
      ? css`
          position: relative;
        `
      : css`
          position: absolute;
          visibility: hidden;
          pointer-events: none;
        `}
`;
