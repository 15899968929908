import React from 'react';
import PropTypes from 'prop-types';
// import Highcharts from 'highcharts';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { ParseRawHighchartDBData } from '../../utility/ParseUtils';
import {
  StyledDataBlock,
  StyledDataBlockHeader,
  StyledDataBlockHeaderText,
} from '../../styled-components/StyledDataBlocks';
import { GetFuncSortByNum } from '../../utility/Sort';
import { defaultRangeSelector } from '../../utility/HighstockUtility';

const BarChartActiveUsersHighstock = (props) => {
  const data = JSON.parse(props.AU);
  const activityData = Object.values(data);
  const dateList = Object.keys(data).map((day) => new Date(day).getTime());
  //
  // format with a list of arrays of datetime associated values
  // e.g.
  // [
  //   [1596240000000, 31],
  //   [1596326400000, 0],
  //   [1596412800000, 3]
  // ]
  //
  let formattedData = activityData.reduce((carry, activityCount, index) => {
    carry.push([dateList[index], activityCount]);
    return carry;
  }, []);
  formattedData.sort(GetFuncSortByNum(0));
  let series = [
    {
      name: 'Users',
      type: 'column',
      color: 'var(--primary-color)',
      data: formattedData,
    },
  ];
  let yAxis = [
    {
      min: 0,
      title: {
        text: `Active ${props.userType || 'Members'}`,
      },
      opposite: false,
    },
  ];
  return (
    <StyledDataBlock fullWidth={true}>
      <StyledDataBlockHeader>
        <StyledDataBlockHeaderText>{props.title}</StyledDataBlockHeaderText>
      </StyledDataBlockHeader>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={'stockChart'}
        options={{
          // chart: {
          //   type: 'column'
          // },
          // default to 1month
          rangeSelector: defaultRangeSelector,
          navigator: {
            enabled: false,
          },
          title: {
            text: '',
          },
          xAxis: {
            type: 'datetime',
            // dateTimeLabelFormats: { // don't display the dummy year
            //   month: '%e. %b',
            //   year: '%b'
            // },
            title: {
              text: 'Date',
            },
          },
          yAxis,
          legend: {
            align: 'right',
            x: -30,
            verticalAlign: 'top',
            y: 25,
            floating: true,
            backgroundColor:
              (Highcharts.theme && Highcharts.theme.background2) || 'white',
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: false,
          },
          plotOptions: {
            column: {
              stacking: 'normal',
              dataLabels: {
                enabled: false,
                color:
                  (Highcharts.theme && Highcharts.theme.dataLabelsColor) ||
                  'white',
              },
            },
          },
          credits: {
            enabled: false,
          },
          series,
        }}
      />
    </StyledDataBlock>
  );
};

BarChartActiveUsersHighstock.propTypes = {
  AU: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default BarChartActiveUsersHighstock;
