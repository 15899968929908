import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { EDIT_VIRTUAL_HUMAN_WITH_GROUPS } from '../queries/VirtualHumanQueries';
import withFlashMessaging from '../HOCs/WithFlashMessaging';
import ScrollableGroupCheckboxList from '../components/ScrollableGroupCheckboxList';
import withUser from '../HOCs/WithUser';
import Tooltip from '../apps/core/src/components/Tooltip/Tooltip';
import VirtualHumanEditors from '../apps/core/src/components/VirtualHumanForm/components/VirtualHumanEditors/VirtualHumanEditors';

const StyledGroupsInputWrapper = styled.div``;
const StyledLabel = styled.label`
  font-weight: 500;
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0px;
`;

const EditorsSectionWrapper = styled.div`
  margin: 0.5rem 0rem 2rem 0rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  gap: 0.5rem;
`;

const GroupCheckboxesForVirtualHuman = (props) => {
  return (
    <StyledGroupsInputWrapper>
      <EditorsSectionWrapper>
        <StyledLabel>
          Give Editor Access
          <Tooltip icon="help">
            <div>
              Users with the Type <strong>Content Creator</strong> can be
              assigned virtual humans that they can then edit. They will be able
              to mark virtual humans as
              <strong> Ready to Publish</strong>, after which an admin can then
              set to
              <strong> Published.</strong>
            </div>
          </Tooltip>
        </StyledLabel>
        <VirtualHumanEditors
          virtualHumanID={props.virtualHumanID}
          usersData={props.virtualhuman.Editors}
        />
      </EditorsSectionWrapper>
      <ScrollableGroupCheckboxList
        scrollableHeight="23rem"
        mainObjectID={props.virtualHumanID}
        updateMutation={EDIT_VIRTUAL_HUMAN_WITH_GROUPS}
        showEveryoneCheckbox={true}
        type="virtual-human"
        checkedGroupIDs={props.checkedGroupIDs}
        mutationVariables={{ organisationID: props.userOrganisationID }}
      />
    </StyledGroupsInputWrapper>
  );
};

GroupCheckboxesForVirtualHuman.defaultProps = {
  virtualHumanID: null,
};

GroupCheckboxesForVirtualHuman.propTypes = {
  editorEdges: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        ID: PropTypes.string.isRequired,
        Name: PropTypes.string.isRequired,
      }),
    })
  ),
  checkedGroupIDs: PropTypes.array.isRequired,
  virtualHumanID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  userOrganisationID: PropTypes.number.isRequired,
};

export default withUser(withFlashMessaging(GroupCheckboxesForVirtualHuman));
