import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const StyledValidationMessages = styled.ul`
  ${props => !props.hasMessages && css`
    display: none;
  `}
  margin-top: 1rem;
  list-style: initial;
  padding-left: 1rem;
`;
const StyledError = styled.li`
  color: var(--validation-error-text-color);
`;

const ValidationMessages = props => (
  <StyledValidationMessages hasMessages={props.errors.length > 0}>
    {
      props.errors.map((error, index) => (
        <StyledError key={index}>{error}</StyledError>
      ))
    }
  </StyledValidationMessages>
)

ValidationMessages.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
}

export default ValidationMessages