import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import StyledTextInput from '../../styled-components/StyledTextInput';
import { InlineH1 } from '../../styled-components/StyledH1';
import EllipsisMenu, { EllipsisMenuButton } from '../EllipsisMenu';
import Icon from '../icons/Icon';
import { Loading } from '@virtidev/toolbox';

const StyledTitle = styled.div`
  ${({ theme: { spacing } }) => css`
    display: flex;
    h1 {
      font-size: 1.6rem;
      line-height: 2rem;
      word-break: break-word;
    }
    margin-left: 0.5rem;
    flex-direction: row;
    gap: ${spacing.md};
    align-items: center;
    justify-content: space-between;
  `}
`;

const SubContent = styled.div`
  ${({ theme: { spacing } }) => css`
    display: flex;
    flex-direction: row;
    gap: ${spacing.md};
    align-items: center;
  `}
`;

const StyledBtn = styled.button`
  font-size: 1.2rem;
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 0.5rem;
  color: ${(props) => props.color};
`;

const StyledInputWrapper = styled.div`
  display: flex;
`;

class EditableTitle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editedTitle: props.title,
      editing: false,
    };
    this.textInput = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.editing && !prevState.editing) {
      if (this.textInput.current) {
        this.textInput.current.focus();
      }
    }
  }

  _onTitleChange = (e) => {
    this.setState({
      editedTitle: e.target.value,
    });
  };

  _setToEdit = () => {
    this.setState({
      editing: true,
      previousTitle: this.props.title,
      editedTitle: this.props.title,
    });
  };

  _setToEditStop = () => {
    this.setState({
      editing: false,
      editedTitle: this.state.previousTitle,
    });
  };

  _save = () => {
    this.props.onSave(this.state.editedTitle);
    this.setState({
      editing: false,
    });
  };

  _onKeyPress = (event) => {
    if (event.key === 'Enter') {
      this._save();
    }
  };

  render() {
    return (
      <StyledTitle className={this.props.className}>
        {this.props.loading && (
          <InlineH1>
            <Loading />
          </InlineH1>
        )}
        {!this.props.loading && this.state.editing && (
          <StyledInputWrapper>
            <StyledTextInput
              styleType="title"
              onKeyPress={this._onKeyPress}
              name={this.props.htmlName}
              value={this.state.editedTitle}
              id={this.props.htmlID}
              onChange={this._onTitleChange}
              disabled={this.props.loading}
              inline={true}
              maxLength={this.props.maxLength}
              ref={this.textInput}
            />
            <StyledBtn
              aria-label="Stop editing and cancel"
              color="red"
              onClick={this._setToEditStop}
            >
              <Icon type="lineawesome" name="times-solid" color="red" />
            </StyledBtn>
            <StyledBtn
              aria-label="Finish editing and save"
              color="green"
              onClick={this._save}
            >
              <Icon type="lineawesome" name="check-solid" />
            </StyledBtn>
          </StyledInputWrapper>
        )}
        {!this.props.loading && !this.state.editing && (
          <SubContent>
            <InlineH1>{this.props.title}</InlineH1>
            {(this.props.editable || this.props.editTitleMenuButtons) && (
              <EllipsisMenu
                ellipsisType="horizontal"
                selectorsToExcludeClosingMenu={['#modal']}
              >
                {this.props.editable && (
                  <EllipsisMenuButton
                    aria-label="Edit simulation name"
                    onClick={this._setToEdit}
                  >
                    <Icon type="lineawesome" name="pencil-alt-solid" /> Edit
                    Name
                  </EllipsisMenuButton>
                )}
                {this.props.editTitleMenuButtons}
              </EllipsisMenu>
            )}
          </SubContent>
        )}
        {!this.props.loading && this.props.children && (
          <SubContent>{this.props.children}</SubContent>
        )}
      </StyledTitle>
    );
  }
}

EditableTitle.defaultProps = {
  loading: false,
  editable: true,
  onSave: () => null,
};

EditableTitle.propTypes = {
  className: PropTypes.string,
  htmlID: PropTypes.string,
  htmlName: PropTypes.string,
  title: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  loading: PropTypes.bool,
  maxLength: PropTypes.number,
  editable: PropTypes.bool,
  editTitleMenuButtons: PropTypes.node,
};

export default EditableTitle;
