import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import {
  Button,
  ConfirmationModal,
  Icon,
  PaginationControl,
  Select,
  Table,
  TextInput,
  ToggleSwitch,
} from '@virtidev/toolbox';
import React, { useCallback, useMemo, useState } from 'react';
import { useFlashMessage } from '../../../../FlashMessage';
import { CREATE_VOICE, READ_VOICES } from './CxVoicesEditor.query';
import * as StyledCxVH from '../../CxVirtualHuman.styled';
import SelectedVoiceEditor from './components/SelectedVoiceEditor/SelectedVoiceEditor';
import { serviceOptions } from '@core/components/Cx/VirtualHuman/components/CxVoicesEditor/ServiceOptions';
import { useDebounce } from 'use-debounce';

const pageSize = 100;
const featuredOptions = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Featured',
    value: 'true',
  },
  {
    label: 'Not Featured',
    value: 'false',
  },
];

const CxVoicesEditor = () => {
  const [page, setPage] = useState(1);
  const serviceOptionsWithBlank = useMemo(() => {
    return [{ label: 'Select Service...', value: '' }, ...serviceOptions];
  }, []);

  const [serviceFilter, setServiceFilter] = useState(
    serviceOptionsWithBlank[0]
  );
  const [localeFilter, setLocaleFilter] = useState('');
  const [searchNameFilter, setSearchNameFilter] = useState('');
  const [searchCodeFilter, setSearchCodeFilter] = useState('');
  const [dbNameSearchFilterVal] = useDebounce(searchNameFilter, 500);
  const [dbCodeSearchFilterVal] = useDebounce(searchCodeFilter, 500);
  const [featuredFilter, setFeaturedFilter] = useState(featuredOptions[0]);

  const { data: voices } = useQuery(READ_VOICES, {
    variables: {
      limit: pageSize,
      offset: (page - 1) * pageSize,

      filter: {
        ...(serviceFilter.value
          ? { Service: { eq: serviceFilter.value } }
          : {}),
        ...(localeFilter ? { Locale: { contains: localeFilter } } : {}),
        ...(dbNameSearchFilterVal
          ? { Name: { contains: dbNameSearchFilterVal } }
          : {}),
        ...(dbCodeSearchFilterVal
          ? { Code: { contains: dbCodeSearchFilterVal } }
          : {}),
        ...(featuredFilter.value
          ? { Featured: { eq: featuredFilter.value } }
          : {}),
      },
    },
  });
  const [createVoice, { loading: creatingVoice }] = useMutation(CREATE_VOICE);
  const [selectedVoiceID, setSelectiveVoiceId] = useState(null);
  const selectedVoice = useMemo(
    () =>
      voices?.readVoices?.nodes &&
      voices.readVoices.nodes.find((voice) => voice.ID === selectedVoiceID),
    [voices, selectedVoiceID]
  );
  const { addFlashMessage } = useFlashMessage();

  const client = useApolloClient();
  const handleCreateVoice = useCallback(async () => {
    await createVoice({
      variables: {
        input: {
          Name: 'Unnamed',
          Code: '',
          Service: '',
        },
      },
      onCompleted: () => {
        addFlashMessage('Created voice');
      },
      onError: () => {
        addFlashMessage('Failed to create voice', 'error');
      },
    });
    client.cache.evict({ fieldName: 'readVoices' });
  }, [createVoice, addFlashMessage, client?.cache]);

  // const addAllFromData = async () => {
  //   for (let voiceCategory of bulkVoiceData) {
  //     for (let voice of voiceCategory.voices) {
  //       await createVoice({
  //         variables: {
  //           input: {
  //             Name: voice.name,
  //             Code: voice.code,
  //             Service: voiceCategory.service,
  //             Locale: voiceCategory.bcp47Code,
  //           },
  //         },
  //       });
  //     }
  //   }
  // };
  const duplicateVoice = useCallback(
    async (voice) => {
      await createVoice({
        variables: {
          input: {
            Name: voice.Name,
            Code: voice.Code,
            Age: voice.Age,
            Locale: voice.Locale,
            Gender: voice.Gender,
            Service: voice.Service,
          },
        },
      });
      client.cache.evict({ fieldName: 'readVoices' });
      setVoiceToDuplicate(null);
    },
    [createVoice, client]
  );

  const handleServiceFilterChange = useCallback(async (newService) => {
    setServiceFilter(newService);
    setPage(1);
  }, []);

  const handleFeaturedFilterChange = useCallback(async (newService) => {
    setFeaturedFilter(newService);
    setPage(1);
  }, []);

  const [voiceToDuplicate, setVoiceToDuplicate] = useState(null);
  const [showDuplicateConfirm, setShowDuplicateConfirm] = useState(false);
  const handleConfirmDuplicate = useCallback(
    (voice) => {
      setVoiceToDuplicate(voice);
      duplicateVoice(voiceToDuplicate);
      setShowDuplicateConfirm(false);
    },
    [duplicateVoice, voiceToDuplicate]
  );
  const handleClickDuplicate = useCallback((voice) => {
    setVoiceToDuplicate(voice);
    setShowDuplicateConfirm(true);
  }, []);

  return (
    <StyledCxVH.Wrapper>
      {/* <button onClick={addAllFromData}>Add From Data</button> */}
      <h1>Voices</h1>
      <StyledCxVH.FiltersWrapper>
        <div>
          <TextInput
            placeholder="Search name..."
            value={searchNameFilter}
            onChange={(e) => {
              setPage(1);
              setSearchNameFilter(e.target.value);
            }}
          />
        </div>
        <div>
          <TextInput
            placeholder="Search code..."
            value={searchCodeFilter}
            onChange={(e) => {
              setPage(1);
              setSearchCodeFilter(e.target.value);
            }}
          />
        </div>
        <div>
          <TextInput
            placeholder="Locale"
            value={localeFilter}
            onChange={(e) => {
              setPage(1);
              setLocaleFilter(e.target.value);
            }}
          />
        </div>
        <div>
          <StyledCxVH.ServiceSelect
            value={serviceFilter}
            options={serviceOptionsWithBlank}
            placeholder="Service"
            onChange={handleServiceFilterChange}
          />
        </div>
        <div>
          <StyledCxVH.ServiceSelect
            value={featuredFilter}
            options={featuredOptions}
            placeholder="Featured"
            onChange={handleFeaturedFilterChange}
          />
        </div>
      </StyledCxVH.FiltersWrapper>
      <PaginationControl
        onPageChange={setPage}
        page={page}
        pageSize={pageSize}
        total={voices?.readVoices?.pageInfo.totalCount ?? 0}
      />
      <StyledCxVH.ContentWrapper>
        <StyledCxVH.ListWrapper>
          <StyledCxVH.EditorList>
            <StyledCxVH.VhCxTable>
              <tr>
                <th>Name</th>
                <th>Code</th>
                <th>Locale</th>
                <th>Age</th>
                <th>Gender</th>
                <th>Service</th>
                <th>Featured</th>
                <th>Deprecated</th>
                <th></th>
              </tr>
              {voices?.readVoices?.nodes &&
                voices.readVoices.nodes.map((voice) => (
                  <StyledCxVH.EditorListTableRow
                    $active={selectedVoiceID === voice.ID}
                  >
                    <td>{voice.Name}</td>
                    <td>{voice.Code}</td>
                    <td>{voice.Locale}</td>
                    <td>{voice.Age}</td>
                    <td>{voice.Gender}</td>
                    <td>{voice.Service}</td>
                    <td>{voice.Featured ? <Icon icon="tick" /> : ''}</td>
                    <td>{voice.Deprecated ? <Icon icon="tick" /> : ''}</td>
                    <td>
                      <Button
                        icon="duplicate"
                        onClick={() => handleClickDuplicate(voice)}
                      />
                    </td>
                    <td>
                      <Button
                        icon="pen"
                        onClick={() => setSelectiveVoiceId(voice.ID)}
                      />
                    </td>
                  </StyledCxVH.EditorListTableRow>
                ))}
            </StyledCxVH.VhCxTable>
          </StyledCxVH.EditorList>
          <Button loading={creatingVoice} onClick={handleCreateVoice}>
            Create Blank Voice
          </Button>
        </StyledCxVH.ListWrapper>
        <StyledCxVH.SelectedItem>
          {selectedVoice && (
            <SelectedVoiceEditor selectedVoice={selectedVoice} />
          )}
        </StyledCxVH.SelectedItem>
      </StyledCxVH.ContentWrapper>
      <ConfirmationModal
        show={showDuplicateConfirm}
        onConfirm={handleConfirmDuplicate}
        loading={creatingVoice}
        onHide={() => setShowDuplicateConfirm(false)}
      >
        Are you sure you want to duplicate this voice?
      </ConfirmationModal>
    </StyledCxVH.Wrapper>
  );
};

export default CxVoicesEditor;
