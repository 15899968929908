import React from 'react';
import { userVar } from '../ApolloReactiveVars';
import { GetUserType } from '../utility/Permissions';

const withUser = (WrappedComponent) => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      const storedUser = userVar();
      const defaults = {
        ID: null,
        Name: null,
        Email: null,
        AvatarMedia: { URL: null },
        OrganisationID: null,
        Organisation: { ID: null, LogoURL: null, Name: null, AccountID: null },
        Organisations: [],
        OrganisationGroups: [],
        Token: null,
        UserType: null,
        OnboardProgress: '[]',
      };
      this.state = { user: { ...defaults, ...storedUser } };
    }

    render() {
      let userID = parseInt(this.state.user.ID, 10);
      let organisationID = parseInt(this.state.user.OrganisationID, 10);
      if (isNaN(userID)) {
        userID = null;
      }
      if (isNaN(organisationID)) {
        organisationID = null;
      }
      return (
        <WrappedComponent
          userID={userID}
          user={this.state.user}
          userOrganisationID={organisationID}
          userOrganisationName={this.state.user.Organisation.Name}
          userType={GetUserType(this.state.user.UserType)}
          userAccountID={this.state.user.Organisation.AccountID}
          {...this.props}
        />
      );
    }
  };
};

export default withUser;
