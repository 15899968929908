import styled from 'styled-components';

export const StyledBackWrapper = styled.div`
  margin: 20px;
  text-align: left;
  display: flex;
  gap: 1.5rem;
`;

export const StyledOrgHeading = styled.h3`
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
`;
