import _ from 'lodash';
import CourseList from '../../../components/CourseList';
import { Titlebar } from '../../../components/Page';
import { withCourseAccess } from '../../../helpers/permissions';
import { withPageTemplate } from '../../../templates';

const CourseListPage = () => {
  return (
    <>
      <Titlebar>Courses</Titlebar>
      <CourseList />
    </>
  );
};

export default _.flowRight(
  withCourseAccess,
  withPageTemplate({ pageKey: 'course' })
)(CourseListPage);
