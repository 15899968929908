import { gql } from '@apollo/client';

export const UPDATE_FEEDBACK_QUESTION = gql`
  mutation updateQuestion($input: UpdateQuestionInput!) {
    updateQuestion(input: $input) {
      ID
      Title
      Placeholder
      Required
      SortOrder
      CharacterLimit
      Type
      Length
      FirstLabel
      LastLabel
    }
  }
`;

export const DELETE_QUESTIONS = gql`
  mutation deleteQuestions($ids: [ID]!) {
    deleteQuestions(ids: $ids)
  }
`;
