import { Card } from '@virtidev/toolbox';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const TableWrapper = styled(Card)`
  padding: 20px 30px;
`;

export const PaginationControlWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

export const StyledGroupLink = styled(Link)`
  font-size: 0.7rem;
  display: block;
  margin-bottom: 5px;
  :hover {
    color: var(--primary-color);
  }
`;

export const FiltersWrapper = styled.div`
  display: flex;
  gap: 20px;
  min-width: 90%;
  max-width: 100%;
  float: right;
  padding: 10px 20px 20px 20px;
`;

// export const SelectedCount = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;
