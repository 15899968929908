import getConfig from '../../../../../../lib/config';

const REACT_APP_MIN_MOBILE_VERSION = getConfig('REACT_APP_MIN_MOBILE_VERSION');

/**
 * @param {string} link
 * @param {string | null} OrganisationID
 * @returns
 */
export const generateDeepLink = (link, OrganisationID = null) => {
  const domain = window.location.origin;

  const pathname = (link || '').split('?')[0] || link;
  const targetQuery = new URLSearchParams((link || '').split('?')[1]);

  // mobile validates if user has access to organisation
  targetQuery.set('organisationID', OrganisationID || '0');
  // set this redirect flag to stop redirect loops
  targetQuery.set('redirect', '1');

  const targetLink = `${domain}${pathname}?${targetQuery.toString()}`;

  const deepQuery = new URLSearchParams({
    link: targetLink,
    apn: 'com.virti.simulationapp',
    isi: '1347707588',
    ibi: 'com.virti.simulationapp',
    // amv: REACT_APP_MIN_MOBILE_VERSION,
  });

  return `https://virti.page.link/?${deepQuery.toString()}`;
};
