import styled, { css } from 'styled-components';

export const TagListContainer = styled.div`
  ${({ theme }) => css`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.xs};
    padding: 0 ${theme.spacing.md};
    overflow: auto;
  `}
`;

export const TagItem = styled.button.attrs({
  type: 'button',
})`
  ${({ theme, disabled }) => css`
    background: transparent;
    border-radius: ${theme.borderRadius.sm};
    border: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${theme.spacing.md};
    width: 100%;
    min-width: 100px;

    &:hover {
      background: ${disabled ? null : theme.color.highlight};
    }
  `}
`;

export const TagItemName = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 60px;
`;

export const LoadingChange = styled.div`
  ${({ $show }) => css`
    position: absolute;
    top: 0;
    right: 0;
    display: ${$show ? 'block' : 'none'};
  `}
`;

export const CreateTagForm = styled.form`
  ${({ theme }) => css`
    min-width: 240px;
    padding: ${theme.spacing.md} ${theme.spacing.md} 0;
  `}
`;
