import { gql } from '@apollo/client';

export const UPDATE_TITLE = gql`
  mutation updateModule($Input: UpdateModuleInput!) {
    updateModule(input: $Input) {
      ID
      Title
    }
  }
`;
