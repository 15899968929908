import styled from 'styled-components';

// TODO: delete this file

export const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-self: flex-end;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  gap: calc(var(--content-padding) / 2);

  // Ensure dropdowns sit above card actions
  position: relative;
  z-index: 1002;
`;

export const FieldWrapper = styled.div`
  flex-grow: 1;
  width: ${({ width }) => width || '10%'};
  min-width: ${({ minWidth }) => minWidth || '170px'};
  max-width: ${({ maxWidth }) => maxWidth || '250px'};
`;
