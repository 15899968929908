import { gql } from '@apollo/client';

export const READ_BADGES = gql`
  query readBadges {
    readBadges {
      edges {
        node {
          ID
          Label
          Title
          Description
        }
      }
    }
  }
`;
