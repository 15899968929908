import FieldError from '@core/components/form/FieldError';
import { nameFormSchema } from '@core/components/Signup/RegisterForm/helpers/register-form.schema';
import {
  PrivacyField,
  RegisterFormInner,
} from '@core/components/Signup/RegisterForm/RegisterForm.styled';
import { Button, TextInput } from '@virtidev/toolbox';
import { FC, useCallback } from 'react';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import { NameFormInput } from '@core/components/Signup/RegisterForm/helpers/register-form.types';

/**
 * @type {FC<{
 *    onSubmit: () => void,
 *    existingOrg?: boolean,
 *    loading?: boolean,
 * }>}
 */
export const NameForm = ({ loading, onSubmit, existingOrg }) => {
  /** @type {UseFormReturn<NameFormInput>} */
  const { register, getValues, trigger, clearErrors } = useFormContext();

  const submitForm = useCallback(
    async (e) => {
      if (e) {
        e.preventDefault();
      }
      try {
        nameFormSchema.parse(getValues());

        clearErrors();
        onSubmit();
      } catch (e) {
        trigger();
      }
    },
    [trigger, clearErrors, getValues, onSubmit]
  );

  return (
    <RegisterFormInner onSubmit={submitForm}>
      <div>
        <TextInput {...register('FirstName')} placeholder="First name" />
        <FieldError name="FirstName" />
      </div>
      <div>
        <TextInput {...register('Surname')} placeholder="Last name" />
        <FieldError name="Surname" />
      </div>
      {!existingOrg && (
        <div>
          <TextInput
            {...register('OrganisationName')}
            placeholder="Company name"
          />
          <FieldError name="OrganisationName" />
        </div>
      )}
      <PrivacyField />
      <Button loading={loading} color="secondary" type="submit">
        Create account
      </Button>
    </RegisterFormInner>
  );
};

export default NameForm;
