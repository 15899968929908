import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useLockedTask, useTaskLink } from '../utility/OnboardingHooks';
import Icon from './icons/Icon';
import StyledLink from '../styled-components/StyledLink';
import WithOnboardingHandler from '../HOCs/WithOnboardingHandler';

const StyledTask = styled.div`
  position: relative;
  &:before {
    content: '';
    border-radius: 0 0 25px 25px;
    height: 25px;
    border: 1px solid var(--neutral-color-lighter);

    border-top: 0;
    position: absolute;
    bottom: 0;
    left: -1px;
    right: -1px;
    pointer-events: none;
  }
  &:last-child:before {
    border: 0;
  }
`;

const StyledTaskHeader = styled.div`
  padding: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center start;
  cursor: pointer;
`;

const StyledTaskHeaderDetails = styled.div`
  display: grid;
  place-items: end;
  width: 100%;
  grid-template-columns: 1fr;
`;

const StyledTaskHeaderDetail = styled.div``;

const StyledTaskTitle = styled.h1`
  font-size: 1.3rem;
`;

const IconWrapper = styled.div`
  display: grid;
  place-items: end;
`;

const StyledTaskBody = styled.div`
  padding: 0rem 2rem 2rem 2rem;
`;

const StyledTaskBodyContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: top start;
  color: var(--primary-font-color-light);
`;

const StyledLinkButton = styled(StyledLink)`
  ${({ theme, disabled }) => css`
    background: ${theme.color.primary};
    font-size: 1rem;
    padding: var(--primary-button-padding);
    border-radius: 24px;
    color: white !important;

    text-decoration: none;
    :hover {
      opacity: 0.7;

      text-decoration: none;
    }

    ${disabled &&
    css`
      pointer-events: none;
      background-color: var(--button-disabled-bg-color);
      color: (--button-disabled-text-color);
    `}
  `}
`;

const StyledButtonWrapper = styled.div`
  display: grid;
  place-items: start;
  margin-top: 2rem;
`;

const StyledTaskDescription = styled.div`
  margin-bottom: 2rem;
  line-height: 1.2rem;
  margin-right: 6rem;
`;

const StyledTime = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.color.secondary};
    padding: 0.5rem 0.8rem;
    display: grid;
    place-items: center;
    border-radius: 24px;
    grid-template-columns: 1fr 2fr;
    gap: 1rem;
    color: ${theme.color.secondary};
  `}
`;

const StyledVideoWrapper = styled.div`
  img,
  audio,
  video {
    width: 100%;
    border-radius: 24px;
    margin: auto;
    margin-bottom: 1rem;
  }
  display: grid;
  place-items: center;
`;

function OnboardingTask(props) {
  const [open, setOpen] = useState(props.startOpen);

  const locked = useLockedTask(props.task.Key, props.data);

  const link = useTaskLink(props.task, props.data);

  // create a styled video component

  return (
    <StyledTask>
      <StyledTaskHeader
        completed={props.task.Completed}
        onClick={() => setOpen(!open)}
      >
        <StyledTaskTitle>
          {props.index + 1}
          {'. '}
          {props.task.Title}
        </StyledTaskTitle>
        <StyledTaskHeaderDetails>
          <StyledTaskHeaderDetail></StyledTaskHeaderDetail>
          <StyledTaskHeaderDetail>
            <IconWrapper>
              {' '}
              {props.task.Completed ? (
                <Icon type="formtools" size="small" name="success-outline" />
              ) : (
                ''
              )}
              {locked && !props.task.Completed && (
                <Icon type="formtools" size="small" name="blue-lock" />
              )}
              {!locked && !props.task.Completed && (
                <StyledTime>
                  <Icon type="formtools" size="small" name="clock" />{' '}
                  {props.task.Time || '5 minutes'}
                </StyledTime>
              )}
            </IconWrapper>
          </StyledTaskHeaderDetail>
        </StyledTaskHeaderDetails>
      </StyledTaskHeader>
      {open && (
        <StyledTaskBody>
          <StyledTaskBodyContent>
            <StyledTaskDescription>
              {props.task.Description}

              <StyledButtonWrapper>
                {' '}
                {!props.task.Completed && (
                  <StyledLinkButton disabled={locked} to={link}>
                    {props.task.Title}
                  </StyledLinkButton>
                )}
              </StyledButtonWrapper>
            </StyledTaskDescription>
            {props.task.Video ? (
              <StyledVideoWrapper>
                <video controls preload="metadata">
                  <source src={props.task.Video + '#t=0.5'} />
                  Your browser does not support this video.
                </video>
              </StyledVideoWrapper>
            ) : (
              <div></div>
            )}
          </StyledTaskBodyContent>
        </StyledTaskBody>
      )}
    </StyledTask>
  );
}

OnboardingTask.propTypes = {
  task: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default WithOnboardingHandler(OnboardingTask);
