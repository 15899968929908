import { FC, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import { ToggleSwitch, ToggleSwitchProps } from '@virtidev/toolbox';

/**
 * @type {FC<ToggleSwitchProps & {
 *    value?: any,
 *    noValue?: any,
 *    name: string,
 * }>}
 */
export const ControlledToggleSwitch = ({ value, noValue, name, ...props }) => {
  const {
    field: { onChange, value: fieldValue },
  } = useController({ name });

  const handleChange = useCallback(
    ({ target }) => {
      onChange(target.checked ? value : noValue);
    },
    [onChange, value, noValue]
  );

  return (
    <ToggleSwitch
      {...props}
      checked={fieldValue === value}
      onChange={handleChange}
      name={name}
    />
  );
};

ControlledToggleSwitch.propTypes = {
  name: PropTypes.string.isRequired,
};

ControlledToggleSwitch.defaultProps = {
  value: true,
  noValue: false,
};

export default ControlledToggleSwitch;
