import React from 'react';
import PropTypes from 'prop-types';
import RedirectWithPrevState from '../components/RedirectWithPrevState';
import VirtualHumanTabNav from '../components/VirtualHumanTabNav';
import WithVirtualHumanPageTemplate from '../HOCs/templates/WithVirtualHumanPageTemplate';
import VirtualHumanManager from '../apps/core/src/components/VirtualHumanManager/VirtualHumanManager';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const PageContent = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;

  > iframe {
    width: 100%;
    height: 100%;
  }
`;

const VirtualHumanPreviewPage = (props) => {
  const { ssVirtualHumanID } = useParams();

  if (!props.userID) {
    return <RedirectWithPrevState to="/login" />;
  }
  return (
    <>
      <VirtualHumanTabNav virtualHumanData={props.data.readOneVirtualHuman} />
      <PageContent>
        <VirtualHumanManager
          virtualHumanId={ssVirtualHumanID}
          isPreviewPlayer={true}
        />
      </PageContent>
    </>
  );
};

export default WithVirtualHumanPageTemplate(
  VirtualHumanPreviewPage,
  undefined,
  false,
  true
);

VirtualHumanPreviewPage.propTypes = {
  userID: PropTypes.number,
  data: PropTypes.object.isRequired,
};
