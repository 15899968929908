import { Card } from '@virtidev/toolbox';
import styled from 'styled-components';

const Image = styled.img`
  width: 100%;
  display: ${(/** @type {{visible: Boolean;}} */ props) =>
    props.visible ? 'block' : 'none'};
  aspect-ratio: 600 / 338;
`;

export const EnvironmentImage = styled(Image)``;
export const AvatarImage = styled(Image)`
  position: absolute;
  top: 0;
`;

export const AvatarWrapper = styled.div`
  width: 100%;
`;
export const AvatarSubwrapper = styled.div`
  position: relative;
  justify-content: center;
  align-items: flex-start;
  aspect-ratio: 600 / 338;
`;
