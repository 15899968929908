import styled from 'styled-components';

export const StyledVHIframeWrapper = styled.div`
  padding: 2rem;
  width: 100%;
  height: 100%;
`;

export const StyledVHIframe = styled.iframe`
  height: 100%;
  width: 100%;
  border: none;
`;
