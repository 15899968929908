import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.5rem;

  ${
    /**
     * @param {{
     *   $hidden: boolean,
     * }} div
     */
    ({ $hidden }) =>
      $hidden &&
      css`
        display: none;
      `
  }
`;

export const VoiceWrapper = styled.div`
  width: 100%;
`;
