import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as Styled from './VoicePreview.styled';
import useGetVHData from '../../../../../../../../utility/VHHooks/useGetVHData';
import { Icon, Loading } from '@virtidev/toolbox';
import { useTheme } from 'styled-components';
import * as Sentry from '@sentry/browser';
import { useFlashMessage } from '@core/components/FlashMessage';

const VoicePreview = ({ voice, locale = '' }) => {
  const audioRef = useRef(null);

  const resolvedLocale = useMemo(() => {
    // If Voice.Locale and locale use the same base language, retain Voice.Locale. Otherwise, use locale.
    const voiceLocaleLang = voice?.Locale.split('-')[0];
    const localeLang = locale.split('-')[0];
    if (locale && voiceLocaleLang !== localeLang) {
      return locale;
    }
    return voice?.Locale;
  }, [voice, locale]);

  const { data, loading, error, refetch } = useGetVHData(
    `tts-preview?voice=${voice?.Service.toLowerCase()}_${
      voice?.Code
    }&text=Hello, this is an example of my voice. How are you doing today?&languageCode=${resolvedLocale}`,
    { skip: true }
  );

  const [isPlaying, setIsPlaying] = useState(false);
  // used to avoid the error Error: AbortError: The play() request was interrupted by a call to pause().
  const [isStartingPlay, setIsStartingPlay] = useState(false);

  const { addFlashMessage } = useFlashMessage();
  const handleClick = useCallback(
    async (e) => {
      e.stopPropagation();
      console.log(resolvedLocale);
      if (!voice?.Code || !resolvedLocale) {
        addFlashMessage(
          'The voice does not have a code and/or locale set',
          'error'
        );
        return;
      }
      if (!data?.data?.data?.file?.url) {
        await refetch();
      }
      if (!audioRef.current || isStartingPlay) return;
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        setIsStartingPlay(true);
        try {
          await audioRef.current.play();
        } catch (error) {
          Sentry.captureException(error);
          console.error('Error playing audio:', error);
        }
        setIsStartingPlay(false);
      }
    },
    [
      isPlaying,
      isStartingPlay,
      addFlashMessage,
      refetch,
      resolvedLocale,
      voice?.Code,
      data?.data?.data?.file?.url,
    ]
  );

  const handleAudioEnded = useCallback(() => {
    setIsPlaying(false);
    // audioRef.current.
  }, []);

  const handleOnPlay = useCallback(() => {
    setIsPlaying(true);
  }, []);

  const handlePause = useCallback(() => {
    setIsPlaying(false);
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  }, [voice]);

  const theme = useTheme();

  return (
    <>
      <Styled.PreviewButton
        title="preview"
        size="small"
        disabled={!!error || loading}
        onClick={handleClick}
        iconSize={20}
        color="pink"
        design="transparent"
      >
        {loading ? (
          <Loading />
        ) : (
          <Icon
            icon={isPlaying ? 'pause' : 'speaker'}
            color={theme.color.midnight10}
            size="1.3rem"
          />
        )}
      </Styled.PreviewButton>
      <audio
        onPlay={handleOnPlay}
        onPause={handlePause}
        onEnded={handleAudioEnded}
        controls={false}
        src={data?.data?.data?.file?.url}
        ref={audioRef}
        preload="none"
      />
    </>
  );
};

export default VoicePreview;
