import styled from 'styled-components';

export const RadioSelectWrapper = styled.div`
  fieldset {
    all: unset;
    display: flex;
    gap: ${({ theme }) => theme.spacing.md};
    flex-wrap: wrap;
  }
  legend {
    display: none;
  }
  label {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.sm};
    padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
    border-radius: ${({ theme }) => theme.borderRadius.sm};
    border: 1px solid ${({ theme }) => theme.color.lightGrey};
    font-size: ${({ theme }) => theme.fontSize.md};

    &:has(input:checked) {
      border-color: ${({ theme }) => theme.color.darkGrey};
      // background-color: ${({ theme }) => theme.color.darkGrey};
    }
  }
`;
