import { useMutation, useQuery } from '@apollo/client';
import { Icon } from '@virtidev/toolbox';
import { produce } from 'immer';
import React, { useCallback, useMemo, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Modal from '../../../../../../components/Modal';
import StyledLink from '../../../../../../styled-components/StyledLink';
import useUser from '../../../helpers/useUser';
import {
  READ_MEMBER_ONBOARD_PROGRESS,
  UPDATE_MEMBER_WELCOME_PROGRESS,
} from '../../../helpers/useWelcomeFlow/useWelcomeFlow.query';
import { StyledIframe, StyledMessage } from './ContactSalesModal.styled';
import tracker from '../../../helpers/tracker';
import { READ_TRIAL_EXPIRY } from '@core/components/Signup/legacy/TrialBanner/TrialBanner.query';
import { getTrialRemainder } from '@core/components/Signup/legacy/helpers/getTrialRemainder';
import { hasExpiredTrial } from '@base/utility/LoginUtility';

export default function ContactSalesModal({ show, setShow }) {
  const { FirstName, Surname, Email, ID, Organisation, Organisations } =
    useUser();
  const { data } = useQuery(READ_MEMBER_ONBOARD_PROGRESS, {
    variables: {
      ID,
    },
    skip: !show,
  });

  const { data: orgData } = useQuery(READ_TRIAL_EXPIRY, {
    variables: {
      ID: Organisation?.ID,
    },
    skip: !Organisation?.ID,
  });

  const trialRemainder = useMemo(() => {
    if (!orgData?.readOneOrganisation?.TrialExpiryDate) {
      return 1;
    }
    return getTrialRemainder(orgData.readOneOrganisation.TrialExpiryDate);
  }, [orgData]);

  const nextActiveOrg = useMemo(() => {
    return Organisations?.find((org) => !hasExpiredTrial(org));
  }, [Organisations]);

  const hubspotMessageHandledRef = useRef(false);

  const trialExpired = useMemo(() => {
    if (trialRemainder == null) {
      return false;
    }
    return trialRemainder <= 0;
  }, [trialRemainder]);

  useEffect(() => {
    if (trialExpired && orgData?.readOneOrganisation?.Trial) {
      setShow(true);
    }
  }, [setShow, trialExpired, orgData?.readOneOrganisation?.Trial]);

  // this will need to change to update the org instead
  const [updateMember] = useMutation(UPDATE_MEMBER_WELCOME_PROGRESS, {
    onError: (err) => console.error(err),
  });

  const OnboardProgress = useMemo(() => {
    if (data) {
      try {
        return JSON.parse(data?.readOneMember?.OnboardProgress);
      } catch (e) {}
    }
    return null;
  }, [data]);

  const handleHubspotMessage = useCallback(
    (e) => {
      // The hubspot widget sends an identical message twice, so we want to make sure to only handle it once
      // We also want to make sure that only the visible modal handle the message, since other closed ones may be rendered and listening for the message event
      if (
        show &&
        !hubspotMessageHandledRef.current &&
        e.data.meetingBookSucceeded
      ) {
        hubspotMessageHandledRef.current = true;

        const updatedProgress = produce(OnboardProgress || {}, (state) => {
          if (!state?.welcomeProgress) {
            state.welcomeProgress = {};
          }
          state.welcomeProgress.contactedSales = true;
        });

        const updatedProgressJSON = JSON.stringify(updatedProgress);
        updateMember({
          variables: {
            Input: {
              ID,
              OnboardProgress: updatedProgressJSON,
            },
          },
        });

        tracker.track('meeting_booked', {
          type: 'sales',
          flow: 'onboarding',
        });
      }
    },
    [show, OnboardProgress, updateMember, ID]
  );

  useEffect(() => {
    window.addEventListener('message', handleHubspotMessage);

    return () => window.removeEventListener('message', handleHubspotMessage);
  }, [handleHubspotMessage]);

  const message = useMemo(() => {
    if (OnboardProgress?.welcomeProgress?.contactedSales) {
      return (
        <StyledMessage>
          <Icon icon="alert" size="25px" />
          You've already booked a meeting with us, but feel free to book another
          if you need.
        </StyledMessage>
      );
    } else {
      return '';
    }
  }, [OnboardProgress]);

  const { pathname } = useLocation();
  const history = useHistory();

  const handleHideModal = useCallback(() => {
    if (trialExpired) {
      return;
    }

    setShow(false);
    if (Organisation?.Trial) {
      if (pathname.includes('virtual-human')) {
        history.replace('/');
      }
    }
  }, [history, pathname, Organisation?.Trial, setShow, trialExpired]);

  const titleMessage = useMemo(() => {
    if (trialExpired) {
      return 'Your trial has ended - contact us to continue using Virti!';
    } else {
      return 'Upgrade your account to access more features!';
    }
  }, [trialExpired]);

  return (
    <Modal
      visible={show}
      hideModal={handleHideModal}
      title={titleMessage}
      noExit={trialExpired}
    >
      {show && (
        <>
          {message}
          <StyledIframe
            title="book-hubspot-meeting"
            src={`https://meetings.hubspot.com/luke-willett/saas-sign-up-meeting?embed=true&firstname=${FirstName}&lastname=${Surname}&email=${Email}&company=${Organisation.Name}`}
          />
          {trialExpired && (
            <p style={{ textAlign: 'center' }}>
              You can <StyledLink to="/logout">log out</StyledLink> here
              {nextActiveOrg && (
                <>
                  , or{' '}
                  <StyledLink to={`/set-organisation/${nextActiveOrg.ID}`}>
                    change organisation
                  </StyledLink>
                </>
              )}
              .
            </p>
          )}
        </>
      )}
    </Modal>
  );
}
