import RedirectWithPrevState from '../components/RedirectWithPrevState';
import { CanEditSimulation } from '../utility/Permissions';
import PropTypes from 'prop-types';
import useUser from '../apps/core/src/helpers/useUser';

const WithSimulationPageRedirects = (WrappedComponent) => {
  const SimulationPageRedirects = (props) => {
    const { ID: userID } = useUser();
    if (!userID) {
      return <RedirectWithPrevState to="/login" />;
    }

    if (!CanEditSimulation(props.data.readOneSimulation, userID)) {
      return <RedirectWithPrevState to="/simulations" />;
    }

    // redirect to URL with first video
    if (!props.match.params.hasOwnProperty('videoID')) {
      const redirectToVideoID =
        props.data.readOneSimulation.StartVideoID > 0
          ? props.data.readOneSimulation.StartVideoID
          : props.data.readOneSimulation.Videos?.nodes[0]?.ID || 0;
      let redirectURL = `/simulations/${props.data.readOneSimulation.ID}/videos/${redirectToVideoID}`;
      if (window.location.pathname.endsWith('analytics')) {
        return (
          <RedirectWithPrevState
            to={`/analytics-data/simulations/${props.data.readOneSimulation.ID}`}
          />
        );
      } else if (window.location.pathname.endsWith('videos')) {
        redirectURL += '/videos';
      } else if (window.location.pathname.endsWith('interactions')) {
        redirectURL += '/interactions';
      } else if (window.location.pathname.endsWith('overview-pages')) {
        redirectURL += '/overview-pages';
      }
      return <RedirectWithPrevState to={redirectURL} />;
    }
    return <WrappedComponent {...props} />;
  };
  SimulationPageRedirects.propTypes = {
    userID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    match: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
  };
  return SimulationPageRedirects;
};

export default WithSimulationPageRedirects;
