import { useMemo, useState } from 'react';
import LoadingPlaceholder, {
  MockIcon,
  MockText,
} from '../../../../LoadingPlaceholder';
import { MockItem } from '../SimpleUploaderItem/SimpleUploaderItem.styled';

const SimpleUploaderLoadingPlaceholder = () => {
  return (
    <LoadingPlaceholder>
      <MockItem>
        <MockText />
        <MockIcon />
      </MockItem>
    </LoadingPlaceholder>
  );
};

export default SimpleUploaderLoadingPlaceholder;
