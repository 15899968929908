import { useQuery, useReactiveVar } from '@apollo/client';
import UsageSignupsTable from '@core/components/Dashboard/DashboardUsagePanel/components/UsageSignupsTable';
import { Button, Loading, Tab, UrlParamTabs } from '@virtidev/toolbox';
import { addDays } from 'date-fns';
import { useCallback, useMemo } from 'react';
import {
  secondarySidebarContentVar,
  sidebarContractedVar,
} from '../../../../../../ApolloReactiveVars';
import useUser from '../../../helpers/useUser';
import { StyledSectionHeader } from '../QuickActions/QuickActions.styled';
import UsageActivityTable from './components/UsageActivityTable';
import UsageSubmissionsTable from './components/UsageSubmissionsTable';
import { DASHBOARD_USAGE } from './DashboardUsagePanel.query';
import {
  PanelContent,
  PanelTitle,
  UsageActivityPanel,
  UsageContainer,
  UsagePanel,
  UsageTypeContainer,
} from './DashboardUsagePanel.styled';

export const DashboardUsagePanel = ({ skip }) => {
  const { Organisation } = useUser();
  const sidebarContracted = useReactiveVar(sidebarContractedVar);
  const subContent = useReactiveVar(secondarySidebarContentVar);

  const now = new Date();

  const lastWeek = useMemo(
    () => addDays(now, -7).toISOString(),
    // only want this to update if there's a change in day
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [now.getMonth(), now.getDate()]
  );

  const {
    data: usageData,
    loading,
    refetch,
  } = useQuery(DASHBOARD_USAGE, {
    variables: {
      datetime: lastWeek,
      orgId: Organisation?.ID,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip,
  });

  const renew = useCallback(() => refetch(), [refetch]);

  if (!usageData && skip) {
    return null;
  }

  return (
    <>
      <StyledSectionHeader>
        System utilisation (in last 7 days){' '}
        <Button
          color="transparent-primary"
          onClick={renew}
          title="Reload session numbers"
          loading={loading}
          icon="loop"
        />
      </StyledSectionHeader>
      <UsageContainer>
        <UsageTypeContainer $contracted={sidebarContracted && !subContent}>
          <UsagePanel $top>
            <PanelTitle>Simulation sessions</PanelTitle>
            <PanelContent>
              {usageData ? (
                usageData.sessionsSim.pageInfo.totalCount
              ) : (
                <Loading />
              )}
            </PanelContent>
          </UsagePanel>
          <UsagePanel $top>
            <PanelTitle>Simulations completed</PanelTitle>
            <PanelContent>
              {usageData ? (
                usageData.completedSim.pageInfo.totalCount
              ) : (
                <Loading />
              )}
            </PanelContent>
          </UsagePanel>
          <UsagePanel $top>
            <PanelTitle>Virtual human sessions</PanelTitle>
            <PanelContent>
              {usageData ? (
                usageData.sessionsVH.pageInfo.totalCount
              ) : (
                <Loading />
              )}
            </PanelContent>
          </UsagePanel>
          <UsagePanel $top>
            <PanelTitle>Virtual humans completed</PanelTitle>
            <PanelContent>
              {usageData ? (
                usageData.completedVH.pageInfo.totalCount
              ) : (
                <Loading />
              )}
            </PanelContent>
          </UsagePanel>
        </UsageTypeContainer>
        <UsageActivityPanel $contracted={sidebarContracted && !subContent}>
          <UsagePanel>
            <UrlParamTabs fill>
              <Tab name="recent-activity" title="Recent activity">
                <UsageActivityTable data={usageData?.sessions?.nodes} />
              </Tab>
              <Tab name="form-submissions" title="Form submissions">
                <UsageSubmissionsTable data={usageData?.submissions?.nodes} />
              </Tab>
            </UrlParamTabs>
          </UsagePanel>
          <UsagePanel title="Sign ups">
            <UsageSignupsTable data={usageData?.signups?.nodes} />
          </UsagePanel>
        </UsageActivityPanel>
      </UsageContainer>
    </>
  );
};

export default DashboardUsagePanel;
