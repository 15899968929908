import { gql } from '@apollo/client';

export const ADD_VH_EDITOR = gql`
  mutation addVirtualHumanEditor($MemberID: ID!, $VirtualHumanID: ID!) {
    addVirtualHumanEditor(
      MemberID: $MemberID
      VirtualHumanID: $VirtualHumanID
    ) {
      ID
      Editors {
        edges {
          node {
            ID
            Name
          }
        }
      }
    }
  }
`;

export const REMOVE_VH_EDITOR = gql`
  mutation removeVirtualHumanEditor($MemberID: ID!, $VirtualHumanID: ID!) {
    removeVirtualHumanEditor(
      MemberID: $MemberID
      VirtualHumanID: $VirtualHumanID
    ) {
      ID
      Editors {
        edges {
          node {
            ID
            Name
          }
        }
      }
    }
  }
`;
