import styled from 'styled-components';

export const PercentTag = styled.strong`
  display: inline-block;
  font-size: 0.7rem;
  border: 1px solid;
  padding: 3px;
  border-radius: 5px;
  margin-left: 5px;
  color: #555;
`;

export default PercentTag;
