import { MockButton } from '@virtidev/toolbox';
import React from 'react';
import styled from 'styled-components';
import LoadingPlaceholder, {
  MockIcon,
  MockText,
} from '../../../LoadingPlaceholder';

export const StyledLoadingSubtitleItem = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 8.5fr 3fr 1.25fr 1.25fr;
  gap: ${({ theme }) => theme.spacing.md};
  place-items: center start;
`;

export default function SubtitlePlaceholder() {
  return (
    <LoadingPlaceholder
      style={{
        border: '2px solid #ccc',
      }}
    >
      <StyledLoadingSubtitleItem>
        <MockIcon />
        <MockText style={{ width: '100%' }} />
        <MockText style={{ width: '50%' }} />
        <MockButton style={{ width: '100%' }} />
        <MockButton style={{ width: '100%' }} />
      </StyledLoadingSubtitleItem>
    </LoadingPlaceholder>
  );
}
