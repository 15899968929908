import { gql } from '@apollo/client';

export const EDIT_SUBTITLES = gql`
  mutation updateSubtitle($input: UpdateSubtitleInput!) {
    updateSubtitle(input: $input) {
      ID
      URL
      Title
      Type
      Language
    }
  }
`;
