import { gql } from '@apollo/client';

export const READ_COURSE = gql`
  query readCourseDetails($ID: ID!) {
    readOneCourse(filter: { ID: { eq: $ID } }) {
      ID
      Title
      AdminTitle
      Description
      OrganisationID
      ImageMediaID
      PublishNotificationSent
      PublishNotificationCount
      ImageMedia {
        ID
        TusID
        URL
      }
      DueDate
      StartDate
      Unlocked
      OrganisationGroups {
        nodes {
          ID
          Name
        }
      }
      Resources {
        edges {
          node {
            ID
            Title
            Type
            Media {
              URL
            }
          }
        }
      }
      FeedbackFormBefore {
        Title
        ID
      }
      FeedbackFormAfter {
        Title
        ID
      }
      Tags {
        nodes {
          ID
          Name
        }
      }
    }
  }
`;

export const UPDATE_COURSE = gql`
  mutation updateCourseDetails($Input: UpdateCourseInput!) {
    updateCourse(input: $Input) {
      ID
      Title
      AdminTitle
      Description
      ImageMediaID
      ImageMedia {
        ID
        TusID
      }
    }
  }
`;
