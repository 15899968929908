import { useCallback, useState } from 'react';
import * as OfflinePluginRuntime from 'offline-plugin/runtime';
import { Box, CloseButton, Position, UpdateLink } from './OfflineUpdate.styled';
import useMounted from '../../helpers/useMounted';

export const OfflineUpdate = () => {
  const [loading, setLoading] = useState(false);
  const mounted = useMounted();

  const updateApp = useCallback(() => {
    OfflinePluginRuntime.applyUpdate();
    setLoading(true);

    setTimeout(() => {
      if (mounted.current) {
        // reset after 5 seconds
        setLoading(false);
      }
    }, 5000);
  }, [mounted]);

  const dismiss = useCallback(() => {
    document.body.classList.remove('virti-offline-update');
  }, []);

  return (
    <Position>
      <Box>
        Update available!
        <UpdateLink onClick={updateApp} loading={loading}>
          click here to update.
        </UpdateLink>
        <CloseButton onClick={dismiss} />
      </Box>
    </Position>
  );
};
