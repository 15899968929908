import { gql } from '@apollo/client';

export const READ_ORGANISATIONS_SIGNUP_DATA = gql`
  query readOrganisationsSignupData(
    $limit: Int!
    $offset: Int!
    $sort: OrganisationSortFields
    $name: String
  ) {
    readOrganisations(
      offset: $offset
      limit: $limit
      sort: $sort
      filter: {
        Trial: { eq: true }
        Archived: { eq: false }
        Name: { contains: $name }
      }
    ) {
      pageInfo {
        hasNextPage
        totalCount
      }
      edges {
        node {
          ID
          Name
          TrialExpiryDate
          Created
          Archived
          SignupType
          Members {
            edges {
              node {
                ID
                Name
                WelcomeCourseProgress
                OnboardProgress
              }
            }
          }
        }
      }
    }
  }
`;

export const LOGIN_AS_USER = gql`
  mutation createUserToken($ID: ID!) {
    createUserToken(ID: $ID) {
      ID
      Token
      PDToken
      Message
    }
  }
`;

export const UPDATE_ORGANISATION = gql`
  mutation updateOrganisation($Input: UpdateOrganisationInput!) {
    updateOrganisation(input: $Input) {
      ID
      TrialExpiryDate
      Archived
    }
  }
`;
