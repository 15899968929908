import styled from 'styled-components';

export const SectionWrapper = styled.div`
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const SectionTitle = styled.label`
  display: block;
  font-weight: 500;
  font-size: 1.1rem;
`;

export const SelectFieldsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5rem;
  width: 100%;
`;

export const SelectFieldWrapper = styled.div`
  display: flex;
  gap: 1rem;
  place-items: center;
  width: 100%;
`;
