import { useQuery } from '@apollo/client';
import { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { READ_ORGANISATIONS } from './SelectOrganisation.query';
import Select from '../../Select';
import _ from 'lodash';
import { useSelectFilter } from '../../helpers/useSelectFilter';

export const SelectOrganisation = ({
  options,
  loading,
  archived,
  ...props
}) => {
  const [filter, filterProps] = useSelectFilter();

  const { data: orgData, loading: orgLoading } = useQuery(READ_ORGANISATIONS, {
    variables: {
      filter,
      archived: archived ?? false,
    },
  });

  const organisations = useMemo(() => {
    if (!orgData) {
      return [];
    }
    return orgData.readOrganisations.edges.map(({ node }) => ({
      value: node.ID,
      label: node.Name,
    }));
  }, [orgData]);

  useEffect(() => {
    const { inputValue, ref } = filterProps;
    if (!orgLoading && inputValue) {
      ref.current.focus();
      ref.current.onMenuOpen();
    }
    // I do not want this to activate with inputValue change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgLoading]);

  const allOptions = useMemo(
    () => [...options, ...organisations],
    [options, organisations]
  );

  return (
    <Select
      {...props}
      {...filterProps}
      options={allOptions}
      loading={orgLoading || loading}
    />
  );
};

SelectOrganisation.defaultProps = {
  options: [],
  loading: false,
};

SelectOrganisation.propTypes = {
  options: PropTypes.arrayOf(PropTypes.any),
  loading: PropTypes.bool,
};

export default SelectOrganisation;
