import { forwardRef } from 'react';
import Page from '@core/templates/components/StandardPage/Page';

export const withPageTemplate =
  (pageProps = {}) =>
  (Component) => {
    const PageTemplate = forwardRef((props, ref) => (
      <Page ref={ref} {...pageProps}>
        <Component {...props} />
      </Page>
    ));

    return PageTemplate;
  };

export default withPageTemplate;
