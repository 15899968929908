import styled from 'styled-components';

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledLink = styled.a`
  color: var(--primary-color);
`;
