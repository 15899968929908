import { gql } from '@apollo/client';

export const DELETE_COURSES = gql`
  mutation deleteCourses($IDs: [ID]!) {
    deleteCourses(ids: $IDs)
  }
`;

export const DELETE_SIMULATIONS = gql`
  mutation deleteSimulations($IDs: [ID]!) {
    deleteSimulations(ids: $IDs)
  }
`;
