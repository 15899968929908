import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Titlebar } from '@core/components/Page/components/Titlebar';
import Page from '@core/templates/components/StandardPage/Page';
import { withPagePartContainer } from '@core/components/Page/PagePart.state';

const NoAccessPage = () => {
  return (
    <Page pageKey="no-access">
      <Titlebar>Forbidden</Titlebar>
      <div>
        No access to this page, you may need to refresh your login by{' '}
        <Link to="/logout">logging out</Link> and back in again.
      </div>
    </Page>
  );
};

export default withPagePartContainer(NoAccessPage);
