import {
  DragEvent,
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  formatFilesize,
  GetAcceptedFiles,
  IsAcceptedFile,
} from '@base/utility/FileUtility';
import {
  BoxContainer,
  BoxContent,
  BoxIcon,
  BoxSub,
  DraggingContent,
  DraggingOverlay,
} from '@core/components/Media/components/MediaUploaders/components/MediaUploadBox.styled';
import { useFlashMessage } from '@core/components/FlashMessage';
import { Icon } from '@virtidev/toolbox';
import useRefs from '@core/helpers/mergeRefs';

/**
 * @param {DragEvent<HTMLDivElement>} e
 */
const noDefault = (e) => {
  if (e) {
    e.preventDefault();
  }
};

export const MediaUploadBox = forwardRef(
  (
    {
      dimension,
      addFiles,
      acceptedTypes = ['*'],
      maxFileSize,
      name,
      multiple,
      ...props
    },
    ref
  ) => {
    const fileInput = useRef(/** @type {HTMLInputElement | null} */ (null));
    const [dragNum, setDragNum] = useState(0);
    const { addFlashMessage } = useFlashMessage();

    const acceptedFiles = useMemo(
      () => acceptedTypes.map(GetAcceptedFiles).join(','),
      [acceptedTypes]
    );

    const checkFiles = useCallback(
      (files) => {
        if (files.length > 1 && !multiple) {
          addFlashMessage('Cannot upload multiple files.', 'warning');
          return false;
        }

        for (const file of files) {
          if (
            !acceptedTypes.find(
              (type) => type === '*' || IsAcceptedFile(file.type, acceptedFiles)
            )
          ) {
            addFlashMessage(
              `Please provide valid ${acceptedTypes.join('/')} files.`,
              'warning'
            );
            return false;
          }

          if (maxFileSize && file.size > maxFileSize) {
            const size = formatFilesize(maxFileSize);
            addFlashMessage(
              `One or more of your files exceeded the maximum size of ${size}.`,
              'warning'
            );
            return false;
          }
        }

        return true;
      },
      [acceptedFiles, acceptedTypes, addFlashMessage, maxFileSize, multiple]
    );

    const handleDrop = useCallback(
      (e) => {
        noDefault(e);

        if (dragNum === 0 || !e.dataTransfer) {
          return;
        }

        setDragNum(0);
        if (fileInput.current) {
          fileInput.current.files = e.dataTransfer.files;
        }

        if (checkFiles(e.dataTransfer.files)) {
          addFiles(e.dataTransfer.files, dimension);
        }
      },
      [addFiles, checkFiles, dimension, dragNum]
    );

    const handleFileChange = useCallback(
      (e) => {
        if (e.target?.files && checkFiles(e.target.files)) {
          addFiles(e.target.files, dimension);
        }
      },
      [addFiles, checkFiles, dimension]
    );

    const handleDragEnter = useCallback((e) => {
      noDefault(e);
      setDragNum((prev) =>
        !!e?.dataTransfer?.items?.length ? prev + 1 : prev
      );
    }, []);

    const handleDragLeave = useCallback((e) => {
      noDefault(e);
      setDragNum((prev) => Math.max(prev - 1, 0));
    }, []);

    const openFilePicker = useCallback(() => {
      fileInput.current?.click?.();
    }, []);

    const fileExtensions = useMemo(
      () =>
        acceptedTypes.flatMap?.((type) => {
          if (type === 'video') {
            return ['mov', 'mp4'];
          }
          if (type === 'image') {
            return ['png', 'jpeg'];
          }
          if (type === 'audio') {
            return ['wav', 'mp3'];
          }
          return [];
        }),
      [acceptedTypes]
    );

    return (
      <BoxContainer
        {...props}
        data-testid="uploader"
        onDrag={noDefault}
        onDragOver={noDefault}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={openFilePicker}
        data-dimension={dimension}
      >
        <input
          multiple={multiple}
          ref={useRefs(fileInput, ref)}
          onChange={handleFileChange}
          type="file"
          name={name}
          style={{ display: 'none' }}
          accept={acceptedFiles}
        />
        {dragNum > 0 && (
          <DraggingOverlay>
            <DraggingContent>
              Drop your{' '}
              {dimension === '3d' ? <>360&#176; media</> : <>standard media</>}{' '}
              file here
            </DraggingContent>
          </DraggingOverlay>
        )}
        <BoxIcon>
          {dimension === '3d' ? <Icon icon="video-3d" size="50px" /> : null}
        </BoxIcon>
        <BoxContent>
          Upload{' '}
          {dimension === '3d' ? <>360&#176; media</> : <>standard media</>}
        </BoxContent>
        {fileExtensions && <BoxSub>{fileExtensions.join(', ')}</BoxSub>}
      </BoxContainer>
    );
  }
);

export default MediaUploadBox;
