import styled from 'styled-components';
import { TextInput } from '@virtidev/toolbox';

export const TextInputField = styled(TextInput)`
  border-radius: 25px;
  border-color: #757575;
  input {
    border-color: #757575;
    border-radius: 25px;
  }
`;

export const ModalLayout = styled.div`
  display: grid;
  grid-template-areas:
    '. top'
    'content content';
  grid-template-columns: 1fr auto;
  grid-template-rows: auto 1fr;
  width: 100%;
  height: 100%;
`;

export const ModalTopArea = styled.div`
  grid-area: top;
`;

export const ModalContentArea = styled.div`
  grid-area: content;
`;

export const ContentSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 1rem;
`;

export const DuplicateNameWrapper = styled.div`
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
`;
