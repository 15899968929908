import { Link, Icon } from './NavMenuLink.styled';

export const NavMenuLink = ({ icon, children, ...props }) => {
  return (
    <Link {...props}>
      <Icon icon={icon} type="outline" size="20px" />
      {children}
    </Link>
  );
};

export default NavMenuLink;
