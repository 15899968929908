import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LinkWithPrevState from './LinkWithPrevState';
import { ButtonLink } from './buttons/Button';
import StyledScorePercentage from '../styled-components/StyledScorePercentage';
import Breakpoints from '../themes/Breakpoints';
import { getResizeImage } from '../apps/core/src/helpers/imageResizing/getResizeImage';
import { Datetime, Loading } from '@virtidev/toolbox';

const StyledItem = styled.div`
  border-radius: var(--card-border-radius);
  background-color: var(--card-bg-color);
  box-shadow: var(--card-box-shadow);
  position: relative;
`;

const StyledImageLink = styled(LinkWithPrevState)`
  position: relative;
  display: block;
  &:hover {
    opacity: var(--image-link-opacity-hover);
  }
  &:active {
    opacity: var(--image-link-opacity-active);
  }
`;

const StyledTitleWrapper = styled.div`
  padding: 1.3rem 2rem;
  font-size: 1.4rem;
  border-bottom: var(--primary-border);
`;
const StyledTitle = styled.h3`
  color: var(--primary-font-color);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 0 3px 0;
  &:hover {
    text-decoration: underline;
  }
`;
const StyledSimulationState = styled.div`
  pointer-events: none;
  font-size: 1.2rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem 0.6rem;
  background-color: #000000cc;
  border-radius: 20px;
  span:first-child {
    margin-right: 0.5rem;
  }
`;

const SimStateIconWrapper = styled.span`
  ${({ theme, isPublished }) => css`
    background-color: ${isPublished
      ? theme.color.primary
      : theme.color.lightGrey};
    display: inline-block;
    border-radius: 60px;
    padding: 0.5rem;
  `}
`;

// TODO: redo this when images are in
const StyledThumbnail = styled.img`
  width: 100%;
  display: block;
  height: 229px;
  max-height: 229px;
  object-fit: cover;
  border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
`;

const StyledContent = styled.div`
  padding: 1rem;
  display: grid;
`;

const StyledDataWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;

const StyledDataHeader = styled.h5`
  text-align: center;
  font-size: 0.7rem;
  margin-bottom: 0.5rem;
  color: var(--tertiary-font-color);
  text-transform: uppercase;
`;

const StyledData = styled.div`
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
`;

const SimulationList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, calc(33.33333333% - 1.4rem));
  grid-gap: 2rem;
  @media (max-width: ${Breakpoints.large}) {
    grid-template-columns: repeat(2, calc(50% - 1.4rem));
  }
  @media (max-width: ${Breakpoints.med}) {
    grid-template-columns: 1fr;
  }
`;

const StyledAvgScoreWrapper = styled.div`
  grid-row: 1 / 3;
  grid-column: 2;
`;
const StyledAvgScore = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: 4rem;
  border-radius: 2rem;
  border: 0.4rem solid var(--high-avg-score-color);
  cursor: default;
  margin-top: 0.8rem;
  margin-left: auto;
  margin-right: auto;
  ${(props) =>
    props.score <= 50 &&
    css`
      border-color: var(--mid-avg-score-color);
    `}
  ${(props) =>
    props.score <= 20 &&
    css`
      border-color: var(--low-avg-score-color);
    `}
  ${(props) =>
    props.score === null &&
    css`
      border-color: var(--disabled-form-input-bg-color);
      cursor: help;
    `}
`;
const StyledLinksWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  > a:nth-child(1) {
    border-radius: 0 0 0 var(--card-border-radius);
  }
  > a:nth-child(2) {
    border-radius: 0 0 var(--card-border-radius) 0;
  }
`;

const StyledLastEdited = styled.div`
  color: var(--tertiary-font-color);
  margin-top: 0.7rem;
  font-size: 0.8rem;
`;

const ReStyledScorePercentage = styled(StyledScorePercentage)`
  font-weight: 500;
  font-size: 1.2rem;
`;
const SimulationState = (props) => {
  // show normal published/unpublished status unless there's something notable about transcoding status to show
  let statusText = props.data.Status;
  if (
    !statusText ||
    ['Failed', 'Transcoding', 'NoUpload'].includes(statusText)
  ) {
    statusText = props.data.TranscodingStatus;
  }
  if (statusText === 'Success') statusText = 'Unpublished';
  let icon = 'eye-slash';
  if (statusText === 'Published') icon = 'eye';
  if (statusText === 'Transcoding') icon = 'spinner';
  if (statusText === 'Failed' || statusText === 'NoUpload')
    icon = 'exclamation-triangle';
  return (
    // <div><Icon name=""></Icon> Published</div>
    <StyledSimulationState>
      <span>{statusText}</span>
      <SimStateIconWrapper isPublished={statusText === 'Published'}>
        {icon === 'spinner' ? <Loading /> : <FontAwesomeIcon icon={icon} />}
      </SimStateIconWrapper>
    </StyledSimulationState>
  );
};

const DataItem = (props) => (
  <div>
    <StyledDataHeader>{props.title}</StyledDataHeader>
    <StyledData>{props.data}</StyledData>
  </div>
);

const SimulationItem = ({ data }) => {
  const {
    ID,
    ImageMedia: { URL: ImageURL },
    FirstVideo: {
      nodes: [firstVideo],
    },
    Title,
    LastSaved,
    LastEdited,
    TotalUsers,
    TotalViews,
    TotalCompletions,
    Quiz,
    AverageScore,
    TranscodingStatus,
    ImageMedia,
  } = data;
  const { PosterURL } = firstVideo || {};

  const url = useMemo(() => {
    if (ImageMedia.TusID) {
      return getResizeImage(ImageMedia.TusID);
    }
    if (ImageURL) {
      return ImageURL;
    }
    return PosterURL;
  }, [ImageMedia, ImageURL, PosterURL]);

  return (
    <div>
      <StyledItem>
        <StyledImageLink to={`/simulations/${ID}`}>
          <StyledThumbnail aria-label={Title} alt={Title} src={url} />
        </StyledImageLink>
        <StyledTitleWrapper>
          <LinkWithPrevState to={`/simulations/${ID}`}>
            <StyledTitle>{Title}</StyledTitle>
          </LinkWithPrevState>
          <StyledLastEdited>
            Last Edited on{' '}
            <Datetime
              datetime={LastSaved || LastEdited}
              long
              noTimeDisplay
              emptyDisplay="-"
            />
          </StyledLastEdited>
          <SimulationState data={data} />
        </StyledTitleWrapper>
        <StyledContent>
          <StyledDataWrapper>
            <DataItem title="Users" data={TotalUsers} />
            <DataItem title="Views" data={TotalViews} />
            <DataItem title="Completions" data={TotalCompletions} />
            <div>
              <StyledDataHeader>Avg Score</StyledDataHeader>
              <ReStyledScorePercentage
                showNA={!Quiz}
                align="center"
                fallback="-"
                score={AverageScore}
              />
            </div>
          </StyledDataWrapper>
          {/* <StyledAvgScoreWrapper>
            <StyledDataHeader>Avg Score</StyledDataHeader>
            <StyledAvgScore title={AverageScore === null ? 'No scores have been recorded yet.' : ''} score={AverageScore}>{AverageScore ? AverageScore + '%' : '-'}</StyledAvgScore>
          </StyledAvgScoreWrapper> */}
        </StyledContent>
        <StyledLinksWrapper>
          <ButtonLink
            dataTestId="simulation-link"
            type="gridded-box"
            to={`/simulations/${ID}`}
          >
            Edit
          </ButtonLink>
          {TranscodingStatus !== 'Transcoding' && (
            <ButtonLink type="gridded-box" to={`/simulations/${ID}/analytics`}>
              Analytics
            </ButtonLink>
          )}
        </StyledLinksWrapper>
      </StyledItem>
    </div>
  );
};

SimulationItem.propTypes = {
  data: PropTypes.shape({
    ID: PropTypes.string,
    Title: PropTypes.string,
    Description: PropTypes.string,
    TotalViews: PropTypes.number.isRequired,
    Content360: PropTypes.bool.isRequired,
    Quiz: PropTypes.bool.isRequired,
  }),
};

export { SimulationList };
export default SimulationItem;
