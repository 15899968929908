import React, { useCallback, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import useGetVHData from '../../../../../utility/VHHooks/useGetVHData';
import {
  IndicatorWrapper,
  IndicatorLoader,
  IndicatorNumber,
} from './AITrainingTabCountIndicator.styled';
import { useInterval } from '../../utility/TimerHooks';

const AITrainingTabCountIndicator = ({ vhId }) => {
  const { data, loading, error, refetch } = useGetVHData(
    `virtual-humans/${vhId}/assessment-logs/count?isFallback=true&trainingComplete=false`
  );

  const handleUpdate = useCallback(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    window.document.addEventListener(
      'onLogTrainingCompleted',
      handleUpdate,
      false
    );
    return () => {
      window.document.removeEventListener(
        'onLogTrainingCompleted',
        handleUpdate,
        false
      );
    };
  }, [handleUpdate]);

  return (
    <IndicatorWrapper $loading={loading}>
      {loading ? (
        <IndicatorLoader />
      ) : (
        <IndicatorNumber>
          ({Math.min(99, data?.data?.data)}
          {data?.data?.data > 99 ? '+' : ''})
        </IndicatorNumber>
      )}
    </IndicatorWrapper>
  );
};

AITrainingTabCountIndicator.propTypes = { vhId: PropTypes.number.isRequired };

export default AITrainingTabCountIndicator;
