import { Select, Table } from '@virtidev/toolbox';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  h1 {
    font-size: 1.5rem;
  }
`;
export const EditorList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EditorListTableRow = styled.tr`
  width: 100%;
  ${({ $active, theme }) =>
    $active &&
    css`
      color: ${theme.color.contrast.turquoise10};
      background: ${theme.color.turquoise10};
    `}
`;

export const FiltersWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const EditorListItem = styled.button`
  display: block;
  width: 100%;
  ${(props) =>
    props.active &&
    css`
      background: white;
    `}
`;

export const ServiceSelect = styled(Select)`
  width: 200px;
`;
export const VhCxTable = styled(Table)`
  && td {
    padding: 4px;
  }
`;
export const SelectedItem = styled.div``;
export const ContentWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;

export const ListWrapper = styled.div`
  overflow-y: overlay;
  border: 1px solid black;
  max-height: 548px;
`;
