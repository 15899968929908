import React from 'react';
import CXDashboardTabNav from '../../../../../../../components/CXDashboardTabNav';
import WithCXDashboardPageTemplate from '../../../../../../../HOCs/templates/WithCXDashboardPageTemplate';
import CopyFeedbackForm from '../../../../components/Cx/CopyFeedbackForm/CopyFeedbackForm';

const CXCopyFeedbackFormPage = (props) => {
  return (
    <>
      <CXDashboardTabNav />
      <CopyFeedbackForm />
    </>
  );
};

export default WithCXDashboardPageTemplate(CXCopyFeedbackFormPage);
