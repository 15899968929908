import {
  BadgeCard,
  BadgeContent,
  BadgeDetail,
  BadgeIcon,
  BadgeTitle,
  IconBox,
} from '@core/components/Badge/Badge.styled';
import { Datetime } from '@virtidev/toolbox';

export const Badge = ({ title, created, unlocked }) => {
  return (
    <BadgeCard>
      <IconBox $unlocked={unlocked}>
        <BadgeIcon />
      </IconBox>
      <BadgeContent>
        <BadgeTitle>{title}</BadgeTitle>
        {unlocked && (
          <BadgeDetail>
            Awarded on: <Datetime datetime={created} long emptyDisplay="-" />
          </BadgeDetail>
        )}
      </BadgeContent>
    </BadgeCard>
  );
};

export default Badge;
