import { gql } from '@apollo/client';

export const CREATE_COURSE = gql`
  mutation createCourse($Input: CreateCourseInput!) {
    createCourse(input: $Input) {
      ID
      Title
      Description
    }
  }
`;

export const CREATE_UNIT = gql`
  mutation createUnit($Input: CreateUnitInput!) {
    createUnit(input: $Input) {
      ID
      Type
      SortOrder
      PassMark
      Assessment
      Simulation {
        ID
        Title
      }
      VirtualHuman {
        ID
        Title
      }
      Module {
        ID
        Units {
          edges {
            node {
              ID
            }
          }
        }
      }
    }
  }
`;
