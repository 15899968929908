import styled from 'styled-components';

export const LoadingSection = styled.div`
  background-color: var(--placeholder-background, #f1f0f0);
  overflow: hidden;

  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex: 1;
`;

export const LoadingContainer = styled(LoadingSection)`
  position: relative;

  margin: ${({ noMargin }) => (noMargin ? '' : '1rem 0rem')};
  border-radius: 12px;

  padding: 0 1rem 1rem;
  z-index: 0;
  flex: unset;
`;

export const LoadingFlash = styled.div`
  position: absolute;
  left: -45%;
  height: 100%;
  width: 45%;
  background-image: linear-gradient(
    to left,
    rgba(251, 251, 251, 0.05),
    rgba(251, 251, 251, 0.3),
    rgba(251, 251, 251, 0.6),
    rgba(251, 251, 251, 0.3),
    rgba(251, 251, 251, 0.05)
  );
  background-image: -moz-linear-gradient(
    to left,
    rgba(251, 251, 251, 0.05),
    rgba(251, 251, 251, 0.3),
    rgba(251, 251, 251, 0.6),
    rgba(251, 251, 251, 0.3),
    rgba(251, 251, 251, 0.05)
  );
  background-image: -webkit-linear-gradient(
    to left,
    rgba(251, 251, 251, 0.05),
    rgba(251, 251, 251, 0.3),
    rgba(251, 251, 251, 0.6),
    rgba(251, 251, 251, 0.3),
    rgba(251, 251, 251, 0.05)
  );
  animation: loading 1s infinite;
  z-index: 1;

  @keyframes loading {
    0% {
      left: -45%;
    }
    100% {
      left: 100%;
    }
  }
`;

export const MockImage = styled.div`
  width: 7rem;
  height: 4rem;
  background-color: var(--placeholder-color, #d8d8d8);
  margin: 1rem 0 0;
  border-radius: 8px;
`;

export const MockText = styled.div`
  flex: 1;
  height: 1rem;
  margin: 1rem 0 0;
  background-color: var(--placeholder-color, #d8d8d8);
  border-radius: 8px;
`;

export const MockIcon = styled.div`
  width: 1rem;
  height: 1rem;
  background-color: var(--placeholder-color, #d8d8d8);
  margin: 1rem 0 0;
  border-radius: 8px;
`;

export const MockButton = styled.div`
  width: 8rem;
  height: 2.2rem;
  background-color: var(--placeholder-color, #d8d8d8);
  margin: 1rem 0 0;
  border-radius: var(--input-border-radius, 25px);
`;
