import { gql } from '@apollo/client';

export const UPDATE_COURSE = gql`
  mutation updateCourseAccess($Input: UpdateCourseInput!) {
    updateCourse(input: $Input) {
      ID
      DueDate
      StartDate
      Unlocked
      PublishNotificationSent
      PublishNotificationCount
      NotifyUsers
      OrganisationGroups {
        nodes {
          ID
          Name
        }
      }
    }
  }
`;

// we want to rely more on the data returned by `updateCourseAccess` instead
export const UPDATE_COURSE_GROUPS = gql`
  mutation updateCourseAccessGroups($GroupIDs: [ID]!, $CourseID: ID!) {
    updateCourseGroups(GroupIDs: $GroupIDs, CourseID: $CourseID) {
      ID
    }
  }
`;

export const READ_GROUP_USERS_COUNT = gql`
  query readGroupUsersCount($GroupIDs: [ID]!) {
    readMembers(filter: { OrganisationGroups: { ID: { in: $GroupIDs } } }) {
      pageInfo {
        totalCount
      }
    }
  }
`;
