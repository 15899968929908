import { css } from 'styled-components';

export default css`
  &::-webkit-scrollbar {
    width: .7rem;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--scrollbar-bg-color);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-color);
  }
  /* firefox */
  scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-bg-color);
  scrollbar-width: thin;
`