import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useFlashMessage } from '../../../../../../FlashMessage';
import useUser from '../../../../../../../helpers/useUser';
import getConfig from '../../../../../../../../../../lib/config';
import SimpleUploaderItem from '../../../../../../Upload/SimpleUploader/components/SimpleUploaderItem/SimpleUploaderItem';

const VHAIFileItem = ({ file, onDelete }) => {
  const [loading, setLoading] = useState(false);
  const handleDelete = useCallback(() => {
    if (onDelete) {
      onDelete(file.id);
      // TODO: delete the file from vh server
    }
  }, [file, onDelete]);

  return (
    <SimpleUploaderItem
      title={file.name}
      downloadUrl={file.url}
      id={file.id}
      loading={loading}
      onDelete={handleDelete}
    />
  );
};

VHAIFileItem.propTypes = {
  file: PropTypes.object,
  onDelete: PropTypes.func,
};

export default VHAIFileItem;
