import React from 'react';
import SelectSimulation from '../../../form/Select/components/SelectSimulation/SelectSimulation';
import SelectSection from '../SelectSection/SelectSection';

export default function SimulationSection({
  getValues,
  handleAdd,
  handleRemove,
}) {
  const beforeField = `SimulationsBefore`;
  const afterField = `SimulationsAfter`;

  return (
    <SelectSection
      title={'Simulations'}
      selectBefore={
        <SelectSimulation
          value={getValues(beforeField)}
          multi
          onAdd={(value, label) => handleAdd(value, label, beforeField)}
          onRemove={(value, label) => handleRemove(value, label, beforeField)}
        />
      }
      selectAfter={
        <SelectSimulation
          multi
          value={getValues(afterField)}
          onAdd={(value, label) => handleAdd(value, label, afterField)}
          onRemove={(value, label) => handleRemove(value, label, afterField)}
        />
      }
    />
  );
}
