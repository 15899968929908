import { useMutation, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, TextInput } from '@virtidev/toolbox';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useFlashMessaging } from '../../../../../../HOCs/WithFlashMessaging';
import useUser from '../../../helpers/useUser';
import FieldContainer from '../../form/FieldContainer';
import FieldError from '../../form/FieldError';
import Label from '../../form/Label';
import { validateAPIURL } from '../../Registration/validation/validate-registration';
import {
  EDIT_ORGANISATION,
  READ_ORGANISATION_API,
} from './OrganisationAPI.query';
import { StyledHeader, StyledLink } from './OrganisationAPI.styled';

export default function OrganisationAPI() {
  const { Organisation } = useUser();
  const [initialised, setInitialised] = useState(false);
  const formMethods = useForm({
    resolver: zodResolver(validateAPIURL),
  });
  const { register, handleSubmit, reset } = formMethods;

  const { data, loading } = useQuery(READ_ORGANISATION_API, {
    variables: {
      ID: Organisation.ID,
    },
  });

  const { addFlashMessage } = useFlashMessaging();

  const [updateOrganisation, { loading: loadingMutation }] = useMutation(
    EDIT_ORGANISATION,
    {
      onCompleted: () => addFlashMessage('Endpoint updated!'),
      onError: (err) => {
        console.error(err);
        addFlashMessage(
          'Something went wrong, see console for details.',
          'error'
        );
      },
    }
  );

  const APIURL = useMemo(() => {
    if (data) {
      return data.readOneOrganisation.APIIncomingURL;
    } else {
      return null;
    }
  }, [data]);

  const onSubmit = useCallback(
    (e) => {
      console.log(e.Endpoint);
      updateOrganisation({
        variables: {
          Input: {
            ID: Organisation.ID,
            APIIncomingURL: e.Endpoint,
          },
        },
      });
    },
    [updateOrganisation, Organisation]
  );

  useEffect(() => {
    const defaultValues = {
      Endpoint: APIURL,
    };
    if (APIURL && !initialised) {
      reset(defaultValues);
      setInitialised(true);
    }
  }, [APIURL, reset, initialised]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FieldContainer>
          <StyledHeader>
            <Label>Organisation API Endpoint:</Label>
            <StyledLink
              href="https://help.virti.com/en/articles/6405571-completed-simulation-data"
              target="_blank"
              rel="noreferrer"
              alt="API help documentation"
            >
              View Documentation
            </StyledLink>
          </StyledHeader>
          <TextInput
            {...register('Endpoint')}
            disabled={loading || loadingMutation}
          />
          <FieldError name="Endpoint" />
        </FieldContainer>

        <div>
          <Button
            style={{ borderRadius: '22px', float: 'right' }}
            type="submit"
            disabled={loading || loadingMutation}
          >
            Save Endpoint
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
