import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './Vital.styled';
import TextLabel, { TextLabelTypes } from '../../atoms/TextLabel/TextLabel';
import Icon from '../../atoms/Icon/Icon';
import { vitalsDataObjects } from './VitalDisplayData';
import { useFeature } from '../../../../../core/src/components/LaunchDarkly';

const Vital = ({ vital }) => {
  const vhBigUiFeature = useFeature('vh-big-ui');
  const vitalDisplayDataObj = vitalsDataObjects[vital.type];
  return (
    <Styled.Wrapper $vhBigUiFeature={vhBigUiFeature}>
      <Styled.IconContainer
        color={
          vitalDisplayDataObj.colors.secondary
            ? vitalDisplayDataObj.colors.secondary
            : 'transparent'
        }
      >
        <Icon
          width="100%"
          height="100%"
          icon={vitalDisplayDataObj.icon}
          color={
            vitalDisplayDataObj.colors.primary
              ? vitalDisplayDataObj.colors.primary
              : '#111B32'
          }
        />
      </Styled.IconContainer>
      <Styled.DataContainer>
        <TextLabel type={TextLabelTypes.LARGE_DARK}>{vital.value}</TextLabel>
        <Styled.Metric
          color={
            vitalDisplayDataObj.colors.primary
              ? vitalDisplayDataObj.colors.primary
              : '#111B32'
          }
        >
          {vital ? vital.metric : ``}
        </Styled.Metric>
      </Styled.DataContainer>
      {vhBigUiFeature && <Styled.Title>{vital.title}</Styled.Title>}
    </Styled.Wrapper>
  );
};

Vital.propTypes = {
  vital: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string,
    metric: PropTypes.string,
    stateId: PropTypes.number,
    type: PropTypes.string,
    value: PropTypes.string,
    organisationId: PropTypes.number,
    normalValueMin: PropTypes.string,
    normalValueMax: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    isActive: PropTypes.bool,
    isDeleted: PropTypes.bool,
  }).isRequired,
};

export default Vital;
