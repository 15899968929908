import { useCallback, useEffect, useRef } from 'react';

export function useClickOutsideCallback(ref, callback, otherExceptions = []) {
  const instance = useRef({ callback });
  instance.current.callback = callback;

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        const otherExceptionNodes = otherExceptions.map((exception) =>
          document.querySelector(exception)
        );

        let dontRunCallback = false;
        otherExceptionNodes.forEach((exception) => {
          if (exception && exception.contains(event.target)) {
            dontRunCallback = true;
          }
        });

        if (!dontRunCallback) {
          instance.current.callback(event);
        }
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}

export function useClickOutsideOnElements(ref, callback, conditions = []) {
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      const conditionalNodes = conditions.map((condition) =>
        document.querySelector(condition)
      );
      let dontRunCallback = false;

      conditionalNodes.forEach((condition) => {
        if (condition && !condition.contains(event.target)) {
          dontRunCallback = true;
        }
      });
      if (!dontRunCallback) {
        callback(event);
      }
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}

export function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return useCallback(() => isMounted.current, []);
}
