import { useApolloClient, useQuery } from '@apollo/client';
import {
  LoadingPlaceholder,
  Select,
  SortableTh,
  Table,
  TextInput,
} from '@virtidev/toolbox';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import useUser from '../../../helpers/useUser';
import { useDebouncedSave } from '../../form/hooks/useDebouncedSave';
import { SelectGroup } from '../../form/Select';
import { usePageScroll } from '../../Page';
import PaginationControl from '../../PaginationControl';
import { usePaginationUrl } from '../../PaginationControl/helpers/usePaginationUrl';
import Actions from '../Actions/BulkActions';
import { READ_MEMBERS } from './UserTable.query';
import {
  FiltersWrapper,
  PaginationControlWrapper,
  TableWrapper,
} from './UserTable.styled';
import UserTableRow from './UserTableRow';
import useSortUrl from '../../../helpers/useSortUrl';
import { userTable as sortOptions } from '../../../helpers/sortCollections';

export default function UserTable() {
  const { OrganisationID } = useUser();
  const { scrollTop } = usePageScroll();
  const { sortField, sortDirection, updateSort } = useSortUrl(sortOptions);
  const [groupFilter, setGroupFilter] = useState(null);
  const [typedFilter, setTypedFilter] = useState('');
  const [disabledFilter, setDisabledFilter] = useState(false);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [listModified, setListModified] = useState(false);

  const { page, pageSize, controlProps, resetPage } = usePaginationUrl({
    onChange: scrollTop,
    pageSize: 20,
  });

  const debouncedProps = useDebouncedSave(typedFilter, {
    onUpdate: useCallback(
      (value) => {
        setTypedFilter(value);
        resetPage();
      },
      [setTypedFilter, resetPage]
    ),
    enter: true,
  });

  const variables = {
    limit: pageSize,
    offset: (page - 1) * pageSize,
    filter: {
      Organisations: { ID: { eq: OrganisationID } },
      ...(groupFilter?.value
        ? { OrganisationGroups: { ID: { eq: groupFilter?.value } } }
        : {}),
      Disabled: { eq: disabledFilter },
    },
    sort: {
      [sortField]: sortDirection,
    },
    Name: typedFilter,
    OrganisationID,
  };

  const { data, loading, error } = useQuery(READ_MEMBERS, {
    variables,
    onCompleted: () => setListModified(false),
  });

  const users = useMemo(() => {
    if (!data && loading) {
      return [...Array(20)];
    }
    return data?.readMembers?.edges || [];
  }, [data, loading]);

  const statusOptions = [
    { value: 'Active', label: 'Active' },
    { value: 'Suspended', label: 'Suspended' },
  ];
  const client = useApolloClient();

  useEffect(() => {
    if (listModified) {
      client.cache.evict({ id: `Organisation:${OrganisationID}` }); // this is to update the License count - there may be a better solution but this is a quick fix
    }
  }, [listModified, client.cache, OrganisationID]);

  const handleGroupFilter = useCallback(
    (value) => {
      setGroupFilter(value);
      resetPage();
    },
    [resetPage]
  );

  const handleStatusFilter = useCallback(
    (e) => {
      setSelectedIDs([]);
      resetPage();
      if (listModified) {
        client.cache.evict({ fieldName: 'readMembers' });
      }

      if (e.value === 'Suspended') {
        setDisabledFilter(true);
      }
      if (e.value === 'Active') {
        setDisabledFilter(false);
      }
    },
    [client, listModified, resetPage]
  );

  const handleSort = (sortField, sortDirection) => {
    updateSort(sortField, sortDirection);
    resetPage();
    if (listModified) {
      client.cache.evict({ fieldName: 'readMembers' });
    }
  };

  return (
    <TableWrapper>
      <FiltersWrapper>
        <TextInput
          placeholder="Search by name or email..."
          disabled={loading}
          {...debouncedProps}
        />

        <SelectGroup
          value={groupFilter}
          onChange={handleGroupFilter}
          placeholder="Filter by group..."
          disabled={loading}
          clearable
        />
        <Select
          onChange={handleStatusFilter}
          placeholder="Active"
          options={statusOptions}
        />

        {selectedIDs.length ? (
          <Actions
            selectedIDs={selectedIDs}
            setSelectedIDs={setSelectedIDs}
            disabledFilter={disabledFilter}
            variables={variables}
            setListModified={setListModified}
          />
        ) : (
          ''
        )}
      </FiltersWrapper>

      <Table>
        <thead>
          <tr>
            <th>Select</th>
            <SortableTh
              sortField="FirstName"
              onSort={handleSort}
              currentSort={{ sortField, sortDirection }}
            >
              Name
            </SortableTh>
            <SortableTh
              sortField="Email"
              onSort={handleSort}
              currentSort={{ sortField, sortDirection }}
            >
              Email
            </SortableTh>
            <th>User Groups</th>
            <th>User Type</th>
            <SortableTh
              sortField="LastSeen"
              onSort={handleSort}
              currentSort={{ sortField, sortDirection }}
              style={{ textAlign: 'center' }}
            >
              Last Seen
            </SortableTh>
            <th>License</th>
            <th style={{ textAlign: 'center' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((item, i) => {
            if (loading) {
              return (
                <tr key={i + 1}>
                  {[...Array(7)].map((_, j) => (
                    <td key={j + 1}>
                      <LoadingPlaceholder style={{ margin: 0 }} />
                    </td>
                  ))}
                </tr>
              );
            }
            const { node: user } = item;
            return (
              <UserTableRow
                key={user.ID}
                id={user.ID}
                user={user}
                selectedIDs={selectedIDs}
                setSelectedIDs={setSelectedIDs}
                variables={variables}
                disabledFilter={disabledFilter}
                setListModified={setListModified}
              />
            );
          })}
        </tbody>
      </Table>
      <PaginationControlWrapper>
        <PaginationControl
          {...controlProps}
          total={data?.readMembers?.pageInfo?.totalCount || 0}
        />
      </PaginationControlWrapper>
    </TableWrapper>
  );
}
