import { useApolloClient, useQuery } from '@apollo/client';
import {
  LoadingPlaceholder,
  Select,
  Table,
  TextInput,
} from '@virtidev/toolbox';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useUser from '../../../helpers/useUser';
import { useDebouncedSave } from '../../form/hooks/useDebouncedSave';
import { SelectOrganisation } from '../../form/Select';
import { usePageScroll } from '../../Page';
import PaginationControl from '../../PaginationControl';
import { usePaginationUrl } from '../../PaginationControl/helpers/usePaginationUrl';
import BulkActions from '../../Users/Actions/BulkActions';
import {
  FiltersWrapper,
  PaginationControlWrapper,
} from '../../Users/UserTable/UserTable.styled';
import ThSortable from './ThSortable/ThSortable';
import UserRow from './UserRow/UserRow';
import { READ_MEMBERS } from './UserTable.query';
import { TableWrapper } from './UserTable.styled';

export default function UserTable() {
  const { OrganisationID } = useUser();
  const { scrollTop } = usePageScroll();
  const [orgFilter, setOrgFilter] = useState(null);
  const [typedFilter, setTypedFilter] = useState('');
  const [disabledFilter, setDisabledFilter] = useState(false);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [listModified, setListModified] = useState(false);
  const [sortFilter, setSortFilter] = useState('Created');
  const [sortDirection, setSortDirection] = useState('ASC');

  const { page, pageSize, changePage, controlProps } = usePaginationUrl({
    onChange: scrollTop,
    pageSize: 20,
  });

  const debouncedProps = useDebouncedSave(typedFilter, {
    onUpdate: setTypedFilter,
    enter: true,
  });

  const variables = {
    limit: pageSize,
    offset: (page - 1) * pageSize,
    sort: { [sortFilter]: sortDirection },
    filter: {
      ...(orgFilter?.value
        ? { Organisations: { ID: { eq: orgFilter.value } } }
        : {}),
      Disabled: { eq: disabledFilter },
    },
    Name: typedFilter,
    OrganisationID,
  };

  const { data, loading, error } = useQuery(READ_MEMBERS, {
    variables,
    onCompleted: () => setListModified(false),
  });

  const users = useMemo(() => {
    if (!data && loading) {
      return [...Array(20)];
    }
    return data?.readMembers.edges;
  }, [data, loading]);

  const statusOptions = [
    { value: 'Active', label: 'Active' },
    { value: 'Suspended', label: 'Suspended' },
  ];
  const client = useApolloClient();

  useEffect(() => {
    if (listModified) {
      client.cache.evict({ id: `Organisation:${OrganisationID}` }); // this is to update the License count - there may be a better solution but this is a quick fix
    }
  }, [listModified, client.cache, OrganisationID]);

  const handleStatusFilter = useCallback(
    (e) => {
      setSelectedIDs([]);
      changePage(1);
      if (listModified) {
        client.cache.evict({ fieldName: 'readMembers' });
      }

      if (e.value === 'Suspended') {
        setDisabledFilter(true);
      }
      if (e.value === 'Active') {
        setDisabledFilter(false);
      }
    },
    [client, listModified, changePage]
  );

  const thSortProps = {
    setDirection: setSortDirection,
    direction: sortDirection,
    setCategory: setSortFilter,
    currentFilter: sortFilter,
  };

  return (
    <TableWrapper>
      <FiltersWrapper>
        <TextInput
          placeholder="Search by name or email..."
          {...debouncedProps}
        />

        <Select
          onChange={handleStatusFilter}
          placeholder="Active"
          options={statusOptions}
        />
        <SelectOrganisation
          value={orgFilter}
          onChange={setOrgFilter}
          placeholder="Filter by org"
          clearable
        />

        {selectedIDs.length ? (
          <BulkActions
            selectedIDs={selectedIDs}
            setSelectedIDs={setSelectedIDs}
            disabledFilter={disabledFilter}
            variables={variables}
            setListModified={setListModified}
            cxList={true}
          />
        ) : (
          ''
        )}
      </FiltersWrapper>

      <Table>
        <thead>
          <tr>
            <th>Select</th>
            <ThSortable category="FirstName" {...thSortProps}>
              Name
            </ThSortable>
            <ThSortable category="Email" {...thSortProps}>
              Email
            </ThSortable>
            <ThSortable category="Created" {...thSortProps}>
              Created
            </ThSortable>
            <th>User Orgs</th>
            <th>User Type</th>
            <ThSortable
              category="LastSeen"
              {...thSortProps}
              style={{ textAlign: 'center' }}
            >
              Last Seen
            </ThSortable>
            <th>License</th>
            <th style={{ textAlign: 'center' }}>Actions</th>
          </tr>
        </thead>
        <tbody style={{ fontSize: '0.9rem' }}>
          {users?.length === 0 && (
            <div style={{ marginTop: '40px' }}>No results.</div>
          )}
          {users?.map((item, i) => {
            if (loading) {
              return (
                <tr key={i + 1}>
                  {[...Array(9)].map((_, j) => (
                    <td key={j + 1}>
                      <LoadingPlaceholder style={{ margin: 0 }} />
                    </td>
                  ))}
                </tr>
              );
            }

            const { node: user } = item;
            return (
              <UserRow
                key={user.ID}
                id={user.ID}
                user={user}
                selectedIDs={selectedIDs}
                setSelectedIDs={setSelectedIDs}
                variables={variables}
                disabledFilter={disabledFilter}
                setListModified={setListModified}
              />
            );
          })}
        </tbody>
      </Table>
      <PaginationControlWrapper>
        <PaginationControl
          {...controlProps}
          total={data?.readMembers.pageInfo.totalCount}
        />
      </PaginationControlWrapper>
    </TableWrapper>
  );
}
