import { useQuery } from '@apollo/client';
import { READ_IMAGES } from '@core/components/AssetSelectModal/AssetSelectModal.query';
import { AssetContainer } from '@core/components/AssetSelectModal/AssetSelectModal.styled';
import ImageCard from '@core/components/AssetSelectModal/components/ImageCard';
import useUser from '@core/helpers/useUser';
import { Loading, PaginationControl } from '@virtidev/toolbox';
import { useCallback, useMemo, useState } from 'react';

export const AssetImageList = ({
  filter = '',
  selectedItems,
  onSelect,
  multiSelect,
  disabledIds,
}) => {
  const [page, setPage] = useState(1);
  const { OrganisationID } = useUser();
  const pageSize = 20;

  const { data, loading: imagesLoading } = useQuery(READ_IMAGES, {
    variables: {
      limit: pageSize,
      offset: (page - 1) * pageSize,
      sort: { Created: 'DESC', ID: 'DESC' },
      filter: {
        Title: { contains: filter },
        Organisation: { ID: { eq: OrganisationID } },
      },
    },
  });

  const images = useMemo(() => {
    if (!data?.readMedias) {
      return [];
    }

    return data.readMedias.nodes;
  }, [data]);

  const noImageMessage = useMemo(() => {
    if (images.length > 0 || imagesLoading) {
      return null;
    }

    if (!filter) {
      return 'No images found';
    }
    return `No images found with your search "${filter}".`;
  }, [images, imagesLoading, filter]);

  const handleSelect = useCallback(
    (id) => {
      if (!multiSelect) {
        const image = images.find(({ ID }) => ID === id);
        return onSelect([image]);
      }

      const newVideos = selectedItems.filter(({ ID }) => ID !== id);
      if (newVideos.length !== selectedItems.length) {
        return onSelect(newVideos);
      }

      const image = images.find(({ ID }) => ID === id);
      return onSelect([...selectedItems, image]);
    },
    [multiSelect, selectedItems, images, onSelect]
  );

  return (
    <>
      {imagesLoading && <Loading size="large" />}
      {noImageMessage}
      <AssetContainer>
        {images.map((image) => (
          <ImageCard
            key={image.ID}
            image={image}
            onSelect={handleSelect}
            disabled={disabledIds.includes(image.ID)}
            selected={!!selectedItems.find(({ ID }) => image.ID === ID)}
            multiSelect={multiSelect}
          />
        ))}
      </AssetContainer>
      <PaginationControl
        onPageChange={setPage}
        page={page}
        pageSize={pageSize}
        total={data?.readMedias?.pageInfo?.totalCount || 0}
      />
    </>
  );
};

export default AssetImageList;
