import { gql } from '@apollo/client';

export const READ_VIRTUAL_HUMANS = gql`
  query readVirtualHumans($filter: VirtualHumanFilterFields) {
    readVirtualHumans(filter: $filter, limit: 50) {
      edges {
        node {
          ID
          Title
          AdminTitle
          ThumbnailURL
          Avatar {
            ImageMedia {
              TusID
              URL
            }
          }
        }
      }
    }
  }
`;

export const READ_MEMBER_WITH_EDITABLE_VHS = gql`
  query readMemberWithEditableSims($ID: ID!, $limit: Int!, $offset: Int!) {
    readOneMember(filter: { ID: { eq: $ID } }) {
      ID
      EditableVirtualHumans(limit: $limit, offset: $offset) {
        pageInfo {
          totalCount
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            ID
            Title
            ThumbnailURL
          }
        }
      }
    }
  }
`;
