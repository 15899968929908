import { gql } from '@apollo/client';

export const DELETE_UNITS = gql`
  mutation deleteUnit($ids: [ID]!) {
    deleteUnits(ids: $ids)
  }
`;

export const MODULE_FRAGMENT = gql`
  fragment UnitModule on Module {
    ID
    Units {
      edges {
        node {
          ID
        }
      }
    }
  }
`;

export const UPDATE_ASSESSMENT = gql`
  mutation updateUnit($Input: UpdateUnitInput!) {
    updateUnit(input: $Input) {
      ID
      Assessment
    }
  }
`;

export const UPDATE_PASSMARK = gql`
  mutation updateUnit($Input: UpdateUnitInput!) {
    updateUnit(input: $Input) {
      ID
      PassMark
    }
  }
`;
