import { useApolloClient, useQuery } from '@apollo/client';
import { useMemo, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import WithOnboardingHandler from '../../../../../HOCs/WithOnboardingHandler';
import useStateUrl from '../../helpers/useStateUrl';
import useUser from '../../helpers/useUser';
import LoadingCourseCard from '../DataListCard/Loading/LoadingCourseCard';
import useFlashMessage from '../FlashMessage';
import { usePageScroll } from '../Page';
import PaginationControl from '../PaginationControl';
import { usePaginationUrl } from '../PaginationControl/helpers/usePaginationUrl';
import { ActionList } from './components/ActionList';
import CourseDataCard from './components/CourseDataCard';
import {
  CardListContainer,
  Content,
  ContentBody,
  EmptyCard,
  PagesContainer,
} from '@core/components/CardList/CardList.styled';
import { READ_COURSES } from './coursesList.query';
import getConfig from '../../../../../lib/config';
import { ActionBar } from '../form/ActionBar/ActionBar.styled';
import useSortUrl from '../../helpers/useSortUrl';
import { createdTitle } from '../../helpers/sortCollections';
import tracker from '../../helpers/tracker';

export const CourseList = ({ updateProgress, handleTourStart }) => {
  const history = useHistory();
  const { scrollTop } = usePageScroll();
  const { OrganisationID } = useUser();
  const { addFlashMessage } = useFlashMessage();
  const client = useApolloClient();

  const { value: titleFilter } = useStateUrl({});
  const { sortField, sortDirection } = useSortUrl(createdTitle);

  const { value: tags } = useStateUrl({
    key: 'tag',
  });

  const { page, pageSize, changePage, controlProps } = usePaginationUrl({
    onChange: scrollTop,
  });

  const variables = useMemo(() => {
    /** @type {object} */
    let fieldFilters = {
      Organisation: { ID: { eq: OrganisationID } },
      TitleOrAdminTitle: { contains: titleFilter || '' },
    };

    if (tags) {
      fieldFilters = {
        ...fieldFilters,
        Tags: {
          ID: { in: tags.split(',').map((id) => (id === '0' ? null : id)) },
        },
      };
    }

    return {
      limit: pageSize,
      offset: (page - 1) * pageSize,
      filter: fieldFilters,
      sort: {
        [sortField]: sortDirection,
      },
    };
  }, [
    page,
    pageSize,
    titleFilter,
    OrganisationID,
    sortField,
    sortDirection,
    tags,
  ]);

  const { data, loading } = useQuery(READ_COURSES, {
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const filterExists = useMemo(
    () => Boolean(titleFilter || tags),
    [titleFilter, tags]
  );

  const courses = useMemo(() => {
    if (!data) {
      return [...Array(pageSize)];
    }
    return data.readCourses.edges.map(({ node }) => node);
  }, [data, pageSize]);

  const handleCreateCourse = useCallback(
    (course) => {
      addFlashMessage(`Course '${course.Title}' successfully created!`);
      updateProgress('add_course', course.ID);
      tracker.track('course_created', {
        course_id: course.ID,
      });
      // forces the courses pagination to refresh
      client.cache.evict({ fieldName: 'readCourses' });

      // redirect to new course
      setTimeout(() => history.push(`/courses/${course.ID}`));
    },
    [addFlashMessage, history, updateProgress, client.cache]
  );

  const handleDeleteCourse = useCallback(
    (deletedCourse) => {
      const cacheData = client.cache.readQuery({
        query: READ_COURSES,
        variables,
      });

      addFlashMessage(
        `Course '${deletedCourse.Title || 'Untitled'}' successfully deleted!`
      );

      // forces the courses pagination to refresh
      client.cache.evict({ fieldName: 'readCourses' });

      // move back a page if none left on current page
      if (cacheData?.readCourses?.edges?.length === 1 && page > 1) {
        changePage(page - 1);
      }
    },
    [variables, changePage, page, addFlashMessage, client.cache]
  );

  return (
    <Content>
      <ActionBar>
        <ActionList onCreateCourse={handleCreateCourse} />
      </ActionBar>
      {!loading && !courses.length && (
        <EmptyCard>
          {filterExists
            ? `No courses found – try adjusting your search!`
            : 'Create your first course!'}
        </EmptyCard>
      )}
      <ContentBody>
        <CardListContainer>
          {courses.map((course, index) =>
            loading || !course ? (
              <LoadingCourseCard key={index} />
            ) : (
              <CourseDataCard
                key={course.ID}
                course={course}
                onDelete={handleDeleteCourse}
                editTags
              />
            )
          )}
        </CardListContainer>
      </ContentBody>
      <PagesContainer>
        <PaginationControl
          {...controlProps}
          total={data?.readCourses?.pageInfo?.totalCount || 0}
        />
      </PagesContainer>
    </Content>
  );
};

export default WithOnboardingHandler(CourseList);
