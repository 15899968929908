import { useApolloClient, useMutation } from '@apollo/client';
import {
  Button,
  ConfirmationModal,
  Select,
  TextInput,
  ToggleSwitch,
} from '@virtidev/toolbox';
import React, { useCallback, useEffect, useState } from 'react';
import Label from '../../../../../../../../../../styled-components/StyledLabel';
import { useFlashMessage } from '../../../../../../FlashMessage';
import {
  DELETE_VOICES,
  READ_VOICES,
  UPDATE_VOICE,
} from '../../CxVoicesEditor.query';
import { genderOptions } from '../../../../VHGenderSelector.data';
import useGetVHData from '../../../../../../../../../../utility/VHHooks/useGetVHData';
import { serviceOptions } from '@core/components/Cx/VirtualHuman/components/CxVoicesEditor/ServiceOptions';

const SelectedVoiceEditor = ({ selectedVoice }) => {
  const [voiceFeatured, setVoiceFeatured] = useState(false);
  const [voiceDeprecated, setVoiceDeprecated] = useState(false);
  const [voiceName, setVoiceName] = useState('');
  const [voiceCode, setVoiceCode] = useState('');
  const [voiceAge, setVoiceAge] = useState('');
  const [voiceGender, setVoiceGender] = useState(genderOptions[0]);
  const [voiceService, setVoiceService] = useState(serviceOptions[0]);
  const [Locale, setLocale] = useState('');
  const { addFlashMessage } = useFlashMessage();
  const [updateVoiceMutation] = useMutation(UPDATE_VOICE, {
    onCompleted: () => {
      addFlashMessage('Updated voice');
    },
    onError: () => {
      addFlashMessage('Failed to update voice', 'error');
    },
  });

  useEffect(() => {
    setVoiceDeprecated(selectedVoice.Deprecated);
    setVoiceName(selectedVoice.Name);
    setVoiceCode(selectedVoice.Code);
    setVoiceAge(selectedVoice.Age ?? '');
    setVoiceService(
      serviceOptions.find((option) => option.value === selectedVoice.Service) ??
        serviceOptions[0]
    );
    setLocale(selectedVoice.Locale);
    setVoiceFeatured(selectedVoice.Featured);
    setVoiceGender(
      genderOptions.find((option) => option.value === selectedVoice.Gender) ??
        genderOptions[0]
    );
  }, [selectedVoice]);

  const updateVoice = useCallback(
    ({
      newVoiceServiceValue,
      newFeaturedVoiceValue,
      newVoiceGenderValue,
      newDeprecatedVoiceValue,
    }) => {
      const Service =
        newVoiceServiceValue?.value ?? voiceService?.value ?? serviceOptions[0];
      const Gender =
        newVoiceGenderValue?.value ?? voiceGender?.value ?? genderOptions[0];
      const Featured = newFeaturedVoiceValue ?? voiceFeatured;
      const Deprecated = newDeprecatedVoiceValue ?? voiceDeprecated;
      if (
        voiceName !== selectedVoice.Name ||
        voiceCode !== selectedVoice.Code ||
        voiceAge !== selectedVoice.Age ||
        Locale !== selectedVoice.Locale ||
        Featured !== selectedVoice.Featured ||
        Deprecated !== selectedVoice.Deprecated ||
        voiceGender?.value !== Gender ||
        (voiceService?.value && voiceService?.value !== Service)
      ) {
        updateVoiceMutation({
          variables: {
            input: {
              ID: selectedVoice.ID,
              Name: voiceName,
              Code: voiceCode,
              Age: voiceAge,
              Service,
              Locale,
              Featured,
              Gender,
              Deprecated,
            },
          },
        });
      }
    },
    [
      selectedVoice?.ID,
      voiceAge,
      voiceGender,
      voiceCode,
      voiceName,
      voiceService,
      voiceFeatured,
      selectedVoice.Deprecated,
      voiceDeprecated,
      Locale,
      updateVoiceMutation,
      selectedVoice.Code,
      selectedVoice.Name,
      selectedVoice.Locale,
      selectedVoice.Featured,
      selectedVoice.Age,
    ]
  );

  const handleVoiceGenderChange = useCallback(
    (newGender) => {
      setVoiceGender(newGender);
      updateVoice({ newVoiceGenderValue: newGender });
    },
    [updateVoice]
  );

  const handleVoiceLocaleChange = useCallback((e) => {
    setLocale(e.target.value);
  }, []);

  const handleDeprecatedChange = useCallback(
    (e) => {
      setVoiceFeatured(e.target.checked);
      updateVoice({ newDeprecatedVoiceValue: e.target.checked });
    },
    [updateVoice]
  );

  const handleFeaturedChange = useCallback(
    (e) => {
      setVoiceFeatured(e.target.checked);
      updateVoice({ newFeaturedVoiceValue: e.target.checked });
    },
    [updateVoice]
  );

  const handleVoiceAgeChange = useCallback((e) => {
    setVoiceAge(e.target.value);
  }, []);

  const handleVoiceCodeChange = useCallback((e) => {
    setVoiceCode(e.target.value);
  }, []);

  const handleVoiceNameChange = useCallback((e) => {
    setVoiceName(e.target.value);
  }, []);

  const handleVoiceServiceChange = useCallback(
    async (newService) => {
      setVoiceService(newService);
      updateVoice({ newVoiceServiceValue: newService });
    },
    [updateVoice]
  );

  const [showConfirm, setShowConfirm] = useState(false);
  const onClickDelete = useCallback(() => {
    setShowConfirm(true);
  }, []);

  const [deleteVoice, { isLoading: isDeleting }] = useMutation(DELETE_VOICES, {
    refetchQueries: [{ query: READ_VOICES }],
  });

  const client = useApolloClient();
  const onCloseConfirm = useCallback(async () => {
    await deleteVoice({ variables: { ids: [selectedVoice.ID] } });
    client.cache.evict({ fieldName: 'readVoices' });
  }, [deleteVoice, client, selectedVoice?.ID]);

  const { data, loading, error } = useGetVHData(
    `tts-preview?voice=${voiceService.value.replace(
      '_streaming',
      ''
    )}_${voiceCode}&languageCode=${Locale}`,
    {
      skip: !Locale || !voiceCode || !voiceService,
    }
  );
  const currentAudioPreviewSrc = data?.data?.data?.file?.url;

  return (
    <div>
      <h2>Selected Voice</h2>
      <div>
        <Label htmlFor="voice-name">Name</Label>
        <TextInput
          onChange={handleVoiceNameChange}
          value={voiceName}
          id="voice-name"
          onBlur={updateVoice}
        />
      </div>
      <div>
        <Label htmlFor="voice-code">Code</Label>
        <TextInput
          onChange={handleVoiceCodeChange}
          value={voiceCode}
          id="voice-code"
          onBlur={updateVoice}
        />
      </div>
      <div>
        <Label htmlFor="locale">Locale</Label>
        <TextInput
          onChange={handleVoiceLocaleChange}
          value={Locale}
          id="locale"
          onBlur={updateVoice}
        />
      </div>
      <div>
        <Label htmlFor="age">Age</Label>
        <TextInput
          onChange={handleVoiceAgeChange}
          value={voiceAge}
          id="age"
          onBlur={updateVoice}
        />
      </div>
      <div>
        <Label htmlFor="voice-code">Gender</Label>
        <Select
          id="voice-gender"
          value={voiceGender}
          onChange={handleVoiceGenderChange}
          options={genderOptions}
        />
      </div>
      <div>
        <Label htmlFor="featured">Featured Voice (Popular)</Label>
        <ToggleSwitch
          name="featured"
          checked={voiceFeatured}
          onChange={handleFeaturedChange}
        />
      </div>
      <div>
        <Label htmlFor="deprecated">Deprecated</Label>
        <ToggleSwitch
          name="deprecated"
          checked={voiceDeprecated}
          onChange={handleDeprecatedChange}
        />
      </div>
      <div>
        <Label htmlFor="voice-code">Service</Label>
        <Select
          id="voice-code"
          value={voiceService}
          onChange={handleVoiceServiceChange}
          options={serviceOptions}
        />
      </div>
      <div>
        <Label>Preview</Label>
        <audio src={currentAudioPreviewSrc} controls={true} />
      </div>
      <ConfirmationModal
        show={showConfirm}
        onConfirm={onCloseConfirm}
        verificationText={selectedVoice?.Name ?? ''}
        loading={isDeleting}
      >
        DANGER: Make sure this Voice is not used by ANY virtual humans before
        deletion.
      </ConfirmationModal>
      <Button color="danger" icon="alert" onClick={onClickDelete}>
        Delete
      </Button>
    </div>
  );
};

export default SelectedVoiceEditor;
