import { useQuery } from '@apollo/client';
import _ from 'lodash';
import CX_ANALYTICS from '../cxAnalytics.query';
import { StyledWrapper } from '../CXAnalytics.styled';

import BarChartActiveUsersHighstock from '../../../../../../components/charts/BarChartActiveUsersHighstock';

export const DailyActiveUsers = () => {
  const { data, loading, error } = useQuery(CX_ANALYTICS);
  const DAU = _.get(data, 'cxAnalytics.DAU');

  if (!DAU) {
    return null;
  }
  return (
    <StyledWrapper>
      <BarChartActiveUsersHighstock AU={DAU} title="Daily Active Users" />
    </StyledWrapper>
  );
};

export default DailyActiveUsers;
