import styled, { css, DefaultTheme, useTheme, keyframes } from 'styled-components';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import { Tooltip } from '@virtidev/toolbox';

export const itemContainerCss = css`
  ${
    /**
     * @param {{ theme: DefaultTheme, $faded?: boolean }} p
     */
    ({ theme: { color, fontFamily }, $faded }) => css`
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 26px;
      padding: 19px 35px;
      width: var(--sidebar-width-expanded);
      transition: padding 300ms, width 300ms;
      white-space: nowrap;
      cursor: pointer;

      background: transparent;
      color: inherit;
      font-family: ${fontFamily};
      font-size: 1rem;
      border: 0;

      &.active {
        ${$faded
          ? css`
              background: ${color.midnight5};
              color: ${color.blue30};

              &:hover {
                background: ${color.midnight10};
                color: ${color.blue30};
              }
            `
          : css`
              background: ${color.pink10};
              color: ${color.contrast.pink10};

              &:hover {
                background: ${color.pink20};
                color: ${color.contrast.pink20};
              }
            `}
      }

      &:active,
      &.active:active {
        background: ${color.pink20};
        color: ${color.contrast.pink20};
      }

      &:hover {
        background: ${color.midnight5};
        color: ${color.blue30};
      }

      .sidebar-contracted & {
        padding: 19px 25px;
        width: var(--sidebar-width-contracted);
      }
      .sidebar-hover.sidebar-contracted & {
        padding: 19px 35px;
        width: var(--sidebar-width-expanded);
      }
    `
  }
`;

export const itemContentCss = css`
  flex: 1;
  text-align: start;
  width: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LogoSvg = styled(SVG)`
  position: absolute;
  top: 32px;
  height: auto;
  box-sizing: border-box;

  &.small-logo {
    left: 38px;
    width: 20px;
    opacity: 0;

    .sidebar-contracted & {
      left: 27px;
      opacity: 100;
    }

    .sidebar-hover.sidebar-contracted & {
      left: 38px;
      opacity: 0;
    }
  }

  &.big-logo {
    left: 38px;
    width: 130px;
    opacity: 100;

    .sidebar-contracted & {
      left: 27px;
      opacity: 0;
    }

    .sidebar-hover.sidebar-contracted & {
      left: 38px;
      opacity: 100;
    }
  }
  transition: opacity 300ms, left 300ms;
`;

export const LogoLink = styled(Link)`
  display: block;
  margin-bottom: 20px;
  position: relative;
  padding-top: 90px;
  width: 200px;
`;

export const Separator = styled.div`
  margin: 20px;
  border-top: 1px solid #eee;
`;

const closeSidebar = keyframes`
  0% {
    width: var(--sidebar-width-expanded);
  }
  100% {
    width: var(--sidebar-width-contracted);
  }
`;

export const Content = styled.div`
  ${
    /**
     * @param {{ theme: DefaultTheme, $inviteShow?: boolean }} p
     */
    ({ theme: { color }, $inviteShow }) => css`
      position: fixed;
      top: 0;
      left: 0;
      bottom: calc(((19px * 2) + 32px) * ${$inviteShow ? 2 : 1});
      height: calc(100vh - ((19px * 2) + 32px) * ${$inviteShow ? 2 : 1})

      overflow-x: hidden;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: stretch;

      background: ${color.midnight};
      color: ${color.blue30};

      z-index: 3;

      transition: width 300ms;

      width: var(--sidebar-width-expanded);

      .sidebar-contracted & {
        width: var(--sidebar-width-contracted);
        scrollbar-color: transparent transparent;
        scrollbar-width: none;
      }
      .sidebar-hover.sidebar-contracted & {
        width: var(--sidebar-width-expanded);
        scrollbar-color: unset;
        scrollbar-width: unset;
        overflow-x: hidden;
      }
      .sidebar-contracted.sidebar-force-closed &,
      .sidebar-hover.sidebar-contracted.sidebar-force-closed & {
        animation: ${closeSidebar} 300ms linear 1;
      }
    `
  }
`;

export const Container = styled.div`
  ${
    /**
     * @param {{ theme: DefaultTheme, $contracted?: boolean }} p
     */
    ({ theme: { color }, $contracted }) => css`
      position: relative;
      overflow: visible;
      min-height: 100%;
      height: 100%;
      z-index: 1004;
      background: ${color.midnight};
      color: ${color.contrast.midnight};
      min-width: ${$contracted
        ? 'var(--sidebar-width-contracted)'
        : 'var(--sidebar-width-expanded)'};
      transition: min-width 300ms;
    `
  }
`;

export const Spacer = styled.div`
  min-height: 70px;
  min-width: 70px;
  flex: 1;
`;

export const HelpItemWrapper = styled.div`
  ${({ theme: { color } }) => css`
    position: fixed;
    bottom: -1px;
    left: 0;
    width: var(--sidebar-width-expanded);
    transition: width 300ms;
    border-top: 1px solid ${color.midnight10};
    background: ${color.midnight};
    padding-bottom: 16px;

    .sidebar-contracted & {
      width: var(--sidebar-width-contracted);
    }

    .sidebar-hover.sidebar-contracted & {
      width: var(--sidebar-width-expanded);
    }
    .sidebar-contracted.sidebar-force-closed &,
    .sidebar-hover.sidebar-contracted.sidebar-force-closed & {
      animation: ${closeSidebar} 300ms linear 1;
    }
  `}
`;

export const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ToggleLabel = styled.div`
  max-width: 0;
  overflow: hidden;
  transition: max-width 300ms;
`;

export const ToggleExpand = styled.button`
  ${({ theme: { color } }) => css`
    ${itemContainerCss}
    position:relative;
    justify-content: flex-end;
    align-self: flex-end;
    right: 0;
    padding: 19px 20px;
    color: ${color.blue30};

    :hover ${ToggleLabel} {
      max-width: var(--sidebar-width-expanded);
    }

    .sidebar-contracted & {
      padding: 19px 20px;
    }
    .sidebar-hover.sidebar-contracted & {
      padding: 19px 20px;
    }
  `}
`;
