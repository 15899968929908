import LinkWithPrevState from '@base/components/LinkWithPrevState';
import { Card, Image } from '@virtidev/toolbox';
import styled, { css } from 'styled-components';

export const LearnerCard = styled(Card).attrs({ noPadding: true })`
  ${({ theme: { spacing } }) => css`
    position: relative;
    gap: ${spacing.sm};
    padding-bottom: ${spacing.lg};
  `}
`;

export const ThumbnailLink = styled(LinkWithPrevState)`
  position: relative;
  display: block;
  aspect-ratio: 16 / 9;
  &:hover {
    opacity: var(--image-link-opacity-hover);
  }
  &:active {
    opacity: var(--image-link-opacity-active);
  }
`;

export const Thumbnail = styled(Image).attrs({ cover: true })`
  display: block;
  aspect-ratio: 16 / 9;
`;

export const CardTitle = styled.h3`
  ${({ theme: { spacing, fontFamilyTitle, fontSize } }) => css`
    padding: 0 ${spacing.lg};
    font-size: ${fontSize.lg};
    font-family: ${fontFamilyTitle};

    // for limiting the text wrapping
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    height: 3.6rem;

    &:hover {
      text-decoration: underline;
    }
  `}
`;

export const ProgressBlock = styled.div`
  ${({ theme: { spacing, fontSize } }) => css`
    display: flex;
    padding: 0 ${spacing.lg};
    font-size: ${fontSize.sm};
    gap: ${spacing.xs};
    flex-direction: row;
  `}
`;

export const ProgressLabel = styled.span`
  ${({ theme: { color } }) => css`
    color: ${color.midnight10};
  `}
`;

export const ProgressValue = styled.span`
  ${({ theme: { color, fontWeight } }) => css`
    font-weight: ${fontWeight.bold};
  `}
`;
