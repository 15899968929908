import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tab, TabNav } from '../styled-components/StyledTabNav';

const UserTabNav = (props) => {
  const path = window.location.pathname.split('/');
  return (
    <React.Fragment>
      <TabNav>
        <Tab
          isActive={!window.location.pathname.includes('analytics')}
          to={`/users/${props.userID}`}
        >
          Details
        </Tab>
        <Tab
          isActive={path[path.length - 1] === 'analytics'}
          to={`/users/${props.userID}/analytics`}
        >
          Video Sim Analytics
        </Tab>
        <Tab
          isActive={path[path.length - 1] === 'virtual-humans'}
          to={`/users/${props.userID}/analytics/virtual-humans`}
        >
          Virtual Human Analytics
        </Tab>
      </TabNav>
    </React.Fragment>
  );
};

UserTabNav.propTypes = {
  userID: PropTypes.string.isRequired,
};

export default UserTabNav;
