import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import withFlashMessaging from '../HOCs/WithFlashMessaging';
import { useMutation, gql } from '@apollo/client';
import WithConfirmationBox from '../HOCs/WithConfirmationBox';
import styled from 'styled-components';
import {
  StyledSections,
  StyledSection,
  StyledSectionInner,
} from '../styled-components/StyledFormStructure';
import { LOGOUT_ALL } from '../queries/UserQueries';
import { Button } from '@virtidev/toolbox';

const OverriddenStyledSectionInner = styled(StyledSectionInner)`
  text-align: right;
`;

const StyledLogoutAllDevicesButton = styled(Button)``;

const SignOutAllDevicesForm = ({ confirm, addFlashMessage }) => {
  const [signOut, { loading }] = useMutation(LOGOUT_ALL, {
    onCompleted: (data) => {
      window.location = '/logout';
    },
    onError: (data) => {
      addFlashMessage('Failed to sign out of other devices', 'error');
    },
    notifyOnNetworkStatusChange: true,
  });
  const actionSignOut = useCallback(() => {
    confirm(
      () => {
        signOut();
      },
      null,
      '<p>Are you sure you wish to log out of all devices?</p><p><strong>Note: This will log you out and will require you to log back in.</strong></p>'
    );
  }, [confirm, signOut]);

  return (
    <StyledSections>
      <StyledSection>
        <OverriddenStyledSectionInner>
          <Button
            color="turquoise"
            design="outline"
            onClick={actionSignOut}
            loading={loading}
          >
            Log me out from all devices
          </Button>
        </OverriddenStyledSectionInner>
      </StyledSection>
    </StyledSections>
  );
};

SignOutAllDevicesForm.propType = {
  addFlashMessage: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
};

export default WithConfirmationBox(withFlashMessaging(SignOutAllDevicesForm));
