import { useMutation } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import useUser from '../../../helpers/useUser';
import useFlashMessage from '../../FlashMessage';
import { SelectOrganisation } from '../../form/Select';
import SelectFeedbackForm from '../../form/Select/components/SelectFeedbackForm/SelectFeedbackForm';
import { COPY_FEEDBACK_FORM } from './CopyFeedbackForm.query';
import {
  StyledButton,
  StyledTitle,
  StyledWrapper,
} from './CopyFeedbackForm.styled';

export default function CopyFeedbackForm() {
  const { Organisation } = useUser();
  const [selectedOrg, setSelectedOrg] = useState({
    value: Organisation.ID,
    label: Organisation.Name,
  });
  const [selectedFeedbackForm, setSelectedFeedbackForm] = useState(null);
  const [selectedTargetOrg, setSelectedTargetOrg] = useState(null);

  const { addFlashMessage } = useFlashMessage();

  const [copyFeedbackForm, { loading }] = useMutation(COPY_FEEDBACK_FORM, {
    variables: {
      FormID: selectedFeedbackForm?.value,
      OrganisationID: selectedTargetOrg?.value,
    },
    onCompleted: () => {
      addFlashMessage(
        `Successfully copied ${selectedFeedbackForm.label} to ${selectedTargetOrg.label}`
      );
    },
    onError: (err) => {
      addFlashMessage('Something went wrong', 'error');
      console.error(err);
    },
  });

  const handleCopy = useCallback(() => {
    if (!(selectedFeedbackForm && selectedTargetOrg)) {
      addFlashMessage('Please fill out all fields', 'error');
      return;
    }
    copyFeedbackForm();
  }, [
    selectedFeedbackForm,
    selectedTargetOrg,
    copyFeedbackForm,
    addFlashMessage,
  ]);

  return (
    <StyledWrapper>
      <StyledTitle>Select Org to copy from:</StyledTitle>
      <SelectOrganisation
        value={selectedOrg}
        onChange={setSelectedOrg}
        placeholder="Select Organisation..."
        disabled
      />
      <StyledTitle>Select Form:</StyledTitle>
      <SelectFeedbackForm
        value={selectedFeedbackForm}
        onChange={setSelectedFeedbackForm}
        placeholder="Select Form..."
      />
      <StyledTitle>Select Org to copy to:</StyledTitle>
      <SelectOrganisation
        value={selectedTargetOrg}
        onChange={setSelectedTargetOrg}
        placeholder="Select Target Organisation..."
      />
      <StyledButton disabled={loading} onClick={handleCopy}>
        Copy
      </StyledButton>
    </StyledWrapper>
  );
}
