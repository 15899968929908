import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StyledPageContentInner } from '../components/StyledPage';
import DBSelect from '../components/DBSelect';
import withUser from '../HOCs/WithUser';
import { READ_SIMULATIONS } from '../queries/SimulationQueries';
import StyledButton from '../components/buttons/Button';
import CXDashboardTabNav from '../components/CXDashboardTabNav';
import WithCXDashboardPageTemplate from '../HOCs/templates/WithCXDashboardPageTemplate';
import { useMutation } from '@apollo/client';
import { COPY_SIMULATION } from '../queries/CXQueries';
import withFlashMessaging from '../HOCs/WithFlashMessaging';
import { StyledHeader } from '../styled-components/StyledLoggedOutPage';
import { SEARCH_ORGANISATIONS } from '../apps/core/src/pages/admin/cx/cxDashboardPages.query';
import usePublishStrategy from '../apps/core/src/components/Cx/helpers/usePublishStrategy';
import StyledLabel from '../styled-components/StyledLabel';
import { Select } from '@virtidev/toolbox';

const StyledInputWrapper = styled.div`
  margin-bottom: 1rem;
`;

const StyledPageContentWrapper = styled.div`
  margin-top: 1rem;
`;

const CXDashboardPage = (props) => {
  const { publishStrategy, publishStrategySelectProps } = usePublishStrategy();
  const [currentOrganisationFrom, setCurrentOrganisationFrom] = useState(null);
  const [currentSimulation, setCurrentSimulation] = useState(null);
  const [currentOrganisationTo, setCurrentOrganisationTo] = useState(null);
  const [mutateCopySimulation, { loading: mutating }] = useMutation(
    COPY_SIMULATION,
    {
      onCompleted: () => {
        props.addFlashMessage(
          `Simulation ${currentSimulation.label} copied from ${currentOrganisationFrom.label} to ${currentOrganisationTo.label}`
        );
      },
      onError: () => {
        props.addFlashMessage('Failed to copy simulation', 'error');
      },
    }
  );

  const buttonDisabled =
    !currentOrganisationFrom || !currentSimulation || !currentOrganisationTo;
  return (
    <>
      <CXDashboardTabNav />
      <StyledPageContentWrapper>
        <StyledPageContentInner>
          <StyledHeader>Copy Simulation</StyledHeader>
          <StyledInputWrapper>
            <DBSelect
              dataQuery={SEARCH_ORGANISATIONS}
              filterVar="filter"
              queryDataPath="readOrganisations.edges"
              queryNestedObjectPath="node"
              onChange={(newOrgObj) => {
                setCurrentSimulation(null);
                setCurrentOrganisationTo(null);
                setCurrentOrganisationFrom(newOrgObj);
              }}
              value={currentOrganisationFrom}
              htmlId="organisation-copy-from"
              label="Organisation to Copy From"
              placeholder="Select Organisation"
              labelField="Name"
              disabled={mutating}
            />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <DBSelect
              dataQuery={READ_SIMULATIONS}
              queryDataPath="readSimulations.edges"
              queryNestedObjectPath="node"
              disabled={!currentOrganisationFrom || mutating}
              dataQueryVariables={{
                organisationID: currentOrganisationFrom
                  ? currentOrganisationFrom.value
                  : null,
              }}
              onChange={(newOrgObj) => {
                setCurrentOrganisationTo(null);
                setCurrentSimulation(newOrgObj);
              }}
              value={currentSimulation}
              htmlId="simulation-list"
              label="Simulation"
              placeholder="Select Simulation to Copy"
              labelField="Title"
            />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <DBSelect
              dataQuery={SEARCH_ORGANISATIONS}
              filterVar="filter"
              queryDataPath="readOrganisations.edges"
              queryNestedObjectPath="node"
              disabled={
                !currentOrganisationFrom || !currentSimulation || mutating
              }
              onChange={(newOrgObj) => setCurrentOrganisationTo(newOrgObj)}
              value={currentOrganisationTo}
              htmlId="organisation-copy-to"
              label="Organisation to Copy To"
              placeholder="Select Organisation"
              labelField="Name"
            />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <StyledLabel>Select publish strategy:</StyledLabel>
            <Select {...publishStrategySelectProps} />
          </StyledInputWrapper>
          <div>
            <StyledButton
              disabled={buttonDisabled}
              loading={mutating}
              onClick={() => {
                mutateCopySimulation({
                  variables: {
                    OrganisationID: currentOrganisationTo.value,
                    SimulationID: currentSimulation.value,
                    PublishStrategy: publishStrategy,
                  },
                });
              }}
              target="_blank"
            >
              Copy
            </StyledButton>
          </div>
        </StyledPageContentInner>
      </StyledPageContentWrapper>
    </>
  );
};

CXDashboardPage.propTypes = {
  // userOrganisationID: PropTypes.string.isRequired,
  addFlashMessage: PropTypes.func.isRequired,
};

export default withFlashMessaging(
  withUser(WithCXDashboardPageTemplate(CXDashboardPage))
);
