import {
  ApolloError,
  ApolloCache,
  useMutation,
  useApolloClient,
} from '@apollo/client';
import { useCallback } from 'react';
import { DetailedError, Upload } from 'tus-js-client';
import useFlashMessage from '../../FlashMessage';
import { ADD_SUBTITLES, VIDEO_FRAGMENT } from './Subtitles.query';
import getConfig from '../../../../../../lib/config';
import { produce } from 'immer';

/**
 * @typedef {import('../../../models/video-media.types').VideoMedia} VideoMedia
 * @typedef {import('../../../models/video-media.types').Subtitle} Subtitle
 */

/**
 * @param {VideoMedia} video
 * @param {string} language
 * @param {string} type
 * @param {{
 *    onCompleted?: (data: any) => void,
 *    onError?: (error: ApolloError) => void,
 *    onOptimisticUpdate?: (cache: ApolloCache<any>, result: any | null, options: any) => void,
 * }} config
 * @returns
 */
export const useUploadAndSaveSRT = (video, language, type, config) => {
  const client = useApolloClient();
  const { onCompleted, onError, onOptimisticUpdate } = config ?? {};
  const { addFlashMessage } = useFlashMessage();
  const [addSubtitles] = useMutation(ADD_SUBTITLES, {
    onCompleted: (data) => {
      addFlashMessage(`Added subtitles ${data.createSubtitle.Title}`);
      onCompleted?.(data);
    },
    onError: (e) => {
      console.error(e);
      onError?.(e);
    },
  });

  const handleTUSSuccess = useCallback(
    /**
     * @param {Upload} upload
     * @param {string} TusID
     */
    (upload, TusID) => {
      const ID = `new-subtitle-${(Math.random() * 100).toFixed(0)}`;

      const Input = {
        Title: /** @type {File} */ (upload.file)?.name || ID,
        VideoMediaID: video.ID,
        URL: `${getConfig('REACT_APP_CLOUDFRONT_URL')}${TusID}`,
        Language: language,
        Type: type,
      };

      /** @type {VideoMedia | null} */
      const videoCache = client.readFragment({
        id: `VideoMedia:${video.ID}`,
        fragment: VIDEO_FRAGMENT,
      });

      const newVideoCache = produce(videoCache, (draft) => {
        if (!draft) {
          return;
        }
        const count = draft.Subtitles.pageInfo.totalCount;
        draft.Subtitles.pageInfo.totalCount = count + 1;
        draft.Subtitles.nodes = [
          /** @type {Subtitle} */ ({ ID, ...Input, __typename: 'Subtitle' }),
          ...draft.Subtitles.nodes,
        ];
      });

      addSubtitles({
        variables: {
          Input,
        },
        optimisticResponse: {
          createSubtitle: {
            ID,
            ...Input,
            VideoMedia: newVideoCache,
            __typename: 'Subtitle',
          },
        },
        update: onOptimisticUpdate,
      });
    },
    [addSubtitles, video.ID, language, type, onOptimisticUpdate, client]
  );

  const uploadSRT = useUploadSRT();

  return useCallback(
    (file) => {
      uploadSRT(file, { onTUSSuccess: handleTUSSuccess });
    },
    [uploadSRT, handleTUSSuccess]
  );
};

export const useUploadSRT = () => {
  const uploadSRT = useCallback(
    /**
     * @param {File} selectedFile
     * @param {{
     *    onTUSSuccess?: (upload: Upload, tusID: string) => void,
     *    onTUSProgress?: (sent: number, total: number) => void,
     *    onTUSError?: (error: Error | DetailedError) => void,
     * }} config
     *
     * @returns {Upload}
     */
    (selectedFile, config) => {
      const { onTUSSuccess, onTUSProgress, onTUSError } = config;
      const upload = new Upload(selectedFile, {
        endpoint: getConfig('REACT_APP_TUS_UPLOAD_ENDPOINT_OTHER_MEDIA'),
        removeFingerprintOnSuccess: true,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: {
          filename: selectedFile.name,
          filetype: selectedFile.type,
        },
        onError: (error) => {
          onTUSError?.(error);
          console.error(error);
        },
        chunkSize: 1024 * 1024 * 50,
        onProgress: onTUSProgress,
        onSuccess: async () => {
          if (!upload.url) {
            onTUSError?.(new Error('No filename found for completed upload'));
            console.error('No filename found for completed upload', upload);
            return;
          }
          const tusID = upload.url.split('/').slice(-1)[0].split('+')[0];
          if (onTUSSuccess) {
            onTUSSuccess(upload, tusID);
          }
        },
      });
      upload.start();
      return upload;
    },
    []
  );

  return uploadSRT;
};

export default useUploadAndSaveSRT;
