import StyledLink from '@base/styled-components/StyledLink';
import { DeletedUserText } from '@core/components/Dashboard/DashboardUsagePanel/DashboardUsagePanel.styled';
import { Datetime } from '@virtidev/toolbox';
import { useMemo } from 'react';

export const UsageSignupsItem = ({ item }) => {
  const { FirstSeen, ID: userId, Name, Email, SignupMethod } = item;

  const userLink = useMemo(() => {
    if (userId === 0 || !userId) {
      return <DeletedUserText>(Deleted)</DeletedUserText>;
    }
    return (
      <StyledLink to={`/users/${userId}`} title={Name || null}>
        {Name || Email}
      </StyledLink>
    );
  }, [userId, Name, Email]);

  const methodLink = useMemo(() => {
    switch (SignupMethod) {
      case 'public':
        return ' public registration';
      case 'group invite':
        return ' group invitation code';
      case 'org invite':
        return 'invitation code';
      default:
        return null;
    }
  }, [SignupMethod]);

  return (
    <div>
      {userLink} signed up{methodLink && <> through {methodLink}</>}{' '}
      <Datetime datetime={FirstSeen} long difference={24 * 7} />
    </div>
  );
};

export default UsageSignupsItem;
