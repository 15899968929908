import PropTypes from 'prop-types';
import { Button, Select, TextInput, Tooltip } from '@virtidev/toolbox';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  CREATE_PLATFORM_LICENSE,
  DELETE_PLATFORM_LICENSE,
  UPDATE_PLATFORM_LICENSE,
} from './PlatformLicenseModal.query';
import {
  ButtonContainer,
  FormContainer,
  LabelTooltipWrapper,
  ResizedModal,
  WarningText,
} from './PlatformLicenseModal.styled';
import { FormProvider, useForm } from 'react-hook-form';
import Label from '@core/components/form/Label';
import FieldContainer from '@core/components/form/FieldContainer';
import { useFlashMessage } from '@core/components/FlashMessage';
import { add, formatISO } from 'date-fns';
import { platformLicenseOptions } from '@core/components/Cx/OrgTable/customerStageOptions';

function getDefaultValues(license) {
  if (license?.ID) {
    return {
      Type: license.Type,
      StartDate: license.StartDate,
      ExpiryDate: license.ExpiryDate,
      Notes: license.Notes,
    };
  }
  return {
    Type: 'FREE_TRIAL',
    StartDate: formatISO(new Date(), { representation: 'date' }),
    ExpiryDate: formatISO(add(new Date(), { days: 30 }), {
      representation: 'date',
    }),
    Notes: '',
  };
}

export const PlatformLicenseModal = ({
  orgID,
  license,
  visible,
  hideModal,
  onCreate,
  onDelete,
}) => {
  const [licenseID, setLicenseID] = useState(license?.ID || 0);
  const create = !licenseID;

  const [saveLicense, { loading: saving }] = useMutation(
    create ? CREATE_PLATFORM_LICENSE : UPDATE_PLATFORM_LICENSE
  );

  const [deleteLicense, { loading: deleting }] = useMutation(
    DELETE_PLATFORM_LICENSE
  );

  const loading = saving || deleting;

  const { addFlashMessage } = useFlashMessage();

  const formMethods = useForm({
    defaultValues: getDefaultValues(license),
  });
  const { register, reset, handleSubmit: submit, setFocus } = formMethods;

  const handleSubmit = useMemo(
    () =>
      submit(async (Input) => {
        const { data } = await saveLicense({
          variables: {
            Input: {
              ...Input,
              OrganisationID: orgID,
              ID: licenseID || undefined,
            },
          },
        });

        const newData =
          data.updatePlatformLicense || data.createPlatformLicense;
        if (create) onCreate(newData);

        setLicenseID(newData.ID);
        reset({
          Type: newData.Type,
          StartDate: newData.StartDate,
          ExpiryDate: newData.ExpiryDate,
          Notes: newData.Notes,
        });

        addFlashMessage(
          `Contract ${create ? 'created' : 'updated'} successfully.`,
          'success'
        );

        hideModal();
      }),
    [
      submit,
      saveLicense,
      orgID,
      licenseID,
      create,
      onCreate,
      reset,
      addFlashMessage,
      hideModal,
    ]
  );

  const handleDelete = useCallback(async () => {
    if (
      window.confirm(
        'Really delete the contract? This should only be done if the contract was created by mistake.'
      )
    ) {
      const { data } = await deleteLicense({
        variables: {
          IDs: [licenseID],
        },
      });

      if (data?.deletePlatformLicenses) {
        addFlashMessage(`Contract deleted successfully.`, 'success');
        hideModal();
        setLicenseID(0);
        reset(getDefaultValues(license));
        onDelete();
      } else {
        addFlashMessage('Failed to delete contract.', 'error');
      }
    }
  }, [
    addFlashMessage,
    deleteLicense,
    hideModal,
    license,
    licenseID,
    onDelete,
    reset,
  ]);

  useEffect(() => {
    setFocus('Type');
  }, [setFocus]);

  useEffect(() => {
    setLicenseID(license?.ID || 0);
    reset(getDefaultValues(license));
  }, [license, orgID, reset]);

  return (
    <ResizedModal
      show={visible}
      title={create ? 'Create Contract' : 'Edit Contract'}
      render={() => (
        <>
          {!create && (
            <WarningText>
              Caution: contracts should usually only be edited if a mistake has
              been made. If a customer's contract changes, create a new contract
              so that we have a history of changes.
            </WarningText>
          )}
          <FormProvider {...formMethods}>
            <FormContainer onSubmit={handleSubmit}>
              <FieldContainer>
                <Label>Type</Label>
                <select {...register('Type')}>
                  {platformLicenseOptions.map(({ value, label }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </FieldContainer>
              <FieldContainer>
                <Label>
                  <LabelTooltipWrapper>
                    Start Date{' '}
                    <Tooltip icon="help">This can be a future date.</Tooltip>
                  </LabelTooltipWrapper>
                </Label>
                <TextInput {...register('StartDate')} type="date" />
              </FieldContainer>
              <FieldContainer>
                <Label>
                  <LabelTooltipWrapper>
                    Expiry Date{' '}
                    <Tooltip icon="help">
                      This can be left empty but should be filled if possible.
                    </Tooltip>
                  </LabelTooltipWrapper>
                </Label>
                <TextInput {...register('ExpiryDate')} type="date" />
              </FieldContainer>
              <FieldContainer>
                <Label>Notes</Label>
                <TextInput multiline {...register('Notes')} />
              </FieldContainer>
              <ButtonContainer>
                {!create && (
                  <Button
                    disabled={loading}
                    loading={loading}
                    onClick={handleDelete}
                    color="error"
                  >
                    Delete
                  </Button>
                )}
                <Button disabled={loading} loading={loading} type="submit">
                  Save
                </Button>
              </ButtonContainer>
            </FormContainer>
          </FormProvider>
        </>
      )}
      onHide={hideModal}
    />
  );
};

PlatformLicenseModal.propTypes = {
  orgID: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  license: PropTypes.object,
  onCreate: PropTypes.func,
  onDelete: PropTypes.func,
};

PlatformLicenseModal.defaultProps = {
  license: null,
  onCreate: (license) => {},
  onDelete: () => {},
};

export default PlatformLicenseModal;
