import styled from 'styled-components';

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const SelectedCount = styled.div`
  color: var(--neutral-color);
`;
