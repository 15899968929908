import { Icon } from '@virtidev/toolbox';
import React, { useCallback, useMemo } from 'react';
import {
  DeleteButton,
  UploadItemTag,
  UploadItemTitle,
  StyledDownloadTag,
  StyledItem,
} from './SimpleUploaderItem.styled';
import SimpleUploaderLoadingPlaceholder from '../SimpleUploaderLoadingPlaceholder/SimpleUploaderLoadingPlaceholder';

const SimpleUploaderItem = ({ id, loading, downloadUrl, title, onDelete }) => {
  const handleDelete = useCallback(() => {
    onDelete(id);
  }, [id, onDelete]);

  const downloadProps = useMemo(
    () => ({
      href: downloadUrl,
      title: 'Download file ' + title,
    }),
    [title, downloadUrl]
  );

  return loading ? (
    <div>
      <SimpleUploaderLoadingPlaceholder />
    </div>
  ) : (
    <StyledItem>
      <UploadItemTag {...downloadProps}>
        <UploadItemTitle>{title || 'Unnamed File'}</UploadItemTitle>
      </UploadItemTag>
      {onDelete && (
        <DeleteButton
          color="transparent-contrast"
          onClick={handleDelete}
          icon="bin"
          iconSize="15px"
        />
      )}
      <StyledDownloadTag {...downloadProps}>
        <Icon icon="download" size="15px" />
      </StyledDownloadTag>
    </StyledItem>
  );
};

export default SimpleUploaderItem;
