import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledDataBlock,
  StyledDataBlockHeader,
  StyledDataBlockHeaderText,
} from '../../styled-components/StyledDataBlocks';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { medianFinder } from '../../utility/MathUtility';

function SimulationUserScores(props) {
  const scores =
    props.data &&
    props.data.SimulationScores.map((item) => {
      return item.Score;
    });

  const median = scores && medianFinder(scores);

  const data = props.singleSim
    ? [
        {
          name: 'First Score',
          y: props.data.AverageScoreFirst,
          views: props.data.AverageScoreFirst,
        },
        {
          name: 'Latest Score',
          y: props.data.SimulationScores[0].Score,
          views: props.data.SimulationScores[0].Score,
        },
        {
          name: 'Best Score',
          y: props.data.SimulationScores[0].BestScore,
          views: props.data.SimulationScores[0].BestScore,
        },
        {
          name: 'Median Score',
          y: median,
          views: median,
        },
        {
          name: 'Average Score',
          y: props.data.AverageScore,
          views: props.data.AverageScore,
        },
      ]
    : [
        {
          name: 'Avg. First Score',
          y: props.data.AverageScoreFirst,
          views: props.data.AverageScoreFirst,
        },
        {
          name: 'Avg. Latest Score',
          y: props.data.AverageScoreLast,
          views: props.data.AverageScoreLast,
        },

        {
          name: 'Total Avg.Score',
          y: props.data.AverageScore,
          views: props.data.AverageScore,
        },
      ];

  const colors = props.singleSim
    ? [
        'var(--secondary-color)',
        'var(--secondary-color)',
        'var(--secondary-color)',
        'var(--primary-color)',
        'var(--primary-color)',
      ]
    : [
        'var(--primary-color)',
        'var(--secondary-color)',
        'var(--primary-color)',
      ];

  return (
    <StyledDataBlock>
      <StyledDataBlockHeader>
        <StyledDataBlockHeaderText>
          {props.title || 'Title'}
        </StyledDataBlockHeaderText>
      </StyledDataBlockHeader>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'bar',
          },
          title: {
            text: '',
          },
          credits: {
            enabled: false,
          },
          legend: {
            enabled: false,
          },
          plotOptions: {},
          tooltip: {
            pointFormat:
              '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.views}%</b><br/>',
          },
          xAxis: {
            type: 'category',
          },
          yAxis: {
            max: 100,
            title: {
              text: props.viewType,
            },
          },
          colors,
          series: [
            {
              name: props.viewType,
              colorByPoint: true,
              data,
            },
          ],
        }}
      />
    </StyledDataBlock>
  );
}

SimulationUserScores.propTypes = {
  data: PropTypes.object,
  singleSim: PropTypes.bool,
};

export default SimulationUserScores;
