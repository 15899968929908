import { FormProvider, useForm } from 'react-hook-form';
import { ButtonContainer, Form } from '../TotaraRegistration.styled';
import NavigationPromptManual from '../../../../../../components/NavigationPromptManual';
import FieldContainer from '../../form/FieldContainer';
import Label from '../../form/Label';
import { TextInput, Button } from '@virtidev/toolbox';
import FieldError from '../../form/FieldError';
import { zodResolver } from '@hookform/resolvers/zod';
import { validateRegistration } from '../validation/validate-registration';
import { useEffect, useState } from 'react';

export const TotaraRegistrationForm = ({
  defaultValues,
  onSubmit,
  disablePrompt,
  loading,
}) => {
  const [initialised, setInitialised] = useState(false);
  const formMethods = useForm({
    resolver: zodResolver(validateRegistration),
  });
  const {
    register,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = formMethods;

  useEffect(() => {
    if (defaultValues && !initialised) {
      reset(defaultValues);
      setInitialised(true);
    }
  }, [defaultValues, reset, initialised]);

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <NavigationPromptManual
          when={!disablePrompt && isDirty}
          message="You haven't completed registration, are you sure you want to leave?"
        />
        <FieldContainer>
          <Label>First Name</Label>
          <TextInput {...register('FirstName')} disabled />
        </FieldContainer>
        <FieldContainer>
          <Label>Last Name</Label>
          <TextInput {...register('Surname')} disabled />
        </FieldContainer>
        <FieldContainer>
          <Label>Email</Label>
          <TextInput {...register('Email')} disabled />
        </FieldContainer>
        <FieldContainer>
          <Label>Password</Label>
          <TextInput {...register('Password')} type="password" />
          <FieldError name="Password" />
        </FieldContainer>
        <ButtonContainer>
          <Button type="submit" color="secondary" disabled={loading}>
            Sign up
          </Button>
        </ButtonContainer>
      </Form>
    </FormProvider>
  );
};

export default TotaraRegistrationForm;
