import { Icon, Tooltip } from '@virtidev/toolbox';
import styled, { css, DefaultTheme } from 'styled-components';
import { itemContentCss, itemContainerCss } from '../../Sidebar.styled';
import { useReactiveVar } from '@apollo/client';
import { secondarySidebarContentVar } from '@base/ApolloReactiveVars';

export const ButtonWrapper = styled.button`
  ${
    /**
     * @param {{ theme: DefaultTheme, $open?: boolean }} p
     */
    ({ theme: { color }, $open }) => css`
      ${itemContainerCss}

      position: relative;

      &.more,
      .sidebar-contracted &.more,
      .sidebar-hover.sidebar-contracted &.more {
        padding-right: 20px;
      }

      ${$open &&
      css`
        background: ${color.pink10};
        color: ${color.contrast.pink10};

        &:hover {
          background: ${color.pink20};
          color: ${color.contrast.pink20};
        }
        &.more,
        .sidebar-contracted &.more,
        .sidebar-hover.sidebar-contracted &.more {
          padding-right: 10px;
        }
      `}
    `
  }
`;

export const Content = styled.div`
  ${itemContentCss}
`;

export const IconsWrapper = styled.div`
  position: relative;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LockIcon = styled(Icon)`
  position: absolute;
  top: 10px;
  left: 15px;
`;

export const MoreIcon = styled(Icon).attrs({
  size: '18px',
})``;

export const IconHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  pointer-events: none;
`;
