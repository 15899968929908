import { useQuery } from '@apollo/client';
import useFlashMessage from '@core/components/FlashMessage';
import { READ_VOICES } from '@core/components/VirtualHumans/VoiceSelector/VoiceSelector.query';
import { useCallback } from 'react';

/**
 * @typedef {import('@core/models/vh-server/vh-server-virtualhuman.types').VHServerVirtualHumanWithLocale} VHServerVirtualHumanWithLocale
 * @typedef {import('@core/models/virtualhuman.types').VHVoice} VHVoice
 */

/** @type {(vhDBVH: VHServerVirtualHumanWithLocale, oldVoice: VHVoice) => [() => Promise<VHVoice | undefined>]} */
export const useFallbackLocaleVoice = (vhDBVH, oldVoice) => {
  // not quite the same logic as in VoiceSelector.js but not super important

  const { refetch: fetchELVoices } = useQuery(READ_VOICES, {
    variables: {
      // Since elevenlabs voices are multi-lingual, we don't need to filter them by locale
      filter: {
        Service: { eq: 'elevenlabs' },
        Deprecated: { ne: true },
      },
    },
    skip: true,
  });

  const { refetch: fetchOtherVoices } = useQuery(READ_VOICES, {
    variables: {
      filter: {
        Service: { ne: 'elevenlabs' }, // can't also remove elevenlabs_streaming so have to do that after
        Locale: { startswith: vhDBVH?.lang?.BCP47Code },
        Deprecated: { ne: true },
      },
    },
    skip: true,
  });

  const { addFlashMessage } = useFlashMessage();

  const resetVoice = useCallback(async () => {
    const [elVoicesData, otherVoicesData] = await Promise.all([
      fetchELVoices(),
      fetchOtherVoices(),
    ]);
    const voices = [
      ...(elVoicesData.data?.readVoices?.nodes || []),
      ...(otherVoicesData?.data?.readVoices?.nodes || []),
    ];
    // if there's an equivalent voice in the new locale then use that
    let newVoice = voices.find(
      /** @type {(voice: VHVoice) => boolean} */
      (voice) => voice.Code === oldVoice.Code
    );
    if (!newVoice) {
      // otherwise get one with the same name
      newVoice = voices?.find(
        /** @type {(voice: VHVoice) => boolean} */
        (voice) => voice.Name === oldVoice.Name
      );
    }
    if (!newVoice) {
      // otherwise get one with the same gender
      newVoice = voices?.find(
        /** @type {(voice: VHVoice) => boolean} */
        (voice) => voice.Gender === oldVoice.Gender
      );
    }
    if (!newVoice) {
      // otherwise grab the first one
      newVoice = voices?.[0];
    }
    if (newVoice) {
      return newVoice;
    } else {
      addFlashMessage('Failed to find voice to reset to.', 'error');
    }
  }, [
    addFlashMessage,
    oldVoice.Code,
    oldVoice.Gender,
    oldVoice.Name,
    fetchELVoices,
    fetchOtherVoices,
  ]);

  return [resetVoice];
};
