import styled, { css } from 'styled-components';

export const Trimmer = styled.div``;

export const OuterBar = styled.div`
  height: 50px;
  width: 100%;
  background: #d9d9d9;
  margin-top: 1rem;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  outline: 1px solid grey;
`;
export const Cursor = styled.div`
  height: 100%;
  position: absolute;
  border-radius: 16px;
  top: 0;
  width: 4px;
  background-color: grey;
  z-index: 1;
`;

export const TrimArea = styled.div`
  ${({ theme, disabled }) => css`
    position: absolute;
    border: 2px solid var(--primary-color);
    border-radius: ${theme.borderRadius.md};
    height: 100%;
    z-index: 1;
    backdrop-filter: contrast(2) brightness(2);
    ${disabled &&
    css`
      border-color: var(--disabled-form-input-bg-color);
    `}
  `}
`;

const dragStyles = css`
  ${({ draggable }) => css`
    background-color: var(--primary-color-lighter);
    border: 2px solid var(--primary-color);
    height: 20px;
    width: 12px;
    position: absolute;
    top: calc(50% - 10px);
    pointer-events: all;
    cursor: pointer;
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;

    :active {
      cursor: grabbing;
    }

    :hover {
      background-color: white;
    }

    ${draggable &&
    css`
      background-color: var(--disabled-form-input-bg-color);
      border-color: var(--disabled-form-input-bg-color);

      :hover {
        background-color: var(--disabled-form-input-bg-color);
      }
    `}
  `}
`;

export const TrimDragMin = styled.div`
  ${dragStyles}
  border-radius: 0 4px 4px 0;
  left: 0;
  border-left: none;
`;

export const TrimDragMax = styled.div`
  ${dragStyles}
  border-radius: 4px 0 0 4px;
  right: 0;
  border-right: none;
`;

export const TrimInputs = styled.div`
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
`;
