import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToDecimalPlace } from '../utility/NumberFormatting';

const StyledScorePercentage = styled.div`
  color: var(--negative-color);
  text-align: ${(props) => (props.align ? props.align : 'initial')};
  ${(props) =>
    props.score >= props.warningThreshold &&
    css`
      color: var(--warning-color);
    `}
  ${(props) =>
    props.score >= props.positiveThreshold &&
    css`
      color: var(--positive-color);
    `}
  ${(props) =>
    props.zeroBased &&
    props.score >= 0 &&
    css`
      color: var(--positive-color);
    `}
  ${(props) =>
    props.zeroBased &&
    props.score < 0 &&
    css`
      color: var(--negative-color);
    `}
  ${(props) =>
    props.usingFallback &&
    css`
      color: var(--primary-font-color);
    `}
  ${(props) =>
    props.usingFallback &&
    css`
      color: var(--primary-font-color);
    `}
  ${(props) =>
    props.showNA &&
    css`
      color: var(--primary-font-color);
    `}
`;
// can deal with provided score of both e.g. 50% and 50
// zeroBased means above 0 is positive, below is negative (as opposed to an always-above-0 figure)
export const ScorePercentage = (props) => {
  const useFallback =
    typeof props.score === 'undefined' ||
    props.score === '' ||
    props.score === null;

  let scoreAsString, scoreWithoutPerc, scoreToRender;
  if (!useFallback) {
    scoreAsString = props.score.toString();
    scoreWithoutPerc = scoreAsString.includes('%')
      ? scoreAsString.split('%').join('')
      : scoreAsString;
    // ensure max 2 dec place

    scoreToRender = ToDecimalPlace(Number(scoreWithoutPerc), 2);
  }
  return (
    <StyledScorePercentage
      showNA={props.showNA}
      warningThreshold={props.warningThreshold}
      positiveThreshold={props.positiveThreshold}
      className={props.className}
      zeroBased={props.zeroBased}
      score={(props.score + '').split('%')[0]}
      align={props.align}
      usingFallback={useFallback}
    >
      {props.showNA && <span>{props.NA}</span>}
      {!props.showNA && useFallback && props.fallback}
      {!props.showNA && !useFallback && `${scoreToRender}%`}
    </StyledScorePercentage>
  );
};

ScorePercentage.defaultProps = {
  zeroBased: false,
  fallback: '',
  NA: 'N/A',
  warningThreshold: 40,
  positiveThreshold: 60,
};

ScorePercentage.propTypes = {
  score: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  zeroBased: PropTypes.bool,
  align: PropTypes.string,
  showNA: PropTypes.bool,
  NA: PropTypes.string.isRequired,
};

export default ScorePercentage;

const StyledScoreChangePercentage = styled.div`
  ${({ theme, score, showNA }) => css`
    display: flex;
    justify-content: center;
    color: ${theme.color.error};

    ${score === 0 &&
    css`
      color: ${theme.color.warning};
    `}
    ${score > 0 &&
    css`
      color: ${theme.color.primary};
    `}
    ${showNA &&
    css`
      color: ${theme.color.base};
    `}

    > div {
      flex-basis: 4rem;
    }

    > div:first-of-type {
      margin-left: 4px;
    }
  `}
`;

export const ScoreChangePercentage = (props) => {
  const useFallback =
    typeof props.score === 'undefined' ||
    props.score === '' ||
    props.score === null;
  const numerated =
    typeof props.score === 'string'
      ? props.score.replace('%', '')
      : props.score;
  let icon = 'caret-up';
  if (props.score < 0) {
    icon = 'caret-down';
  }
  return (
    <StyledScoreChangePercentage showNA={props.showNA} score={numerated}>
      {props.showNA && <span>{props.NA}</span>}
      {!props.showNA && useFallback && props.fallback}
      {!props.showNA && !useFallback && (
        <>
          {!isNaN(numerated) && <FontAwesomeIcon icon={icon} />}
          <div>{isNaN(numerated) ? '-' : numerated + '%'}</div>
        </>
      )}
    </StyledScoreChangePercentage>
  );
};

ScoreChangePercentage.defaultProps = {
  useFallback: false,
  fallback: '',
  NA: 'N/A',
};

ScoreChangePercentage.propTypes = {
  useFallback: PropTypes.bool,
  fallback: PropTypes.string,
  showNA: PropTypes.bool,
  NA: PropTypes.string.isRequired,
};
