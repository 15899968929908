import _ from 'lodash';
import { useEffect } from 'react';
import { useDragLayer } from 'react-dnd';

const SCROLL_PADDING = 50;
const SCROLL_SPEED = 10;

export const useDragScroll = (ref) => {
  const { isDragging, offset } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
    offset: monitor.getClientOffset(),
  }));
  const { y } = offset || {};

  useEffect(() => {
    if (!isDragging || !ref) {
      return;
    }
    let speed = 0;

    const box = ref.getBoundingClientRect();
    const padding = Math.min(SCROLL_PADDING, box.height / 2);
    const top = Math.max(0, box.top);

    if (y - top <= padding) {
      // scroll up
      speed = -1 * SCROLL_SPEED;
    }

    const vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );
    const bottom = Math.min(vh, box.bottom);

    if (bottom - y <= padding) {
      // scroll down
      speed = 1 * SCROLL_SPEED;
    }

    if (!speed) {
      return;
    }
    let frame = null;

    const tick = _.throttle(() => {
      ref.scrollTo({ top: ref.scrollTop + speed });

      frame = window.requestAnimationFrame(tick);
    }, 1000 / 60);

    frame = window.requestAnimationFrame(tick);

    return () => {
      window.cancelAnimationFrame(frame);
      tick.cancel();
    };
  }, [isDragging, ref, y]);
};

export default useDragScroll;
