import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import StyledPage from '../../components/StyledPage';
import LoadingPageIndicator from '../../components/LoadingPageIndicator';
import PageLoadError from '../../components/PageLoadError';
import RedirectWithPrevState from '../../components/RedirectWithPrevState';
import HeaderArea from '../../components/HeaderArea';
import withUser from '../WithUser';
import { StyledProgressBar } from '../../styled-components/StyledOnboardingDashboard';
import styled, { useTheme } from 'styled-components';
import { Icon } from '@virtidev/toolbox';
import { READ_LEARNER_COURSE } from '../../queries/LearnerQueries';

const StyledProgressWrapper = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: center;
`;

const FormProgressWrapper = styled.div`
  font-size: 0.8rem;
  display: flex;
  gap: 1rem;
`;

const TextAndCheck = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const WithMyCoursePageTemplate = (WrappedComponent, pageKey) => {
  const MyCoursePageTemplate = (props) => {
    const theme = useTheme();
    const { loading, error, data, refetch } = useQuery(READ_LEARNER_COURSE, {
      variables: {
        ID: props.match.params.courseID,
      },
    });

    const course = data?.readOneCourse;

    const beginForm = useMemo(() => {
      if (!course) {
        return null;
      }
      const form = course.FeedbackFormBefore;
      if (form && form.ID !== '0') {
        return form;
      }
    }, [course]);

    const beginFormComplete = useMemo(() => {
      if (!course) {
        return null;
      }
      return course.FeedbackFormBeforeCompleted;
    }, [course]);

    const endForm = useMemo(() => {
      if (!course) {
        return null;
      }
      const form = course.FeedbackFormAfter;
      if (form && form.ID !== '0') {
        return form;
      }
    }, [course]);

    const endFormComplete = useMemo(() => {
      if (!course) {
        return null;
      }
      return course.FeedbackFormAfterCompleted;
    }, [course]);

    if (!props.userID) {
      return <RedirectWithPrevState to="/login" />;
    }

    return (
      <StyledPage pageKey={pageKey}>
        {loading && <LoadingPageIndicator />}
        {!loading && error && <PageLoadError graphQLErrorObj={error} />}
        {!loading && course && (
          <>
            <HeaderArea
              imageSrc={course.ImageMedia.URL}
              title={course.Title}
              subtitle={course.Description}
              type="course"
            >
              <StyledProgressWrapper>
                Progress: {course.MemberProgress}%
                <StyledProgressBar
                  max={100}
                  value={course.MemberProgress}
                  width="200px"
                />
                <FormProgressWrapper>
                  {beginForm && (
                    <TextAndCheck>
                      Intro Form:{' '}
                      <Icon
                        icon="success"
                        size="14px"
                        color={
                          beginFormComplete
                            ? theme.color.secondary
                            : theme.color.lightGrey
                        }
                      />
                    </TextAndCheck>
                  )}
                  {endForm && (
                    <TextAndCheck>
                      End Form:{' '}
                      <Icon
                        icon="success"
                        size="14px"
                        color={
                          endFormComplete
                            ? theme.color.secondary
                            : theme.color.lightGrey
                        }
                      />
                    </TextAndCheck>
                  )}
                </FormProgressWrapper>
              </StyledProgressWrapper>
            </HeaderArea>
            <WrappedComponent
              {...props}
              data={data}
              refetch={refetch}
              endForm={endForm}
              endFormComplete={endFormComplete}
              beginForm={beginForm}
              beginFormComplete={beginFormComplete}
            />
          </>
        )}
      </StyledPage>
    );
  };
  return withUser(MyCoursePageTemplate);
};
export default WithMyCoursePageTemplate;
