import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 24px;
`;

export const SectionTitle = styled.label`
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
`;

export const SectionTitleTooltipWrapper = styled.span`
  display: flex;
  justify-content: space-between;
`;
