import styled, { css } from 'styled-components';

export const StyledIframe = styled.iframe`
  width: 100%;
  height: 620px;
  border: none;
`;

export const StyledMessage = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing.md};
    margin: auto;
    margin-bottom: ${theme.spacing.md};
    text-align: center;
    max-width: 100%;
    line-height: ${theme.lineHeight};
    background: ${theme.color.primary};
    color: ${theme.color.contrast.primary};
    padding: ${theme.spacing.md};
    border-radius: ${theme.borderRadius.lg};
  `}
`;
