import {
  Container,
  FormContainer,
  Gap,
  Image,
  ImageContainer,
  Logout,
  SuccessHeader,
} from './VRPairing.styled';
import vrIllustration from './assets/vr-illustration.svg';
import vrIllustrationTick from './assets/vr-illustration-tick.svg';
import { useCallback, useState } from 'react';
import VRPairingForm from './components/VRPairingForm/VRPairingForm';

export const VRPairing = () => {
  const [paired, setPaired] = useState(false);

  const handlePaired = useCallback(() => setPaired(true), [setPaired]);

  return (
    <Container>
      <ImageContainer>
        <Image src={vrIllustration} hidden={paired} cover />
        <Image src={vrIllustrationTick} hidden={!paired} cover />
      </ImageContainer>
      <Gap />
      <FormContainer>
        {paired ? (
          <>
            <SuccessHeader>Your VR Device is now paired!</SuccessHeader>
            <Logout>Log out</Logout>
          </>
        ) : (
          <VRPairingForm onPaired={handlePaired} />
        )}
      </FormContainer>
    </Container>
  );
};

export default VRPairing;
