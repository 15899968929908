import { gql } from '@apollo/client';

export const DELETE_VH = gql`
  mutation ($IDs: [ID]!) {
    deleteVirtualHumans(ids: $IDs)
  }
`;

export const DUPLICATE_VH = gql`
  mutation copyVirtualHuman(
    $VirtualHumanID: ID!
    $OrganisationID: ID!
    $PublishStrategy: String = "DRAFT"
    $CreationMethod: String
  ) {
    copyVirtualHuman(
      VirtualHumanID: $VirtualHumanID
      OrganisationID: $OrganisationID
      PublishStrategy: $PublishStrategy
      CreationMethod: $CreationMethod
    ) {
      ID
      Title
      ExternalID
    }
  }
`;
