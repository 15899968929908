import styled from 'styled-components';

export const StyledPercentTag = styled.strong`
  font-size: 0.6rem;
  border: 1px solid;
  padding: 3px;
  border-radius: 5px;
  margin-left: 5px;
  color: ${(props) => props.color && props.color};
  position: absolute;
`;
