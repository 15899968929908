import { gql } from '@apollo/client';

export const READ_VIRTUAL_HUMANS = gql`
  query readVirtualHumansPaginated(
    $limit: Int!
    $offset: Int!
    $filterBy: VirtualHumanFilterFields
    $sortBy: VirtualHumanSortFields
  ) {
    readVirtualHumans(
      offset: $offset
      limit: $limit
      filter: $filterBy
      sort: $sortBy
    ) {
      pageInfo {
        totalCount
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ID
          Created
          LastEdited
          AuthorID
          Author {
            ID
            Name
          }
          Title
          AdminTitle
          Description
          OrganisationID
          Status
          Thumbnail {
            ID
            URL
            TusID
          }
          Avatar {
            ID
            Name
            ImageMedia {
              ID
              URL
              TusID
            }
          }
          Voice
          Views
          Likes
          Featured
          CoursesOnly
          DownloadVersion
          ExternalID
          APIEndpoint
          TotalUsers
          TotalCompletions
          Views
          AverageScore
          AverageConversationScore
          AverageDiagnosisScore
          Diagnosis
          TimeLimit
          DisableHelp
          Type
          Tags {
            nodes {
              ID
              Name
            }
          }
          OrganisationGroups {
            edges {
              node {
                ID
                Name
              }
            }
          }
          ImageMedia {
            ID
            URL
            TusID
          }
        }
      }
    }
  }
`;

export const READ_MEMBER_WITH_EDITABLE_VHS = gql`
  query readMemberWithEditableVHs($ID: ID!, $limit: Int!, $offset: Int!) {
    readOneMember(filter: { ID: { eq: $ID } }) {
      ID
      EditableVirtualHumans(limit: $limit, offset: $offset) {
        pageInfo {
          totalCount
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            ID
            Created
            LastEdited
            AuthorID
            Author {
              ID
              Name
            }
            Title
            Description
            OrganisationID
            Status
            ThumbnailURL
            Thumbnail {
              ID
              URL
              TusID
            }
            Avatar {
              ID
              Name
              ImageMedia {
                ID
                URL
                TusID
              }
            }
            Voice
            Views
            Likes
            Featured
            CoursesOnly
            DownloadVersion
            ExternalID
            APIEndpoint
            TotalUsers
            TotalCompletions
            Views
            AverageScore
            AverageDiagnosisScore
            Diagnosis
            TimeLimit
            DisableHelp
            Type
            OrganisationGroups {
              edges {
                node {
                  ID
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;
