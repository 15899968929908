import { useMutation, useQuery } from '@apollo/client';
import { useRef, useEffect, useCallback, useLayoutEffect } from 'react';

export function useEffectOnlyOnce(func) {
  // eslint-disable-next-line
  useEffect(func, []);
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

// a hook that will execute a lazy query after a given amount of time if no data has been retrieved
export function useExecuteDelayedLazyQuery(
  queryExecuteCallback,
  queryData,
  delay
) {
  useEffectOnlyOnce(() => {
    if (!queryData) {
      const timer = setTimeout(() => {
        queryExecuteCallback();
        console.log('This will run after ' + delay + ' second!');
      }, delay);
      return () => clearTimeout(timer);
    }
  });
}

//  used to check if component is mounted (allows for guarding against state updates on unmounted components)
export const useMounted = function () {
  const mounted = useRef(true);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, [mounted]);
  return mounted;
};

// designed to mock apollo pollInterval functionality using provided query details
// this is because currently pollInterval is bugged
export const useQueryWithPollInterval = function (
  query,
  variables,
  interval = 10000
) {
  const { loading, error, data, refetch } = useQuery(query, { variables });
  const pollTimer = useRef(null);

  const stopPolling = useCallback(() => {
    clearTimeout(pollTimer.current);
    pollTimer.current = null;
  }, []);

  useEffectOnlyOnce(() => {
    const callRefetch = () => {
      pollTimer.current = setTimeout(async () => {
        await refetch();
        if (pollTimer.current) {
          callRefetch();
        }
      }, interval);
    };
    callRefetch();

    return stopPolling;
  });

  return { loading, error, data, stopPolling };
};

export const useInterval = function (callback, delay) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (!delay) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
};
