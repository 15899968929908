import { useQuery } from '@apollo/client';
import { useRef, useState, FC, useMemo } from 'react';
import { IsFirefox } from '../../../../../../../utility/BrowserCheck';
import { useFeature } from '../../../LaunchDarkly';
import SubtitleGenerator from '../SubtitleGenerator/SubtitleGenerator';
import SubtitleList from '../SubtitleList/SubtitleList';
import { READ_VIDEO_SUBTITLES } from '../Subtitles.query';
import SubtitleTrack from '../SubtitleTrack/SubtitleTrack';
import SubtitleUploader from '../SubtitleUploader/SubtitleUploader';
import { Splitter, StyledVideo, UploaderListBox } from './SubtitleForm.styled';

/**
 * @typedef {import('../../../../models/video-media.types').VideoMedia} VideoMedia
 * @typedef {import('../../../../models/video-media.types').Subtitle} Subtitle
 */

/**
 * @type {FC<{
 *    video: VideoMedia,
 * }>}
 */
export const SubtitleForm = ({ video }) => {
  const videoRef = useRef(null);
  const [uploading, setUploading] = useState(false);
  // disabled subtitle generation until server-side work is ready
  // const subtitleGeneratorFeature = useFeature('subtitle-generator');

  const { data, loading } = useQuery(READ_VIDEO_SUBTITLES, {
    variables: {
      ID: video.ID,
    },
  });

  /** @type {Subtitle[]} */
  const subtitles = useMemo(
    () => data?.readOneVideoMedia?.Subtitles?.nodes || [],
    [data]
  );

  return (
    <>
      <StyledVideo
        controls
        key={video?.ID}
        ref={videoRef}
        preload="metadata"
        onContextMenu={(e) => e.preventDefault()}
        controlsList="nodownload"
      >
        <source src={video?.EditorURL} type="video/mp4" />
        {subtitles.map((subtitle, index) => (
          <SubtitleTrack
            key={'track' + subtitle.ID}
            subtitle={subtitle}
            isDefault={index === 0 && !IsFirefox()}
          />
        ))}
      </StyledVideo>
      <UploaderListBox>
        <SubtitleUploader
          video={video}
          loading={uploading}
          setLoading={setUploading}
        />
        {/* {subtitleGeneratorFeature && (
          <>
            <Splitter />
            <SubtitleGenerator video={video} />
          </>
        )} */}
      </UploaderListBox>
      <SubtitleList
        loading={loading}
        subtitles={subtitles}
        videoID={video.ID}
      />
    </>
  );
};

export default SubtitleForm;
