import {
  FC,
  useCallback,
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import {
  secondarySidebarContentVar,
  setSecondarySidebarContentVar,
  sidebarContractedVar,
} from '@base/ApolloReactiveVars';
import {
  CanViewAnalyticsPage,
  CanViewCoursesList,
  CanViewDashboard,
  CanViewFormsList,
  CanViewGroupsList,
  CanViewMedia,
  CanViewSimulationsList,
  CanViewUsersList,
} from '@base/utility/Permissions';
import ContactSalesModal from '@core/components/Signup/ContactSalesModal/ContactSalesModal';

import ButtonItem from '../ButtonItem/ButtonItem';
import LinkItem from '../LinkItem/LinkItem';
import { useReactiveVar } from '@apollo/client';

/**
 * @type {FC<{}>}
 */
export const AdminSidebar = () => {
  const [showModal, setShowModal] = useState(false);
  const contracted = useReactiveVar(sidebarContractedVar);

  const openSubContent = useCallback((contentName) => {
    if (secondarySidebarContentVar() === contentName) {
      setSecondarySidebarContentVar(null);
      return;
    }
    setSecondarySidebarContentVar(contentName);
  }, []);

  const openAnalytics = useCallback(() => {
    openSubContent('analytics');
  }, [openSubContent]);

  const openUsers = useCallback(() => {
    openSubContent('users');
  }, [openSubContent]);

  return (
    <>
      <ContactSalesModal setShow={setShowModal} show={showModal} />

      {CanViewDashboard() && (
        <LinkItem icon="dashboard" to="/" exact>
          Dashboard
        </LinkItem>
      )}

      {CanViewAnalyticsPage() && (
        <ButtonItem
          icon="analytics"
          contentName="analytics"
          to="/analytics-data/"
          onClick={openAnalytics}
          sublinks={[
            { to: '/analytics-data/overall', label: 'Overall' },
            // { to: '/analytics-data/users', label: 'Users' },
            { to: '/analytics-data/simulations', label: 'Simulations' },
            { to: '/analytics-data/courses', label: 'Courses' },
            { to: '/analytics-data/virtual-human', label: 'Virtual Humans' },
            { to: '/analytics-data/forms', label: 'Feedback Forms' },
            { to: '/analytics-data/tags', label: 'Tags' },
          ]}
        >
          Analytics
        </ButtonItem>
      )}

      {CanViewMedia() && (
        <LinkItem icon="media" to="/media">
          Media
        </LinkItem>
      )}
      {CanViewCoursesList() && (
        <LinkItem icon="course" to="/courses">
          Courses
        </LinkItem>
      )}
      {CanViewSimulationsList() && (
        <LinkItem icon="vr" iconType="outline" to="/simulations">
          Simulations
        </LinkItem>
      )}

      <LinkItem icon="virtual-human" to="/virtual-humans">
        Virtual Humans
      </LinkItem>
      {(CanViewUsersList() || CanViewGroupsList()) && (
        <ButtonItem
          icon="group"
          contentName="users"
          to={['/users', '/groups']}
          onClick={openUsers}
          sublinks={[
            CanViewUsersList() && { to: '/users', label: 'Users' },
            CanViewGroupsList() && { to: '/groups', label: 'Groups' },
          ]}
        >
          Users
        </ButtonItem>
      )}
      {CanViewFormsList() && (
        <LinkItem icon="feedback" to="/feedback-forms">
          Feedback
        </LinkItem>
      )}
    </>
  );
};

export default AdminSidebar;
