import React from 'react';
import CXDashboardTabNav from '../../../../../../../components/CXDashboardTabNav';
import WithCXDashboardPageTemplate from '../../../../../../../HOCs/templates/WithCXDashboardPageTemplate';
import CopyCourse from '../../../../components/Cx/CopyCourse/CopyCourse';

const CXCourseCopyPage = (props) => {
  return (
    <>
      <CXDashboardTabNav />
      <CopyCourse />
    </>
  );
};

export default WithCXDashboardPageTemplate(CXCourseCopyPage);
