
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import SaveBar from '../../../../../components/SaveBar';
import * as Styled from './VirtualHumanForm.styled';
import { EDIT_VIRTUAL_HUMAN_WITH_ENVIRONMENT_RESPONSE } from '../../../../../queries/VirtualHumanQueries';
import {
  Datetime,
  Icon,
  TextInput,
  AutosaveIndicator,
} from '@virtidev/toolbox';
import FeedbackForms from '../FeedbackForms/FeedbackForms/FeedbackForms';
import EnvironmentAvatarSelectors from '../VirtualHumans/EnvironmentAvatarSelectors/EnvironmentAvatarSelectors';
import { useDebouncedCallback } from 'use-debounce';
import { useMutation } from '@apollo/client';
import UploadImage from '../CourseForm/components/UploadImage/UploadImage';
import { FormProvider, useForm } from 'react-hook-form';
import NavigationPromptManual from '../../../../../components/NavigationPromptManual';
import { useEffectOnlyOnce } from '../../../../../utility/CustomHooks';
import useUpdateVHMutation from '../../../../../utility/VHHooks/useUpdateVHMutation';
import useGetVHData from '../../../../../utility/VHHooks/useGetVHData';
import { useFlashMessage } from '../FlashMessage';
import { useFeature } from '../LaunchDarkly';
import { useTheme } from 'styled-components';
import SelectTagFieldForm from '@core/components/Tags/SelectTagFieldForm';
import * as Sentry from '@sentry/browser';
import VHLocaleEditor from '@core/components/VirtualHumanForm/components/VirtualHumanEditors/VHLocaleEditor/VHLocaleEditor';
import { useFallbackLocaleVoice } from './useFallbackLocaleVoice';

const VirtualHumanForm = (props) => {
  const theme = useTheme();
  const { dataObj, environments } = props;
  const [preppingSave, setPreppingSave] = useState(false);
  const {
    data: vhDBVHResponse,
    loading: vhDBVHResponseLoading,
    error,
    refetch,
  } = useGetVHData(`patient/${props.dataObj.ExternalID}`);
  const getDefaultValues = useCallback(
    (dataObj) => {
      return {
        AvatarID: dataObj.Avatar.ID,
        Description: dataObj.Description,
        EnvironmentID:
          dataObj?.Environment.ID || environments?.edges[0].node.ID || 0,
        Featured: dataObj.Featured,
        CoursesOnly: dataObj.CoursesOnly,
        Status: dataObj.Status,
        DisableHelp: dataObj.DisableHelp,
        EasyMode: dataObj.EasyMode,
        TimeLimit: dataObj.TimeLimit + '', // make into string initially so correct comparison can be made to indicate dirty form
        AdminTitle: dataObj.AdminTitle,
        ImageMediaID: dataObj.ImageMedia.ID,
        VHVoiceID: dataObj.VHVoiceID,
        HideScoresFromUsers: dataObj.HideScoresFromUsers,
        PublicAccess: dataObj.PublicAccess,
        ExamMode: dataObj.ExamMode,
      };
    },
    [environments]
  );

  const formMethods = useForm({
    defaultValues: getDefaultValues(dataObj),
  });

  // fixes weird issue where it was caching old voice ID data in the react-hook-form state when navigating away and back
  useEffectOnlyOnce(() => {
    formMethods.reset(getDefaultValues(dataObj));
  });

  const {
    register,
    setValue,
    reset,
    handleSubmit: submit,
    formState,
    handleSubmit,
    getValues,
    watch,
  } = formMethods;

  const { addFlashMessage } = useFlashMessage();
  const [mutate, { loading: mutating, error: mutationError }] = useMutation(
    EDIT_VIRTUAL_HUMAN_WITH_ENVIRONMENT_RESPONSE,
    {
      onError: () => {
        addFlashMessage('Failed to save virtual human', 'error');
      },
      onCompleted: props.refetchVirtualHuman,
    }
  );

  const [updateVHDB, { isLoading: updatingVHDBVH }] = useUpdateVHMutation();

  useEffect(() => {
    const updateVHEffect = async () => {
      const updated = await updateVHDB(dataObj.ExternalID, {
        isEmbeddable: dataObj.PublicAccess,
      });
      if (!updated) {
        const msg = 'Failed to sync vh embeddable status';
        console.error(msg);
        Sentry.captureException(new Error(msg));
        addFlashMessage(msg, 'error');
      } else {
        refetch();
      }
    };
    if (
      vhDBVHResponse?.data !== undefined &&
      dataObj.PublicAccess !== vhDBVHResponse?.data?.isEmbeddable &&
      !vhDBVHResponseLoading
    ) {
      updateVHEffect();
    }
  }, [
    dataObj,
    addFlashMessage,
    updateVHDB,
    vhDBVHResponse,
    vhDBVHResponseLoading,
    refetch,
  ]);

  const _submitForm = useCallback(() => {
    const values = getValues();
    let variables = {
      Input: {
        ...values,
        TimeLimit: Number(getValues('TimeLimit')), // needs to be number
        DisableHelp: getValues('DisableHelp'),
      },
    };
    variables.Input.ID = dataObj.ID;
    // reset();
    mutate({
      variables,
      // refetchQueries: refetches,
    });
    setPreppingSave(false);
    // });
  }, [getValues, dataObj, mutate]);

  const debouncedSubmit = useDebouncedCallback(() => {
    _submitForm();
  }, 1000);

  useEffect(() => {
    return () => {
      debouncedSubmit.flush();
    };
  }, [debouncedSubmit]);

  // trigger save if change is made
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      const isDirty = !_.isEqual(getValues(), getDefaultValues(dataObj));
      if (isDirty) {
        setPreppingSave(true);
        debouncedSubmit();
        return;
      }
      setPreppingSave(false);
      debouncedSubmit.cancel();
    });
    return () => subscription.unsubscribe();
  }, [dataObj, getDefaultValues, getValues, debouncedSubmit, watch]);

  const showLoading = preppingSave || mutating || updatingVHDBVH;

  const vhExamModeFeature = useFeature('vh-exam-mode');
  const showVRExamModeToggle =
    vhExamModeFeature && props?.dataObj?.Type !== 'freeform_v3';
  const showOnScreenObjectivesToggle = props?.dataObj?.Type !== 'freeform_v3';
  const showEasyModeToggle = props?.dataObj?.Type !== 'freeform_v3';

  const [getFallbackVoice] = useFallbackLocaleVoice(
    vhDBVHResponse?.data,
    props.dataObj.VHVoice
  );

  const handleLocaleEdited = useCallback(async () => {
    await refetch();
    const newVoice = await getFallbackVoice();
    if (newVoice) {
      setValue('VHVoiceID', newVoice.ID);
    } else {
      addFlashMessage('No voice was found for the given locale.', 'error');
    }
  }, [addFlashMessage, getFallbackVoice, refetch, setValue]);

  return (
    <FormProvider {...formMethods}>
      <NavigationPromptManual
        when={showLoading}
        message="You have an unsaved changes, are you sure you want to leave?"
      />
      <Styled.VirtualHumanForm onSubmit={handleSubmit(_submitForm)}>
        <Styled.EditFormWrapper accommodateTabContentPadding={true}>
          <div>
            <EnvironmentAvatarSelectors
              voiceString={dataObj.Voice}
              localeCode={vhDBVHResponse?.data?.lang?.BCP47Code ?? 'en-US'}
              vhType={props?.dataObj?.Type}
            />
          </div>
          <Styled.InputsWrapper>
            <Styled.CardSection title="General">
              <Styled.InputsColumnWrapper>
                <Styled.CoverImageWrapper>
                  <UploadImage
                    url={dataObj.ImageMediaID.URL}
                    mediaTusID={dataObj.ImageMedia?.TusID}
                    name="ImageMediaID"
                    aspectRatio="1280 / 720"
                  />
                </Styled.CoverImageWrapper>
              </Styled.InputsColumnWrapper>
              <Styled.InputFieldWithLabel>
                <Styled.InputsColumnWrapper>
                  <SelectTagFieldForm
                    target={dataObj}
                    label="Tags"
                    id="tags"
                    type="virtual human"
                  />
                </Styled.InputsColumnWrapper>
              </Styled.InputFieldWithLabel>
              <Styled.InputFieldWithLabel>
                <Styled.DescriptionInput
                  multiline
                  name="description"
                  id="description"
                  placeholder="Description..."
                  // onChange={handleDescriptionChange}
                  label="Description/Background"
                  {...register('Description')}
                />
              </Styled.InputFieldWithLabel>
              <VHLocaleEditor
                loading={vhDBVHResponseLoading}
                onComplete={handleLocaleEdited}
                vhDBVH={vhDBVHResponse?.data}
              />
            </Styled.CardSection>
            <Styled.CardSection title="Settings">
              <Styled.InputsColumnWrapper>
                <Styled.InputFieldWithLabel>
                  <TextInput
                    id="admin-title"
                    maxLength="255"
                    label="Admin Title"
                    suffix={
                      <Styled.HelpTooltip>
                        This title is shown only to admins for identification
                        purposes in the admin area. It is not displayed to
                        learners.
                      </Styled.HelpTooltip>
                    }
                    {...register('AdminTitle')}
                  />
                </Styled.InputFieldWithLabel>
                <Styled.InputFieldWithLabel>
                  <TextInput
                    id="time-limit"
                    type="number"
                    label="Time Limit (Minutes)"
                    suffix={
                      <Styled.HelpTooltip>
                        {`The amount of time in minutes the user has to complete the virtual human ${
                          props.dataObj.Type === 'medical'
                            ? 'diagnosis'
                            : 'session'
                        }. Set to 0 for infinite time.`}
                      </Styled.HelpTooltip>
                    }
                    {...register('TimeLimit')}
                    step="1"
                  />
                </Styled.InputFieldWithLabel>
                {props.dataObj.Type === 'medical' && (
                  <Styled.InputFieldWithLabel>
                    <TextInput
                      id="diagnosis"
                      type="text"
                      label="Diagnosis"
                      disabled
                      value={_.get(props, 'condition.diagnosisPhrase', '')}
                    />
                    <Styled.DiagnosisLink
                      to={`/virtual-humans/${props.dataObj.ID}/builder/patient/${props.dataObj.ExternalID}/diagnosis`}
                    >
                      Edit Diagnosis Data
                    </Styled.DiagnosisLink>
                  </Styled.InputFieldWithLabel>
                )}
                {showOnScreenObjectivesToggle && (
                  <Styled.ToggleWrapper>
                    <Styled.ControlledToggleSwitch
                      name="DisableHelp"
                      label={`On-screen objectives${
                        dataObj.Type !== 'branching' ? ' & hints' : ''
                      }`}
                      value={false}
                      noValue={true}
                    />
                    <Styled.HelpTooltip>
                      On-screen objectives during a session will only be visible
                      if this toggle is set to true.
                    </Styled.HelpTooltip>
                  </Styled.ToggleWrapper>
                )}
                {showEasyModeToggle && (
                  <Styled.ToggleWrapper>
                    <Styled.ControlledToggleSwitch
                      name="EasyMode"
                      label="Easy Mode"
                    />
                    <Styled.HelpTooltip>
                      Easy mode shows all objective names by default and allows
                      users to use hints without penalty.
                    </Styled.HelpTooltip>
                  </Styled.ToggleWrapper>
                )}

                <Styled.ToggleWrapper>
                  <Styled.ControlledToggleSwitch
                    name="HideScoresFromUsers"
                    label="Hide Scores From Users"
                  />
                  <Styled.HelpTooltip>
                    Hides the score for this Virtual Human from the Learner in
                    the Interactive Video and Logs, an Admin will still see the
                    scores.
                  </Styled.HelpTooltip>
                </Styled.ToggleWrapper>
                {showVRExamModeToggle && (
                  <Styled.ToggleWrapper>
                    <Styled.ControlledToggleSwitch
                      name="ExamMode"
                      label="VR Exam Mode"
                    />
                    <Styled.HelpTooltip>
                      In VR: hide all the hotspots so that users will have to
                      place the objects on the right spots.
                    </Styled.HelpTooltip>
                  </Styled.ToggleWrapper>
                )}
                <FeedbackForms
                  contentId={dataObj.ID}
                  feedbackFormBefore={dataObj.FeedbackFormBefore}
                  feedbackFormAfter={dataObj.FeedbackFormAfter}
                  type="virtual human"
                />

                <SaveBar>
                  <Styled.SaveBarContent>
                    <Styled.AutosaveAndProvenance>
                      <AutosaveIndicator
                        loading={showLoading}
                        error={mutationError?.message}
                        dirty={false}
                      />
                      <Styled.ProvenanceWrapper>
                        <div>
                          Last Edited:{' '}
                          <Datetime
                            datetime={dataObj?.LastEdited}
                            long
                            emptyDisplay="-"
                          />
                        </div>
                        <div>Created by: {dataObj.Author?.Name}</div>
                      </Styled.ProvenanceWrapper>
                    </Styled.AutosaveAndProvenance>
                    <Styled.PublishedStatus>
                      <Icon
                        icon={
                          dataObj.Status === 'Published' ? 'eye' : 'eye-closed'
                        }
                        size="25px"
                        color={
                          dataObj.Status === 'Published'
                            ? theme.color.primary
                            : theme.color.lightGrey
                        }
                      />
                      {dataObj.Status}
                    </Styled.PublishedStatus>
                  </Styled.SaveBarContent>
                </SaveBar>
              </Styled.InputsColumnWrapper>
            </Styled.CardSection>
          </Styled.InputsWrapper>
        </Styled.EditFormWrapper>
      </Styled.VirtualHumanForm>
    </FormProvider>
  );
};

VirtualHumanForm.propTypes = {
  userOrganisationID: PropTypes.string.isRequired,
  onVirtualHumanCreated: PropTypes.func,
  dataObj: PropTypes.shape({
    ID: PropTypes.string.isRequired,
    Title: PropTypes.string.isRequired,
    Description: PropTypes.string,
    // Diagnosis: PropTypes.string.isRequired,
    ExternalID: PropTypes.number.isRequired,
    OrganisationID: PropTypes.string.isRequired,
  }),
  refetchVirtualHuman: PropTypes.func.isRequired,
  condition: PropTypes.object,
  environments: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          ID: PropTypes.string.isRequired,
          Name: PropTypes.string.isRequired,
          Description: PropTypes.string,
        }).isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default VirtualHumanForm;
