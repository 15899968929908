import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Modal } from '@virtidev/toolbox';
import useFlashMessage from '../../FlashMessage';
import { SelectGroup } from '../../form/Select';
import { BULK_UPDATE_MEMBERS } from './Actions.query';

const AddMembersToGroupModal = ({
  selectedIDs,
  setSelectedIDs,
  setListModified,
  showModal,
  setShowModal,
}) => {
  const [group, setGroup] = useState(null);
  const { addFlashMessage } = useFlashMessage();

  const [bulkUpdateUsers] = useMutation(BULK_UPDATE_MEMBERS, {
    onCompleted: () => {
      setSelectedIDs([]);
      setListModified(true);
      addFlashMessage(
        `User${selectedIDs.length > 1 ? 's' : ''} added to group successfully.`
      );
    },
  });

  const handleSubmit = useCallback(() => {
    bulkUpdateUsers({
      variables: {
        MemberIDs: selectedIDs,
        AddToGroupID: group,
      },
      update: (cache, result) => {
        cache.evict({ fieldName: 'readMembers' });
      },
    });
  }, [bulkUpdateUsers, selectedIDs, group]);

  const handleChange = useCallback((e) => {
    setGroup(e.value);
  }, []);

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        title={`Select group to add user${
          selectedIDs.length > 1 ? 's' : ''
        } to`}
        render={() => (
          <SelectGroup onChange={handleChange} menuPosition="fixed" />
        )}
        footerRender={() => <Button onClick={handleSubmit}>Submit</Button>}
      />
    </>
  );
};

export default AddMembersToGroupModal;
