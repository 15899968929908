import { useMemo, useRef, useEffect, useCallback, useState } from 'react';
import { Icon } from '@virtidev/toolbox';
import { useDrop } from 'react-dnd';
import WithConfirmationBox from '../../../../../../../HOCs/WithConfirmationBox';
import DescriptionInput from '../DescriptionInput/DescriptionInput';
import ModuleTitle from '../ModuleTitle/ModuleTitle';
import CourseUnit from '../CourseUnit/CourseUnit';
import { useModuleDragDrop } from './hooks/useModuleDragDrop';
import {
  ActionsContainer,
  DeleteAction,
  DropModuleArea,
  DropSimArea,
  DropUnitArea,
  EditAction,
  EmptyListBox,
  EmptyListContainer,
  ModuleBody,
  ModuleBox,
  ModuleContainer,
  UnitsHolder,
} from './Module.styled';

export const Module = ({
  data,
  index,
  onSortUnits,
  onSortModules,
  draggingModule,
  setDraggingModule,
  onDelete,
  confirm,
}) => {
  const { ID, Title, Units } = data;
  const [opened, setOpened] = useState(false);
  const containerRef = useRef();
  const [editable, setEditable] = useState(false);

  const {
    dragging,
    enabled,
    hover,
    above,
    hoverItem,
    typeItem,
    dropRef,
    dragRef,
    dropItemRef,
    dragViewRef,
  } = useModuleDragDrop(data, index, onSortModules);

  const toggleOpened = useCallback(
    () => !ID.includes('new') && setOpened(!opened),
    [setOpened, opened, ID]
  );

  const units = useMemo(() => Units.edges.map(({ node }) => node), [Units]);

  // update global dragging state if something is being dragged
  useEffect(() => setDraggingModule(dragging), [dragging, setDraggingModule]);

  const toggleEditable = useCallback(
    () => setEditable(!editable),
    [setEditable, editable]
  );

  const handleDelete = useCallback(() => {
    confirm(
      () => onDelete(ID, Title),
      null,
      `Are you sure you want to delete this module '${Title}'?`,
      'delete'
    );
  }, [ID, Title, confirm, onDelete]);

  const [{ hover: hoverUnit, type: typeUnit }, dropUnitRef] = useDrop(
    () => ({
      accept: 'UnitSort',
      drop: () => ({ moduleId: ID, units: [], index: 0 }),
      collect: (monitor) => {
        const item = monitor.getItem();

        return item
          ? {
              type: item?.data?.Type,
              hover: monitor.isOver(),
            }
          : {};
      },
    }),
    [ID]
  );

  const label = useMemo(
    () => (typeItem === 'VirtualHuman' ? 'virtual human' : 'interactive video'),
    [typeItem]
  );

  const labelUnit = useMemo(() => {
    switch (typeUnit) {
      case 'VirtualHuman':
        return 'virtual human';
      case 'Simulation':
        return 'interactive video';
      default:
        return 'interactive video or virtual human';
    }
  }, [typeUnit]);

  dropRef(dropItemRef(containerRef));

  return (
    <ModuleContainer ref={containerRef}>
      {hoverItem && <DropSimArea>Add {label} here?</DropSimArea>}
      {hover && above && (
        <DropModuleArea top>Shift module here?</DropModuleArea>
      )}
      <ModuleBox>
        <ModuleBody opened={editable && !draggingModule}>
          <DescriptionInput data={data} />
        </ModuleBody>
        <ModuleTitle
          dragViewRef={dragViewRef}
          dragRef={dragRef}
          enabled={enabled}
          toggle={toggleOpened}
          data={data}
          opened={opened}
          editable={editable}
          dragging={dragging}
        />
        {!ID.includes('new') && (
          <ActionsContainer>
            <EditAction type="button" onClick={toggleEditable}>
              <Icon
                icon="pen"
                type={editable ? 'solid' : 'outline'}
                size="16px"
              />
            </EditAction>
            <DeleteAction type="button" onClick={handleDelete}>
              <Icon icon="bin" type="outline" size="16px" />
            </DeleteAction>
          </ActionsContainer>
        )}
      </ModuleBox>
      <UnitsHolder opened={opened && !draggingModule}>
        {!!units.length ? (
          units.map((unit, indexUnit) => (
            <CourseUnit
              key={unit.ID}
              moduleId={ID}
              units={units}
              data={unit}
              index={indexUnit}
              onSort={onSortUnits}
            />
          ))
        ) : (
          <EmptyListContainer ref={dropUnitRef}>
            {hoverUnit ? (
              <DropUnitArea top>Move {labelUnit} here?</DropUnitArea>
            ) : (
              <EmptyListBox>
                <Icon icon="move" type="outline" size="16px" color="#fff" />
                <span>Drag your first {labelUnit} here</span>
              </EmptyListBox>
            )}
          </EmptyListContainer>
        )}
      </UnitsHolder>
      {hover && !above && <DropModuleArea>Shift module here?</DropModuleArea>}
    </ModuleContainer>
  );
};

export default WithConfirmationBox(Module);
