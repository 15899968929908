import { gql } from '@apollo/client';

export const SORT_QUESTIONS = gql`
  mutation sortQuestions($FeedbackPageID: ID!, $QuestionIDs: [ID]!) {
    sortOrderFeedbackQuestions(
      FeedbackPageID: $FeedbackPageID
      QuestionIDs: $QuestionIDs
    ) {
      ID
      Questions {
        nodes {
          ID
          Title
          SortOrder
          Options {
            nodes {
              ID
              Name
              Value
            }
          }
          Type
          Required
          Placeholder
          CharacterLimit
        }
      }
    }
  }
`;
