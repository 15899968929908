import styled from 'styled-components';
import Button from '../../../../../../components/buttons/Button';

export const VideoArea = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  margin-top: 1rem;
`;
export const VideoRecordedArea = styled.div``;
export const VideoWrapper = styled.div``;
export const Video = styled.video`
  border-radius: 16px;
  width: 100%;
`;

export const ControlButtons = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
  justify-content: flex-end;
`;
export const UploadButton = styled(Button)`
  width: 160px;
`;
export const DownloadButton = styled(Button)`
  > div > div {
    display: flex;
    gap: 0.5rem;
  }
`;
