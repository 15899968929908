import { useMutation } from '@apollo/client';
import { Icon } from '@virtidev/toolbox';
import React, { useCallback } from 'react';
import { CircularButton } from '../QuestionActions.styled';
import { COPY_QUESTION } from './DuplicateQuestion.query';

export default function DuplicateQuestion({
  question,
  setEditing,
  setLoading,
}) {
  const [copyQuestion] = useMutation(COPY_QUESTION, {
    variables: {
      QuestionID: question.ID,
    },
    onCompleted: () => setLoading(false),
    update: (cache, { data }) => {
      cache.evict({ fieldName: 'readOneFeedbackForm' });
    },
  });

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      copyQuestion();
      setLoading(true);
      setTimeout(() => {
        setEditing(false);
      }, 1);
    },
    [setEditing, copyQuestion, setLoading]
  );

  return (
    <CircularButton onClick={handleClick} title="Duplicate Question">
      <Icon icon="duplicate" />
    </CircularButton>
  );
}
