import { useQuery } from '@apollo/client';
import { READ_VIDEOS } from '@core/components/AssetSelectModal/AssetSelectModal.query';
import { AssetContainer } from '@core/components/AssetSelectModal/AssetSelectModal.styled';
import VideoCard from '@core/components/AssetSelectModal/components/VideoCard';
import useUser from '@core/helpers/useUser';
import { Loading, PaginationControl } from '@virtidev/toolbox';
import { useCallback, useMemo, useState } from 'react';

export const AssetVideoList = ({
  filter = '',
  onSelect,
  selectedItems = [],
  disabledIds = [],
  multiSelect = false,
}) => {
  const [page, setPage] = useState(1);
  const { OrganisationID } = useUser();
  const pageSize = 20;

  const {
    data,
    loading: videosLoading,
    error,
  } = useQuery(READ_VIDEOS, {
    variables: {
      limit: pageSize,
      offset: (page - 1) * pageSize,
      sort: { Created: 'DESC', ID: 'DESC' },
      filter: {
        Title: { contains: filter },
        Organisation: { ID: { eq: OrganisationID } },
      },
    },
  });

  const videos = useMemo(() => {
    if (!data?.readVideoMedias) {
      return [];
    }

    return data.readVideoMedias.nodes;
  }, [data]);

  const noVideoMessage = useMemo(() => {
    if (videos.length > 0 || videosLoading) {
      return null;
    }

    if (error) {
      console.error('Error loading videos:', error);
      return 'Error loading videos. Please try again later.';
    }

    if (!filter) {
      return 'No videos found';
    }
    return `No videos found with your search "${filter}".`;
  }, [videos, videosLoading, filter, error]);

  const handleSelect = useCallback(
    (id) => {
      const video = videos.find(({ ID }) => ID === id);
      if (!video) {
        return;
      }
      if (!multiSelect) {
        return onSelect([video]);
      }

      const newVideos = selectedItems.filter(({ ID }) => ID !== id);
      if (newVideos.length !== selectedItems.length) {
        return onSelect(newVideos);
      }

      return onSelect([...selectedItems, video]);
    },
    [multiSelect, selectedItems, videos, onSelect]
  );

  return (
    <>
      {videosLoading && <Loading size="large" />}
      {noVideoMessage}
      <AssetContainer>
        {videos.map((video) => (
          <VideoCard
            key={video.ID}
            video={video}
            onSelect={handleSelect}
            disabled={disabledIds.includes(video.ID)}
            selected={!!selectedItems.find(({ ID }) => video.ID === ID)}
            multiSelect={multiSelect}
          />
        ))}
      </AssetContainer>
      <PaginationControl
        onPageChange={setPage}
        page={page}
        pageSize={pageSize}
        total={data?.readVideoMedias?.pageInfo?.totalCount || 0}
      />
    </>
  );
};

export default AssetVideoList;
