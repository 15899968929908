import styled, { css } from 'styled-components';
import LinkWithPrevState from '../../../../../../../components/LinkWithPrevState';

export const StyledTab = styled(LinkWithPrevState)`
  ${({ theme: { spacing, color, tabs }, $isActive, disabled }) => css`
    cursor: pointer;
    text-align: center;
    padding: ${spacing.p3} ${spacing.lg};
    color: ${color.midnight10};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: ${spacing.sm};
    font-weight: 500;
    &:active {
      color: ${color.turquoise};
    }
    ${$isActive &&
    css`
      color: ${color.turquoise};
      box-shadow: inset ${color.turquoise} 0 -${tabs.dotSize} 0 0;
    `}
    ${disabled &&
    css`
      color: var(--tab-disabled-text-color);
      pointer-events: none;
    `}

    ${!$isActive &&
    !disabled &&
    css`
      &:active {
        color: ${color.turquoise};
      }

      &:hover {
        background: ${color.blue40};
      }

      &:focus {
        background: ${color.blue30};
      }
    `}
  `}
`;
