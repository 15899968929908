import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import LinkWithPrevState from './LinkWithPrevState';
import IsActiveUserIndicator from './IsActiveUserIndicator';
import fallbackAvatar from '../components/icons/avatar-default.jpeg';

const StyledUserBasic = styled.div`
  display: grid;
  min-height: 0;
  min-width: 0;
  grid-template-columns: 5rem 1fr;
  align-items: center;
  padding-right: 1rem;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
`;
const StyledUserAvatarWrapper = styled.div`
  align-self: center;
  padding: 0 1.5rem 0 0.4rem;
  position: relative;
`;
const StyledUserAvatar = styled.img`
  width: 3.25rem;
  height: 3.25rem;
  border-radius: 50rem;
`;
const StyledUserBasicData = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: text;
`;
const StyledLink = styled(LinkWithPrevState)`
  &:hover {
    text-decoration: underline;
  }
`;
const StyledUserLink = styled(StyledLink)`
  font-weight: 500;
  font-size: 0.9rem;
  margin-bottom: 0.3rem;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      text-decoration: line-through;
    `}
`;
const PositionedIsActiveUserIndicator = styled(IsActiveUserIndicator)`
  position: absolute;
  bottom: -2px;
`;

const UserListNameSection = (props) => (
  <StyledUserBasic>
    <StyledUserAvatarWrapper>
      <StyledUserAvatar
        src={
          props.userNode.AvatarMedia.URL
            ? props.userNode.AvatarMedia.URL
            : fallbackAvatar
        }
        alt="user avatar"
      />
      <PositionedIsActiveUserIndicator lastSeen={props.userNode.LastSeen} />
    </StyledUserAvatarWrapper>
    <StyledUserBasicData>
      <StyledUserLink
        to={props.link || `/users/${props.userNode.ID}`}
        disabled={props.userNode.Disabled}
      >
        {props.userNode.Name}
      </StyledUserLink>
      <div>{props.userNode.Email}</div>
    </StyledUserBasicData>
  </StyledUserBasic>
);

UserListNameSection.propTypes = {
  userNode: PropTypes.object.isRequired,
  link: PropTypes.string,
};

export default UserListNameSection;
