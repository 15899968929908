import { useCallback, useState } from 'react';
import VHQuery from './VHQuery';

/**
 * @typedef {import('./useGenerateVHV3Details.types').vhGenerationInput} vhGenerationInput
 * @typedef {import('./useGenerateVHV3Details.types').generatedVhData} generatedVhData
 */

const useGenerateVHV3Details = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const generate = useCallback(
    async (/** @type {vhGenerationInput} */ input) => {
      setError(null);
      setIsLoading(true);
      try {
        /** @type {{data: {data: generatedVhData}}} */
        const result = await VHQuery(`ai-generate-vh-v3`, 'POST', {
          ...input,
        });
        setIsLoading(false);
        return result;
      } catch (err) {
        setError(err);
        setIsLoading(false);
      }
    },
    []
  );
  return [generate, { isLoading, error }];
};
export default useGenerateVHV3Details;
