import React, { FC, useCallback } from 'react';
import { InputFieldWithLabel } from '@core/components/VirtualHumanForm/VirtualHumanForm.styled';
import { Select, TextInput } from '@virtidev/toolbox';
import { useLocaleOptions } from '@core/helpers/useLocaleOptions';
import useFlashMessage from '@core/components/FlashMessage';
import * as Sentry from '@sentry/browser';
import { useUpdateVHLocaleMutation } from '@core/components/VirtualHumanForm/components/VirtualHumanEditors/VHLocaleEditor/useUpdateVHLocaleMutation';
import { Note } from '@core/components/VirtualHumanForm/components/VirtualHumanEditors/VHLocaleEditor/VHLocaleEditor.styled';

/**
 * @typedef {import('@core/models/vh-server/vh-server-virtualhuman.types').VHServerVirtualHumanWithLocale} VHServerVirtualHumanWithLocale
 */

/** @type {FC<{vhDBVH: VHServerVirtualHumanWithLocale; onComplete: (localeCode: string) => void; loading: boolean;}>} */
const VHLocaleEditor = ({ vhDBVH, onComplete, loading }) => {
  const options = useLocaleOptions();
  const [update, { isLoading, error }] = useUpdateVHLocaleMutation();
  const { addFlashMessage } = useFlashMessage();

  const handleChange = useCallback(
    async (newOption) => {
      console.log(newOption);
      try {
        await update(vhDBVH.id, newOption.value);
        onComplete(newOption.value);
      } catch (e) {
        addFlashMessage('There was an error updating the language', 'error');
        console.error(e);
        Sentry.captureException(e);
      }
    },
    [update, vhDBVH?.id, onComplete, addFlashMessage]
  );

  const editableLocale = !['medical', 'default'].includes(vhDBVH?.vhType);

  if (!vhDBVH?.lang?.name && !editableLocale) return null;

  return (
    <InputFieldWithLabel>
      <Select
        options={options}
        loading={isLoading || loading}
        disabled={!editableLocale}
        label="Locale"
        id="locale"
        value={vhDBVH?.lang?.BCP47Code ?? 'en-US'}
        onChange={handleChange}
        menuPortalTarget={document.body}
      />
      {editableLocale && (
        <Note>Note: changing the locale may reset the selected voice</Note>
      )}
    </InputFieldWithLabel>
  );
};

export default VHLocaleEditor;
