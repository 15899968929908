import { rebrand } from '@core/templates/components/SplashPage/SplashPage.styled';
import { Icon } from '@virtidev/toolbox';
import styled, { css, DefaultTheme } from 'styled-components';

export const RegisterInfoContainer = styled.div`
  ${
    /**
     *
     * @param {{
     *    theme: DefaultTheme,
     *    $hideDetails?: boolean,
     *    $hideTablet?: boolean,
     * }} p
     * @returns
     */
    ({ theme, $hideDetails, $hideTablet }) => css`
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: ${theme.spacing.lg};
      padding: 0 ${theme.spacing.md} calc(${theme.spacing.lg} * 2);
      z-index: 3;

      ${$hideDetails &&
      css`
        ${RegisterInfoTitle}, ${RegisterInfoDescription} {
          display: none;
        }
      `}

      @media screen and (min-width: 992px) {
        align-items: flex-start;
        flex: 1;
        max-width: 550px;
        padding: 10% 0 ${theme.spacing.md};

        ${$hideDetails &&
        css`
          ${RegisterInfoTitle}, ${RegisterInfoDescription} {
            display: flex;
          }
        `}

        ${$hideTablet &&
        css`
          display: none;
        `}
      }

      ${$hideTablet &&
      css`
        @media screen and (min-width: 1200px) {
          display: flex;
        }
      `}
    `
  }
`;

export const RegisterInfoTitle = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.xl};
    text-align: center;

    @media screen and (min-width: 992px) {
      text-align: start;
      margin: 0 0 0 10%;
    }
  `}
`;

export const RegisterInfoDescription = styled.p`
  margin: 0 auto;
  width: 70%;
  min-width: 280px;
  text-align: center;

  @media screen and (min-width: 992px) {
    text-align: start;
    margin: 0 0 0 10%;
  }
`;

export const Marker = styled(Icon).attrs({ icon: 'success', size: '1.5rem' })`
  color: ${rebrand.color.pink};
`;

export const RegisterInfoList = styled.ul`
  ${({ theme }) => css`
    display: none;
    flex-direction: column;
    gap: ${theme.spacing.lg};

    padding-left: 0;
    margin: 0 0 0 calc(10% + 1.5rem + ${theme.spacing.sm});

    list-style-type: none;

    > li {
      position: relative;
      padding-left: ${theme.spacing.sm};

      ${Marker} {
        position: absolute;
        left: -1.5rem;
        top: 50%;
        transform: translate(0, -50%);
      }
    }

    @media screen and (min-width: 992px) {
      display: flex;
    }
  `}
`;

export const RegisterInfoLogo = styled.img`
  width: 40%;
  min-width: 200px;
  max-width: 300px;
  align-self: center;

  @media screen and (min-width: 992px) {
    display: none;
  }
`;
