import { MockText } from '@virtidev/toolbox';
import {
  EmptyList,
  LoadingPlaceholder,
  UsageList,
} from '../DashboardUsagePanel.styled';
import UsageSubmissionItem from './UsageSubmissionItem';

export const UsageSubmissionsTable = ({ data }) => {
  if (!data) {
    return (
      <UsageList>
        {Array.from(Array(3)).map((_, i) => (
          <div>
            <LoadingPlaceholder key={`placeholder-${i}`}>
              <MockText />
            </LoadingPlaceholder>
          </div>
        ))}
      </UsageList>
    );
  }

  if (!data.length) {
    return <EmptyList>No recent form submissions found.</EmptyList>;
  }

  return (
    <UsageList>
      {data.map((item) => (
        <UsageSubmissionItem item={item} />
      ))}
    </UsageList>
  );
};

export default UsageSubmissionsTable;
