import styled, { css, DefaultTheme } from 'styled-components';

export const TagContainer = styled.div`
  ${
    /**
     * @param {{ theme: DefaultTheme, $content360?: boolean }} p
     */
    ({ theme: { color, spacing }, $content360 }) => css`
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 0 ${spacing.sm};
      z-index: 1;
      border-radius: 0 6px 0 0;
      ${$content360
        ? css`
            background: ${color.primary};
            color: ${color.contrast.primary};
          `
        : css`
            background: ${color.secondary};
            color: ${color.contrast.secondary};
          `};
    `
  }
`;
