import { gql } from '@apollo/client';

export const READ_COURSES = gql`
  query readCourses(
    $filter: CourseFilterFields
    $limit: Int!
    $offset: Int!
    $sort: CourseSortFields
  ) {
    readCourses(filter: $filter, limit: $limit, offset: $offset, sort: $sort) {
      pageInfo {
        totalCount
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ID
          Title
          AdminTitle
          ImageMedia {
            ID
            TusID
          }
          LastEdited
          TotalStudents
          TotalCompletions
          TotalSuccessful
          Status
          Tags {
            nodes {
              ID
              Name
            }
          }
          Modules {
            edges {
              node {
                ID
                Units {
                  edges {
                    node {
                      ID
                    }
                  }
                }
              }
            }
          }
          OrganisationGroups {
            edges {
              node {
                ID
                Name
              }
            }
          }
        }
      }
    }
  }
`;
