import { gql } from '@apollo/client';

export const READ_API_NOTIFICATIONS = gql`
  query readAPINotifications(
    $OrganisationID: ID!
    $limit: Int!
    $offset: Int!
  ) {
    readAPINotifications(
      filter: { Organisation: { ID: { eq: $OrganisationID } } }
      offset: $offset
      limit: $limit
    ) {
      pageInfo {
        totalCount
      }
      edges {
        node {
          ID
          Created
          LastSent
          Event
          Status
          Body
          ResponseStatusCode
          Response
          URL
        }
      }
    }
  }
`;

export const UPDATE_API_NOTIFICATION = gql`
  mutation updateAPINotification($Input: APINotificationUpdateInputType!) {
    updateAPINotification(Input: $Input) {
      ID
    }
  }
`;
