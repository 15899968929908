export const OneDecimalPlace = function (num) {
  return Math.round(num * 10) / 10;
};

export const ToDecimalPlace = function (num, decimals) {
  if (!isFinite(num)) {
    return 0;
  }
  const decCalculation = Math.pow(10, decimals);
  return (
    Math.round((Number(num) + Number.EPSILON) * decCalculation) / decCalculation
  );
};

// allows "." at end
export const StringAsOneDecimal = function (textNum) {
  if (textNum.indexOf('.') === textNum.length - 1) return textNum;

  return OneDecimalPlace(textNum);
};

// cuts off the end (e.g. 102643 becomes 102600)
export const NormalizeTimestamp = function (timestamp) {
  return Math.round(timestamp / 100) * 100;
};

// removes any text after 1 decimal place e.g. 3.35 becomes 3.3 (used in input change callbacks to prevent messing with values)
export const RemoveDecimalsAfter1 = function (timestampString) {
  if (timestampString.indexOf('.') === -1) return timestampString;
  const arr = timestampString.split('.');
  return arr[0] + '.' + (arr[1] === '' ? '' : arr[1][0]);
};
