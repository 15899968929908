import { useMutation, useQuery } from '@apollo/client';
import { Button } from '@virtidev/toolbox';
import React, { useCallback, useMemo, useState } from 'react';
import { useFlashMessage } from '../../../../FlashMessage';
import {
  CREATE_ENVIRONMENT,
  READ_ENVIRONMENTS,
} from './CxEnvironmentsEditor.query';
import * as StyledCxVH from '../../CxVirtualHuman.styled';
import SelectedEnvironmentEditor from './components/SelectedEnvironmentEditor/SelectedEnvironmentEditor';
import SelectedEnvironmentAvatarsEditor from './components/SelectedEnvironmentAvatarsEditor/SelectedEnvironmentAvatarsEditor';

const CxEnvironmentsEditor = () => {
  const { data: environments } = useQuery(READ_ENVIRONMENTS);
  const [createEnvironment, { loading: creatingEnvironment }] =
    useMutation(CREATE_ENVIRONMENT);
  const [selectedEnvironmentID, setSelectiveEnvironmentId] = useState(null);
  const selectedEnvironment = useMemo(
    () =>
      environments?.readEnvironments?.nodes &&
      environments.readEnvironments.nodes.find(
        (environment) => environment.ID === selectedEnvironmentID
      ),
    [environments, selectedEnvironmentID]
  );
  const { addFlashMessage } = useFlashMessage();

  const handleCreateEnvironment = useCallback(() => {
    createEnvironment({
      variables: {
        input: {
          ID: '',
          Name: 'Unnamed',
          DisplayName: 'Unnamed',
          Description: '',
          IsPreview: true,
        },
      },
      refetchQueries: [{ query: READ_ENVIRONMENTS }],
      onCompleted: () => {
        addFlashMessage('Created environment');
      },
      onError: () => {
        addFlashMessage('Failed to create environment', 'error');
      },
    });
  }, [createEnvironment, addFlashMessage]);

  return (
    <StyledCxVH.Wrapper>
      <h1>Environments</h1>
      <StyledCxVH.ContentWrapper>
        <StyledCxVH.ListWrapper>
          {environments?.readEnvironments?.nodes &&
            environments.readEnvironments.nodes.map((environment) => (
              <StyledCxVH.EditorListItem
                key={environment.ID}
                active={selectedEnvironmentID === environment.ID}
                onClick={() => setSelectiveEnvironmentId(environment.ID)}
              >
                {environment.DisplayName} ({environment.Name})
              </StyledCxVH.EditorListItem>
            ))}
          <Button
            loading={creatingEnvironment}
            onClick={handleCreateEnvironment}
          >
            Create Blank Environment
          </Button>
        </StyledCxVH.ListWrapper>
        <StyledCxVH.SelectedItem>
          {selectedEnvironment && (
            <SelectedEnvironmentEditor
              selectedEnvironment={selectedEnvironment}
            />
          )}
        </StyledCxVH.SelectedItem>
        {selectedEnvironment && (
          <SelectedEnvironmentAvatarsEditor
            selectedEnvironment={selectedEnvironment}
          />
        )}
      </StyledCxVH.ContentWrapper>
    </StyledCxVH.Wrapper>
  );
};

export default CxEnvironmentsEditor;
