import React from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/client/react/components';
import { PaginationBottom } from '../components/Pagination';
import LoadingPageIndicator from '../components/LoadingPageIndicator';
import PageLoadError from '../components/PageLoadError';
import { GetFirstNestedWithKey } from '../utility/Objects';
import SimulationItem, { SimulationList } from '../components/SimulationItem';
import MySimulationItem from '../components/MySimulationItem';
import StyledLink from '../styled-components/StyledLink';
import { NetworkStatus } from '@apollo/client';

const PaginatedSimulationsList = (props) => {
  return (
    <Query
      query={props.dataQuery}
      fetchPolicy="cache-and-network"
      variables={{
        sort: { Created: 'DESC' },
        ...props.dataQueryVariables,
        offset:
          props.paginationState.limit * props.paginationState.page -
          props.paginationState.limit,
        limit: props.paginationState.limit,
        filter: {
          ...(props.dataQueryVariables.filter || {}),
          Organisation: { ID: { eq: props.userOrganisationID } },
        },
      }}
    >
      {({ loading, error, data, networkStatus }) => {
        if (loading && networkStatus !== NetworkStatus.poll)
          return <LoadingPageIndicator />;
        if (error) return <PageLoadError graphQLErrorObj={error} />;

        const edges = GetFirstNestedWithKey(data[props.queryName], 'edges');

        if (!edges?.length) {
          return props.userMode === 'user' ? (
            <div>
              This is where you'll find interactive videos that are separate
              from courses. Looks like there aren't any right now. Go to the{' '}
              <StyledLink to="/my-courses">courses page</StyledLink> to find
              available interactive videos.
            </div>
          ) : (
            <div>No results found.</div>
          );
        }

        return (
          <>
            <SimulationList data-testid="simulations-list">
              {props.userMode === 'admin' &&
                edges.length > 0 &&
                edges.map((edge) => (
                  <SimulationItem data={edge.node} key={edge.node.ID} />
                ))}
              {props.userMode === 'user' &&
                edges.length > 0 &&
                edges.map((edge) => (
                  <MySimulationItem data={edge.node} key={edge.node.ID} />
                ))}
            </SimulationList>
            <PaginationBottom
              changePageCallback={props.changePageCallback}
              perPage={props.paginationState.limit}
              page={props.paginationState.page}
              totalCount={
                data && data[props.queryName]
                  ? GetFirstNestedWithKey(data[props.queryName], 'totalCount')
                  : 0
              }
              pageInfo={
                data && data[props.queryName]
                  ? GetFirstNestedWithKey(data[props.queryName], 'pageInfo')
                  : null
              }
            />
          </>
        );
      }}
    </Query>
  );
};

PaginatedSimulationsList.defaultProps = {
  dataQueryVariables: {},
  userMode: 'admin',
};

PaginatedSimulationsList.propTypes = {
  changePageCallback: PropTypes.func.isRequired,
  paginationState: PropTypes.object.isRequired,
  dataQuery: PropTypes.object.isRequired,
  dataQueryVariables: PropTypes.object,
  queryName: PropTypes.string.isRequired,
  userOrganisationID: PropTypes.number.isRequired,
  userMode: PropTypes.string.isRequired,
};

export default PaginatedSimulationsList;
