import {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useState,
} from 'react';

const OverviewShowingContext = createContext(false);

export const useOverviewShowing = () => {
  const context = useContext(OverviewShowingContext);

  if (!context) {
    console.error('useOverviewShowing called without context!');
  }
  return context;
};

export const OverviewShowingProvider = ({ initialShow, children }) => {
  const [show, setState] = useState(initialShow);

  const showModal = useCallback(() => setState(true), [setState]);

  const hideModal = useCallback(() => setState(false), [setState]);

  return (
    <OverviewShowingContext.Provider value={{ show, showModal, hideModal }}>
      {children}
    </OverviewShowingContext.Provider>
  );
};

export const withOverviewShowing =
  (initialShow = false) =>
  (Component) => {
    const OverviewShowingWrapper = forwardRef((props, ref) => {
      return (
        <OverviewShowingProvider initialShow={initialShow}>
          <Component {...props} ref={ref} />
        </OverviewShowingProvider>
      );
    });

    return OverviewShowingWrapper;
  };
