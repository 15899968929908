import { useMutation } from '@apollo/client';
import { Button, Modal } from '@virtidev/toolbox';

import React, { useCallback, useMemo, useState } from 'react';
import { READ_ORGANISATION_LICENSES } from '../../../../../../../queries/OrganisationQueries';
import { useFlashMessage } from '../../../FlashMessage';
import { SelectOrganisation } from '../../../form/Select';
import { ModalContent } from '../../../Users/Actions/AddMembersToGroupModal.styled';
import { ADD_MEMBERS_TO_ORG } from './AddMembersToOrgModal.query';

const AddMembersToOrgModal = ({
  selectedIDs,
  setSelectedIDs,
  showModal,
  setShowModal,
}) => {
  const [org, setOrg] = useState(null);
  const { addFlashMessage } = useFlashMessage();

  const [addMembersToOrg] = useMutation(ADD_MEMBERS_TO_ORG, {
    refetchQueries: [READ_ORGANISATION_LICENSES],
  });

  const handleSubmit = useCallback(() => {
    addMembersToOrg({
      variables: {
        OrganisationID: org.value,
        MemberIDs: selectedIDs,
      },
      onCompleted: (data) => {
        addFlashMessage(
          `Added ${selectedIDs.length} user${
            selectedIDs.length > 1 ? 's' : ''
          } to ${org.label}!`
        );
        // for each member, add the org to their "organisations"
        // setSelectedIDs([]);
        // setOrg(null);
      },
      onError: (err) => {
        addFlashMessage('Something went wrong', 'error');
        console.error(err);
      },
      update: (cache) => {
        cache.evict({ fieldName: 'readMembers' }); // temporary solution

        // selectedIDs.forEach((id) => {
        //   let data = cache.readFragment({
        //     fragment: MEMBER_FRAGMENT,
        //     id: `Member:${id}`,
        //   });

        //   console.log(data);
        //   //cache.readFragment
        //   // add org title/id to orgs
        //   // check for duplicates
        //   // cache.writeFragment
        // });
        setSelectedIDs([]);
        setOrg(null);
      },
    });
  }, [addMembersToOrg, selectedIDs, addFlashMessage, org, setSelectedIDs]);

  const handleChange = useCallback((e) => {
    setOrg(e);
  }, []);

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        title={`Select organisation to add user${
          selectedIDs.length > 1 ? 's' : ''
        } to`}
        footerRender={() => (
          // rendering in the footer because the Select dropdown gets cut off in the body render, will look at fix in toolbox
          <ModalContent>
            <SelectOrganisation
              onChange={handleChange}
              value={org}
              direction="up"
            />
            <Button
              onClick={handleSubmit}
              disabled={!org}
              style={{ borderRadius: '22px' }}
            >
              Submit
            </Button>
          </ModalContent>
        )}
        render={() => {
          return <div></div>;
        }}
      />
    </>
  );
};

export default AddMembersToOrgModal;
