import { Loading } from '@virtidev/toolbox';
import React, { useState } from 'react';
import { SelectFeedbackForm } from '../../../form/Select';
import CreateNewFeedbackForm from '../CreateNewFeedbackForm/CreateNewFeedbackForm';
import EditFeedbackModal from '../EditFeedbackModal/EditFeedbackModal';
import {
  SelectAndLinkWrapper,
  StyledFormButtonWrapper,
} from './SelectAndCreateForm.styled';

export default function SelectAndCreateForm({
  loading,
  value,
  handleSubmit,
  setLoading,
  onChange,
  field,
  ...props
}) {
  const [showModal, setShowModal] = useState(false);

  return (
    <SelectAndLinkWrapper>
      <SelectFeedbackForm
        {...props}
        disabled={loading}
        value={value}
        // loading={loading}
        onChange={onChange}
        clearable
      />
      {loading ? (
        <Loading />
      ) : (
        <>
          {value?.value && (
            <StyledFormButtonWrapper>
              <EditFeedbackModal
                key={value.value}
                id={value.value}
                showModal={showModal}
                setShowModal={setShowModal}
              />
            </StyledFormButtonWrapper>
          )}
          <StyledFormButtonWrapper>
            <CreateNewFeedbackForm
              field={field}
              setLoading={setLoading}
              handleAddToContent={handleSubmit}
              setShowModal={setShowModal}
            />
          </StyledFormButtonWrapper>
        </>
      )}
    </SelectAndLinkWrapper>
  );
}
