import { gql } from '@apollo/client';

export const GET_ORG_VH_COUNTS = gql`
  query getOrgVHCounts($OrganisationID: ID!) {
    readOneOrganisation(filter: { ID: { eq: $OrganisationID } }) {
      ID
      Name
      VirtualHumanFreeformLimit
      VirtualHumanBranchingLimit
      UnusedFreeformLicenses
      BranchingVirtualHumans: VirtualHumans(
        filter: { Type: { eq: "branching" }, Disabled: { eq: false } }
      ) {
        pageInfo {
          totalCount
        }
      }
      MedicalVirtualHumans: VirtualHumans(
        filter: { Type: { eq: "medical" }, Disabled: { eq: false } }
      ) {
        pageInfo {
          totalCount
        }
      }
      DefaultVirtualHumans: VirtualHumans(
        filter: { Type: { eq: "default" }, Disabled: { eq: false } }
      ) {
        pageInfo {
          totalCount
        }
      }
    }
  }
`;
