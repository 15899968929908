import { Icon, Card } from '@virtidev/toolbox';
import styled, { css, DefaultTheme } from 'styled-components';

export const BadgeCard = styled(Card)`
  .body {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const IconBox = styled.div`
  ${
    /** @param {{ theme: DefaultTheme, $unlocked?: boolean }} p */
    ({ theme: { color }, $unlocked }) => css`
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      height: 53px;
      width: 53px;
      ${$unlocked
        ? css`
            background: ${color.pink20};
            color: ${color.pink};
          `
        : css`
            background: ${color.midnight30};
            color: ${color.midnight20};
          `}
    `
  }
`;

export const BadgeIcon = styled(Icon).attrs({ icon: 'star', size: '27px' })``;

export const BadgeContent = styled.div`
  ${({ theme: { spacing } }) => css`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: ${spacing.sm};
  `}
`;

export const BadgeTitle = styled.h2`
  ${({ theme: { fontSize, fontFamilyTitle, fontWeight } }) => css`
    font-size: ${fontSize.lg};
    font-family: ${fontFamilyTitle};
    font-weight: ${fontWeight.partBold};
  `}
`;

export const BadgeDetail = styled.span`
  ${({ theme: { color, fontSize } }) => css`
    color: ${color.midnight10};
    font-size: ${fontSize.sm};
  `}
`;
