import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import { READ_TRANSCODING_STATUS } from '../queries/SimulationQueries';
import { StyledPageContentInner } from '../components/StyledPage';
import SimulationEditForm from '../forms/SimulationEditForm';
import RedirectWithPrevState from '../components/RedirectWithPrevState';
import { GetCurrentVideoFromSimulation } from '../utility/SimulationHelpers';

import withSimulationPageTemplate from '../HOCs/templates/WithSimulationPageTemplate';

const SimulationPage = (props) => {
  const currentVideo = GetCurrentVideoFromSimulation(
    props.data.readOneSimulation,
    props.match.params.videoID
  );

  const { loading, error, data, stopPolling } = useQuery(
    READ_TRANSCODING_STATUS,
    {
      variables: { ID: currentVideo?.ID },
      pollInterval: 10000,
      skip: !currentVideo?.ID || currentVideo.TranscodingStatus === 'Success',
    }
  );

  const transcodingStatus =
    loading || !data || error
      ? currentVideo?.TranscodingStatus
      : data.readOneVideo?.TranscodingStatus;

  useEffect(() => {
    if (!loading && transcodingStatus === 'Success') {
      stopPolling();
    }
  }, [loading, transcodingStatus, stopPolling]);

  // if there's no video we redirect - TODO: once we add ability to remove videos, we
  // either need to prevent user from deleting final video or accommodate no current
  // video in children and in routes
  if (!currentVideo) return <RedirectWithPrevState to="/simulations" />;
  return (
    <StyledPageContentInner>
      <SimulationEditForm
        dataObject={props.data.readOneSimulation}
        currentVideo={currentVideo}
        transcodingStatus={transcodingStatus}
        userOrganisationID={props.userOrganisationID}
      />
    </StyledPageContentInner>
  );
};

SimulationPage.propTypes = {
  match: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default withSimulationPageTemplate(
  SimulationPage,
  'edit-simulation',
  true,
  false
);
