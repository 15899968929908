import getConfig from '../lib/config';

export const FindGetParameter = function (parameterName) {
  var result = null,
    tmp = [];
  window.location.search
    .substr(1)
    .split('&')
    .forEach(function (item) {
      tmp = item.split('=');
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });
  return result;
};

export const MatchPathWithWildcards = function (
  pathWithWildcards,
  currentPath
) {
  if (pathWithWildcards === currentPath) return true;
  const splitPath1 = pathWithWildcards.split('/');
  const splitPath2 = currentPath.split('/');
  // return true if every segment of the url matches or is a *
  return splitPath1.reduce((carry, urlSegment, index) => {
    if (!carry) return carry;
    return urlSegment === splitPath2[index] || urlSegment === '*';
  }, true);
};

export function PathJoin(parts, sep) {
  const separator = sep || '/';
  parts = parts.map((part, index) => {
    if (index) {
      part = part.replace(new RegExp('^' + separator), '');
    }
    if (index !== parts.length - 1) {
      part = part.replace(new RegExp(separator + '$'), '');
    }
    return part;
  });
  return parts.join(separator);
}

export const URLToHTTPS = function (url) {
  return url.replace('http://', 'https://');
};
