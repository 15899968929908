import { Icon } from '@virtidev/toolbox';
import React, { useCallback } from 'react';
import { IconButton } from './ThSortable.styled';

export default function ThSortable({
  setCategory,
  setDirection,
  direction,
  children,
  category,
  style,
  currentFilter,
}) {
  const handleClick = useCallback(() => {
    setCategory(category);
    if (direction === 'ASC') {
      setDirection('DESC');
    }
    if (direction === 'DESC') {
      setDirection('ASC');
    }
  }, [direction, setDirection, setCategory, category]);

  if (currentFilter !== category) {
    return (
      <th style={{ ...style, cursor: 'pointer' }} onClick={handleClick}>
        {children}{' '}
      </th>
    );
  }

  return (
    <th style={{ ...style, cursor: 'pointer' }} onClick={handleClick}>
      {children}

      {direction === 'ASC' ? (
        <IconButton icon="chevron" direction="down" size="12px" />
      ) : (
        <IconButton icon="chevron" direction="up" size="12px" />
      )}
    </th>
  );
}
