import React from 'react';
import { Tab, TabNav } from '../styled-components/StyledTabNav';
import styled from 'styled-components';
import useFeature from '../apps/core/src/components/LaunchDarkly/hooks/useFeature';

const StyledTabNav = styled(TabNav)`
  background: none;
  margin-bottom: 1rem;
`;

const MediaTabNav = (props) => {
  const screenCaptureFeature = useFeature('screen-capture-tool');
  return (
    <StyledTabNav>
      <Tab isActive={window.location.pathname === '/media'} to={`/media`}>
        Videos
      </Tab>
      {screenCaptureFeature && (
        <Tab
          isActive={window.location.pathname.includes('screen-capture')}
          to={`/media/screen-capture`}
        >
          Screen Capture
        </Tab>
      )}
    </StyledTabNav>
  );
};
export default MediaTabNav;
