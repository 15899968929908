import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useDebouncedSave } from '../../../form/hooks/useDebouncedSave';
import { UPDATE_DESCRIPTION } from './descriptionInput.query';
import { PositionedLoading, Textarea, TextHolder } from './DescriptionInput.styled';

export const DescriptionInput = ({ data, ...props }) => {
  const { ID, Description } = data;
  const [updateDescription, { loading: updating }] =
    useMutation(UPDATE_DESCRIPTION);

  const onUpdate = useCallback(
    async (newDescription) => {
      await updateDescription({
        variables: {
          Input: {
            ID,
            Description: newDescription,
          },
        },
        optimisticResponse: {
          updateModule: {
            ID,
            Description: newDescription,
            __typename: 'Module',
          },
        },
      });
    },
    [ID, updateDescription]
  );

  const debounceProps = useDebouncedSave(Description || '', {
    onUpdate,
    enter: false,
  });

  return (
    <TextHolder>
      <Textarea {...props} multiline {...debounceProps} />
      {updating && (
        <PositionedLoading />
      )}
    </TextHolder>
  );
};

export default DescriptionInput;
