import PropTypes from 'prop-types';
import {
  forwardRef,
  useCallback,
  FC,
  ComponentType,
  Validator,
  ForwardedRef,
} from 'react';
import { useController } from 'react-hook-form';

/**
 * @type {(Component: ComponentType<any>) => ComponentType<any>}
 */
export const withController = (Component) => {
  /**
   * @type {FC<{
   *    name: string,
   *    onChange: (name: string, value: any) => void,
   * }>}
   */
  const ControlledWrapper = forwardRef(({ name, onChange, ...props }, ref) => {
    const {
      field: { onChange: onChangeField, value },
    } = useController({ name });

    const handleChange = useCallback(
      (option) => {
        const value = option ? option.value : option;
        onChangeField(value);
        onChange(name, value);
      },
      [onChange, name, onChangeField]
    );

    return (
      <Component ref={ref} {...props} value={value} onChange={handleChange} />
    );
  });

  ControlledWrapper.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: /** @type {Validator<(name: string, value: any) => void>} */ (
      PropTypes.func
    ),
  };

  ControlledWrapper.defaultProps = {
    onChange: () => null,
  };

  return ControlledWrapper;
};

export default withController;
