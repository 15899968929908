import {
  createContext,
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

export const PagePartContext = createContext(null);

export const PagePartProvider = ({ children }) => {
  const [parts, setParts] = useState({});
  const partsRef = useRef(parts);

  const getPart = useCallback((name) => parts[name], [parts]);

  const setPart = useCallback((name, ref) => {
    const newParts = {
      ...partsRef.current,
      [name]: ref,
    };

    partsRef.current = newParts;
    setParts(newParts);
  }, []);

  const value = useMemo(
    () => ({
      getPart,
      setPart,
    }),
    [getPart, setPart]
  );

  return (
    <PagePartContext.Provider value={value}>
      {children}
    </PagePartContext.Provider>
  );
};

export const withPagePartContainer = (Component) => {
  const PagePartContainer = forwardRef((props, ref) => {
    return (
      <PagePartProvider>
        <Component {...props} ref={ref} />
      </PagePartProvider>
    );
  });

  return PagePartContainer;
};
