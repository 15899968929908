import { useReactiveVar } from '@apollo/client';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Datetime, Tooltip } from '@virtidev/toolbox';
import { confirmModalActiveVar } from '../../../../../../../ApolloReactiveVars';
import { getReadableUserType } from '../../helpers/getReadableUserType';
import RestoreMember from '../../../Users/Actions/RestoreMember';
import DeleteMember from '../../../Users/Actions/DeleteMember';
import ChangeMemberType from '../../../Users/Actions/ChangeMemberType';
import ResendWelcomeEmail from '../../../Users/Actions/ResendWelcomeEmail';
import WithConfirmationBox from '../../../../../../../HOCs/WithConfirmationBox';
import { OrgListItem } from './UserRow.styled';
import AddMembersToOrgModal from '../AddMembersToOrgModal/AddMembersToOrgModal';
import RemoveMemberFromOrgModal from '../RemoveMemberFromOrgModal/RemoveMemberFromOrgModal';
import ActionMenuButton from '../../../Users/Actions/ActionMenuButton';
import { useLoginAsUser } from '../../../../../../../utility/LoginUtility';
import StyledLink from '../../../../../../../styled-components/StyledLink';

const UserRow = ({
  user,
  setSelectedIDs,
  selectedIDs,
  disabledFilter,
  variables,
  setListModified,
  confirm,
}) => {
  const [checked, setChecked] = useState(false);
  const [showModal, setShowModal] = useState(false); //currently used for the group modal, but could be used for other updates
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const { loginAsUser, loading: loadingToken } = useLoginAsUser();

  const handleChange = useCallback(
    (e) => {
      if (checked) {
        setChecked(false);
        const filteredIDs = [...selectedIDs].filter((item) => {
          return item !== e.target.value;
        });
        setSelectedIDs(filteredIDs);
      }
      if (!checked) {
        setChecked(true);
        setSelectedIDs([...selectedIDs, e.target.value]);
      }
    },
    [selectedIDs, checked, setSelectedIDs]
  );

  useEffect(() => {
    if (selectedIDs.includes(user.ID)) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [selectedIDs, checked, user]);

  const checkStatus = useCallback((active, disabled, email) => {
    if (disabled) {
      return 'Suspended';
    }
    if (email && email.includes('virti.com')) {
      return 'Virti';
    }
    if (active && !disabled) {
      return 'Active';
    }
    if (!active && !disabled) {
      // active in the db means they have logged in once, active in the list currently means they count toward a licese. This needs to cleared up.
      return 'Active';
    }
    return 'Unknown';
  }, []);

  const confirmModalActive = useReactiveVar(confirmModalActiveVar);

  const userType = useMemo(
    () => getReadableUserType(user.UserType),
    [user.UserType]
  );
  const userStatus = useMemo(
    () => checkStatus(user.Active, user.Disabled, user.Email),
    [user, checkStatus]
  );

  return (
    <tr id={user.ID}>
      <td style={{ width: '10px' }}>
        {user.UserType !== 'customeradmin' && (
          <input
            type="checkbox"
            value={user.ID}
            checked={checked}
            onChange={handleChange}
          />
        )}
      </td>
      <td>
        <StyledLink to={`/users/${user.ID}`}>{user.Name || '–'}</StyledLink>
      </td>
      <td>{user.Email}</td>
      <td>{user.Created}</td>
      <td>
        {user.Organisations.nodes.map((org) => {
          return (
            <OrgListItem key={org.ID}>
              {org.ID} – {org.Name}
            </OrgListItem>
          );
        })}
      </td>
      <td>{userType}</td>
      <td style={{ textAlign: 'center' }}>
        <Datetime datetime={user.LastSeen} emptyDisplay="–" />
      </td>
      <td>{userStatus}</td>
      <td>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {
            <Tooltip
              type="menu"
              icon="dots"
              placement="left-start"
              style={{ color: 'var(--neutral-color)' }}
              confirmModalActive={confirmModalActive || showModal}
            >
              {disabledFilter ? (
                <RestoreMember
                  button={ActionMenuButton}
                  variables={variables}
                  selectedIDs={[user.ID]}
                  setListModified={setListModified}
                  confirm={confirm}
                  setSelectedIDs={setSelectedIDs} //temporary
                />
              ) : (
                user.UserType !== 'customeradmin' && (
                  <DeleteMember
                    button={ActionMenuButton}
                    variables={variables}
                    selectedIDs={[user.ID]}
                    setListModified={setListModified}
                    confirm={confirm}
                    setSelectedIDs={setSelectedIDs} //temporary
                  />
                )
              )}
              {user.UserType !== 'admin' && user.UserType !== 'customeradmin' && (
                <ChangeMemberType
                  button={ActionMenuButton}
                  variables={variables}
                  selectedIDs={[user.ID]}
                  setListModified={setListModified}
                  newUserType={'admin'}
                  confirm={confirm}
                  setSelectedIDs={() => null} //temporary
                />
              )}
              {user.UserType !== 'user' && user.UserType !== 'customeradmin' && (
                <ChangeMemberType
                  button={ActionMenuButton}
                  variables={variables}
                  selectedIDs={[user.ID]}
                  setListModified={setListModified}
                  newUserType={'user'}
                  confirm={confirm}
                  setSelectedIDs={() => null} //temporary
                />
              )}
              {user.UserType !== 'contentcreator' &&
                user.UserType !== 'customeradmin' && (
                  <ChangeMemberType
                    button={ActionMenuButton}
                    variables={variables}
                    selectedIDs={[user.ID]}
                    setListModified={setListModified}
                    newUserType={'contentcreator'}
                    confirm={confirm}
                    setSelectedIDs={() => null} //temporary
                  />
                )}
              {user.UserType !== 'customeradmin' && (
                <ResendWelcomeEmail
                  button={ActionMenuButton}
                  selectedIDs={user.ID}
                  setSelectedIDs={setSelectedIDs}
                />
              )}
              <ActionMenuButton onClick={() => setShowModal(true)}>
                Add to an Org
              </ActionMenuButton>
              <ActionMenuButton onClick={() => setShowRemoveModal(true)}>
                Remove from an Org
              </ActionMenuButton>
              <ActionMenuButton
                onClick={() => loginAsUser(user.ID)}
                disabled={loadingToken}
              >
                Login as this user
              </ActionMenuButton>
            </Tooltip>
          }
        </div>
        <AddMembersToOrgModal
          variables={variables}
          selectedIDs={[user.ID]}
          setListModified={setListModified}
          confirm={confirm}
          setSelectedIDs={() => null} //temporary
          showModal={showModal}
          setShowModal={setShowModal}
        />
        <RemoveMemberFromOrgModal
          variables={variables}
          selectedIDs={[user.ID]}
          setListModified={setListModified}
          confirm={confirm}
          setSelectedIDs={() => null} //temporary
          showModal={showRemoveModal}
          setShowModal={setShowRemoveModal}
          organisations={user.Organisations.nodes}
        />
      </td>
    </tr>
  );
};

export default WithConfirmationBox(UserRow);
