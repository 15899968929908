import { usePageScroll } from '@core/components/Page';
import useSortUrl from '@core/helpers/useSortUrl';
import useUser from '@core/helpers/useUser';
import {
  Datetime,
  FALLBACK,
  Icon,
  Image,
  LoadingFlash,
  Modal,
  PaginationControl,
  Tooltip,
  usePaginationUrl,
  useStateUrl,
} from '@virtidev/toolbox';
import React, { useCallback, useMemo, useState } from 'react';
import { createdTitle as sortOptions } from '@core/helpers/sortCollections';
import {
  ListContainer,
  TagContent,
  HeaderRow,
  Header,
  MediaRow,
  MediaLink,
  MediaThumbnail,
  MediaTooltipTags,
  MediaDetail,
  MenuWrapper,
  LoadingText,
  LoadingTextWrapper,
} from '@core/components/Media/MediaList.styled';
import MediaPageFilters from '@core/components/Media/components/MediaPageFilters/MediaPageFilters';
import { useQuery } from '@apollo/client';
import PageLoadError from '@base/components/PageLoadError';
import { READ_MEDIAS } from './ImagesList.query';
import FilesListTypeButton from '@base/components/FilesListTypeButton';
import { getResizeImage } from '@core/helpers/imageResizing/getResizeImage';
import EditMediaTitleButtonInMenu from '@base/components/buttons/EditMediaTitleButtonInMenu';
import CreateSimulationFromMediaButtonInMenu from '@base/components/buttons/CreateSimulationFromMediaButtonInMenu';
import DeleteMediaButtonInMenu from '@base/components/buttons/DeleteMediaButtonInMenu';
import EditMediaTitleModalForm from '@base/components/buttons/EditMediaTitleModalForm';

/**
 * @typedef {import('@core/models/video-media.types').Media} Media
 */

export const ImagesList = () => {
  const { scrollTop } = usePageScroll();
  const { OrganisationID } = useUser();
  const { pageQuery, controlProps, resetPage } = usePaginationUrl({
    pageSize: 20,
    onChange: scrollTop,
  });
  const { sortField, sortDirection } = useSortUrl(sortOptions);
  const { value: filter } = useStateUrl({
    initialValue: '',
  });
  const { value: tags } = useStateUrl({
    key: 'tag',
  });
  const [editImage, setEditImage] = useState(
    /** @type {string | null} */ (null)
  );
  const [deleteImages, setDeleteImages] = useState(
    /** @type {string[]} */ ([])
  );
  const [currentImage, setCurrentImage] = useState(
    /** @type {Media | null} */ (null)
  );

  const addDeleteImages = useCallback((id) => {
    setDeleteImages((items) => [...items, id]);
  }, []);

  const variables = useMemo(() => {
    /**
     * @type {any}
     */
    const vars = {
      ...pageQuery,
      sort: {
        [sortField]: sortDirection,
      },
      filter: {
        ...(filter ? { Title: { contains: filter || '' } } : {}),
        Archived: { eq: false },
        Organisation: { ID: { eq: OrganisationID } },
        ...(tags
          ? {
              Tags: {
                ID: {
                  in: tags
                    .split(',')
                    .filter(Boolean)
                    .map((id) => (id === '0' ? null : id)),
                },
              },
            }
          : {}),
      },
    };

    return vars;
  }, [filter, pageQuery, OrganisationID, sortDirection, sortField, tags]);

  const { loading, error, data } = useQuery(READ_MEDIAS, {
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const { nodes: images = [], pageInfo } = data?.readMedias || {};

  return (
    <TagContent>
      {!!editImage && (
        <EditMediaTitleModalForm
          hideModal={() => setEditImage(null)}
          modalVisible
          file={images.find(({ ID }) => ID === editImage)}
          type="image"
        />
      )}
      <Modal
        title={currentImage ? currentImage.Title : ''}
        show={!!currentImage?.URL}
        onHide={() => {
          setCurrentImage(null);
        }}
        render={() => currentImage && <Image src={currentImage.URL} />}
      />
      <MediaPageFilters />
      <div>
        {error && <PageLoadError graphQLErrorObj={error} />}
        <HeaderRow $type="image">
          <Header $left>Image name</Header>
          <Header>Tags</Header>
          <Header>Type</Header>
          <Header>Created on</Header>
          <Header>Action</Header>
        </HeaderRow>
        <ListContainer data-testid="files-images-list">
          {loading &&
            [...Array(20)].map((_, index) => (
              <MediaRow key={index + 1} $disabled $type="image">
                <MediaLink $left $bold>
                  <MediaThumbnail src={FALLBACK} cover />
                  <LoadingTextWrapper>
                    <LoadingText />
                    <LoadingFlash />
                  </LoadingTextWrapper>
                </MediaLink>
                <MediaDetail></MediaDetail>
                <MediaDetail $bold>
                  <FilesListTypeButton />
                </MediaDetail>
                <MediaDetail $bold>
                  <LoadingTextWrapper>
                    <LoadingText />
                    <LoadingFlash />
                  </LoadingTextWrapper>
                </MediaDetail>
                <MediaDetail>
                  <Icon icon="dots" />
                </MediaDetail>
              </MediaRow>
            ))}
          {images &&
            images.map((image) => {
              if (!image || deleteImages.includes(image.ID)) {
                return null;
              }
              return (
                <MediaRow key={image.ID} $type="image">
                  <MediaLink $left $bold onClick={() => setCurrentImage(image)}>
                    <MediaThumbnail
                      src={
                        image.TusID
                          ? getResizeImage(image.TusID, { height: 64 })
                          : null
                      }
                      fallback={image.URL}
                      cover
                      alt={`${image.Title} thumbnail`}
                    />
                    <span>{image.Title}</span>
                  </MediaLink>
                  <MediaTooltipTags
                    cardType="image"
                    targetId={image.ID}
                    tags={image.Tags.nodes}
                    editTags
                  />
                  <MediaDetail $bold>
                    <FilesListTypeButton file={image} type="image" />
                  </MediaDetail>
                  <MediaDetail $bold>
                    <Datetime
                      datetime={image.Created}
                      long
                      noTimeDisplay
                      emptyDisplay="-"
                    />
                  </MediaDetail>
                  <MediaDetail>
                    <Tooltip
                      type="menu"
                      icon="dots"
                      placement="bottom-end"
                      autoClose
                    >
                      <MenuWrapper>
                        <EditMediaTitleButtonInMenu
                          ID={image.ID}
                          onEdit={setEditImage}
                          type="image"
                        />
                        <CreateSimulationFromMediaButtonInMenu
                          ID={image.ID}
                          type="image"
                        />
                        <DeleteMediaButtonInMenu
                          file={image}
                          onDelete={addDeleteImages}
                          type="image"
                        />
                      </MenuWrapper>
                    </Tooltip>
                  </MediaDetail>
                </MediaRow>
              );
            })}
          {/* <FilesListDetailed files={images} loading={loading} /> */}

          {pageInfo && (
            <PaginationControl {...controlProps} total={pageInfo.totalCount} />
          )}
        </ListContainer>
      </div>
    </TagContent>
  );
};

export default ImagesList;
