import { gql } from '@apollo/client';
import { pseudoMemberFragment } from './UserQueries';

export const pseudoUnitFragment = `
  ID
  SortOrder
  PassMark
  Assessment
  isCompleted
  isUnlocked
  Type
  Simulation {
    ID
    Title
    Description
    Status
    CoursesOnly
    ImageMedia {
      URL
    }
    FirstVideo: Videos(limit: 1) {
      nodes {
        ID
        PosterURL
      }
    }
    Quiz
  }
  VirtualHuman {
    ID
    Title
    Description
    Status
    CoursesOnly
    Thumbnail {
      ID
      URL
      TusID
    }
    ThumbnailURL
  }
`;

export const pseudoModuleFragment = `
  ID
  Title
  Description
  SortOrder
  UnlockType
  UnlockDate
  UnlockDaysAfterStart
  CourseID
  Units {
    edges {
      node {
        ${pseudoUnitFragment}
      }
    }
  }
`;

export const pseudoCourseFragment = `
  ID
  Title
  Description
  LastEdited
  OrganisationID
  TotalStudents
  TotalSuccessful
  TotalCompletions
  ImageMedia {
    URL
  }
  DueDate
  StartDate
  Status
  Unlocked
  MemberProgress
`;

export const READ_COURSES_PAGINATED_FOR_GROUP = gql`
  query readCoursesPaginated(
    $limit: Int!,
    $offset: Int!,
    $filter: CourseFilterFields,
  ) {
    readCourses(
      offset: $offset,
      limit: $limit,
      filter: $filter,
    ) {
      pageInfo {
        totalCount
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ${pseudoCourseFragment}
          Modules {
            nodes {
              ${pseudoModuleFragment}
            }
          }
        }
      }
    }
  }
`;

// ${simulationDataFragments.simulationData}

export const EDIT_COURSE = gql`
  mutation updateCourse($Input: UpdateCourseInput!) {
    updateCourse(input: $Input) {
      ${pseudoCourseFragment}
      OrganisationGroups {
        edges {
          node {
            ID
          }
        }
      }
    }
  }
`;
// ${CourseDataFragments.CourseData}

export const READ_COURSE = gql`
query readOneCourse($ID: ID!) {
  readOneCourse(filter: { ID: { eq: $ID } }) {
    ${pseudoCourseFragment}
    OrganisationGroups {
      edges {
        node {
          ID
        }
      }
    }
  }
}
`;

export const DELETE_COURSES = gql`
  mutation deleteCourses($IDs: [ID]!) {
    deleteCourses(ids: $IDs)
  }
`;
