import styled from 'styled-components';
import { Table, LoadingPlaceholder } from '@virtidev/toolbox';

export const Message = styled.div`
  text-align: center;
  padding: 1rem;
  color: var(--tertiary-font-color);
`;
export const StyledLoadingPlaceholder = styled(LoadingPlaceholder)`
  margin: 2rem auto;
`;

export const StyledTable = styled(Table)`
  margin: 1rem 0;
`;

export const Td = styled.td`
  text-align: ${(props) => props.align || 'left'};
`;

export const Th = styled.th`
  text-align: ${(props) => props.align || 'left'};
`;

export const GreyText = styled.span`
  color: var(--tertiary-font-color);
`;
