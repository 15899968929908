import { gql } from '@apollo/client';

export const READ_ORGANISATIONS = gql`
  query readOrganisations($filter: String, $archived: Boolean) {
    readOrganisations(
      filter: { Name: { contains: $filter }, Archived: { eq: $archived } }
    ) {
      edges {
        node {
          ID
          Name
        }
      }
    }
  }
`;
