import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import LinkWithPrevState from '../components/LinkWithPrevState';
import styled, { css } from 'styled-components';
import NavBar from '../components/NavBar';
import Icon from '../components/icons/Icon';
import FlashMessageHolder from '../components/FlashMessageHolder';
import Breakpoints from '../themes/Breakpoints';
import { useReactiveVar } from '@apollo/client';

import {
  sidebarContractedVar,
  showOnboardDialogueVar,
} from '../ApolloReactiveVars';
import OnboardingDialogue from './OnboardingDialogue';
import Sidebar from '../apps/core/src/components/Sidebar/Sidebar';
import { useLocation } from 'react-router-dom';

const StyledPageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const StyledPage = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: ${(props) =>
    props.hasSaveBar ? 'var(--savebar-height-space-allowance)' : '0'};
  flex: 1;
  overflow: hidden; /* allows child flexbox elemnts to scroll overflow */
`;

const StyledTopArea = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.gridCols ? props.gridCols : '40% 1fr 40%'};
  grid-template-rows: ${(props) =>
    props.gridRows ? props.gridRows : 'var(--input-height)'};
  > div {
    display: flex;
    align-items: center;
    > * {
      margin-left: 1rem;
    }
    &:last-child {
      justify-content: flex-end;
    }
  }
`;

const FlexTopArea = styled(StyledTopArea)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const PageStretch = styled.div`
  margin-top: calc(var(--nav-bar-height) * 0.8);
  flex: 1;
  display: flex;
`;

const StyledPageContentOuter = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  transition: margin-left var(--sidebar-anim-duration);
  padding-bottom: 3rem;

  margin-top: ${(props) => {
    if (props.showOnboardDialogue && props.hasBackbar) {
      return 'calc(var(--breadcrumbs-calculated-height) + 9rem)';
    } else {
      return props.hasBackbar ? 'var(--breadcrumbs-calculated-height)' : '0';
    }
  }};

  overflow: hidden; /* allows child flexbox elemnts to scroll overflow */
  ${(props) =>
    props.stretchFullHeight &&
    css`
      height: 100%;
      padding-bottom: 0;
    `}
`;

const StyledPageContentInner = styled.div`
  padding: 1rem var(--content-side-padding) 1.5rem;
  display: flex;
  flex-direction: column;
  flex: ${(props) => props.flex || 1};
`;

export const PageContent = styled.div`
  margin: 2rem;
  padding-top: ${(props) => (props.noTopPad ? '0' : '1rem')};
  margin-top: ${(props) => (props.noTopPad ? '0' : '2rem')};
`;

export const PageContentCard = styled.div`
  padding: 2rem;
  border-radius: var(--card-border-radius);
  background-color: var(--card-bg-color);
  box-shadow: var(--card-box-shadow);
`;

export const StyledPageContentCenterPadded = styled.div`
  max-width: 60rem;
  margin-left: auto;
  margin-right: auto;
`;

export const StyledPageContentCenterPaddedLarge = styled(
  StyledPageContentCenterPadded
)`
  max-width: 80rem;
`;

export const StyledPageWidthMaximum = styled.div``;

const StyledPageContentInnerNoFlex = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 var(--content-side-padding);
  gap: var(--content-side-padding);
`;

const StyledBackBar = styled.div`
  position: fixed;
  z-index: 1003;
  ${(props) => {
    if (props.sidebarContracted === null) {
      return css`
        width: calc(100% - var(--sidebar-width-expanded));
        left: var(--sidebar-width-expanded);
        @media (max-width: ${Breakpoints.sidebarContractMaxWidth}) {
          width: calc(100% - var(--sidebar-width-contracted));
          left: var(--sidebar-width-contracted);
        }
      `;
    }
    let width = 'var(--sidebar-width-contracted)';
    if (!props.sidebarContracted) {
      width = 'var(--sidebar-width-expanded)';
    }
    return css`
      left: ${width};
      width: calc(100% - ${width});
    `;
  }}
  transition: left var(--sidebar-anim-duration);
  top: var(--nav-bar-height);
  height: var(--breadcrumbs-height);
  padding: var(--breadcrumbs-padding);
  display: ${(props) => (props.active ? 'flex' : 'none')};
  align-items: center;
`;

const GoBackLinkText = styled.span`
  margin-left: 0.6rem;
  vertical-align: middle;
`;

const BackBar = ({
  goBackToLink,
  fallbackLink,
  goBackToLinkText,
  fallbackText,
  ...props
}) => {
  const { state } = useLocation();
  const sidebarContracted = useReactiveVar(sidebarContractedVar);

  const backLink = useMemo(() => {
    if (goBackToLink) {
      return goBackToLink;
    }
    if (state?.prevPath) {
      return state.prevPath;
    }
    return fallbackLink;
  }, [state, goBackToLink, fallbackLink]);

  const backText = useMemo(() => {
    if (goBackToLinkText) {
      return goBackToLinkText;
    }
    if (state?.prevTitle) {
      return state.prevTitle;
    }
    return fallbackText;
  }, [state, goBackToLinkText, fallbackText]);

  return (
    <StyledBackBar
      {...props}
      sidebarContracted={sidebarContracted}
      active
      goBackToLink={backLink}
    >
      {backLink && (
        <LinkWithPrevState to={backLink}>
          <Icon type="top_menu" size={null} name="back-arrow" />
          <GoBackLinkText>{`Back to ${backText}`}</GoBackLinkText>
        </LinkWithPrevState>
      )}
    </StyledBackBar>
  );
};

const BackBarButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  ${(props) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `};
  > button {
    margin-left: 1rem;
  }
`;

BackBar.propTypes = {
  goBackToLink: PropTypes.string,
  goBackToLinkText: PropTypes.string,
};

BackBar.defaultProps = {
  fallbackLink: null,
  fallbackText: 'previous page',
};

const PageSidebar = styled(Sidebar)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
`;

const Page = (props) => {
  const sidebarContracted = useReactiveVar(sidebarContractedVar);
  const showOnboardDialogue = useReactiveVar(showOnboardDialogueVar);

  return (
    <StyledPageWrapper>
      <NavBar pageKey={props.pageKey} />
      <OnboardingDialogue />

      <FlashMessageHolder />
      <PageStretch className="virti-page-stretch">
        <StyledPage hasSaveBar={props.hasSaveBar}>
          <PageSidebar />

          <StyledPageContentOuter
            className="virti-page-outer"
            stretchFullHeight={props.stretchFullHeight}
            hasBreadcrumbs={props.goBackToLink && props.goBackToLinkText}
            hasSaveBar={props.hasSaveBar}
            hasBackbar={props.hasBackbar}
            sidebarContracted={sidebarContracted}
            showOnboardDialogue={showOnboardDialogue}
          >
            {props.children}
          </StyledPageContentOuter>
        </StyledPage>
      </PageStretch>
    </StyledPageWrapper>
  );
};

Page.defaultProps = {
  hasSaveBar: false,
  renderBackBar: true,
};

Page.propTypes = {
  goBackToLink: PropTypes.string,
  goBackToLinkText: PropTypes.string,
  hasSaveBar: PropTypes.bool,
  pageKey: PropTypes.string,
  renderBackBar: PropTypes.bool,
  onboardingData: PropTypes.object,
};

export default Page;
export {
  StyledPageContentInner,
  StyledPageContentInnerNoFlex,
  StyledTopArea,
  BackBar,
  BackBarButtons,
  FlexTopArea,
};
