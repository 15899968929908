import wrapUnitType from "../../../helpers/wrapUnitType";

export const getUnitOrder = (edges, ID, dragUnit, dropUnit) => {
  if (ID === dropUnit.moduleId) {
    const newUnitIds = dropUnit.units
      .map(({ ID }) => ID)
      .filter((ID) => ID !== dragUnit.data.ID);

    newUnitIds.splice(dropUnit.index, 0, dragUnit.data.ID);

    return newUnitIds.map(wrapUnitType);
  }

  if (ID === dragUnit.moduleId) {
    const newUnitIds = dragUnit.units
      .map(({ ID }) => ID)
      .filter((ID) => ID !== dragUnit.data.ID);

    return newUnitIds.map(wrapUnitType);
  }

  return edges.map(({ node: { ID } }) => ID).map(wrapUnitType);
};

export default getUnitOrder;
