import { LoadingContainer, LoadingFlash } from './LoadingPlaceholder.styled';

export const LoadingPlaceholder = ({ children, ...props }) => (
  <LoadingContainer {...props}>
    {children}
    <LoadingFlash />
  </LoadingContainer>
);

export default LoadingPlaceholder;
