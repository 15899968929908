import { gql } from '@apollo/client';

export const READ_MEDIAS = gql`
  query readMediaPageMedia(
    $limit: Int
    $offset: Int
    $sort: MediaSortFields
    $filter: MediaFilterFields
  ) {
    readMedias(
      FileType: "image"
      filter: $filter
      limit: $limit
      offset: $offset
      sort: $sort
    ) {
      pageInfo {
        totalCount
        hasNextPage
        hasPreviousPage
      }
      nodes {
        ID
        URL
        Title
        Created
        TusID
        Width
        Height
        Content360
        Archived
        Tags {
          nodes {
            ID
            Name
          }
        }
        Videos {
          nodes {
            ID
            Simulations {
              nodes {
                ID
                Title
              }
            }
          }
        }
        EmbeddedImageInEvents {
          nodes {
            ID
            Label
            Timestamp
            SimulationID
            VideoID
          }
        }
        Resources {
          nodes {
            ID
            Title
            CourseID
            Course {
              ID
              Title
            }
            SimulationID
            Simulation {
              ID
              Title
            }
          }
        }
      }
    }
  }
`;
