import {
  createContext,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
  FC,
} from 'react';
import useUser from '../../helpers/useUser';
import DeepLinkBanner from './components/DeepLinkBanner/DeepLinkBanner';
import { IsMobile } from '../../../../../utility/BrowserCheck';
import { generateDeepLink } from './helpers/generateDeepLink';

export const deepLinkContext = createContext(
  /**
   * @type {Dispatch<SetStateAction<string | null>>}
   */
  (value) => undefined
);

export const deepLinkValueContext = createContext(
  /**
   * @type {string | null}
   */
  (null)
);

/**
 * @type {FC<{}>}
 */
export const DeepLinkProvider = ({ children }) => {
  const [link, setLink] = useState(/** @type {string | null} */ (null));
  const { OrganisationID } = useUser();

  const deepLink = useMemo(() => {
    // skip if no link or for non-mobiles and non-tablets
    if (!link || !IsMobile()) {
      return null;
    }
    const currentSearch = new URLSearchParams(
      window.location.search.split('?')[1]
    );
    if (currentSearch.has('organisationID') || currentSearch.has('redirect')) {
      return null;
    }

    return generateDeepLink(link, OrganisationID);
  }, [link, OrganisationID]);

  return (
    <deepLinkContext.Provider value={setLink}>
      <deepLinkValueContext.Provider value={deepLink}>
        {deepLink && <DeepLinkBanner key={deepLink} deepLink={deepLink} />}
        {children}
      </deepLinkValueContext.Provider>
    </deepLinkContext.Provider>
  );
};
