import { ForwardRefRenderFunction, forwardRef, Ref } from 'react';
import { Background, Box, Checkmark, Input } from './Checkbox.styled';

export const Checkbox = forwardRef(
  /**
   * @type {ForwardRefRenderFunction<HTMLInputElement, JSX.IntrinsicElements["input"]>}
   */
  (
    ({ className, size, children, ...props }, ref) => {
      const { disabled } = props;

      return (
        <Box className={className} $disabled={disabled} $size={size}>
          <Input ref={ref} {...props} />
          <Background>
            <Checkmark $size={size} />
          </Background>
          {children}
        </Box>
      );
    }
  )
);

export default Checkbox;
