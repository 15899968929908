import React, { useMemo } from 'react';
import { StyledProgressBar } from '../../../../../../../styled-components/StyledOnboardingDashboard';
import { StyledProgressWrapper } from './OnboardingUsage.styled';

export default function ProgressPercent({ progress }) {
  const parsedPR = useMemo(() => {
    const parsed = JSON.parse(progress);
    if (!parsed) {
      return null;
    }
    const progressObj = parsed && parsed.progress;
    const progressKeys = progress && Object.keys(progressObj);

    const count =
      progressKeys &&
      progressKeys.reduce((acc, item) => {
        if (progressObj[item]) {
          return acc + 1;
        }
        return acc;
      }, 0);

    const percent =
      progressKeys && Math.floor((count / progressKeys.length) * 100);

    return percent;
  }, [progress]);

  return (
    <StyledProgressWrapper>
      {parsedPR}%
      <StyledProgressBar value={parsedPR} max="100" width={'100%'} />
    </StyledProgressWrapper>
  );
}
