import styled, { css } from 'styled-components';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 14px;
  border-radius: var(--card-border-radius, 24px);
  background-color: var(--card-bg-color, #fff);
  box-shadow: var(--card-box-shadow);
  padding: 45px;
`;

export const ButtonContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: ${theme.spacing.md};
    margin-top: 6px;
  `}
`;

export const Header = styled.h2`
  font-size: 1.8rem;
`;
