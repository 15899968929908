import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
// import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
// import 'react-circular-progressbar/dist/styles.css';

// circle-based
// const CharLengthIndicator = ({ currentLength, maxLength, optimumLength }) => {
//   const percentage = (currentLength / maxLength) * 100;
//   return (
//     <CircularProgressbar
//       value={percentage}
//       strokeWidth={50}
//       // text={currentLength}
//       maxValue={maxLength}
//       styles={buildStyles({
//         // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
//         strokeLinecap: 'butt',
//         // Text size
//         textSize: '16px',
//         // How long animation takes to go from one percentage to another, in seconds
//         pathTransitionDuration: 0.1,
//         // Can specify path transition in more detail, or remove it entirely
//         // pathTransition: 'none',
//         // Colors
//         pathColor: `rgba(62, 152, 199, ${percentage / 100})`,
//         textColor: '#FFF',
//         trailColor: '#d6d6d6',
//         backgroundColor: '#3e98c7',
//       })}
//     />
//   )
// }

const StyledIndicator = styled.div`
  color: var(--char-counter-okay-text-color);
  ${props => props.currentLength / props.maxLength > 0.8 && css`
    color: var(--char-counter-caution-text-color);
  `}
  /* ${props => props.percentageFromOptimum > 10 && css`
    color: orange;
  `} */
  ${props => (props.atMaximum || !props.overMinimum) && css`
    color: var(--char-counter-max-text-color);
  `}
  position: absolute;
  right: 0;
  /* we want to move the indicator to the left if a textarea is using cols, so that it doesn't get positioned far to the right */
  ${props => props.usesCols && css`
    position: relative;
    margin-right: auto;
  `}
  cursor: default;
  transition: all .5s;
  ${props => !props.isTextArea && css`
    /* bottom: -1.5rem; */
    top: 50%;
    transform: translateY(-50%);
  `}
  max-width: var(--char-counter-width);
  text-overflow: ellipsis;
  overflow: hidden;
`

const CharLengthIndicator = ({ currentLength, minLength, maxLength, optimumLength, isTextArea, className, usesCols }) => {
  const percentage = (currentLength / maxLength) * 100;
  const optimumPercentage = (optimumLength / maxLength) * 100;
  return (
    <StyledIndicator
      isTextArea={isTextArea}
      className={className}
      atMaximum={currentLength >= maxLength}
      overMinimum={currentLength >= minLength}
      percentage={percentage}
      maxLength={maxLength}
      currentLength={currentLength}
      usesCols={usesCols}
      // percentageFromOptimum={Math.abs(percentage - optimumPercentage)} 
      title={`You are at ${currentLength} of ${maxLength} maximum characters`}
    >
      {currentLength}
      {/* {`${currentLength} / ${maxLength}`} */}
    </StyledIndicator>
  )
}

CharLengthIndicator.defaultProps = {
  minLength: 0,
  isTextArea: false
}

CharLengthIndicator.propTypes = {
  maxLength: PropTypes.number.isRequired,
  minLength: PropTypes.number,
  currentLength: PropTypes.number,
  optimumLength: PropTypes.number,
  isTextArea: PropTypes.bool,
  usesCols: PropTypes.bool,
};

export default CharLengthIndicator;