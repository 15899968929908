import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './VideoResult.styled';
import { ADD_VIDEO_FROM_URL } from '../../../../../../queries/AssetQueries';
import VideoTrimmer from '../VideoTrimmer/VideoTrimmer';
import ScreenCaptureUploader from '../ScreenCaptureUploader/ScreenCaptureUploader';
import { ToDecimalPlace } from '../../../../../../utility/NumberFormatting';
import { Icon } from '@virtidev/toolbox';
import { useMutation } from '@apollo/client';
import useFlashMessage from '../../FlashMessage';
import useUser from '../../../helpers/useUser';

const VideoResult = ({ blob, chunks, recordedDurationMs }) => {
  const videoRef = useRef();
  const [videoDuration, setVideoDuration] = useState(0);
  const [videoCursor, setVideoCursor] = useState(0);
  const [uploadBlob, setUploadBlob] = useState(null);
  const [progress, setProgress] = useState(0);
  const [trim, setTrim] = useState(null);
  const [uploading, setUploading] = useState(false);
  const user = useUser();
  const { addFlashMessage } = useFlashMessage();
  const [mutateAddVideoObject, { loading: mutatingSaveUpload }] = useMutation(
    ADD_VIDEO_FROM_URL,
    {
      onCompleted: () => {
        addFlashMessage(
          'Upload Successful. It is now transcoding and will show up on the Media Videos page.'
        );
      },
      onError: () => {
        addFlashMessage('Upload Failed', 'error');
      },
      update: (cache) => {
        cache.evict({ fieldName: 'readVideoMedias' });
      },
    }
  );

  const handleDownload = () => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'virti_recording.webm';
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 100);
  };
  // changing the uploadBlob property will trigger the upload
  const handleUpload = () => {
    setUploadBlob(blob);
  };
  useEffect(() => {
    if (blob) {
      const url = window.URL.createObjectURL(blob);
      videoRef.current.src = url;
      setVideoDuration(videoRef.current.duration);

      return () => {
        window.URL.revokeObjectURL(url);
      };
    }
  }, [blob]);

  return (
    <Styled.VideoArea show={!!blob}>
      <Styled.VideoWrapper>
        <Styled.Video
          onTimeUpdate={(e) => setVideoCursor(e.target.currentTime)}
          onDurationChange={(e) => setVideoDuration(e.target.duration)}
          ref={videoRef}
          controls={true}
        />
        <VideoTrimmer
          videoRef={videoRef}
          disabled={uploading || mutatingSaveUpload}
          // awkward chrome bug where videoDuration is Infinity sometimes
          videoDuration={
            videoDuration === Infinity
              ? recordedDurationMs / 1000
              : videoDuration
          }
          videoCursor={videoCursor}
          onTrimChange={(minTrim, maxTrim) => {
            setTrim({ min: minTrim, max: maxTrim, length: maxTrim - minTrim });
          }}
          onVideoTimeChange={(newTime) => {
            if (
              isNaN(newTime) ||
              newTime === undefined ||
              newTime === null ||
              newTime === false ||
              newTime === Infinity
            )
              return;
            videoRef.current.currentTime = newTime;
            setVideoCursor(newTime);
          }}
          blob={blob}
        />
        <Styled.ControlButtons>
          <Styled.DownloadButton type="new-primary" onClick={handleDownload}>
            <Icon icon="download" type="outline" />
            <span>Download Full Video</span>
          </Styled.DownloadButton>
          <Styled.UploadButton
            type="new-primary"
            disabled={uploading || mutatingSaveUpload}
            onClick={handleUpload}
          >
            {mutatingSaveUpload && 'Trimming...'}
            {!mutatingSaveUpload && (
              <>{progress ? progress + '%' : 'Upload and Trim'}</>
            )}
          </Styled.UploadButton>
          <ScreenCaptureUploader
            uploadBlob={uploadBlob}
            onSending={() => {
              setUploading(true);
            }}
            onComplete={(upload, tusID) => {
              setUploading(false);
              setProgress(0);
              setUploadBlob(null);
              mutateAddVideoObject({
                variables: {
                  URL: upload.url,
                  TusID: tusID,
                  Filename: 'screen_capture.webm',
                  OrganisationID: user.OrganisationID,
                  StartClip: trim.min,
                  ClipLength: trim.length,
                },
              });
            }}
            onError={(e) => {
              console.error(e);
              setUploadBlob(null);
            }}
            onCancel={() => {
              setProgress(0);
              setUploading(false);
              setUploadBlob(null);
            }}
            onProgress={(progress) => {
              setProgress(ToDecimalPlace(progress, 0));
            }}
          />
        </Styled.ControlButtons>
      </Styled.VideoWrapper>
    </Styled.VideoArea>
  );
};

VideoResult.propTypes = {
  recordedDurationMs: PropTypes.number,
  blob: PropTypes.object,
  chunks: PropTypes.object,
};

export default VideoResult;
