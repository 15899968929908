import { gql } from '@apollo/client';

export const UPDATE_FEEDBACK_OPTION = gql`
  mutation updateFeedbackOption($input: UpdateFeedbackOptionInput!) {
    updateFeedbackOption(input: $input) {
      ID
      Value
      Name
    }
  }
`;

// this should be moved
export const CREATE_FEEDBACK_OPTION = gql`
  mutation createFeedbackOption($input: CreateFeedbackOptionInput!) {
    createFeedbackOption(input: $input) {
      ID
      Value
      Name
    }
  }
`;

export const READ_QUESTION = gql`
  query readOneQuestion($filter: QuestionFilterFields!) {
    readOneQuestion(filter: $filter) {
      ID
      Title
      Options {
        nodes {
          ID
          Name
        }
      }
    }
  }
`;

export const DELETE_FEEDBACK_OPTIONS = gql`
  mutation deleteFeedbackOptions($ids: [ID]!) {
    deleteFeedbackOptions(ids: $ids)
  }
`;
