import styled, { css } from 'styled-components';

export const TabbedItem = styled.button`
  ${({ theme: { color } }) => css`
    position: relative;
    border: none;
    background: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.3rem;
    cursor: pointer;
    margin: 1px;
    padding: 0;
    align-items: flex-start;
    img {
      aspect-ratio: 600 / 338;
      width: 100%;
      max-height: 100%;
      background-color: ${({ theme }) => theme.color.lightGrey};
    }
  `}
`;

export const TabbedItemImg = styled.img`
  ${({ $active, theme: { color } }) =>
    $active &&
    css`
      border-color: ${color.pink};
      color: ${color.pink};
      outline: 2px solid ${color.pink};
    `}
`;

export const TabbedItemLabel = styled.div`
  ${({ $active, theme: { color } }) =>
    $active &&
    css`
      color: ${color.pink};
    `}
  padding: 0 0.25rem;
`;

export const CircleTick = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  ${({ theme: { color } }) => css`
    background-color: ${color.pink};
  `}
  > div {
    position: relative;
    top: 1px;
  }
`;
