import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import StyledLabel from './StyledLabel';
import HelpTooltip from '../components/HelpTooltip';
import LoadingIndicator from '../components/LoadingIndicator';

const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${props => props.labelPosition === "left" && css`
    label { 
      margin-bottom: 0;
      margin-right: 1rem;
    }
  `}
`

const StyledInnerWrapper = styled.div`
  display: flex;
  ${props => props.fillWidth && css`
    flex: 1;
  `}
`

const StyledSelect = styled.select`
  padding: 0 1.2rem;
  font-size: 0.8rem;
  cursor: pointer;
  border: var(--input-border);
  ${props => props.invalid && css`
    border-color: var(--validation-error-input-border-color);
  `}
  flex: 1;
  &:focus {
    outline: var(--input-focus-color) auto;    
  }
  color: var(--primary-font-color);
  padding: var(--secondary-button-padding); 
  border-radius: var(--input-border-radius);
  background-color: var(--input-bg-color);
  font-size: 0.9rem;
  font-family: var(--text-font);
  border: var(--input-border);
  height: var(--input-height);
  ${props => props.disabled && css`
    background-color: var(--disabled-form-input-bg-color);
  `}
  ${props => props.filterStyle && css`
    background:transparent;
    text-align-last:right;
    border-bottom: 1px solid var(--primary-font-color);
    border-radius:0;
    &:focus {
      outline:0;
    }
    option {
      direction: ltr;
    }
  `}
  ${props => props.isCurrentlyDefaultValue && css`
    color: var(--white-input-placeholder-color);
    option:not(:first-child) {
      color: var(--primary-font-color);
    }
  `}
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  font-size: 1rem;
  display: inline-block;
  margin-left: 0.4rem;
  position: relative;
  top: 1px;
`

const Select = props => {
  return (
    <StyledInputWrapper labelPosition={props.labelPosition} className={props.className}>
      {props.label && (
        <StyledLabel htmlFor={props.id} tooltip={props.labelTooltip}>
          {props.label} {props.loading && <StyledLoadingIndicator />}
        </StyledLabel>
      )}
      <StyledInnerWrapper fillWidth={props.fillWidth}>
        <StyledSelect
          id={props.id}
          name={props.name || props.id}
          data-testid={props.dataTestId}
          invalid={props.invalid}
          type={props.type}
          onChange={props.onChange}
          value={props.value}
          filterStyle={props.filterStyle}
          disabled={props.disabled}
          isCurrentlyDefaultValue={props.isCurrentlyDefaultValue}
        >
          {props.children}
        </StyledSelect>
        {
          props.tooltip && (
            <HelpTooltip text={props.tooltip} side={props.tooltipSide} />
          )
        }
      </StyledInnerWrapper>
    </StyledInputWrapper>
  );
}

Select.defaultProps = {
  value: '',
  type: 'text',
  invalid: false,
  fillWidth: false,
  labelPosition: 'top'
};

Select.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  fillWidth: PropTypes.bool,
  labelPosition: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipSide: PropTypes.string,
  labelTooltip: PropTypes.string,
  disabled: PropTypes.bool,
  isCurrentlyDefaultValue: PropTypes.bool,
  loading: PropTypes.bool,
};

export default Select;