import { useEffect, useMemo, useState } from 'react';
import {
  FlavourEllipsis,
  FlavourEllipsisDot,
  FlavourText,
  FlavourTextWrapper,
} from './LoadingFlavourText.styled';

/**
 * @typedef {import('./LoadingFlavourText.types').LoadingFlavourTextProps} LoadingFlavourTextProps
 */

/**
 * @type {import('react').FC<LoadingFlavourTextProps>}
 */

const LoadingFlavourText = ({ flavourTexts }) => {
  const [flavourTextIndex, setFlavourTextIndex] = useState(0);

  useEffect(() => {
    if (flavourTextIndex + 1 >= flavourTexts.length) {
      return;
    }
    const diff =
      flavourTexts[flavourTexts.length - 1].max -
      flavourTexts[flavourTexts.length - 1].min;
    const randomFromDiff = Math.random() * diff;
    const timeoutSecs =
      flavourTexts[flavourTexts.length - 1].min + randomFromDiff;
    console.log(timeoutSecs);
    const tick = () => {
      setFlavourTextIndex((index) => index + 1);
    };
    const timeout = setTimeout(tick, timeoutSecs * 1000);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [flavourTextIndex, flavourTexts]);

  return (
    <FlavourTextWrapper>
      <FlavourText key={flavourTexts[flavourTextIndex].text}>
        <span>{flavourTexts[flavourTextIndex].text}</span>
        <FlavourEllipsis>
          <FlavourEllipsisDot>.</FlavourEllipsisDot>
          <FlavourEllipsisDot>.</FlavourEllipsisDot>
          <FlavourEllipsisDot>.</FlavourEllipsisDot>
        </FlavourEllipsis>
      </FlavourText>
    </FlavourTextWrapper>
  );
};

export default LoadingFlavourText;
