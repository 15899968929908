import { useQuery } from '@apollo/client';
import { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import useUser from '../../../../../helpers/useUser';
import Select from '../../Select';
import _ from 'lodash';
import { useSelectFilter } from '../../helpers/useSelectFilter';
import { READ_COURSES } from './SelectCourse.query';

export const SelectCourse = ({ options, loading, ...props }) => {
  const { OrganisationID } = useUser();
  const [filter, filterProps] = useSelectFilter();

  const { data: courseData, loading: courseLoading } = useQuery(READ_COURSES, {
    variables: {
      OrganisationID,
      filter: {
        Title: { contains: filter },
        Organisation: { ID: { eq: OrganisationID } },
      },
    },
    onError: (error) => console.log(error),
  });

  const groups = useMemo(() => {
    if (!courseData) {
      return [];
    }
    return courseData.readCourses.edges.map(({ node }) => ({
      value: node.ID,
      label: node.Title,
    }));
  }, [courseData]);

  useEffect(() => {
    const { inputValue, ref } = filterProps;
    if (!courseLoading && inputValue) {
      ref.current.focus();
      ref.current.onMenuOpen();
    }
    // I do not want this to activate with inputValue change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseLoading]);

  const allOptions = useMemo(() => [...options, ...groups], [options, groups]);

  return (
    <Select
      {...props}
      {...filterProps}
      options={allOptions}
      loading={courseLoading || loading}
    />
  );
};

SelectCourse.defaultProps = {
  options: [],
  loading: false,
};

SelectCourse.propTypes = {
  options: PropTypes.arrayOf(PropTypes.any),
  loading: PropTypes.bool,
};

export default SelectCourse;
