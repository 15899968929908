import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import useUser from '../../../../../helpers/useUser';
import getConfig from '../../../../../../../../lib/config';
import useSaveTusFile from '../../../../../../../../utility/VHHooks/API/useSaveTusFile';
import { useFlashMessage } from '../../../../FlashMessage';
import SimpleUploader from '../../../../Upload/SimpleUploader/SimpleUploader';
import VHAIFileItem from './components/VHAIFileItem/VHAIFileItem';
import SimpleUploaderPlaceholder from '../../../../Upload/SimpleUploader/components/SimpleUploaderLoadingPlaceholder/SimpleUploaderLoadingPlaceholder';

const VHAIFiles = ({ setValue, name, onChange }) => {
  const [files, setFiles] = useState([]);
  const fileIds = useMemo(() => files.map((file) => file.id), [files]);
  const { addFlashMessage } = useFlashMessage();

  const { saveTusFile, isLoading } = useSaveTusFile({
    onComplete: (res) => {
      if (res.data?.data?.file) {
        addFlashMessage('File successfully uploaded');
        setFiles((files) => [...files, res.data.data.file]);
        setValue('fileIds', [...fileIds, res.data.data.file.id]);
      } else {
        addFlashMessage('Failed to add file', 'error');
      }
    },
  });
  const handleUploaded = useCallback(
    (upload, tusId) => {
      saveTusFile({
        file: upload.file,
        tusId,
        tusUrl: upload.url,
        parseText: true,
      });
      onChange();
    },
    [saveTusFile, onChange]
  );

  const handleFileItemDelete = useCallback(
    (fileId) => {
      setValue(
        'fileIds',
        fileIds.filter((id) => id !== fileId)
      );
      setFiles((files) => files.filter((file) => file.id !== fileId));
      onChange();
    },
    [fileIds, setValue, onChange]
  );

  return (
    <div>
      <SimpleUploader
        name={name}
        tusEndpointOverride={getConfig(
          'REACT_APP_TUS_VH_UPLOAD_ENDPOINT_OTHER_MEDIA'
        )}
        onUploaded={handleUploaded}
        acceptedFileMimeTypes={[
          'application/pdf',
          'text/plain',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ]}
        text="Drag or Select File (.pdf, .doc, .docx, .txt)"
      />
      {files.map((file) => (
        <VHAIFileItem
          onDelete={handleFileItemDelete}
          key={file.id}
          file={file}
        />
      ))}
      {isLoading && <SimpleUploaderPlaceholder />}
    </div>
  );
};

VHAIFiles.propTypes = {
  setValue: PropTypes.func.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default VHAIFiles;
