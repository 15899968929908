import { Link as DomLink } from 'react-router-dom';
import styled from 'styled-components';
import { Icon as VirtiIcon } from '@virtidev/toolbox';

export const Link = styled(DomLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.75rem;
  color: var(--menu-font-color);

  &:hover {
    color: var(--primary-color);
  }
`;

export const Icon = styled(VirtiIcon)`
  margin-right: 1rem;
`;
