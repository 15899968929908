import { useLocation } from 'react-router-dom';

export const useRouterQuery = () => {
  const location = useLocation();
  if (location.search) {
    return new URLSearchParams(location.search);
  }

  // fallback, for unknown reasons pathname contains the search in some circumstances
  const path = location.pathname.split('?')[1];
  if (path) {
    return new URLSearchParams(path);
  }

  return new URLSearchParams(window.location.search);
};
