import { gql } from '@apollo/client';

export const ADD_MEMBERS_TO_ORG = gql`
  mutation addMembersToOrganisation($OrganisationID: ID!, $MemberIDs: [ID!]) {
    addMembersToOrganisation(
      OrganisationID: $OrganisationID
      MemberIDs: $MemberIDs
    ) {
      ID
    }
  }
`;

export const MEMBER_FRAGMENT = gql`
  fragment member on Members {
    ID
    Name
    Organisations {
      nodes {
        ID
        Title
      }
    }
  }
`;
