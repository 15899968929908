import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledCustomisedInputWrapper,
  StyledCustomisedInputEditWrapper,
  StyledCustomisedInputPreviewWrapper,
  StyledCustomisedInputEditInputWrapper,
  StyledCustomisedInputPreviewInputWrapper,
  StyledCustomisedInputHeader,
  StyledLabel,
} from '../../styled-components/StyledCustomisedInputs';

import StyledTextArea from '../../styled-components/StyledTextArea';
import Details from '../../apps/core/src/components/FeedbackForms/Question/Details/Details';
import Label from '../../apps/core/src/components/form/Label';
import { Icon, TextInput } from '@virtidev/toolbox';

function CustomisedInputTextarea(props) {
  return (
    <>
      {props.editing && (
        <StyledCustomisedInputEditWrapper>
          <Details
            editing={props.editing}
            register={props.register}
            typeTitle="Paragraph Question"
            info="Respondents can answer with long text in a resizable box."
            icon="paragraph"
          >
            <StyledCustomisedInputEditInputWrapper>
              <Label>Placeholder</Label>
              <TextInput
                placeholder="Placeholder..."
                {...props.register('Placeholder')}
              />
            </StyledCustomisedInputEditInputWrapper>
            <StyledCustomisedInputEditInputWrapper>
              <Label>Character Limit</Label>
              <TextInput
                {...props.register('CharacterLimit')}
                type="number"
                step="1"
                min={0}
                pattern="\d*"
                disabled={props.disabled}
                max={9999999}
              />
            </StyledCustomisedInputEditInputWrapper>
          </Details>
        </StyledCustomisedInputEditWrapper>
      )}
      {!props.editing && (
        <StyledCustomisedInputPreviewWrapper>
          <StyledCustomisedInputPreviewInputWrapper>
            <StyledCustomisedInputHeader>
              <Icon icon="paragraph" size="35px" color="#ccc" />
            </StyledCustomisedInputHeader>
            <StyledLabel>{props.question.Title}</StyledLabel>

            <StyledTextArea
              placeholder={props.question.Placeholder}
              value=""
              onChange={() => null}
            />
          </StyledCustomisedInputPreviewInputWrapper>
        </StyledCustomisedInputPreviewWrapper>
      )}
    </>
  );
}

CustomisedInputTextarea.propTypes = {
  question: PropTypes.object,
  removeQuestion: PropTypes.func,
  disabled: PropTypes.bool,
  onQuestionEdit: PropTypes.func,
  otherQuestions: PropTypes.array,
  index: PropTypes.number,
};

export default CustomisedInputTextarea;
