import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  StyledDataBlock,
  StyledDataBlockHeader,
  StyledDataBlockHeaderText,
} from '../../styled-components/StyledDataBlocks';
import {
  S_tr,
  S_td,
  S_tdLeft,
  S_thLeft,
  S_table,
  S_thead,
  S_tbody,
  S_th,
} from '../../styled-components/StyledTable';

import ScorePercentage, {
  ScoreChangePercentage,
} from '../../styled-components/StyledScorePercentage';
import LinkWithPrevState from '../LinkWithPrevState';
import SimulationScoresSparkline from '../SimulationScoresSparkline';
import { Datetime } from '@virtidev/toolbox';

const StyledUserSimAnalyticsLink = styled(LinkWithPrevState)`
  :hover {
    text-decoration: underline;
  }
`;

const TableUserSimulations = (props) => {
  //reduced to only Simulations where Quiz = true, as the table is called "scores"
  const simulationScores = props.simulationScores.reduce((acc, item) => {
    if (item.Simulation.Quiz) {
      acc.push(item);
    }
    return acc;
  }, []);
  return (
    <StyledDataBlock>
      <StyledDataBlockHeader>
        <StyledDataBlockHeaderText>
          User Simulation Scores
        </StyledDataBlockHeaderText>
      </StyledDataBlockHeader>
      <S_table>
        <S_thead>
          <S_tr>
            <S_thLeft>Title</S_thLeft>

            <S_th>Latest Completion</S_th>
            <S_th>Progress</S_th>
            <S_th>First Score</S_th>
            <S_th>Best Score</S_th>
            <S_th>Gain</S_th>
          </S_tr>
        </S_thead>
        <S_tbody>
          {simulationScores.length === 0 && (
            <S_tr>
              <S_td colSpan="10">No scores found</S_td>
            </S_tr>
          )}
          {simulationScores.map((simScore) => {
            const gain = simScore.LatestScore - simScore.FirstScore;
            return (
              <S_tr key={simScore.ID}>
                <S_tdLeft>
                  <StyledUserSimAnalyticsLink
                    to={`/simulations/${simScore.Simulation.ID}/analytics/user/${props.userID}`}
                  >
                    {simScore.Simulation.Title}
                  </StyledUserSimAnalyticsLink>
                </S_tdLeft>

                <S_td>
                  <Datetime
                    datetime={simScore.Created}
                    long
                    noTime
                    emptyDisplay="-"
                  />
                </S_td>
                <S_td>
                  <SimulationScoresSparkline
                    submissions={simScore.AnalyticsSubmissions.nodes}
                  />
                </S_td>
                <S_td>
                  <ScorePercentage
                    showNA={!simScore.Simulation.Quiz || props.showNAForScores}
                    align="center"
                    score={simScore.FirstScore}
                  />
                </S_td>
                <S_td>
                  <ScorePercentage
                    showNA={!simScore.Simulation.Quiz || props.showNAForScores}
                    align="center"
                    score={simScore.BestScore}
                  />
                </S_td>
                <S_td>
                  <ScoreChangePercentage
                    showNA={!simScore.Simulation.Quiz || props.showNAForScores}
                    align="center"
                    score={gain}
                  />
                </S_td>
              </S_tr>
            );
          })}
        </S_tbody>
      </S_table>
    </StyledDataBlock>
  );
};

TableUserSimulations.propTypes = {
  simulationScores: PropTypes.array,
  userID: PropTypes.any,
  showNAForScores: PropTypes.bool,
};

export default TableUserSimulations;
