import React, { Component } from 'react';
import {
  Required,
  Matching,
  RunValidations,
  GetErrors,
  GetInvalidFields,
  MinLength,
} from '../utility/FormValidations';
import ValidationMessages from '../components/ValidationMessages';
import styled from 'styled-components';

import StyledTextInput from '../styled-components/StyledTextInput';
import { Mutation } from '@apollo/client/react/components';
import { CHANGE_PASSWORD } from '../queries/PasswordQueries';
import withFlashMessaging from '../HOCs/WithFlashMessaging';
import {
  StyledInputWrapper,
  StyledSections,
  StyledSection,
  StyledSectionInner,
  StyledSubmitBtnWrapper,
  StyledValidationMessagesWrapper,
} from '../styled-components/StyledFormStructure';
import _ from 'lodash';
import WithOnboardingHandler from '../HOCs/WithOnboardingHandler';
import tracker from '../apps/core/src/helpers/tracker';
import { Button } from '@virtidev/toolbox';

const StyledForm = styled.form`
  border-bottom: var(--primary-border);
  padding-bottom: 2rem;
`;

const StyledInputsWrapper = styled.div`
  display: grid;
  grid-template-areas:
    'CurrentPassword null'
    'NewPassword ConfirmNewPassword'
    'Button Button'
    'ValidationMessages ValidationMessages';
  grid-column-gap: 1rem;
`;

class ChangePasswordProfileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validations: [],
      invalidFields: [],
      pass1: '',
      pass2: '',
      oldPassword: '',
      submittingForm: false,
    };
  }

  validations = [
    Matching('pass1', 'pass2', 'New Password', 'Confirm Password'),
    Required('oldPassword', 'Current Password'),
    MinLength('pass1', 'New Password', true, 8),
    MinLength('pass2', 'Confirm New Password', true, 8),
  ];

  _validate = (callback) => {
    const newValidations = RunValidations(this.validations, this.state);
    const invalidFields = GetInvalidFields(newValidations);
    this.setState(
      {
        validations: newValidations,
        invalidFields,
      },
      () => {
        if (callback) {
          const isInvalid = this.state.validations.some(
            (validation) => !validation.valid
          );
          callback(!isInvalid);
        }
      }
    );
  };

  _submitForm = (e) => {
    e.preventDefault();
    this._validate((valid) => {
      if (!valid) return;
      this.setState({
        submittingForm: true,
      });
      this.forgotPassMutate({
        variables: {
          OldPassword: this.state.oldPassword,
          Password: this.state.pass2,
        },
      });
    });
  };

  render() {
    return (
      <StyledForm>
        <Mutation
          mutation={CHANGE_PASSWORD}
          onCompleted={() => {
            this.setState({
              submittingForm: false,
            });
            this.props.addFlashMessage('Password updated.', 'success');
            tracker.track('password_changed');
            this.props.updateProgress('upload_profile');
          }}
          onError={(error) => {
            this.setState({
              submittingForm: false,
            });
            this.props.addFlashMessage(
              _.get(
                error,
                'graphQLErrors[0].message',
                'There was an error updating your password.'
              ),
              'error'
            );
          }}
        >
          {(forgotPassMutate, { data }) => {
            this.forgotPassMutate = forgotPassMutate;
            return (
              <StyledSections>
                <StyledSection>
                  <StyledSectionInner>
                    <StyledInputsWrapper>
                      <StyledInputWrapper gridArea="CurrentPassword">
                        <StyledTextInput
                          invalid={this.state.invalidFields.includes(
                            'oldPassword'
                          )}
                          label="Current Password"
                          name="old-pass"
                          id="old-pass"
                          type="password"
                          required={true}
                          onChange={(event) =>
                            this.setState({ oldPassword: event.target.value })
                          }
                          placeholder="Current Password"
                          value={this.state.oldPassword}
                        />
                      </StyledInputWrapper>
                      <StyledInputWrapper gridArea="NewPassword">
                        <StyledTextInput
                          invalid={this.state.invalidFields.includes('pass1')}
                          label="New Password"
                          name="new-pass-1"
                          id="new-pass-1"
                          type="password"
                          required={true}
                          onChange={(event) =>
                            this.setState({ pass1: event.target.value })
                          }
                          placeholder="New Password"
                          underText="Minimum 8 characters"
                          value={this.state.pass1}
                        />
                      </StyledInputWrapper>
                      <StyledInputWrapper gridArea="ConfirmNewPassword">
                        <StyledTextInput
                          invalid={this.state.invalidFields.includes('pass2')}
                          label="Confirm New Password"
                          name="new-pass-2"
                          id="new-pass-2"
                          type="password"
                          required={true}
                          onChange={(event) =>
                            this.setState({ pass2: event.target.value })
                          }
                          placeholder="Confirm New Password"
                          underText="Minimum 8 characters"
                          value={this.state.pass2}
                        />
                      </StyledInputWrapper>
                      <StyledSubmitBtnWrapper>
                        <Button
                          icon="link"
                          color="turquoise"
                          loading={this.state.submittingForm}
                          onClick={this._submitForm}
                        >
                          Update password
                        </Button>
                      </StyledSubmitBtnWrapper>
                      <StyledValidationMessagesWrapper>
                        <ValidationMessages
                          errors={GetErrors(this.state.validations)}
                        />
                      </StyledValidationMessagesWrapper>
                    </StyledInputsWrapper>
                  </StyledSectionInner>
                </StyledSection>
              </StyledSections>
            );
          }}
        </Mutation>
      </StyledForm>
    );
  }
}

ChangePasswordProfileForm.propTypes = {};

export default WithOnboardingHandler(
  withFlashMessaging(ChangePasswordProfileForm)
);
