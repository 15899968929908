import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SparkLine from './charts/Sparkline';

const StyledWrapper = styled.div`
  margin: auto;
  display: grid;
  place-items: centre;
  width: 7rem;

  pointer-events: none;
`;

function SimulationScoresSparkline({ submissions }) {
  const chartData = submissions.map(({ Score }) => Score);

  return (
    <StyledWrapper>
      <SparkLine data={chartData} />
    </StyledWrapper>
  );
}

SimulationScoresSparkline.propTypes = {
  submissions: PropTypes.array,
};

export default SimulationScoresSparkline;
