import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledIndicator = styled.div`
  border-radius: 20px;
  width: 13px;
  height: 13px;
  cursor: help;
  background-color: ${(props) => (props.isActive ? 'green' : '#CCC')};
  border: 1px solid white;
`;

const IsActiveUserIndicator = (props) => {
  const minutesSinceActive =
    (new Date().getTime() - new Date(props.lastSeen).getTime()) / 1000 / 60;
  const isActive = minutesSinceActive <= props.minutesAgoCountsAsActive;
  return (
    <StyledIndicator
      className={props.className}
      isActive={isActive}
      title={`Last active: ${props.lastSeen}`}
    />
  );
};

IsActiveUserIndicator.defaultProps = {
  minutesAgoCountsAsActive: 15,
};

IsActiveUserIndicator.propTypes = {
  lastSeen: PropTypes.string,
  minutesAgoCountsAsActive: PropTypes.number.isRequired,
};

export default IsActiveUserIndicator;
