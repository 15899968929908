import styled from 'styled-components';

export const VirtualHumansList = styled.div`
  flex: 1;
  overflow-y: scroll;
`;

export const StyledNoResults = styled.div`
  margin-top: 3rem;
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 8px;
`;
