import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  RunValidations,
  GetErrors,
  GetInvalidFields,
} from '@base/utility/FormValidations';
import ValidationMessages from '@base/components/ValidationMessages';
import {
  StyledLoggedOutPage,
  StyledForm,
  StyledTextInput,
  StyledSubmitBtn,
  StyledLogoWrapper,
  StyledLogo,
  StyledLoggedOutPageContentWrapper,
  StyledVersionNumber,
} from '@base/styled-components/StyledLoggedOutPage';
import StyledLink from '@base/styled-components/StyledLink';
import Alert from '@base/styled-components/StyledAlerts';
import LoginSuccessMessage from '@core/components/Signup/legacy/LoginSuccessMessage/LoginSuccessMessage';

import getConfig from '../lib/config';

const StyledLinkWrapper = styled.div`
  margin-top: 1rem;
  display: flexbox;
`;
let genericURL = getConfig("REACT_APP_REMOTE_URI").split('//');
let serverName = genericURL[1].split('.')[0];

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      validations: [],
      invalidFields: [],
    };
  }

  validations = [
    // IsEmail('email', 'Email', true),
    // Required('password', 'Password', true, 1),
  ];

  _validate = (callback) => {
    const newValidations = RunValidations(this.validations, this.state);
    const invalidFields = GetInvalidFields(newValidations);
    this.setState(
      {
        validations: newValidations,
        invalidFields,
      },
      () => {
        if (callback) {
          const isInvalid = this.state.validations.some(
            (validation) => !validation.valid
          );
          callback(!isInvalid);
        }
      }
    );
  };

  _submitForm = (e) => {
    e.preventDefault();
    this.props.resetFailedLogin();
    this._validate((valid) => {
      if (!valid) return;
      this.props.submitCallback(this.state.email, this.state.password);
    });
  };

  render() {
    return (
      <StyledLoggedOutPage>
        <StyledLoggedOutPageContentWrapper>
          <LoginSuccessMessage />

          <StyledForm onSubmit={this._submitForm}>
            <StyledLogoWrapper>
              <StyledLogo />
            </StyledLogoWrapper>
            {this.props.failedLogin &&
              !this.props.loggingIn &&
              this.props.errMsg && (
                <Alert>
                  <p>{this.props.errMsg}</p>
                </Alert>
              )}
            {/* might be redundant now */}
            {this.props.failedLogin &&
              !this.props.loggingIn &&
              !this.props.errMsg && (
                <Alert>
                  <p>
                    Your email and password combination were incorrect. Please
                    try again.
                  </p>
                </Alert>
              )}
            {this.props.invalidUserType && !this.props.loggingIn && (
              <Alert>
                <p>
                  Your user account does not have organisation administration
                  privileges.
                </p>
              </Alert>
            )}
            {this.props.redirectedError &&
              !this.props.loggingIn &&
              !this.props.failedLogin &&
              !this.props.invalidUserType && (
                <Alert>{this.props.redirectedError}</Alert>
              )}
            {this.props.failedConnection &&
              !this.props.loggingIn &&
              !this.props.failedLogin &&
              !this.props.invalidUserType && (
                <Alert>
                  <p>
                    Failed to contact server. Please check your network
                    connection or try again later.
                  </p>
                </Alert>
              )}
            <StyledTextInput
              invalid={this.state.invalidFields.includes('Email')}
              label="Email"
              name="email"
              id="email"
              required
              // styleType="alt"
              type="text"
              onChange={(event) => this.setState({ email: event.target.value })}
              placeholder="Email Address"
              value={this.state.email}
            />
            <StyledTextInput
              invalid={this.state.invalidFields.includes('Password')}
              name="password"
              id="password"
              label="Password"
              required
              // styleType="alt"
              type="password"
              onChange={(event) =>
                this.setState({ password: event.target.value })
              }
              placeholder="Password"
              value={this.state.password}
            />
            <StyledSubmitBtn
              dataTestId="submit-login"
              loading={this.props.loggingIn}
            >
              Login
            </StyledSubmitBtn>
            <StyledLinkWrapper>
              <StyledLink to="forgot-password">Forgot password?</StyledLink>
            </StyledLinkWrapper>
            <StyledLinkWrapper>
              <StyledLink to="activate-account">
                Activate account from invite
              </StyledLink>
            </StyledLinkWrapper>
            <StyledLinkWrapper>
              <StyledLink to="/privacy-policy" target="_blank">
                Privacy Policy
              </StyledLink>
            </StyledLinkWrapper>
            <ValidationMessages errors={GetErrors(this.state.validations)} />
          </StyledForm>
          <StyledVersionNumber>
            {serverName} {getConfig("REACT_APP_VERSION_NUMBER")}
          </StyledVersionNumber>
        </StyledLoggedOutPageContentWrapper>
        {/* <StyledPageImage /> */}
      </StyledLoggedOutPage>
    );
  }
}

LoginForm.propTypes = {
  submitCallback: PropTypes.func.isRequired,
  failedLogin: PropTypes.bool.isRequired,
  loggingIn: PropTypes.bool.isRequired,
  failedConnection: PropTypes.bool.isRequired,
  resetFailedLogin: PropTypes.func.isRequired,
  redirectedError: PropTypes.string,
  invalidUserType: PropTypes.bool,
};

export default LoginForm;
