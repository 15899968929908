import { gql } from '@apollo/client';

export const READ_TRIAL_EXPIRY = gql`
  query readOneOrganisation($ID: ID!) {
    readOneOrganisation(filter: { ID: { eq: $ID } }) {
      ID
      Trial
      TrialExpiryDate
    }
  }
`;
