import { useState } from 'react';
import VHQuery from '../VHQuery';
import getConfig from '../../../lib/config';

const useSaveTusFile = ({ onError, onComplete }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const saveTusFile = async ({
    file,
    tusId,
    tusUrl,
    parseText,
    aiGenerationVhId,
  }) => {
    try {
      setIsLoading(true);
      const res = await VHQuery(`files`, 'POST', {
        baseUrl: getConfig('REACT_APP_VH_MEDIA_CLOUDFRONT_URL'),
        tusId,
        tusUrl,
        fileName: file.name,
        fileSize: file.size,
        mimeType: file.type,
        parseText,
        aiGenerationVhId,
      });
      setIsLoading(false);
      if (onComplete) {
        onComplete(res);
      }
    } catch (err) {
      setError(err);
      setIsLoading(false);
      if (onError) {
        onError(err);
      }
    }
  };
  return { saveTusFile, isLoading, error };
};
export default useSaveTusFile;
