import React from 'react';
import CXDashboardTabNav from '../components/CXDashboardTabNav';
import CxProductDashboard from '../components/CxProductDashboard';
import WithCXDashboardPageTemplate from '../HOCs/templates/WithCXDashboardPageTemplate';

const CXProductDashboardPage = () => {
  return (
    <>
      <CXDashboardTabNav />
      <CxProductDashboard />
    </>
  );
};

export default WithCXDashboardPageTemplate(CXProductDashboardPage);
