import { Button, Icon } from '@virtidev/toolbox';
import _ from 'lodash';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { EditCourseForm } from '../../../components/CourseForm';
import { Titlebar } from '../../../components/Page';
import { withCourseAccess } from '../../../helpers/permissions';
import { withPageTemplate } from '../../../templates';

const ContentTitle = styled(Titlebar)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: var(--content-padding, 25px);

  min-width: 305px;
`;

const EditCoursePage = () => {
  const { courseId } = useParams();

  return (
    <>
      <ContentTitle>
        <span>Edit Course</span>
        <Button
          type="link"
          color="transparent"
          as={Link}
          to={`/analytics-data/courses/${courseId}`}
        >
          Analytics
          <Icon icon="expand" />
        </Button>
      </ContentTitle>
      <EditCourseForm courseId={courseId} />
    </>
  );
};

export default _.flowRight(
  withCourseAccess,
  withPageTemplate({ pageKey: 'course' })
)(EditCoursePage);
