/**
 * Set values to null to use the value given from the Launch Darkly
 *   server, if flag is not defined there, it'll give false
 * Set values to false to leave turned off for local dev
 * Set values to true to turn on the feature for local dev
 *
 * @type {{ [name: string]: string | boolean | null }}
 */
export const features = {
  'page-template': null,
  'states-editor': true,
  'subtitle-generator': false,
  'screen-capture-tool': null,
  'vh-ai-generator': true,
  'show-avatar-environment-previews': true,
  'show-in-dev-avatars-environments': true,
  'vh-exam-mode': null,
  'vh-ai-dialogflow-response-bypass': true,
  'vh-non-english-languages-deepgram': true,
  'vh-deepgram-elevenlabs-voices': true,
  'preview-features-mode': null,
  'vh-unity-build-path': 'dev',
  'vh-big-ui': true,
  'vh-one-click-availability': true,
  'vh-biography-combined': true,
  'vh-freeform-legacy-v2': true,
  'htc-push-to-talk': false,
  'elevenlabs-streaming': true,
  'image-simulation-support': true,
  'no-endpointing-deepgram': true,
  'freeform-v-2': true,
  'record-and-send-microphone-option': true,
  'core-scorm-download': true,
};

/**
 *
 * @param {string} featureName
 */
export const getDevFeature = (featureName) => {
  return features[featureName];
};

export default getDevFeature;
