import styled, { css } from 'styled-components';

export const contentFragment = css`
  margin-bottom: 1rem;
  text-align: left;

  h1,
  h2,
  p,
  ul,
  ol,
  li {
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  img {
    margin: 0.25rem auto;
    max-width: 100%;
  }
  b,
  strong {
    font-weight: bold;
  }
  i,
  em {
    font-style: italic;
  }
  ul,
  ol {
    list-style: initial;
    margin-left: 1rem;
  }
  ol {
    list-style: decimal;
    ol {
      list-style: lower-alpha;
    }
  }
  li {
    padding-left: 0.75rem;
    margin: 0.25rem 0;
  }
`;

export const ContentHolder = styled.div`
  ${contentFragment}
`;

export const ContentContainer = styled.div`
  min-height: 40vh;
  display: grid;
  place-items: center;
  margin-bottom: 1rem;
`;

export const StyledImage = styled.img`
  width: 60%;
  max-width: 1000px;
`;
