import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import StyledTextInput from './StyledTextInput';
import Button from '../components/buttons/Button';

const StyledLoggedOutPage = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--login-page-bg-color);
  position: relative;
  /* right: 15%; */
  z-index: 1;
`;

const StyledLoggedOutPageContentWrapper = styled.div`
  max-width: 40rem;
  position: relative;
  padding: 2rem;
  flex: 1;
  z-index: 1;
  /* position: relative;
  right: 10%; */
`;

const StyledVersionNumber = styled.p`
  position: absolute;
  bottom: -1rem;
  right: 3rem;
  color: var(--tertiary-font-color);
  text-align: right;
  font-style: italic;
`;
const StyledForm = styled.form`
  max-width: 40rem;
  background-color: #ccc;
  padding: 2rem;
  flex: 1;
  background-color: var(--login-form-bg-color);
  border-radius: var(--card-border-radius);
  background-color: var(--card-bg-color);
  box-shadow: var(--card-box-shadow);
`;

const ModifiedTextInput = styled(StyledTextInput)`
  margin-bottom: 1rem;
  width: 100%;
`;

const StyledSubmitBtn = styled(Button)`
  margin-top: 1rem;
  width: 100%;
  background: var(--primary-color);
  &:hover {
    background: var(--primary-color);
    opacity: 0.9;
  }
`;

const StyledLogoWrapper = styled.div`
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 4rem;
`;

const StyledLogo = styled.img`
  height: var(--nav-logo-height);
`;
const StyledLogoComponent = (props) => (
  <StyledLogo src="/images/virti-dark.svg" alt="virti logo" />
);

const StyledHeader = styled.h1`
  margin-bottom: 2rem;
  font-size: 1.5rem;
`;

const StyledSuccessText = styled.div`
  margin-top: 1rem;
`;

const StPageImage = styled.img`
  position: fixed;
  z-index: 0;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  left: 35%;
  /* Preserve aspect ratio */
  min-width: 100%;
  min-height: 100%;
`;

export const StyledPageImage = (props) => <StPageImage src={props.src} />;

StyledPageImage.defaultProps = {
  src: '/images/background-login.png',
};

StyledPageImage.propTypes = {
  src: PropTypes.string,
};

export {
  StyledLoggedOutPage,
  StyledVersionNumber,
  StyledLoggedOutPageContentWrapper,
  StyledForm,
  ModifiedTextInput as StyledTextInput,
  StyledSubmitBtn,
  StyledLogoWrapper,
  StyledLogoComponent as StyledLogo,
  StyledHeader,
  StyledSuccessText,
};
