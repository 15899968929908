import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledTable,
  Message,
  StyledLoadingPlaceholder,
  Th,
  Td,
  GreyText,
} from './MySessions.styled';
import { Datetime, Icon, MockText, SortableTh } from '@virtidev/toolbox';
import StyledScorePercentage from '../../../../../../styled-components/StyledScorePercentage';
import StyledLink from '../../../../../../styled-components/StyledLink';

const MySessionsTable = ({
  sessions,
  title,
  loading,
  handleSort,
  currentSort,
}) => {
  const naText = <GreyText>N/A</GreyText>;
  const withLogs = sessions.some((session) => !!session.logUrl);

  const renderTableRow = (
    { key, title, startDateTime, endDateTime, score, logUrl },
    index
  ) => {
    return (
      <tr key={key || index + 1}>
        <Td align="left">{title || <GreyText>(No title)</GreyText>}</Td>
        <Td align="center">
          <Datetime datetime={startDateTime} long />
        </Td>
        <Td align="center">
          <Datetime datetime={endDateTime} long emptyDisplay={naText} />
        </Td>
        <Td align="center">
          {typeof score === 'number' ? (
            <StyledScorePercentage align="center" score={score} />
          ) : (
            naText
          )}
        </Td>
        {withLogs && (
          <Td align="center">
            {logUrl && (
              <StyledLink to={logUrl}>
                <Icon icon="document" />
              </StyledLink>
            )}
          </Td>
        )}
      </tr>
    );
  };

  const sortProps = {
    onSort: handleSort,
    currentSort: currentSort,
  };

  return (
    <>
      <StyledTable>
        <thead>
          <tr>
            <Th align="left">{title}</Th>
            <SortableTh {...sortProps} sortField="Created" align="center">
              Started
            </SortableTh>
            <Th align="center">Finished</Th>
            <Th align="center">Score</Th>
            {withLogs && <Th align="center">Log</Th>}
          </tr>
        </thead>
        <tbody>{sessions.map(renderTableRow)}</tbody>
      </StyledTable>

      {!loading && !sessions.length && (
        <Message>No sessions to display</Message>
      )}

      {loading && (
        <StyledLoadingPlaceholder>
          <MockText />
        </StyledLoadingPlaceholder>
      )}
    </>
  );
};

MySessionsTable.propTypes = {
  title: PropTypes.string.isRequired,
  sessions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string,
      startDateTime: PropTypes.string.isRequired,
      endDateTime: PropTypes.string,
      score: PropTypes.number,
      logUrl: PropTypes.string,
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  handleSort: PropTypes.func,
  currentSort: PropTypes.object,
};

export default MySessionsTable;
