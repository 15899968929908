import styled from 'styled-components';
import StyledTextArea from '../../../../../../styled-components/StyledTextArea';
import { Savebar } from '../../Page';

export const SubtitlesWrapper = styled.div`
  min-width: 300px;
  flex: 1;
  border-radius: var(--card-border-radius);
  background-color: var(--card-bg-color);
  box-shadow: var(--card-box-shadow);
  padding: 1rem;
  max-height: 100%;
  overflow-y: overlay;
`;
export const ReStyledTextArea = styled(StyledTextArea)`
  textarea {
    border-radius: 16px;
    height: 7rem;
  }
`;
export const Subtitle = styled.div`
  display: grid;
  margin-bottom: 1rem;
  grid-template-columns: 1fr 139px 50px;
  grid-column-gap: 1rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex: 1;
  height: 300px;
`;

export const VideoWrapper = styled.div`
  width: 600px;
  max-width: 40%;
  aspect-ratio: 16/9;
`;

export const Video = styled.video`
  background-color: #000;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  width: 100%;
`;

export const NewButtonWrapper = styled.div`
  text-align: center;
`;

export const TimeInputs = styled.div`
  > div:first-child {
    margin-bottom: 0.75rem;
  }
`;
export const RestyledSaveBar = styled(Savebar)`
  display: flex;
  justify-content: space-between;
`;

export const LeftSaveBar = styled.div`
  flex: 1;
  justify-content: flex-start;
  align-items: center;
`;

export const DeleteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  :hover div {
    color: green;
  }
`;
