import { createSelector, createSlice } from '@reduxjs/toolkit';

const VirtualHumanSlice = createSlice({
  name: 'virtualHuman',
  initialState: {
    data: null,
    unitID: null,
    courseID: null,
    nextUnit: null,
  },
  reducers: {
    setVirtualHumanData: (state, action) => {
      state.data = action.payload;
    },
    setUnitID: (state, action) => {
      state.unitID = action.payload;
    },
    setCourseID: (state, action) => {
      state.courseID = action.payload;
    },
    setNextUnit: (state, action) => {
      state.nextUnit = action.payload;
    },
    resetUnitData: (state, action) => {
      state.unitID = null;
      state.nextUnit = null;
      state.courseID = null;
    },
  },
  extraReducers: {},
});

export const {
  setVirtualHumanData,
  setUnitID,
  setCourseID,
  setNextUnit,
  resetUnitData,
} = VirtualHumanSlice.actions;

export const virtualHumanDataSelector = (state) => state.virtualHuman.data;
export const shareTokenSelector = (state) =>
  state.virtualHuman?.data?.ShareToken;
export const virtualHumanTypeSelector = (state) =>
  state.virtualHuman?.data?.Type ?? 'default';
export const onscreenObjectivesAndHintsEnabledSelector = (state) =>
  state.virtualHuman?.data?.DisableHelp ? false : true;
export const easyModeSelector = (state) => !!state.virtualHuman?.data?.EasyMode;
export const nextUnitSelector = (state) => state.virtualHuman.nextUnit;
export const unitIDSelector = (state) => state.virtualHuman.unitID;
export const courseIDSelector = (state) => state.virtualHuman.courseID;
export const defaultOrganisationMicBehaviorSelector = (state) =>
  state.virtualHuman?.data?.Organisation?.MicrophoneBehavior;

export default VirtualHumanSlice;
