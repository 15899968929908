import { Icon } from '@virtidev/toolbox';
import React from 'react';

export default function ExpandIcon({ expand, handleExpand }) {
  return (
    <Icon
      icon="chevron"
      style={{
        transform: expand ? 'rotate(-90deg)' : 'rotate(90deg)',
        cursor: 'pointer',
      }}
      onClick={handleExpand}
    />
  );
}
