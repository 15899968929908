import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import StyledLabel from './StyledLabel';
import HelpTooltip from '../components/HelpTooltip';
import CharLengthIndicator from '../components/CharLengthIndicator';

const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const StyledInnerWrapper = styled.div`
  display: flex;
`;

const StyledTextAreaEl = styled.textarea`
  padding: 0.5rem 1.2rem;
  background-color: var(--text-input-bg-color);
  color: var(--text-input-text-color);
  width: ${(props) => (props.cols ? 'auto' : '100%')};
  font-size: 0.8rem;
  resize: vertical;
  font-family: var(--text-font);
  line-height: 2;
  border: var(--input-border);
  border-radius: 4px;
  ~ div {
    opacity: 0;
    visibility: hidden;
  }
  &:focus {
    outline: var(--input-focus-color) auto;
    ~ div {
      opacity: 1;
      visibility: initial;
    }
  }
  ${(props) =>
    props.invalid &&
    css`
      border-color: var(--validation-error-input-border-color);
    `}
  ${(props) =>
    props.disabled &&
    css`
      background-color: var(--disabled-form-input-bg-color);
    `}
  ${(props) =>
    props.charCounterRendered &&
    css`
      margin-right: var(--char-counter-input-margin-right);
    `}
  ${(props) =>
    !!props.cols &&
    css`
      margin-right: 1rem;
    `}
  ${(props) =>
    props.styleType === 'alt' &&
    css`
      background-color: var(--input-alt-bg-color);
      border: var(--input-alt-border);
      border-radius: var(--input-alt-border-radius);
      ::placeholder {
        color: var(--input-alt-placeholder-text-color);
      }
    `}
`;

const StyledTextArea = (props) => {
  const charCounterRendered = props.showCharCounter && props.maxLength;
  return (
    <StyledInputWrapper className={props.className}>
      {props.label && (
        <StyledLabel htmlFor={props.id} tooltip={props.labelTooltip}>
          {props.label}
        </StyledLabel>
      )}
      <StyledInnerWrapper>
        <StyledTextAreaEl
          data-testid={props.dataTestId}
          disabled={props.disabled}
          rows={props.rows}
          cols={props.cols}
          invalid={props.invalid}
          maxLength={props.maxLength}
          id={props.id}
          onChange={props.onChange}
          onKeyPress={props.onKeyPress}
          placeholder={props.placeholder}
          value={props.value || ''}
          charCounterRendered={charCounterRendered}
          styleType={props.styleType}
        />
        {props.tooltip && (
          <HelpTooltip text={props.tooltip} side={props.tooltipSide} />
        )}
        {charCounterRendered && (
          <CharLengthIndicator
            usesCols={!!props.cols}
            minLength={props.minLength && props.minLength}
            maxLength={props.maxLength && props.maxLength}
            currentLength={props.value ? props.value.length : 0}
            optimumLength={props.optimumLength || props.maxLength / 4}
            isTextArea={true}
          />
        )}
      </StyledInnerWrapper>
    </StyledInputWrapper>
  );
};

StyledTextArea.defaultProps = {
  placeholder: '',
  value: '',
  invalid: false,
  rows: '5',
  showCharCounter: true,
  minLength: 0,
};

StyledTextArea.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  type: PropTypes.string,
  invalid: PropTypes.bool,
  rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  tooltip: PropTypes.string,
  tooltipSide: PropTypes.string,
  disabled: PropTypes.bool,
  labelTooltip: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  showCharCounter: PropTypes.bool,
  dataTestId: PropTypes.string,
  styleType: PropTypes.string,
};

export default StyledTextArea;
