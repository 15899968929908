// each validation must return a function(value) that returns a validation object such as: 
// {
//     fieldName,
//     label,
//     valid: false,
//     error: 'The field "First Name" must be at least 3 characters long'
// }

const ErrorIfFieldNotExists = function (fieldName, state) {
  if (typeof (state[fieldName]) === 'undefined') {
    console.error(`CODE ERROR: ${fieldName} does not exist in state; cannot validate field`)
  }
}

const MinLength = function (fieldName, label, isRequired = false, length) {
  return (state) => {
    ErrorIfFieldNotExists(fieldName, state);
    const value = state[fieldName]?.trim?.() || '';
    return {
      fieldName,
      label,
      valid: value.length >= length || (!isRequired && value === ''),
      error: label + ' is too short, it must be ' + length + ' or more characters long.'
    }
  }
}

const MaxLength = function (fieldName, label, isRequired = false, length) {
  return state => {
    ErrorIfFieldNotExists(fieldName, state);
    return {
      fieldName,
      label,
      valid: state[fieldName]?.length < length || (!isRequired && state[fieldName] === ''),
      error: label + ' is too long, it must not exceed ' + length + ' characters.'
    }
  }
}

const Required = function (fieldName, label) {
  return state => {
    ErrorIfFieldNotExists(fieldName, state);
    return {
      fieldName,
      label,
      valid: typeof (state[fieldName]) !== 'undefined' && state[fieldName] !== '' && state[fieldName] !== null,
      error: 'The field "' + label + '" is required.'
    };
  }
}

const Matching = function (fieldName1, fieldName2, label1, label2) {
  return state => {
    ErrorIfFieldNotExists(fieldName1, state);
    ErrorIfFieldNotExists(fieldName2, state);
    return {
      fieldName1,
      fieldName2,
      label1,
      label2,
      valid: state[fieldName1] === state[fieldName2],
      error: 'The field "' + label1 + '" and "' + label2 + '" must match.'
    };
  }
}

const RunValidations = function (validations, state) {
  return validations.reduce((carry, validation) => {
    carry.push(validation(state))
    return carry;
  }, []);
}

const GetErrors = function (validations) {
  if (!validations) return [];
  return validations.filter(validation => !validation.valid).map(validation => validation.error);
}

const GetInvalidFields = function (validations) {
  if (!validations) return [];
  return validations.filter(validation => !validation.valid).map(validation => validation.fieldName || validation.fieldName2);
}

export { Required, MaxLength, MinLength, RunValidations, GetErrors, GetInvalidFields, Matching }


export const IsEmail = function (fieldName, label, isRequired = false) {
  return state => {
    ErrorIfFieldNotExists(fieldName, state);
    return {
      fieldName,
      label,
      // allows for setting "admin" as email
      valid: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(state[fieldName]) || (!isRequired && state[fieldName] === '') || (state[fieldName] === 'admin'), // eslint-disable-line no-useless-escape
      error: 'The field "' + label + '" is not a valid email address.'
    }
  };
}

