import React from 'react';
import SelectCourse from '../../../form/Select/components/SelectCourse/SelectCourse';
import SelectSection from '../SelectSection/SelectSection';

export default function CoursesSection({ getValues, handleAdd, handleRemove }) {
  const beforeField = `CoursesBefore`;
  const afterField = `CoursesAfter`;
  return (
    <SelectSection
      title="Courses"
      selectBefore={
        <SelectCourse
          value={getValues(beforeField)}
          multi
          onAdd={(value, label) => handleAdd(value, label, beforeField)}
          onRemove={(value, label) => handleRemove(value, label, beforeField)}
        />
      }
      selectAfter={
        <SelectCourse
          multi
          value={getValues(afterField)}
          onAdd={(value, label) => handleAdd(value, label, afterField)}
          onRemove={(value, label) => handleRemove(value, label, afterField)}
        />
      }
    />
  );
}
