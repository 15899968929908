import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Upload } from 'tus-js-client';

import withFlashMessaging from '../../../../../../HOCs/WithFlashMessaging';
import WithConfirmationBox from '../../../../../../HOCs/WithConfirmationBox';
import {
  IsAcceptedFile,
  GetAcceptedFiles,
} from '../../../../../../utility/FileUtility';
import { setStateAsync } from '../../../../../../utility/ReactUtil';
import { usePrevious } from '../../../../../../utility/CustomHooks';
import getConfig from '../../../../../../lib/config';

const ScreenCaptureUploader = ({
  uploadBlob,
  onSending,
  chunkSize,
  onComplete,
  onProgress,
  onError,
  onCancel,
}) => {
  const uploadRef = useRef(null);
  const [state, setState] = useState({
    bytesAccepted: 0,
    totalBytes: 0,
    files: null,
    filesUploadedProgress: 0,
    status: 'no file selected',
    uploadUrl: null,
    dragging: false,
    progress: 0,
    completed: false,
    key: 0,
    paused: false,
    uploading: false,
  });
  useEffect(() => {
    return () => {
      if (uploadRef.current) {
        uploadRef.current.abort(true);
      }
    };
  }, []);

  const getFileID = () => {
    return uploadRef.current.url.split('/').slice(-1)[0].split('+')[0];
  };
  const prevUploadBlob = usePrevious(uploadBlob);

  const getEndpoint = (file, mediaType) => {
    return getConfig('REACT_APP_TUS_UPLOAD_ENDPOINT_VIDEO');
  };

  const upload = useCallback(
    (file) => {
      if (uploadRef.current) {
        console.warn('Already uploading');
        return;
      }
      setState({
        uploading: true,
        paused: false,
        completed: false,
      });
      uploadRef.current = new Upload(file, {
        endpoint: getEndpoint(file),
        removeFingerprintOnSuccess: true, // (github note) a boolean indicating if the fingerprint in the storage will be removed when the upload is successfully completed. This value is false for not breaking the previous API contract, but we strongly suggest to set it to true to avoid cluttering the storage space. The effect is that if the same file is uploaded again, it will create an entirely new uploadRef.current. Furthermore, this option will only change behaviour if resume is set to true.
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: {
          filename: file.name,
          filetype: file.type,
        },
        onError: (error) => {
          // console.log('Failed because: ' + error);
          setState({
            status: `upload failed ${error}`,
          });
          if (onError) {
            onError();
          }
        },
        chunkSize,
        onProgress: (bytesAccepted, totalBytes) => {
          const progress = (bytesAccepted / totalBytes) * 100;
          setState({
            totalBytes: totalBytes,
            bytesAccepted: bytesAccepted,
            progress,
          });
          if (onProgress) {
            onProgress(progress);
          }
        },
        onSuccess: () => {
          setState(
            {
              status: 'upload finished',
              uploadUrl: uploadRef.current.url,
              completed: true,
              uploading: false,
              progress: 100,
            },
            this
          );
          if (onComplete) {
            onComplete(uploadRef.current, getFileID());
            uploadRef.current = null;
          }
        },
      });
      // Start the upload
      uploadRef.current.start();
      if (onSending) {
        onSending();
      }
    },
    [chunkSize, onComplete, onError, onSending, onProgress]
  );

  const cancelUpload = useCallback(async () => {
    uploadRef.current.abort();
    setState({
      files: null,
      uploading: false,
      paused: false,
      progress: 0,
      completed: false,
    });
    if (onCancel) {
      onCancel();
    }
  }, [onCancel]);

  useEffect(() => {
    if (uploadBlob && !prevUploadBlob) {
      const file = new File([uploadBlob], 'screen_capture_upload.webm');
      upload(file);
    } else if (prevUploadBlob && !uploadBlob) {
      if (state.uploading && uploadRef.current) {
        cancelUpload();
      }
    }
  }, [uploadBlob, cancelUpload, upload, prevUploadBlob, state.uploading]);

  return <></>;
};

ScreenCaptureUploader.defaultProps = {
  chunkSize: 1024 * 1024 * 5,
};

ScreenCaptureUploader.propTypes = {
  uploadBlob: PropTypes.object,
  onSending: PropTypes.func,
  onComplete: PropTypes.func,
  onError: PropTypes.func,
  onCancel: PropTypes.func,
  onProgress: PropTypes.func,
  chunkSize: PropTypes.number,
};

export default WithConfirmationBox(withFlashMessaging(ScreenCaptureUploader));
