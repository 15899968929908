import useDragScroll from '@core/components/Page/helpers/useDragScroll';
import { useResize } from '@core/helpers/useResize';
import { useMemo, useState } from 'react';

export const usePageTemplateScroll = () => {
  const [contentRef, setContentRef] = useState(
    /** @type {HTMLDivElement | null} */ (null)
  );
  const [scrolled, setScrolled] = useState(false);

  useResize(contentRef, () => {
    if (contentRef) {
      setScrolled(contentRef.scrollHeight > contentRef.clientHeight);
    }
  });

  useDragScroll(contentRef);

  const pageScrollContext = useMemo(
    () => ({
      scrollTop: () => {
        if (contentRef) {
          contentRef.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
      },
      /** @param {ScrollToOptions} options  */
      scrollTo: (options) => {
        contentRef?.scrollTo(options);
      },
      /** @param {HTMLElement} element */
      scrollIntoView: (element) => {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start',
        });
      },
      contentRef,
    }),
    [contentRef]
  );

  return {
    scrolled,
    setContentRef,
    pageScrollContext,
  };
};

export default usePageTemplateScroll;
