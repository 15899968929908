import styled from 'styled-components';

export const ReportWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
  padding: 2.5rem 5rem 4rem 5rem;
`;
export const ReportTextSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
`;
export const ReportTitle = styled.h2`
  ${({ theme }) => theme.font.h2}
`;
export const ReportText = styled.h2`
  ${({ theme }) => theme.font.body}
  color: ${({ theme }) => theme.color.midnight10};
`;
