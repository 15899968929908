import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { useEffectOnlyOnce } from '../utility/CustomHooks';
import StyledLink from '../styled-components/StyledLink';
import { Loading } from '@virtidev/toolbox';

const appear = keyframes`
  0% {
    top: 0;
    opacity: 0;
  }
  40% {
    top: 0;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
`;

const StyledLoader = styled.div`
  text-align: center;
  position: relative;
  animation: ${appear} 2s ease-out;
`;

const StyledWarning = styled.div`
  margin-top: 1rem;
`;

const LoadingPageIndicator = ({ error }) => {
  const [timedOut, setTimedOut] = useState(false);
  useEffectOnlyOnce(() => {
    const timer = setTimeout(() => {
      setTimedOut(true);
    }, 25000);
    return () => {
      clearTimeout(timer);
    };
  });
  return (
    <StyledLoader id="page-load-indicator" data-testid="page-load-indicator">
      {!error && <Loading style={{ margin: 'auto' }} size="large" />}
      {timedOut && (
        <StyledWarning>
          {error ? (
            'There was an error loading data from the server.'
          ) : (
            <>
              This is taking longer than expected. It is possible the page
              requires a{' '}
              <StyledLink onClick={() => window.location.reload()}>
                refresh
              </StyledLink>
              .
            </>
          )}
        </StyledWarning>
      )}
    </StyledLoader>
  );
};

LoadingPageIndicator.propTypes = {
  error: PropTypes.string,
};

export default LoadingPageIndicator;
