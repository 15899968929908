import { Button } from '@virtidev/toolbox';
import styled from 'styled-components';

export const StyledModalSubmitBtn = styled(Button).attrs({
  color: 'turquoise',
})`
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 2rem;
`;
