import { FC } from 'react';
import FlashMessageHolder from '@base/components/FlashMessageHolder';
import { SavebarHolder } from '@core/components/Page/components/Savebar';
import { TitlebarHolder } from '@core/components/Page/components/Titlebar';
import { ActionbarHolder } from '@core/components/Page/components/Actionbar';
import { withPagePartContainer } from '@core/components/Page/PagePart.state';
import {
  PageBody,
  PageContainer,
  PageContent,
  PageGlobalStyle,
} from '../StandardPage/Page.styled';
import PageScrollContext from '@core/components/Page/context/PageScroll.context';
import usePageTemplateScroll from '@core/components/Page/helpers/usePageTemplateScroll';

/**
 * @type {FC<{
 *    pageKey?: string
 * }>}
 */
export const StandardPage = (props) => {
  const { scrolled, setContentRef, pageScrollContext } =
    usePageTemplateScroll();

  return (
    <PageContainer {...props}>
      <PageGlobalStyle />
      <PageBody $restricted>
        <PageContent ref={setContentRef}>
          <TitlebarHolder />
          <FlashMessageHolder />

          <ActionbarHolder />
          <PageScrollContext.Provider value={pageScrollContext}>
            {props.children}
          </PageScrollContext.Provider>
        </PageContent>
        <SavebarHolder />
      </PageBody>
    </PageContainer>
  );
};

StandardPage.defaultProps = {};

StandardPage.propTypes = {};

export default withPagePartContainer(StandardPage);
