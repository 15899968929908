import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Icon from './icons/Icon';
import { useClickOutsideCallback } from '../utility/EventHooks';
import LinkWithPrevState from './LinkWithPrevState';
import { Loading } from '@virtidev/toolbox';

const StyledWrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
`;
const StyledOpenMenuButton = styled.button`
  border: none;
  border-radius: 80px;
  width: 36px;
  height: 36px;
  background: none;
  background-color: ${(props) => (props.isOpen ? '#F1F4F6' : 'none')};
  :hover {
    background-color: #f1f4f6;
  }
  cursor: pointer;
`;
const StyledIcon = styled(Icon)``;
const StyledMenuButtons = styled.div`
  position: absolute;
  ${(props) =>
    props.verticalPosition === 'bottom' &&
    css`
      top: 2rem;
    `}
  ${(props) =>
    props.verticalPosition === 'top' &&
    css`
      bottom: 2rem;
    `}
  ${(props) =>
    props.horizontalPosition === 'left' &&
    css`
      right: 0;
    `}
  ${(props) =>
    props.horizontalPosition === 'right' &&
    css`
      left: 0;
    `}
  display: ${(props) => (props.isOpen ? 'grid' : 'none')};
  grid-template-columns: minmax(14rem, 1fr);
  z-index: 1;
  background-color: var(--card-bg-color);
  box-shadow: var(--card-box-shadow);
  border-radius: var(--card-border-radius);
`;

const EllipsisMenu = (props) => {
  const [isOpen, setOpenState] = useState(false);
  const wrapperRef = useRef(null);
  useClickOutsideCallback(
    wrapperRef,
    () => setOpen(false),
    props.selectorsToExcludeClosingMenu
  );
  const setOpen = (newState) => {
    if (!isOpen) {
      setOpenState(newState);
      return;
    }
    if (!props.preventClose) {
      setOpenState(newState);
    }
  };

  return (
    <StyledWrapper ref={wrapperRef} className={props.className}>
      <StyledOpenMenuButton
        aria-label="Open menu dropdown"
        onClick={() => setOpen(!isOpen)}
        isOpen={isOpen}
      >
        <StyledIcon
          customWidth="1rem"
          customHeight="1rem"
          name={`dots-${props.ellipsisType}`}
          type="redesign"
        />
      </StyledOpenMenuButton>
      <StyledMenuButtons
        isOpen={isOpen}
        horizontalPosition={props.horizontalPosition}
        verticalPosition={props.verticalPosition}
      >
        {props.children}
      </StyledMenuButtons>
    </StyledWrapper>
  );
};

EllipsisMenu.defaultProps = {
  verticalPosition: 'bottom',
  horizontalPosition: 'right',
  ellipsisType: 'vertical',
  preventClose: false,
};
EllipsisMenu.propTypes = {
  verticalPosition: PropTypes.string,
  horizontalPosition: PropTypes.string,
  ellipsisType: PropTypes.string,
  preventClose: PropTypes.bool,
  selectorsToExcludeClosingMenu: PropTypes.array,
};

export default EllipsisMenu;

// BUTTON

const reset = css`
  text-align: left;
  border: none;
  font-family: var(--text-font);
  margin: 0;
  padding: 0.7rem 1rem;
  color: var(--primary-font-color);
  background: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  :first-child {
    border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
  }
  :last-child {
    border-radius: 0 0 var(--card-border-radius) var(--card-border-radius);
  }
  :first-child:last-child {
    border-radius: var(--card-border-radius);
  }
  i {
    color: #b1bbc4;
    font-size: 1.6rem;
    margin-right: 0.6rem;
  }
  svg {
    margin-right: 0.6rem;
  }
  path[fill='#7a8ea2'] {
    fill: #b1bbc4;
  }
  :hover {
    background-color: #eff4f6;
    color: var(--primary-color);
    path[fill='#7a8ea2'] {
      fill: var(--primary-color);
    }
    i {
      color: var(--primary-color);
    }
  }
  span:first-child {
    width: 30px;
  }
`;

const StyledEllipsisMenuButton = styled.button`
  ${reset}
  ${(props) =>
    (props.loading || props.disabled) &&
    css`
      pointer-events: none;
    `}
  text-align: ${(props) => (props.loading ? 'center' : 'left')};
`;

export const EllipsisMenuButton = (props) => {
  const passedProps = { ...props };
  passedProps.loading = passedProps.loading ? 1 : 0;
  return (
    <StyledEllipsisMenuButton {...passedProps}>
      {props.loading && (
        <Loading style={{ marginLeft: 'auto', marginRight: 'auto' }} />
      )}
      {!props.loading && props.children}
    </StyledEllipsisMenuButton>
  );
};

// LINK

export const StyledEllipsisMenuLink = styled(LinkWithPrevState)`
  ${reset}
`;

export const EllipsisMenuLink = (props) => (
  <StyledEllipsisMenuLink {...props}>{props.children}</StyledEllipsisMenuLink>
);
