import styled, { createGlobalStyle, css } from 'styled-components';
import newTheme from '@base/themes/new-theme';

export const PageGlobalStyle = createGlobalStyle`
  :root {
    ${newTheme}
  }

  code {
    font-family: 'Ubuntu Mono', Courier;
    background: #f2f2f2;
    border-radius: 4px;
    padding: 2px;
    line-height: 1.3;
    border: 1px solid #ddd;
  }
`;

export const PageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: stretch;

  ${newTheme}
`;

export const PageBody = styled.div`
  ${
    /** @param {{ $restricted?: boolean }} p */
    ({ $restricted }) => css`
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      max-height: ${$restricted ? '100vh' : null};
    `
  }
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  transition: width var(--sidebar-anim-duration);
  ${
    /** @param {{ $noContentPadding?: boolean }} p */
    ({ $noContentPadding }) =>
      !$noContentPadding &&
      css`
        padding: calc(var(--content-padding) / 2) var(--content-padding);
      `
  }
  overflow-x: auto;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CenterContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
