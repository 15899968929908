import mixpanel from 'mixpanel-browser';
import getConfig from '../../../../lib/config';

export const defaultTrackingProps = {
  channel: 'web_app',
  web_app_env: getConfig('REACT_APP_ENV'),
};

export const tracker = mixpanel;

tracker.init(getConfig('REACT_APP_MIXPANEL_PROJECT_TOKEN'), {
  debug: !!getConfig('REACT_APP_MIXPANEL_DEBUG'),
  api_host: 'https://api-eu.mixpanel.com',
});

// Prevent super properties from spanning subdomains
mixpanel.unregister('channel');
mixpanel.unregister('web_app_env');
mixpanel.unregister('organisation_id');
mixpanel.unregister('impersonator_id');
tracker.register(defaultTrackingProps, { persistent: false });

export default tracker;

export const trackingHeaders = () => {
  const props = {
    channel: 'web_app',
    distinct_id: tracker.get_distinct_id(),
  };
  return {
    'X-Virti-Mixpanel': JSON.stringify(props),
  };
};
