import { Icon, Modal, Tabs } from '@virtidev/toolbox';
import styled, { css, DefaultTheme } from 'styled-components';
import newTheme from '../../../../../themes/new-theme';
import { Link } from 'react-router-dom';

export const ResizedModal = styled(Modal)`
  ${newTheme}
  .modal-content {
    align-items: stretch;
  }

  .modal-box {
    width: 75%;
    max-width: 538px;
    height: 95vh;

    @media screen and (min-width: 768px) {
      width: 70%;
      max-width: none;
      height: 85vh;
    }
    @media screen and (min-width: 1200px) {
      width: 1080;
      max-width: none;
      height: 75vh;
    }
  }
`;

export const AssetTabs = styled(Tabs)`
  ${({ $showTabsList }) => css`
    .tab-content {
      overflow: auto;
      height: 50%;
    }
    ${!$showTabsList &&
    css`
      .tab-list {
        display: none;
      }
    `}
  `}
`;

export const AssetContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: var(--content-padding);
  padding-bottom: var(--content-padding);
  width: 100%;
`;

export const FilterContainer = styled.div`
  ${
    /** @param {{ theme: DefaultTheme, $margin?: boolean }} p */
    ({ theme: { spacing }, $margin }) => css`
      margin-bottom: ${$margin ? spacing.lg : null};
      width: 100%;
    `
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const UploadNotice = styled.div`
  padding-left: 5px;
`;

export const FooterLink = styled(Link)`
  color: ${({ theme }) => theme.color.turquoise};
  text-decoration: underline;
`;

export const AssetTypeContainer = styled.div`
  ${({ theme: { color, borderRadius, spacing } }) => css`
    position: absolute;
    top: ${spacing.sm};
    left: ${spacing.sm};
    z-index: 1;
    background: ${color.blue10};
    border-radius: ${borderRadius.sm};
    padding: 0 6px;
  `}
`;

export const AssetTypeIcon = styled(Icon).attrs({
  size: '24px',
  icon: 'video-3d',
})`
  position: relative;
  top: 2px;
`;

export const AssetCard = styled.div`
  ${
    /** @param {{ theme: DefaultTheme, $selected?: boolean, $disabled?: boolean, $multiSelect?: boolean }} p */
    ({ theme: { spacing }, $selected, $disabled, $multiSelect }) => css`
      display: flex;
      flex-direction: column;
      position: relative;
      gap: ${spacing.sm};

      ${AssetAction} {
        display: none;
      }
      &:hover {
        ${AssetAction} {
          display: block;
        }
      }

      ${$disabled &&
      css`
        pointer-events: none;
        filter: grayscale(1);
      `}
      ${!$selected &&
      css`
        ${AssetSelectBox} {
          display: none;
        }
        ${AssetSelectIconContainer} {
          display: none;
        }
      `}
    ${(!$selected || $multiSelect) &&
      css`
        &:hover {
          ${AssetImageContainer} img {
            opacity: 0.5;
          }
        }
      `}
    `
  }
`;

export const AssetImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 131px;
  max-height: 131px;

  img {
    transition: opacity 1ms;
    min-height: 100%;
  }
`;

export const AssetSelectBox = styled.div`
  ${({ theme: { color } }) => css`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: 3px solid ${color.pink};
    z-index: 1;
  `}
`;

export const AssetSelectIcon = styled(Icon).attrs({
  size: '16px',
  icon: 'tick',
})``;

export const AssetSelectIconContainer = styled.div`
  ${({ theme: { color, spacing } }) => css`
    position: absolute;
    top: ${spacing.sm};
    right: ${spacing.sm};
    background: ${color.pink};
    color: ${color.contrast.pink};
    border-radius: 100%;
    padding: 6px;
    box-shadow: 0 ${spacing.p1} ${spacing.p1} 0 rgba(179, 179, 189, 0.5);
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const AssetTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

export const AssetTimestamp = styled.div`
  ${({ theme: { font, color } }) => css`
    ${font.smallnote}
    color: ${color.midnight10};
  `}
`;

export const AssetAction = styled.div`
  ${({ theme: { spacing, color, borderRadius } }) => css`
    position: absolute;
    display: flex;
    top: ${spacing.md};
    left: 50%;
    transform: translateX(-50%);
    padding: ${spacing.sm};
    background: ${color.midnight5};
    color: ${color.contrast.midnight5};
    border-radius: ${borderRadius.sm};
    z-index: 1;
  `}
`;
