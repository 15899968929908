import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import Select from 'react-select';
import _ from 'lodash';
import StyledLabel from '../styled-components/StyledLabel';
import { useDebounce } from 'use-debounce';
import { usePrevious } from '../utility/CustomHooks';

const DBSelect = (props) => {
  const [input, setInput] = useState('');
  const [prevShownOptions, setPrevShownOptions] = useState([]);
  const [currentSearchDB] = useDebounce(input, 1000);
  const { data, loading, error, refetch } = useQuery(props.dataQuery, {
    variables: {
      ...props.dataQueryVariables,
      [props.filterVar]: currentSearchDB,
    },
    notifyOnNetworkStatusChange: true,
  });

  const optionsList =
    _.get(data, props.queryDataPath) && !error
      ? _.get(data, props.queryDataPath)
      : [];

  const optionsData = optionsList.map((queryDataList) => {
    // queryDataList could be a list of data objects or a list of edges
    // queryNestedObjectPath e.g. when accessing `node` within an `edge`
    if (props.queryNestedObjectPath) {
      return {
        value: _.get(
          queryDataList,
          props.queryNestedObjectPath + '.' + props.valueField
        ),
        label: _.get(
          queryDataList,
          props.queryNestedObjectPath + '.' + props.labelField
        ),
        data: _.get(queryDataList, props.queryNestedObjectPath),
      };
    }
    // if no queryNestedObjectPath (e.g. if not using an edge/node setup)
    return {
      value: queryDataList[props.valueField],
      label: queryDataList[props.labelField],
    };
  });

  const options = props.filterResults(optionsData);
  const prevOptions = usePrevious(options);
  useEffect(() => {
    if (options.length === 0 && prevOptions && prevOptions.length > 0) {
      setPrevShownOptions(prevOptions);
    }
  }, [options, prevOptions]);
  const optionsToShow =
    prevShownOptions?.length > 0 && loading ? prevShownOptions : options;

  const prevCurrentSearchDB = usePrevious(currentSearchDB);
  const prevPropsDataQueryVariables = usePrevious(props.dataQueryVariables);

  useEffect(() => {
    // re-run query if the typed filter has changed
    if (
      (currentSearchDB !== prevCurrentSearchDB &&
        prevCurrentSearchDB != null) ||
      (!_.isEqual(props.dataQueryVariables, prevPropsDataQueryVariables) &&
        prevPropsDataQueryVariables != null)
    ) {
      refetch();
    }
  });

  return (
    <>
      <StyledLabel htmlFor={props.htmlId}>{props.label}</StyledLabel>
      <Select
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        value={props.value}
        inputId={props.htmlId} // inputId is the html id
        name={props.name || props.id}
        isLoading={loading}
        onChange={(newObj) => {
          console.log(newObj);
          // find the actual database object pertaining to the selected object so that we can return it as a second argument
          const actualRowObj = optionsList.find((queryDataList) => {
            const val = _.get(
              queryDataList,
              props.queryNestedObjectPath + '.' + props.valueField
            );
            return val === newObj.value;
          });
          // get the actual node
          const actualRow = _.get(actualRowObj, props.queryNestedObjectPath);
          props.onChange(newObj, actualRow);
        }}
        onInputChange={(val) => setInput(val)}
        options={optionsToShow}
        // styles={props.theme === 'white' ? whiteBoxStyles : linedStyles}
        isDisabled={props.disabled}
        components={{
          IndicatorSeparator: () => null,
        }}
        placeholder={props.placeholder}
        noOptionsMessage={({ inputValue }) =>
          loading || inputValue !== currentSearchDB
            ? 'Loading...'
            : 'No results found.'
        }
        // ensure menu list is rendered over things correctly
      />
    </>
  );
};

DBSelect.defaultProps = {
  labelField: 'Title',
  valueField: 'ID',
  disabled: false,
  filterVar: 'Filter',
  filterResults: (a) => a,
};

DBSelect.propTypes = {
  dataQuery: PropTypes.object.isRequired,
  dataQueryVariables: PropTypes.object,
  queryDataPath: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  htmlId: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  labelField: PropTypes.string,
  valueField: PropTypes.string,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  filterVar: PropTypes.string,
  dataField: PropTypes.string,
};

export default DBSelect;
