import useGetVHCounts from '../useGetVHCounts';
import VHAvailabilityCounter from '../VHAvailabilityCounter/VHAvailabilityCounter';

const VHAvailabilityCounterPopulated = () => {
  const { error, isLoading, freeformMax, freeformTotalCount } =
    useGetVHCounts();
  return (
    <VHAvailabilityCounter
      error={error}
      loading={isLoading}
      freeformMax={freeformMax}
      freeformTotalCount={freeformTotalCount}
    />
  );
};

export default VHAvailabilityCounterPopulated;
