import { Icon } from '@virtidev/toolbox';
import styled, { css } from 'styled-components';

export const IconButton = styled(Icon)`
  ${({ direction }) => css`
    :hover {
      color: var(--primary-color);
    }
    cursor: pointer;
    transform: ${direction === 'up' && 'rotate(-90deg)'};
    transform: ${direction === 'down' && 'rotate(90deg)'};
    margin-left: 8px;
  `}
`;
