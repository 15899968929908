import { useDrag, useDrop } from "react-dnd";

export const useModuleDragDrop = (data, index, onSortModules) => {

  const [{ hover: hoverItem, type: typeItem }, dropItemRef] = useDrop(
    () => ({
      accept: 'ItemToModule',
      drop: () => data,
      canDrop: () => !String(data?.ID).includes('new'),
      collect: (monitor) => {
        const item = monitor.getItem();

        return {
          type: item?.type,
          hover: monitor.isOver() && monitor.canDrop(),
        };
      },
    }),
    [data]
  );

  const [{ dragging, enabled }, dragRef, dragViewRef] = useDrag(
    () => ({
      type: 'ModuleSort',
      item: { data, index },
      canDrag: () => !String(data?.ID).includes('new'),
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult();
        if (item && dropResult) {
          onSortModules(item, dropResult);
        }
      },
      collect: (monitor) => ({
        dragging: monitor.isDragging(),
        enabled: !String(data?.ID).includes('new'),
      }),
    }),
    [data, index, onSortModules]
  );

  const [{ hover, above }, dropRef] = useDrop(
    () => ({
      accept: 'ModuleSort',
      drop: () => ({ data, index }),
      canDrop: (item) => item.data.ID !== data.ID,
      collect: (monitor) => {
        const item = monitor.getItem();

        return item
          ? {
            hover: monitor.isOver() && monitor.canDrop(),
            above: index < item.index,
          }
          : {};
      },
    }),
    [data, index]
  );

  return {
    dragging,
    enabled,
    hover,
    hoverItem,
    typeItem,
    above,
    dropRef,
    dragRef,
    dropItemRef,
    dragViewRef,
  };
};
