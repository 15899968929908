import React from "react";
import ReactDOM from "react-dom";
import StyledModalWithProps from "../styled-components/StyledModal";

function Modal(props) {
  if (!props.visible) {
    return null;
  }
  return ReactDOM
    .createPortal(
       <StyledModalWithProps {...props} />,
       document.querySelector("#modal")                      //target DOM element
     );
}
export default Modal; 