import { gql } from '@apollo/client';

export const SEARCH_ORGANISATIONS = gql`
  query readOrganisations($filter: String) {
    readOrganisations(
      filter: { Name: { contains: $filter }, Archived: { eq: false } }
    ) {
      edges {
        node {
          ID
          Name
          VirtualHumanAPI
        }
      }
    }
  }
`;

export const GET_VIRTUAL_HUMANS = gql`
  query readVirtualHumansForLanguages($OrganisationID: ID!) {
    readVirtualHumans(filter: { OrganisationID: { eq: $OrganisationID } }) {
      nodes {
        ID
        Title
        ExternalID
      }
    }
  }
`;
