import { gql } from '@apollo/client';

export const COPY_FEEDBACK_FORM = gql`
  mutation copyForm($FormID: ID!, $OrganisationID: ID!) {
    copyForm(FormID: $FormID, OrganisationID: $OrganisationID) {
      ID
      Title
    }
  }
`;
