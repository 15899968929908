import { produce } from 'immer';
import _ from 'lodash';

export const cleanupDetailsData = (data) => {
  return _.pick(data, [
    'Description',
    'ID',
    'ImageMediaID',
    'Title',
    'AdminTitle',
  ]);
};

export default cleanupDetailsData;
