import { Modal } from '@virtidev/toolbox';
import CreateCourseForm from '../../../CourseForm/CreateCourseForm';

export const CreateCourseModal = ({ show, onHide, onCreateCourse }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      title="Create a new course"
      render={() => (
        <CreateCourseForm onCreate={onCreateCourse} onComplete={onHide} />
      )}
    />
  );
};

export default CreateCourseModal;
