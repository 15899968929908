import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import TotaraRegistration from '../../../components/Registration/TotaraRegistration';
import { useRouterQuery } from '../../../helpers/useRouterQuery';

const fieldNames = ['FirstName', 'Surname', 'Email', 'Hash', 'ExternalID'];

export const TotaraRegisterPage = () => {
  const query = useRouterQuery();
  const history = useHistory();
  const { pathname } = useLocation();
  const [defaultValues, setDefaultValues] = useState(null);
  const [simulationID, setSimulationID] = useState(null);

  const queryKey = query.toString();

  useEffect(() => {
    if (!queryKey) {
      return;
    }
    const fields = {};

    setSimulationID(query.get('SimulationID'));
    for (const [key, value] of query.entries()) {
      if (fieldNames.includes(key)) {
        fields[key] = value;
      }
    }

    // hide the url params after it is loaded
    setDefaultValues(fields);
    history.replace(pathname);
  }, [history, pathname, queryKey, query]);

  if (defaultValues == null && !queryKey) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: {
            errorMessage:
              "Invalid data provided, please check that you're visiting the right page.",
          },
        }}
      />
    );
  }

  return <TotaraRegistration defaultValues={defaultValues} simulationID={simulationID} />;
};

export default TotaraRegisterPage;
