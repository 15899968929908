export const generalLoadingTexts = [
  { min: 1, max: 3, text: 'Setting up request' },
  { min: 1, max: 3, text: 'Generating greeting' },
  { min: 1, max: 3, text: 'Creating scenario name' },
  { min: 3, max: 5, text: 'Creating scenario description' },
  { min: 6, max: 10, text: 'Generating scenario' },
  { min: 6, max: 10, text: 'Generating biography' },
  { min: 3, max: 6, text: 'Providing hobbies' },
  { min: 5, max: 10, text: 'Assigning personality' },
  { min: 5, max: 10, text: 'Generating objectives' },
];
export const convItemLoadingTexts = [
  { min: 1, max: 3, text: 'Setting up request' },
  { min: 3, max: 5, text: 'Resolving roles' },
  { min: 8, max: 15, text: 'Sending objectives' },
  { min: 5, max: 10, text: 'Analysing scenario' },
  { min: 5, max: 10, text: 'Analysing roles' },
  { min: 5, max: 15, text: 'Analysing biography' },
  { min: 3, max: 6, text: 'Structuring request' },
  { min: 3, max: 6, text: 'Parsing objectives' },
  { min: 3, max: 6, text: 'Parsing response' },
  { min: 3, max: 6, text: 'Verifying response' },
];
