import { gql } from '@apollo/client';

export const READ_VIRTUAL_HUMAN_FOR_SCORE_OVERVIEW = gql`
  query readVHForScoreOverview($ID: ID!) {
    readOneVirtualHuman(filter: { ID: { eq: $ID } }) {
      ID
      Title
      Type
      ExternalID
    }
  }
`;
