import FilesListDetailed from '@base/components/FilesListDetailed';
import MediaPageFilters from '@core/components/Media/components/MediaPageFilters/MediaPageFilters';
import {
  ListContainer,
  TagContent,
} from '@core/components/Media/MediaList.styled';
import { usePageScroll } from '@core/components/Page';
import useSortUrl from '@core/helpers/useSortUrl';
import {
  PaginationControl,
  usePaginationUrl,
  useStateUrl,
} from '@virtidev/toolbox';
import React, { useEffect, useMemo } from 'react';
import { createdTitle as sortOptions } from '@core/helpers/sortCollections';
import useUser from '@core/helpers/useUser';
import { useQuery } from '@apollo/client';
import { READ_VIDEOS_WITH_ASSOCIATIONS } from '@base/queries/AssetQueries';
import PageLoadError from '@base/components/PageLoadError';

export const VideosList = () => {
  const { scrollTop } = usePageScroll();
  const { OrganisationID } = useUser();
  const { pageQuery, controlProps, resetPage } = usePaginationUrl({
    pageSize: 20,
    onChange: scrollTop,
  });
  const { sortField, sortDirection } = useSortUrl(sortOptions);
  const { value: filter } = useStateUrl({
    initialValue: '',
  });
  const { value: tags } = useStateUrl({
    key: 'tag',
  });

  const variables = useMemo(() => {
    /**
     * @type {any}
     */
    const vars = {
      ...pageQuery,
      sort: {
        [sortField]: sortDirection,
      },
      filter: {
        ...(filter ? { Title: { contains: filter || '' } } : {}),
        Archived: { eq: false },
        Organisation: { ID: { eq: OrganisationID } },
        ...(tags
          ? {
              Tags: {
                ID: {
                  in: tags.split(',').map((id) => (id === '0' ? null : id)),
                },
              },
            }
          : {}),
      },
    };

    return vars;
  }, [filter, pageQuery, OrganisationID, sortDirection, sortField, tags]);

  const { loading, error, data, startPolling, stopPolling } = useQuery(
    READ_VIDEOS_WITH_ASSOCIATIONS,
    {
      variables,
      notifyOnNetworkStatusChange: true,
    }
  );

  const videos = useMemo(() => data?.readVideoMedias?.nodes || [], [data]);

  const pageInfo = useMemo(() => data?.readVideoMedias?.pageInfo, [data]);

  useEffect(() => {
    const hasTranscodingVideos = !!videos.find(
      ({ TranscodingStatus }) => TranscodingStatus === 'Transcoding'
    );
    if (hasTranscodingVideos) {
      startPolling(10000);
    } else if (!hasTranscodingVideos) {
      stopPolling();
    }
  }, [videos, startPolling, stopPolling]);

  return (
    <TagContent>
      <MediaPageFilters />
      <ListContainer>
        {error && <PageLoadError graphQLErrorObj={error} />}
        <FilesListDetailed files={videos} loading={loading} />
        {pageInfo && (
          <PaginationControl {...controlProps} total={pageInfo.totalCount} />
        )}
      </ListContainer>
    </TagContent>
  );
};

export default VideosList;
