import { Button, Icon } from '@virtidev/toolbox';
import styled, { css } from 'styled-components';

export const PageList = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const PageButton = styled(Button)`
  ${({ active }) => css`
    background: ${active ? 'var(--primary-color-lighter)' : 'none'};
    color: ${active ? 'var(--primary-color)' : 'var(--neutral-color)'};
    outline: 4px solid white;
    :hover {
      background: var(--primary-color-lighter) !important;
    }
  `}
`;

export const DeleteIcon = styled(Icon)`
  &:hover {
    color: var(--negative-color);
  }
`;
