import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { useResize } from '../../../../helpers/useResize';
import { Header } from './PageHeader.styled';

export const PageHeader = forwardRef((props, ref) => {
  const [width, setWidth] = useState();
  const [marginTop, setMarginTop] = useState();
  const navbar = useRef();
  const [header, setHeader] = useState();

  useEffect(() => {
    if (typeof ref === 'function') {
      ref(header);
      return;
    }
    if (!ref) {
      return;
    }
    ref.current = header;
  }, [header, ref]);

  const resize = useCallback(() => {
    if (!navbar.current) {
      navbar.current = document.querySelector('.virti-navbar');
    }
    // try find navbar and header again later
    if (!navbar.current || !header) {
      setTimeout(resize, 50);
      return;
    }

    const newNavWidth = navbar.current?.clientWidth * 0.8 || 0;
    const headerWidth = header.clientWidth || 0;

    if (headerWidth - (marginTop ? newNavWidth : 0) > 300) {
      setWidth(`calc(100% - ${newNavWidth}px)`);
      setMarginTop(null);
      return;
    }
    setWidth(null);
    setMarginTop(navbar.current?.clientHeight * 0.8 || 0);
  }, [header, marginTop]);

  useResize(header, resize);

  return <Header ref={setHeader} {...props} style={{ width, marginTop }} />;
});

export default PageHeader;
