import { useEffect, useState } from 'react';
import { Progress } from './LoadingProgress.styled';
import { buildStyles } from 'react-circular-progressbar';
import { useTheme } from 'styled-components';

const LoadingProgress = ({ anticipatedDuration = 5 * 1000 }) => {
  const theme = useTheme();
  const [progress, setProgress] = useState(0);

  // Each time the progress changes, set a new timeout to update the progress again
  // As we get closer to 100%, the progress will slow down
  useEffect(() => {
    const randTime = () => 1000 + Math.round(Math.random() * 2000);
    const tick = () => {
      setProgress((prevProgress) => {
        const limit = 99;
        if (prevProgress >= limit) {
          return limit;
        }
        return Math.min(
          prevProgress +
            Math.random() *
              Math.min(limit - prevProgress) *
              Math.min(9000 / anticipatedDuration, 0.7),
          limit
        );
      });
    };
    const timeout = setTimeout(tick, randTime());
    return () => {
      clearTimeout(timeout);
    };
  }, [anticipatedDuration, progress]);

  return (
    <Progress
      value={progress}
      text={`${Math.round(progress)}%`}
      styles={buildStyles({
        textColor: theme.color.primary,
        pathColor: theme.color.primary,
        trailColor: theme.color.lightGrey,
      })}
    />
  );
};

export default LoadingProgress;
