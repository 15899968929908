import React, { useCallback } from 'react';
import { ActionBar } from './MediaPageFilters.styled';
import {
  TextInput,
  useDebouncedSave,
  usePaginationUrl,
  useStateUrl,
} from '@virtidev/toolbox';
import { StateUrlSelectTag } from '@core/components/form/Select/components/SelectTag/SelectTag';
import { usePageScroll } from '@core/components/Page';
import { createdTitle as sortOptions } from '@core/helpers/sortCollections';
import { StateUrlSelect } from '@core/components/form/Select';

export const MediaPageFilters = () => {
  const { scrollTop } = usePageScroll();
  const { value: filter, updateValue: setFilter } = useStateUrl({
    initialValue: '',
  });
  const { resetPage } = usePaginationUrl({
    onChange: scrollTop,
  });

  const handleFilterChange = useCallback(
    (value) => {
      resetPage();
      setFilter(value || '');
    },
    [setFilter, resetPage]
  );

  const debouncedProps = useDebouncedSave(filter, {
    onUpdate: handleFilterChange,
  });

  return (
    <ActionBar>
      <StateUrlSelect
        param="sort"
        options={sortOptions}
        placeholder="Sort..."
        inlineLabel="Sort:"
        initialValue={sortOptions[0].value}
        isSearchable={false}
      />
      <StateUrlSelectTag
        param="tag"
        multi
        onChange={resetPage}
        placeholder="Filter by tag..."
        options={[{ value: '0', label: '(No tags)' }]}
      />
      <TextInput {...debouncedProps} placeholder="Search..." id="filter" />
    </ActionBar>
  );
};

export default MediaPageFilters;
