import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withUserPageTemplate from '../HOCs/templates/WithUserPageTemplate';
import { StyledPageContentInner } from '../components/StyledPage';
import UserForm from '../forms/UserForm';
import RedirectWithPrevState from '../components/RedirectWithPrevState';
import UserTabNav from '../components/UserTabNav';

class UserPage extends Component {
  render() {
    if (!this.props.userID) {
      return <RedirectWithPrevState to="/login" />
    }
    return (
      <>
        <UserTabNav
          userID={this.props.data.readOneMember.ID}
        />
        <StyledPageContentInner>
          <UserForm
            mutationType='edit'
            dataObject={this.props.data.readOneMember}
            includeChangePasswordSuperAdminForm={true} 
            // includeChangePasswordSuperAdminForm={this.props.user.UserType === 'super' && ['admin', 'super'].includes(this.props.data.readOneMember.UserType)} 
          />
        </StyledPageContentInner>
      </>
    );
  }
}

UserPage.propTypes = {
  userID: PropTypes.number,
  data: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withUserPageTemplate(UserPage, 'edit-user', true);