import styled, { css } from 'styled-components';
import { Icon, inputCss } from '@virtidev/toolbox';

export const Input = styled.input`
  ${inputCss}
  color: #fff;
  padding: 0;
  border: 0;
  background: transparent;
  flex: 1;
`;

export const TitleContainer = styled.div`
  ${({ theme, dragging }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    background: ${dragging ? theme.color.lightGrey : theme.color.secondary};
    border-radius: var(--accordion-border-radius);
    padding: 0 16px;
    gap: 16px;
    position: relative;

    &:hover {
      filter: brightness(105%);
    }
  `}
`;

export const TitleContent = styled.div`
  display: flex;
  flex: 1;
  gap: 16px;
  align-self: stretch;
  align-items: center;
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`;

export const TitleText = styled.div`
  flex: 1;
  width: 100%;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  width: 150px;
`;

export const DragIcon = styled(Icon)`
  cursor: ${({ dragging }) => (dragging ? 'grabbing' : 'grab')};
`;
