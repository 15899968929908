import { useCallback, useEffect, useReducer, useState } from 'react';
import { useEffectOnlyOnce } from '../CustomHooks';
import VHQuery from './VHQuery';

const initialState = { data: null, loading: false, error: null };
const reducer = (state, action) => {
  switch (action.type) {
    case 'finish_fetch':
      return { ...state, loading: false, data: action.payload, error: null };
    case 'start_fetch':
      return { ...state, loading: true };
    case 'error':
      return { ...state, loading: false, error: action.payload };
    case 'reset_data':
      return { ...state, data: null };
    default:
      return state;
  }
};

const defaultOptions = {
  skip: false,
};

const useGetVHData = (endpoint, options) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { skip, vhAPIEndpointOverride } = { ...defaultOptions, ...options };

  const fetchData = useCallback(async () => {
    dispatch({ type: 'start_fetch' });
    try {
      const data = await VHQuery(
        endpoint,
        'GET',
        undefined,
        vhAPIEndpointOverride
      );
      dispatch({ type: 'finish_fetch', payload: data });
    } catch (e) {
      dispatch({ type: 'error', payload: e });
    }
  }, [endpoint, vhAPIEndpointOverride]);

  const resetData = useCallback(() => {
    dispatch({ type: 'reset_data' });
  }, []);

  useEffect(() => {
    if (!skip) {
      fetchData();
    }
  }, [endpoint, fetchData, skip]);

  return { ...state, refetch: fetchData, resetData };
};

export default useGetVHData;
