import React, { useCallback, useState } from 'react';
import { Button } from '@virtidev/toolbox';
import { CopyCodeButtons } from './VHEmbeddableCopyCode.styled';
import tracker from '@core/helpers/tracker';
import useUser from '@core/helpers/useUser';
import useScormDownload from '@core/helpers/useScormDownload';
import useFeature from '@core/components/LaunchDarkly';

const VHEmbeddableCopyCode = ({ token, id, title }) => {
  const [scormFeature] = useFeature(['core-scorm-download']);
  const [copiedIframe, setCopiedIframe] = useState(false);
  const [copiedURL, setCopiedURL] = useState(false);
  const { Organisation } = useUser();
  const baseURL = window.location.origin;

  const handleClickCopyIframe = () => {
    setCopiedIframe(true);
    tracker.track('share_iframe', {
      type: 'virtual human',
      org_id: Organisation?.ID,
      org_name: Organisation?.Name,
      customer_stage: Organisation?.CustomerStage,
      token,
    });
    navigator.clipboard.writeText(
      `<iframe
        title="Virti-Player"
        src="${baseURL}/embed/my-virtual-humans/${token}"
        id="VirtiPlayer"
        width="1366"
        height="768"
        allow="microphone ${baseURL}; fullscreen ${baseURL};"
      ></iframe>`
    );
    setTimeout(() => {
      setCopiedIframe(false);
    }, 2000);
  };
  const handleClickCopyURL = useCallback(() => {
    setCopiedURL(true);
    tracker.track('share_url', {
      type: 'virtual human',
      org_id: Organisation?.ID,
      org_name: Organisation?.Name,
      customer_stage: Organisation?.CustomerStage,
      token,
    });
    navigator.clipboard.writeText(
      `${baseURL}/embed/my-virtual-humans/${token}`
    );
    setTimeout(() => {
      setCopiedURL(false);
    }, 2000);
  }, [
    Organisation?.CustomerStage,
    Organisation?.ID,
    Organisation?.Name,
    baseURL,
    token,
  ]);

  const handleDownloadScorm = useScormDownload({ simulationID: id, title });

  return (
    <CopyCodeButtons>
      {scormFeature && (
        <Button color="secondary" icon="download" onClick={handleDownloadScorm}>
          Download SCORM file
        </Button>
      )}
      <Button
        color="secondary"
        icon={!copiedIframe ? 'link' : null}
        onClick={handleClickCopyIframe}
      >
        {!copiedIframe ? 'Copy Embed Code' : 'Copied!'}
      </Button>
      <Button
        color="primary"
        icon={!copiedURL ? 'link' : null}
        onClick={handleClickCopyURL}
      >
        {!copiedURL ? 'Copy URL' : 'Copied!'}
      </Button>
    </CopyCodeButtons>
  );
};

export default VHEmbeddableCopyCode;
