import styled, { css } from 'styled-components';

export const StyledLinkAndTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const StyledLinks = styled.div`
  display: flex;
  justify-content: start;
  gap: 0.5rem;
`;

export const StyledCopyAlert = styled.div`
  cursor: pointer;
  color: ${(props) => (props.clicked ? 'var(--positive-color)' : '')};
`;

export const StyledCopyButton = styled(StyledCopyAlert)`
  ${({ theme, clicked }) => css`
    border: 1px solid var(--neutral-color-light);
    border-radius: var(--primary-button-border-radius);
    padding: 1rem 1.5rem;
    color: ${clicked ? theme.color.success : theme.color.secondary};
    width: 15rem;
    font-size: 0.95rem;
  `}
`;

export const StyledLinkText = styled.div`
  width: 100%;
`;
