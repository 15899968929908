export const getReadableUserType = (dbValue) => {
  switch (dbValue) {
    case 'user':
      return 'Learner';
    case 'contentcreator':
      return 'Content Creator';
    case 'admin':
      return 'Admin';
    case 'super':
      return 'Super Admin';
    case 'customeradmin':
      return 'Domain Admin';

    default:
      return 'Unknown Type';
  }
};
