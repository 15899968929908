import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StyledPageContentInner } from '../../../../../../../../components/StyledPage';
import { StyledHeader } from '../../../../../../../../styled-components/StyledLoggedOutPage';
import DBSelect from '../../../../../../../../components/DBSelect';
import Button from '../../../../../../../../components/buttons/Button';
import withFlashMessaging from '../../../../../../../../HOCs/WithFlashMessaging';
import useCopyActionLibrary from '../../../../../../../../utility/VHHooks/useCopyActionLibrary';
import { SEARCH_ORGANISATIONS } from '../../../../../../../../apps/core/src/pages/admin/cx/cxDashboardPages.query';

const StyledInputWrapper = styled.div`
  margin-bottom: 1rem;
`;

const StyledPageContentWrapper = styled.div`
  margin-top: 2rem;
`;

const CxCopyActionBlueprints = (props) => {
  const [currentOrganisationFrom, setCurrentOrganisationFrom] = useState(null);
  const [currentOrganisationFromData, setCurrentOrganisationFromData] =
    useState(null);
  const [currentOrganisationTo, setCurrentOrganisationTo] = useState(null);
  const [currentOrganisationToData, setCurrentOrganisationToData] =
    useState(null);

  const { copyLibrary, isLoading, error } = useCopyActionLibrary({
    onComplete: () => {
      props.addFlashMessage('Successfully copied');
    },
    onError: () => {
      props.addFlashMessage('Error copying library', 'error');
    },
  });

  const orgApiMismatch =
    currentOrganisationFromData &&
    currentOrganisationToData &&
    currentOrganisationFromData.VirtualHumanAPI !==
      currentOrganisationToData.VirtualHumanAPI;

  const buttonDisabled =
    !currentOrganisationFrom || !currentOrganisationTo || orgApiMismatch;

  const handleCopyClick = () => {
    copyLibrary(currentOrganisationFrom.value, currentOrganisationTo.value);
  };
  return (
    <StyledPageContentWrapper>
      <StyledPageContentInner>
        <StyledHeader>Copy Action Library to Organisation</StyledHeader>
        <StyledInputWrapper>
          <DBSelect
            dataQuery={SEARCH_ORGANISATIONS}
            filterVar="filter"
            queryDataPath="readOrganisations.edges"
            queryNestedObjectPath="node"
            onChange={(newOrgSelectObj, actualNode) => {
              setCurrentOrganisationTo(null);
              setCurrentOrganisationToData(null);
              setCurrentOrganisationFrom(newOrgSelectObj);
              setCurrentOrganisationFromData(actualNode);
            }}
            value={currentOrganisationFrom}
            htmlId="organisation-copy-from"
            label="Organisation to copy library from"
            placeholder="Select an organisation to copy library from..."
            labelField="Name"
            disabled={isLoading}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <DBSelect
            dataQuery={SEARCH_ORGANISATIONS}
            filterVar="filter"
            queryDataPath="readOrganisations.edges"
            queryNestedObjectPath="node"
            disabled={!currentOrganisationFrom || isLoading}
            onChange={(newOrgSelectObj, actualRow) => {
              setCurrentOrganisationTo(newOrgSelectObj);
              setCurrentOrganisationToData(actualRow);
            }}
            value={currentOrganisationTo}
            htmlId="organisation-copy-to"
            label="Organisation to Copy to"
            placeholder="Select the organisation that you want to copy library to..."
            labelField="Name"
          />
        </StyledInputWrapper>
        <div>
          <Button
            disabled={buttonDisabled}
            loading={isLoading}
            onClick={handleCopyClick}
            target="_blank"
          >
            Copy
          </Button>
        </div>
        {orgApiMismatch && (
          <div>
            You cannot copy a virtual human between these organisations because
            they have different API Endpoints set.
          </div>
        )}
      </StyledPageContentInner>
    </StyledPageContentWrapper>
  );
};

CxCopyActionBlueprints.propTypes = {
  addFlashMessage: PropTypes.func.isRequired,
};

export default withFlashMessaging(CxCopyActionBlueprints);
