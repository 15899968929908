import styled from 'styled-components';

export const StyledLinkGenerator = styled.div`
  margin: 2rem 0rem;
  width: 100%;
`;

export const StyledLabel = styled.div`
  margin: 2rem 0rem 1rem 0rem;
`;

export const StyledLink = styled.a`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 2rem;
  max-width: 100%;
`;

export const StyledLinkWrapper = styled.div`
  width: 100%;
  border: 1px solid var(--input-border-color);
  padding: 1rem;
  display: grid;
  grid-template-columns: auto 3rem;
  a {
    color: var(--primary-color);
  }
`;

export const StyledCopyAlert = styled.div`
  cursor: pointer;
  color: ${({ clicked }) => clicked && 'var(--positive-color)'};
`;
