import styled, { css } from 'styled-components';
import LinkWithPrevState from '../components/LinkWithPrevState';

export const StyledLink = styled(LinkWithPrevState)`
  ${({ theme: { color } }) => css`
    color: ${color.turquoise};
    font-weight: 500;
    &:hover {
      color: ${color.turquoise10};
      text-decoration: underline;
    }
    &:active {
      color: ${color.blue20};
    }
  `}
`;

export const LinkContext = styled.span`
  ${({ theme: { color } }) => css`
    color: ${color.lightGrey};
  `};
`;

export const StyledLinkAsBtn = styled.button`
  ${({ theme: { color } }) => css`
    color: ${color.turquoise};
    font-weight: 500;
    background: none;
    border: none;
    font-family: var(--text-font);
    padding: 0;
    cursor: pointer;
    &:hover {
      color: ${color.turquoise10};
      text-decoration: underline;
    }
    &:active {
      color: ${color.blue20};
    }
  `}
`;

export default StyledLink;
