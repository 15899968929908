import _ from 'lodash';
import {
  useCallback,
  useMemo,
  useRef,
  useState,
  MutableRefObject,
} from 'react';

/**
 * @returns {[string, {
 *    ref: MutableRefObject<any>,
 *    inputValue: string,
 *    onInputChange: (value: string, meta: { action: string }) => void;
 * }]}
 */
export const useSelectFilter = () => {
  const ref = useRef();
  const [filter, setFilter] = useState('');
  const [inputValue, setInputValue] = useState('');
  const debounceUpdate = useMemo(
    () => _.debounce(setFilter, 1000),
    [setFilter]
  );

  const onInputChange = useCallback(
    (newValue, { action }) => {
      if (action !== 'menu-close') {
        setInputValue(newValue);
        debounceUpdate(newValue);
      }
    },
    [debounceUpdate, setInputValue]
  );


  return [filter, {
    ref,
    inputValue,
    onInputChange,
  }];
}