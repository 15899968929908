import { useMutation } from '@apollo/client';
import { Button, CreateSelect, HelpTooltip } from '@virtidev/toolbox';
import { useCallback, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  SAVE_REGISTER_VH,
  SEND_EMAIL_SHARE_VH,
} from '@core/components/VirtualHumanForm/components/VirtualHumanEditors/VHShareModal/VHShareModal.query';
import {
  Description,
  ShareModal,
} from '@core/components/VirtualHumanForm/components/VirtualHumanEditors/VHShareModal/VHShareModal.styled';
import Label from '@core/components/form/Label';
import useAutosave from '@core/helpers/useAutosave';
import FieldContainer from '@core/components/form/FieldContainer';
import VHEmbeddableCopyCode from '@core/components/VirtualHumanForm/components/VirtualHumanEditors/VHShareModal/VHEmbeddableCopyCode/VHEmbeddableCopyCode';
import {
  RegisterBox,
  SwitchLabel,
} from '@core/components/Simulation/SimShareForm/SimShareForm.styled';
import { difference } from 'lodash';
import { useFlashMessage } from '@core/components/FlashMessage';
import tracker from '@core/helpers/tracker';
import { Userpilot } from 'userpilot';
import useUser from '@core/helpers/useUser';

export const VHShareModal = ({ show, onHide, virtualhuman }) => {
  const { addFlashMessage } = useFlashMessage();
  const { Organisation } = useUser();
  const formMethods = useForm({
    defaultValues: virtualhuman,
  });
  const [emailInput, setEmailInput] = useState('');
  const [emails, setEmails] = useState(/** @type {string[]} */ ([]));
  const emailsRef = useRef(emails);
  emailsRef.current = emails;

  const { reset } = formMethods;

  const [saveRegisterVH] = useMutation(SAVE_REGISTER_VH, {
    onError: () => reset(virtualhuman),
  });

  const [sendEmailShareVH] = useMutation(SEND_EMAIL_SHARE_VH);

  const saveRegister = useCallback(
    (input) => {
      saveRegisterVH({
        variables: {
          input: { ID: virtualhuman.ID, ShowRegister: input.ShowRegister },
        },
      });
    },
    [virtualhuman, saveRegisterVH]
  );

  const sendInvites = useCallback(async () => {
    await new Promise((resolve) => setTimeout(resolve, 10));

    if (!emailsRef.current.length) {
      return;
    }
    const sendingEmails = [...emailsRef.current];
    setEmails([]);

    const { data } = await sendEmailShareVH({
      variables: { vhId: virtualhuman.ID, emails: sendingEmails },
    });

    tracker.track('share_invite', {
      type: 'virtual human',
      org_id: Organisation?.ID,
      org_name: Organisation?.Name,
      customer_stage: Organisation?.CustomerStage,
      emailCount: sendingEmails.length,
    });
    Userpilot.track('share_invite', {
      type: 'virtual human',
      emailCount: sendingEmails.length,
    });
    const sent = data?.emailVirtualHumanShareLink || [];
    const unsent = difference(sendingEmails, sent);

    if (sent.length) {
      addFlashMessage(`Sent email successfully to ${sent.length} users`);
    }

    if (unsent.length) {
      setEmails(unsent);
      addFlashMessage(
        `Failed to send emails to: "${unsent.join('", "')}"`,
        'error'
      );
    } else {
      onHide();
    }
  }, [sendEmailShareVH, virtualhuman, addFlashMessage, Organisation, onHide]);

  useAutosave(formMethods, saveRegister);

  return (
    <ShareModal
      title="Share your Virtual Human"
      show={show}
      onHide={onHide}
      render={() => (
        <>
          <Description>
            Share what you&apos;ve created and let others join in! Anyone with a
            share email or link can view your Virtual Human.
          </Description>

          <FieldContainer>
            <Label horizontal>
              <span style={{ flex: 1 }}>Email invite</span>
              <HelpTooltip>
                Users with an email invite can access this Virtual Human without
                creating an account.
              </HelpTooltip>
            </Label>
            <CreateSelect
              value={emails}
              onChange={setEmails}
              onInputChange={setEmailInput}
              isEmailList
            />
          </FieldContainer>
          <FieldContainer>
            <Label horizontal>Share</Label>
            <VHEmbeddableCopyCode
              token={virtualhuman.ShareToken}
              id={virtualhuman?.ID}
              title={virtualhuman?.Title}
            />
          </FieldContainer>
          <FormProvider {...formMethods}>
            <RegisterBox name="ShowRegister">
              <SwitchLabel>
                <Label as="div" horizontal>
                  Show create account banner
                </Label>
                <Description>
                  If enabled, users can join your organization
                </Description>
              </SwitchLabel>
            </RegisterBox>
          </FormProvider>
        </>
      )}
      footerRender={() => (
        <>
          <Button color="secondary" onClick={onHide}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={emails.length === 0 && emailInput.length === 0}
            onClick={sendInvites}
          >
            Send
          </Button>
        </>
      )}
    />
  );
};

export default VHShareModal;
