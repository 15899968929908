import { Icon } from '@virtidev/toolbox';
import styled, { css } from 'styled-components';

export const SaveBarContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: sticky;
  gap: 1rem;
  padding-left: 1rem;
`;

export const PublishedStatus = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const PublishIcon = styled(Icon).attrs(({ $published }) => ({
  icon: $published ? 'v-bold' : 'clock',
  size: '25px',
}))`
  ${({ theme, $published }) => css`
    color: ${$published ? theme.color.turquoise : theme.color.lightGrey};
  `}
`;
