import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Loading } from '@virtidev/toolbox';

const StyledFAIconWrapper = styled.div`
  opacity: ${(props) => (props.$show ? '1' : '0')};
  transition: 0.25s all;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const LoadingIndicator = (props) => {
  return (
    <StyledFAIconWrapper
      className={props.className}
      data-testid="load-indicator"
    >
      <Loading size={props.size} />
    </StyledFAIconWrapper>
  );
};

LoadingIndicator.defaultProps = {
  loading: true,
};

LoadingIndicator.propTypes = {
  size: PropTypes.string,
  loading: PropTypes.bool,
};

export default LoadingIndicator;
