import { useQuery } from '@apollo/client';
import React, { useMemo, useState } from 'react';
import useUser from '@core/helpers/useUser';
import ContactSalesModal from '@core/components/Signup/ContactSalesModal/ContactSalesModal';
import { getTrialRemainder } from '@core/components/Signup/legacy/helpers/getTrialRemainder';
import { READ_TRIAL_EXPIRY } from './TrialBanner.query';
import { ContactSalesButton, StyledTrialBanner } from './TrialBanner.styled';

export default function TrialBanner() {
  const [showModal, setShowModal] = useState(false);
  const { ID, Organisation } = useUser();

  const { data: orgData } = useQuery(READ_TRIAL_EXPIRY, {
    variables: {
      ID: Organisation?.ID,
    },
    skip: !Organisation?.ID,
  });

  const remainder = useMemo(() => {
    if (orgData) {
      return getTrialRemainder(orgData.readOneOrganisation.TrialExpiryDate);
    }
    return 0;
  }, [orgData]);

  return (
    <StyledTrialBanner>
      {orgData ? (
        remainder > 0 ? (
          <div>
            <strong>{remainder} days</strong> remaining on your demo account!
          </div>
        ) : (
          <div>
            Your trial period has expired, contact sales to upgrade your plan.
          </div>
        )
      ) : (
        <div />
      )}
      <ContactSalesModal show={showModal} setShow={setShowModal} />
      <ContactSalesButton onClick={() => setShowModal(true)}>
        Contact Sales
      </ContactSalesButton>
    </StyledTrialBanner>
  );
}
