import { Button } from '@virtidev/toolbox';
import styled from 'styled-components';

export const SubmitButton = styled(Button)`
  margin-top: 1rem;
  border-radius: 42px !important;
  display: flex;
  text-transform: capitalize;
`;

export const SelectWrapper = styled.div`
  width: 100%;
  color: black;
  text-align: left;
  margin: 1rem 0rem 0.5rem 0rem;
`;
