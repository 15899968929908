import { gql } from '@apollo/client';

// export const UPDATE_FEEDBACK_PAGE = gql`
// mutation updateFeedbackPage
// `

export const CREATE_FEEDBACK_PAGE = gql`
  mutation createFeedbackPage($input: CreateFeedbackPageInput!) {
    createFeedbackPage(input: $input) {
      ID
      Title
      SortOrder
      # FeedbackForm {
      #   ID
      #   Pages {
      #     nodes {
      #       ID
      #     }
      #   }
      # }
    }
  }
`;

export const DELETE_FEEDBACK_PAGE = gql`
  mutation deleteFeedbackPages($ids: [ID]!) {
    deleteFeedbackPages(ids: $ids)
  }
`;

export const FEEDBACK_FORM_FRAGMENT = gql`
  fragment feedbackForm on FeedbackForm {
    ID
    Pages {
      nodes {
        ID
      }
    }
  }
`;
