import { gql } from '@apollo/client';

export const READ_FEEDBACK_FORMS = gql`
  query readFeedbackFormsSelect($OrganisationID: ID!) {
    readFeedbackForms(
      filter: { Organisation: { ID: { eq: $OrganisationID } } }
    ) {
      edges {
        node {
          ID
          Title
        }
      }
    }
  }
`;
