import { getResizeImage } from '@core/helpers/imageResizing/getResizeImage';
import React, { useMemo } from 'react';
import * as Styled from './VHImage.styled';

const VHImage = ({
  environmentNodes,
  selectedEnvironmentID,
  avatarNodes,
  selectedAvatarID,
}) => {
  const selectedAvatar = useMemo(
    () => avatarNodes.find((av) => av.ID === selectedAvatarID),
    [selectedAvatarID, avatarNodes]
  );
  const selectedEnvironment = useMemo(
    () => environmentNodes.find((en) => en.ID === selectedEnvironmentID),
    [selectedEnvironmentID, environmentNodes]
  );
  return (
    <Styled.AvatarWrapper>
      <Styled.AvatarSubwrapper>
        {environmentNodes.map((environment) => (
          <Styled.EnvironmentImage
            key={environment.ID}
            visible={environment.ID === selectedEnvironment?.ID}
            fetchpriority={
              environment.ID === selectedEnvironment?.ID ? 'high' : 'low'
            }
            src={getResizeImage(environment.ImageMedia.TusID, {
              resize: {
                width: 1200,
                height: 674,
              },
            })}
            alt={`${environment.Gender} named ${environment.Name}`}
          />
        ))}
        {avatarNodes.map((avatar) => (
          <Styled.AvatarImage
            key={avatar.ID}
            visible={avatar.ID === selectedAvatar?.ID}
            fetchpriority={avatar.ID === selectedAvatar?.ID ? 'high' : 'low'}
            src={getResizeImage(
              avatar.AvatarMedia.TusID ?? avatar.ImageMedia.TusID,
              {
                resize: {
                  width: 1200,
                  height: 674,
                },
              }
            )}
            alt={`${avatar.Gender} named ${avatar.Name}`}
          />
        ))}
      </Styled.AvatarSubwrapper>
    </Styled.AvatarWrapper>
  );
};

export default VHImage;
