import { useFeature } from '@core/components/LaunchDarkly';
import { Titlebar } from '@core/components/Page';
import { withPageTemplate } from '@core/templates';
import { Tab, UrlParamTabs as Tabs, useStateUrl } from '@virtidev/toolbox';
import { flowRight } from 'lodash';
import VideosList from '@core/components/Media/components/VideosList';
import ScreenCapture from '@core/components/Media/components/ScreenCapture';
import MediaPageUploader from '@core/components/Media/components/MediaPageUploader/MediaPageUploader';
import ImagesList from '@core/components/Media/components/ImageList/ImagesList';

export const MediaPage = () => {
  const [screenCaptureFeature, stillImageSimFeature] = useFeature([
    'screen-capture-tool',
    'image-simulation-support',
  ]);
  const { value: tab } = useStateUrl({ key: 'tab' });

  return (
    <div>
      <Titlebar>Media</Titlebar>
      <MediaPageUploader hidden={tab === 'screen-capture'} />
      <Tabs>
        <Tab name="videos" title="Videos" component={VideosList} />
        {stillImageSimFeature && (
          <Tab name="images" title="Images" component={ImagesList} />
        )}
        {screenCaptureFeature && (
          <Tab
            name="screen-capture"
            title="Screen Capture"
            component={ScreenCapture}
          />
        )}
      </Tabs>
    </div>
  );
};

export default flowRight(withPageTemplate())(MediaPage);
