import { Icon, TextInput } from '@virtidev/toolbox';
import React from 'react';
import {
  StyledCustomisedInputEditInputWrapper,
  StyledCustomisedInputHeader,
  StyledInfo,
} from '../../../../../../../styled-components/StyledCustomisedInputs';
import Label from '../../../form/Label';
import ControlledToggleSwitch from '../../../form/ToggleSwitch/ControlledToggleSwitch';
import NoDragWrapper from '../../DragAndDrop/NoDragWrapper';
import { ToggleWrapper } from './Details.styled';

export default function Details({
  children,
  editing,
  register,
  info,
  typeTitle,
  icon,
}) {
  // title and header styling
  // actions
  // accept children

  return (
    editing && (
      <NoDragWrapper>
        <StyledCustomisedInputHeader>
          <Icon icon={icon} size="35px" color="#ccc" />
          {typeTitle}
        </StyledCustomisedInputHeader>
        <StyledInfo>{info}</StyledInfo>
        <StyledCustomisedInputEditInputWrapper>
          <Label>Question Text</Label>
          <TextInput placeholder="Question..." {...register('Title')} />
        </StyledCustomisedInputEditInputWrapper>
        {children}
        <ToggleWrapper>
          <ControlledToggleSwitch
            name="Required"
            value={true}
            noValue={false}
            label="Is required?"
          />
        </ToggleWrapper>
      </NoDragWrapper>
    )
  );
}
