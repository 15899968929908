import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Link, LinkProps } from 'react-router-dom';

/**
 * Use this to ensure that there's always an available reference to the previous url
 * @type {FC<{
 *    to: LinkProps["to"],
 *    search?: string,
 *    hash?: string,
 *    prevTitle?: string,
 *    nextPageState?: object,
 * }>}
 */
const LinkWithPrevState = ({
  to,
  search,
  hash,
  prevTitle,
  nextPageState,
  ...props
}) => (
  <Link
    {...props}
    to={
      typeof to === 'string'
        ? {
            pathname: to,
            search: search,
            hash: hash,
            state: {
              prevPath: window.location.pathname,
              prevTitle: prevTitle,
              ...nextPageState,
            },
          }
        : to
    }
  />
);

LinkWithPrevState.defaultProps = {
  hash: null,
  search: null,
  nextPageState: {},
};

LinkWithPrevState.propTypes = {
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ pathname: PropTypes.string }),
  ]).isRequired,
  nextPageState: PropTypes.object,
  hash: PropTypes.string,
  search: PropTypes.string,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  title: PropTypes.string,
};

export default LinkWithPrevState;
