import {
  Image,
  LoadingPlaceholder,
  MockText,
  Tooltip,
  Uploader,
} from '@virtidev/toolbox';
import styled, { css } from 'styled-components';
import { math } from 'polished';

export const VHAIFilesContainer = styled.div`
  ${({ theme: { spacing } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${spacing.md};
  `}
`;

export const VHAIFilesUploader = styled(Uploader)``;

export const VHAIFileListTitle = styled.div`
  ${({ theme: { font } }) => css`
    ${font.label}
  `}
`;

export const VHAIFileList = styled.div`
  ${({ theme: { spacing } }) => css`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
    align-items: stretch;
    gap: ${spacing.sm};
  `}
`;

export const VHAIFileContainer = styled.div`
  ${({ theme: { spacing, color, borderRadius } }) => css`
    border: 1px solid ${color.blue30};
    border-radius: ${borderRadius.sm};

    display: flex;
    flex-direction: column;
    gap: ${spacing.p1};
    position: relative;
    padding: ${spacing.sm} ${spacing.md};
  `}
`;

export const VHAIFileSize = styled.span`
  ${({ theme: { font, color } }) => css`
    ${font.note}
    color: ${color.midnight10};
  `}
`;

export const VHAIFileName = styled.span`
  ${({ theme: { font, fragments, color } }) => css`
    ${font.label}
    ${fragments.ellipsis}
    color: ${color.midnight10};
  `}
`;

export const VHAIFileIcon = styled(Image)`
  ${({ theme: { spacing } }) => css`
    margin: ${spacing.p2} auto ${spacing.p3} ${spacing.p2};
    height: 50px;
    max-width: 50px;
  `}
`;

export const VHAIFileMenu = styled(Tooltip).attrs({
  type: 'menu',
})`
  ${({ theme: { spacing, borderRadius } }) => css`
    position: absolute;
    top: ${spacing.md};
    right: ${spacing.sm};

    + div {
      border-radius: ${borderRadius.p1};
      .content {
        padding: ${spacing.p1} 0;
      }
    }
  `}
`;

const menuItemFragment = css`
  ${({ theme: { color, spacing, borderRadius } }) => css`
    text-decoration: none;
    color: ${color.base};
    padding: ${spacing.p3} ${spacing.p5};
    background: ${color.contrast.base};
    min-width: 120px;
    text-align: start;
    border: 0;

    * + & {
      border-top: 1px solid ${color.midnight30};
    }

    &:first-child {
      border-top-left-radius: ${borderRadius.p1};
      border-top-right-radius: ${borderRadius.p1};
    }

    &:last-child {
      border-bottom-left-radius: ${borderRadius.p1};
      border-bottom-right-radius: ${borderRadius.p1};
    }

    &:hover {
      background: ${color.blue40};
    }
    &:active {
      background: ${color.blue20};
    }
  `}
`;

export const VHAIFileMenuItem = styled.button`
  ${menuItemFragment}
`;

export const VHAIFileMenuLink = styled.a`
  ${menuItemFragment}
`;

export const VHAIPlaceholder = styled(LoadingPlaceholder)`
  flex-direction: column;
  margin: 0;
`;

export const VHAIMockText = styled(MockText)`
  ${({ theme: { fontSize, spacing }, $small }) => css`
    height: ${fontSize.md};
    margin: 0;

    ${$small &&
    css`
      height: ${fontSize.sm};
      width: ${math(`3 * ${spacing.md}`)};
    `}
  `}
`;
