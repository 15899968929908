import { gql } from '@apollo/client';

export const COPY_QUESTION = gql`
  mutation copyQuestion($QuestionID: ID!) {
    copyQuestion(QuestionID: $QuestionID) {
      ID
      Title
      Options {
        nodes {
          ID
          Name
          Value
        }
      }
      Required
      Type
      Placeholder
      SortOrder
      Length
      FirstLabel
      LastLabel
      PageID
      CharacterLimit
    }
  }
`;
