import React, { useCallback, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _ from 'lodash';

import StyledPage from '../../components/StyledPage';
import LoadingPageIndicator from '../../components/LoadingPageIndicator';
import PageLoadError from '../../components/PageLoadError';
import EditableTitle from '../../components/button-modal-forms/EditableTitle';
import RedirectWithPrevState from '../../components/RedirectWithPrevState';
import withFlashMessaging from '../WithFlashMessaging';
import {
  EDIT_VIRTUAL_HUMAN_NAME,
  READ_VIRTUAL_HUMAN,
  SET_VH_PUBLIC,
} from '../../queries/VirtualHumanQueries';
import withUser from '../WithUser';
import { useCanEditVirtualHumans } from '../../apps/core/src/helpers/permissions.hooks';
import NoAccessPage from '../../apps/core/src/pages/user/error/no-access';
import { useLocation } from 'react-router-dom';
import { Button } from '@virtidev/toolbox';
import AccessModal from '@core/components/VirtualHumanForm/components/VirtualHumanEditors/AccessModal/AccessModal';
import useUser from '@core/helpers/useUser';
import VHShareModal from '@core/components/VirtualHumanForm/components/VirtualHumanEditors/VHShareModal/VHShareModal';
import useUpdateVHMutation from '@base/utility/VHHooks/useUpdateVHMutation';

const EditableTitleZIndexed = styled(EditableTitle)`
  position: relative;
  z-index: 2;
  padding: 0 var(--content-side-padding);
  min-height: 1.6rem;
`;

const WithVirtualHumanPageTemplate = (
  WrappedComponent,
  pageKey = undefined,
  hasSaveBar = false,
  stretchFullHeight = false,
  renderBackBar = true
) => {
  const VirtualHumanPageTemplate = (props) => {
    const location = useLocation();
    const user = useUser();
    const { data, loading, error, refetch } = useQuery(READ_VIRTUAL_HUMAN, {
      variables: {
        ID: props.match.params.ssVirtualHumanID,
        organisationID: Number(user?.OrganisationID),
      },
    });
    const virtualHumanData = _.get(data, 'readOneVirtualHuman');
    const canEditVirtualHumans = useCanEditVirtualHumans();

    const [showAccessModal, setShowAccessModal] = useState(false);
    const openAccessModal = useCallback(() => {
      setShowAccessModal(true);
    }, []);
    const closeAccessModal = useCallback(() => {
      setShowAccessModal(false);
    }, []);

    const [showShareModal, setShowShareModal] = useState(false);
    // temporary, setting the vh public for backwards compatibility with mobile apps
    const [setPublic] = useMutation(SET_VH_PUBLIC, {
      variables: { virtualHumanID: virtualHumanData?.ID },
    });
    const [updateVHDB, { isLoading: updatingVHDBVH }] = useUpdateVHMutation();

    const openShareModal = useCallback(async () => {
      // temp, as above
      if (virtualHumanData && !virtualHumanData?.PublicAccess) {
        setPublic();
        const updated = await updateVHDB(virtualHumanData.ExternalID, {
          isEmbeddable: true,
        });
      }
      // end temp

      setShowShareModal(true);
    }, [setPublic, updateVHDB, virtualHumanData]);
    const closeShareModal = useCallback(() => {
      setShowShareModal(false);
    }, []);

    const [editName, { loading: mutatingTitle }] = useMutation(
      EDIT_VIRTUAL_HUMAN_NAME,
      {
        variables: {
          Input: {
            ID: props.match.params.ssVirtualHumanID,
            Title: true,
          },
        },
        onError: (err) => {
          props.addFlashMessage(
            'There was an error updating the virtual human name',
            'error'
          );
        },
        onCompleted: (data) => {
          props.addFlashMessage('Virtual Human name sucessfully updated');
        },
      }
    );
    if (!user?.ID) {
      return <RedirectWithPrevState to="/login" />;
    }

    if (!canEditVirtualHumans && !location.pathname.includes('/my-logs/')) {
      return <NoAccessPage />;
    }

    return (
      <StyledPage
        stretchFullHeight={stretchFullHeight}
        pageKey={pageKey}
        hasSaveBar={hasSaveBar}
        goBackToLinkText="all virtual humans"
        goBackToLink="/virtual-humans"
        renderBackBar={renderBackBar}
      >
        {loading && <LoadingPageIndicator />}
        {error && <PageLoadError graphQLErrorObj={error} />}
        {!loading && !error && !virtualHumanData && (
          <RedirectWithPrevState to="/virtual-humans" />
        )}
        {!loading && !error && virtualHumanData && (
          <>
            <EditableTitleZIndexed
              className="edit-title"
              htmlID="edit-title"
              htmlName="edit-title"
              title={virtualHumanData.Title}
              onSave={(newTitle) => {
                editName({
                  variables: {
                    Input: {
                      ID: virtualHumanData.ID,
                      Title: newTitle,
                    },
                  },
                  optimisticResponse: {
                    updateVirtualHuman: {
                      ID: virtualHumanData.ID,
                      Title: newTitle,
                      __typename: 'VirtualHuman',
                    },
                  },
                });
              }}
              loading={loading}
              maxLength={50}
              editable={canEditVirtualHumans}
            >
              <Button
                color="turquoise"
                design="outline"
                onClick={openAccessModal}
              >
                Manage access
              </Button>
              <Button color="turquoise" onClick={openShareModal} icon="link">
                Share
              </Button>
              <AccessModal
                show={showAccessModal}
                onHide={closeAccessModal}
                virtualhuman={virtualHumanData}
              />
              <VHShareModal
                show={showShareModal}
                onHide={closeShareModal}
                virtualhuman={virtualHumanData}
              />
            </EditableTitleZIndexed>

            <WrappedComponent
              {...props}
              userOrganisationID={user.OrganisationID}
              data={data}
              refetchVirtualHuman={refetch}
            />
          </>
        )}
      </StyledPage>
    );
  };
  VirtualHumanPageTemplate.propTypes = {
    addFlashMessage: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        ssVirtualHumanID: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };
  return withFlashMessaging(withUser(VirtualHumanPageTemplate));
};
export default WithVirtualHumanPageTemplate;
