import { FC } from 'react';
import { RegisterFormContainer } from '@core/components/Signup/RegisterForm/RegisterForm.styled';
import RegisterInfo from '@core/components/Signup/RegisterInfo/RegisterInfo';
import {
  LoadingText,
  LoadingWrapper,
  RegisterLoadingContent,
  RegisterLoadingLogo,
  RegisterLoadingTitle,
} from '@core/components/Signup/RegisterLoading/RegisterLoading.styled';
import { Loading } from '@virtidev/toolbox';

/**
 * @type {FC<{
 *    trialLength?: number,
 *    existingOrg?: boolean,
 * }>}
 */
export const RegisterLoading = ({ trialLength, existingOrg }) => (
  <>
    {!existingOrg && <RegisterInfo hideDetails trialLength={trialLength} />}
    <RegisterFormContainer>
      <RegisterLoadingLogo src="/images/virti-dark.svg" />
      <RegisterLoadingTitle>Sign up</RegisterLoadingTitle>
      <RegisterLoadingContent>
        <LoadingWrapper>
          <Loading size="large" />
        </LoadingWrapper>
        {!existingOrg && (
          <LoadingText>
            Just a minute, we're adding immersive and interactive content to
            your organisation...
          </LoadingText>
        )}
      </RegisterLoadingContent>
    </RegisterFormContainer>
  </>
);

export default RegisterLoading;
