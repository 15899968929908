import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Icon from './Icon';
import { Loading } from '@virtidev/toolbox';

const StyledSmallTranscoding = styled.div`
  ${({ theme }) => css`
    color: ${theme.color.primary};
    display: flex;
    align-items: center;
    ${
      /** @param {{ transcodingStatus: string }} p */
      ({ transcodingStatus }) =>
        transcodingStatus === 'Failed' &&
        css`
          color: ${theme.color.error};
        `
    }
    ${({ transcodingStatus }) =>
      transcodingStatus === 'Cancelled' &&
      css`
        color: ${theme.color.warning};
      `}
  `}
`;

const StyledTranscodingIndicatorText = styled.span`
  margin-left: 1rem;
  font-weight: 500;
`;

const TranscodingIcon = (props) => {
  const icon = useMemo(() => {
    if (props.transcodingStatus === 'Success') {
      return <Icon type="video_editing" size="small" name="icon-complete" />;
    }
    if (props.transcodingStatus === 'Transcoding') {
      return <Loading />;
    }
    if (props.transcodingStatus !== 'NoUpload') {
      return <Icon type="redesign" name="alert-error" />;
    }
    return null;
  }, [props.transcodingStatus]);
  return (
    <StyledSmallTranscoding
      transcodingStatus={props.transcodingStatus}
      title={props.transcodingStatus}
    >
      {icon}
      {props.includeText && (
        <StyledTranscodingIndicatorText>
          {props.transcodingStatus === 'Success'
            ? props.status || 'Done'
            : props.transcodingStatus}
        </StyledTranscodingIndicatorText>
      )}
    </StyledSmallTranscoding>
  );
};

TranscodingIcon.defaultProps = {
  includeText: false,
};

TranscodingIcon.propTypes = {
  includeText: PropTypes.bool,
  transcodingStatus: PropTypes.string.isRequired,
  status: PropTypes.string,
};

export default TranscodingIcon;
