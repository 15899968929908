import { usePrevious } from '@base/utility/CustomHooks';
import useGetVHData from '@base/utility/VHHooks/useGetVHData';
import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * @typedef {import('@core/models/vh-server/vh-server-coaching-session-report.types').CoachingSessionCombined} CoachingSessionCombined
 */

/**
 * @type {
 *  (readyToCheck: boolean, assessmentId: number, onReportUpdated: (report: CoachingSessionCombined) => void) =>
 *  {
 *    loading: boolean; error: any; data: {data: CoachingSessionCombined}; resetData: () => void;
 *  }
 * }
 */
export const useGetSessionReportUntilDone = (
  readyToCheck,
  assessmentId,
  onReportUpdated
) => {
  const { data, loading, error, refetch, resetData } = useGetVHData(
    `assessments/${assessmentId}/coaching-session-report`,
    { skip: !readyToCheck }
  );

  const [startedChecking, setStartedChecking] = useState(false);
  const intervalRef = useRef();
  const dataReportRef = useRef(data?.data?.data);
  dataReportRef.current = data?.data?.data;
  const refetchRef = useRef(data?.data?.data);
  refetchRef.current = refetch;

  useEffect(() => {
    // Cleanup on unmount to prevent leaks
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    // Reset when assessmentId changes
    setStartedChecking(false);
  }, [assessmentId]);

  const startChecking = useCallback(() => {
    setStartedChecking(true);
    clearInterval(intervalRef.current);
    intervalRef.current = window.setInterval(() => {
      console.log(dataReportRef.current);
      console.log(dataReportRef.current?.generatingStatus);
      if (
        !dataReportRef.current ||
        dataReportRef.current?.generatingStatus === 'pending'
      ) {
        refetchRef.current();
      } else {
        clearInterval(intervalRef.current);
      }
    }, 3000);
  }, []);

  useEffect(() => {
    if (readyToCheck && !startedChecking) {
      startChecking();
    }
  }, [readyToCheck, startChecking, startedChecking]);

  useEffect(() => {
    const status = data?.data?.data?.generatingStatus;
    if (status !== 'pending') {
      onReportUpdated(data?.data?.data);
      if (status === 'failed') {
        console.error('Report generation failed.');
      }
    }
  }, [data?.data?.data, onReportUpdated]);

  return { data, loading, error, resetData };
};
