import { Datetime } from '@virtidev/toolbox';
import { useMemo } from 'react';
import StyledLink from '../../../../../../../styled-components/StyledLink';
import { DeletedUserText } from '../DashboardUsagePanel.styled';

export const UsageSubmissionItem = ({ item }) => {
  const {
    ID,
    Created,
    Member: { ID: userId, Name },
    FeedbackForm: { ID: formId, Title },
  } = item;

  const userLink = useMemo(() => {
    if (userId === 0 || !userId) {
      return <DeletedUserText>(Deleted)</DeletedUserText>;
    }
    return (
      <StyledLink to={`/users/${userId}`} title={Name || null}>
        {Name || <DeletedUserText>(No name)</DeletedUserText>}
      </StyledLink>
    );
  }, [userId, Name]);

  const itemLink = useMemo(
    () =>
      formId ? (
        <StyledLink
          to={{
            pathname: `/analytics-data/forms/${formId}`,
            search: `tab=submissions&submission=${ID}`,
          }}
          title={Title || null}
        >
          {Title || <DeletedUserText>(No title)</DeletedUserText>}
        </StyledLink>
      ) : (
        <DeletedUserText>(Deleted)</DeletedUserText>
      ),
    [formId, Title, ID]
  );

  return (
    <div>
      {userLink} submitted to {itemLink}{' '}
      <Datetime datetime={Created} long difference={24 * 7} />
    </div>
  );
};

export default UsageSubmissionItem;
