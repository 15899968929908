import { useMutation, useQuery } from '@apollo/client';
import { Button, Select } from '@virtidev/toolbox';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { READ_ENVIRONMENTS } from '../../CxEnvironmentsEditor.query';
import { useFlashMessage } from '../../../../../../FlashMessage';
import ConfirmationModal from '../../../../../../../../../../components/modals/ConfirmationModal';
import { UPDATE_ENVIRONMENT_AVATARS } from './SelectedEnvironmentAvatarsEditor.query';
import * as Styled from './SelectedEnvironmentAvatarsEditor.styled';
import { READ_AVATARS } from '../../../CxAvatarsEditor/CxAvatarsEditor.query';

const SelectedEnvironmentAvatarsEditor = ({ selectedEnvironment }) => {
  const { addFlashMessage } = useFlashMessage();
  const [updateEnvironmentMutation, { loading: isUpdating }] = useMutation(
    UPDATE_ENVIRONMENT_AVATARS,
    {
      onCompleted: () => {
        addFlashMessage('Updated environment avatars');
      },
      onError: () => {
        addFlashMessage('Failed to update environment avatars', 'error');
      },
      refetchQueries: [{ query: READ_ENVIRONMENTS }],
    }
  );
  const currentRelationAvatarIDs = useMemo(
    () => selectedEnvironment.Avatars.nodes.map((avatar) => avatar.ID),
    [selectedEnvironment?.Avatars?.nodes]
  );
  const [showConfirmForAvatarRemoval, setShowConfirmForAvatarRemoval] =
    useState(false);

  const onCloseConfirm = useCallback(() => {
    console.log(showConfirmForAvatarRemoval);
    const avatarsWithRemoved = currentRelationAvatarIDs.filter(
      (avatarID) => avatarID !== showConfirmForAvatarRemoval.ID
    );
    updateEnvironmentMutation({
      variables: {
        EnvironmentID: selectedEnvironment.ID,
        AvatarIDs: avatarsWithRemoved,
      },
    });
    setShowConfirmForAvatarRemoval(false);
  }, [
    updateEnvironmentMutation,
    selectedEnvironment?.ID,
    currentRelationAvatarIDs,
    showConfirmForAvatarRemoval,
  ]);

  const { data: avatarsData } = useQuery(READ_AVATARS);

  const addRelationOptions = useMemo(() => {
    const currentRelatedAvatarIDs =
      selectedEnvironment.Avatars?.nodes?.map((avatar) => avatar.ID) ?? [];
    const avatars = avatarsData?.readAvatars?.nodes
      .filter((avatar) => !currentRelatedAvatarIDs.includes(avatar.ID))
      .map((avatar) => ({
        label: `${avatar.DisplayName} (${avatar.Name})`,
        value: avatar.ID,
      }));
    return avatars ?? [];
  }, [avatarsData?.readAvatars.nodes, selectedEnvironment.Avatars.nodes]);

  const selectRef = useRef(null);

  const onAddChange = useCallback(
    async (newSelection) => {
      if (!newSelection) return; // return if empty i.e. select has been cleared
      const avatarsWithNew = [...currentRelationAvatarIDs, newSelection.value];
      await updateEnvironmentMutation({
        variables: {
          EnvironmentID: selectedEnvironment.ID,
          AvatarIDs: avatarsWithNew,
        },
      });
      if (selectRef.current?.select?.clearValue) {
        selectRef.current.select.clearValue();
      }
    },
    [
      currentRelationAvatarIDs,
      selectedEnvironment.ID,
      updateEnvironmentMutation,
    ]
  );

  return (
    <Styled.Wrapper>
      <h2>Associated Avatars</h2>
      <Select
        options={addRelationOptions}
        placeholder="Add Avatar Relation"
        loading={isUpdating}
        onChange={onAddChange}
        ref={selectRef}
      />
      <div>
        {selectedEnvironment.Avatars.nodes.map((avatar) => (
          <Styled.EnvironmentAvatarItem key={avatar.ID}>
            <div>{`${avatar.DisplayName} (${avatar.Name})`}</div>
            <Button
              color="danger"
              icon="alert"
              onClick={() => setShowConfirmForAvatarRemoval(avatar)}
              size="small"
            >
              Delete
            </Button>
          </Styled.EnvironmentAvatarItem>
        ))}
      </div>

      {showConfirmForAvatarRemoval && (
        <ConfirmationModal
          onConfirm={onCloseConfirm}
          onCancel={() => setShowConfirmForAvatarRemoval(false)}
          verificationTextRequired={
            showConfirmForAvatarRemoval?.DisplayName ??
            showConfirmForAvatarRemoval.Name ??
            ''
          }
          loading={isUpdating}
        >
          <>
            DANGER: Make sure this avatar is not used by ANY virtual humans with
            this environment before deletion.{' '}
            {(showConfirmForAvatarRemoval?.DisplayName ??
              showConfirmForAvatarRemoval.Name) && (
              <>
                Type the avatar name{' '}
                <strong>
                  {showConfirmForAvatarRemoval?.DisplayName ??
                    showConfirmForAvatarRemoval.Name}
                </strong>{' '}
                to confirm.
              </>
            )}
          </>
        </ConfirmationModal>
      )}
    </Styled.Wrapper>
  );
};

export default SelectedEnvironmentAvatarsEditor;
