import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Image } from '@virtidev/toolbox';

const StyledImgWrapper = styled.div`
  width: 240px;
  height: 135px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  img {
    width: initial;
    border-radius: initial;
    height: 100%;
  }
`;
const NoImg = styled.div`
  background-color: var(--text-input-bg-color);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ccc;
  cursor: default;
  font-style: italic;
`;

const HeaderImage = (props) => {
  return (
    <StyledImgWrapper>
      <Image alt="" src={props.imageSrc} />
    </StyledImgWrapper>
  );
};

HeaderImage.propTypes = {
  imageSrc: PropTypes.string,
};

export default HeaderImage;
