import React from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/client/react/components';
import { PaginationBottom } from '../components/Pagination';
import LoadingPageIndicator from '../components/LoadingPageIndicator';
import PageLoadError from '../components/PageLoadError';
import CourseItem, { CourseList } from '../components/CourseItem';
import MyCourseItem from '../components/MyCourseItem';
import _ from 'lodash';
import withUser from '../HOCs/WithUser';
import useUser from '../apps/core/src/helpers/useUser';
import { useQuery } from '@apollo/client';
import { usePageScroll } from '../apps/core/src/components/Page';
import { PaginationControl, usePaginationUrl } from '@virtidev/toolbox';

const PaginatedCoursesList = (props) => {
  const { OrganisationID } = useUser();
  const { scrollTop } = usePageScroll();
  const { pageQuery, controlProps } = usePaginationUrl({
    onChange: scrollTop,
    pageSize: props.paginationState.limit,
  });
  const { loading, error, data } = useQuery(props.dataQuery, {
    variables: {
      ...props.dataQueryVariables,
      filter: {
        ...(props.dataQueryVariables.filter || {}),
        Organisation: { ID: { eq: OrganisationID } },
      },
      ...pageQuery,
    },
  });

  if (loading) return <LoadingPageIndicator />;
  if (error) return <PageLoadError graphQLErrorObj={error} />;
  const edges = _.get(data, `${props.queryName}.edges`, []);
  const pageInfo = _.get(data, `${props.queryName}.pageInfo`, {});
  return (
    <React.Fragment>
      <CourseList data-testid="courses-list">
        {(!props.userMode || (props.userMode && props.userMode === 'admin')) &&
          edges.length > 0 &&
          edges.map((edge) => (
            <CourseItem data={edge.node} key={edge.node.ID} />
          ))}
        {props.userMode &&
          props.userMode === 'user' &&
          edges.length > 0 &&
          edges.map((edge) => (
            <MyCourseItem data={edge.node} key={edge.node.ID} />
          ))}

        {edges.length === 0 && (
          <div>
            No courses found, please contact your admin and check back later.
          </div>
        )}
      </CourseList>
      <PaginationControl
        siblingCount={2}
        {...controlProps}
        total={data?.readCourses?.pageInfo?.totalCount}
      />
    </React.Fragment>
  );
};

PaginatedCoursesList.defaultProps = {
  dataQueryVariables: {},
};

PaginatedCoursesList.propTypes = {
  changePageCallback: PropTypes.func.isRequired,
  paginationState: PropTypes.object.isRequired,
  dataQuery: PropTypes.object.isRequired,
  dataQueryVariables: PropTypes.object,
  queryName: PropTypes.string.isRequired,
  userMode: PropTypes.string,
};

export default withUser(PaginatedCoursesList);
