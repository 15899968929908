import { useEffect, useRef } from 'react';

// used to check if component is mounted
// (allows for guarding against state updates on unmounted components)
export const useMounted = function () {
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return mounted;
};

export default useMounted;
