import { FC } from 'react';
import LinkWithPrevState from '@base/components/LinkWithPrevState';
import {
  ThumbnailLink,
  LearnerCard,
  Thumbnail,
  CardTitle,
  ProgressBlock,
  ProgressLabel,
  ProgressValue,
} from '@core/components/DataListCard/LearnerDataCard.styled';
import PropTypes from 'prop-types';
import { LinkProps } from 'react-router-dom';
import Content360Tag from '@core/components/DataListCard/components/Content360Tag/Content360Tag';

/**
 * @type {FC<{
 *    title?: string | undefined | null,
 *    thumbnailUrl?: string | undefined | null,
 *    to: LinkProps["to"],
 *    progress?: string | number | undefined | null,
 *    content360?: boolean,
 * }>}
 */
export const LearnerDataCard = ({
  title,
  thumbnailUrl,
  to,
  progress,
  content360,
  ...props
}) => {
  return (
    <LearnerCard {...props}>
      <ThumbnailLink to={to}>
        <Thumbnail
          src={thumbnailUrl}
          alt={`Thumbnail for ${title || 'Content'}`}
        />
        {content360 != null && <Content360Tag content360={content360} />}
      </ThumbnailLink>
      <LinkWithPrevState to={to}>
        <CardTitle>{title || '(Untitled Course)'}</CardTitle>
      </LinkWithPrevState>
      {progress != null && (
        <ProgressBlock>
          <ProgressLabel>Your progress:</ProgressLabel>
          <ProgressValue>{progress}%</ProgressValue>
        </ProgressBlock>
      )}
    </LearnerCard>
  );
};

LearnerDataCard.propTypes = {
  data: PropTypes.shape({
    ID: PropTypes.string,
    Title: PropTypes.string,
    MemberProgress: PropTypes.number,
  }),
};

export default LearnerDataCard;
