import styled from 'styled-components';

export const StyledPlaceholderFill = styled.div`
  width: 10rem;
  height: 1rem;
`;

export const CodesWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;
