import { MockText } from '@virtidev/toolbox';
import {
  EmptyList,
  LoadingPlaceholder,
  UsageList,
} from '../DashboardUsagePanel.styled';
import UsageActivityItem from './UsageActivityItem';

export const UsageActivityTable = ({ data }) => {
  if (!data) {
    return (
      <UsageList>
        {Array.from(Array(3)).map((_, i) => (
          <LoadingPlaceholder key={`placeholder-${i}`}>
            <MockText />
          </LoadingPlaceholder>
        ))}
      </UsageList>
    );
  }

  if (!data.length) {
    return <EmptyList>No recent activity found.</EmptyList>;
  }

  return (
    <UsageList>
      {data.map((item) => (
        <UsageActivityItem key={item.ID} item={item} />
      ))}
    </UsageList>
  );
};

export default UsageActivityTable;
