import { gql } from '@apollo/client';

export const SEARCH_VIRTUAL_HUMANS = gql`
  query readVirtualHumans($filter: String!, $organisationID: ID!) {
    readVirtualHumans(
      filter: {
        Title: { contains: $filter }
        Organisation: { ID: { eq: $organisationID } }
      }
    ) {
      edges {
        node {
          ID
          Title
          Type
        }
      }
    }
  }
`;
