import styled from 'styled-components';
import StyledTextInput from '../styled-components/StyledTextInput';
import Button from './buttons/Button';

const StyledLoggedOutPage = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--login-page-bg-color);
`;

const StyledForm = styled.form`
  position: relative;
  max-width: 40rem;
  background-color: #ccc;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem;
  flex: 1;
  background-color: var(--login-form-bg-color);
  border-radius: 6px;
`;

const ModifiedTextInput = styled(StyledTextInput)`
  margin-bottom: 1rem;
`;

const StyledSubmitBtn = styled(Button)`
  width: 100%;
`;

const StyledLogoWrapper = styled.div`
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 4rem;
`;

const StyledLogo = styled.img`
  height: var(--nav-logo-height);
`;
const StyledLogoComponent = (props) => (
  <StyledLogo src="/images/virti-dark.svg" alt="virti logo" />
);

const StyledHeader = styled.h1`
  margin-bottom: 2rem;
  font-size: 1.5rem;
`;

const StyledSuccessText = styled.div`
  margin-top: 1rem;
`;

export {
  StyledLoggedOutPage,
  StyledForm,
  ModifiedTextInput as StyledTextInput,
  StyledSubmitBtn,
  StyledLogoWrapper,
  StyledLogoComponent as StyledLogo,
  StyledHeader,
  StyledSuccessText,
};
