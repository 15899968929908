import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Tab, TabNav } from '../styled-components/StyledTabNavV2';
import styled from 'styled-components';
import { useInjectedVHTypeConfig } from '../apps/pd-webgl/src/components/vh-types/vhTypeHooks';
import { useLocation } from 'react-router-dom';
import useFeature from '../apps/core/src/components/LaunchDarkly';

const TabNavZIndexed = styled(TabNav)`
  margin-top: 0.6rem;
  position: relative;
  z-index: 1;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
`;

const TabWrapper = styled.div`
  display: flex;
`;

const VirtualHumanTabNav = (props) => {
  const vhType = props.virtualHumanData?.Type ?? 'default';
  const location = useLocation();
  const vhTypeConfig = useInjectedVHTypeConfig(vhType);
  const statesEditorFeature = useFeature('states-editor');
  const vhAiGeneratorFeature = useFeature('vh-ai-generator');

  const navOptions = useMemo(
    () =>
      vhTypeConfig?.navigationLocations
        .filter((option) => !option?.analytics)
        .filter((option) =>
          option.vhAiGeneratorFeature ? vhAiGeneratorFeature : true
        )
        .filter((option) =>
          option.statesEditorFeature ? statesEditorFeature : true
        ) ?? [],
    [vhTypeConfig, statesEditorFeature, vhAiGeneratorFeature]
  );

  return (
    <TabNavZIndexed>
      <TabWrapper>
        {navOptions.map((navOption, index) => (
          <Tab
            key={index}
            data-testid={navOption.testId}
            $isActive={navOption.isActive(location)}
            to={navOption.to(props.virtualHumanData)}
          >
            {navOption.title}{' '}
            {navOption.extraContent
              ? navOption.extraContent(props.virtualHumanData?.ExternalID)
              : undefined}
          </Tab>
        ))}
      </TabWrapper>
    </TabNavZIndexed>
  );
};

VirtualHumanTabNav.propTypes = {
  virtualHumanData: PropTypes.object.isRequired,
};

export default VirtualHumanTabNav;
