import { gql } from '@apollo/client';

export const READ_ENVIRONMENTS = gql`
  query readEnvironments {
    readEnvironments {
      nodes {
        ID
        Name
        DisplayName
        Description
        IsPreview
        IsDevelopment
        URL
        ImageMedia {
          ID
          URL
          TusID
        }
        Avatars {
          nodes {
            ID
            Name
            DisplayName
          }
        }
      }
    }
  }
`;

export const UPDATE_ENVIRONMENT = gql`
  mutation updateEnvironment($input: UpdateEnvironmentInput!) {
    updateEnvironment(input: $input) {
      ID
      Name
      DisplayName
      Description
      IsPreview
      IsDevelopment
      URL
      ImageMedia {
        ID
        URL
        TusID
      }
      Avatars {
        nodes {
          ID
          Name
        }
      }
    }
  }
`;

export const CREATE_ENVIRONMENT = gql`
  mutation createEnvironment($input: CreateEnvironmentInput!) {
    createEnvironment(input: $input) {
      ID
      Name
      DisplayName
      Description
      IsPreview
      IsDevelopment
      URL
      ImageMedia {
        ID
        URL
        TusID
      }
      Avatars {
        nodes {
          ID
          Name
          DisplayName
        }
      }
    }
  }
`;

export const DELETE_ENVIRONMENT = gql`
  mutation deleteEnvironments($ids: [ID]!) {
    deleteEnvironments(ids: $ids)
  }
`;
