import { useCallback } from 'react';
import getConfig from '@base/lib/config';
import { saveAs } from 'file-saver';
import { GetAccessToken } from '@base/utility/LoginUtility';
import useFlashMessage from '@core/components/FlashMessage';
import { captureException } from '@sentry/browser';

const exportURL = getConfig('REACT_APP_REMOTE_URI');

/**
 *
 * @param {{
 *    simulationID?: string,
 *    virtualHumanID?: string,
 *    title?: string,
 * }} options
 * @returns
 */
export const useScormDownload = ({ simulationID, virtualHumanID, title }) => {
  const { addFlashMessage } = useFlashMessage();

  return useCallback(async () => {
    const url = `${exportURL}scorm/generateSCORM`;
    const headers = {
      accept: '*/*',
      'content-type': 'application/zip',
      authorization: `Bearer ${GetAccessToken()}`,
    };
    if (!simulationID && !virtualHumanID) {
      const error = new Error('Missing simulation or virtual human ID');
      console.error(error);
      captureException(error);
      return;
    }
    const body = JSON.stringify(
      (simulationID && { simulationID }) ||
        (virtualHumanID && { virtualHumanID })
    );

    try {
      const res = await fetch(url, {
        method: 'POST',
        body,
        // mode: 'no-cors', // no-cors, *cors, same-origin
        headers,
      });
      const blob = await res.blob();
      const filename =
        res.headers.get('filename') ||
        title ||
        (simulationID && `simulation-${simulationID}`) ||
        (virtualHumanID && `virtual-human-${virtualHumanID}`);

      saveAs(blob, filename);
    } catch (e) {
      const message = title ? ` for "${title}"` : '';
      addFlashMessage(`Error downloading SCORM file${message}`, 'error');
      console.log(e);
      captureException(e);
    }
  }, [simulationID, virtualHumanID, title, addFlashMessage]);
};

export default useScormDownload;
