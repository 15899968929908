import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import EditableTitle from './button-modal-forms/EditableTitle';
import HeaderImage from './HeaderImage';

const StyledHeaderArea = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.showImg ? '17rem minmax(10rem, 1fr) 1fr' : 'minmax(10rem, 1fr) 1fr'};
  background-color: #fff;
  padding: 1rem 2rem;
  position: relative;
`;
const StyledTitleArea = styled.div`
  margin-top: 0.8rem;
  padding-right: 2rem;
`;
const StyledSubtitle = styled.h2`
  font-size: 0.9rem;
  margin-top: 0.3rem;
  color: #b7c4cb;
  line-height: 1.3rem;
  display: -webkit-box;

  ${(props) =>
    !props.showMore
      ? css`
          line-clamp: 2;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        `
      : ''}
`;

const StyledHeaderContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledShowMoreButton = styled.button`
  margin-top: 0.4rem;
  background: none;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 0.9rem;
  color: var(--primary-color);
`;

const HeaderArea = (props) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <StyledHeaderArea showImg={props.showImg} className={props.className}>
      {props.showImg && (
        <div>
          <HeaderImage
            imageSrc={props.imageSrc}
            editImageMutation={props.editImageMutation}
          />
        </div>
      )}
      <StyledTitleArea>
        <EditableTitle
          className="edit-title"
          htmlID="edit-title"
          htmlName="edit-title"
          title={props.title ? props.title : '(Unnamed)'}
          onSave={props.editTitleCallback}
          loading={props.loading}
          maxLength={50}
          editable={!!props.editTitleCallback}
          editTitleMenuButtons={props.editTitleMenuButtons}
        />

        <StyledSubtitle showMore={showMore}>{props.subtitle}</StyledSubtitle>
        {props.subtitle?.length > 190 && (
          <StyledShowMoreButton onClick={() => setShowMore(!showMore)}>
            show {showMore ? 'less' : 'more'}
          </StyledShowMoreButton>
        )}
      </StyledTitleArea>
      <StyledHeaderContent>{props.children}</StyledHeaderContent>
    </StyledHeaderArea>
  );
};

HeaderArea.defaultProps = {
  showImg: true,
};

HeaderArea.propTypes = {
  children: PropTypes.node,
  imageSrc: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  loading: PropTypes.bool,
  editImageMutation: PropTypes.func,
  editTitleMenuButtons: PropTypes.node,
  showImg: PropTypes.bool,
};

export default HeaderArea;
// export default StyledHeaderArea;
