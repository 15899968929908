import {
  LOGIN,
  EDIT_MEMBER,
  READ_MEMBER_WITH_FULL_DATA,
  ACTIVATE_ACCOUNT,
  READ_MEMBER_BASIC,
} from '../queries/UserQueries';

export const createToken = async function (apolloClient, Email, Password) {
  return await apolloClient.mutate({
    mutation: LOGIN,
    variables: {
      Email,
      Password,
    },
  });
};

export const activateMember = async function (apolloClient, Email, Password) {
  return await apolloClient.mutation({
    mutation: ACTIVATE_ACCOUNT,
    variables: {
      Email,
      Password,
    },
  });
};

export const readOneMember = async function (
  apolloClient,
  memberID,
  organisationID
) {
  return await apolloClient.query({
    query: READ_MEMBER_WITH_FULL_DATA,
    variables: {
      ID: memberID,
      OrganisationID: organisationID,
    },
  });
};

export const readOneMemberBasic = async function (
  apolloClient,
  memberID,
  organisationID
) {
  if (!memberID) {
    return null;
  }
  return await apolloClient.query({
    query: READ_MEMBER_BASIC,
    variables: {
      ID: memberID,
      OrganisationID: organisationID,
    },
  });
};

export const updateMember = async function (apolloClient, newInput) {
  return await apolloClient.mutate({
    mutation: EDIT_MEMBER,
    variables: {
      Input: newInput,
    },
  });
};
