import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './TabbedItemSelector.styled';
import TabbedItem from './components/TabbedItem/TabbedItem';

const TabbedItemSelector = ({ selectedID, items, onItemChange }) => {
  const handleOnClick = useCallback(
    (itemID) => {
      onItemChange(itemID);
    },
    [onItemChange]
  );

  return (
    <Styled.Wrapper>
      {items.map((item) => (
        <TabbedItem
          key={item.value}
          item={item}
          active={selectedID === item.value}
          onClick={handleOnClick}
        />
      ))}
    </Styled.Wrapper>
  );
};

TabbedItemSelector.propTypes = {
  selectedID: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      imageURL: PropTypes.string,
    })
  ).isRequired,
  onItemChange: PropTypes.func,
};

export default TabbedItemSelector;
