import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './WaveformCanvas.styled';
import { useEffectOnlyOnce } from '../../../../../../../utility/CustomHooks';
import { useTheme } from 'styled-components';

const WaveformCanvas = ({ blob, videoRef, videoDuration }) => {
  const canvasRef = useRef();
  const analyserRef = useRef(/** @type {AnalyserNode | null} */ (null));
  const theme = useTheme();

  // base height for canvas (gone for large width so that it will scale better, rather than using smaller width which would blur on widening viewport)
  const width = 1920 * 2;
  const height = 50;

  useEffectOnlyOnce(() => {
    return () => {
      if (analyserRef.current) {
        analyserRef.current.disconnect();
      }
    };
  });
  const filterData = (audioBuffer) => {
    const rawData = audioBuffer.getChannelData(0); // We only need to work with one channel of data
    const samples = 600; // Number of samples we want to have in our final data set
    const blockSize = Math.floor(rawData.length / samples); // the number of samples in each subdivision
    const filteredData = [];
    for (let i = 0; i < samples; i++) {
      let blockStart = blockSize * i; // the location of the first sample in the block
      let sum = 0;
      for (let j = 0; j < blockSize; j++) {
        sum = sum + Math.abs(rawData[blockStart + j]); // find the sum of all the samples in the block
      }
      filteredData.push(sum / blockSize); // divide the sum by the block size to get the average
    }
    return filteredData;
  };
  const normalizeData = (filteredData) => {
    const multiplier = Math.pow(Math.max(...filteredData), -1);
    return filteredData.map((n) => n * multiplier);
  };
  const drawWaveform = useCallback(() => {
    if (blob && videoRef.current) {
      var videoStream = videoRef.current.captureStream();
      var hasAudio = false;
      videoStream.getTracks().forEach((track) => {
        if (track.kind === 'audio') {
          hasAudio = true;
        }
      });
      if (!hasAudio) return;
      // if (!sourceRef.current) {
      var audioCtx = new (window.AudioContext || window.webkitAudioContext)();
      analyserRef.current = audioCtx.createAnalyser();
      blob.arrayBuffer().then((arrayBuffer) => {
        audioCtx.decodeAudioData(arrayBuffer).then((audioBuffer) => {
          const normalizedData = normalizeData(filterData(audioBuffer));
          const padding = 8;
          const context = canvasRef.current.getContext('2d');
          context.clearRect(0, 0, width, height);
          let rectWidth = width / normalizedData.length;
          for (let i = 0; i < normalizedData.length; i++) {
            const x = rectWidth * i;
            let rectHeight = normalizedData[i] * height - padding;
            if (rectHeight < 0) {
              rectHeight = 0;
            }
            console.log(rectHeight);
            context.strokeStyle = '#000'; // what color our line is
            context.fillStyle = theme.color.primary;
            if (rectHeight >= 2) {
              context.fillRect(
                x,
                height / 2 - rectHeight / 2 + 1,
                rectWidth + 1, // add one to plug holes when resizing
                Math.max(2, rectHeight)
              );
            } else {
              context.fillRect(x, height / 2 - rectHeight / 2, rectWidth, 2);
            }
          }

          // analyserRef.current.fftSize = 2048;
          // var bufferLength = analyserRef.current.frequencyBinCount;
          // var dataArray = new Uint8Array(bufferLength);
          // analyserRef.current.getByteTimeDomainData(dataArray);
          // var context = canvasRef.current.getContext('2d');
          // context.clearRect(
          //   0,
          //   0,
          //   canvasRef.current.offsetWidth,
          //   canvasRef.current.offsetHeight
          // );
          // analyserRef.current.getByteFrequencyData(dataArray);

          // context.fillStyle = 'rgb(0, 255, 0)';
          // // context.fillRect(0, 0, width, height);
          // console.log(analyserRef.current.frequencyBinCount);
          // var barWidth = (width / bufferLength) * 2.5;
          // var barHeight;
          // var x = 0;
          // for (var i = 0; i < bufferLength; i++) {
          //   barHeight = dataArray[i] / 2;
          //   console.log(dataArray[i]);

          //   context.fillStyle = 'rgb(' + (barHeight + 100) + ',50,50)';
          //   context.fillRect(x, height - barHeight / 2, barWidth, barHeight);

          //   x += barWidth + 1;
          // }
        });
      });
      // analyser.connect(distortion);
      // distortion.connect(audioCtx.destination);
    }
  }, [videoRef, blob, width, height, theme]);

  useEffect(() => {
    drawWaveform();
  }, [videoRef, blob, drawWaveform, videoDuration]);
  return (
    <>
      <Styled.Canvas width={width} height={height} ref={canvasRef} />
      {/* <button
        style={{ position: 'fixed', right: '2rem', bottom: '2rem' }}
        onClick={drawWaveform}
      >
        Draw
      </button> */}
    </>
  );
};

WaveformCanvas.propTypes = {
  blob: PropTypes.object,
  videoDuration: PropTypes.number.isRequired,
  videoRef: PropTypes.object.isRequired,
};

export default WaveformCanvas;
