import { Image, Tooltip, Card } from '@virtidev/toolbox';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import Icon from '../../../../../components/icons/Icon';
import LinkWithPrevState from '../../../../../components/LinkWithPrevState';
import Button from '../form/Button';

export const StyledCard = styled(Card).attrs({ noPadding: true })`
  position: relative;
  .body {
    gap: 0;
  }
`;

const aspectRatio = `16 / 9`;

export const StyledImageLink = styled(LinkWithPrevState)`
  ${({ theme: { color }, disabled }) => css`
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: var(--primary-border);
    aspect-ratio: ${aspectRatio};
    background: ${color.midnight};

    &:hover {
      opacity: var(--image-link-opacity-hover);
    }
    &:active {
      opacity: var(--image-link-opacity-active);
    }
    ${disabled
      ? css`
          pointer-events: none;
        `
      : null};
  `}
`;

export const StyledTitleWrapper = styled.h3`
  ${({ theme: { spacing, fontSize, fontWeight } }) => css`
    padding: ${spacing.md} ${spacing.lg};
    font-size: ${fontSize.lg};
    font-weight: ${fontWeight.partBold};
    border-bottom: var(--primary-border);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden !important;
    max-width: 100%;
  `}
`;

export const StyledLinksWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  > a:nth-child(1) {
    border-radius: 0 0 0 var(--card-border-radius);
  }
  > a:nth-child(2) {
    border-radius: 0 0 var(--card-border-radius) 0;
  }
`;

export const StyledLastEdited = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.sm};
    display: flex;
    align-items: center;
    gap: ${theme.spacing.sm};
    color: #626262;
    padding: 0.4rem 0.7rem 0.3rem 0.7rem;
    position: absolute;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.81);
    border-radius: 10px 0 0 0;
    font-style: italic;
    @media (max-width: 1700px) {
      text-align: left;
      max-width: 100%;
    }
  `}
`;

export const FixedButton = styled(Button)`
  text-align: center;
  width: 128px;
  height: 32px;
  border-radius: 22px;
  display: flex;
`;

export const StyledButtonsAreaWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const StyledButtonsWrapper = styled.div`
  ${({ theme: { spacing } }) => css`
    display: flex;
    justify-content: flex-end;
    gap: ${spacing.sm};
    padding: ${spacing.md};
  `}
`;

const StyledCornerIcons = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.sm};
    position: absolute;
    top: 0;
    padding: 0.5rem 0.6rem;
    border-radius: 20px;
    z-index: 1001;
    display: flex;
    gap: 0.5rem;
    pointer-events: none;
    > * {
      pointer-events: all;
    }
    max-width: 100%;
    display: flex;
  `}
`;

export const StyledLeftCornerIcons = styled(StyledCornerIcons)`
  left: 0;
`;

export const StyledRightCornerIcons = styled(StyledCornerIcons)`
  right: 0;
`;

export const CornerIconWrapper = styled.span`
  border-radius: 60px;
  padding: 0.5rem;
`;

export const ReStyledLinkWithPrevState = styled(LinkWithPrevState)`
  color: ${({ blank }) =>
    blank ? 'var(--primary-font-color-light)' : 'var(--primary-font-color)'};
  text-decoration: none;
  display: block;
  width: 100%;
  line-height: 1.8rem;
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: default;
    `}
  word-break: break-word;
  overflow-wrap: anywhere;
`;

export const ReStyledIcon = styled(Icon)`
  box-shadow: var(--card-box-shadow);
`;

export const StyledCardLinkTitleWrapper = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  height: 3.5rem;
  width: 100%;
`;

export const StyledCardLinkTitle = styled.span`
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledCardLinkAdminTitle = styled(StyledCardLinkTitle)`
  ${({ theme: { color, fontSize } }) => css`
    color: ${color.midnight10};
    font-size: ${fontSize.md};
  `}
`;

export const StyledCardImage = styled(Image)`
  background: #fff;
  aspect-ratio: ${aspectRatio};
`;

export const StyledMenuHeader = styled.div`
  font-weight: 500;
`;

export const UnpublishedBox = styled.div`
  padding: 0.3rem 0;
`;

export const ReStyledLink = styled(Link)`
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0.3rem 0;

  :hover {
    text-decoration: underline;
  }
`;

export const IconContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const IconText = styled.div`
  ${({ theme: { fontSize } }) => css`
    flex: 1;
    font-size: ${fontSize.sm};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: start;
  `}
`;

export const CardTooltip = styled(Tooltip).attrs(({ autoClose }) => ({
  type: 'menu',
  iconType: 'solid',
  placement: 'bottom',
  autoClose: autoClose == null ? true : false,
}))`
  ${({ theme: { color, spacing }, $unpublished }) => css`
    height: 2.5rem;
    min-width: 2.5rem;
    color: ${$unpublished ? color.midnight20 : color.turquoise};
    border-radius: 2.5rem;
    padding: 0 0.5rem;
    background: ${transparentize(0.1, color.blue50)};

    .content {
      padding: ${spacing.md} 0;
    }

    &:hover,
    &:focus {
      filter: brightness(85%);
    }
  `}
`;
