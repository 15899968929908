import { useEffect, useState, useCallback, forwardRef } from 'react';
import { format, parseISO } from 'date-fns';
import { DatepickerWrapper, StyledDatepicker } from './Datepicker.styled';
import { useController } from 'react-hook-form';

export const Datepicker = forwardRef(
  ({ name, onChange, placeholder, ...props }, ref) => {
    const [selected, setSelected] = useState(null);
    const {
      field: { value, onChange: onFormChange },
    } = useController({ name });

    useEffect(() => {
      if (!value) {
        setSelected(null);
        return;
      }
      const date = parseISO(value);

      // disable this when time is supported
      date.setHours(0, 0, 0, 0);

      setSelected(date);
    }, [value]);

    const handleChange = useCallback(
      (newDate) => {
        const value = newDate ? format(newDate, 'yyyy-MM-dd') : null;
        onChange(value);
        onFormChange(value);
        setSelected(newDate);
      },
      [onChange, onFormChange]
    );

    return (
      <DatepickerWrapper>
        <StyledDatepicker
          ref={ref}
          {...props}
          name={name}
          placeholderText={placeholder}
          selected={selected}
          onChange={handleChange}
          isClearable
          fixedHeight
        />
      </DatepickerWrapper>
    );
  }
);

Datepicker.defaultProps = {
  dateFormat: 'MMMM d, yyyy',
  onChange: () => null,
  placeholder: null,
};

export default Datepicker;
