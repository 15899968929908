import { useContext } from 'react';
import { createPortal } from 'react-dom';
import { PagePartContext } from '../../PagePart.state';
import { ActionbarContainer } from './Actionbar.styled';

export const Actionbar = ({ children }) => {
  const { getPart } = useContext(PagePartContext);
  const container = getPart('action');

  if (!container || !children) {
    return null;
  }

  return createPortal(
    <ActionbarContainer>{children}</ActionbarContainer>,
    container
  );
};
