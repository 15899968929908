import axios from 'axios';
import { GetAIToken } from '../LoginUtility';
import { getVirtualHumanAPI } from '../VirtualHumanUtility';

const VHQuery = (
  endpoint,
  method = 'GET',
  body = undefined,
  vhAPIEndpointOverride = undefined
) => {
  if (endpoint.indexOf('/') === 0) {
    endpoint = endpoint.substring(1);
  }
  return axios({
    method,
    data: body,
    url: (vhAPIEndpointOverride ?? getVirtualHumanAPI()) + '/api/' + endpoint,
    headers: {
      Authorization: 'Bearer ' + GetAIToken(),
    },
  });
};

export default VHQuery;
