import React, { useMemo, FC } from 'react';
import _ from 'lodash';
import {
  StyledPageContentInner,
  StyledTopArea,
} from '../../../../../../../components/StyledPage';
import { useQuery } from '@apollo/client';
import { GET_VIDEO_WITH_SUBTITLES } from './videoSubtitles.query';
import { useParams } from 'react-router-dom';
import LoadingPageIndicator from '../../../../../../../components/LoadingPageIndicator';
import PageLoadError from '../../../../../../../components/PageLoadError';
import { withPageTemplate } from '../../../../templates';
import StyledPageHeader from '../../../../../../../styled-components/StyledPageHeader';
import { withMediaAccess } from '../../../../helpers/permissions';
import SubtitlesEditor from '../../../../components/Videos/SubtitlesEditor/SubtitlesEditor';
import { Titlebar } from '../../../../components/Page';

/**
 * @type {FC<{}>}
 */
const VideoSubtitlesPage = () => {
  /** @type {{ videoID: string, subtitleID: string }} */
  const { videoID, subtitleID } = useParams();

  const { data, loading, error } = useQuery(GET_VIDEO_WITH_SUBTITLES, {
    variables: { ID: videoID, SubtitleID: subtitleID },
  });

  const video = useMemo(() => data?.readOneVideoMedia, [data]);

  const subtitle = useMemo(() => {
    if (!video) {
      return null;
    }
    const item = video.Subtitles.nodes.find(({ ID }) => subtitleID);

    return item || null;
  }, [video, subtitleID]);

  return (
    <>
      <Titlebar>
        {loading && <LoadingPageIndicator />}
        {error && <PageLoadError graphQLErrorObj={error} />}
        <StyledPageHeader>Subtitles Editor</StyledPageHeader>
      </Titlebar>
      {!loading && !error && video && subtitle && (
        <SubtitlesEditor video={video} subtitle={subtitle} />
      )}
    </>
  );
};

export default _.flowRight(
  withMediaAccess,
  withPageTemplate({ pageKey: 'video-subtitles' })
)(VideoSubtitlesPage);
