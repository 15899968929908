import styled, { css } from 'styled-components';

export const StyledSubtitleItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 9fr 3fr 1fr 1fr;
  gap: ${({ theme }) => theme.spacing.md};
  width: 100%;
  place-items: center start;
  gap: 1rem;
  padding: 1rem 1rem;
  border: 2px solid #ccc;
  border-radius: 11px;
  margin: 10px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const StyledFileTitle = styled.a`
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 3px 0px;
`;
