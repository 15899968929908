import React from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components';
import NoSearchResults from './NoSearchResults';
import {
  StyledPageList,
  StyledPageListHeaders,
  StyledPageListHeader,
  StyledPageListItem,
  StyledDetailsWrapper,
  StyledData,
} from '../styled-components/StyledPageList';
import UserListNameSection from './UserListNameSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Datetime, Button } from '@virtidev/toolbox';

const StyledHeaders = styled(StyledPageListHeaders)`
  grid-template-columns: 60% 20% 20%;
  padding-left: 2rem;
  padding-right: 2rem;
`;
const StyledUser = styled(StyledPageListItem)`
  margin-bottom: 7px;
  .body {
    grid-template-columns: 60% 20% 20%;
    padding: 0 2rem;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
const StyledNoSearchResults = styled(NoSearchResults)`
  text-align: center;
`;

const StyledDeleteUserBtn = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: var(--primary-color);
  &:hover {
    color: var(--primary-color-light);
  }
`;

const GroupUser = (props) => {
  const { userNode } = props;
  return (
    <StyledUser>
      <StyledDetailsWrapper align="left">
        <UserListNameSection userNode={userNode} />
      </StyledDetailsWrapper>
      <StyledData bold={true}>
        <div>
          <Datetime
            datetime={userNode.LastSeen}
            long
            noTimeDisplay
            emptyDisplay="-"
          />
        </div>
      </StyledData>
      <StyledData bold={true}>
        <Button
          color="error"
          design="text"
          icon="cross"
          title={`Remove ${userNode.Name || '(No name)'}`}
          size="small"
          onClick={() => props.removeUser(userNode)}
        />
      </StyledData>
    </StyledUser>
  );
};

GroupUser.propTypes = {
  userNode: PropTypes.object.isRequired,
  removeUser: PropTypes.func.isRequired,
};

const GroupUsersList = (props) => {
  return (
    <StyledPageList className={props.className}>
      <StyledHeaders>
        <StyledPageListHeader align="left">Name</StyledPageListHeader>
        <StyledPageListHeader>Last Seen</StyledPageListHeader>
        <StyledPageListHeader></StyledPageListHeader>
      </StyledHeaders>
      {(props.userEdges || []).map((userEdge) => (
        <GroupUser
          key={userEdge.node.ID}
          userNode={userEdge.node}
          removeUser={(id) => props.removeUser(id)}
        />
      ))}
      {!props?.userEdges?.length && (
        <StyledNoSearchResults>No users yet added.</StyledNoSearchResults>
      )}
    </StyledPageList>
  );
};

GroupUsersList.propTypes = {
  userEdges: PropTypes.array.isRequired,
  removeUser: PropTypes.func.isRequired,
};

export default GroupUsersList;
