import useStateUrl from './useStateUrl';

export const useSortUrl = (sortOptions) => {
  const {
    value: sort,
    updateValue,
    reset: resetSort,
  } = useStateUrl({
    key: 'sort',
  });
  const updateSort = (sortField, sortDirection) => {
    // Optionally pass <sortField>:<sortDirection> string format with single argument
    if (
      sortDirection === undefined &&
      sortOptions.map(({ value }) => value).includes(sortField)
    ) {
      updateValue(sortField);
    } else if (sortField && sortDirection) {
      const sortValue = `${sortField}:${sortDirection}`;
      if (sortOptions.map(({ value }) => value).includes(sortValue)) {
        updateValue(sortValue);
      }
    }
  };
  if (sortOptions.map(({ value }) => value).includes(sort)) {
    const [sortField, sortDirection] = sort.split(':');
    return { sortField, sortDirection, updateSort, resetSort };
  }
  const [defaultSortField, defaultSortDirection] =
    sortOptions[0].value.split(':');
  return {
    sortField: defaultSortField,
    sortDirection: defaultSortDirection,
    updateSort,
    resetSort,
  };
};

export default useSortUrl;
