import { useMutation } from '@apollo/client';
import { useMemo, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import NavigationPromptManual from '../../../../../components/NavigationPromptManual';
import useUser from '../../helpers/useUser';
import Button from '../form/Button';
import FieldContainer from '../form/FieldContainer';
import Label from '../form/Label';
import { CREATE_COURSE } from './courseForm.query';
import {
  ButtonContainer,
  CreateContainer,
  LabelTooltipWrapper,
} from './CourseForm.styled';
import { TextInput, Tooltip } from '@virtidev/toolbox';

export const CreateCourseForm = ({ onCreate, ...props }) => {
  const { OrganisationID } = useUser();
  const formMethods = useForm({
    defaultValues: {
      Title: '',
      AdminTitle: '',
      Description: '',
    },
  });
  const {
    register,
    reset,
    handleSubmit: submit,
    formState,
    setFocus,
  } = formMethods;

  const [createCourse, { loading }] = useMutation(CREATE_COURSE);

  const handleSubmit = useMemo(
    () =>
      submit(async (Input) => {
        const { data } = await createCourse({
          variables: {
            Input: {
              ...Input,
              OrganisationID,
            },
          },
        });

        onCreate(data.createCourse);

        reset({
          Title: '',
          AdminTitle: '',
          Description: '',
        });
      }),
    [submit, reset, onCreate, createCourse, OrganisationID]
  );

  useEffect(() => {
    setFocus('Title');
  }, [setFocus]);

  return (
    <FormProvider {...props} {...formMethods}>
      <NavigationPromptManual
        when={formState.isDirty}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <CreateContainer onSubmit={handleSubmit}>
        <TextInput
          id="course-title"
          label="Course title"
          {...register('Title')}
        />
        <TextInput
          id="course-admin-title"
          label="Admin Title"
          suffix={
            <Tooltip icon="help">
              This title is shown only to admins for identification purposes in
              the admin area. It is not displayed to learners.
            </Tooltip>
          }
          {...register('AdminTitle')}
        />
        <TextInput
          id="description"
          label="Description"
          multiline
          {...register('Description')}
        />
        <ButtonContainer>
          <Button disabled={loading} loading={loading} type="submit">
            Create Course
          </Button>
        </ButtonContainer>
      </CreateContainer>
    </FormProvider>
  );
};

CreateCourseForm.propTypes = {
  onCreate: PropTypes.func,
  onComplete: PropTypes.func,
};

export default CreateCourseForm;
