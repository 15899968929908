import { useApolloClient } from '@apollo/client';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import StyledPage from '../components/StyledPage';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import VirtualHumanManager from '../apps/core/src/components/VirtualHumanManager/VirtualHumanManager';
import useStateUrl from '../apps/core/src/helpers/useStateUrl';
import { useDeepLink } from '../apps/core/src/components/DeepLink/hooks/useDeepLink';

const StyledVHIframe = styled.iframe`
  height: 100%;
`;

export const MyCourseUnitVirtualHumanPage = () => {
  const history = useHistory();
  const client = useApolloClient();
  const { pathname } = useLocation();

  let { courseID, virtualHumanID } = useParams();

  const { value: unitID } = useStateUrl({ key: 'unitID' });

  const query = useMemo(() => {
    const search = new URLSearchParams({
      unitID,
      vhID: virtualHumanID,
      courseID,
      content: true,
    });

    return search.toString();
  }, [virtualHumanID, courseID, unitID]);

  useDeepLink(`${pathname}?${query}`);

  // when browsing to this page rather than refreshing, virtualHumanID useParams doesn't work
  if (virtualHumanID.indexOf('?') > -1) {
    virtualHumanID = virtualHumanID.match(/(\d+)(?=\?)/g)[0];
  }
  const onUnitComplete = useCallback(
    (unitURL) => {
      client.cache.evict({ id: `Course:${courseID}` });
      if (unitURL) {
        history.push(unitURL);
      } else {
        history.push(`/my-courses/${courseID}`);
      }
    },
    [history, courseID, client]
  );

  return (
    <StyledPage pageKey="pd-web-player" stretchFullHeight={true}>
      <VirtualHumanManager
        onComplete={onUnitComplete}
        virtualHumanId={virtualHumanID}
        unitID={unitID}
      />
    </StyledPage>
  );
};

export default MyCourseUnitVirtualHumanPage;
