import { Link, Modal } from '@virtidev/toolbox';

export const VHWebPlayerPagePublicRedirect = () => {
  return (
    <Modal show title="Content no longer available">
      <p>
        We've recently changed how content is shared. You'll need to request a
        new link from an organisation administrator. Please contact them
        directly to receive a new link.
      </p>
      <p>
        If you have any further questions, email{' '}
        <Link to="mailto:support@virti.com">support@virti.com</Link>.
      </p>
      <p>
        You can also <Link to="/">log in to our Application</Link>.
      </p>
    </Modal>
  );
};

export default VHWebPlayerPagePublicRedirect;
