import { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { PagePartContext } from './PagePart.state';

export const PagePartHolder = ({ outlet, Component, ...props }) => {
  const { setPart } = useContext(PagePartContext);

  const saveRef = useCallback(
    (item) => {
      setPart(outlet, item);
    },
    [setPart, outlet]
  );

  return <Component {...props} ref={saveRef} />;
};

PagePartHolder.propTypes = {
  Component: PropTypes.elementType,
};

PagePartHolder.defaultProps = {
  Component: 'div',
};
