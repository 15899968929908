import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: end;
`;
export const ToggleWrapper = styled.div`
  margin-top: 40px;
  width: 250px;
`;

export const FormTitle = styled.h1`
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: -20px;
`;
