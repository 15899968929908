import { gql } from '@apollo/client';

export const READ_VIRTUAL_HUMANS_FOR_SELECT = gql`
  query readVirtualHumans($filter: String, $OrganisationID: ID) {
    readVirtualHumans(
      filter: {
        Title: { contains: $filter }
        Organisation: { ID: { eq: $OrganisationID } }
      }
    ) {
      edges {
        node {
          ID
          Title
        }
      }
    }
  }
`;
