import { FC } from 'react';
import FlashMessageHolder from '@base/components/FlashMessageHolder';
import NavBar from '@base/components/NavBar';
import { SavebarHolder } from '@core/components/Page/components/Savebar';
import { TitlebarHolder } from '@core/components/Page/components/Titlebar';
import { BannerHolder } from '@core/components/Page/components/Banner';
import { ActionbarHolder } from '@core/components/Page/components/Actionbar';
import { withPagePartContainer } from '@core/components/Page/PagePart.state';
import {
  PageBody,
  PageContainer,
  PageContent,
  PageGlobalStyle,
} from './Page.styled';
import OnboardingDialogue from '@base/components/OnboardingDialogue';
import Sidebar from '@core/components/Sidebar/Sidebar';
import PageScrollContext from '@core/components/Page/context/PageScroll.context';
import usePageTemplateScroll from '@core/components/Page/helpers/usePageTemplateScroll';

/**
 * @type {FC<{
 *    pageKey?: string;
 *    $noContentPadding?: boolean;
 * }>}
 */
export const StandardPage = (props) => {
  const { scrolled, setContentRef, pageScrollContext } =
    usePageTemplateScroll();

  return (
    <PageContainer {...props}>
      <PageGlobalStyle />
      <Sidebar />
      <PageBody $restricted>
        <PageContent
          $noContentPadding={props.$noContentPadding}
          ref={setContentRef}
        >
          <BannerHolder />
          <OnboardingDialogue />
          <TitlebarHolder />
          <NavBar pageKey={props.pageKey} scrollGap={scrolled} />
          <FlashMessageHolder />

          <ActionbarHolder />
          <PageScrollContext.Provider value={pageScrollContext}>
            {props.children}
          </PageScrollContext.Provider>
        </PageContent>
        <SavebarHolder />
      </PageBody>
    </PageContainer>
  );
};

StandardPage.defaultProps = {};

StandardPage.propTypes = {};

export default withPagePartContainer(StandardPage);
