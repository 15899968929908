import { setLastCompletedOnboardingTaskVar } from '../ApolloReactiveVars';

export const useOnboardingProgress = (parsedKeys) => {
  if (!parsedKeys) {
    return;
  }

  const onboardingDataObject = [
    {
      Title: 'User Setup',

      Tasks: [
        {
          Title: 'Create an Account',
          Key: 'initial_details',
          Link: '/profile',
          Description:
            "If you're here, good job – this step is already complete!",
          Completed: parsedKeys.initial_details,
          Time: '5 minutes',
        },
        {
          Title: 'Add Profile',
          Key: 'upload_profile',
          Link: '/profile',
          Description:
            'Personalize your account details. Change your name or add a photo. This is also where you can change your password, if necessary.',
          Completed: parsedKeys.upload_profile,
          Time: '3 minutes',
        },
        {
          Title: 'Add a User',
          Key: 'upload_users',
          Link: '/users',
          Description: 'Add a new user to your organization.',
          Completed: parsedKeys.upload_users,
          Time: '3 minutes',
        },
      ],
    },
    {
      Title: 'Upload a Video',

      Tasks: [
        {
          Title: 'Upload a Video File',
          Key: 'upload_video',
          Link: '/media',
          Description:
            'Use this uploader to upload one or more video files to your organization. Once the file has been uploaded, it will be transcoded (converted into several files with different sizes and qualities). When this is complete, you can start using your videos to create simulations!',
          Completed: parsedKeys.upload_video,
          Time: '5 minutes',
          Video:
            'https://virti-onboarding.s3.eu-west-2.amazonaws.com/HelpVid_MediaUploading.mp4',
        },
      ],
    },

    {
      Title: 'Create a Simulation',

      Tasks: [
        {
          Title: 'Create a Simulation',
          Key: 'create_simulation',
          Link: '/simulations/new',
          Description:
            'To create a new simulation, simply select or upload a video. After this is complete, the real creation begins when you start editing details and adding interactions.',
          Completed: parsedKeys.create_simulation,
          Time: '5 minutes',
          Video:
            'https://virti-onboarding.s3.eu-west-2.amazonaws.com/Creating+a+Sim.mp4',
        },
        {
          Title: 'Edit Simulation Details',
          Key: 'edit_simulation',
          Link: '/simulations',
          Description:
            'There are several ways to edit your simulation details to tailor it to the needs of your learners. Watch our video and follow the tour to discover all the ways you can do so.',
          Completed: parsedKeys.edit_simulation,
          Time: '5 minutes',
        },
        {
          Title: 'Add a Question',
          Key: 'add_simulation_question',
          Link: '/simulations',
          Description:
            'Watch the video to learn about Questions, and then, just like with Infoboxes, try to add one to your simulation.',
          Completed: parsedKeys.add_simulation_question,
          Video:
            'https://virti-onboarding.s3.eu-west-2.amazonaws.com/Questions.mp4',
        },
        {
          Title: 'Add a Hotspot',
          Key: 'add_simulation_hotspot',
          Link: '/simulations',
          Description:
            'Watch the video to learn about Hotspots, then try adding one to your own simulation.',
          Completed: parsedKeys.add_simulation_hotspot,
          Video:
            'https://virti-onboarding.s3.eu-west-2.amazonaws.com/Hotspots_Guide.mp4',
        },
        {
          Title: 'Add an Infobox',
          Key: 'add_simulation_infobox',
          Link: '/simulations',
          Description:
            'Watch the video to learn about Infoboxes, then try adding one to your own simulation.',
          Completed: parsedKeys.add_simulation_infobox,
          Video:
            'https://virti-onboarding.s3.eu-west-2.amazonaws.com/Infoboxes.mp4',
        },
      ],
    },
    {
      Title: 'Add a Course',

      Tasks: [
        {
          Title: 'Add Course',
          Key: 'add_course',
          Link: '/courses',
          Completed: parsedKeys.add_course,
          Description:
            'Courses are a way to organize your simulations. They can help you group simulations together, and most importantly, keep track of data and progress from specific user groups over specific time periods.',
          Time: '5 minutes',
          Video:
            'https://virti-onboarding.s3.eu-west-2.amazonaws.com/Courses+Full+Runthrough.mp4',
        },
        {
          Title: 'Add Module',
          Key: 'add_course_module',
          Link: '/courses',
          Completed: parsedKeys.add_course_module,
          Description:
            'Courses are divided into modules, which in turn contain units. Create a module first, then start to fill it with units.',
          Time: '3 minutes',
          Video:
            'https://virti-onboarding.s3.eu-west-2.amazonaws.com/Onboarding_Modules.mp4',
        },
        {
          Title: 'Add Unit',
          Key: 'add_course_unit',
          Link: '/courses',
          Completed: parsedKeys.add_course_unit,
          Description:
            'Units are simulations added to a module. These units can be given a passing grade required for users to advance. Analytics are also available at the unit level to separate data for simulations used in multiple courses',
          Time: '3 minutes',
        },
      ],
    },

    {
      Title: 'Create a Form',

      Tasks: [
        {
          Title: 'Create New Form',
          Key: 'create_form',
          Link: '/feedback-forms',
          Completed: parsedKeys.create_form,
          Description:
            'Forms are a great tool to collect feedback from users. Forms can be deployed as a link accessible to anyone, or in select places within the Virti app.',
          Time: '2 minutes',
          Video:
            'https://virti-onboarding.s3.eu-west-2.amazonaws.com/FormsTool_Guide.mp4',
        },
        // {
        //   Title: 'Add a Question',
        //   Key: 'create_form_question',
        //   Link: '/feedback-forms',
        //   Completed: parsedKeys.create_form_question,
        //   Description:
        //     'Multiple question types exist within forms. Familiarize yourself with them to collect the best data from users.',
        //   Time: '2 minutes',
        // },
        {
          Title: 'Save Form Changes',
          Key: 'save_form_changes',
          Link: '/feedback-forms',
          Completed: parsedKeys.save_form_changes,
          Description:
            'Try editing the form—by adding a question, for example—and then saving the changes.',
          Time: '5 minutes',
        },
      ],
    },
  ];

  // loop through each section, then loop through each task, for each complete task, add to count
  const data = onboardingDataObject.map((section) => {
    const progress = section.Tasks.reduce((acc, task) => {
      if (task.Completed) {
        acc++;
      }
      return acc;
    }, 0);

    section.Progress = (progress / section.Tasks.length) * 100;
    return section;
  });

  return data;
};

export const useResetOnboardingProgress = (onboardingDismissed) => {
  const progress = {
    initial_details: true,
    upload_profile: false,
    upload_users: false,
    add_course: false,
    add_course_module: false,
    add_course_unit: false,
    create_form: false,
    // create_form_question: false,
    upload_video: false,
    create_simulation: false,
    edit_simulation: false,
    add_simulation_infobox: false,
    add_simulation_hotspot: false,
    add_simulation_question: false,
    save_form_changes: false,
    // add_simulation_interaction: false,
  };

  const data = {
    // adding these data so the old onboarding process doesn't break
    ...useOldOnboardDataTemplate(),

    // current onboarding data needed
    createdCourseID: '',
    createdSimID: '',
    redirectedToOnboarding: false,
    onboardingDismissed,
    progress,
  };

  return data;
};

// this is not used atm but in case there are any issues and we need to revert to the old onboarding objects, it can stay for a bit
export const useOldOnboardDataTemplate = () => {
  const data = {
    stagesComplete: ['organization'],
    onboardClosed: false,
    seenIntroVideo: true,
  };
  return data;
};

// this is used in onboarding section components
// to make a task locked, a field needs to added to the OnboardProgress JSON that contains the ID of the item the task is dependent on
// then it needs to be added here
export const useLockedTask = (key, data) => {
  switch (key) {
    case 'add_course_module':
      if (!data.createdCourseID) {
        return true;
      }
      break;
    case 'add_course_unit':
      if (!data.createdCourseID) {
        return true;
      }
      break;
    case 'edit_simulation':
    case 'add_simulation_infobox':
    case 'add_simulation_hotspot':
    case 'add_simulation_question':
      if (!data.createdSimID) {
        return true;
      }
      break;
    case 'create_form_question':
      if (!data.createdFormID) {
        return true;
      }
      break;
    case 'save_form_changes':
      if (!data.createdFormID) {
        return true;
      }
      break;
    default:
      return false;
  }
};

export const useTaskLink = (task, data) => {
  if (!task?.Key) {
    return;
  }
  switch (task.Key) {
    case 'add_course_module':
    case 'add_course_unit':
      return `${task.Link}/${data.createdCourseID}`;

    case 'edit_simulation':
      return `${task.Link}/${data.createdSimID}/`;

    case 'add_simulation_infobox':
    case 'add_simulation_hotspot':
    case 'add_simulation_question':
      return `${task.Link}/${data.createdSimID}/interactions`;

    case 'create_form_question':
      return `${task.Link}/${data.createdFormID}/`;
    case 'save_form_changes':
      return `${task.Link}/${data.createdFormID}/`;
    default:
      return task.Link;
  }
};

export const useNextStepLink = (progress, onboardingData) => {
  if (!progress) {
    return;
  }
  const unfinished = Object.keys(progress).find(
    (key) => progress[key] === false
  );

  let taskWithLink = null;

  onboardingData?.forEach((section) => {
    section.Tasks.forEach((task) => {
      if (task.Key === unfinished) {
        taskWithLink = task;
      }
    });
  }, '');

  return taskWithLink;
};

// used to display what the last completed step was in the display message
export const setLastStep = (key, onboardingData) => {
  let lastStep = null;

  onboardingData?.forEach((section) => {
    section.Tasks.forEach((task) => {
      if (task.Key === key) {
        lastStep = task;
      }
    });
  }, '');

  setLastCompletedOnboardingTaskVar(lastStep);
};
