import _ from 'lodash';
import {
  S_td,
  S_th,
} from '../../../../../../../../../styled-components/StyledTable';
import styled from 'styled-components';
import { Button, ToggleSwitch } from '@virtidev/toolbox';

export const StyledWrapper = styled.div`
  margin-bottom: 2rem;
`;

export const StyledAddendum = styled.span`
  text-transform: initial;
  font-size: 0.6rem;
`;

export const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--primary-border-width);
  background: var(--primary-border-color);
`;

export const StyledFormSection = styled.div`
  padding: 1rem 0;
  background: white;
`;

export const StyledCheckbox = styled(ToggleSwitch)`
  justify-content: flex-end;
`;

export const StyledCheckboxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
`;

export const StyledFieldLabel = styled.div`
  display: block;
  font-size: 0.7rem;
  font-weight: 500;
  text-transform: uppercase;
`;

export const StyledCheckboxLabel = styled(StyledFieldLabel)``;

export const StyledTd = styled(S_td)`
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
`;

export const StyledTh = styled(S_th)`
  padding: 0.25rem 0.5rem;
`;

export const EditButton = styled.button`
  all: unset;
  padding: 0;
  cursor: pointer;

  &:focus-visible {
    outline: 2px solid var(--secondary-color);
  }
`;

export const ContractButton = styled(Button)`
  margin-top: 8px;
`;
