import React, { useRef, useEffect, useCallback, FC } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import withFlashMessaging from '../../HOCs/WithFlashMessaging';
import WithFormHandler from '../../HOCs/WithFormHandler';
import { EDIT_MEDIA, EDIT_VIDEO_MEDIA } from '../../queries/SimulationQueries';
import Modal from '../Modal';
import StyledTextInput from '../../styled-components/StyledTextInput';
import { MinLength } from '../../utility/FormValidations';
import { useEffectOnlyOnce, usePrevious } from '../../utility/CustomHooks';
import { StyledModalSubmitBtn } from '../../styled-components/StyledModalFormComponents';
import ValidationMessages from '../ValidationMessages';
import useFlashMessage from '../../apps/core/src/components/FlashMessage';
import { Button, TextInput } from '@virtidev/toolbox';

/**
 * @typedef {import('../../apps/core/src/models/video-media.types').VideoMedia} VideoMedia
 * @typedef {import('../../apps/core/src/models/video-media.types').Media} Media
 */

const formConfig = {
  inputs: [
    {
      dbName: 'Title',
    },
  ],
  validations: [MinLength('Title', 'Title', true, 1)],
};

/**
 * @type {FC<{
 *    hideModal: () => void,
 *    modalVisible: boolean,
 *    file: VideoMedia | Media,
 *    isDirtyForm: boolean,
 *    invalidFields: string[],
 *    resetPrevFormData: () => void,
 *    currentFormData: {
 *      Title: string,
 *    },
 *    setInitialData: (form: object) => void,
 *    setInputState: (field: string, value: string) => void,
 *    validationErrors: string[],
 * type: 'video' | 'image'
 * }>}
 */
const EditMediaTitleModalForm = ({
  modalVisible,
  isDirtyForm,
  invalidFields,
  resetPrevFormData,
  hideModal,
  file,
  currentFormData,
  setInitialData,
  setInputState,
  validationErrors,
  type,
}) => {
  const titleRef = useRef(/** @type {HTMLInputElement | null} */ (null));
  const prevVisible = usePrevious(modalVisible);
  const formDisabled = !isDirtyForm || invalidFields.length > 0;
  const { addFlashMessage } = useFlashMessage();

  const [editFile, { loading: mutating }] = useMutation(
    type === 'video' ? EDIT_VIDEO_MEDIA : EDIT_MEDIA,
    {
      onError: (err) => {
        console.error(err);
        addFlashMessage('There was an error editing the file', 'error');
      },
      onCompleted: (data) => {
        addFlashMessage(
          type === 'video' ? 'Video title updated' : 'Image title updated',
          'success'
        );
        resetPrevFormData();
        hideModal();
      },
    }
  );

  const handleSubmit = useCallback(
    (e) => {
      if (formDisabled) {
        return;
      }
      e.preventDefault();
      e.stopPropagation();

      editFile({
        variables: {
          Input: {
            ID: file.ID,
            Title: currentFormData.Title,
          },
        },
        optimisticResponse:
          type === 'video'
            ? {
                updateVideoMedia: {
                  ID: file.ID,
                  Title: currentFormData.Title,
                  __typename: 'VideoMedia',
                },
              }
            : {
                updateMedia: {
                  ID: file.ID,
                  Title: currentFormData.Title,
                  __typename: 'Media',
                },
              },
      });
    },
    [editFile, file, currentFormData, formDisabled, type]
  );

  useEffectOnlyOnce(() => {
    setInitialData({ Title: file.Title });
  });

  useEffect(() => {
    if (!prevVisible && modalVisible) {
      titleRef.current?.focus?.();
    }
  });

  return (
    <Modal
      title={type === 'video' ? 'Edit video title' : 'Edit image title'}
      visible={modalVisible}
      hideModal={hideModal}
    >
      <form onSubmit={handleSubmit}>
        <TextInput
          label={type === 'video' ? 'Video title' : 'Image title'}
          name="file-title"
          id={'file-title-' + file.ID}
          value={currentFormData.Title}
          ref={titleRef}
          onChange={(e) => setInputState('Title', e.target.value)}
          error={invalidFields.includes('Title')}
        />
        <ValidationMessages errors={validationErrors} />
        <StyledModalSubmitBtn
          type="submit"
          disabled={formDisabled}
          loading={mutating}
        >
          Save
        </StyledModalSubmitBtn>
      </form>
    </Modal>
  );
};

EditMediaTitleModalForm.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default WithFormHandler(
  withFlashMessaging(EditMediaTitleModalForm),
  formConfig
);
