import styled from 'styled-components';
import { StyledDataBlock } from '../../../../../../../styled-components/StyledDataBlocks';
import { S_td, S_tr } from '../../../../../../../styled-components/StyledTable';

export const StyledError = styled.div`
  margin-top: 3rem;
  margin-bottom: 2rem;
  text-align: center;
`;

export const SelectOrganisationWrapper = styled.div`
  display: grid;
  grid-template-columns: 20rem;
`;

export const StyledStep = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  font-size: 0.7rem;
`;

export const RestyledBlock = styled(StyledDataBlock)``;

export const StyledProgressWrapper = styled.div`
  min-width: 8rem;
`;
