import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getResizeImage } from '../apps/core/src/helpers/imageResizing/getResizeImage';
import LearnerDataCard from '@core/components/DataListCard/LearnerDataCard';

const MySimulationItem = ({ data }) => {
  const { ID, ImageMedia, FirstVideo, Content360, Title } = data;

  const thumbnailUrl = useMemo(() => {
    if (ImageMedia?.TusID) {
      return getResizeImage(ImageMedia.TusID);
    }
    return ImageMedia?.URL || FirstVideo?.nodes?.[0]?.PosterURL;
  }, [ImageMedia, FirstVideo]);

  return (
    <LearnerDataCard
      to={`/my-simulations/${ID}`}
      thumbnailUrl={thumbnailUrl}
      title={Title}
      content360={Content360 || false}
    />
  );
};

MySimulationItem.propTypes = {
  data: PropTypes.shape({
    ID: PropTypes.string,
    Title: PropTypes.string,
    Content360: PropTypes.bool,
  }),
};

export default MySimulationItem;
