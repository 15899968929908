import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Matching,
  RunValidations,
  GetErrors,
  GetInvalidFields,
  IsEmail,
  MinLength,
} from '../utility/FormValidations';
import ValidationMessages from '../components/ValidationMessages';

import {
  StyledLoggedOutPage,
  StyledForm,
  StyledTextInput,
  StyledSubmitBtn,
  StyledLogoWrapper,
  StyledLogo,
  StyledHeader,
  StyledSuccessText,
  StyledLoggedOutPageContentWrapper,
  StyledPageImage,
} from '../styled-components/StyledLoggedOutPage';

const StyledParagraph = styled.p`
  margin-bottom: 2rem;
`;

class AccountActivationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      firstname: '',
      surname: '',
      validations: [],
      invalidFields: [],
    };
  }

  validations = [
    IsEmail('email', 'Email', true),
    MinLength('password', 'Password', true, 8),
    MinLength('firstname', 'First Name', true, 1),
    MinLength('surname', 'Last Name', true, 1),
  ];

  _validate = (callback) => {
    const newValidations = RunValidations(this.validations, this.state);
    const invalidFields = GetInvalidFields(newValidations);
    this.setState(
      {
        validations: newValidations,
        invalidFields,
      },
      () => {
        if (callback) {
          const isInvalid = this.state.validations.some(
            (validation) => !validation.valid
          );
          callback(!isInvalid);
        }
      }
    );
  };

  _submitForm = (e) => {
    e.preventDefault();
    this._validate((valid) => {
      if (!valid) return;
      this.props.submitCallback(
        this.state.email,
        this.state.password,
        this.state.firstname,
        this.state.surname
      );
    });
  };

  render() {
    return (
      <StyledLoggedOutPage>
        <StyledLoggedOutPageContentWrapper>
          <StyledForm onSubmit={this._submitForm}>
            <StyledLogoWrapper>
              <StyledLogo />
            </StyledLogoWrapper>
            <StyledHeader>Activate Account</StyledHeader>

            <StyledTextInput
              invalid={this.state.invalidFields.includes('email')}
              label="Email"
              name="email"
              id="email"
              type="email"
              required={true}
              onChange={(event) => this.setState({ email: event.target.value })}
              placeholder="Email Address"
              value={this.state.email}
            />
            <StyledTextInput
              invalid={this.state.invalidFields.includes('firstname')}
              label="First Name"
              name="firstName"
              id="firstName"
              type="firstName"
              required={true}
              onChange={(event) =>
                this.setState({ firstname: event.target.value })
              }
              placeholder="First Name"
              value={this.state.FirstName}
            />
            <StyledTextInput
              invalid={this.state.invalidFields.includes('surname')}
              label="Last Name"
              name="lastName"
              id="lastName"
              type="lastName"
              required={true}
              onChange={(event) =>
                this.setState({ surname: event.target.value })
              }
              placeholder="Last Name"
              value={this.state.LastName}
            />
            <StyledTextInput
              invalid={this.state.invalidFields.includes('password')}
              label="Password"
              name="password"
              id="password"
              type="password"
              required={true}
              onChange={(event) =>
                this.setState({ password: event.target.value })
              }
              value={this.state.password}
              placeholder="Enter desired password"
              underText="Minimum 8 characters"
            />
            <ValidationMessages errors={GetErrors(this.state.validations)} />
            <StyledSubmitBtn loading={this.props.sending}>
              Activate Account
            </StyledSubmitBtn>
            {this.props.sent && (
              <StyledSuccessText>
                You have successfully activated your account, you can now login.
              </StyledSuccessText>
            )}
          </StyledForm>
        </StyledLoggedOutPageContentWrapper>
        {/* <StyledPageImage /> */}
      </StyledLoggedOutPage>
    );
  }
}

AccountActivationForm.propTypes = {
  submitCallback: PropTypes.func.isRequired,
  sending: PropTypes.bool.isRequired,
  sent: PropTypes.bool.isRequired,
};

export default AccountActivationForm;
