import { useMutation } from '@apollo/client';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFlashMessaging } from '../../../../../../HOCs/WithFlashMessaging';
import {
  UPDATE_COURSE_FEEDBACK_FORMS,
  UPDATE_SIMULATION_FEEDBACK_FORMS,
  UPDATE_VIRTUAL_HUMAN_FEEDBACK_FORMS,
} from './FeedbackForms.query';
import { SectionTitle, SectionWrapper } from './FeedbackForms.styled';
import SelectAndCreateForm from './SelectAndCreateForm/SelectAndCreateForm';

export default function FeedbackForms({
  contentId,
  feedbackFormBefore,
  feedbackFormAfter,
  type,
}) {
  const { addFlashMessage } = useFlashMessaging();
  const [beforeValue, setBeforeValue] = useState();
  const [afterValue, setAfterValue] = useState();
  const [loadingBefore, setLoadingBefore] = useState(false);
  const [loadingAfter, setLoadingAfter] = useState(false);

  const beforeForm = useMemo(() => {
    if (!feedbackFormBefore || feedbackFormBefore.ID === '0') {
      // placeholder form with ID 0 exists when no form is attache
      return null;
    }
    return { value: feedbackFormBefore.ID, label: feedbackFormBefore.Title };
  }, [feedbackFormBefore]);
  const afterForm = useMemo(() => {
    if (!feedbackFormAfter || feedbackFormAfter.ID === '0') {
      return null;
    }
    return { value: feedbackFormAfter.ID, label: feedbackFormAfter.Title };
  }, [feedbackFormAfter]);

  useEffect(() => {
    setBeforeValue(beforeForm);
    setAfterValue(afterForm);
  }, [beforeForm, afterForm]);

  const responseHandlers = {
    onCompleted: (data) => {
      setLoadingBefore(false);
      setLoadingAfter(false);
      addFlashMessage(`Updated ${type} forms successfully!`);
      // could set the forms here to be extra careful
    },
    onError: (err) => {
      setBeforeValue(beforeForm);
      setAfterValue(afterForm);
      addFlashMessage(
        `Something went wrong, the ${type} form could not be updated.`,
        'error'
      );

      console.error(err);
    },
  };

  const [updateCourse] = useMutation(UPDATE_COURSE_FEEDBACK_FORMS, {
    ...responseHandlers,
  });

  const [updateSimulation] = useMutation(UPDATE_SIMULATION_FEEDBACK_FORMS, {
    ...responseHandlers,
  });

  const [updateVirtualHuman] = useMutation(
    UPDATE_VIRTUAL_HUMAN_FEEDBACK_FORMS,
    {
      ...responseHandlers,
    }
  );

  const handleSubmit = useCallback(
    (formID, trigger) => {
      const variables = {
        Input: {
          ID: contentId,
          [trigger]: formID || 0,
        },
      };
      if (trigger === 'FeedbackFormBeforeID') {
        setLoadingBefore(true);
      } else if (trigger === 'FeedbackFormAfterID') {
        setLoadingAfter(true);
      }
      switch (type) {
        case 'course':
          updateCourse({
            variables,
          });
          break;
        case 'simulation':
          updateSimulation({
            variables,
          });
          break;
        case 'virtual human':
          updateVirtualHuman({
            variables,
          });
          break;

        default:
          break;
      }
    },
    [contentId, updateCourse, type, updateSimulation, updateVirtualHuman]
  );

  const onChange = useCallback(
    (e, trigger) => {
      switch (trigger) {
        case 'before':
          setBeforeValue(e);
          handleSubmit(e?.value, 'FeedbackFormBeforeID');

          break;
        case 'after':
          setAfterValue(e);
          handleSubmit(e?.value, 'FeedbackFormAfterID');

          break;
        default:
          break;
      }
    },
    [handleSubmit]
  );

  return (
    <SectionWrapper>
      <SelectAndCreateForm
        id="feedback-before"
        label="Feedback Form Before"
        loading={loadingBefore}
        value={beforeValue}
        handleSubmit={handleSubmit}
        setLoading={setLoadingBefore}
        onChange={(e) => onChange(e, 'before')}
        field={'FeedbackFormBeforeID'}
      />
      <SelectAndCreateForm
        id="feedback-after"
        label="Feedback Form After"
        loading={loadingAfter}
        value={afterValue}
        handleSubmit={handleSubmit}
        setLoading={setLoadingAfter}
        onChange={(e) => onChange(e, 'after')}
        field={'FeedbackFormAfterID'}
      />
    </SectionWrapper>
  );
}
