import styled, { css, DefaultTheme } from 'styled-components';
import ScorePercentage from '../../../../../../styled-components/StyledScorePercentage';
import {
  LoadingPlaceholder as BaseLoadingPlaceholder,
  Card,
} from '@virtidev/toolbox';

export const UsageContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.lg};
    padding: 2px 7px 7px;
  `}
`;

export const UsageTypeContainer = styled.div`
  ${
    /**
     * @param {{
     *    theme: DefaultTheme,
     *    $contracted?: boolean,
     * }} p
     */ ({ theme, $contracted }) => css`
      display: grid;
      grid-template-columns: repeat(2, minmax(150px, 1fr));
      gap: ${theme.spacing.lg};

      @media screen and (min-width: ${$contracted ? '1060px' : '1230px'}) {
        grid-template-columns: repeat(4, minmax(150px, 1fr));
      }
    `
  }
`;

export const UsagePanel = styled(Card)`
  ${
    /**
     * @param {{
     *    theme: DefaultTheme,
     *    $top?: boolean,
     * }} p
     */ ({ theme, $top }) => css`
      .body {
        align-items: ${$top ? 'center' : 'stretch'};
        justify-content: ${$top ? 'flex-end' : 'flex-start'};
        gap: ${theme.spacing.sm};
      }
    `
  }
`;

export const UsageActivityPanel = styled.div`
  ${
    /**
     * @param {{
     *    theme: DefaultTheme,
     *    $contracted?: boolean,
     * }} p
     */ ({ theme, $contracted }) => css`
      display: grid;
      grid-template-columns: minmax(280px, 1fr);
      gap: ${theme.spacing.lg};

      @media screen and (min-width: ${$contracted ? '1060px' : '1230px'}) {
        grid-template-columns: minmax(560px, 2fr) minmax(280px, 1fr);
      }
    `
  }
`;

export const PanelTitle = styled.div`
  ${({ theme: { fontWeight } }) => css`
    font-weight: ${fontWeight.bold};
    text-align: center;
  `}
`;

export const PanelContent = styled.div``;

export const DeletedUserText = styled.div`
  ${({ theme }) => css`
    display: inline-block;
    color: ${theme.color.lightGrey};
  `}
`;

export const ScoreText = styled(ScorePercentage)`
  display: inline-block;
`;

export const UsageList = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    gap: ${theme.spacing.sm};
    min-height: 200px;

    > div {
      &::before {
        content: '';
        display: block;
        height: 0;
        border-bottom: 1px solid ${theme.color.lightGrey};
        margin-bottom: ${theme.spacing.sm};
      }
      &:first-of-type::before {
        content: none;
      }
    }
  `}
`;

export const LoadingPlaceholder = styled(BaseLoadingPlaceholder)`
  margin: 0;
`;

export const EmptyList = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: ${theme.spacing.md};
    min-height: 200px;
  `}
`;
