import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 90%;
  gap: var(--content-padding);
  flex: 1;
  padding-bottom: var(--content-padding);
`;

export const ContentBody = styled.div`
  flex: 1;
`;

export const CardListContainer = styled.div`
  display: grid;
  // This allows up to 4 columns. Skip 5 col layout and because it's awkward spreading 12 items over 5 cols.
  grid-template-columns: repeat(
    auto-fill,
    minmax(min(100%/1, max(300px, 100%/5)), 1fr)
  );
  gap: var(--content-padding);

  @media (min-width: 2240px) {
    grid-template-columns: repeat(6, minmax(300px, 1fr));
  }

  // Avoid 7+ col layout but allow it eventually
  @media (min-width: 3000px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
`;

export const PagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const EmptyCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 540px;
  max-width: 100%;
  margin: 0 auto;
  align-self: center;
  border-radius: var(--card-border-radius);
  background-color: var(--card-bg-color);
  box-shadow: var(--card-box-shadow);
  padding: var(--content-padding);
`;
