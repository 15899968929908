import {
  ProgressLoaderWrapper,
  ProgressLoaderText,
} from '@core/components/Loaders/ProgressLoader/ProgressLoader.styled';
import { ProgressBar } from '@virtidev/toolbox';
import React from 'react';

/** @type {React.FC<{text: string; progress: number; onProgressChange?: (newProgress: number) => void}>} */
const ProgressLoader = ({ text, progress }) => {
 const validProgress = Math.max(0, Math.min(100, progress));
 return (
   <ProgressLoaderWrapper>
     <ProgressBar
       labelText={Math.round(validProgress) + '%'}
       color="turquoise"
       height={4}
       max={100}
       progress={validProgress}
     />
     {text && <ProgressLoaderText>{text}</ProgressLoaderText>}
   </ProgressLoaderWrapper>
 );
};

export default ProgressLoader;
