import { useMutation } from '@apollo/client';
import { Button, TextInput } from '@virtidev/toolbox';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useFlashMessaging } from '../../../../../../HOCs/WithFlashMessaging';

import { UPDATE_ORGANISATION } from './OrgSingleTextFieldForm.query';
import {
  StyledLabel,
  StyledWrapper,
  StyledLabelHeading,
} from './OrgSingleTextFieldForm.styled';

export default function OrgSingleTextFieldForm({
  organisation,
  disabled,
  fieldName,
  fieldLabel,
  fieldLabelSuffix = '',
  type = 'text',
  onComplete = () => {},
  ...inputProps
}) {
  const formMethods = useForm();
  const {
    register,
    reset,
    handleSubmit: submit,
    formState: { isDirty },
  } = formMethods;

  const { addFlashMessage } = useFlashMessaging();

  const [updateOrganisation, { loading }] = useMutation(UPDATE_ORGANISATION, {
    onCompleted: () => {
      addFlashMessage(`Successfully changed ${fieldLabel}.`);
      onComplete();
    },
    onError: () => addFlashMessage('Something went wrong.', 'error'),
  });

  const handleSubmit = useCallback(() => {
    const doSubmit = submit((values) => {
      let maxInt = undefined;
      if ((inputProps.max ?? false) && type === 'number') {
        const int = parseInt(inputProps.max);
        if (!isNaN(int)) {
          maxInt = int;
        }
      }

      if (maxInt !== undefined && parseInt(values[fieldName]) > maxInt) {
        addFlashMessage(`Value cannot be greater than ${maxInt}`, 'error');
        return;
      }

      let minInt = undefined;
      if ((inputProps.min ?? false) && type === 'number') {
        const int = parseInt(inputProps.min);
        if (!isNaN(int)) {
          minInt = int;
        }
      }

      if (minInt !== undefined && parseInt(values[fieldName]) < minInt) {
        addFlashMessage(`Value cannot be less than ${minInt}`, 'error');
        return;
      }
      updateOrganisation({
        variables: {
          Input: {
            ID: organisation.ID,
            [fieldName]:
              type === 'number'
                ? parseInt(values[fieldName])
                : values[fieldName],
          },
        },
      });
    });
    doSubmit();
  }, [
    submit,
    fieldName,
    updateOrganisation,
    organisation.ID,
    type,
    addFlashMessage,
    inputProps.max,
    inputProps.min,
  ]);

  const defaultValue = organisation[fieldName]
    ? `${organisation[fieldName]}`
    : '';

  useEffect(() => {
    reset({
      [fieldName]: defaultValue,
    });
  }, [reset, defaultValue, fieldName, organisation.ID]);

  return (
    <FormProvider {...formMethods}>
      <StyledLabel>
        <StyledLabelHeading>{fieldLabel}</StyledLabelHeading>
        {fieldLabelSuffix}
      </StyledLabel>

      <StyledWrapper>
        <TextInput
          disabled={disabled || loading}
          {...register(fieldName)}
          type={type}
          {...inputProps}
        />
        <Button
          disabled={disabled || loading}
          onClick={handleSubmit}
          design={isDirty ? 'outline' : 'fill'}
          color={isDirty ? 'pink' : 'blue'}
        >
          Save
        </Button>
      </StyledWrapper>
    </FormProvider>
  );
}
