import { gql } from '@apollo/client';

export const READ_TAGS = gql`
  query readContentTagsSelect($name: String, $orgId: ID) {
    readContentTags(
      filter: { Name: { contains: $name }, OrganisationID: { eq: $orgId } }
    ) {
      nodes {
        ID
        Name
      }
    }
  }
`;
