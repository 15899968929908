import styled, { css, keyframes } from 'styled-components';

const pop = keyframes`
from {
    transform: translateY(20px);
    opacity: 0;
}
to {
    transform: translateY(0px);
    opacity: 1;
}`;

export const StyledMessage = styled.div`
    display: flex;
    margin-bottom: 2rem;
    padding: 1.3rem 2.5rem;
    background: #fff;
    border-radius: var(--card-border-radius);
    box-shadow: var(--card-box-shadow);
  background: var(--primary-color);
    color: white;
    max-width: 100%;
    text-align: left;
    flex-direction: column;
`;

export const StyledSuccess = styled.div`
  color: var(--secondary-color);
    font-size: 1.3rem;
    margin-bottom: 0.3rem;
`;

export const StyledConfettiWrapper = styled.div`
  ${({ left }) => css`
    position: absolute;
    right: 0;
    top: -10px;
    ${left
      ? css`
          left: -90px;
        `
      : css`
          right: -80px;
        `};
    animation: ${pop} 0.2s forwards;
    @media (max-width: 600px) {
      display: none;
    }
  `}
`;
