import styled, { css } from 'styled-components';

export const SimulationList = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-template-rows: 1fr;
    gap: 0 ${theme.spacing.md};
    padding: 2px 7px 7px;
    grid-auto-rows: 0px;
    overflow: hidden;
  `}
`;

export const Content = styled.div`
  ${({ theme: { spacing } }) => css`
    padding: ${spacing.md} ${spacing.md} ${spacing.lg};
    display: flex;
    flex-direction: column;
    gap: ${spacing.lg};
  `}
`;
