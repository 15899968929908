import { gql } from '@apollo/client';

export const SAVE_REGISTER_VH = gql`
  mutation saveRegisterVh($input: UpdateVirtualHumanInput!) {
    updateVirtualHuman(input: $input) {
      ID
      ShowRegister
    }
  }
`;

export const SEND_EMAIL_SHARE_VH = gql`
  mutation sendEmailShareVH($vhId: ID!, $emails: [String]!) {
    emailVirtualHumanShareLink(VirtualHumanID: $vhId, Emails: $emails)
  }
`;
