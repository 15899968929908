import React, { Component } from 'react';
// import NavigationPrompt from "react-router-navigation-prompt";
import NavigationPrompt from '../components/NavigationPromptManual';
import _ from 'lodash';

import {
  RunValidations,
  GetErrors,
  GetInvalidFields,
} from '../utility/FormValidations';
import { ShallowEqual } from '../utility/EqualityChecks';

// currently deals with setting dirty form
// Implementation:
// - set up a config object with inputs and validations
// - (untested) there is a custom option for setting custom data that is set in the config (ALL REQUIRED)
//    - customInputKey - key that stores an object of different state values associated with the input e.g. 'dateofbirth'
//    - customInputFunc - func that takes the customInputsState and generates a value that the db value should be (for when something generates the final db result based on a custom method or with multiple state values)
//    - customInputInitialFunc - func that takes the object data as an argument and generates the initial state value
// - use invalidFields and validationErrors in child form to show errors and style invalid inputs
// - use this.props.currentFormData to get the db-related data for submitting the form
// - call this.props.setInitialData(formData) at the form mount with the db object
// - call this.props.setInputState instead of setting the state in the child
//    - e.g. this.props.setInputState('Name', 'bob')
//    - also has a boolean arg for whether or not you want to run state revalidations if it was previously invalid (usually true)
// - call this.props.setSubmittingForm(true/false) to keep track of mutating state
// - call this.props.triggerRevalidation() to update this.props.invalidFields and this.props.validationErrors (also accepts a callback with `valid` bool arg)

const WithFormHandler = function (
  WrappedComponent,
  config,
  deepCompare = true
) {
  return class extends Component {
    constructor(props) {
      super(props);
      // avoid React error for changing from uncontrolled to controlled by setting up default empty values for currentFormData
      const defaultEmptyCurrentFormData = config.inputs.reduce(
        (carry, inputObj) => {
          carry[inputObj.dbName] = inputObj.hasOwnProperty('initialInputVal')
            ? inputObj.initialInputVal
            : '';
          return carry;
        },
        {}
      );
      this.state = {
        prevFormData: [],
        currentFormData: defaultEmptyCurrentFormData,
        customInputsData: {},
        imgInputsData: {},
        submittingForm: false,
        invalidFields: [],
      };
      this.resetPrevFormData = this.resetPrevFormData.bind(this);
      this._setInitialData = this._setInitialData.bind(this);
      this._setSubmittingForm = this._setSubmittingForm.bind(this);
    }

    // for updating state that can then be passed
    _updateValidationState = () => {
      const newValidations = RunValidations(
        config.validations,
        this._getCurrentFormData()
      );
      const invalidFields = GetInvalidFields(newValidations);
      this.setState({
        validations: newValidations,
        invalidFields,
      });
    };

    // function rather than prop so a callback can be run and so that it's not revalidated constantly
    _validate = (callback) => {
      const newValidations = RunValidations(
        config.validations,
        this._getCurrentFormData()
      );
      const isInvalid = newValidations.some((validation) => !validation.valid);
      callback(!isInvalid);
    };

    resetPrevFormData() {
      this.setState({ prevFormData: this._getCurrentFormData() });
    }

    _setInitialData(itemData, furtherSetupCallback = null) {
      let newFormState = {};
      let newCustomInputsData = {};
      config.inputs.forEach((input) => {
        newFormState[input.dbName] = itemData[input.dbName];
        if (
          typeof input.customInputKey !== 'undefined' &&
          input.customInputKey &&
          typeof input.customInputInitialFunc !== 'undefined' &&
          input.customInputInitialFunc
        ) {
          newCustomInputsData[input.customInputKey] =
            input.customInputInitialFunc(newFormState[input.customInputKey]);
        }
      });
      // if there is further setup (e.g. setting some initial image state, run here before prev form data is set)
      if (furtherSetupCallback) furtherSetupCallback();
      this.setState({
        currentFormData: newFormState,
        customInputsData: newCustomInputsData,
        prevFormData: newFormState,
      });
    }

    _getImgSrc(imgURLField, dataObject, file, isRemoved = false) {
      if (isRemoved) return null;
      return file || (dataObject && dataObject[imgURLField]) || null;
    }

    // used with dropzone component
    // provides helper object as prop for keeping track of whether the image is removed, the url etc.
    // input name should be e.g. Avatar - it will then access the url via AvatarURL
    _setInputImgState = (imgIDField, imgURLField, dataObject, ID, file) => {
      this.setState({
        currentFormData: {
          ...this.state.currentFormData,
          [imgIDField]: ID,
        },
        imgInputsData: {
          ...this.state.imgInputsData,
          [imgIDField]: {
            ID: ID,
            src: this._getImgSrc(imgURLField, dataObject, file, ID === '0'), // if ID is "0" there is no image
            remountKey: this.state.imgInputsData.hasOwnProperty(imgIDField)
              ? this.state.imgInputsData[imgIDField].remountKey + 1
              : 2,
          },
        },
      });
    };

    _setInputState = (
      inputName,
      value,
      isCustom = false,
      revalidateIfInvalid = true,
      callback = null
    ) => {
      // TODO use produce instead
      if (isCustom) {
        this.setState(
          {
            customInputsData: {
              ...this.state.customInputsData,
              [inputName]: value,
            },
          },
          () => {
            this._conditionalRevalidate(revalidateIfInvalid);
            if (callback) {
              callback();
            }
          }
        );
      } else {
        this.setState(
          {
            currentFormData: {
              ...this.state.currentFormData,
              [inputName]: value,
            },
          },
          () => {
            this._conditionalRevalidate(revalidateIfInvalid);
            if (callback) {
              callback();
            }
          }
        );
      }
    };

    _conditionalRevalidate = (revalidate) => {
      if (revalidate) this._updateValidationState();
    };

    // _revalidateIfInvalid = () => {
    //   if (this.props.invalidFields.length > 0) {
    //     this._validate();
    //   }
    // }

    _triggerRevalidation = (callback) => {
      this._validate((isValid) => {
        this._updateValidationState();
        if (callback) {
          callback(isValid);
        }
      });
    };

    _setSubmittingForm(submittingForm) {
      this.setState({ submittingForm });
    }

    _getCurrentFormData = () => {
      // gets the form data either using the base dbName val or calls the custom function if it exists (with the custom state with custom key data) to generate what should be saved in the database
      let formData = {};
      config.inputs.forEach((input) => {
        if (
          typeof input.customInputKey !== 'undefined' &&
          input.customInputKey &&
          typeof input.customInputFunc !== 'undefined' &&
          input.customInputFunc
        ) {
          formData[input.dbName] = input.customInputFunc(
            this.state.customInputsData[input.customInputKey]
          );
        } else {
          formData[input.dbName] = this.state.currentFormData[input.dbName];
        }
      });
      return formData;
    };

    _isEqual(prevFormData, currentFormData, deepCompare = true) {
      return deepCompare
        ? _.isEqual(prevFormData, currentFormData)
        : ShallowEqual(prevFormData, currentFormData);
    }

    render() {
      const dirty = !this._isEqual(
        this.state.prevFormData,
        this._getCurrentFormData(),
        deepCompare
      );
      return (
        <React.Fragment>
          <NavigationPrompt
            when={dirty}
            message="You have unsaved changes, are you sure you want to leave?"
          />
          <WrappedComponent
            resetPrevFormData={this.resetPrevFormData}
            prevFormData={this.state.prevFormData}
            setInitialData={this._setInitialData}
            setSubmittingForm={this._setSubmittingForm}
            submittingForm={this.state.submittingForm}
            currentFormData={this.state.currentFormData}
            imgInputsData={this.state.imgInputsData}
            setInputState={this._setInputState}
            invalidFields={this.state.invalidFields}
            triggerRevalidation={this._triggerRevalidation}
            validationErrors={GetErrors(this.state.validations)}
            setInputImgState={this._setInputImgState}
            isDirtyForm={dirty}
            {...this.props}
          />
        </React.Fragment>
      );
    }
  };
};

export default WithFormHandler;
