import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';

import withUser from '../HOCs/WithUser';
import StyledPage, {
  StyledPageContentInner,
  StyledTopArea,
} from '../components/StyledPage';
import { READ_VIRTUAL_HUMANS_PAGINATED } from '../queries/VirtualHumanQueries';
import RedirectWithPrevState from '../components/RedirectWithPrevState';
import StyledTextInput from '../styled-components/StyledTextInput';
import StyledPageHeader from '../styled-components/StyledPageHeader';
import PaginatedVirtualHumansList from '../components/PaginatedVirtualHumansList';

const StyledSearch = styled(StyledTextInput)`
  margin-left: auto;
`;

class MyVirtualHumansPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFilter: '',
      typedFilter: '',
      publishStatus: '',
      activeUserGroupFilter: '',
      pagination: {
        limit: 12,
        page: 1,
      },
    };
    this._changeTypedFilter = this._changeTypedFilter.bind(this);
    this._changeDBFilter = _.debounce(this._changeDBFilter.bind(this), 500);
  }

  _changeFilter = (e) => {
    this._changeTypedFilter(e.target.value);
  };

  _changeTypedFilter = (newFilter) => {
    this.setState(
      {
        typedFilter: newFilter,
      },
      () => this._changeDBFilter(newFilter)
    );
  };

  _changeDBFilter = (newFilter) => {
    this.setState({
      activeFilter: newFilter,
      pagination: { ...this.state.pagination, page: 1 },
    });
  };

  _changeGroupFilter = (mockSelectOption) => {
    this.setState({
      activeUserGroupFilter: mockSelectOption,
      pagination: { ...this.state.pagination, page: 1 },
    });
  };

  _onPublishStatusChange = (mockSelectOption) => {
    this.setState({ publishStatus: mockSelectOption });
  };

  render() {
    if (!this.props.userID) {
      return <RedirectWithPrevState to="/login" />;
    }
    return (
      <StyledPage pageKey="virtual-humans">
        <StyledPageContentInner card={false}>
          <StyledTopArea gridCols="1fr 280px">
            <StyledPageHeader>My Virtual Humans</StyledPageHeader>
            <StyledSearch
              filterStyle
              placeholder="Search"
              value={this.state.typedFilter}
              id="filter"
              onChange={this._changeFilter}
            />
          </StyledTopArea>
          <PaginatedVirtualHumansList
            userMode="user"
            dataQuery={READ_VIRTUAL_HUMANS_PAGINATED}
            dataQueryVariables={{
              filterBy: {
                Organisation: { ID: { eq: this.props.userOrganisationID } },
                ...(this.state.activeUserGroupFilter.value
                  ? {
                      OrganisationGroup: {
                        ID: { eq: +this.state.activeUserGroupFilter.value },
                      },
                    }
                  : {}),
                Title: { contains: this.state.activeFilter ?? '' },
                Status: { eq: 'Published' },
                CoursesOnly: { eq: false },
              },
            }}
            queryName="readVirtualHumans"
            paginationState={this.state.pagination}
            changePageCallback={(newPage) =>
              this.setState({
                pagination: { ...this.state.pagination, page: newPage },
              })
            }
            userOrganisationID={this.props.userOrganisationID}
          />
        </StyledPageContentInner>
      </StyledPage>
    );
  }
}

MyVirtualHumansPage.propTypes = {
  userID: PropTypes.number,
  match: PropTypes.object.isRequired,
  userOrganisationID: PropTypes.number.isRequired,
};

export default withUser(MyVirtualHumansPage);
