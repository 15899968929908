import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';

import withUser from '../HOCs/WithUser';
import StyledPage, {
  StyledPageContentInner,
  StyledTopArea,
} from '../components/StyledPage';
import RedirectWithPrevState from '../components/RedirectWithPrevState';
import StyledTextInput from '../styled-components/StyledTextInput';
import PaginatedCoursesList from '../components/PaginatedCoursesList';
import StyledPageHeader from '../styled-components/StyledPageHeader';
import { READ_LEARNER_COURSES } from '../queries/LearnerQueries';

const MyCoursesPage = (props) => {
  const [pagination, setPagination] = useState({ limit: 12, page: 1 });
  if (!props.userID) {
    return <RedirectWithPrevState to="/login" />;
  }
  return (
    <StyledPage pageKey="courses">
      <StyledPageContentInner>
        <StyledTopArea gridCols="1fr 1fr">
          <StyledPageHeader>My Courses</StyledPageHeader>
        </StyledTopArea>
        <PaginatedCoursesList
          userMode="user"
          dataQuery={READ_LEARNER_COURSES}
          queryName="readCourses"
          paginationState={pagination}
          dataQueryVariables={{
            OrganisationID: props.userOrganisationID,
          }}
          changePageCallback={(newPage) => {
            setPagination({ ...pagination, page: newPage });
          }}
        />
      </StyledPageContentInner>
    </StyledPage>
  );
};

MyCoursesPage.propTypes = {
  userID: PropTypes.number,
  match: PropTypes.object.isRequired,
  userOrganisationID: PropTypes.number.isRequired,
};

export default withUser(MyCoursesPage);
