import { produce } from 'immer';

export const rebuildDetailsData = (data) => {
  return produce(data, (courseData) => {
    courseData.__typename = 'Course';
    courseData.ImageMedia = null;

    return courseData;
  });
};

export default rebuildDetailsData;
