import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LoadingPageIndicator from '../components/LoadingPageIndicator';
import PageLoadError from '../components/PageLoadError';
import withUserPageTemplate from '../HOCs/templates/WithUserPageTemplate';
import { StyledPageContentInner } from '../components/StyledPage';
import RedirectWithPrevState from '../components/RedirectWithPrevState';
import UserTabNav from '../components/UserTabNav';
import { MEMBER_ANALYTICS } from '../queries/UserQueries';
import {
  StyledDataBlock,
  StyledDataBlockBody,
  StyledDataBlockHeader,
  StyledDataBlockHeaderText,
  StyledDataBlocks,
  StyledDataItem,
  StyledDataLabel,
  BlockWrapper,
} from '../styled-components/StyledDataBlocks';
import TableKnowledgeGainAvg from '../components/charts/TableKnowledgeGainAvg';
import BarChartMostPopular from '../components/charts/BarChartMostPopular';
import TableStrongestWeakestSimulations from '../components/charts/TableStrongestWeakestSimulations';
import TableUserCourses from '../components/charts/TableUserCourses';
import TableUserSimulations from '../components/charts/TableUserSimulations';
import BarChartTotalViewsOverTimeHighstock from '../components/charts/BarChartTotalViewsOverTimeHighstock';
import ScorePercentage from '../styled-components/StyledScorePercentage';
import SimulationUserScores from '../components/charts/SimulationUserScores';

const UserAnalyticsPage = (props) => {
  const { loading, error, data } = useQuery(MEMBER_ANALYTICS, {
    variables: {
      UserID: props.data.readOneMember.ID,
    },
    fetchPolicy: 'cache-and-network',
  });
  const analyticsData = data?.userAnalytics;

  if (!props.userID) {
    return <RedirectWithPrevState to="/login" />;
  }

  if (loading || !analyticsData) {
    return (
      <>
        <UserTabNav userID={props.data.readOneMember.ID} />
        <StyledPageContentInner>
          <LoadingPageIndicator />
        </StyledPageContentInner>
      </>
    );
  }
  if (error) {
    return (
      <>
        <UserTabNav userID={props.data.readOneMember.ID} />
        <StyledPageContentInner>
          <PageLoadError graphQLErrorObj={error} />
        </StyledPageContentInner>
      </>
    );
  }
  return (
    <>
      <UserTabNav userID={props.data.readOneMember.ID} />
      <StyledPageContentInner>
        <BlockWrapper>
          <StyledDataBlocks>
            <StyledDataBlock>
              <StyledDataBlockHeader>
                <StyledDataBlockHeaderText>
                  Time Spent
                </StyledDataBlockHeaderText>
              </StyledDataBlockHeader>
              <StyledDataBlockBody>
                <div>
                  <StyledDataItem>
                    {analyticsData.TotalCompletions || 0}
                  </StyledDataItem>
                  <StyledDataLabel>
                    <FontAwesomeIcon icon="eye" /> Total Completed
                  </StyledDataLabel>
                </div>
                <div>
                  <StyledDataItem>
                    {analyticsData.TotalTimeWatched}
                  </StyledDataItem>
                  <StyledDataLabel>
                    <FontAwesomeIcon icon="clock" /> Total Time Watched
                  </StyledDataLabel>
                </div>
              </StyledDataBlockBody>
            </StyledDataBlock>
            <StyledDataBlock>
              <StyledDataBlockHeader>
                <StyledDataBlockHeaderText>
                  Performance
                </StyledDataBlockHeaderText>
              </StyledDataBlockHeader>
              <StyledDataBlockBody>
                <div>
                  <StyledDataItem>
                    <ScorePercentage
                      align="center"
                      score={analyticsData.AverageScore}
                    />
                  </StyledDataItem>
                  <StyledDataLabel>
                    <FontAwesomeIcon icon="star" /> Average Score
                  </StyledDataLabel>
                </div>
                <div>
                  <StyledDataItem>
                    {analyticsData.AverageReactionTime}
                  </StyledDataItem>
                  <StyledDataLabel>
                    <FontAwesomeIcon icon="clock" /> Average ReactionTime
                  </StyledDataLabel>
                </div>
              </StyledDataBlockBody>
            </StyledDataBlock>
          </StyledDataBlocks>
        </BlockWrapper>
        <BlockWrapper>
          <TableKnowledgeGainAvg
            firstCorrect={analyticsData.AverageScoreFirst}
            latestCorrect={analyticsData.AverageScoreLast}
            headerText="Knowledge gain averaged across all simulations"
          />
        </BlockWrapper>
        <BlockWrapper>
          <StyledDataBlocks>
            <BarChartTotalViewsOverTimeHighstock
              viewsDays={analyticsData.DailyTotalViewsDays}
              views={analyticsData.DailyTotalViewsViews}
            />
            <SimulationUserScores
              title={'User Score Highlights'}
              data={analyticsData}
            />
          </StyledDataBlocks>
        </BlockWrapper>
        <BlockWrapper>
          <BarChartMostPopular
            title="Most Watched Simulations By User"
            type="simulation"
            views={analyticsData.MostWatchedViews}
            titles={analyticsData.MostWatchedTitles}
          />
        </BlockWrapper>
        <BlockWrapper>
          <TableUserSimulations
            userID={props.userID}
            analyticsUserID={props.data.readOneMember.ID}
            simulationScores={analyticsData.SimulationScores}
          />
        </BlockWrapper>
        <BlockWrapper>
          <TableUserCourses courses={analyticsData.CourseScores} />
        </BlockWrapper>
      </StyledPageContentInner>
    </>
  );
};

UserAnalyticsPage.propTypes = {
  userID: PropTypes.number,
  data: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withUserPageTemplate(UserAnalyticsPage, 'user-analytics');
