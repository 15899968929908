import { useContext } from 'react';
import { createPortal } from 'react-dom';
import { PagePartContext } from '../../PagePart.state';
import { TitlebarContainer } from './Titlebar.styled';

export const Titlebar = ({ children, ...props }) => {
  const { getPart } = useContext(PagePartContext);
  const container = getPart('title');

  if (!container || !children) {
    return null;
  }

  return createPortal(
    <TitlebarContainer {...props}>{children}</TitlebarContainer>,
    container
  );
};
