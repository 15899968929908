import styled from 'styled-components';

export const MenuItemText = styled.span`
  flex: 1;
  text-align: start;
`;

export const MenuItemButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  background: none;
  border: 0;
  margin-bottom: 10px;
  :last-of-type {
    margin-bottom: 0;
  }
  &:hover {
    ${MenuItemText} {
      text-decoration: underline;
    }
  }
`;
