import React from 'react';
import CXDashboardTabNav from '../components/CXDashboardTabNav';
import CxMoveUser from '../components/CxMoveUser';
import { StyledPageContentInner } from '../components/StyledPage';
import WithCXDashboardPageTemplate from '../HOCs/templates/WithCXDashboardPageTemplate';
import { StyledHeader } from '../styled-components/StyledLoggedOutPage';
import styled from 'styled-components';

const CXUserCopyPage = () => {
  return (
    <>
      <CXDashboardTabNav />
      <CxMoveUser />
    </>
  );
};

export default WithCXDashboardPageTemplate(CXUserCopyPage);
