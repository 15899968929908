import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StyledPageContentInner } from '../../../../../../../../components/StyledPage';
import { StyledHeader } from '../../../../../../../../styled-components/StyledLoggedOutPage';
import DBSelect from '../../../../../../../../components/DBSelect';
import Button from '../../../../../../../../components/buttons/Button';
import withFlashMessaging from '../../../../../../../../HOCs/WithFlashMessaging';
import { useMutation } from '@apollo/client';
import { DUPLICATE_VH } from '../../../../../../../../apps/core/src/components/VirtualHumansList';
import useGetVHCounts from '../../../../../../../../components/VirtualHumanCreationWizard/useGetVHCounts';
import { SEARCH_ORGANISATIONS } from '../../../../../../../../apps/core/src/pages/admin/cx/cxDashboardPages.query';
import { SEARCH_VIRTUAL_HUMANS } from './cxCopyVH.query';
import usePublishStrategy from '../../../helpers/usePublishStrategy';
import StyledLabel from '../../../../../../../../styled-components/StyledLabel';
import { Select } from '@virtidev/toolbox';

const StyledInputWrapper = styled.div`
  margin-bottom: 1rem;
`;

const StyledPageContentWrapper = styled.div`
  margin-top: 2rem;
`;

const CxCopyVH = (props) => {
  const { publishStrategy, publishStrategySelectProps } = usePublishStrategy();
  const [currentOrganisationFrom, setCurrentOrganisationFrom] = useState(null);
  const [currentOrganisationFromData, setCurrentOrganisationFromData] =
    useState(null);
  const [currentVH, setCurrentVH] = useState(null);
  const [currentVHData, setCurrentVHData] = useState(null);
  const [currentOrganisationTo, setCurrentOrganisationTo] = useState(null);
  const [currentOrganisationToData, setCurrentOrganisationToData] =
    useState(null);
  const [duplicate, { loading: isLoading }] = useMutation(DUPLICATE_VH, {
    onCompleted: () => {
      props.addFlashMessage('Successfully copied');
      refetch();
    },
    onError: () => {
      props.addFlashMessage('Error retrieving vh data', 'error');
    },
  });
  const {
    canMakeFreeform,
    canMakeBranching,
    branchingTotalCount,
    freeformTotalCount,
    branchingMax,
    freeformMax,
    isLoading: loadingVHCounts,
    refetch,
  } = useGetVHCounts(
    currentOrganisationToData?.ID,
    currentOrganisationToData?.VirtualHumanAPI
  );

  const orgApiMismatch =
    currentOrganisationFromData &&
    currentOrganisationToData &&
    currentOrganisationFromData.VirtualHumanAPI !==
      currentOrganisationToData.VirtualHumanAPI;

  const targetOrgLimitHit =
    currentOrganisationTo &&
    ((!canMakeFreeform &&
      currentVHData &&
      ['default', 'medical'].includes(currentVHData?.Type)) ||
      (!canMakeBranching &&
        currentVHData &&
        currentVHData.Type === 'branching'));

  const buttonDisabled =
    !currentOrganisationFrom ||
    !currentVH ||
    !currentOrganisationTo ||
    orgApiMismatch ||
    targetOrgLimitHit;

  return (
    <StyledPageContentWrapper>
      <StyledPageContentInner>
        <StyledHeader>Copy VH to Organisation</StyledHeader>
        <StyledInputWrapper>
          <DBSelect
            dataQuery={SEARCH_ORGANISATIONS}
            filterVar="filter"
            queryDataPath="readOrganisations.edges"
            queryNestedObjectPath="node"
            onChange={(newOrgSelectObj, actualNode) => {
              setCurrentVH(null);
              setCurrentOrganisationTo(null);
              setCurrentOrganisationToData(null);
              setCurrentOrganisationFrom(newOrgSelectObj);
              setCurrentOrganisationFromData(actualNode);
            }}
            value={currentOrganisationFrom}
            htmlId="organisation-copy-from"
            label="Organisation to Select VH From"
            placeholder="Select an organisation that the vh already exists in..."
            labelField="Name"
            disabled={isLoading}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <DBSelect
            dataQuery={SEARCH_VIRTUAL_HUMANS}
            filterVar="filter"
            queryDataPath="readVirtualHumans.edges"
            queryNestedObjectPath="node"
            disabled={!currentOrganisationFrom || isLoading}
            dataQueryVariables={{
              organisationID: currentOrganisationFrom
                ? currentOrganisationFrom.value
                : null,
            }}
            onChange={(newVHSelectObj, vhRow) => {
              setCurrentOrganisationTo(null);
              setCurrentOrganisationToData(null);
              setCurrentVH(newVHSelectObj);
              setCurrentVHData(vhRow);
            }}
            value={currentVH}
            htmlId="vh-list"
            label="Virtual Human"
            placeholder="Select vh..."
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <DBSelect
            dataQuery={SEARCH_ORGANISATIONS}
            filterVar="filter"
            queryDataPath="readOrganisations.edges"
            queryNestedObjectPath="node"
            disabled={!currentOrganisationFrom || !currentVH || isLoading}
            onChange={(newOrgSelectObj, actualRow) => {
              setCurrentOrganisationTo(newOrgSelectObj);
              setCurrentOrganisationToData(actualRow);
            }}
            value={currentOrganisationTo}
            htmlId="organisation-copy-to"
            label="Organisation to Add VH To"
            placeholder="Select the organisation that you want to add the VH to..."
            labelField="Name"
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <StyledLabel>Select publish strategy:</StyledLabel>
          <Select {...publishStrategySelectProps} />
        </StyledInputWrapper>
        <div>
          <Button
            disabled={buttonDisabled}
            loading={isLoading}
            onClick={async () => {
              duplicate({
                variables: {
                  VirtualHumanID: currentVH.value,
                  OrganisationID: currentOrganisationTo.value,
                  PublishStrategy: publishStrategy,
                  CreationMethod: 'cx_duplicate',
                },
              });
            }}
            target="_blank"
          >
            Copy
          </Button>
        </div>
        {orgApiMismatch && (
          <div>
            You cannot copy a virtual human between these organisations because
            they have different API Endpoints set.
          </div>
        )}
        {!orgApiMismatch && targetOrgLimitHit && (
          <div>
            The target organisation has hit their VH limit for{' '}
            {currentVHData.Type === 'branching' ? 'branching' : 'freeform'}{' '}
            Virtual Humans (
            {currentVHData.Type === 'branching'
              ? branchingTotalCount
              : freeformTotalCount}
            /{currentVHData.Type === 'branching' ? branchingMax : freeformMax}).
          </div>
        )}
      </StyledPageContentInner>
    </StyledPageContentWrapper>
  );
};

CxCopyVH.propTypes = {
  addFlashMessage: PropTypes.func.isRequired,
};

export default withFlashMessaging(CxCopyVH);
