import { useMutation } from '@apollo/client';
import { TextInput, Card } from '@virtidev/toolbox';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAutosave } from '../../../../../helpers/useAutosave';
import { UPDATE_PAGE_TITLE } from './EditPageTitle.query';

export default function EditPageTitle({ activePage, setLoading }) {
  const { __typename, ...defaultValues } = activePage;
  const formMethods = useForm({
    defaultValues,
  });
  const { register } = formMethods;

  const [updatePageTitle] = useMutation(UPDATE_PAGE_TITLE);

  const handleSubmit = useMemo(() => {
    return async (values) => {
      const input = _.pick(values, ['ID', 'Title']);
      await updatePageTitle({
        variables: {
          input,
        },
        optimisticResponse: {
          updateFeedbackPage: {
            ...input,
            __typename: 'FeedbackPage',
          },
        },
      });
    };
  }, [updatePageTitle]);

  useAutosave(formMethods, handleSubmit, setLoading);

  return (
    <Card title="Page Title">
      <FormProvider {...formMethods}>
        <TextInput {...register('Title')}></TextInput>
      </FormProvider>
    </Card>
  );
}
