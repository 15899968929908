import React from 'react';
import _ from 'lodash';
import StyledPage, { StyledPageContentInner } from '../components/StyledPage';
import StyledPageHeader from '../styled-components/StyledPageHeader';
import RedirectWithPrevState from '../components/RedirectWithPrevState';
import useUser from '../apps/core/src/helpers/useUser';
import { withLoggedInAccess } from '../apps/core/src/helpers/permissions';
import MyVirtualHumanSessions from '@core/components/Learners/MySessions/MyVirtualHumanSessions/MyVirtualHumanSessions';

const MyVirtualHumanSessionsPage = () => {
  const { ID } = useUser();

  if (!ID) {
    return <RedirectWithPrevState to="/login" />;
  }
  return (
    <StyledPage pageKey="profile">
      <StyledPageContentInner>
        <StyledPageHeader>Virtual Human Sessions</StyledPageHeader>
        <MyVirtualHumanSessions />
      </StyledPageContentInner>
    </StyledPage>
  );
};

MyVirtualHumanSessionsPage.propTypes = {};

export default withLoggedInAccess(MyVirtualHumanSessionsPage);
