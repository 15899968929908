import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '../components/icons/Icon';

const StyledModal = styled.div`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  position: fixed;
  z-index: 4;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  align-items: center;
  justify-content: center;
  z-index: 1010;
`;

const StyledModalInner = styled.div`
  position: relative;
  min-height: 10rem;
  min-width: ${(props) => props.minWidth || '40rem'};
  background-color: var(--material-bg-color);
  max-height: 80%;
  max-width: 80%;
  overflow: auto;
`;

const StyledContent = styled.div`
  padding: 1rem 2rem 2rem 2rem;
`;

const StyledTitle = styled.h1`
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 1rem;
  text-align: center;
  border-bottom: var(--secondary-border);
  padding: 1.5rem;
`;

const StyledCloseButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  cursor: pointer;
`;

class StyledModalWithProps extends React.Component {
  constructor(props) {
    super(props);
    this.internalModalContent = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('keydown', this._handleKeyPress);
  }

  componentWillUmount() {
    window.removeEventListener('keydown', this._handleKeyPress);
  }

  _handleKeyPress = (e) => {
    if (e.keyCode === 27) {
      this.props.hideModal();
    }
  };

  render() {
    return (
      <StyledModal
        visible={this.props.visible}
        onClick={(e) => {
          // hide modal if clicking on the grey overlay
          if (e.target.firstChild === this.internalModalContent.current) {
            this.props.hideModal();
          }
        }}
      >
        <StyledModalInner
          minWidth={this.props.minWidth}
          ref={this.internalModalContent}
        >
          {this.props.title && <StyledTitle>{this.props.title}</StyledTitle>}
          <StyledCloseButton
            onClick={(e) => {
              e.preventDefault();
              this.props.hideModal();
            }}
          >
            {!this.props.noExit && (
              <Icon
                name="close-blk"
                resizeWidth={false}
                resizeHeight={false}
                type="general"
              />
            )}
          </StyledCloseButton>
          <StyledContent>{this.props.children}</StyledContent>
        </StyledModalInner>
      </StyledModal>
    );
  }
}

StyledModal.propTypes = {
  visible: PropTypes.bool,
  hideModal: PropTypes.func,
  children: PropTypes.node,
  title: PropTypes.string,
  minWidth: PropTypes.string,
};

export default StyledModalWithProps;

// import React from 'react';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';
// import { GET_MODAL, HIDE_MODAL } from '../queries-local/ModalQueries';

// const StyledModal = styled.div`
//   display: ${props => props.visible ? 'flex' : 'none'};
//   position: fixed;
//   z-index: 3;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   overflow: auto;
//   background-color: rgb(0,0,0);
//   background-color: rgba(0,0,0,0.4);
//   align-items: center;
//   justify-content: center;
// `;

// const StyledContent = styled.div`
//   position: relative;
//   width: 50%;
//   height: 50%;
//   min-height: 10rem;
//   min-width: 10rem;
//   background-color: #FFF;
// `;

// const StyledCloseButton = styled.button`
//   background: none;
//   border: none;
//   position: absolute;
//   top: 1rem;
//   right: 1rem;
//   cursor: pointer;
// `;

// class BeforeUnloadModal extends React.Component {
//   constructor(props) {
//     super(props);
//     this.handleBeforeunload.bind(this);
//   }

//   componentDidMount() {
//     window.addEventListener("beforeunload", this.handleBeforeunload);
//   }

//   componentWillUnmount() {
//     window.removeEventListener("beforeunload", this.handleBeforeunload);
//   }

//   handleBeforeunload = event => {
//     if (this.shouldShowOnUnload) return false;
//     return "sdfsfdsfddf";
//   };

//   render() {

//     return (
//       <Mutation mutation={HIDE_MODAL}>
//         {closeModal => (
//           <Query query={GET_MODAL}>
//             {({ loading, error, data }) => {
//               if (loading) return <p>Loading...</p>;
//               if (error) return <p>Error :(</p>;
//               const { modalData } = data;
//               this.shouldShowOnUnload = data.shouldShowOnUnload;
//               console.log(data)
//               return (
//                 <StyledModal visible={modalData.visible}>
//                   <StyledContent>
//                     <StyledCloseButton onClick={closeModal}>X</StyledCloseButton>
//                     {
//                       modalData.modalType === 'beforeunload' && (
//                         <div>
//                           <div>{modalData.title}</div>
//                           <div>{modalData.content}</div>
//                         </div>
//                       )
//                     }
//                   </StyledContent>
//                 </StyledModal>
//               )
//             }}
//           </Query>
//         )}
//       </Mutation>
//     );
//   }
// }

// // Modal.propTypes = {
// //   visible: PropTypes.bool.isRequired,
// //   modalType: PropTypes.string.isRequired,
// // };

// export default Modal;
