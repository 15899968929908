import { gql } from '@apollo/client';

export const READ_ORGANISATION_API = gql`
  query readOneOrganisation($ID: ID!) {
    readOneOrganisation(filter: { ID: { eq: $ID } }) {
      ID
      APIIncomingURL
    }
  }
`;

export const EDIT_ORGANISATION = gql`
  mutation updateOrganisation($Input: UpdateOrganisationInput!) {
    updateOrganisation(input: $Input) {
      ID
      APIIncomingURL
    }
  }
`;
