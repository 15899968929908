import { useQuery } from '@apollo/client';
import useUser from '../../../helpers/useUser';
import { useHasPendingContent } from '../../../helpers/useHasPendingContent';
import DashboardBanner from '../DashboardBanner/DashboardBanner';

export default function PendingContentCopyBanner({ show = null }) {
  const { ID, Organisation } = useUser();

  const { data: orgData } = useHasPendingContent(Organisation.ID, {
    skip: show !== null,
  });

  if (show || orgData?.HasContentQueuedForCopy) {
    return (
      <DashboardBanner>
        Additional content is being copied to your account. This may take a few
        minutes.
      </DashboardBanner>
    );
  } else {
    return null;
  }
}
