import { useContext, useEffect } from "react"
import { deepLinkContext } from "../DeepLinkProvider"

export const useDeepLink = (link) => {
  const setLink = useContext(deepLinkContext);

  useEffect(() => {
    setLink(link);

    return () => setLink(null);
  }, [link, setLink]);
}