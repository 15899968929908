import React from 'react';
import styled from 'styled-components';
import Icon from '../components/icons/Icon';

export const StyledCustomisedInputWrapper = styled.div`
  position: relative;
  margin-bottom: 2rem;
  border-radius: var(--card-border-radius);
  background-color: var(--card-bg-color);
  box-shadow: var(--card-box-shadow);
  padding: 2rem;
  border-left: ${(props) => props.editing && '5px solid var(--primary-color)'};
`;
const StyledRemoveButton = styled.button`
  background-color: var(--text-input-bg-color);
  border: none;
  border-radius: 60px;
  width: 36px;
  height: 36px;
  color: #333;
  cursor: pointer;
  :hover {
    background-color: #ececec;
  }
`;
const StyledRemoveBtnWrapper = styled.div`
  position: absolute;
  top: 9px;
  right: 9px;
`;
export const StyledRemoveBtn = (props) => (
  <StyledRemoveBtnWrapper className={props.className}>
    <StyledRemoveButton onClick={props.onClick}>
      <Icon type="lineawesome" name="trash-solid" />
    </StyledRemoveButton>
  </StyledRemoveBtnWrapper>
);
export const StyledCustomisedInputEditWrapper = styled.div``;
export const StyledCustomisedInputPreviewWrapper = styled.div``;
export const StyledCustomisedInputEditInputWrapper = styled.div`
  margin-bottom: 1rem;
`;
export const StyledCustomisedInputPreviewInputWrapper = styled.div`
  margin-bottom: 1rem;
`;
export const StyledCustomisedInputHeader = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const StyledLabel = styled.div`
  margin-bottom: 2rem;
  word-break: break-word;
`;

export const StyledInfo = styled.div`
  font-weight: 300;
  font-size: 0.8rem;
  margin: 1rem 0rem 2rem 0rem;
  line-height: 1.2rem;
`;
