import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import useUser from '../../../apps/core/src/helpers/useUser';
import { useTheme } from 'styled-components';
import { useFeature } from '@core/components/LaunchDarkly';
import useGetVHCounts from '@base/components/VirtualHumanCreationWizard/useGetVHCounts';
import * as Styled from './VHScenarioStep.styled';

const VH_TYPES = {
  DEFAULT: 'default',
  BRANCHING: 'branching',
  MEDICAL: 'medical',
  FREEFORM_V3: 'freeform_v3',
};

const VHScenarioStep = ({ confirmAction }) => {
  const { Organisation } = useUser();
  const {
    error,
    isLoading,
    branchingTotalCount,
    branchingMax,
    freeformMax,
    freeformTotalCount,
    canMakeFreeform,
    canMakeBranching,
  } = useGetVHCounts();

  const handleSelectBranching = useCallback(() => {
    confirmAction({
      Type: VH_TYPES.BRANCHING,
    });
  }, [confirmAction]);

  const handleSelectFreeform = useCallback(() => {
    confirmAction({
      Type: VH_TYPES.DEFAULT,
    });
  }, [confirmAction]);

  const handleSelectMedical = useCallback(() => {
    confirmAction({
      Type: VH_TYPES.MEDICAL,
    });
  }, [confirmAction]);

  const handleSelectFreeformV3 = useCallback(() => {
    confirmAction({
      Type: VH_TYPES.FREEFORM_V3,
    });
  }, [confirmAction]);

  const legacyFreeformV2Feature = useFeature('vh-freeform-legacy-v2');
  const freeformV3Feature = useFeature('freeform-v-2');

  return (
    <div>
      <Styled.Buttons>
        {freeformV3Feature && (
          <Styled.ScenarioButton
            disabled={!canMakeBranching}
            onClick={handleSelectFreeformV3}
          >
            <Styled.TitleWrapper>
              <Styled.Title>Conversational</Styled.Title>{' '}
              {!canMakeBranching && (
                <Styled.LimitNotice>
                  {branchingTotalCount}/{branchingMax} created. Limit reached
                </Styled.LimitNotice>
              )}
            </Styled.TitleWrapper>
            <Styled.Description>
              Use AI for conversation and evaluation. Define your evaluation
              criteria and guide the AI on response style to create a truly
              tailored experience, giving insightful feedback for each learner.
            </Styled.Description>
          </Styled.ScenarioButton>
        )}
        {legacyFreeformV2Feature && (
          <Styled.ScenarioButton
            onClick={handleSelectFreeform}
            disabled={!canMakeFreeform}
          >
            <Styled.TitleWrapper>
              <Styled.Title>Freeform</Styled.Title>{' '}
              {!canMakeFreeform && (
                <Styled.LimitNotice>
                  {freeformTotalCount}/{freeformMax} created. Limit reached
                </Styled.LimitNotice>
              )}
            </Styled.TitleWrapper>
            <Styled.Description>
              Let your learners practise their conversational skills by using
              natural language questions or statements, getting pre-defined
              replies.
            </Styled.Description>
          </Styled.ScenarioButton>
        )}
        <Styled.ScenarioButton
          disabled={!canMakeBranching}
          onClick={handleSelectBranching}
        >
          <Styled.TitleWrapper>
            <Styled.Title>Guided</Styled.Title>{' '}
            <Styled.TagTeal>Beginner</Styled.TagTeal>{' '}
            {!canMakeBranching && (
              <Styled.LimitNotice>
                {branchingTotalCount}/{branchingMax} created. Limit reached
              </Styled.LimitNotice>
            )}
          </Styled.TitleWrapper>
          <Styled.Description>
            Choose from pre-defined options that trigger specific replies.
            Allowing your learners to experience guided, decision-based
            conversations.
          </Styled.Description>
        </Styled.ScenarioButton>
        {/* use direct comparison to "false" to ease transition from logged in users not having the Medical data available in localstorage */}
        {Organisation?.Medical !== false && (
          <Styled.ScenarioButton
            onClick={handleSelectMedical}
            disabled={!canMakeFreeform}
          >
            <Styled.TitleWrapper>
              <Styled.Title>Medical exam</Styled.Title>{' '}
              <Styled.TagBlue>Medical</Styled.TagBlue>{' '}
              {!canMakeFreeform && (
                <Styled.LimitNotice>
                  {freeformTotalCount}/{freeformMax} created. Limit reached
                </Styled.LimitNotice>
              )}
            </Styled.TitleWrapper>
            <Styled.Description>
              Including conversation practice, learners can engage in medical
              examinations, with scenarios enhanced by adding vital signs for a
              more realistic medical experience.
            </Styled.Description>
          </Styled.ScenarioButton>
        )}
      </Styled.Buttons>
    </div>
  );
};

VHScenarioStep.propTypes = {
  confirmAction: PropTypes.func.isRequired,
};

export default VHScenarioStep;
