import { useEffect } from 'react';
import useUser from '@core/helpers/useUser';
import * as Sentry from '@sentry/browser';
import getConfig from '@base/lib/config';
import { IsMobile } from '@base/utility/BrowserCheck';

const sessionStart = new Date();
const REACT_APP_REMOTE_URI = getConfig('REACT_APP_REMOTE_URI');

export const useSentryUserData = () => {
  const user = useUser();

  let prefix =
    REACT_APP_REMOTE_URI === 'https://pikachu.virti.com/' ||
    REACT_APP_REMOTE_URI === 'https://raichu.virti.com/'
      ? 'prod'
      : 'dev';

  useEffect(() => {
    if (!user?.ID || IsMobile()) {
      return;
    }
    const { ID, OrganisationID, UserType } = user;

    const data = {
      id: `${prefix}-${ID}`,
      org_id: `${prefix}-${OrganisationID}`,
      user_type: `${prefix}-${UserType}`,
      window_width: window.innerWidth,
      window_height: window.innerHeight,
      session_start: sessionStart.toISOString(),
      time: new Date().toISOString(),
    };

    Sentry.setUser(data);
  }, [user, prefix]);
};

export default useSentryUserData;
