import { gql } from '@apollo/client';

export const CREATE_QUESTION = gql`
  mutation createQuestion($input: CreateQuestionInput!) {
    createQuestion(input: $input) {
      ID
      Type
      Title
      SortOrder
      Placeholder
      Required
      CharacterLimit
      Length
      FirstLabel
      LastLabel
      Options {
        nodes {
          ID
        }
      }
    }
  }
`;

export const READ_PAGE = gql`
  query readOneFeedbackPage($filter: FeedbackPageFilterFields!) {
    readOneFeedbackPage(filter: $filter) {
      ID
      Title
      SortOrder
      Questions {
        nodes {
          ID
          Title
          SortOrder
          Options
          Placeholder
          Required
        }
      }
    }
  }
`;
