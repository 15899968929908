import { css } from 'styled-components';

/**
 * Core 2.0 UX variables
 * override variables in theme.css where possible
 */
export const newTheme = css`
  ${({ theme }) => css`
    --text-font: 'Ubuntu', sans-serif;

    --primary-color: ${theme.color.primary};
    --primary-color-contrast: #ffffff;
    --primary-color-light: #5880f6;
    --secondary-color: ${theme.color.secondary};
    --secondary-color-contrast: #ffffff;
    --secondary-color-light: #66d9ca;
    --error-color: ${theme.color.error};
    --success-color: ${theme.color.success};

    --primary-font-color: ${theme.color.base};
    --primary-font-color-light: #616161;

    --content-padding: ${theme.spacing.lg};
    --placeholder-color: #d8d8d8;
    --placeholder-background: #f1f0f0;

    --input-border-radius: ${theme.borderRadius.lg};
    --input-multiline-border-radius: ${theme.borderRadius.md};
    --input-border: 1px solid #cccccc;
    --input-background: #fff;
    --input-disabled-background: #eee;
    --input-off-background: #ddd;
    --input-text-color: #333;
    --input-focus-box-shadow: 0 0 1px 1px ${theme.color.primary};
    --input-hover-box-shadow: 0 0 1px 1px ${theme.color.secondary};
    --input-error-text-color: #cf3535;
    --input-error-border: 1px solid #cf3535;
    --field-note-text-color: #777;

    --card-border-radius: 24px;
    --card-border: 1px solid #c3c3c3;
    --card-box-shadow: 0 3px 6px 1px #eaeaea;
    --accordion-border-radius: 8px;
    --module-action-gap: 35px;

    --sidebar-width-contracted: 75px;
    --sidebar-width-expanded: 260px;
    --sidebar-transition-time: 0.3s;

    --sim-forms-min-expanded: 280px;
    --sim-forms-max-expanded: 460px;
    --sim-forms-duration: 0.3s;
  `}
`;

export default newTheme;
