import PropTypes from 'prop-types';
import { FieldHelpWrapper, InfoIcon } from './FieldHelp.styled';

const FieldHelp = (props) => {
  return (
    <FieldHelpWrapper>
      <div>
        <InfoIcon />
      </div>
      <div>{props.children}</div>
    </FieldHelpWrapper>
  );
};

FieldHelp.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FieldHelp;
