import { useQuery } from '@apollo/client';
import { Button, Icon, Loading, Modal } from '@virtidev/toolbox';
import React, { useState } from 'react';
import { READ_FEEDBACK_FORM } from '../../../../../../../queries/FeedbackFormQueries';
import AutosaveIndicator from '../../../form/AutosaveIndicator/AutosaveIndicator';
import EditFeedbackForm from '../EditFeedbackForm/EditFeedbackForm';
import { Footer, FormLink } from './EditFeedbackModal.styled';

export default function EditFeedbackModal({ id, showModal, setShowModal }) {
  const [loading, setLoading] = useState(false);

  const { data, loading: queryLoading } = useQuery(READ_FEEDBACK_FORM, {
    // I want to use the slimmed custom query but it doesn't work even though it's the same?!!
    variables: {
      ID: id,
    },
    skip: !showModal,
  });

  return (
    <>
      <Button
        style={{ color: 'inherit', paddingLeft: '0', paddingRight: '0' }}
        size="small"
        color="transparent"
        onClick={() => setShowModal(true)}
        title="Edit Feedback Form"
      >
        <Icon icon="form" />
      </Button>
      <Modal
        onHide={() => setShowModal(false)}
        show={showModal}
        render={() => {
          if (data) {
            return (
              <EditFeedbackForm
                setParentLoading={setLoading}
                data={data}
                loading={queryLoading}
                isModal
              />
            );
          }
          if (queryLoading) {
            return <Loading size="large" style={{ margin: 'auto' }} />;
          }
        }}
        footerRender={() => {
          return (
            <Footer>
              <FormLink to={`/feedback-forms/${id}`}>
                Open in Feedback Forms <Icon icon="expand" />
              </FormLink>

              <AutosaveIndicator loading={loading} />
            </Footer>
          );
        }}
      />
    </>
  );
}
