import gql from 'graphql-tag';

// I had consolidated the query here to reduce the number of network calls made
export const CX_ANALYTICS = gql`
  query cxAnalytics {
    cxAnalytics {
      DAU
      MAU
      TotalUsers
      ActiveUsers
      Retention
      MAUMonthly
      Licenses
    }
  }
`;

export default CX_ANALYTICS;
