import { useMutation } from '@apollo/client';
import { Button, ConfirmationModal } from '@virtidev/toolbox';
import { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import useUser from '../../helpers/useUser';
import DeleteAccountForm from './components/DeleteAccountForm';
import { DELETE_SELF } from './DeleteMyAccountButton.query';

export const DeleteMyAccountButton = ({ confirm }) => {
  const history = useHistory();
  const { ID } = useUser();
  const [requesting, setRequesting] = useState(false);
  const formMethods = useForm();

  const { getValues } = formMethods;

  const [deleteSelf, { loading }] = useMutation(DELETE_SELF);

  const deleteAccount = useCallback(async () => {
    const Reason = getValues('reason');
    const result = await deleteSelf({
      variables: {
        Reason,
      },
      optimisticResponse: {
        deleteSelf: {
          ID,
          DeleteRequestID: 'pending',
        },
      },
    });
    const message = encodeURIComponent(
      'Successfuly sent a request to delete your account.'
    );
    history.push({
      pathname: '/logout',
      search: `?msg=${message}`,
    });
  }, [deleteSelf, history, getValues, ID]);

  const showRequest = useCallback(() => setRequesting(true), []);

  const hideRequest = useCallback(() => setRequesting(false), []);

  return (
    <>
      <Button
        icon="link"
        color="turquoise"
        loading={loading}
        disabled={loading}
        onClick={showRequest}
      >
        Request account deletion
      </Button>
      <FormProvider {...formMethods}>
        <ConfirmationModal
          show={requesting}
          onHide={hideRequest}
          onConfirm={deleteAccount}
          title="Request account deletion"
          component={DeleteAccountForm}
        />
      </FormProvider>
    </>
  );
};

export default DeleteMyAccountButton;
