/**
 * @typedef {Readonly<{ value: string, label: string }>[]} Options
 */

const title = /** @type {Options} */ (
  [
    { value: 'Title:ASC', label: 'Name A–Z' },
    { value: 'Title:DESC', label: 'Name Z–A' },
  ].map(Object.freeze)
);

const name = /** @type {Options} */ (
  [
    { value: 'Name:ASC', label: 'Name A–Z' },
    { value: 'Name:DESC', label: 'Name Z–A' },
  ].map(Object.freeze)
);

export const created = /** @type {Options} */ (
  Object.freeze(
    [
      { value: 'Created:DESC', label: 'Newest first' },
      { value: 'Created:ASC', label: 'Oldest first' },
    ].map(Object.freeze)
  )
);

const firstName = /** @type {Options} */ (
  [
    { value: 'FirstName:ASC', label: 'Name A–Z' },
    { value: 'FirstName:DESC', label: 'Name Z–A' },
  ].map(Object.freeze)
);

const email = /** @type {Options} */ (
  [
    { value: 'Email:ASC', label: 'Email A–Z' },
    { value: 'Email:DESC', label: 'Email Z–A' },
  ].map(Object.freeze)
);

const lastSeen = /** @type {Options} */ (
  [
    { value: 'LastSeen:DESC', label: 'Recent last' },
    { value: 'LastSeen:ASC', label: 'Recent first' },
  ].map(Object.freeze)
);

export const createdTitle = Object.freeze([...created, ...title]);

export const userTable = Object.freeze([...lastSeen, ...firstName, ...email]);

export const groupTable = Object.freeze([...name]);
