import React, { FC, useCallback, useMemo } from 'react';
import * as Styled from './ClickableVoice.styled';
import VoicePreview from '@core/components/VirtualHumans/VoiceSelector/components/VoicePreview/VoicePreview';

/**
 * @typedef {import('../../VoiceSelector.types').VoiceWithViewData} VoiceWithViewData
 */

/** @type {FC<{disabled?: boolean; voice: VoiceWithViewData; active: boolean; onClick: (voice: VoiceWithViewData) => void; filterLocaleCode?: string;}>} */
const ClickableVoice = ({
  disabled,
  voice,
  onClick,
  active,
  filterLocaleCode,
}) => {
  const handleVoiceClick = useCallback(() => {
    onClick(voice);
  }, [onClick, voice]);

  return (
    <Styled.ClickableVoice
      role="button"
      tabIndex={0}
      $active={active}
      onClick={handleVoiceClick}
    >
      <Styled.Texts>
        <Styled.VoiceName $active={active}>{voice.Name}</Styled.VoiceName>
        <Styled.Tags>
          {voice.tags.map((tag) => (
            <Styled.Tag key={tag}>{tag}</Styled.Tag>
          ))}
        </Styled.Tags>
      </Styled.Texts>
      <Styled.VoicePreview>
        <VoicePreview voice={voice} locale={filterLocaleCode} />
      </Styled.VoicePreview>
    </Styled.ClickableVoice>
  );
};

export default ClickableVoice;
