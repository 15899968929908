import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import useStateUrl from '../../../../helpers/useStateUrl';
import useUser from '../../../../helpers/useUser';
import Button from '../../../form/Button';
import { useDebouncedSave } from '../../../form/hooks/useDebouncedSave';
import { StateUrlSelect } from '../../../form/Select';
import { usePageScroll } from '../../../Page';
import { usePaginationUrl } from '../../../PaginationControl/helpers/usePaginationUrl';
import { StateUrlSelectGroup } from '../../../form/Select/components/SelectGroup/SelectGroup';
import { TextInput } from '@virtidev/toolbox';
import { FieldWrapper } from '../../../form/ActionBar/ActionBar.styled';
import { createdTitle as sortOptions } from '../../../../helpers/sortCollections';
import { StateUrlSelectTag } from '../../../form/Select/components/SelectTag/SelectTag';

const statuses = [
  { value: '', label: 'All statuses' },
  { value: 'Published', label: 'Published' },
  { value: 'Unpublished', label: 'Unpublished' },
];

const groupOptions = [
  { value: '', label: 'All groups' },
  { value: '0', label: '(Has no groups)' },
];

const assignmentOptions = [
  { value: 'created', label: 'Created by you' },
  { value: 'assigned', label: 'Assigned to you' },
];

export const ActionList = ({ loading }) => {
  const { UserType } = useUser();
  const { scrollTop } = usePageScroll();

  const { resetPage } = usePaginationUrl({
    onChange: scrollTop,
  });

  const { value: filter, updateValue: setFilter } = useStateUrl({
    initialValue: '',
    onChange: scrollTop,
  });

  const handleFilterChange = useCallback(
    (value) => {
      resetPage();
      setFilter(value || '');
    },
    [setFilter, resetPage]
  );

  const debouncedProps = useDebouncedSave(filter, {
    onUpdate: handleFilterChange,
  });

  return (
    <>
      {UserType === 'contentcreator' ? (
        <>
          <FieldWrapper>
            <StateUrlSelect
              param="assignment"
              options={assignmentOptions}
              placeholder="Filter by assignment..."
              onChange={resetPage}
              clearable
            />
          </FieldWrapper>
          <FieldWrapper>
            <StateUrlSelectTag
              param="tag"
              multi
              onChange={resetPage}
              placeholder="Filter by tag..."
              options={[{ value: '0', label: '(No tags)' }]}
            />
          </FieldWrapper>
        </>
      ) : (
        <>
          <FieldWrapper>
            <StateUrlSelect
              param="status"
              options={statuses}
              placeholder="Filter by status..."
              onChange={resetPage}
            />
          </FieldWrapper>
          <FieldWrapper>
            <StateUrlSelectGroup
              param="group"
              onChange={resetPage}
              options={groupOptions}
              placeholder="Filter by group..."
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextInput {...debouncedProps} placeholder="Search..." />
          </FieldWrapper>
          <FieldWrapper>
            <StateUrlSelectTag
              param="tag"
              multi
              onChange={resetPage}
              placeholder="Filter by tag..."
              options={[{ value: '0', label: '(No tags)' }]}
            />
          </FieldWrapper>
          <FieldWrapper>
            <StateUrlSelect
              param="sort"
              isSearchable={false}
              options={sortOptions}
              placeholder="Sort..."
              inlineLabel="Sort:"
              initialValue={sortOptions[0].value}
              onChange={resetPage}
            />
          </FieldWrapper>
        </>
      )}
      <Button color="primary" type="link" as={Link} to="/simulations/new">
        Create New Simulation
      </Button>
    </>
  );
};

export default ActionList;
