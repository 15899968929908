import { useQuery } from '@apollo/client';
import { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import useUser from '../../../../../helpers/useUser';
import { READ_VIRTUAL_HUMANS_FOR_SELECT } from './SelectVirtualHuman.query';
import Select from '../../Select';
import _ from 'lodash';
import { useSelectFilter } from '../../helpers/useSelectFilter';

export const SelectVirtualHuman = ({ options, loading, ...props }) => {
  const { OrganisationID } = useUser();
  const [filter, filterProps] = useSelectFilter();

  const { data: vhData, loading: vhLoading } = useQuery(
    READ_VIRTUAL_HUMANS_FOR_SELECT,
    {
      variables: {
        OrganisationID,
        filter,
      },
    }
  );

  const vhs = useMemo(() => {
    if (!vhData) {
      return [];
    }
    return vhData.readVirtualHumans.edges.map(({ node }) => ({
      value: node.ID,
      label: node.Title,
    }));
  }, [vhData]);

  useEffect(() => {
    const { inputValue, ref } = filterProps;
    if (!vhLoading && inputValue) {
      ref.current.focus();
      ref.current.onMenuOpen();
    }
    // I do not want this to activate with inputValue change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vhLoading]);

  const allOptions = useMemo(() => [...options, ...vhs], [options, vhs]);

  return (
    <Select
      {...props}
      {...filterProps}
      options={allOptions}
      loading={vhLoading || loading}
    />
  );
};

SelectVirtualHuman.defaultProps = {
  options: [],
  loading: false,
};

SelectVirtualHuman.propTypes = {
  options: PropTypes.arrayOf(PropTypes.any),
  loading: PropTypes.bool,
};

export default SelectVirtualHuman;
