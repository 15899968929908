import styled from 'styled-components';

export const DropdownWrapper = styled.div`
  width: 100%;
`;

export const VoiceSelectorWrapper = styled.div`
  overflow-y: auto;
  max-height: 26svh;
`;
