import PropTypes from 'prop-types';

import {
  ADD_MEDIA_SIMULATION,
  ADD_VIDEO_SIMULATION,
} from '../../queries/SimulationQueries';
import withFlashMessaging from '../../HOCs/WithFlashMessaging';
import { EllipsisMenuButton } from '../EllipsisMenu';
import tracker from '../../apps/core/src/helpers/tracker';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useFlashMessage } from '@core/components/FlashMessage';
import { Icon } from '@virtidev/toolbox';
import { useTheme } from 'styled-components';

const CreateSimulationFromMediaButtonInMenu = ({ ID, type }) => {
  const { addFlashMessage } = useFlashMessage();
  const { color } = useTheme();
  const history = useHistory();
  const [createSimulation, { loading }] = useMutation(
    type === 'video' ? ADD_VIDEO_SIMULATION : ADD_MEDIA_SIMULATION,
    {
      variables:
        type === 'video'
          ? {
              VideoID: ID,
            }
          : {
              MediaID: ID,
            },
      update: (cache) => {
        // evict instead of refetchQueries since we're redirecting away from page
        cache.evict({ fieldName: 'readSimulations' });
        cache.evict({ fieldName: 'readVideoMedias' });
        cache.evict({ fieldName: 'readMedias' });
      },
      onError: (err) => {
        console.error(err);
        addFlashMessage('There was an error creating the simulation', 'error');
      },
      onCompleted: (data) => {
        const simId =
          data?.createSimulationFromVideoMedia?.ID ||
          data?.createSimulationFromMedia?.ID;
        if (simId) {
          addFlashMessage('Simulation created', 'success');
          tracker.track('simulation_created', {
            simulation_id: simId,
          });
          history.push(`/simulations/${simId}`);
        }
      },
    }
  );

  return (
    <EllipsisMenuButton loading={loading} onClick={createSimulation}>
      <Icon size="1.3rem" icon="vr" type="outline" color={color.blue20} />{' '}
      Create new Simulation
    </EllipsisMenuButton>
  );
};

CreateSimulationFromMediaButtonInMenu.propTypes = {
  ID: PropTypes.string,
};

export default CreateSimulationFromMediaButtonInMenu;
