import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { READ_ORGANISATION_LICENSES } from '../queries/OrganisationQueries';
import LoadingIndicator from './LoadingIndicator';

const StyledCount = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0 1rem;
  font-size: 0.8rem;
  color: var(--primary-font-color-light);
  div {
    margin: 0.4rem 0rem;
  }
`;

export default function LicenseCount(props) {
  const { data, loading, error } = useQuery(READ_ORGANISATION_LICENSES, {
    variables: {
      ID: props.organisationID,
    },
  });

  const organisation = useMemo(() => data?.readOneOrganisation, [data]);

  const used = useMemo(
    () => Number(organisation?.TotalUsers) + Number(organisation?.TotalAdmins),
    [organisation]
  );

  const remaining = useMemo(
    () => Math.max(0, organisation?.Licenses - used),
    [organisation, used]
  );

  return (
    <div>
      {loading && <LoadingIndicator />}
      {!loading && (
        <StyledCount>
          <div>Licenses Used: {used}</div>
          <div>Total Licenses: {organisation?.Licenses}</div>
          <div>Licenses Remaining: {remaining}</div>
          {error && <div>User Licenses could not be loaded.</div>}
        </StyledCount>
      )}
    </div>
  );
}
