import { useStateUrl } from '@virtidev/toolbox';
import { withSplashPageTemplate } from '@core/templates';
import RegisterForm from '@core/components/Signup/RegisterForm/RegisterForm';
import {
  FC,
  Dispatch,
  useCallback,
  useLayoutEffect,
  useState,
  useMemo,
} from 'react';
import { RegisterFormInput } from '@core/components/Signup/RegisterForm/helpers/register-form.types';
import submitHubspotData from '@core/components/Signup/RegisterForm/helpers/submitHubspotData';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import RegisterLoading from '@core/components/Signup/RegisterLoading/RegisterLoading';
import RegisterDemo from '@core/components/Signup/RegisterDemo/RegisterDemo';
import {
  COPY_CONTENT,
  CREATE_NEW_ACCOUNT,
  CREATE_TOKEN_ACCOUNT,
  GET_SIM_CONTENT,
  GET_VH_CONTENT,
  LOGIN,
  WELCOME_COURSE,
} from '@core/components/Signup/RegisterForm/RegisterForm.query';
import tracker from '@core/helpers/tracker';
import { StoreLogin } from '@base/utility/LoginUtility';
import useUser from '@core/helpers/useUser';
import { IsMobile } from '@base/utility/BrowserCheck';
import { useHistory } from 'react-router-dom';

/**
 * @type {FC<{
 *    setExpanded?: Dispatch<boolean>,
 * }>}
 */
export const RegisterPage = ({ setExpanded }) => {
  const { ID, OrganisationID } = useUser();
  const { value: stage, updateValue: setStage } = useStateUrl({
    key: 'stage',
    initialValue: 'register',
    replace: true,
  });
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(/** @type {string | null} */ (null));
  const { value: campaign } = useStateUrl({ key: 'utm_campaign' });
  const { value: tokenType } = useStateUrl({ key: 'type' });
  const { value: token } = useStateUrl({ key: 'sharetoken' });

  // hardcoded here until we need more functionality, this is currently thought to be one-off
  const [trialLength, partner] = useMemo(() => {
    if (campaign === 'Learning Technologies 2024') {
      return [60, ''];
    }
    if (campaign === 'Digital Learning Institute') {
      return [120, 'DLI'];
    }
    return [14, ''];
  }, [campaign]);

  const { data: welcomeData, loading: welcomeLoading } = useQuery(
    WELCOME_COURSE,
    {
      onError: (error) => {
        setError(error.message);
      },
      variables: {
        OrganisationID,
      },
      skip: Boolean(!OrganisationID || IsMobile() || stage !== 'demo'),
      notifyOnNetworkStatusChange: true,
    }
  );

  const existingOrg = useMemo(() => !!token && !!tokenType, [token, tokenType]);

  const [createNewAccount] = useMutation(CREATE_NEW_ACCOUNT, {
    onError: (error) => {
      setError(error.message);
    },
    notifyOnNetworkStatusChange: true,
  });

  const [createTokenAccount] = useMutation(CREATE_TOKEN_ACCOUNT, {
    onError: (error) => {
      setError(error.message);
    },
    notifyOnNetworkStatusChange: true,
  });

  const [login] = useMutation(LOGIN, {
    onError: (error) => {
      setError(error.message);
    },
    notifyOnNetworkStatusChange: true,
  });

  const [copyContent] = useMutation(COPY_CONTENT, {
    onError: (error) => {
      setError(error.message);
    },
  });

  const [getVHContent] = useLazyQuery(GET_VH_CONTENT, {
    variables: { filter: { ShareToken: { eq: token } } },
  });

  const [getSimContent] = useLazyQuery(GET_SIM_CONTENT, {
    variables: { filter: { ShareToken: { eq: token } } },
  });

  const { unitID, simID } = useMemo(() => {
    const unit =
      welcomeData?.welcomeCourse?.Modules?.nodes?.[0]?.Units?.nodes?.[0];

    if (!unit) {
      return {};
    }

    return {
      unitID: unit.ID,
      simID: unit.Simulation?.ID,
    };
  }, [welcomeData]);

  const createAccount = useCallback(
    async (input) => {
      const userData = await createNewAccount({
        variables: {
          ...input,
          TrialLength: trialLength,
          OrganisationPartner: partner,
        },
      });

      if (!userData?.data?.signupNewUser) {
        throw new Error('Account error, failed to create');
      }
      const user = userData.data.signupNewUser;
      let orgId = user?.Organisations?.nodes?.[0]?.ID;

      if (!orgId) {
        throw new Error('Create account error, no organisation');
      }

      tracker.track('organisation_registered', {
        flow: 'organisation_self_registration',
        organisation_id: orgId,
        trial_length: trialLength,
      });

      const loginData = await login({ variables: input });

      if (!loginData.data?.createVirtiToken) {
        throw new Error('Login error');
      }

      StoreLogin(
        loginData.data.createVirtiToken.Token,
        loginData.data.createVirtiToken.PDToken,
        user,
        null,
        orgId
      );

      await copyContent({
        variables: {
          OrganisationID: orgId,
        },
      });

      setTimeout(async () => {
        setStage('demo');
        setLoading(false);
      }, 200);
    },
    [copyContent, createNewAccount, login, partner, setStage, trialLength]
  );

  const updateAccount = useCallback(
    async (input) => {
      const userData = await createTokenAccount({
        variables: {
          ...input,
          tokenType,
          token,
        },
      });

      if (!userData?.data?.signupTokenUser) {
        throw new Error('Account error, failed to create');
      }
      const user = userData.data.signupTokenUser;
      let orgId = user?.Organisations?.nodes?.[0]?.ID;
      let content = null;

      if (tokenType === 'virtualhuman') {
        const { data } = await getVHContent();

        content = data.readOneVirtualHuman;
      }
      if (tokenType === 'simulation') {
        const { data } = await getSimContent();

        content = data.readOneSimulation;
      }

      if (content?.ShareToken !== token) {
        content = null;
      }
      orgId = content?.OrganisationID || orgId;

      if (!orgId) {
        throw new Error('Create account error, cannot find organisation');
      }

      tracker.track('organisation_registered', {
        flow: 'organisation_self_registration',
        organisation_id: orgId,
      });

      const loginData = await login({ variables: input });

      if (!loginData.data?.createVirtiToken) {
        throw new Error('Login error');
      }

      StoreLogin(
        loginData.data.createVirtiToken.Token,
        loginData.data.createVirtiToken.PDToken,
        user,
        null,
        orgId
      );

      setLoading(false);

      if (content) {
        if (tokenType === 'virtualhuman') {
          history.push(`/my-virtual-humans/${content.ID}/play/${token}`);
          return;
        }
        if (tokenType === 'simulation') {
          history.push(`/my-simulations/${content.ID}/${token}`);
          return;
        }
      }
    },
    [
      createTokenAccount,
      getSimContent,
      getVHContent,
      history,
      login,
      token,
      tokenType,
    ]
  );

  const saveAccount = useCallback(
    /**
     * @param {RegisterFormInput} input
     */
    async (input) => {
      try {
        setLoading(true);

        const hubspotPromise = submitHubspotData({
          email: input.Email,
          firstname: input.FirstName,
          lastname: input.Surname,
          company: input.OrganisationName,
          dataConsent: input.AcceptPrivacy,
          pageName: 'Organisation Self Registration',
        });

        if (!existingOrg) {
          await createAccount(input);
        } else {
          await updateAccount(input);
        }

        await hubspotPromise;
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message);
        }
        console.error(e);

        setLoading(false);
      }
    },
    [createAccount, updateAccount, existingOrg]
  );

  useLayoutEffect(() => {
    if (!setExpanded) {
      return;
    }
    if (stage === 'demo') {
      setExpanded(true);
      return;
    }
    setExpanded(false);
    return;
  }, [stage, setExpanded]);

  useLayoutEffect(() => {
    if (!['register', 'demo'].includes(stage) || !ID) {
      setStage('register');
      return;
    }
    if (!loading) {
      setStage('demo');
    }
  }, [stage, setStage, ID, loading]);

  if (loading) {
    return (
      <RegisterLoading trialLength={trialLength} existingOrg={existingOrg} />
    );
  }

  return (
    <>
      {stage === 'register' && (
        <RegisterForm
          error={error}
          onSubmit={saveAccount}
          trialLength={trialLength}
          existingOrg={existingOrg}
        />
      )}
      {stage === 'demo' && (
        <RegisterDemo
          signupType={welcomeData?.readOneOrganisation?.SignupType}
          loading={welcomeLoading}
          unitID={unitID}
          simID={simID}
          error={error}
          trialLength={trialLength}
          existingOrg={existingOrg}
        />
      )}
    </>
  );
};

export default withSplashPageTemplate({ pageKey: 'register' })(RegisterPage);
