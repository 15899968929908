export const GetEventBoxTagColor = (eventType) => {
  if (eventType === 'Question' || eventType === 'Answer') {
    return 'var(--interaction-question-color)';
  }
  if (eventType === 'Hotspot') {
    return 'var(--interaction-hotspot-color)';
  }
  if (eventType === 'InfoBox') {
    return 'var(--interaction-infobox-color)';
  } else {
    return 'var(--neutral-color-light)';
  }
};

export const ContainsMedia = (event) => {
  if (event.imageURL || event.videoURL || event.audioURL) {
    return true;
  } else {
    return false;
  }
};
