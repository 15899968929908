import { useMutation } from '@apollo/client';
import React, { useCallback, useMemo } from 'react';
import { LoadingPlaceholder, Button } from '@virtidev/toolbox';

import {
  GENERATE_GROUP_INVITE_CODES,
  GENERATE_ORG_INVITE_CODES,
} from './GenerateInviteCodes.query';
import {
  CodesWrapper,
  StyledPlaceholderFill,
} from './GenerateInviteCodes.styled';

export default function GenerateInviteCodes(props) {
  const [updateGroup, { loading: loadingGroup }] = useMutation(
    GENERATE_GROUP_INVITE_CODES,
    {
      variables: {
        ID: props.groupID,
        Input: {
          ID: props.groupID,
        },
      },
    }
  );

  const [updateOrganisation, { loading: loadingOrg }] = useMutation(
    GENERATE_ORG_INVITE_CODES,
    {
      variables: {
        ID: props.orgID,
        Input: {
          ID: props.orgID,
        },
      },
    }
  );

  const loading = useMemo(() => {
    return loadingGroup || loadingOrg;
  }, [loadingOrg, loadingGroup]);

  const generateCodes = useCallback(() => {
    if (props.orgID) {
      updateOrganisation();
    } else if (props.groupID) {
      updateGroup();
    }
  }, [props.groupID, props.orgID, updateOrganisation, updateGroup]);

  return (
    <div>
      {loading ? (
        <CodesWrapper>
          <LoadingPlaceholder>
            <StyledPlaceholderFill />
          </LoadingPlaceholder>
          <LoadingPlaceholder>
            <StyledPlaceholderFill />
          </LoadingPlaceholder>
        </CodesWrapper>
      ) : (
        <Button color="primary" onClick={generateCodes}>
          Generate Invite Codes
        </Button>
      )}
    </div>
  );
}
