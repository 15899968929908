import { StyledPageContentInner } from '../../../../../../../components/StyledPage';
import withUser from '../../../../../../../HOCs/WithUser';
import CXDashboardTabNav from '../../../../../../../components/CXDashboardTabNav';
import WithCXDashboardPageTemplate from '../../../../../../../HOCs/templates/WithCXDashboardPageTemplate';
import CXOrganisationAnalytics from '../../../../components/CXOrganisationAnalytics/CXOrganisationAnalytics';
import OrgTable from '../../../../components/Cx/OrgTable/OrgTable';
import { Icon, useStateUrl } from '@virtidev/toolbox';
import { StyledLinkAsBtn } from '../../../../../../../styled-components/StyledLink';
import {
  StyledBackWrapper,
  StyledOrgHeading,
} from './CXOrganisationAnalyticsPage.styled';
import { useCallback } from 'react';
import CXEditOrganisationForm from './components/CXEditOrganisationForm/CXEditOrganisationForm';
import { useQuery } from '@apollo/client';
import { READ_ORGANISATION } from '@base/queries/OrganisationQueries';

const CXOrganisationAnalyticsPage = (props) => {
  const { value: orgID, updateValue: setOrgID } = useStateUrl({
    key: 'orgID',
  });
  const { value: action, updateValue: setAction } = useStateUrl({
    key: 'action',
  });

  let validAction = '';
  if (orgID && action === 'edit') {
    validAction = 'edit';
  } else if (orgID && action === 'analytics') {
    validAction = 'analytics';
  }

  const { data } = useQuery(READ_ORGANISATION, {
    variables: { ID: orgID },
    skip: !orgID,
  });

  const openEditForm = useCallback(
    (orgID) => {
      setOrgID(orgID);
      setAction('edit');
    },
    [setAction, setOrgID]
  );

  const viewAnalytics = useCallback(
    (orgID) => {
      setOrgID(orgID);
      setAction('analytics');
    },
    [setAction, setOrgID]
  );

  const back = useCallback(() => {
    setOrgID('');
    setAction('');
  }, [setAction, setOrgID]);

  return (
    <>
      <CXDashboardTabNav />
      <StyledPageContentInner>
        {!validAction && (
          <OrgTable openEditForm={openEditForm} viewAnalytics={viewAnalytics} />
        )}
        {validAction && (
          <>
            <StyledBackWrapper>
              <StyledLinkAsBtn onClick={back}>
                <Icon icon="chevron" style={{ transform: 'scaleX(-1)' }} /> Back
              </StyledLinkAsBtn>
              <StyledOrgHeading>
                {data?.readOneOrganisation?.Name}
              </StyledOrgHeading>
            </StyledBackWrapper>
            {action === 'edit' && <CXEditOrganisationForm orgID={orgID} />}
            {action === 'analytics' && (
              <CXOrganisationAnalytics orgID={orgID} />
            )}
          </>
        )}
      </StyledPageContentInner>
    </>
  );
};

CXOrganisationAnalyticsPage.propTypes = {
  // userOrganisationID: PropTypes.string.isRequired,
};

export default withUser(
  WithCXDashboardPageTemplate(CXOrganisationAnalyticsPage)
);
