import { useReactiveVar } from '@apollo/client';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import {
  secondarySidebarContentVar,
  setShowOnboardDialogueVar,
  setShowOnboardProgressVar,
  showOnboardDialogueVar,
  showOnboardProgressVar,
  sidebarContractedVar,
} from '../ApolloReactiveVars';
import WithOnboardingHandler from '../HOCs/WithOnboardingHandler';
import { StyledProgressBar } from '../styled-components/StyledOnboardingDashboard';
import _ from 'lodash';
import { CanViewOnboarding } from '../utility/Permissions';

const StyledGoToDash = styled.div`
  ${({ theme, sidebarContracted }) => css`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1001;

    transition: margin-left var(--sidebar-transition-time, 0.3s);
    ${sidebarContracted
      ? css`
          margin-left: var(--sidebar-width-contracted);
        `
      : css`
          margin-left: var(--sidebar-width-expanded);
        `};
    background-color: ${theme.color.primary};
    padding: 1.2rem 2rem 1rem 2rem;

    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    transition: var(--sidebar-anim-duration);
  `}
`;

const StyledMargin = styled.div`
  ${({ showUpdate }) => (showUpdate ? '  margin: 18rem' : 'margin: 6rem')}
`;

const StyledSimpleLink = styled(Link)`
  ${({ theme }) => css`
    background: ${theme.color.secondary};
    padding: var(--primary-button-padding);
    border-radius: 24px;
    color: white !important;
    text-decoration: none;
    font-weight: 500;
    :hover {
      opacity: 0.9;

      text-decoration: none;
    }
    :active {
      background-color: var(--primary-button-bg-color-active);
    }
  `}
`;

const StyledOptions = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  place-items: end;
  gap: 1rem;
  width: 100%;
`;

const StyledCongrats = styled.div`
  display: grid;
  grid-template-rows: 1fr 1.5fr;
  place-items: center start;
  color: white;
  width: 100%;
`;

const StyledProgressWithLabel = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr auto;
    width: 100%;
    gap: 0.5rem;
    place-items: center start;
    color: ${theme.color.secondary};
    font-size: 0.7rem;
    margin-top: -0.3rem;
  `}
`;

function OnboardingProgress(props) {
  const sidebarContracted = useReactiveVar(sidebarContractedVar);
  const subContent = useReactiveVar(secondarySidebarContentVar);
  const showOnboardDialogue = useReactiveVar(showOnboardDialogueVar);

  const showThisBar = useReactiveVar(showOnboardProgressVar);

  let onboardingFeatureIsActive = CanViewOnboarding();

  useEffect(() => {
    if (
      onboardingFeatureIsActive &&
      !props?.parsedData?.onboardingDismissed &&
      props.totalProgress &&
      props.totalProgress !== 100 &&
      !showOnboardDialogue
    ) {
      setShowOnboardProgressVar(true);
    } else {
      setShowOnboardProgressVar(false);
    }
  }, [onboardingFeatureIsActive, props, showOnboardDialogue]);

  useEffect(() => {
    setShowOnboardDialogueVar(false);
  }, []);

  return (
    <>
      {showThisBar && (
        <div>
          <StyledGoToDash sidebarContracted={sidebarContracted && !subContent}>
            <StyledCongrats>
              You've got onboarding steps to complete!
              <StyledProgressWithLabel>
                <StyledProgressBar
                  value={props.totalProgress}
                  max="100"
                  width={'100%'}
                />{' '}
                PROGRESS
              </StyledProgressWithLabel>
            </StyledCongrats>
            <StyledOptions>
              <StyledSimpleLink to="onboarding-dashboard">
                Onboarding Dashboard
              </StyledSimpleLink>
            </StyledOptions>
          </StyledGoToDash>
          <StyledMargin />
        </div>
      )}
    </>
  );
}

export default WithOnboardingHandler(OnboardingProgress);
