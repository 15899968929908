import { gql } from "@apollo/client";

export const CREATE_MODULE = gql`
  mutation createModule($Input: CreateModuleInput!) {
    createModule(input: $Input) {
      ID
      Title
      Description
      SortOrder
      Units {
        edges {
          node {
            ID
          }
        }
      }
      Course {
        ID
        Modules {
          nodes {
            ID
          }
        }
      }
    }
  }
`;
