import React from 'react';
import PropTypes from 'prop-types';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useDrop } from 'react-dnd';
import { DropArea } from './SimpleUploaderDropArea.styled';

const SimpleUploaderDropArea = ({
  onDrop,
  children,
  acceptedFileMimeTypes,
}) => {
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item) {
        onDrop(item);
      },
      canDrop(props, monitor) {
        const item = monitor.getItem();
        return (
          item.files &&
          item.files.length > 0 &&
          item.files[0] &&
          acceptedFileMimeTypes.includes(item.files[0].type)
        );
      },
      hover() {},
      collect: (monitor) => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [onDrop, acceptedFileMimeTypes]
  );
  const isActive = canDrop && isOver;

  return (
    <DropArea ref={drop} isActive={isActive}>
      {isActive ? 'Release to drop' : children}
    </DropArea>
  );
};

SimpleUploaderDropArea.defaultProps = {
  acceptedFileMimeTypes: [],
};

SimpleUploaderDropArea.propTypes = {
  acceptedFileMimeTypes: PropTypes.arrayOf(PropTypes.string.isRequired)
    .isRequired,
  onDrop: PropTypes.func,
};

export default SimpleUploaderDropArea;
