import React, { Component } from 'react';
import PropTypes from 'prop-types';

import withUser from '../HOCs/WithUser';
import StyledPage, { StyledPageContentInner, StyledTopArea } from '../components/StyledPage';
import UserForm from '../forms/UserForm';
import StyledH1 from '../styled-components/StyledH1';
import RedirectWithPrevState from '../components/RedirectWithPrevState';
import StyledPageHeader from '../styled-components/StyledPageHeader';

class NewUserPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      newUserID: null
    };
  }

  _submitCallback = (data) => {
    this.setState({ newUserID: data.createMember.ID });
  }

  render() {
    if (!this.props.userID) {
      return <RedirectWithPrevState to="/login" />
    }
    return (
      <StyledPage goBackToLink="/users" goBackToLinkText="all users" pageKey="new-user" >
        <StyledPageContentInner card={false}>
          {this.state.newUserID && <RedirectWithPrevState to={`/users/${this.state.newUserID}`} />}
          <StyledTopArea>
            <StyledPageHeader>Create New User</StyledPageHeader>
          </StyledTopArea>
          <UserForm userOrganisationID={this.props.userOrganisationID} ignoreRedirectPrompt={!!this.state.newUserID} mutationType='add' submitCallback={this._submitCallback} />
        </StyledPageContentInner>
      </StyledPage>
    );
  }
}

NewUserPage.propTypes = {
  userID: PropTypes.number,
  match: PropTypes.object.isRequired,
  userOrganisationID: PropTypes.number.isRequired,
};

export default withUser(NewUserPage);