import { useCallback, useEffect, useState } from 'react';

export const useInitialisePlayer = (embedRef, simulationData) => {
  // random string as hash, ref: https://stackoverflow.com/a/8084248
  const [Hash] = useState((Math.random() + 1).toString(36).substring(4));

  const sendSimData = useCallback(() => {
    if (embedRef?.contentWindow?.postMessage && simulationData) {
      embedRef.contentWindow.postMessage({
        event: 'initialise',
        Hash,
        simulationData,
      });
    }
  }, [Hash, embedRef, simulationData]);

  useEffect(() => {
    // send data when iframe signals it's ready
    const handleReady = (event) => {
      const { Hash: EventHash, event: eventName } = event.data;

      if (EventHash !== Hash) {
        return;
      }
      if (eventName === 'ready') {
        sendSimData();
      }
    };

    window.addEventListener('message', handleReady, false);

    // send data as data changes
    sendSimData();

    return () => {
      window.removeEventListener('message', handleReady, false);
    };
  }, [sendSimData, Hash]);

  return { Hash };
};
