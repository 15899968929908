import {
  LoadingPlaceholder,
  MockIcon,
  MockImage,
  MockText,
} from '@virtidev/toolbox';

export const LoadingPreview = () => {
  return (
    <LoadingPlaceholder>
      <MockImage />
      <MockText />
      <MockIcon />
    </LoadingPlaceholder>
  );
};

export default LoadingPreview;
