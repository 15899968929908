import { NativeTypes } from 'react-dnd-html5-backend';
import { useDrop } from 'react-dnd';

import styled from 'styled-components';

const StyledDropArea = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--accordion-border-radius);

  ${(props) =>
    props.isActive ? 'background: var(--neutral-color-lighter)' : ''}
`;

export const TargetBox = (props) => {
  const { onDrop } = props;
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item) {
        onDrop(item);
      },
      canDrop() {
        return true;
      },
      hover() {},
      collect: (monitor) => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [props]
  );
  const isActive = canDrop && isOver;

  return (
    <StyledDropArea ref={drop} isActive={isActive}>
      {isActive ? 'Release to drop' : props.children}
    </StyledDropArea>
  );
};
