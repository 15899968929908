import _ from 'lodash';
import { useEffect, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useMounted } from '../../../../utility/CustomHooks';

export const useAutosave = (formMethods, onUpdate, setLoading) => {
  // intended for use only with react-hook-form
  const { watch, formState, handleSubmit: submit, reset } = formMethods;
  const mounted = useMounted();
  const loader = useRef(setLoading);
  const watchRef = useRef(watch);
  watchRef.current = watch;

  loader.current = setLoading || (() => null);

  const handleUpdate = useDebouncedCallback(
    submit(async (values) => {
      if (mounted.current) {
        reset(values, { keepValues: true });
      }
      await onUpdate(values);

      if (mounted.current) {
        loader.current(false);
      }
    }),
    1000
  );
  const updateRef = useRef(handleUpdate);
  updateRef.current = handleUpdate;

  // trigger save if change is made
  useEffect(() => {
    if (formState.isDirty) {
      loader.current(true);

      const subscription = watchRef.current(() => {
        updateRef.current();
      });

      updateRef.current();

      return () => {
        loader.current(false);

        subscription.unsubscribe();
      };
    }
  }, [formState.isDirty]);

  // action the save if unmounting
  useEffect(() => {
    return () => {
      loader.current(false);

      handleUpdate.flush();
    };
  }, [handleUpdate]);
};

export default useAutosave;
