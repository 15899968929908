import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
  useContext,
} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import LoadingPageIndicator from '../components/LoadingPageIndicator';
import PageLoadError from '../components/PageLoadError';
import { IsMobile, WeSupportUnity } from '../utility/BrowserCheck';
import _ from 'lodash';
import FeedbackFormEmbed from '../apps/core/src/components/FeedbackFormEmbed/FeedbackFormEmbed';
import { Prompt, Redirect, useLocation, withRouter } from 'react-router-dom';
import StyledPageHeader from '../styled-components/StyledPageHeader';
import { StyledTopArea } from '../components/StyledPage';
import StyledLink from '../styled-components/StyledLink';
import UnitCard from './UnitCard';
import { Button } from './buttons/Button';
import { Button as ToolboxButton } from '@virtidev/toolbox';
import useStateUrl from '../apps/core/src/helpers/useStateUrl';
import SimulationPlayer from '../apps/core/src/components/Unity/components/SimulationPlayer';
import {
  CREATE_ANALYTICS_EVENT,
  READ_LEARNER_COURSE,
  READ_SIMULATION_FOR_UNITY,
  READ_STUDENT_SIMULATION,
  READ_UNIT_MODULE,
} from '../queries/LearnerQueries';
import tracker from '../apps/core/src/helpers/tracker';
import { deepLinkValueContext } from '../apps/core/src/components/DeepLink/DeepLinkProvider';
import useUser from '../apps/core/src/helpers/useUser';
import { generateDeepLink } from '../apps/core/src/components/DeepLink/helpers/generateDeepLink';
import { SimulationPlayerManager } from '../apps/core/src/components/Unity/Unity.types';

const StyledMessage = styled.div`
  padding: 2rem;
  text-align: center;
  line-height: 1.5rem;
`;

const ContentArea = styled.div`
  flex: 1;
`;

const StyledButton = styled(Button)`
  padding: 0;
`;

const WebPlayerWrapper = (props) => {
  // save which stage we're on 'before', 'sim', 'after' or 'complete'
  const [stage, setStage] = useState(null);
  const sendExitSimData = useRef({ complete: false });
  const client = useApolloClient();
  const [key, setKey] = useState(2);
  const [manager, setManager] = useState(
    /** @type {SimulationPlayerManager} */ (null)
  );
  const deepLink = useContext(deepLinkValueContext);
  const { pathname } = useLocation();
  const { ID, OrganisationID } = useUser();
  const {
    onShowSim,
    simulationID,
    preview,
    welcomeVideo,
    unitID,
    trackerProperties,
    onSimulationComplete,
    sharetoken,
  } = props;

  const { value: querySimID, updateValue: updateSimID } = useStateUrl({
    replace: true,
    key: 'simID',
  });
  const { value: queryUnitID, updateValue: updateUnitID } = useStateUrl({
    replace: true,
    key: 'unitID',
  });
  const courseID = _.get(props, 'match.params.courseID');

  const hasParam =
    querySimID !== '' &&
    Number(querySimID) === Number(simulationID) &&
    (!unitID || (queryUnitID !== '' && Number(queryUnitID) === Number(unitID)));

  sendExitSimData.current.stage = stage;
  sendExitSimData.current.preview = preview;

  const goToLink = useCallback(() => {
    window.onbeforeunload = null;
    if (deepLink) {
      window.location = deepLink;
    } else {
      window.location = generateDeepLink(pathname, OrganisationID);
    }
  }, [deepLink, pathname, OrganisationID]);

  const {
    loading: loadingUnity,
    error: errorUnity,
    data: dataUnity,
  } = useQuery(READ_SIMULATION_FOR_UNITY, {
    variables: {
      sharetokenHeader: sharetoken,
      filter: { ID: { eq: simulationID } },
    },
  });

  const { loading, error, data } = useQuery(READ_STUDENT_SIMULATION, {
    fetchPolicy: 'cache-and-network',
    variables: {
      sharetokenHeader: sharetoken,
      ID: simulationID,
    },
  });
  const [loadCourse] = useLazyQuery(READ_LEARNER_COURSE, {
    variables: {
      ID: courseID,
    },
    // forces the course data to refresh
    fetchPolicy: 'network-only',
  });

  const { data: dataUnit, loading: loadingUnit } = useQuery(READ_UNIT_MODULE, {
    skip: !unitID,
    variables: { ID: unitID },
  });

  const [sendAnalytics] = useMutation(CREATE_ANALYTICS_EVENT);

  useEffect(() => {
    if (hasParam) {
      return;
    }

    updateSimID(dataUnity?.readOneSimulation?.ID);
    if (unitID) {
      updateUnitID(unitID);
    }
  }, [unitID, dataUnity, hasParam, updateSimID, updateUnitID]);

  // resets the stage if unit or simulation id changes but component is still loaded
  useEffect(() => {
    setStage(null);
  }, [unitID, simulationID]);

  const onExitSimData = useCallback(
    (data) => {
      sendExitSimData.current.send = () => {
        console.log('Exit data', data);
        sendAnalytics({ variables: { Input: JSON.parse(data) } });
      };
    },
    [sendAnalytics]
  );
  const onComplete = useCallback(() => {
    sendExitSimData.current.complete = true;
    sendExitSimData.current.send = null;
    tracker.track('simulation_completed', {
      ...trackerProperties,
      simulation_id: simulationID,
    });
  }, [simulationID, trackerProperties]);

  const callSendAnalytics = useCallback(() => {
    if (
      sendExitSimData.current.stage === 'sim' &&
      !sendExitSimData.current.complete &&
      sendExitSimData.current.send &&
      !sendExitSimData.current.preview
    ) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      sendExitSimData.current.send();
    }
  }, []);

  const onPlayerLoaded = useCallback(() => {
    const id = tracker.get_distinct_id();
    tracker.track('simulation_loaded', {
      user_id: ID,
      simulation_id: simulationID,
    });
    if (manager) {
      manager.setMixpanelId(id);
    }
  }, [manager, ID, simulationID]);

  useEffect(() => {
    return () => callSendAnalytics();
  }, [callSendAnalytics]);

  const forms = useMemo(() => {
    const beforeForm = _.get(data, 'readOneSimulation.FeedbackFormBefore');
    const afterForm = _.get(data, 'readOneSimulation.FeedbackFormAfter');

    const shouldShowBefore =
      beforeForm?.AllowMultipleSubmissions ||
      !data?.readOneSimulation.FeedbackFormBeforeCompleted;

    const shouldShowAfter =
      afterForm?.AllowMultipleSubmissions ||
      !data?.readOneSimulation.FeedbackFormAfterCompleted;

    return {
      beforeForm: shouldShowBefore && beforeForm,
      afterForm: shouldShowAfter && afterForm,
    };
  }, [data]);

  useEffect(() => {
    if (stage === 'sim') {
      onShowSim(true);
      return;
    }
    onShowSim(false);
  }, [stage, onShowSim]);

  useEffect(() => {
    const beforeSim = forms.beforeForm;
    const afterSim = forms.afterForm;
    if (!stage) {
      if (beforeSim) {
        setStage('before');
      } else {
        setStage('sim');
      }
    }
    if (!beforeSim && stage === 'before') {
      setStage('sim');
    }
    if (!afterSim && stage === 'after') {
      setStage('complete');
    }

    if (stage === 'complete') {
      client.cache.evict({ id: `Course:${courseID}` });
      window.onbeforeunload = null;
    } else if (!preview && hasParam) {
      window.onbeforeunload = () => {
        callSendAnalytics();
        return 'Simulation in progress, are you sure about leaving?';
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [
    stage,
    data,
    callSendAnalytics,
    client,
    courseID,
    hasParam,
    preview,
    forms,
  ]);

  const completeRef = useRef();
  completeRef.current = {
    courseID,
    loadCourse,
  };

  const onSimulationEnd = useCallback(() => {
    // refreshes course completed states, so this unit is marked completed
    if (completeRef.current.courseID) {
      completeRef.current.loadCourse();
    }
    document.exitFullscreen().catch(() => null);
    if (onSimulationComplete) {
      onSimulationComplete();
    }
    setStage('after');
  }, [onSimulationComplete]);

  const onBeforeFormComplete = useCallback(() => {
    setStage('sim');
  }, []);

  const onAfterFormComplete = useCallback(() => {
    setStage('complete');
  }, []);

  const restartSim = useCallback(() => {
    setStage('before');
    setKey(key + 1);
  }, [key, setStage]);

  if (loadingUnit || loadingUnity || loading) {
    return <LoadingPageIndicator />;
  }
  if (errorUnity || error) {
    return <PageLoadError graphQLErrorObj={errorUnity || error} />;
  }
  if (!WeSupportUnity() || IsMobile()) {
    return (
      <StyledMessage>
        Unfortunately, web preview is currently only available in latest version
        of Google Chrome, Mozilla Firefox, and Microsoft Edge on a computer.
        <ToolboxButton color="primary" onClick={goToLink}>
          Use the Virti App
        </ToolboxButton>
      </StyledMessage>
    );
  }

  let content = null;

  if (stage === 'complete') {
    window.onbeforeunload = null;

    const units = _.get(dataUnit, 'readOneUnit.Module.Units.edges', []).map(
      ({ node }) => node
    );

    const unitIndex = units.findIndex(
      ({ ID }) => String(ID) === String(unitID)
    );

    const nextUnit = units[unitIndex + 1];
    if (unitIndex > -1 && nextUnit) {
      return (
        <ContentArea>
          <StyledTopArea>
            <StyledPageHeader>Congratulations!</StyledPageHeader>
          </StyledTopArea>
          <StyledMessage>
            <p>
              You have completed the training, go to the next content in this
              module.
            </p>
            <UnitCard unit={nextUnit} courseID={courseID} />
          </StyledMessage>
        </ContentArea>
      );
    } else if (courseID) {
      return <Redirect to={`/my-courses/${courseID}`} />;
    } else {
      content = (
        <ContentArea>
          <StyledTopArea>
            <StyledPageHeader>Congratulations!</StyledPageHeader>
          </StyledTopArea>
          <StyledMessage>
            {preview ? (
              <>
                The simulation is complete,{' '}
                <StyledButton type="link" onClick={restartSim}>
                  click here to restart
                </StyledButton>
                .
              </>
            ) : (
              <>
                Your video has finished, go back to your{' '}
                <StyledLink to="/my-simulations">
                  simulations list here
                </StyledLink>
                .
              </>
            )}
          </StyledMessage>
        </ContentArea>
      );
    }
  }

  if (stage === 'before') {
    const form = forms.beforeForm;

    // just in case this the state is incorrect
    if (form) {
      content = (
        <FeedbackFormEmbed
          hash={form.Hash}
          formID={form.ID}
          onComplete={onBeforeFormComplete}
          trigger="BEGIN_SIM"
          sourceID={simulationID}
        />
      );
    }
  }

  if (stage === 'after') {
    const form = forms.afterForm;

    // just in case this the state is incorrect
    if (form) {
      content = (
        <FeedbackFormEmbed
          hash={form.Hash}
          formID={form.ID}
          onComplete={onAfterFormComplete}
          trigger="END_SIM"
          sourceID={simulationID}
        />
      );
    }
  }

  if (!hasParam) {
    return null;
  }

  return (
    <>
      <Prompt
        when={stage !== 'complete' && !preview && !welcomeVideo}
        message="Simulation in progress, are you sure about leaving?"
      />
      {content}
      <SimulationPlayer
        key={key}
        show={stage === 'sim'}
        onEnd={onSimulationEnd}
        onComplete={onComplete}
        onExitSimData={onExitSimData}
        onPlayerLoaded={onPlayerLoaded}
        simulationData={dataUnity.readOneSimulation}
        setManager={setManager}
        unitID={unitID}
        sharetoken={sharetoken}
      />
    </>
  );
};

WebPlayerWrapper.propTypes = {
  simulationID: PropTypes.string.isRequired,
  unitID: PropTypes.string,
  onSimulationComplete: PropTypes.func,
};

WebPlayerWrapper.defaultProps = {
  onShowSim: () => null,
  trackerProperties: {},
};

export default withRouter(WebPlayerWrapper);
