import styled, { css } from 'styled-components';

export const NavMenuContainer = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 100%;
  margin: 0 0.8rem;
`;

export const NavMenuButton = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  color: var(--sidebar-link-text-color);
  &:hover {
    color: var(--primary-button-bg-color-hover);
  }
  ${({ open }) =>
    open &&
    css`
      color: var(--primary-button-bg-color-hover);
    `}
`;

export const NavMenuContent = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  padding: 0.25rem 0;
  min-width: 230px;
  border-radius: var(--card-border-radius);
  border-top-right-radius: 0;
  background: var(--card-bg-color);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  z-index: 1005;
  transform: scale(1.15) translate(-6%, 8%);

  ${({ open }) =>
    !open &&
    css`
      display: none;
    `}
`;
