import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';

import ForgotPasswordForm from '../forms/ForgotPasswordForm';
import FlashMessageHolder from '../components/FlashMessageHolder';
import { FORGOT_PASSWORD } from '../queries/PasswordQueries';

class ForgotPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sending: false,
      sent: false,
    };
    this.forgotPassMutate = null;
  }

  _submitForgotPass = (email) => {
    if (!this.forgotPassMutate) return;
    this.setState({
      sending: true,
    });
    this.forgotPassMutate({ variables: { Email: email } });
  };

  _sentEmail = () => {
    this.setState({
      sending: false,
      sent: true,
    });
  };

  render() {
    return (
      <div>
        <FlashMessageHolder />
        <Mutation mutation={FORGOT_PASSWORD} onCompleted={this._sentEmail}>
          {(forgotPassMutate, { data }) => {
            this.forgotPassMutate = forgotPassMutate;
            return (
              <ForgotPasswordForm
                sending={this.state.sending}
                submitCallback={this._submitForgotPass}
                sent={this.state.sent}
              />
            );
          }}
        </Mutation>
      </div>
    );
  }
}

ForgotPasswordPage.propTypes = {
  userID: PropTypes.number,
  match: PropTypes.object.isRequired,
};

export default ForgotPasswordPage;
