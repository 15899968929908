import React, { useState, useRef, useCallback, FC, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  StyledPageListHeader,
  StyledPageListItem,
  StyledData,
  DataLabelledThumb,
} from '../styled-components/StyledPageList';
import CreateSimulationFromMediaButtonInMenu from './buttons/CreateSimulationFromMediaButtonInMenu';
import TranscodingIcon from './icons/TranscodingIcon';
import EditMediaTitleButtonInMenu from './buttons/EditMediaTitleButtonInMenu';
import DeleteMediaButtonInMenu from './buttons/DeleteMediaButtonInMenu';
import FilesListTypeButton from './FilesListTypeButton';
import SubtitleButton from '../apps/core/src/components/Videos/Subtitles/SubtitleButton/SubtitleButton';
import SubtitleForm from '../apps/core/src/components/Videos/Subtitles/SubtitleForm/SubtitleForm';
import {
  Datetime,
  FALLBACK,
  Icon,
  LoadingFlash,
  MockText,
  Modal,
  Tooltip,
} from '@virtidev/toolbox';
import EditMediaTitleModalForm from './buttons/EditMediaTitleModalForm';
import { MediaTooltipTags } from '@core/components/Media/MediaList.styled';

/**
 * @typedef {import('../apps/core/src/models/video-media.types').VideoMedia} VideoMedia
 */

const NoResults = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 500px;
  padding: 1rem;
`;

const LoadingText = styled(MockText)`
  margin: 0;
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > :first-child,
  > :last-child {
    border-radius: 0;
  }
`;

const StyledHeaders = styled.div`
  display: grid;
  grid-template-columns: 5fr repeat(6, minmax(10%, 40px));
  gap: 0.5rem;
  text-transform: uppercase;
  padding: 1rem;
`;

const StyledFile = styled(StyledPageListItem)`
  .body {
    grid-template-columns: 5fr repeat(6, minmax(10%, 40px));
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    ${
      /**
       * @param {{ $disabled?: boolean }} p
       */
      ({ $disabled }) =>
        $disabled &&
        css`
          pointer-events: none;
          filter: grayscale(1);
        `
    }
  }
`;

const StyledVideo = styled.video`
  align-self: center;
  width: 100%;
  max-width: 90vw;
`;

const StyledTextPlayButton = styled.button`
  background: none;
  border: none;
  font-size: inherit;
  font-weight: inherit;
  cursor: pointer;
  text-align: left;

  &:hover {
    text-decoration: underline;
  }
`;

/**
 * @type {FC<{
 *    files: VideoMedia[],
 *    loading: boolean
 * }>}
 */
const FilesListDetailed = ({ files, loading }) => {
  const [visibleVideoModal, setVisibleVideoModal] = useState(false);
  const [showSubtitles, setShowSubtitles] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(
    /** @type {VideoMedia | null} */ (null)
  );
  const videoRef = useRef(/** @type {HTMLVideoElement | null} */ (null));
  const [editVideo, setEditVideo] = useState(
    /** @type {string | null} */ (null)
  );
  const [deleteVideos, setDeleteVideos] = useState(
    /** @type {string[]} */ ([])
  );

  const videoUrl = useMemo(
    () => currentVideo?.EditorURL || currentVideo?.SourceURL,
    [currentVideo]
  );

  const openSubtitleModal = useCallback((video) => {
    setCurrentVideo(video);
    setShowSubtitles(true);
  }, []);

  const closeSubtitleModal = useCallback(() => {
    setCurrentVideo(null);
    setShowSubtitles(false);
  }, []);

  const addDeleteVideos = useCallback((id) => {
    setDeleteVideos((items) => [...items, id]);
  }, []);

  return (
    <div data-testid="files-videos-list">
      {!!editVideo && (
        <EditMediaTitleModalForm
          hideModal={() => setEditVideo(null)}
          modalVisible
          file={files.find(({ ID }) => ID === editVideo)}
          type="video"
        />
      )}
      <Modal
        title={currentVideo ? currentVideo.Title : ''}
        show={visibleVideoModal && !!currentVideo}
        onHide={() => {
          setVisibleVideoModal(false);
        }}
        render={() => (
          <StyledVideo autoPlay controls key={videoUrl}>
            <source src={videoUrl} type="video/mp4" />
          </StyledVideo>
        )}
      />
      <Modal
        title="Edit subtitles"
        show={showSubtitles && !!currentVideo}
        onHide={closeSubtitleModal}
        render={() => currentVideo && <SubtitleForm video={currentVideo} />}
      />
      {(files.length > 0 || loading) && (
        <StyledHeaders>
          <StyledPageListHeader align="left">Video name</StyledPageListHeader>
          <StyledPageListHeader>Tags</StyledPageListHeader>
          <StyledPageListHeader>Status</StyledPageListHeader>
          <StyledPageListHeader>Type</StyledPageListHeader>
          <StyledPageListHeader>Subtitles</StyledPageListHeader>
          <StyledPageListHeader>Created on</StyledPageListHeader>
          <StyledPageListHeader>Action</StyledPageListHeader>
        </StyledHeaders>
      )}
      {loading &&
        [...Array(20)].map((_, index) => (
          <StyledFile key={index + 1} $disabled>
            <DataLabelledThumb fallback isVideo src={FALLBACK}>
              <LoadingText />
              <LoadingFlash />
            </DataLabelledThumb>
            <StyledData></StyledData>
            <StyledData>
              <TranscodingIcon transcodingStatus="None" />
            </StyledData>
            <StyledData bold>
              <FilesListTypeButton />
            </StyledData>
            <StyledData>
              <SubtitleButton />
            </StyledData>
            <StyledData $center bold>
              <LoadingText />
              <LoadingFlash />
            </StyledData>
            <StyledData>
              <Icon icon="dots" />
            </StyledData>
          </StyledFile>
        ))}
      {files.length > 0 &&
        files.map((file) => {
          if (deleteVideos.includes(file.ID)) {
            return null;
          }
          return (
            <StyledFile key={file.ID}>
              <StyledTextPlayButton
                onClick={() => {
                  setCurrentVideo(file);
                  setVisibleVideoModal(true);
                }}
              >
                <DataLabelledThumb isVideo src={file.PosterURL}>
                  {file.Title}
                </DataLabelledThumb>
              </StyledTextPlayButton>
              <MediaTooltipTags
                cardType="video"
                targetId={file.ID}
                tags={file.Tags.nodes}
                editTags
              />
              <StyledData bold={true}>
                <TranscodingIcon transcodingStatus={file.TranscodingStatus} />
              </StyledData>
              <StyledData bold={true}>
                <FilesListTypeButton file={file} type="video" />
              </StyledData>
              <StyledData>
                <SubtitleButton onOpen={openSubtitleModal} video={file} />
              </StyledData>
              <StyledData $center bold={true} title={file.Created}>
                <Datetime
                  datetime={file.Created}
                  long
                  noTimeDisplay
                  emptyDisplay="-"
                />
              </StyledData>
              <StyledData>
                <Tooltip
                  type="menu"
                  icon="dots"
                  placement="bottom-end"
                  autoClose
                >
                  <MenuWrapper>
                    <EditMediaTitleButtonInMenu
                      ID={file.ID}
                      onEdit={setEditVideo}
                      type="video"
                    />
                    <CreateSimulationFromMediaButtonInMenu
                      ID={file.ID}
                      type="video"
                    />
                    <DeleteMediaButtonInMenu
                      file={file}
                      onDelete={addDeleteVideos}
                      type="video"
                    />
                  </MenuWrapper>
                </Tooltip>
              </StyledData>
            </StyledFile>
          );
        })}
      {!files.length && !loading && <NoResults>No files found.</NoResults>}
    </div>
  );
};

FilesListDetailed.propTypes = {
  files: PropTypes.arrayOf(
    /** @type {PropTypes.Validator<VideoMedia>} */ (PropTypes.object)
  ).isRequired,
};

export default FilesListDetailed;
