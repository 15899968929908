import { Modal } from '@virtidev/toolbox';
import styled, { css } from 'styled-components';

export const Description = styled.span`
  ${({ theme: { color } }) => css`
    color: ${color.midnight10};
  `}
`;

export const ShareModal = styled(Modal)`
  .modal-box {
    max-width: 700px;
  }
`;
