import { gql } from '@apollo/client';

export const READ_MEMBERS = gql`
  query readUserTable(
    $limit: Int!
    $offset: Int!
    $filter: MemberFilterFields
    $OrganisationID: ID
    $sort: MemberSortFields
    $Name: String
  ) {
    readMembers(
      offset: $offset
      limit: $limit
      filter: $filter
      sort: $sort
      Name: $Name
    ) {
      pageInfo {
        totalCount
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ID
          Name
          Email
          UserType
          LastSeen
          Active
          Disabled
          Created
          Organisations {
            nodes {
              ID
              Name
            }
          }
          OrganisationGroups(
            filter: { Organisation: { ID: { eq: $OrganisationID } } }
          ) {
            edges {
              node {
                ID
                Name
              }
            }
          }
        }
      }
    }
  }
`;
