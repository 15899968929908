import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import useStateUrl from '../../helpers/useStateUrl';
import useUser from '../../helpers/useUser';
import {
  CardListContainer,
  Content,
  ContentBody,
  EmptyCard,
  PagesContainer,
} from '@core/components/CardList/CardList.styled';
import { Actions } from './VirtualHumansList.styled';
import PaginationControl from '../PaginationControl';
import { usePaginationUrl } from '../PaginationControl/helpers/usePaginationUrl';
import ActionList from './components/ActionList/ActionList';
import VirtualHumanDataCard from './components/VirtualHumanDataCard/VirtualHumanDataCard';
import { READ_VIRTUAL_HUMANS } from './VirtualHumansList.query';
import LoadingCourseCard from '../DataListCard/Loading/LoadingCourseCard';
import useGetVHCounts from '../../../../../components/VirtualHumanCreationWizard/useGetVHCounts';
import { usePageScroll } from '../Page';

export const VirtualHumansList = (props) => {
  const { OrganisationID } = useUser();
  const { scrollTop } = usePageScroll();

  const { UserType, ID } = useUser();

  const { page, pageSize, controlProps } = usePaginationUrl({
    onChange: scrollTop,
    pageSize: props.pageSize || 12,
  });
  const { value: filter } = useStateUrl({});
  const { value: group } = useStateUrl({
    key: 'group',
  });

  const { value: status } = useStateUrl({
    key: 'status',
  });

  let { value: assignment } = useStateUrl({
    key: 'assignment',
  });

  const { value: tags } = useStateUrl({
    key: 'tag',
  });

  const contentCreatorFilters = (() => {
    if (UserType !== 'contentcreator') {
      return {};
    }
    if (assignment === 'created') {
      return { Author: { ID: { eq: ID } } };
    }
    if (assignment === 'assigned') {
      return { Editors: { ID: { eq: ID } } };
    }
    return { EditorsOrAuthorID: { eq: ID } };
  })();

  const variables = useMemo(() => {
    return {
      filterBy: {
        ...(OrganisationID
          ? { Organisation: { ID: { eq: OrganisationID } } }
          : {}),
        ...(group ? { OrganisationGroups: { ID: { eq: group } } } : {}),
        ...(status ? { Status: { eq: status } } : {}),
        ...contentCreatorFilters,
        TitleOrAdminTitle: { contains: filter ?? '' },
        ...(tags
          ? {
              Tags: {
                ID: {
                  in: tags.split(',').map((id) => (id === '0' ? null : id)),
                },
              },
            }
          : {}),
      },
      limit: pageSize,
      offset: (page - 1) * pageSize,
    };
  }, [
    OrganisationID,
    group,
    status,
    contentCreatorFilters,
    filter,
    pageSize,
    page,
    tags,
  ]);

  const { data, loading } = useQuery(READ_VIRTUAL_HUMANS, {
    variables,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const virtualHumans = useMemo(() => {
    if (!data?.readVirtualHumans?.edges) {
      return [...Array(pageSize)];
    }
    return data.readVirtualHumans.edges.map(({ node }) => node);
  }, [data, pageSize]);

  const filterExists = useMemo(() => {
    if (filter || group || status || tags) {
      return true;
    }
  }, [filter, group, status, tags]);

  const {
    error,
    isLoading: loadingVHCountData,
    canMakeBranching,
    canMakeFreeform,
    canMakeFreeformV3,
  } = useGetVHCounts();

  return (
    <Content>
      {!props.hideActions && (
        <Actions>
          <ActionList loading={loading} />
        </Actions>
      )}
      {!loading && !virtualHumans.length && (
        <EmptyCard>
          {filterExists
            ? `No virtual human found – try adjusting your search!`
            : 'Create your first virtual human!'}
        </EmptyCard>
      )}
      <ContentBody>
        <CardListContainer>
          {virtualHumans.map((virtualHuman, index) => {
            if (loading || !virtualHuman) {
              return <LoadingCourseCard key={index} />;
            }
            let canDuplicate = false;
            const ready = !loadingVHCountData && !error;
            switch (virtualHuman.Type) {
              case 'branching':
                canDuplicate = ready && canMakeBranching;
                break;
              case 'default':
              case 'medical':
                canDuplicate = ready && canMakeFreeform;
                break;
              case 'freeform_v3':
                canDuplicate = ready && canMakeFreeformV3;
                break;
              default:
                break;
            }

            return (
              <VirtualHumanDataCard
                key={virtualHuman.ID}
                virtualHuman={virtualHuman}
                canDuplicate={canDuplicate}
                editTags
              />
            );
          })}
        </CardListContainer>
      </ContentBody>
      <PagesContainer>
        <PaginationControl
          {...controlProps}
          total={data?.readVirtualHumans?.pageInfo?.totalCount || 0}
        />
      </PagesContainer>
    </Content>
  );
};

export default VirtualHumansList;
