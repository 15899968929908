import VHQuery from '@base/utility/VHHooks/VHQuery';
import { useCallback, useState } from 'react';

/**
 * @type {() => [
 *      (externalID: number, localeCode: string) => void,
 *      {isLoading: boolean; error: any}
 *    ]
 * }
 */
export const useUpdateVHLocaleMutation = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  /** @type {(externalID: number, localeCode: string) => void} */
  const update = useCallback(async (externalID, localeCode) => {
    setIsLoading(true);
    try {
      const result = await VHQuery(
        `virtual-humans/${externalID}/locale`,
        'PATCH',
        { localeCode }
      );
      return result;
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }, []);
  return [update, { isLoading, error }];
};
