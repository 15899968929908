import { TextInput } from '@virtidev/toolbox';
import styled from 'styled-components';

export const Searchbox = styled(TextInput)`
  && {
    min-width: 220px;
  }
`;

// z index of the menu went under the corner controls of the cards
// is there a better way to do this?
export const StyledSelectWrapper = styled.div`
  width: 280px;
  z-index: 1002;
`;

export const StyledCreateWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.p3};
  flex-wrap: wrap;
  justify-content: flex-end;
`;
