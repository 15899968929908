import { useCallback, useMemo } from 'react';
import { SelectGroup } from '../../../form/Select';
import { useController } from 'react-hook-form';

export const CourseAccessGroup = ({ name, loading }) => {
  const {
    field: { value: groups, onChange: onFormChange },
  } = useController({ name });

  const selected = useMemo(() => {
    if (!groups || !groups.length) {
      if (loading) {
        return [];
      }
      return [{ label: 'Everyone', value: null, isFixed: true }];
    }
    // map to select structure
    return groups.map(({ ID, Name }) => ({
      value: ID,
      label: Name,
    }));
  }, [groups, loading]);

  const handleChange = useCallback(
    (newGroups) => {
      onFormChange(
        newGroups
          // ignore "Everyone" option
          .filter(({ value }) => value != null)
          // re-map back to database structure
          .map(({ value, label }) => ({ ID: value, Name: label }))
      );
    },
    [onFormChange]
  );

  return (
    <SelectGroup
      value={selected}
      onChange={handleChange}
      loading={loading}
      placeholder=""
      multi
      menuPosition="fixed"
    />
  );
};

export default CourseAccessGroup;
