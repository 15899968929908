import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation, Query } from '@apollo/client/react/components';

import StyledTextInput from '../../styled-components/StyledTextInput';
import { ADD_GROUP, READ_GROUP_NAMES } from '../../queries/GroupQueries';
import WithFlashMessaging from '../../HOCs/WithFlashMessaging';
import withUser from '../../HOCs/WithUser';
import { StyledModalSubmitBtn } from '../../styled-components/StyledModalFormComponents';
import ValidationMessages from '../ValidationMessages';
import { Modal as ToolboxModal, Button } from '@virtidev/toolbox';
import { withRouter } from 'react-router-dom';

class GroupAddButtonAndForm extends Component {
  constructor(props) {
    super(props);
    this.nameRef = React.createRef(null);
    this.state = {
      visibleModal: false,
      groupName: '',
      errors: [],
      begunInput: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.visibleModal && this.state.visibleModal) {
      this.nameRef.current.focus();
    }
  }

  _submitForm = (e, mutate) => {
    e.preventDefault();
    this.setState({
      saving: true,
    });
    const input = {
      Name: this.state.groupName,
      OrganisationID: this.props.userOrganisationID,
    };
    const variables = { Input: input };
    mutate({
      variables,
      update: (cache) => {
        cache.evict({ fieldName: 'readVirtiGroups' });
      },
    });
  };

  _openModal = (e) => {
    e.preventDefault();
    this.setState({
      visibleModal: true,
    });
  };

  _closeModal = () => {
    this.setState({
      visibleModal: false,
    });
  };

  _onSave = (e) => {
    e.preventDefault();
  };

  _onNameChange = (e, allGroupNames) => {
    let errors = [];
    if (e.target.value === '') {
      errors.push('Please enter a group name');
    }
    if (allGroupNames.includes(e.target.value)) {
      errors.push('That group name already exists');
    }
    this.setState({
      groupName: e.target.value,
      errors,
      begunInput: true,
    });
  };

  _handleCompleted = (data) => {
    this.setState({
      saving: false,
      groupName: '',
    });
    this._closeModal();
    this.props.addFlashMessage('Successfully added new group', 'success');
  };

  render() {
    return (
      <div className={this.props.className}>
        <Button onClick={this._openModal} icon="group" color="turquoise">
          Add New User Group
        </Button>
        <ToolboxModal
          show={this.state.visibleModal}
          onHide={this._closeModal}
          render={() => (
            <Query
              query={READ_GROUP_NAMES}
              variables={{
                organisationID: this.props.userOrganisationID,
              }}
            >
              {({
                loading: groupNamesLoading,
                error: groupNamesErrors,
                data: groupNamesData,
              }) => {
                let allGroupNames = [];
                if (!groupNamesLoading && !groupNamesErrors) {
                  allGroupNames = groupNamesData.readVirtiGroups.edges.map(
                    (groupEdge) => groupEdge.node.Name
                  );
                }
                const disabledForm =
                  this.state.errors.length !== 0 || !this.state.begunInput;
                return (
                  <Mutation
                    mutation={ADD_GROUP}
                    onCompleted={this._handleCompleted}
                    onError={(e) => {
                      console.error(e);
                      this.props.addFlashMessage(
                        'Error creating group.',
                        'error'
                      );
                      this.setState({
                        saving: false,
                      });
                    }}
                  >
                    {(mutateAddGroup, { mutationData }) => (
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          if (!disabledForm) {
                            this._submitForm(e, mutateAddGroup);
                          }
                        }}
                      >
                        <StyledTextInput
                          ref={this.nameRef}
                          labelBold={false}
                          label="Group Name"
                          name="group-name"
                          value={this.state.groupName}
                          id="group-name"
                          onChange={(e) => this._onNameChange(e, allGroupNames)}
                        />
                        <ValidationMessages errors={this.state.errors} />
                        <StyledModalSubmitBtn
                          type="submit"
                          loading={this.state.saving}
                          disabled={disabledForm}
                        >
                          Add Group
                        </StyledModalSubmitBtn>
                      </form>
                    )}
                  </Mutation>
                );
              }}
            </Query>
          )}
        />
      </div>
    );
  }
}

GroupAddButtonAndForm.defaultProps = {
  buttonType: 'material-grey',
};

GroupAddButtonAndForm.propTypes = {
  groupEdges: PropTypes.array,
  addFlashMessage: PropTypes.func.isRequired,
  userOrganisationID: PropTypes.number.isRequired,
  buttonType: PropTypes.string,
};

export default withRouter(withUser(WithFlashMessaging(GroupAddButtonAndForm)));
