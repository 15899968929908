import { forwardRef, ForwardRefExoticComponent, ComponentType } from 'react';
import useFeature from '../hooks/useFeature';
import useFeaturesLoaded from '../hooks/useFeaturesLoaded';

/**
 * @param {string|string[]} featuresInput
 */
export const withFeature =
  (featuresInput) =>
  /**
   * @param {ComponentType<any>} Component
   */
  (Component) => {
    const featureList = Array.isArray(featuresInput)
      ? featuresInput
      : [featuresInput];

    /**
     * @type {ForwardRefExoticComponent<any>}
     */
    const FeatureProvider = forwardRef((props, ref) => {
      const features = useFeature(featureList);
      const featuresLoaded = useFeaturesLoaded();

      if (!featuresLoaded) {
        return null;
      }

      return <Component ref={ref} {...props} features={features} />;
    });

    return FeatureProvider;
  };

export default withFeature;
