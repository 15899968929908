import styled from 'styled-components';

export const DropArea = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--accordion-border-radius);

  ${(props) =>
    props.isActive ? 'background: var(--neutral-color-lighter)' : ''}
`;
