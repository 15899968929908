import PropTypes from 'prop-types';

export const VHTemplateNodePropType = PropTypes.shape({
  ID: PropTypes.string.isRequired,
  Title: PropTypes.string.isRequired,
  VirtualHuman: PropTypes.shape({
    ID: PropTypes.string.isRequired,
    Created: PropTypes.string,
    LastEdited: PropTypes.string,
    Title: PropTypes.string,
    Description: PropTypes.string,
    OrganisationID: PropTypes.string,
    Status: PropTypes.string,
    ThumbnailURL: PropTypes.string,
    Avatar: PropTypes.shape({
      ID: PropTypes.string,
      Name: PropTypes.string,
    }),
    Voice: PropTypes.string,
    Featured: PropTypes.bool,
    CoursesOnly: PropTypes.bool,
    ExternalID: PropTypes.number,
    Type: PropTypes.string.isRequired,
  }),
});

export const VHTemplateEdgePropType = PropTypes.shape({
  node: VHTemplateNodePropType,
});
