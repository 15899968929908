import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { itemContentCss, itemContainerCss } from '../../Sidebar.styled';

export const LinkWrapper = styled(NavLink)`
  ${itemContainerCss}
  position: relative;
`;

export const Content = styled.div`
  ${itemContentCss}
`;
