import React, { useState, useCallback, useRef, FC } from 'react';
import Select from '../../../form/Select/Select';
import { supported_languages } from '../languages';
import {
  LoadingHolder,
  SelectWrapper,
  StyledFileInput,
  StyledInputWrapper,
  UploaderSection,
} from './SubtitleUploader.styled';
import { SubmitButton } from '../Subtitles.styled';
import useUploadAndSaveSRT from '../useUploadAndSaveSRT';
import { Loading } from '@virtidev/toolbox';

/**
 * @typedef {import('../../../../models/video-media.types').VideoMedia} VideoMedia
 */

const initialLanguage = {
  label: 'Select language...',
  value: '',
};

//waiting for Lukas to put changes live
const types = [
  { label: 'Subtitles', value: 'subtitles' },
  { label: 'Closed Captioning', value: 'captions' },
];

/**
 * @type {FC<{
 *    video: VideoMedia,
 *    setLoading: (loading: boolean) => void,
 *    loading: boolean,
 * }>}
 */
export const SubtitleUploader = ({ video, setLoading, loading }) => {
  const [selectedFile, setSelectedFile] = useState(
    /** @type {File | null} */ (null)
  );
  const [language, setLanguage] = useState(initialLanguage);
  const fileRef = useRef(/** @type {HTMLInputElement | null} */ (null));
  const [type, setType] = useState(types[0]);

  const handleFileChange = useCallback((e) => {
    if (e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
  }, []);

  const handleLanguageChange = useCallback((value) => {
    setLanguage(value);
  }, []);

  const startUploadSRT = useUploadAndSaveSRT(
    video,
    language.label,
    type.value,
    {
      onError: () => {
        setLoading(false);
      },
      onCompleted: async () => {
        setLoading(false);
      },
    }
  );

  const uploadHandler = useCallback(async () => {
    setLoading(true);
    setSelectedFile(null);
    setLanguage(initialLanguage);

    if (fileRef.current) {
      fileRef.current.value = '';
    }

    startUploadSRT(selectedFile);
  }, [startUploadSRT, selectedFile, setLoading]);

  return (
    <UploaderSection>
      <LoadingHolder>{loading && <Loading size="large" />}</LoadingHolder>
      <SelectWrapper>
        <Select
          value={language}
          options={supported_languages}
          placeholder="Select language"
          onChange={handleLanguageChange}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
        />
      </SelectWrapper>
      {/* waiting for Lukas Unity approval to allow for CC types */}
      {/* <SelectWrapper>
        <Select
          value={type}
          options={types}
          loading={props.loading}
          placeholder="Select type"
          onChange={setType}
        />
      </SelectWrapper> */}
      <StyledInputWrapper $selected={!!selectedFile} title={selectedFile?.name}>
        {selectedFile ? selectedFile.name : 'Select file (.srt)'}
        <StyledFileInput
          type="file"
          onChange={handleFileChange}
          accept=".srt"
          ref={fileRef}
        />
      </StyledInputWrapper>
      <SubmitButton
        type="submit"
        disabled={!selectedFile || !language.value}
        onClick={uploadHandler}
      >
        Upload
      </SubmitButton>
    </UploaderSection>
  );
};

export default SubtitleUploader;
