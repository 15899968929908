import { BannerIcon, StyledDashboardBanner } from './DashboardBanner.styled';
import { Icon } from '@virtidev/toolbox';

export default function DashboardBanner({ children }) {
  if (children) {
    return (
      <StyledDashboardBanner>
        <BannerIcon icon="bell" />
        <p>{children}</p>
      </StyledDashboardBanner>
    );
  } else {
    return null;
  }
}
