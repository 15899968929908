import React from 'react';
import { StyledDoubleTd } from '../styled-components/StyledTable';
import { GetPercentColors } from '../utility/PercentUtility';
import { StyledPercentTag } from '../styled-components/StyledPercentTag';

export default function LicensesDataItem(props) {
  const utilisation = (activeUsers, licenses) => {
    const utilisation = parseInt(activeUsers) / licenses;
    if (!isNaN(utilisation) && isFinite(utilisation)) {
      return Math.round(utilisation * 100);
    } else {
      return 0;
    }
  };

  return (
    <StyledDoubleTd>
      {props.licenses}
      <StyledPercentTag
        color={GetPercentColors(
          utilisation(props.activeUsers, props.licenses),
          false,
          100,
          80
        )}
      >
        {utilisation(props.activeUsers, props.licenses)}%
      </StyledPercentTag>
    </StyledDoubleTd>
  );
}
