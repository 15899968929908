import { gql } from '@apollo/client';

export const READ_VOICES = gql`
  query readVoices($offset: Int, $limit: Int, $filter: VoiceFilterFields) {
    readVoices(limit: $limit, offset: $offset, filter: $filter) {
      pageInfo {
        totalCount
        hasNextPage
        hasPreviousPage
      }
      nodes {
        ID
        Name
        Code
        Service
        Locale
        Featured
        Gender
        Age
        Deprecated
      }
    }
  }
`;

export const UPDATE_VOICE = gql`
  mutation updateVoice($input: UpdateVoiceInput!) {
    updateVoice(input: $input) {
      ID
      Name
      Code
      Service
      Locale
      Featured
      Gender
      Age
      Deprecated
    }
  }
`;

export const CREATE_VOICE = gql`
  mutation createVoice($input: CreateVoiceInput!) {
    createVoice(input: $input) {
      ID
      Name
      Code
      Service
      Locale
      Featured
      Gender
      Age
      Deprecated
    }
  }
`;

export const DELETE_VOICES = gql`
  mutation deleteVoices($ids: [ID]!) {
    deleteVoices(ids: $ids)
  }
`;
