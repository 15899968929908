import React from 'react';
import CourseAction from './components/CourseAction';
import SimulationAction from './components/SimulationAction';
import VirtualHumanAction from './components/VirtualHumanAction';
import {
  QuickActionsContainer,
  StyledButtonsWrapper,
  StyledSectionHeader,
} from './QuickActions.styled';

export const QuickActions = () => {
  return (
    <QuickActionsContainer>
      <StyledSectionHeader>Quick actions</StyledSectionHeader>
      <StyledButtonsWrapper>
        <CourseAction />
        <SimulationAction />
        <VirtualHumanAction />
      </StyledButtonsWrapper>
    </QuickActionsContainer>
  );
};

export default QuickActions;
