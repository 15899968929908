import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, useLocation } from 'react-router-dom';

// use this to ensure that there's always an available reference to the previous url
const RedirectWithPrevState = (props) => {
  const location = useLocation();
  const prevPath = location?.pathname?.replace?.(/^\/+/g, '/') || '';

  return (
    <Redirect
      className={props.className}
      to={{
        pathname: props.to,
        search: props.search,
        hash: props.hash,
        state: { prevPath, ...props.state },
      }}
    >
      {props.children}
    </Redirect>
  );
};
RedirectWithPrevState.defaultProps = {
  hash: null,
  search: null,
  state: {},
};

RedirectWithPrevState.propTypes = {
  to: PropTypes.string.isRequired,
  state: PropTypes.object,
  hash: PropTypes.string,
  search: PropTypes.string,
};

export default RedirectWithPrevState;
