import React from 'react';
import { Tab, TabNav } from '../styled-components/StyledTabNav';
import useFeature from '../apps/core/src/components/LaunchDarkly/hooks/useFeature';

export const GetPageType = function () {
  return window.location.pathname.slice(14);
};

const CXDashboardTabNav = (props) => {
  const pageType = GetPageType();
  return (
    <TabNav>
      <Tab
        data-testid="cx-analytics-tab"
        isActive={pageType === 'analytics'}
        to={`/cx-dashboard`}
      >
        All Analytics
      </Tab>
      <Tab
        data-testid="cx-org-analytics-tab"
        isActive={pageType === 'organisations'}
        to={`/cx-dashboard/organisations`}
      >
        Org Mgmt
      </Tab>
      <Tab
        data-testid="cx-copy-org-data-tab"
        isActive={pageType === 'simulation-copy'}
        to={`/cx-dashboard/simulation-copy`}
      >
        Copy Sim to Org
      </Tab>
      <Tab
        data-testid="cx-copy-course-data-tab"
        isActive={pageType === 'course-copy'}
        to={`/cx-dashboard/course-copy`}
      >
        Copy Course to Org
      </Tab>
      <Tab
        data-testid="cx-copy-feedback-form-data-tab"
        isActive={pageType === 'feedback-form-copy'}
        to={`/cx-dashboard/feedback-form-copy`}
      >
        Copy Form to Org
      </Tab>
      <Tab
        data-testid="cx-copy-org-vh-tab"
        isActive={pageType === 'vh-copy'}
        to={`/cx-dashboard/vh-copy`}
      >
        Virtual Humans
      </Tab>
      <Tab
        data-testid="cx-copy-org-data-tab"
        isActive={pageType === 'user-copy'}
        to={`/cx-dashboard/user-copy`}
      >
        Add User to Org
      </Tab>
      <Tab to={`/cx-dashboard/users`} isActive={pageType === 'users'}>
        User Mgmt
      </Tab>
      <Tab
        data-testid="product-dashboard-tab"
        isActive={pageType === 'product-dashboard'}
        to={`/cx-dashboard/product-dashboard`}
      >
        Product Dashboard
      </Tab>
    </TabNav>
  );
};

CXDashboardTabNav.propTypes = {};

export default CXDashboardTabNav;
