import {
  ChatSidebarTabs,
  completeSession,
  promptDiagnosis,
} from '../../../slices/chat/ChatSlice';
import VHTypeNavigation from './VHTypeNavigation';
import VHDefaultConfig from './VHDefaultConfig';
import VHVitals from '../medical/VHVitals/VHVitals';
import VHTypes from './VHTypes';

const VHMedicalConfig = {
  ...VHDefaultConfig,
  type: VHTypes.MEDICAL,
  navigationLocations: [
    VHTypeNavigation.DETAILS,
    VHTypeNavigation.OBJECTIVES,
    VHTypeNavigation.BUILDER,
    VHTypeNavigation.ACTIONS,
    VHTypeNavigation.VITALS,
    VHTypeNavigation.STATES,
    VHTypeNavigation.DIAGNOSIS,
    VHTypeNavigation.AI_FALLBACKS,
    VHTypeNavigation.AI_GENERATOR,
    VHTypeNavigation.TRAINING,
    VHTypeNavigation.PREVIEW,
    VHTypeNavigation.COMPLETIONS,
    VHTypeNavigation.ANALYTICS,
    VHTypeNavigation.OBJECTIVES_ANALYTICS,
    VHTypeNavigation.SESSIONS,
  ],
  finishButtonText: (vhDBVh) =>
    vhDBVh?.disableDiagnosis ? 'End conversation' : 'Diagnose',
  finishPromptText: (vhDBVh) =>
    vhDBVh?.disableDiagnosis ? 'Proceed' : 'Proceed to Diagnosis',
  endsConversationPromptText: (_vhDBVh) =>
    "You've completed the conversation. Would you like to end the session and diagnose the patient?",
  postObjectivesCompletePromptText: (vhDBVh) =>
    `You've completed all the objectives! Would you like to end the session${
      vhDBVh?.disableDiagnosis ? '' : ' and diagnose the patient'
    }?`,
  finishWarningText: (vhDBVh) =>
    vhDBVh?.disableDiagnosis
      ? 'You will not be able to ask any more questions if you proceed. You will be able to scroll through your conversation and view the results.'
      : 'You will not be able to ask any more questions if you proceed with the diagnosis. You will be able to scroll through your conversation and view the examination and investigation results.',
  onFinishSession: (dispatch, vhDBVh) => {
    if (vhDBVh?.disableDiagnosis) {
      dispatch(
        completeSession({
          patientId: vhDBVh.id,
        })
      );
    } else {
      dispatch(promptDiagnosis());
    }
  },
  overlayComponent: () => <VHVitals />,
  chatSidebarTabs: {
    VITALS: ChatSidebarTabs.VITALS,
    CHAT: ChatSidebarTabs.CHAT,
    ACTIONS: ChatSidebarTabs.ACTIONS,
    RESULTS: ChatSidebarTabs.RESULTS,
  },
  actionIcon: 'stethoscope',
  sidebarVisibleAtStart: true,
  usesCondition: true,
  usesDialogflow: true,
};
export default VHMedicalConfig;
