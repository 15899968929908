import styled from 'styled-components';

export const EggContainer = styled.div`
  position: fixed;
  top: 0;
  left: ${({ sidebarContracted }) => (sidebarContracted ? 0 : '10px')};
  transition: left var(--sidebar-anim-duration);
  pointer-events: none;
  z-index: 99999;
  width: 75px;
`;
