import { Icon } from '@virtidev/toolbox';

import React from 'react';
import { StyledTab } from './Tab.styled';

export default function Tab({ isActive, children, ...props }) {
  // added div and style as styled component was not passing styles to children
  return (
    <StyledTab $isActive={isActive} {...props}>
      {children}
    </StyledTab>
  );
}
