import {
  RegisterFormContainer,
  RegisterFormLogo,
} from '@core/components/Signup/RegisterForm/RegisterForm.styled';
import { Button } from '@virtidev/toolbox';
import styled, { css } from 'styled-components';

export const RegisterDemoContainer = styled(RegisterFormContainer)`
  ${({ theme }) => css`
    padding: ${theme.spacing.lg} 0 ${theme.spacing.md};
  `}
`;

export const RegisterDemoLogo = styled(RegisterFormLogo)`
  ${({ theme }) => css`
    margin: ${theme.spacing.sm} auto ${theme.spacing.lg} calc(10%);
  `}
`;

export const RegisterDemoText = styled.p`
  text-align: center;
`;

export const VideoWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.md};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
  `}
`;

export const ErrorMessage = styled.div``;

export const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: ${theme.spacing.md};
  `}
`;

export const SkipButton = styled(Button).attrs({
  color: 'primary',
})`
  ${({ theme }) => css`
    background: ${theme.color.lightGrey};
    color: ${theme.color.contrast.lightGrey};
  `}
`;
