import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1008;

  margin: 0 auto;

  background: #fff;
  border-bottom: 1px solid #aaa;

  @media (min-width: 601px) {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    border-left: 1px solid #aaa;
    border-right: 1px solid #aaa;
  }

  max-width: 600px;

  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 12px;
  gap: 10px;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
`;

export const Title = styled.div`
  font-size: 1.2rem;
`;

export const Subtext = styled.div`
  color: #aaa;
  font-size: 0.9rem;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  color: var(--secondary-color, #46beaf);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 50px;
  height: 50px;
`;

export const CloseButton = styled.button`
  background: #fff;
  color: #aaa;
  cursor: pointer;
  border-radius: var(--input-border-radius, 25px);
  border: transparent;
  padding: 5px;

  &:focus {
    box-shadow: var(--input-focus-box-shadow, 0 0 5px 1px #51cbee);
  }

  &:hover {
    filter: brightness(115%);
  }

  &:active {
    filter: brightness(85%);
  }
`;
