import { useMutation } from '@apollo/client';
import { Button } from '@virtidev/toolbox';
import { useCallback, useMemo } from 'react';
import useFlashMessage from '../../FlashMessage';
import { BULK_UPDATE_MEMBERS } from './Actions.query';

const DeleteMember = ({
  confirm,
  selectedIDs,
  setListModified,
  setSelectedIDs,
  // why do we need this button prop?
  button,
}) => {
  const { addFlashMessage } = useFlashMessage();

  const SelectedButton = useMemo(() => button || Button, [button]);

  const [deleteMember] = useMutation(BULK_UPDATE_MEMBERS, {
    onError: (err) => {
      console.error(err);
      addFlashMessage(
        'Something went wrong, this user could not be suspended.',
        'error'
      );
    },
  });

  const handleDelete = useCallback(
    async (ids, title) => {
      await deleteMember({
        variables: {
          MemberIDs: [...ids],
          Disabled: true,
        },
        // the response from deleteMember is null, so can't so optimistic response
        update: (cache, result) => {
          cache.evict({ fieldName: 'readMembers' });
        },
      });
      setSelectedIDs([]);
      setListModified(true);
      addFlashMessage(
        `User${ids.length > 1 ? 's' : ''} suspended successfully!`
      );
    },
    [deleteMember, addFlashMessage, setSelectedIDs, setListModified]
  );

  const handleClick = useCallback(() => {
    confirm(
      () => {
        handleDelete(selectedIDs);
      },
      `This will suspend ${selectedIDs.length} user${
        selectedIDs.length > 1 ? 's' : ''
      }.`,
      ` A suspended user will not be able to log in and use the platform but will still show up in analytics.
       Suspended users do not count towards your license count.`,
      'delete'
    );
  }, [confirm, selectedIDs, handleDelete]);
  return (
    <SelectedButton onClick={handleClick}>
      Suspend User
      {selectedIDs.length > 1 ? 's' : ''}
    </SelectedButton>
  );
};

export default DeleteMember;
