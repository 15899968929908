import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { successfulUserSignupVar } from '@base/ApolloReactiveVars';
import Icon from '@base/components/icons/Icon';
import {
  StyledConfettiWrapper,
  StyledMessage,
  StyledSuccess,
} from './LoginSuccessMessage.styled';

export default function LoginSuccessMessage() {
  const showMessage = useReactiveVar(successfulUserSignupVar);

  if (!showMessage) {
    return null;
  }
  return (
    <StyledMessage>
      <StyledSuccess>Success!</StyledSuccess>
      Your account has been created, you can now login below.
      <StyledConfettiWrapper left>
        <Icon type="illustrations" name="confetti" size="large" />
      </StyledConfettiWrapper>
      <StyledConfettiWrapper>
        <Icon type="illustrations" name="confetti" size="large" />
      </StyledConfettiWrapper>
    </StyledMessage>
  );
}
