import { gql } from '@apollo/client';

export const UPDATE_PLATFORM_LICENSE = gql`
  mutation updatePlatformLicense($Input: UpdatePlatformLicenseInput!) {
    updatePlatformLicense(input: $Input) {
      ID
      Type
      StartDate
      ExpiryDate
      Notes
      OrganisationID
    }
  }
`;

export const CREATE_PLATFORM_LICENSE = gql`
  mutation createPlatformLicense($Input: CreatePlatformLicenseInput!) {
    createPlatformLicense(input: $Input) {
      ID
      Type
      StartDate
      ExpiryDate
      Notes
      OrganisationID
    }
  }
`;

export const DELETE_PLATFORM_LICENSE = gql`
  mutation deletePlatformLicenses($IDs: [ID]!) {
    deletePlatformLicenses(ids: $IDs)
  }
`;
