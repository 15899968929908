import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@virtidev/toolbox';
import { Upload } from 'tus-js-client';
import {
  StyledFileInput,
  StyledInputWrapper,
  UploaderListBox,
} from './SimpleUploader.styled';
import { useFlashMessage } from '../../FlashMessage';
import getConfig from '../../../../../../lib/config';
import SimpleUploaderDropArea from './components/SimpleUploaderDropArea/SimpleUploaderDropArea';

const SimpleUploader = ({
  tusEndpointOverride,
  onUploaded,
  maxFileSize,
  acceptedFileMimeTypes,
  text,
  name,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const { addFlashMessage } = useFlashMessage();

  const handleChange = useCallback((e) => {
    setSelectedFile(e.target.files[0]);
  }, []);

  const handleDropChange = useCallback((target) => {
    setSelectedFile(target.files[0]);
  }, []);

  const getFileID = (upload) => {
    try {
      return upload.url.split('/').slice(-1)[0].split('+')[0];
    } catch (e) {
      return null;
    }
  };

  const handleSuccess = useCallback(
    async (upload, TusID) => {
      onUploaded(upload, TusID);
    },
    [onUploaded]
  );

  const handleUpload = useCallback(async () => {
    if (selectedFile.size > maxFileSize) {
      addFlashMessage(
        'File is too large - must be smaller than 100mb.',
        'error'
      );
      return;
    }

    setLoading(true);

    const upload = new Upload(selectedFile, {
      endpoint:
        tusEndpointOverride ??
        getConfig('REACT_APP_TUS_UPLOAD_ENDPOINT_OTHER_MEDIA'),
      removeFingerprintOnSuccess: true,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      metadata: {
        filename: selectedFile.name,
        filetype: selectedFile.type,
      },

      onError: (error) => {
        console.error(error);
        addFlashMessage(
          'Something went wrong, resource could not be uploaded.',
          'error'
        );
        setLoading(false);
      },
      chunkSize: 1024 * 1024 * 50,
      onProgress: (bytesAccepted, totalBytes) => {
        // can add a progress wheel or bar in next steps
      },
      onSuccess: async (res) => {
        const fileId = getFileID(upload);
        setLoading(false);
        if (fileId) {
          return handleSuccess(upload, fileId);
        }
        console.error('TusID not found', upload);
      },
    });

    upload.start();
  }, [
    selectedFile,
    handleSuccess,
    addFlashMessage,
    tusEndpointOverride,
    maxFileSize,
  ]);

  return (
    <UploaderListBox>
      <StyledInputWrapper
        fileSelected={selectedFile}
        title={selectedFile?.name}
      >
        <SimpleUploaderDropArea
          onDrop={handleDropChange}
          acceptedFileMimeTypes={acceptedFileMimeTypes}
        >
          {selectedFile?.name ?? text ?? 'Drag or Select File'}
          <StyledFileInput
            accept={acceptedFileMimeTypes}
            type="file"
            onChange={handleChange}
            name={name}
          />
        </SimpleUploaderDropArea>
      </StyledInputWrapper>
      <Button disabled={!selectedFile || loading} onClick={handleUpload}>
        Upload
      </Button>
    </UploaderListBox>
  );
};

SimpleUploader.defaultProps = {
  maxFileSize: 100000000,
};

SimpleUploader.propTypes = {
  tusEndpointOverride: PropTypes.string,
  onUploaded: PropTypes.func,
  maxFileSize: PropTypes.number,
  acceptedFileMimeTypes: PropTypes.array,
  text: PropTypes.string,
  name: PropTypes.string,
};

export default SimpleUploader;
