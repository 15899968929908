import { gql } from '@apollo/client';

export const MY_SIMULATION_SESSIONS = gql`
  query mySimulationLogs(
    $ID: ID!
    $limit: Int
    $offset: Int
    $filter: AnalyticsSubmissionFilterFields
    $sort: AnalyticsSubmissionSortFields
  ) {
    readOneMember(filter: { ID: { eq: $ID } }) {
      ID
      AnalyticsSubmissions(
        limit: $limit
        offset: $offset
        filter: $filter
        sort: $sort
      ) {
        nodes {
          ID
          Score
          Created
          Finished
          LastEdited
          Simulation {
            ID
            Title
            Quiz
            HideScoresFromUsers
          }
        }
        pageInfo {
          totalCount
        }
      }
    }
  }
`;
