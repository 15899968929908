import { useMutation } from '@apollo/client';
import { Button, Modal } from '@virtidev/toolbox';
import React, { useCallback, useState, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useFlashMessaging } from '../../../../../../HOCs/WithFlashMessaging';
import ControlledToggleSwitch from '../../form/ToggleSwitch/ControlledToggleSwitch';
import LinkGenerator from '../LinkGenerator/LinkGenerator';
import CoursesSection from './Section/CoursesSection';
import SimulationSection from './Section/SimulationSection';
import VirtualHumansSection from './Section/VirtualHumansSection';
import {
  EDIT_FEEDBACK_FORM,
  EDIT_FEEDBACK_FORM_SHARING,
} from './ShareModal.query';
import { ButtonWrapper, FormTitle, ToggleWrapper } from './ShareModal.styled';

export default function ShareModal({ formData }) {
  const [open, setOpen] = useState(false);

  const { addFlashMessage } = useFlashMessaging();

  const [updateFormSharingObjects, { loading }] = useMutation(
    EDIT_FEEDBACK_FORM_SHARING,
    {
      onCompleted: (newData) => {
        addFlashMessage('Form updated successfully!');
        reset({
          ...defaultValues(newData.updateFeedbackFormSharingObjects),
          AllowMultipleSubmissions:
            newData.updateFeedbackFormSharingObjects.AllowMultipleSubmissions,
        });
      },
      onError: (err) => {
        addFlashMessage(
          'Something went wrong: form could not be updated.',
          'error'
        );
        console.error(err);
      },
    }
  );

  const [updateFeedbackForm, { loading: formLoading }] =
    useMutation(EDIT_FEEDBACK_FORM);

  const defaultValues = useCallback((inputData) => {
    const keys = [
      'SimulationsBefore',
      'SimulationsAfter',
      'VirtualHumansBefore',
      'VirtualHumansAfter',
      'CoursesBefore',
      'CoursesAfter',
    ];

    const valuesObject = {};

    keys.forEach((key) => {
      const parsedValues = [...inputData[key].edges].map(({ node }) => {
        return { label: node.Title, value: node.ID };
      });
      valuesObject[key] = parsedValues;
    });

    return valuesObject;
  }, []);

  const formMethods = useForm({
    // the select component needs an initial value or it breaks, maybe should look at fixing that?
    defaultValues: {
      ...defaultValues(formData),
      AllowMultipleSubmissions: formData.AllowMultipleSubmissions,
    },
  });

  const {
    reset,
    handleSubmit: submit,
    setValue,
    getValues,
    watch,
  } = formMethods;

  const watchAllFields = watch(); // without this, the change in value doesn't reflect in the Select list component/UI

  const handleHide = () => {
    reset();
    setOpen(false);
  };

  const handleAdd = useCallback(
    (value, label, field) => {
      setValue(field, [...getValues(field), { value, label }]);
    },
    [getValues, setValue]
  );

  const handleRemove = useCallback(
    (value, label, field) => {
      const filteredValues = [...getValues(field)].filter((item) => {
        return item.value !== value;
      });
      setValue(field, filteredValues);
    },
    [getValues, setValue]
  );
  const handleSubmit = useMemo(
    () =>
      submit((Input) => {
        const parsedInput = Object.keys(Input).reduce((acc, key) => {
          if (key === 'AllowMultipleSubmissions') {
            acc[key] = Input[key];
            return acc;
          }
          const IDs = Input[key].map((item) => {
            return item.value;
          });
          acc[key] = IDs;
          return acc;
        }, {});

        updateFormSharingObjects({
          variables: {
            FeedbackFormID: formData.ID,
            BeforeSimulationIDs: parsedInput.SimulationsBefore,
            AfterSimulationIDs: parsedInput.SimulationsAfter,
            BeforeVirtualHumanIDs: parsedInput.VirtualHumansBefore,
            AfterVirtualHumanIDs: parsedInput.VirtualHumansAfter,
            BeforeCourseIDs: parsedInput.CoursesBefore,
            AfterCourseIDs: parsedInput.CoursesAfter,
            AllowMultipleSubmissions: parsedInput.AllowMultipleSubmissions,
          },
        });
      }),
    [submit, updateFormSharingObjects, formData]
  );
  const sectionProps = {
    handleAdd,
    handleRemove,
    getValues,
  };

  return (
    <div>
      <Modal
        show={open}
        onHide={handleHide}
        render={() => (
          <>
            <FormTitle>{formData.Title}</FormTitle>
            <LinkGenerator hash={formData.Hash} formId={formData.ID} />
            <FormProvider {...formMethods}>
              <form onSubmit={handleSubmit}>
                <SimulationSection {...sectionProps} />
                <VirtualHumansSection {...sectionProps} />
                <CoursesSection {...sectionProps} />
                <ToggleWrapper>
                  <ControlledToggleSwitch
                    name="AllowMultipleSubmissions"
                    label="Allow multiple responses"
                    value={true}
                    noValue={false}
                    // disabled={loading}
                  />
                </ToggleWrapper>

                <ButtonWrapper>
                  <Button
                    loading={loading}
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    Submit
                  </Button>
                </ButtonWrapper>
              </form>
            </FormProvider>
          </>
        )}
      />

      <Button color="turquoise" onClick={() => setOpen(!open)}>
        Share Form
      </Button>
    </div>
  );
}
