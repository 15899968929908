import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledDataBlock,
  StyledDataBlockHeader,
  StyledDataBlockHeaderText,
} from '../../styled-components/StyledDataBlocks';
import {
  S_tr,
  S_td,
  S_tdLeft,
  S_thLeft,
  S_table,
  S_thead,
  S_tbody,
  S_th,
} from '../../styled-components/StyledTable';
import ScorePercentage from '../../styled-components/StyledScorePercentage';
import { Datetime } from '@virtidev/toolbox';

const TableUserCourses = (props) => {
  return (
    <StyledDataBlock>
      <StyledDataBlockHeader>
        <StyledDataBlockHeaderText>Courses</StyledDataBlockHeaderText>
      </StyledDataBlockHeader>
      <S_table>
        <S_thead>
          <S_tr>
            <S_thLeft>Title</S_thLeft>
            <S_th>Start Date</S_th>
            <S_th>Due Date</S_th>
            <S_th>Progress</S_th>
            <S_th>Average Score</S_th>
          </S_tr>
        </S_thead>
        <S_tbody>
          {(!props.courses || props.courses.length === 0) && (
            <S_tr>
              <S_td colSpan="10">No data available.</S_td>
            </S_tr>
          )}
          {props.courses.map((course) => (
            <S_tr key={course.ID}>
              <S_tdLeft>{course.Course.Title}</S_tdLeft>
              <S_td>
                <Datetime
                  datetime={course.Course.StartDate}
                  long
                  noTime
                  emptyDisplay="-"
                />
              </S_td>
              <S_td>
                <Datetime
                  datetime={course.Course.DueDate}
                  long
                  noTime
                  emptyDisplay="-"
                />
              </S_td>
              <S_td>
                <ScorePercentage align="center" score={course.Progress} />
              </S_td>
              <S_td>
                {/* ToDo: Update needed here to show N/A for courses that don't have a scoring component? */}
                <ScorePercentage align="center" score={course.AverageScore} />
              </S_td>
            </S_tr>
          ))}
        </S_tbody>
      </S_table>
    </StyledDataBlock>
  );
};

TableUserCourses.propTypes = {
  courses: PropTypes.array.isRequired,
};

export default TableUserCourses;
