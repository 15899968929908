import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { StyledWrapper } from '../CXAnalytics.styled';
import CX_ANALYTICS from '../cxAnalytics.query';

import BarChartActiveUsersHighstock from '../../../../../../components/charts/BarChartActiveUsersHighstock';

export const MonthlyActiveUsers = () => {
  const { data, loading, error } = useQuery(CX_ANALYTICS);
  const MAUMonthly = _.get(data, 'cxAnalytics.MAUMonthly');

  if (!MAUMonthly) {
    return null;
  }

  return (
    <StyledWrapper>
      <BarChartActiveUsersHighstock
        AU={MAUMonthly}
        title="Monthly Active Users"
      />
    </StyledWrapper>
  );
};

export default MonthlyActiveUsers;
