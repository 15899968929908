import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import withUser from '../../../../../../../HOCs/WithUser';
import { ADD_ORGANISATION } from '../../../../../../../queries/CXQueries';
import { useApolloClient, useMutation } from '@apollo/client';
import withFlashMessaging from '../../../../../../../HOCs/WithFlashMessaging';
import { Button, Select, TextInput } from '@virtidev/toolbox';
import getConfig from '../../../../../../../lib/config';
import useGetFreeAgentsCount from '../../../../../../../utility/VHHooks/useGetFreeAgentsCount';

const StyledFormWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 0 0 10px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.lightGrey};

  > div {
    min-width: 14rem;
  }

  > div:last-child {
    min-width: auto;
  }
`;

const CreateOrgForm = (props) => {
  const [newOrgName, setNewOrgName] = useState('');
  const [newOrgPartner, setNewOrgPartner] = useState('');
  const client = useApolloClient();

  const [mutateCreateOrganisation, { loading: mutating }] = useMutation(
    ADD_ORGANISATION,
    {
      onCompleted: (data) => {
        props.addFlashMessage(`Organisation ${newOrgName} created`);
        setNewOrgName('');
        setNewOrgPartner('');
        client.cache.evict({ fieldName: 'readOrganisations' });
        if (props.onCreate) {
          props.onCreate(data.createOrganisation);
        }
      },
      onError: () => {
        props.addFlashMessage('Failed to create organisation', 'error');
      },
    }
  );

  const { freeAgentsCount, isLoading: freeAgentsLoading } =
    useGetFreeAgentsCount();

  return (
    <StyledFormWrapper>
      <TextInput
        placeholder="Organisation Name..."
        value={newOrgName}
        onChange={(e) => {
          setNewOrgName(e.target.value);
        }}
      />
      <TextInput
        placeholder="Partner... (optional)"
        value={newOrgPartner}
        onChange={(e) => {
          setNewOrgPartner(e.target.value);
        }}
      />
      <div>
        <Button
          loading={mutating || freeAgentsLoading}
          disabled={newOrgName === '' || freeAgentsLoading}
          onClick={() => {
            mutateCreateOrganisation({
              variables: {
                Input: {
                  Name: newOrgName,
                  Partner: newOrgPartner,
                  VirtualHumanAPI: getConfig('REACT_APP_PUBLIC_URL'),
                  VirtualHumanBranchingLimit: 20,
                  VirtualHumanFreeformLimit: freeAgentsCount > 50 ? 1 : 0,
                },
              },
            });
          }}
        >
          Create
        </Button>
      </div>
    </StyledFormWrapper>
  );
};

CreateOrgForm.propTypes = {
  addFlashMessage: PropTypes.func.isRequired,
  onCreate: PropTypes.func,
};

export default withFlashMessaging(withUser(CreateOrgForm));
