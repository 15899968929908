import { useDateLocale as useDateToolbox } from '@virtidev/toolbox';
import { produce } from 'immer';
import { Locale } from 'date-fns';

const enGBOverride = {
  time: {
    full: 'h:mm:ss a zzzz',
    long: 'h:mm:ss a z',
    medium: 'h:mm:ss a',
    short: 'h:mm a',
  },
};
const supportedLanguages = ['en-GB', 'en-US', 'zh-TW'];

const importFile = async () => {
  const { language, languages } = navigator;
  // combine language and languages since language may not be be in languages
  const newLocale =
    [language, ...languages].find((language) =>
      supportedLanguages.includes(language)
    ) || 'en-GB';

  // This webpack option stops all of the date-fns files being imported and chunked.
  const localeToSet = await import(
    /* webpackMode: "lazy-once" */
    /* webpackChunkName: "date-locale" */
    /* webpackInclude: /date-fns[\\\/]locale[\\\/](en-(US|GB)|zh-TW)[\\\/]/ */
    `date-fns/locale/${newLocale}/index.js`
  );

  if (newLocale === 'en-GB') {
    return produce(
      localeToSet.default,
      /**
       * @param {Locale} state
       */ (state) => {
        if (state.formatLong) {
          /**
           *
           * @param {{
           *    width: 'full' | 'long' | 'medium' | 'short'
           * }} options
           */
          state.formatLong.time = (options) => {
            const { width } = options;

            return enGBOverride.time[width] || enGBOverride.time.full;
          };
        }
      }
    );
  }
  return localeToSet.default;
};

export const useDateLocale = () => {
  useDateToolbox(importFile);
};

export default useDateLocale;
