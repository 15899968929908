import { useReactiveVar } from '@apollo/client';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Datetime, Tooltip } from '@virtidev/toolbox';
import { confirmModalActiveVar } from '../../../../../../ApolloReactiveVars';
import WithConfirmationBox from '../../../../../../HOCs/WithConfirmationBox';

import ChangeMemberType from '../Actions/ChangeMemberType';
import DeleteMember from '../Actions/DeleteMember';
import RestoreMember from '../Actions/RestoreMember';

import { StyledGroupLink } from './UserTable.styled';
import ResendWelcomeEmail from '../Actions/ResendWelcomeEmail';
import AddMembersToGroupModal from '../Actions/AddMembersToGroupModal';
import ActionMenuButton from '../Actions/ActionMenuButton';
import { getReadableUserType } from '../../Cx/helpers/getReadableUserType';

const UserTableRow = ({
  user,
  setSelectedIDs,
  selectedIDs,
  disabledFilter,
  variables,
  setListModified,
  confirm,
}) => {
  const [checked, setChecked] = useState(false);
  const [showModal, setShowModal] = useState(false); //currently used for the group modal, but could be used for other updates

  const handleChange = useCallback(
    (e) => {
      if (checked) {
        setChecked(false);
        const filteredIDs = [...selectedIDs].filter((item) => {
          return item !== e.target.value;
        });
        setSelectedIDs(filteredIDs);
      }
      if (!checked) {
        setChecked(true);
        setSelectedIDs([...selectedIDs, e.target.value]);
      }
    },
    [selectedIDs, checked, setSelectedIDs]
  );

  useEffect(() => {
    if (selectedIDs.includes(user.ID)) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [selectedIDs, checked, user]);

  const checkStatus = useCallback((active, disabled, email) => {
    if (disabled) {
      return 'Suspended';
    }
    if (email && email.includes('virti.com')) {
      return 'Virti';
    }
    if (active && !disabled) {
      return 'Active';
    }
    if (!active && !disabled) {
      // active in the db means they have logged in once, active in the list currently means they count toward a licese. This needs to cleared up.
      return 'Active';
    }
    return 'Unknown';
  }, []);

  const confirmModalActive = useReactiveVar(confirmModalActiveVar);

  const userType = useMemo(
    () => getReadableUserType(user.UserType),
    [user.UserType]
  );
  const userStatus = useMemo(
    () => checkStatus(user.Active, user.Disabled, user.Email),
    [user, checkStatus]
  );

  return (
    <tr id={user.ID}>
      <td style={{ width: '10px' }}>
        {user.UserType !== 'customeradmin' && (
          <input
            type="checkbox"
            value={user.ID}
            checked={checked}
            onChange={handleChange}
          />
        )}
      </td>
      <td style={{ textAlign: user.Name ? 'left' : 'center' }}>
        <Link to={`/users/${user.ID}`}>{user.Name || '–'}</Link>
      </td>
      <td
        style={{
          fontSize: '0.8rem',
          maxWidth: '230px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <Link to={`/users/${user.ID}`}>{user.Email}</Link>
      </td>

      <td>
        {user.OrganisationGroups.edges.map(({ node: group }) => {
          return (
            <StyledGroupLink key={group.ID} to={`/groups/${group.ID}`}>
              {group.Name}
            </StyledGroupLink>
          );
        })}
      </td>
      <td>{userType}</td>
      <td style={{ textAlign: 'center' }}>
        <Datetime datetime={user.LastSeen} long emptyDisplay="–" />
      </td>
      <td>{userStatus}</td>
      <td>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {user.UserType !== 'customeradmin' && (
            <Tooltip
              type="menu"
              icon="dots"
              placement="left-start"
              style={{ color: 'var(--neutral-color)' }}
              confirmModalActive={confirmModalActive || showModal}
            >
              {disabledFilter ? (
                <RestoreMember
                  button={ActionMenuButton}
                  variables={variables}
                  selectedIDs={[user.ID]}
                  setListModified={setListModified}
                  confirm={confirm}
                  setSelectedIDs={setSelectedIDs} //temporary
                />
              ) : (
                <DeleteMember
                  button={ActionMenuButton}
                  variables={variables}
                  selectedIDs={[user.ID]}
                  setListModified={setListModified}
                  confirm={confirm}
                  setSelectedIDs={setSelectedIDs} //temporary
                />
              )}
              {user.UserType !== 'admin' && (
                <ChangeMemberType
                  button={ActionMenuButton}
                  variables={variables}
                  selectedIDs={[user.ID]}
                  setListModified={setListModified}
                  newUserType={'admin'}
                  confirm={confirm}
                  setSelectedIDs={() => null} //temporary
                />
              )}
              {user.UserType !== 'user' && (
                <ChangeMemberType
                  button={ActionMenuButton}
                  variables={variables}
                  selectedIDs={[user.ID]}
                  setListModified={setListModified}
                  newUserType={'user'}
                  confirm={confirm}
                  setSelectedIDs={() => null} //temporary
                />
              )}
              {user.UserType !== 'contentcreator' && (
                <ChangeMemberType
                  button={ActionMenuButton}
                  variables={variables}
                  selectedIDs={[user.ID]}
                  setListModified={setListModified}
                  newUserType={'contentcreator'}
                  confirm={confirm}
                  setSelectedIDs={() => null} //temporary
                />
              )}
              <ResendWelcomeEmail
                userID={user.ID}
                selectedIDs={[user.ID]}
                setSelectedIDs={setSelectedIDs}
                button={ActionMenuButton}
              />
              <ActionMenuButton onClick={() => setShowModal(true)}>
                Add to Group
              </ActionMenuButton>
            </Tooltip>
          )}
        </div>
        <AddMembersToGroupModal
          variables={variables}
          selectedIDs={[user.ID]}
          setListModified={setListModified}
          confirm={confirm}
          setSelectedIDs={() => null} //temporary
          showModal={showModal}
          setShowModal={setShowModal}
        />
      </td>
    </tr>
  );
};

export default WithConfirmationBox(UserTableRow);
