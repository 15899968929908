import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/client/react/components';
import { PaginationBottom } from '../components/Pagination';
import LoadingPageIndicator from '../components/LoadingPageIndicator';
import PageLoadError from '../components/PageLoadError';
import { GetFirstNestedWithKey } from '../utility/Objects';
import VirtualHumanItem, {
  VirtualHumansList,
} from './VirtualHumanItem/VirtualHumanItem';
import MyVirtualHumanItem from './MyVirtualHumanItem';
import StyledLink from '../styled-components/StyledLink';
import { setCanViewUserSideVirtualHumans } from '../ApolloReactiveVars';
import { NetworkStatus } from '@apollo/client';

const PaginatedVirtualHumansList = (props) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count > 0) {
      setCanViewUserSideVirtualHumans(true);
    }
  }, [count]);

  return (
    <Query
      query={props.dataQuery}
      fetchPolicy="cache-and-network"
      variables={{
        ...props.dataQueryVariables,
        offset:
          props.paginationState.limit * props.paginationState.page -
          props.paginationState.limit,
        limit: props.paginationState.limit,
        sortBy: { Created: 'DESC' },
      }}
    >
      {({ loading, error, data, networkStatus }) => {
        if (loading && networkStatus !== NetworkStatus.poll)
          return <LoadingPageIndicator />;
        if (error) return <PageLoadError graphQLErrorObj={error} />;
        const edges = GetFirstNestedWithKey(data[props.queryName], 'edges');

        setTimeout(() => {
          setCount(GetFirstNestedWithKey(data[props.queryName], 'totalCount'));
        });
        if (!edges?.length) {
          return props.userMode === 'user' ? (
            <div>
              This is where you'll find virtual humans that are separate from
              courses. Looks like there aren't any right now. Go to the{' '}
              <StyledLink to="/my-courses">courses page</StyledLink> to find
              available virtual humans.
            </div>
          ) : (
            <div>No results found.</div>
          );
        }

        return (
          <>
            <VirtualHumansList data-testid="virtual-humans-list">
              {(!props.userMode ||
                (props.userMode && props.userMode === 'admin')) &&
                edges &&
                edges.length > 0 &&
                edges.map((edge) => (
                  <VirtualHumanItem
                    data={edge.node}
                    key={edge.node.ID}
                    condition={
                      props.conditions && props.conditions[edge.node.ExternalID]
                        ? props.conditions[edge.node.ExternalID]
                        : undefined
                    }
                  />
                ))}
              {props.userMode &&
                props.userMode === 'user' &&
                edges &&
                edges.length > 0 &&
                edges.map((edge) => (
                  <MyVirtualHumanItem data={edge.node} key={edge.node.ID} />
                ))}
            </VirtualHumansList>
            <PaginationBottom
              changePageCallback={props.changePageCallback}
              perPage={props.paginationState.limit}
              page={props.paginationState.page}
              totalCount={
                data && data[props.queryName]
                  ? GetFirstNestedWithKey(data[props.queryName], 'totalCount')
                  : 0
              }
              pageInfo={
                data && data[props.queryName]
                  ? GetFirstNestedWithKey(data[props.queryName], 'pageInfo')
                  : null
              }
            />
          </>
        );
      }}
    </Query>
  );
};

PaginatedVirtualHumansList.defaultProps = {
  dataQueryVariables: {},
};

PaginatedVirtualHumansList.propTypes = {
  changePageCallback: PropTypes.func.isRequired,
  paginationState: PropTypes.object.isRequired,
  dataQuery: PropTypes.object.isRequired,
  dataQueryVariables: PropTypes.object,
  queryName: PropTypes.string.isRequired,
  userOrganisationID: PropTypes.number.isRequired,
  userMode: PropTypes.string,
  // object with keys being patientId and value being string, not sure how to do this with proptypes
  conditions: PropTypes.object,
};

export default PaginatedVirtualHumansList;
