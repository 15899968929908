import { gql } from '@apollo/client';

export const READ_USERS_BY_TYPE = gql`
  query readMembers($filterBy: MemberFilterFields, $Name: String) {
    readMembers(filter: $filterBy, Name: $Name) {
      edges {
        node {
          ID
          Name
          Email
          UserType
        }
      }
    }
  }
`;
