import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  StyledLoggedOutPage,
  StyledForm,
  StyledTextInput,
  StyledSubmitBtn,
  StyledLogoWrapper,
  StyledLogo,
  StyledHeader,
  StyledSuccessText,
  StyledLoggedOutPageContentWrapper,
  StyledPageImage
} from '../styled-components/StyledLoggedOutPage';

const StyledParagraph = styled.p`
  margin-bottom: 2rem;
`;


class ForgotPasswordForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
    };
  }

  _submitForm = (e) => {
    e.preventDefault();
    this.props.submitCallback(this.state.email);
  }

  render() {
    return (
      <StyledLoggedOutPage>
        <StyledLoggedOutPageContentWrapper>
          <StyledForm onSubmit={this._submitForm}>
            <StyledLogoWrapper>
              <StyledLogo />
            </StyledLogoWrapper>
            <StyledHeader>Forgot Password?</StyledHeader>
            <StyledParagraph>
              Please enter your email address below and we'll send you a secure link to reset your password.
          </StyledParagraph>
            <StyledTextInput
              label="Email"
              name="email"
              id="email"
              type="email"
              onChange={event => this.setState({ email: event.target.value })}
              placeholder="Email Address"
              value={this.state.email}
            />
            <StyledSubmitBtn loading={this.props.sending}>Send Email</StyledSubmitBtn>
            {this.props.sent && <StyledSuccessText>If a matching account was found an email has been sent to allow you to reset your password.</StyledSuccessText>}
          </StyledForm>
        </StyledLoggedOutPageContentWrapper>
        {/* <StyledPageImage /> */}
      </StyledLoggedOutPage>
    );
  }
}

ForgotPasswordForm.propTypes = {
  submitCallback: PropTypes.func.isRequired,
  sending: PropTypes.bool.isRequired,
  sent: PropTypes.bool.isRequired,
};

export default ForgotPasswordForm;