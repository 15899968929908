import React from 'react';
import CXDashboardTabNav from '../../../../../../../components/CXDashboardTabNav';
import WithCXDashboardPageTemplate from '../../../../../../../HOCs/templates/WithCXDashboardPageTemplate';
import UserTable from '../../../../components/Cx/UserTable/UserTable';

const CXUsersPage = () => {
  return (
    <div>
      <CXDashboardTabNav />
      <UserTable />
    </div>
  );
};

export default WithCXDashboardPageTemplate(CXUsersPage);
