import _ from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import OverviewPagesModal from './components/OverviewPagesModal';
import {
  useOverviewShowing,
  withOverviewShowing,
} from './OverviewShowing.state';
import {
  OverviewContainer,
  Card,
  CardTitle,
  DescriptionContainer,
  ButtonContainer,
  ButtonsContainer,
} from './SimulationOverview.styled';
import { Button } from '@virtidev/toolbox';

export const SimulationOverview = ({ simulation, onComplete }) => {
  const { showModal } = useOverviewShowing();
  const { OverviewPages, Description } = simulation;

  const pages = useMemo(() => {
    const { edges } = OverviewPages;

    return edges.map(({ node }) => node);
  }, [OverviewPages]);

  // in this case, there's no point having an overview
  useEffect(() => {
    if (!pages.length && !Description) {
      onComplete();
    }
  }, [pages, Description, onComplete]);

  const clickPlay = useCallback(() => {
    if (!pages.length) {
      onComplete();
      return;
    }
    showModal();
  }, [showModal, onComplete, pages]);

  return (
    <OverviewContainer>
      <DescriptionContainer>
        <CardTitle>Description</CardTitle>
        <Card>{Description}</Card>
      </DescriptionContainer>
      <ButtonsContainer>
        <ButtonContainer>
          <Card>
            <Button
              color="secondary"
              onClick={clickPlay}
              icon="alert"
              iconType="outline"
            >
              Overview
            </Button>
          </Card>
        </ButtonContainer>
        <ButtonContainer>
          <Card>
            <Button color="primary" onClick={clickPlay} icon="play">
              Play Simulation
            </Button>
          </Card>
        </ButtonContainer>
      </ButtonsContainer>
      <OverviewPagesModal pages={pages} onComplete={onComplete} />
    </OverviewContainer>
  );
};

export default _.flowRight(withOverviewShowing())(SimulationOverview);
