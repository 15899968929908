import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/client/react/components';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GroupTabNav from '../components/GroupTabNav';

import { GROUP_ANALYTICS } from '../queries/GroupQueries';
import { StyledPageContentInner } from '../components/StyledPage';
import LoadingPageIndicator from '../components/LoadingPageIndicator';
import PageLoadError from '../components/PageLoadError';
import {
  StyledDataBlock,
  StyledDataBlockBody,
  StyledDataBlockHeader,
  StyledDataBlockHeaderText,
  StyledDataBlockLink,
  StyledDataBlocks,
  StyledDataItem,
  StyledDataLabel,
  BlockWrapper,
} from '../styled-components/StyledDataBlocks';
import ScorePercentage from '../styled-components/StyledScorePercentage';
import BarChartTotalViewsOverTimeHighstock from '../components/charts/BarChartTotalViewsOverTimeHighstock';
import WithGroupPageTemplate from '../HOCs/templates/WithGroupPageTemplate';

const GroupAnalyticsPage = ({ data }) => {
  return (
    <>
      <GroupTabNav groupID={data.readOneVirtiGroup.ID} />
      <StyledPageContentInner>
        <Query
          query={GROUP_ANALYTICS}
          variables={{ GroupID: data.readOneVirtiGroup.ID }}
          fetchPolicy="cache-and-network"
        >
          {({ loading, error, data: analyticsDataObj }) => {
            const analyticsData = analyticsDataObj?.groupAnalytics;
            if (loading || !analyticsData) {
              return <LoadingPageIndicator />;
            }
            if (error) {
              return <PageLoadError graphQLErrorObj={error} />;
            }
            return (
              <>
                <BlockWrapper first={true}>
                  <StyledDataBlocks>
                    <StyledDataBlock>
                      <StyledDataBlockHeader>
                        <StyledDataBlockHeaderText>
                          Time Spent
                        </StyledDataBlockHeaderText>
                      </StyledDataBlockHeader>
                      <StyledDataBlockBody>
                        <div>
                          <StyledDataItem>
                            {analyticsData.TotalCompletions}
                          </StyledDataItem>
                          <StyledDataLabel>
                            <FontAwesomeIcon icon="eye" /> Total Completed
                          </StyledDataLabel>
                        </div>
                        <div>
                          <StyledDataItem>
                            {analyticsData.TotalTimeWatched}
                          </StyledDataItem>
                          <StyledDataLabel>
                            <FontAwesomeIcon icon="clock" /> Total Time Watched
                          </StyledDataLabel>
                        </div>
                      </StyledDataBlockBody>
                    </StyledDataBlock>
                    <StyledDataBlock>
                      <StyledDataBlockHeader>
                        <StyledDataBlockHeaderText>
                          Performance
                        </StyledDataBlockHeaderText>
                      </StyledDataBlockHeader>
                      <StyledDataBlockBody>
                        <div>
                          <StyledDataItem>
                            <ScorePercentage
                              score={analyticsData.AverageScore}
                            />
                          </StyledDataItem>
                          <StyledDataLabel>
                            <FontAwesomeIcon icon="star" /> Average Score
                          </StyledDataLabel>
                        </div>
                        <div>
                          <StyledDataItem>
                            {analyticsData.AverageReactionTime}
                          </StyledDataItem>
                          <StyledDataLabel>
                            <FontAwesomeIcon icon="clock" /> Average
                            ReactionTime
                          </StyledDataLabel>
                        </div>
                      </StyledDataBlockBody>
                    </StyledDataBlock>
                  </StyledDataBlocks>
                </BlockWrapper>
                <BlockWrapper>
                  <BarChartTotalViewsOverTimeHighstock
                    scores={analyticsData.DailyTotalViewsScores}
                    viewsDays={analyticsData.DailyTotalViewsDays}
                    views={analyticsData.DailyTotalViewsViews}
                  />
                </BlockWrapper>
              </>
            );
          }}
        </Query>
      </StyledPageContentInner>
    </>
  );
};

GroupAnalyticsPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default WithGroupPageTemplate(GroupAnalyticsPage, 'edit-group', false);
