import _ from 'lodash';
import { forwardRef, useEffect, useRef, useState } from 'react';

/**
 * To use this hook:

  // we need to use `useState` here to refresh the hook when references to the element changes
  const [areaRef, setAreaRef] = useState();

  const callback = useCallback(() => {
    // do something here
  }, []);

  useResize(areaRef, callback);
  
  return (
    <div ref={setAreaRef}>something</div>
  );
 */

export const useResize = (areaRef, callback, { rate = 1000 / 60 } = {}) => {
  const callbackRef = useRef(() => null);

  callbackRef.current = callback;

  useEffect(() => {
    if (!areaRef) {
      return;
    }
    const widths = [0, 0];
    const resize = _.throttle(() => {
      const width = areaRef.offsetWidth;

      // this is prevent alternating between two sizes when a scrollbar appears and disappears
      if (!widths.includes(width) || Math.abs(widths[1] - width) > 20) {
        callbackRef.current();

        widths.splice(0, 1);
        widths.push(width);
      }
    }, rate);

    if (!window.ResizeObserver) {
      resize();
      window.addEventListener('resize', resize, { passive: true });
      return () => {
        if (!window.ResizeObserver) {
          window.removeEventListener('resize', resize, { passive: true });
        }
      };
    }

    const observer = new ResizeObserver(() => {
      requestAnimationFrame(resize);
    });

    observer.observe(areaRef);

    return () => {
      observer.disconnect();
    };
  }, [areaRef, rate]);
};

/**
 * To use this HOC:

  componentDidMount = () => {
    this.props.setResizeConfig({
      element: this.videoAreaRef.current,
      callback: this._videoResize,
    });
  };
*/

export const withResize = (Component) => {
  const ResizeProvider = forwardRef((props, ref) => {
    const [resizeConfig, setResizeConfig] = useState({
      element: null,
      callback: () => null,
    });

    useResize(resizeConfig.element, resizeConfig.callback);

    return (
      <Component
        ref={ref}
        {...props}
        setResizeConfig={setResizeConfig}
      />
    );
  });

  return ResizeProvider;
};
