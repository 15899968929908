import { Icon } from '@virtidev/toolbox';
import {
  ButtonText,
  Button as StyledButton,
} from '../SimulationOverview.styled';

export const Button = ({
  icon,
  type,
  iconType,
  onClick,
  children,
  ...props
}) => {
  return (
    <StyledButton {...props} type={type} onClick={onClick}>
      <Icon icon={icon} type={iconType} /> <ButtonText>{children}</ButtonText>
    </StyledButton>
  );
};

export default Button;
