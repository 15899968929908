/**
 * @typedef {import("../../../models/video-media.types").SubState} SubState
 */

export const getSubsFileStr = function (subs, type = 'srt') {
  let fileText = type === 'srt' ? '' : `WEBVTT\n\n`;
  let num = 1;
  for (let sub of subs) {
    const start =
      (type === 'srt' ? sub.start : sub.start?.replace?.(',', '.')) || '';
    const end = (type === 'srt' ? sub.end : sub.end?.replace?.(',', '.')) || '';
    fileText += num + '\n';
    fileText += start + ' --> ' + end + '\n' + sub.text + '\n\n';
    num++;
  }
  return fileText;
};

export const getSrtTimestampAsNum = function (srtTimestamp) {
  return Number((srtTimestamp || '').replaceAll(',', '').replaceAll(':', ''));
};

/**
 * @param {string} srt
 */
export const convertSrtToSubsState = function (srt) {
  try {
    let srtWithConsistentSpacing = srt.replace(/(?:\r\n|\r|\n)/g, '\n') || '';

    let subs = srtWithConsistentSpacing.split('\n\n');
    subs = subs.filter((sub) => !!sub && sub !== '\n');
    return subs.map((subText) => {
      const subSplit = subText.split('\n');
      const times = subSplit[1].split(' --> ');
      /** @type {SubState} */
      const substate = {
        text: subSplit[2],
        start: times[0],
        startEdit: times[0],
        end: times[1],
        endEdit: times[1],
        invalidTimePositions: false,
      };
      return substate;
    });
  } catch (e) {
    console.error(e);
    alert('There was an error processing the SRT file');
    return [];
  }
};
