import { Loading } from '@virtidev/toolbox';
import styled, { css } from 'styled-components';

export const IndicatorWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 26px;
  height: 15px;
  ${(props) =>
    props.$loading &&
    css`
      top: -5px;
    `}
`;
export const IndicatorLoader = styled(Loading)`
  position: absolute;
`;

export const IndicatorNumber = styled.div``;
