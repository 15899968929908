import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withMyCoursePageTemplate from '../HOCs/templates/WithMyCoursePageTemplate';
import RedirectWithPrevState from '../components/RedirectWithPrevState';
import MyCourseModule from '../components/MyCourseModule';
import ResourceItem from '../apps/core/src/components/CourseForm/components/Resources/ResourceItem';
import { useWelcomeFlow } from '../apps/core/src/helpers/useWelcomeFlow/useWelcomeFlow';
import CourseFeedbackModal from '../apps/core/src/components/FeedbackForms/CourseFeedbackModal/CourseFeedbackModal';
import useUser from '../apps/core/src/helpers/useUser';
import { useCurrentOrganisation } from '../apps/core/src/helpers/useCurrentOrganisation';

const StyledContentArea = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
`;

const StyledSectionTitle = styled.p`
  color: #939ca3;
  font-weight: 400;
  margin-bottom: 1.4rem;
  font-size: 1.2rem;
`;

const StyledResources = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-bottom: 2rem;
`;

const StyledFormSection = styled.div`
  background: #fff;
  border-radius: var(--card-border-radius);
  box-shadow: var(--card-box-shadow);
  padding: 1.5rem 1rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledModuleSection = styled.div``;

const MyCoursePage = (props) => {
  const [activeModule, setActiveModule] = useState();

  useCurrentOrganisation(props.data?.readOneCourse?.OrganisationID);

  useEffect(() => {
    const completedModules =
      props.data?.readOneCourse?.Modules?.nodes?.reduce((acc, node) => {
        const allCompleted = node.Units.edges.reduce(
          (acc, { node: unitNode }) => {
            if (acc === false) {
              acc = false;
            } else if (unitNode.isCompleted === true) {
              acc = true;
            } else {
              acc = false;
            }
            return acc;
          },
          true
        );
        if (allCompleted) {
          acc.push(node);
        }
        return acc;
      }, []) || [];
    setActiveModule(completedModules.length);
  }, [props.data?.readOneCourse?.Modules?.nodes]);

  const courseComplete = useMemo(() => {
    // this is just for saas signup
    return props?.data?.readOneCourse.MemberProgress === 100;
  }, [props.data.readOneCourse.MemberProgress]);

  useWelcomeFlow(courseComplete, activeModule);

  if (!props.userID) {
    return <RedirectWithPrevState to="/login" />;
  }

  return (
    <StyledContentArea>
      {props?.data?.readOneCourse?.Resources?.edges.length > 0 && (
        <>
          <StyledSectionTitle>Course Resources</StyledSectionTitle>
          <StyledResources>
            {props.data.readOneCourse.Resources.edges.map(
              ({ node: resource }) => {
                return <ResourceItem key={resource.ID} resource={resource} />;
              }
            )}
          </StyledResources>
        </>
      )}
      {props.beginForm && (
        <>
          <StyledSectionTitle>Course Intro Form</StyledSectionTitle>
          <StyledFormSection>
            {!props.beginFormComplete ? (
              <div>Complete this form to start your course.</div>
            ) : (
              <div>You've completed this form.</div>
            )}

            <CourseFeedbackModal
              formData={props.beginForm}
              refetch={props.refetch}
              formComplete={props.beginFormComplete}
              trigger="BEGIN_COURSE"
              sourceID={props?.data?.readOneCourse.ID}
            />
          </StyledFormSection>
        </>
      )}

      <StyledSectionTitle>Course Modules</StyledSectionTitle>
      <StyledModuleSection>
        {props?.data?.readOneCourse?.Modules?.nodes?.map((module, index) => (
          <MyCourseModule
            key={module.ID}
            moduleData={module}
            userID={props.userID}
            activeModule={activeModule}
            index={index}
            lockModule={props.beginForm && !props.beginFormComplete}
          />
        ))}
      </StyledModuleSection>
      {props.endForm && (
        <>
          <StyledSectionTitle>Course End Form</StyledSectionTitle>
          <StyledFormSection>
            {!props.endFormComplete ? (
              courseComplete ? (
                <div>Complete this form to finish your course.</div>
              ) : (
                <div>
                  This form can be taken after all modules have been completed.
                </div>
              )
            ) : (
              <div>You've completed this form.</div>
            )}

            <CourseFeedbackModal
              formData={props.endForm}
              refetch={props.refetch}
              formComplete={props.endFormComplete}
              disabled={!courseComplete}
              trigger="END_COURSE"
              sourceID={props?.data?.readOneCourse.ID}
            />
          </StyledFormSection>
        </>
      )}
    </StyledContentArea>
  );
};

MyCoursePage.propTypes = {
  userID: PropTypes.number,
  data: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  userOrganisationID: PropTypes.number.isRequired,
};

export default withMyCoursePageTemplate(MyCoursePage, 'student-course-details');
