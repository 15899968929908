import styled from 'styled-components';

export const SavebarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  background: #fff;
  padding: 15px;
  border-top: 1px solid #ccc;
`;
