import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Button from './Button';

const StyledClearButton = styled(Button)`
  cursor: pointer !important;
  ${props => props.isInCorner && css`
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;
  `}
  background-color: var(--delete-button-bg-color);
  color: var(--delete-button-text-color);
  z-index: 2;
  padding: 0.6rem 0.8rem;
  width: 42px;
  height: 42px;
  svg {
    width: 26px;
    height: 26px;
  }
  svg, span, div, path {
    cursor: pointer !important;
  }
  &:hover {
    background-color: var(--delete-button-bg-color-hover);
    color: var(--delete-button-text-color);
  }
  ${props => props.disabled && css`
    background-color: var(--button-disabled-bg-color);
    &:hover {
      background-color: var(--button-disabled-bg-color);
    }
  `}
`

const ClearButton = props => (
  <StyledClearButton
    className={props.className}
    title={props.title}
    dataTestId={props.dataTestId}
    type="tertiary"
    iconType="lineawesome"
    icon="trash-solid"
    onClick={props.onClick}
    disabled={props.disabled}
    isInCorner={props.isInCorner}
  />
)

ClearButton.defaultProps = {
  isInCorner: true
}

ClearButton.propTypes = {
  dataTestId: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  isInCorner: PropTypes.bool.isRequired,
}

export default ClearButton;