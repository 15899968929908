import React from 'react';

import styled, { css } from 'styled-components';
import Button from '../components/buttons/Button';
import Icon from '../components/icons/Icon';

export const StyledMessageBlock = styled.div`
  box-shadow: var(--card-box-shadow);
  padding: 2rem;
  border-radius: var(--card-border-radius);
  margin-top: 2rem;
  font-size: 0.9rem;
`;

export const StyledPageHeading = styled.h1`
  margin-bottom: 1rem;
  font-size: 2rem;
`;

export const StyledIcon = styled(Icon)`
  opacity: ${(props) => (props.completed ? '0.2' : '1')};
  position: ${(props) => (props.position ? props.position : '')};
`;

export const StyledResetMessageBlock = styled.div`
  margin: auto;

  margin-top: 3rem;
  display: grid;
  place-items: center;
`;

export const StyledResetMessage = styled.div`
  display: grid;
  place-items: center;
  gap: 1rem;
`;

export const StyledCards = styled.div`
  ${({ sidebarContracted }) => css`
    margin: 0.5rem 0rem 2.5rem 0rem;
    padding: 1rem 0rem 1rem 0rem;
    place-items: center;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
    gap: 1rem;
    max-width: 100%;

    @media (max-width: 1580px) {
      ${!sidebarContracted &&
      css`
        overflow-x: scroll;
        padding: 1rem 0.5rem;
      `}
    }

    @media (max-width: 1422px) {
      overflow-x: scroll;
      padding: 1rem 0.5rem;
    }
  `}
`;

export const StyledCard = styled.div`
  ${({ theme, selected, completed }) => css`
    width: 15rem;
    align-items: center;
    height: auto;
    padding: 0rem 0rem 1.2rem 0rem;
    display: flex;
    flex-direction: column;
    border: var(--primary-border);
    border-radius: var(--card-border-radius);
    background-color: var(--card-bg-color);
    box-shadow: var(--card-box-shadow);

    text-decoration: none;
    cursor: pointer;
    color: var(--tertiary-font-color);
    ${selected &&
    css`
      background-color: ${theme.color.primary};
      color: #fff;
      transform: scale(1.04);
    `}

    ${completed &&
    css`
      background-color: var(--neutral-color-light);
    `}
  `}
`;

export const StyledGreenBorder = styled.div``;

export const StyledCardBody = styled.div`
  ${({ completed }) => css`
    filter: ${completed && 'brightness(50%)'};
    display: flex;
    flex-direction: column;
    width: 100%;
  `}
`;

export const StyledIconWrapper = styled.div`
  margin: 1rem 1rem 1rem 1rem;
  height: 12rem;
  display: grid;
  place-items: center;
`;

export const StyledCompletedOverlay = styled.div`
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const StyledProgressBar = styled.progress`
  ${({ theme, height, width, maxWidth }) => css`
    height: ${height ? height : '1rem'};
    border-radius: 7px;
    width: ${width ? width : '80%'};
    max-width: ${maxWidth ? maxWidth : ''};

    margin: 1rem auto 1rem auto;

    ::-webkit-progress-bar {
      background-color: ${theme.color.lightGrey};
      border-radius: 24px;
      height: ${height ? height : '1rem'};
      transition: 1s;
    }

    ::-moz-progress-bar {
      background-color: var(--secondary-color, #40d1bf) !important;
      color: var(--secondary-color, #40d1bf) !important;
      border-radius: 24px;
      height: 100%;
      transition: 1s !important;
    }

    &::-webkit-progress-value {
      background-color: ${theme.color.secondary};
      border-radius: 24px;
      height: ${height ? height : '1rem'};
      transition: 1s;
    }
  `}
`;

export const StyledCardImage = styled.img`
  margin: 2rem 0rem;
  padding: 2rem 0rem;
`;

export const StyledCardTitle = styled.label`
  font-size: 1rem;
  padding: 1rem 0rem;
  text-align: center;
  cursor: pointer;
`;

export const StyledTask = styled.div``;

export const StyledProgressTitle = styled.div`
  color: var(--secondary-color, #46beaf);
  text-align: right;
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-end;
  img {
    margin-left: 0.2rem;
  }
`;
export const StyledOnboardingResetButton = styled(Button)`
  background-color: none;
  border: none;
  color: var(--neutral-color-light);

  :hover {
    background: none !important;
  }
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      background: none;
      color: var(--button-disabled-text-colorcss);
    `};
`;

export const StyledOnboardingSkipButton = styled(Button)`
  ${({ theme, disabled }) => css`
    background-color: ${theme.color.primary};
    color: #fff;
    border: none;

    :hover {
      color: #fff;
      background-color: ${theme.color.primary};
      opacity: 0.9;
    }

    ${disabled &&
    css`
      pointer-events: none;
      background-color: var(--button-disabled-bg-color);
      color: var(--button-disabled-text-colorcss);
    `}
  `}
`;

export const StyledCenterAlignWrapper = styled.div`
  display: grid;
  place-items: center;
`;
export const StyledSettingsWrapper = styled.div`
  margin-top: 2.5rem;

  display: flex;
  flex-direction: column;
`;

export const StyledSettingsTitle = styled.div`
  display: grid;
  place-items: center end;
  grid-template-columns: 2fr 1fr;
  width: 100%;
`;

export const StyledCompletedIconsWrapper = styled.div`
  position: relative;
  display: grid;
  place-items: center;
`;

// Completion Screen
export const StyledCompletionArea = styled.div`
  text-align: center;
  margin: auto;
  margin-bottom: 6rem;
  position: relative;
  width: 70rem;
  max-width: 100%;
`;

export const StyledCompletionTitle = styled.h1`
  margin-top: 1rem;
  text-align: center;
  color: var(--primary-color, #528fbf);
  font-size: 2rem;
  font-weight: 500;
`;

export const StyledCompletionMessage = styled.div`
  text-align: center;
  line-height: 2rem;
  margin-top: 2rem;
`;

export const StyledPageIllustration = styled(Icon)`
  margin: -10rem 0;
`;

export const StyledConfetti = styled(Icon)`
  position: absolute;
  top: ${(props) => (props.top ? props.top : 0)};
  right: ${(props) => (props.right ? props.right : 0)};
  transform: scale(1.5);
`;

export const StyledAnchorTag = styled.a`
  color: var(--primary-color, #528fbf);
  font-weight: 600;
  color: var(--link-text-color);
  font-weight: bold;
  &:visited {
    color: var(--link-text-color-visited);
  }
  &:hover {
    color: var(--link-text-color-hover);
    text-decoration: underline;
  }
  &:active {
    color: var(--link-text-color-active);
  }
`;

// dismissed message
export const StyledDismissedMessage = styled.div`
  margin: auto;
  margin-top: 5rem;
  display: grid;
  grid-template-rows: 1fr 1fr;
  place-items: center;
  gap: 1rem;
  line-height: 2rem;
  text-align: center;
`;
