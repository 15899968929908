import React from 'react';
import PropTypes from 'prop-types';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { ChartContainer } from './EnrolledChart.styled';

export const EnrolledChart = (props) => {
  return (
    <ChartContainer>
      <CircularProgressbar
        value={props.data}
        strokeWidth={26}
        styles={buildStyles({
          strokeLinecap: 'butt',
          textSize: '1.5rem',
          // Colors
          pathColor: 'var(--primary-color)',
          trailColor: 'var(--primary-color-lighter)',
        })}
      />
    </ChartContainer>
  );
};

EnrolledChart.propTypes = {
  title: PropTypes.string,
  data: PropTypes.number.isRequired,
};

export default EnrolledChart;
