import { gql } from '@apollo/client';

export const UPDATE_MEMBER = gql`
  mutation updateMember($Input: UpdateMemberInput!) {
    updateMember(input: $Input) {
      ID
    }
  }
`;

export const BULK_UPDATE_MEMBERS = gql`
  mutation bulkUpdateUsers(
    $MemberIDs: [ID]!
    $AddToGroupID: ID
    $RemoveFromGroupID: ID
    $UserType: String
    $Disabled: Boolean
  ) {
    bulkUpdateUsers(
      MemberIDs: $MemberIDs
      AddToGroupID: $AddToGroupID
      RemoveFromGroupID: $RemoveFromGroupID
      UserType: $UserType
      Disabled: $Disabled
    ) {
      ID
      UserType
      OrganisationGroups {
        edges {
          node {
            ID
            Name
          }
        }
      }
    }
  }
`;
