import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
`;

export const EnvironmentAvatarItem = styled.div`
  display: grid;
  grid-template-columns: 60% 100px;
  align-items: center;
  border-bottom: 1px solid grey;
  padding-bottom: 2px;
  margin-bottom: 2px;
`;

export const Select = styled.select``;
