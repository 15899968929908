import { useContext } from 'react';
import { createPortal } from 'react-dom';
import { PagePartContext } from '../../PagePart.state';
import { SavebarContainer } from './Savebar.styled';

export const Savebar = ({ children, ...props }) => {
  const { getPart } = useContext(PagePartContext);
  const container = getPart('save');

  if (!container || !children) {
    return null;
  }

  return createPortal(
    <SavebarContainer {...props}>{children}</SavebarContainer>,
    container
  );
};
