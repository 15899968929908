import { useState } from 'react';

const publishStrategyOptions = [
  { value: 'PRESERVE', label: 'Preserve published/draft status' },
  { value: 'DRAFT', label: 'Give all copied content draft status' },
];

export const usePublishStrategy = () => {
  const [selectedPublishStrategy, setSelectedPublishStrategy] = useState(
    publishStrategyOptions[0]
  );
  return {
    publishStrategy: selectedPublishStrategy.value,
    publishStrategySelectProps: {
      options: publishStrategyOptions,
      value: selectedPublishStrategy,
      onChange: setSelectedPublishStrategy,
      placeholder: 'Select Publish Strategy...',
    },
  };
};

export default usePublishStrategy;
