import { FC, useMemo, ButtonHTMLAttributes } from 'react';
import { Icon } from '@virtidev/toolbox';
import { matchPath, useLocation } from 'react-router-dom';
import {
  ButtonWrapper,
  Content,
  IconHolder,
  IconsWrapper,
  LockIcon,
  MoreIcon,
} from './ButtonItem.styled';
import { useReactiveVar } from '@apollo/client';
import {
  secondarySidebarContentVar,
  sidebarContractedVar,
} from '@base/ApolloReactiveVars';
import classNames from 'classnames';
import { Sublink } from '@core/components/Sidebar/Sidebar.types';
import SubSidebar from '@core/components/Sidebar/components/SubSidebar/SubSidebar';

/**
 * @type {FC<{
 *    icon: string,
 *    iconType?: "outline" | "solid",
 *    to?: string | string[],
 *    locked?: boolean,
 *    contentName?: string,
 *    sublinks?: Sublink[],
 * } & ButtonHTMLAttributes<HTMLButtonElement>>}
 */
export const ButtonItem = ({
  icon,
  iconType,
  to,
  children,
  locked,
  contentName,
  sublinks,
  className,
  ...props
}) => {
  const sidebarContent = useReactiveVar(secondarySidebarContentVar);
  const { pathname } = useLocation();

  const open = useMemo(
    () => sidebarContent === contentName,
    [sidebarContent, contentName]
  );

  const active = useMemo(() => {
    if (!to) {
      return false;
    }

    return !!(Array.isArray(to) ? to : [to]).find((subTo) =>
      matchPath(pathname, subTo)
    );
  }, [pathname, to]);

  return (
    <ButtonWrapper
      {...props}
      type="button"
      $open={open}
      className={classNames(className, 'sidebar-item', {
        active,
        more: !!sublinks,
      })}
      $faded={!!sidebarContent}
    >
      <IconsWrapper>
        {locked && ( // having the condition on only LockIcon caused styling issues for the menu chevron button
          <LockIcon icon={'padlock'} color="var(--primary-color)" size="18px" />
        )}
        <Icon icon={icon} type={iconType} size="22px" />
      </IconsWrapper>
      <Content>{children}</Content>
      {sublinks && (
        <IconHolder>
          <MoreIcon icon="chevron" $active={open} />
        </IconHolder>
      )}
    </ButtonWrapper>
  );
};

export default ButtonItem;
