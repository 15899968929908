import { TextInput, ToggleSwitch } from '@virtidev/toolbox';
import styled from 'styled-components';

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
`;

export const SearchInput = styled(TextInput)`
  width: 10%;
  min-width: 200px;
  max-width: 350px;
`;

export const SearchSwitch = styled(ToggleSwitch)`
  width: 25%;
  min-width: 250px;
  max-width: 300px;
`;
