import {
  AssetTabs,
  FilterContainer,
  ModalFooter,
  ResizedModal,
  UploadNotice,
} from '@core/components/AssetSelectModal/AssetSelectModal.styled';
import AssetImageList from '@core/components/AssetSelectModal/components/AssetImageList';
import AssetVideoList from '@core/components/AssetSelectModal/components/AssetVideoList';
import {
  Button,
  Icon,
  Link,
  Tab,
  TextInput,
  useDebouncedSave,
} from '@virtidev/toolbox';
import { useCallback, useEffect, useMemo, useState } from 'react';

export const AssetSelectModal = ({
  show = false,
  onHide = () => null,
  onConfirm,
  loading = false,
  multiSelect = false,
  disabledVideoIds = [],
  disabledImageIds = [],
  adding = false,
  replacing = false,
  acceptedTypes = ['video'],
}) => {
  const [tab, setTab] = useState(/** @type {'video' | 'image'} */ ('video'));
  const [selectedItems, setSelectedItems] = useState([]);
  const [filter, setFilter] = useState('');

  const title = useMemo(() => {
    if (adding) {
      return tab === 'video' ? 'Add existing video' : 'Add existing image';
    }
    if (replacing) {
      return 'Replace media';
    }
    return 'Choose existing media';
  }, [tab, adding, replacing]);

  const handleChangeTab = useCallback((name) => {
    setSelectedItems([]);
    setTab(name);
  }, []);

  const handleConfirm = useCallback(() => {
    onConfirm(multiSelect ? selectedItems : selectedItems[0], tab);
  }, [selectedItems, tab, onConfirm, multiSelect]);

  const buttonLabel = useMemo(() => {
    if (!multiSelect) {
      return adding ? 'Add' : 'Select';
    }

    const suffix = selectedItems.length ? ` (${selectedItems.length})` : '';

    return adding ? `Add${suffix}` : `Select${suffix}`;
  }, [multiSelect, selectedItems, adding]);

  const handleFilterChange = useCallback(
    (value) => {
      setFilter(value || '');
    },
    [setFilter]
  );

  const debouncedProps = useDebouncedSave(filter, {
    onUpdate: handleFilterChange,
  });

  useEffect(() => {
    return () => {
      if (show) {
        setSelectedItems([]);
      }
    };
  }, [show]);

  return (
    <ResizedModal
      show={show}
      title={title}
      onHide={onHide}
      render={() => (
        <>
          <FilterContainer>
            <TextInput {...debouncedProps} placeholder="Search..." />
          </FilterContainer>
          <AssetTabs
            fill
            onChange={handleChangeTab}
            selectedTabName={tab}
            $showTabsList={acceptedTypes.length > 1}
          >
            {acceptedTypes.includes('video') && (
              <Tab name="video" title="Video">
                <AssetVideoList
                  filter={filter}
                  selectedItems={selectedItems}
                  onSelect={setSelectedItems}
                  disabledIds={disabledVideoIds}
                  multiSelect={multiSelect}
                />
              </Tab>
            )}
            {acceptedTypes.includes('image') && (
              <Tab name="image" title="Image">
                <AssetImageList
                  filter={filter}
                  selectedItems={selectedItems}
                  onSelect={setSelectedItems}
                  disabledIds={disabledImageIds}
                  multiSelect={multiSelect}
                />
              </Tab>
            )}
          </AssetTabs>
        </>
      )}
      footerRender={() => (
        <ModalFooter>
          <UploadNotice>
            Upload new media in{' '}
            <Link to="/media" target="_blank">
              Media library <Icon icon="expand" size="small" />
            </Link>
          </UploadNotice>
          <Button
            color="turquoise"
            disabled={!selectedItems.length || loading}
            loading={loading}
            onClick={handleConfirm}
          >
            {buttonLabel}
          </Button>
        </ModalFooter>
      )}
    />
  );
};

export default AssetSelectModal;
