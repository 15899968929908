import { useMutation } from '@apollo/client';
import { Button, TextInput } from '@virtidev/toolbox';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';
import Label from '../../../../../../../../../../styled-components/StyledLabel';
import {
  DELETE_ENVIRONMENT,
  READ_ENVIRONMENTS,
  UPDATE_ENVIRONMENT,
} from '../../CxEnvironmentsEditor.query';
import { useFlashMessage } from '../../../../../../FlashMessage';
import UploadImage from '../../../../../../CourseForm/components/UploadImage/UploadImage';
import ConfirmationModal from '../../../../../../../../../../components/modals/ConfirmationModal';
import ControlledToggleSwitch from '../../../../../../form/ToggleSwitch/ControlledToggleSwitch';

const SelectedEnvironmentEditor = ({ selectedEnvironment }) => {
  const formMethods = useForm();
  const { register, reset, getValues, formState, setValue, watch } =
    formMethods;

  const { addFlashMessage } = useFlashMessage();
  const [updateEnvironmentMutation] = useMutation(UPDATE_ENVIRONMENT, {
    onCompleted: () => {
      addFlashMessage('Updated environment');
    },
    onError: () => {
      addFlashMessage('Failed to update environment', 'error');
    },
    refetchQueries: [{ query: READ_ENVIRONMENTS }],
  });

  useEffect(() => {
    reset(selectedEnvironment);
    // setEnvironmentName(selectedEnvironment.Name);
    // setEnvironmentDisplayName(selectedEnvironment.DisplayName);
    // setEnvironmentDescription(selectedEnvironment.Description);
    // setThumbnailURL(selectedEnvironment?.ImageMedia?.URL ?? '');
  }, [reset, selectedEnvironment]);

  const updateEnvironment = useCallback(() => {
    // if (!formState.isDirty) return;
    updateEnvironmentMutation({
      variables: {
        input: {
          ID: selectedEnvironment.ID,
          Name: getValues('Name'),
          DisplayName: getValues('DisplayName'),
          Description: getValues('Description'),
          ImageMediaID: getValues('ImageMediaID'),
          IsPreview: getValues('IsPreview'),
          IsDevelopment: getValues('IsDevelopment'),
          URL: getValues('URL'),
        },
      },
    });
  }, [getValues, selectedEnvironment?.ID, updateEnvironmentMutation]);

  const debouncedUpdateEnvironment = useDebouncedCallback(
    updateEnvironment,
    1000
  );

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (!name) return;
      const debouncedChangeValues = [
        'Name',
        'DisplayName',
        'Description',
        'URL',
      ];
      if (debouncedChangeValues.includes(name)) {
        debouncedUpdateEnvironment();
      } else {
        updateEnvironment();
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, debouncedUpdateEnvironment, updateEnvironment]);

  const [showConfirm, setShowConfirm] = useState(false);
  const onClickDelete = useCallback(() => {
    setShowConfirm(true);
  }, []);

  const [deleteEnvironment, { isLoading: isDeleting }] = useMutation(
    DELETE_ENVIRONMENT,
    {
      refetchQueries: [{ query: READ_ENVIRONMENTS }],
    }
  );

  const onCloseConfirm = useCallback(() => {
    // setShowConfirm(false);
    deleteEnvironment({ variables: { ids: [selectedEnvironment.ID] } });
  }, [deleteEnvironment, selectedEnvironment?.ID]);

  return (
    <FormProvider {...formMethods}>
      <form>
        <h2>Selected Environment</h2>
        <div>
          <ControlledToggleSwitch name="IsPreview">
            IsPreview
          </ControlledToggleSwitch>
        </div>
        <div>
          <ControlledToggleSwitch name="IsDevelopment">
            IsDevelopment
          </ControlledToggleSwitch>
        </div>
        <div>
          <Label htmlFor="environment-codename">Code Name</Label>
          <TextInput id="environment-codename" {...register('Name')} />
        </div>
        <div>
          <Label htmlFor="environment-displayname">Display Name</Label>
          <TextInput
            id="environment-displayname"
            {...register('DisplayName')}
          />
        </div>
        <div>
          <Label htmlFor="environment-code">Description</Label>
          <TextInput
            multiline={true}
            id="environment-code"
            {...register('Description')}
          />
        </div>
        <div>
          <UploadImage
            url={selectedEnvironment?.ImageMedia?.URL}
            mediaTusID={selectedEnvironment?.ImageMedia?.TusID}
            name="ImageMediaID"
            key={selectedEnvironment.ID}
          />
        </div>
        <div>
          <Label htmlFor="environment-url">URL</Label>
          <TextInput id="environment-url" {...register('URL')} />
        </div>
      </form>
      {showConfirm && (
        <ConfirmationModal
          onConfirm={onCloseConfirm}
          onCancel={() => setShowConfirm(false)}
          verificationTextRequired={selectedEnvironment?.Name ?? ''}
          loading={isDeleting}
        >
          <>
            DANGER: Make sure this environment is not used by ANY virtual humans
            before deletion.{' '}
            {selectedEnvironment?.Name && (
              <>
                Type the environment name{' '}
                <strong>{selectedEnvironment.Name}</strong> to confirm.
              </>
            )}
          </>
        </ConfirmationModal>
      )}
      <Button color="danger" icon="alert" onClick={onClickDelete}>
        Delete
      </Button>
    </FormProvider>
  );
};

export default SelectedEnvironmentEditor;
