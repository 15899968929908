import { useCallback } from 'react';

export const useRefs = (...refs) => {
  return useCallback((value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        ref.current = value;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refs);
};

export default useRefs;
