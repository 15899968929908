import { Button } from '@virtidev/toolbox';
import styled, { css } from 'styled-components';

export const Position = styled.div`
  ${({ theme }) => css`
    display: none;
    position: fixed;
    top: 65px;
    right: ${theme.spacing.md};
    z-index: 1011;

    .virti-offline-update & {
      display: block;
    }
  `}
`;

export const Box = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    border-radius: ${theme.borderRadius.md};
    background: ${theme.color.contrast.base};
    border: 1px solid ${theme.color.lightGrey};
    padding: ${theme.spacing.sm} ${theme.spacing.md};
    overflow: auto;
  `}
`;

export const CloseButton = styled(Button).attrs({
  color: 'transparent',
  icon: 'cross',
  iconSize: '0.8rem',
  size: 'small',
})`
  ${({ theme }) => css`
    position: absolute;
    top: ${theme.spacing.sm};
    right: ${theme.spacing.sm};
    padding: ${theme.spacing.sm};
  `}
`;

export const UpdateLink = styled(Button).attrs({
  color: 'transparent-primary',
})`
  :focus {
    box-shadow: none;
  }
`;