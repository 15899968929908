import { gql } from '@apollo/client';

export const COPY_COURSE = gql`
  mutation copyCourse(
    $OrganisationID: ID!
    $CourseID: ID!
    $PublishStrategy: String = "DRAFT"
  ) {
    copyCourse(
      OrganisationID: $OrganisationID
      CourseID: $CourseID
      PublishStrategy: $PublishStrategy
    ) {
      ID
      Title
    }
  }
`;

export const READ_COURSE_VIRTUAL_HUMANS = gql`
  query readOneCourse($ID: ID!) {
    readOneCourse(filter: { ID: { eq: $ID } }) {
      ID
      Modules {
        nodes {
          ID
          Units {
            edges {
              node {
                ID
                VirtualHuman {
                  ID
                  Type
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const READ_ORG_VH_API = gql`
  query readOrgVHAPI($ID: ID!) {
    readOneOrganisation(filter: { ID: { eq: $ID } }) {
      ID
      Name
      VirtualHumanAPI
    }
  }
`;
