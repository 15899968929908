import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { useQuery } from '@apollo/client';
import { READ_FEEDBACK_FORM } from '../../../../../../queries/FeedbackFormQueries';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { withPageTemplate } from '../../../templates';
import { Savebar, Titlebar } from '../../../components/Page';
import { Actionbar } from '../../../components/Page/components/Actionbar';
import FormsNav from '../../../../../../components/FormsNav';

import EditFeedbackForm from '../../../components/FeedbackForms/FeedbackForms/EditFeedbackForm/EditFeedbackForm';
import { useCurrentOrganisation } from '../../../helpers/useCurrentOrganisation';
import { withFeedbackFormsAccess } from '../../../helpers/permissions';

const StyledPageWrapper = styled.div`
  ${({ theme: { spacing } }) => css`
    width: 80%;
    min-width: 300px;
    margin: ${spacing.md} auto;
  `}
`;

const FeedbackFormPage = () => {
  const { id } = useParams();

  const { data, loading } = useQuery(READ_FEEDBACK_FORM, {
    // give this its own query
    variables: {
      ID: id,
    },
    fetchPolicy: 'cache-and-network',
  });

  useCurrentOrganisation(data?.readOneFeedbackForm?.OrganisationID);

  return (
    <StyledPageWrapper>
      <Titlebar>Edit Feedback Form </Titlebar>
      <Actionbar>
        <FormsNav formID={id} />
      </Actionbar>
      <EditFeedbackForm data={data} loading={loading} />
    </StyledPageWrapper>
  );
};

export default _.flowRight(
  withPageTemplate({ pageKey: 'edit-feedback-form' }),
  withFeedbackFormsAccess
)(FeedbackFormPage);
