import { useCallback, useState } from 'react';
import VHQuery from './VHQuery';

const useUpdateVHMutation = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const update = useCallback(
    async (externalID, newVHDataObj, vhAPIEndpointOverride = undefined) => {
      setIsLoading(true);
      try {
        const result = await VHQuery(
          `patient/${externalID}`,
          'PUT',
          newVHDataObj,
          vhAPIEndpointOverride
        );
        setIsLoading(false);
        return result;
      } catch (err) {
        setError(err);
        setIsLoading(false);
      }
    },
    []
  );
  return [update, { isLoading, error }];
};
export default useUpdateVHMutation;
