/** font awesome **/
import { library } from '@fortawesome/fontawesome-svg-core';

// import { fab } from '@fortawesome/free-brands-svg-icons'
import {
  faUser,
  faUsers,
  faTrashAlt,
  faPencilAlt,
  faUserFriends,
  faArrowLeft,
  faSpinner,
  faPlus,
  faEye,
  faEyeSlash,
  faExclamationTriangle,
  faCaretDown,
  faCaretUp,
  faCaretLeft,
  faCaretRight,
  faGlobeAmericas,
  faChartBar,
  faChevronLeft,
  faClock,
  faStar,
  faChevronRight,
  faTimesCircle,
  faCheck,
  faTimes,
  faQuestionCircle,
  faPlay,
  faVideo,
  faCheckCircle,
  faCalculator,
  faSave,
  faExpand,
  faFileAlt,
  faBookmark,
} from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';

export const setupFA = () => {
  library.add(
    faUser,
    faUsers,
    faTrashAlt,
    faPencilAlt,
    faUserFriends,
    faArrowLeft,
    faSpinner,
    faPlus,
    faEye,
    faEyeSlash,
    faExclamationTriangle,
    faCaretDown,
    faCaretUp,
    faCaretLeft,
    faCaretRight,
    faGlobeAmericas,
    faChartBar,
    faChevronLeft,
    faClock,
    faStar,
    faStarRegular,
    faChevronRight,
    faTimesCircle,
    faCheck,
    faTimes,
    faQuestionCircle,
    faVideo,
    faPlay,
    faCheckCircle,
    faCalculator,
    faSave,
    faExpand,
    faFileAlt,
    faBookmark
  );
};

export default setupFA;
