import React, { FC } from 'react';
import * as Styled from '../../ScoreOverview.styled';
import ScorePercentCircle from '@core/components/Analytics/VirtualHuman/ScoreOverview/components/ScorePercentCircle/ScorePercentCircle';
import {
  GetFormattedTime,
  GetFormattedTimeLong,
} from '@base/utility/TimeFormatting';
import AnalyticsSummaryItem from '@core/components/Analytics/VirtualHuman/ScoreOverview/components/AnalyticsSummaryItem/AnalyticsSummaryItem';
import { ToDecimalPlace } from '@base/utility/NumberFormatting';
import PropTypes from 'prop-types';

/**
 * @type {FC<{
 * hideScores?: boolean;
 * pointsScored: number;
 * clampedScorePercentageFraction: number;
 * scorePercentage: number;
 * timeSpent: number;
 * vhType: import('@core/models/virtualhuman.types').VHType;
 * pointsPossible: number;
 * }>}
 */
const ScoreStats = ({
  hideScores,
  pointsScored,
  clampedScorePercentageFraction,
  scorePercentage,
  timeSpent,
  vhType,
  pointsPossible,
}) => {
  const showMainScore =
    (!hideScores && pointsPossible > 0) ||
    (vhType === 'freeform_v3' && typeof scorePercentage === 'number');
  const showObjectivePoints = vhType !== 'freeform_v3' && pointsPossible > 0;

  return (
    <>
      {showMainScore && (
        <Styled.SummaryCircle>
          <ScorePercentCircle
            scorePercent={ToDecimalPlace(clampedScorePercentageFraction, 1)}
          />
          <Styled.AbsoluteSummaryItem>
            <AnalyticsSummaryItem
              label="Score"
              value={`${scorePercentage}%`}
              iconName="medal"
            />
          </Styled.AbsoluteSummaryItem>
        </Styled.SummaryCircle>
      )}
      <Styled.BlueSummaryCircle>
        <AnalyticsSummaryItem
          label="Time taken"
          value={GetFormattedTime(timeSpent)}
          iconName="clock"
        />
      </Styled.BlueSummaryCircle>
      {showObjectivePoints && (
        <Styled.TurquoiseSummaryCircle>
          <AnalyticsSummaryItem
            label="Objective points"
            value={`${pointsScored}`}
            iconName="diamond"
          />
        </Styled.TurquoiseSummaryCircle>
      )}
    </>
  );
};

ScoreStats.propTypes = {
  hideScores: PropTypes.bool,
  pointsScored: PropTypes.number.isRequired,
  clampedScorePercentageFraction: PropTypes.number.isRequired,
  scorePercentage: PropTypes.number.isRequired,
  timeSpent: PropTypes.number.isRequired,
  vhType:
    /** @type {import('prop-types').Validator<import('@core/models/virtualhuman.types').VHType>}*/ (
      PropTypes.oneOf(['medical', 'default', 'branching', 'freeform_v3'])
        .isRequired
    ),
  pointsPossible: PropTypes.number.isRequired,
};

export default ScoreStats;
