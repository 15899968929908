import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';

import ForgotCodeForm from '../forms/ForgotCodeForm';
import FlashMessageHolder from '../components/FlashMessageHolder';
import { FORGOT_CODE } from '../queries/CodeQueries';
import { withUser } from '../apps/core/src/helpers/useUser';
import { Redirect } from 'react-router-dom';

class ForgotCodePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sending: false,
      sent: false,
    };
    this.forgotCodeMutate = null;
  }

  _submitForgotCode = (email) => {
    if (!this.forgotCodeMutate) return;
    this.setState({
      sending: true,
    });
    this.forgotCodeMutate({ variables: { Email: email } });
  };

  _sentEmail = () => {
    this.setState({
      sending: false,
      sent: true,
    });
  };

  render() {
    if (this.props.userID) {
      return <Redirect to="/" />;
    }
    return (
      <div>
        <FlashMessageHolder />
        <Mutation mutation={FORGOT_CODE} onCompleted={this._sentEmail}>
          {(forgotCodeMutate, { data }) => {
            this.forgotCodeMutate = forgotCodeMutate;
            return (
              <ForgotCodeForm
                sending={this.state.sending}
                submitCallback={this._submitForgotCode}
                sent={this.state.sent}
              />
            );
          }}
        </Mutation>
      </div>
    );
  }
}

ForgotCodePage.propTypes = {
  userID: PropTypes.number,
  match: PropTypes.object.isRequired,
};

export default withUser(ForgotCodePage);
