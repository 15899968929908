import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import setupSentry from './apps/core/src/components/Sentry/setupSentry';
import setupFA from './lib/setupFA';
import setupOfflinePlugin from './apps/core/src/components/OfflineUpdate/setupOfflinePlugin';

setupOfflinePlugin();
setupSentry();
setupFA();

const rootNode = document.getElementById('root');
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  rootNode
);
