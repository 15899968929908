import React from 'react';
import FeedbackUsage from '../apps/core/src/components/CXAnalytics/components/Feedback/FeedbackUsage';
import OnboardingUsage from '../apps/core/src/components/CXAnalytics/components/Onboarding/OnboardingUsage';
import { StyledPageContentInner } from './StyledPage';
import styled from 'styled-components';
import SignupUsage from '../apps/core/src/components/CXAnalytics/components/Signup/SignupUsage';

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  max-width: 1500px;
  width: 100%;
  gap: 3rem;
  margin: auto;
`;

export default function CxProductDashboard() {
  return (
    <StyledPageContentInner>
      <StyledContent>
        <SignupUsage />
        <FeedbackUsage />
        <OnboardingUsage />
      </StyledContent>
    </StyledPageContentInner>
  );
}
