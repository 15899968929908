import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  StyledDataBlock,
  StyledDataBlockBody,
  StyledDataBlockHeader,
  StyledDataBlockHeaderText,
  StyledDataItem,
  StyledDataLabel,
} from '../../styled-components/StyledDataBlocks';
import { ToDecimalPlace } from '../../utility/NumberFormatting';
import StyledScorePercentage, {
  ScoreChangePercentage,
} from '../../styled-components/StyledScorePercentage';
import {
  HelpContent,
  HelpLabel,
  HelpTooltip,
} from '../../apps/core/src/components/Tooltip/HelpTooltip';
import { Loading } from '@virtidev/toolbox';

const StyledNotation = styled.div`
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #777;
`;

const TableKnowledgeGainAvg = ({
  firstCorrect,
  headerText,
  latestCorrect,
  latestCorrectLabel,
  latestCorrectDescription,
  firstCorrectLabel,
  firstCorrectDescription,
  showNAForScores,
  loading,
  ...props
}) => {
  const numLatest =
    typeof latestCorrect === 'string'
      ? latestCorrect.replace('%', '')
      : latestCorrect;
  const numFirst =
    typeof firstCorrect === 'string'
      ? firstCorrect.replace('%', '')
      : firstCorrect;
  let delta = numLatest - numFirst;
  delta = ToDecimalPlace(delta, 1);
  let gain = ((numLatest - numFirst) / numFirst) * 100;
  gain = ToDecimalPlace(gain, 1);

  const showNA =
    showNAForScores || firstCorrect == null || latestCorrect == null;

  return (
    <StyledDataBlock {...props}>
      <StyledDataBlockHeader>
        <StyledDataBlockHeaderText>{headerText}</StyledDataBlockHeaderText>
        <HelpTooltip placement="bottom-end">
          <HelpLabel>{latestCorrectLabel}</HelpLabel>
          <HelpContent>{latestCorrectDescription}</HelpContent>
          <HelpLabel>{firstCorrectLabel}</HelpLabel>
          <HelpContent>{firstCorrectDescription}</HelpContent>
          <HelpLabel>Knowledge Delta</HelpLabel>
          <HelpContent>
            The difference between the {firstCorrectLabel} and{' '}
            {latestCorrectLabel}.
          </HelpContent>
          <HelpLabel>Knowledge Gain</HelpLabel>
          <HelpContent>
            The percentage of the {firstCorrectLabel} gained to reach the{' '}
            {latestCorrectLabel}, showing how much improvement has been made
            relative to when the learners started.
          </HelpContent>
        </HelpTooltip>
      </StyledDataBlockHeader>
      <StyledDataBlockBody>
        <div>
          <StyledDataItem data-testid="dashboard-avg-latest-correct">
            {loading ? (
              <Loading />
            ) : (
              <StyledScorePercentage
                align="center"
                showNA={showNA}
                score={latestCorrect}
              />
            )}
          </StyledDataItem>
          <StyledDataLabel>{latestCorrectLabel}</StyledDataLabel>
        </div>
        <StyledNotation>-</StyledNotation>
        <div>
          <StyledDataItem data-testid="dashboard-avg-first-correct">
            {loading ? (
              <Loading />
            ) : (
              <StyledScorePercentage
                align="center"
                showNA={showNA}
                score={firstCorrect}
              />
            )}
          </StyledDataItem>
          <StyledDataLabel>{firstCorrectLabel}</StyledDataLabel>
        </div>
        <StyledNotation>=</StyledNotation>
        <div>
          <StyledDataItem data-testid="dashboard-users-online">
            {loading ? (
              <Loading />
            ) : (
              <StyledScorePercentage
                showNA={showNA}
                align="center"
                score={delta}
                zeroBased={true}
              />
            )}
          </StyledDataItem>
          <StyledDataLabel>Knowledge Delta</StyledDataLabel>
        </div>
        <div>
          <StyledDataItem data-testid="dashboard-users-online">
            {loading ? (
              <Loading />
            ) : (
              <ScoreChangePercentage
                align="center"
                score={gain}
                showNA={numFirst === 0 || showNA}
              />
            )}
          </StyledDataItem>
          <StyledDataLabel>Knowledge Gain</StyledDataLabel>
        </div>
      </StyledDataBlockBody>
    </StyledDataBlock>
  );
};

TableKnowledgeGainAvg.defaultProps = {
  headerText: 'Knowledge gain averaged across all users',
  firstCorrectLabel: 'Avg. First Score',
  firstCorrectDescription:
    'The average score from the first Completed Session for all Learners.',
  latestCorrectLabel: 'Avg. Best Score',
  latestCorrectDescription:
    'The average best score for all Leaners from all their Completed Sessions.',
};

TableKnowledgeGainAvg.propTypes = {
  latestCorrect: PropTypes.number,
  firstCorrect: PropTypes.number,
  headerText: PropTypes.string,
  firstCorrectLabel: PropTypes.string,
  latestCorrectLabel: PropTypes.string,
  showNAForScores: PropTypes.bool,
};

export default TableKnowledgeGainAvg;
