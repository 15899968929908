import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  FC,
  ChangeEvent,
  FocusEvent,
  MouseEvent,
} from 'react';
import Button from '../../../../../../components/buttons/Button';
import {
  GetSubtitleTime,
  isValidSubtitleTime,
} from '../../../../../../utility/TimeFormatting';
import {
  SanitizeFileName,
  SaveFile,
} from '../../../../../../utility/FileUtility';
import _ from 'lodash';
import {
  convertSrtToSubsState,
  getSrtTimestampAsNum,
  getSubsFileStr,
} from './subtitles.utils';
import {
  Container,
  DeleteButton,
  LeftSaveBar,
  NewButtonWrapper,
  RestyledSaveBar,
  ReStyledTextArea,
  Subtitle,
  SubtitlesWrapper,
  TimeInputs,
  Video,
  VideoWrapper,
} from './SubtitlesEditor.styled';
import { Button as NewButton, Icon, TextInput } from '@virtidev/toolbox';
import SRTUploader from './components/SRTUploader/SRTUploader';
import NavigationPromptManual from '../../../../../../components/NavigationPromptManual';

/**
 * @typedef {import('../../../models/video-media.types').VideoMedia} VideoMedia
 * @typedef {import('../../../models/video-media.types').Subtitle} Subtitle
 * @typedef {import('../../../models/video-media.types').SubState} SubState
 */

/**
 * @type {FC<{
 *    video: VideoMedia
 *    subtitle: Subtitle
 * }>}
 */
const SubtitlesEditor = ({ video, subtitle }) => {
  const videoRef = useRef(/** @type {HTMLVideoElement | null} */ (null));
  const [subtitles, setSubtitles] = useState(/** @type {SubState[]} */ ([]));
  const [vtt, setVtt] = useState(/** @type {string | undefined} */ (undefined));

  const [initialSubtitles, setInitialSubtitles] = useState(
    /** @type {SubState[]} */ ([])
  );

  const generateVtt = useMemo(
    () =>
      _.debounce(async (newSubs) => {
        const blob = new Blob([getSubsFileStr(newSubs, 'vtt')], {
          type: 'text/plain;charset=utf-8',
        });
        const url = window.URL.createObjectURL(blob);
        await setVtt(url);
      }, 250),
    []
  );

  useEffect(
    () => () => {
      if (vtt) {
        window.URL.revokeObjectURL(vtt);
      }
    },
    [vtt]
  );

  useEffect(() => {
    const fetchSRT = async () => {
      const srt = await fetch(subtitle.URL);
      const srtText = await srt.text();
      const newSubs = convertSrtToSubsState(srtText);
      if (!newSubs) {
        // no subs found..?
        return;
      }
      setSubtitles(newSubs);
      setInitialSubtitles(_.cloneDeep(newSubs));
      generateVtt(newSubs);
    };
    fetchSRT();
  }, [subtitle, generateVtt]);

  const sortSubs = useCallback((subs) => {
    let newSubs = [...subs];
    newSubs.sort((a, b) => {
      const aStart = getSrtTimestampAsNum(a.start);
      const bStart = getSrtTimestampAsNum(b.start);
      return aStart >= bStart ? 1 : -1;
    });
    return newSubs;
  }, []);

  const updateSubs = useCallback(
    /**
     * @param {number} index
     * @param {keyof SubState} key
     * @param {string} value
     * @param {boolean} sort
     */
    async (index, key, value, sort = false) => {
      let newSubs = [...subtitles];
      newSubs[index][key] = value;
      const startAsNum = getSrtTimestampAsNum(newSubs[index].start);
      const endAsNum = getSrtTimestampAsNum(newSubs[index].end);
      newSubs[index].invalidTimePositions = false;
      if (!isNaN(startAsNum) && !isNaN(endAsNum) && startAsNum > endAsNum) {
        newSubs[index].invalidTimePositions = true;
      }
      if (sort) {
        newSubs = sortSubs(newSubs);
      }
      setSubtitles(newSubs);
      generateVtt(newSubs);
    },
    [subtitles, generateVtt, sortSubs]
  );

  const handleOnSaved = useCallback(() => {
    setInitialSubtitles(_.cloneDeep(subtitles));
  }, [subtitles]);

  const getSrt = useCallback(() => {
    return new Blob([getSubsFileStr(subtitles, 'srt')], {
      type: 'text/plain;charset=utf-8',
    });
  }, [subtitles]);

  const isDirty = useMemo(() => {
    return !_.isEqual(initialSubtitles, subtitles);
  }, [initialSubtitles, subtitles]);

  return (
    <Container>
      <NavigationPromptManual
        when={isDirty}
        message="You have unsaved changes. Are you sure you wish to leave?"
      />
      <VideoWrapper>
        <Video
          data-testid="sim-subs-video"
          ref={videoRef}
          crossOrigin="anonymous"
          controls
          onContextMenu={(e) => e.preventDefault()}
          controlsList="nodownload"
        >
          <source src={video.EditorURL || video.SourceURL} type="video/mp4" />
          <track default src={vtt} />
        </Video>
      </VideoWrapper>
      <SubtitlesWrapper>
        {subtitles.map((sub, index) => (
          <Subtitle key={index}>
            <ReStyledTextArea
              resize={false}
              // label="Video Title"
              name={'sub-title-' + index}
              value={sub.text}
              placeholder="Enter text..."
              id={'sub-title-' + index}
              onChange={
                /** @param {ChangeEvent<HTMLTextAreaElement>} e */
                (e) => updateSubs(index, 'text', e.target.value)
              }
            />
            <TimeInputs>
              <TextInput
                // label="Video Title"
                name={'sub-start-' + index}
                value={sub.startEdit}
                placeholder="Start time..."
                id={'sub-start-' + index}
                onChange={
                  /** @param {ChangeEvent<HTMLInputElement>} e */
                  (e) => updateSubs(index, 'startEdit', e.target.value)
                }
                onBlur={
                  /** @param {FocusEvent<HTMLInputElement>} e */
                  async (e) => {
                    const validTime = isValidSubtitleTime(
                      subtitles[index].startEdit
                    );
                    if (validTime) {
                      await updateSubs(
                        index,
                        'start',
                        subtitles[index].startEdit,
                        true
                      );
                    }
                  }
                }
                invalid={
                  !isValidSubtitleTime(sub.startEdit) ||
                  sub.invalidTimePositions
                }
              />
              <TextInput
                // label="Video Title"
                // name="video-title"
                value={sub.endEdit}
                placeholder="End time..."
                name={'sub-end-' + index}
                id={'sub-end-' + index}
                onChange={
                  /** @param {ChangeEvent<HTMLInputElement>} e */
                  (e) => updateSubs(index, 'endEdit', e.target.value)
                }
                onBlur={
                  /** @param {FocusEvent<HTMLInputElement>} e */
                  async (e) => {
                    const validTime = isValidSubtitleTime(
                      subtitles[index].endEdit
                    );
                    if (validTime) {
                      await updateSubs(
                        index,
                        'end',
                        subtitles[index].endEdit,
                        true
                      );
                    }
                  }
                }
                invalid={
                  !isValidSubtitleTime(sub.endEdit) || sub.invalidTimePositions
                }
              />
            </TimeInputs>
            <div>
              <DeleteButton
                onClick={async (e) => {
                  const updatedSubs = subtitles.filter(
                    (sub, filterIndex) => filterIndex !== index
                  );
                  setSubtitles(updatedSubs);
                  generateVtt(updatedSubs);
                }}
              >
                <Icon icon="bin" color="var(--neutral-color)" />
              </DeleteButton>
            </div>
          </Subtitle>
        ))}
        <NewButtonWrapper>
          <NewButton
            onClick={() => {
              let newSubs = [
                ...subtitles,
                {
                  text: '',
                  start: GetSubtitleTime(videoRef.current?.currentTime || 0),
                  startEdit: GetSubtitleTime(
                    videoRef.current?.currentTime || 0
                  ),
                  end: GetSubtitleTime(
                    (videoRef.current?.currentTime || 0) + 1
                  ),
                  endEdit: GetSubtitleTime(
                    (videoRef.current?.currentTime || 0) + 1
                  ),
                  invalidTimePositions: false,
                },
              ];
              newSubs = sortSubs(newSubs);
              setSubtitles(newSubs);
            }}
          >
            + New Line
          </NewButton>
        </NewButtonWrapper>
      </SubtitlesWrapper>
      {/* <div>Language</div>
      <Select
        value={captionLanguage}
        options={[
          { value: 'en', label: 'English' },
          { value: 'fr', label: 'French' },
        ]}
        onChange={(val) => {
          setCaptionLanguage(val);
        }}
      />
      <div>Type</div>
      <Select
        value={captionType}
        options={[{ value: 'subtitle', label: 'Subtitle' }]}
        onChange={(val) => {
          setCaptionType(val);
        }}
      /> */}
      <RestyledSaveBar>
        <LeftSaveBar>
          <Button
            type="secondary"
            dataTestId="download-subtitles-btn"
            onClick={() => {
              SaveFile(
                getSrt(),
                `${SanitizeFileName(video.Title)}-${SanitizeFileName(
                  video.Title
                )}.srt`
              );
            }}
            icon="save"
            iconType="fa"
            iconFontSize="1rem"
            iconAllWhite={false}
          >
            Download as SRT
          </Button>
          {/* <SRTParser
            onChange={(srtStr) => {
              const newSubs = convertSrtToSubsState(srtStr);
              // NOTE: any changes to subtitles state structure should be reflected in this SRTParser onChange response
              setSubtitles(newSubs);
            }}
          /> */}
        </LeftSaveBar>
        <SRTUploader
          // captionLanguage={captionLanguage}
          // captionType={captionType}
          // saving={saving}
          // setSaving={setSaving}
          isDirty={isDirty}
          subtitle={subtitle}
          getSrt={getSrt}
          video={video}
          onSaved={handleOnSaved}
        />
      </RestyledSaveBar>
    </Container>
  );
};

export default SubtitlesEditor;
