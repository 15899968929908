import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledAnchorTag,
  StyledCompletionArea,
  StyledCompletionMessage,
  StyledCompletionTitle,
  StyledConfetti,
  StyledPageIllustration,
} from '../styled-components/StyledOnboardingDashboard';
import StyledLink from '../styled-components/StyledLink';
import Icon from './icons/Icon';

function OnboardingCompletedScreen(props) {
  return (
    <StyledCompletionArea>
      <StyledPageIllustration
        className="congratulations-screen"
        name="Ensemble"
        type="illustrations"
        size="page-illustration"
      ></StyledPageIllustration>
      <StyledCompletionTitle>
        Congrats on finishing your onboarding!
      </StyledCompletionTitle>
      <StyledCompletionMessage>
        You have completed your profile, uploaded content, created a simulation,
        added a course, and created a form! <br></br> You can find more help and
        guides in our{' '}
        <StyledAnchorTag
          href="https://help.virti.com/en/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Help Centre
        </StyledAnchorTag>
        . Enjoy the Virti platform and start improving human performance!
      </StyledCompletionMessage>
      <StyledCompletionMessage>
        Did your onboarding help you understand our platform?{' '}
        <StyledAnchorTag
          href="https://forms.virti.com/forms/b91c639c5ec8823e3263365c9fd7140f907dc87d/161"
          target="_blank"
          rel="noopener noreferrer"
        >
          Tell us how we did.
        </StyledAnchorTag>
      </StyledCompletionMessage>
      <StyledConfetti
        name="Confetti"
        type="illustrations"
        size="extra-large"
        top="15rem"
        right="8%"
      />
      <StyledConfetti
        name="Confetti"
        type="illustrations"
        size="extra-large"
        top="3rem"
        right="75%"
      />
    </StyledCompletionArea>
  );
}

OnboardingCompletedScreen.propTypes = {};

export default OnboardingCompletedScreen;
