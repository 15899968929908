import { gql } from '@apollo/client';

export const READ_HAS_CONTENT_QUEUED_FOR_COPY = gql`
  query readOneOrganisation($ID: ID!) {
    readOneOrganisation(filter: { ID: { eq: $ID } }) {
      ID
      HasContentQueuedForCopy
      HasCourseQueuedForCopy
      HasSimulationQueuedForCopy
      HasVirtualHumanQueuedForCopy
    }
  }
`;
