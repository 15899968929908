export const platformLicenseOptions = [
  { value: 'FREE_TRIAL', label: 'Free Trial' },
  { value: 'POC', label: 'POC' },
  { value: 'FULL_CONTRACT', label: 'Full Contract' },
  { value: 'PARTNER', label: 'Partner' },
  { value: 'VIRTI', label: 'Virti' },
].map(Object.freeze);

const platformLicenseOptionsMap = platformLicenseOptions.reduce(
  (acc, { value, label }) => {
    acc[value] = label;
    return acc;
  },
  {}
);

export const getLabelForPlatformLicense = (value = '') =>
  platformLicenseOptionsMap[value] || value;

export const customerStageOptions = [
  ...platformLicenseOptions,
  { value: 'PROSPECT', label: 'Prospect' },
  { value: 'CHURNED', label: 'Churned' },
  { value: 'ARCHIVED', label: 'Archived' },
].map(Object.freeze);
