import styled from 'styled-components';
import HelpTooltip from '../../../../../components/HelpTooltip';

export const StyledWrapper = styled.div`
  margin-bottom: 2rem;
`;

export const StyledHelpTooltip = styled(HelpTooltip)`
  text-transform: none;
  margin: 0;
  padding: 0;
`;

export const StyledTitleAndTooltip = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const StyledTwoBlockContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;
