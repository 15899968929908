import { TextInput } from '@virtidev/toolbox';
import styled from 'styled-components';

export const TableWrapper = styled.div`
  border-radius: var(--card-border-radius);
  background-color: var(--card-bg-color);
  box-shadow: var(--card-box-shadow);
  padding: 20px 30px;
  margin: 20px;

  button {
    text-align: left;
  }
`;

export const PaginationControlWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

export const FiltersWrapper = styled.div`
  display: flex;
  gap: 20px;
  padding: 10px 0 20px 0;
  justify-content: flex-end;
`;

export const StyledTextInput = styled(TextInput)`
  max-width: 300px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
