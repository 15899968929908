import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StyledPageContentInner } from '../../../../../../../../components/StyledPage';
import { StyledHeader } from '../../../../../../../../styled-components/StyledLoggedOutPage';
import withFlashMessaging from '../../../../../../../../HOCs/WithFlashMessaging';
import { useQuery } from '@apollo/client';
import useGetFreeAgentsCount from '../../../../../../../../utility/VHHooks/useGetFreeAgentsCount';
import { READ_ORGANISATION_VH_CAPS } from './CxListAgentUsage.query';
import { ToggleSwitch } from '@virtidev/toolbox';
import getConfig from '../../../../../../../../lib/config';

const StyledPageContentWrapper = styled.div`
  margin-top: 2rem;
`;
const StyledTable = styled.table`
  text-align: left;
  th {
    font-weight: bold;
  }
  td {
    border-bottom: 1px solid #ccc;
  }
  line-height: 1.3rem;
`;
const StyledRobotnikToggleWrapper = styled.div`
  display: flex;
  margin-left: auto;
  gap: 0.5rem;
`;

const CxListAgentUsage = (props) => {
  const [offset, setOffset] = useState(0);
  const perPage = 200;
  const { data: orgVHCaps } = useQuery(READ_ORGANISATION_VH_CAPS, {
    variables: {
      limit: perPage,
      offset,
    },
  });
  const {
    freeAgentsCount,
    isLoading: freeAgentsLoading,
    freeAgentsError,
  } = useGetFreeAgentsCount();

  const [robotnikOnly, setRobotnikOnly] = useState(false);
  const orgEdgesWithVHCaps =
    orgVHCaps?.readOrganisations?.edges?.filter((edge) =>
      robotnikOnly
        ? edge.node.VirtualHumanAPI &&
          edge.node.VirtualHumanAPI.indexOf('robotnik') !== -1
        : true
    ) ?? [];

  return (
    <StyledPageContentWrapper>
      <StyledPageContentInner>
        <StyledHeader>
          Free Agents Remaining for {getConfig('REACT_APP_PUBLIC_URL')}:{' '}
          {freeAgentsCount}
        </StyledHeader>
        <StyledRobotnikToggleWrapper>
          <button
            onClick={() => {
              setOffset(offset - perPage);
            }}
            disabled={!orgVHCaps?.readOrganisations?.pageInfo.hasPreviousPage}
          >
            Prev
          </button>
          <button
            onClick={() => {
              setOffset(offset + perPage);
            }}
            disabled={!orgVHCaps?.readOrganisations?.pageInfo.hasNextPage}
          >
            Next
          </button>
          <label htmlFor="robotnik-only">Robotnik Only</label>
          <ToggleSwitch
            name="robotnik-only"
            checked={robotnikOnly}
            onChange={(e) => {
              setRobotnikOnly(e.target.checked);
            }}
          />
        </StyledRobotnikToggleWrapper>
        <StyledTable>
          <thead>
            <tr>
              <th>Org Name</th>
              <th>VH API</th>
              <th>Freeform VHs</th>
              <th>Branching VHs</th>
            </tr>
          </thead>
          <tbody>
            {orgEdgesWithVHCaps.map((orgEdge) => {
              const freeformCount =
                (orgEdge?.node?.MedicalVirtualHumans.pageInfo.totalCount ?? 0) +
                (orgEdge?.node?.DefaultVirtualHumans.pageInfo.totalCount ?? 0);
              return (
                <tr key={orgEdge?.node?.ID}>
                  <td>{orgEdge?.node.Name}</td>
                  <td>{orgEdge?.node.VirtualHumanAPI}</td>
                  <td>
                    {freeformCount}/{orgEdge?.node.VirtualHumanFreeformLimit}
                  </td>
                  <td>
                    {orgEdge?.node?.BranchingVirtualHumans.pageInfo
                      .totalCount ?? 0}
                    /{orgEdge?.node.VirtualHumanBranchingLimit}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </StyledTable>
      </StyledPageContentInner>
    </StyledPageContentWrapper>
  );
};

CxListAgentUsage.propTypes = {
  addFlashMessage: PropTypes.func.isRequired,
};

export default withFlashMessaging(CxListAgentUsage);
