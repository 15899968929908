import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  S_tr,
  S_table,
  S_thLeft,
  S_tdLeft,
  S_tbody,
  S_scrollableWrapper,
  S_thSticky,
} from '../styled-components/StyledTable';
import {
  StyledDataBlock,
  StyledDataBlockHeader,
  StyledDataBlockHeaderText,
} from '../styled-components/StyledDataBlocks';
import Icon from './icons/Icon';
import LicensesDataItem from './LicensesDataItem';
import PercentDataItem from './PercentDataItem';

export const CxActivityByOrgTable = (props) => {
  const orgsDataObj = useMemo(
    () =>
      props.orgsDataObj.filter(({ node }) => {
        return node.Licenses > 0;
      }),
    [props.orgsDataObj]
  );

  return (
    <StyledDataBlock>
      <StyledDataBlockHeader gridCols="90% auto">
        <div>
          <StyledDataBlockHeaderText>
            Activity By Organization
          </StyledDataBlockHeaderText>
        </div>
      </StyledDataBlockHeader>
      <S_scrollableWrapper>
        <S_table>
          <S_tbody>
            <S_tr>
              <S_thSticky>Organisation</S_thSticky>
              <S_thSticky>Published Simulations</S_thSticky>
              <S_thSticky>Published Courses</S_thSticky>
              <S_thSticky>Total Users</S_thSticky>
              <S_thSticky>Activated Users</S_thSticky>

              <S_thSticky>Total Admins</S_thSticky>
              <S_thSticky>Activated Admins</S_thSticky>
              <S_thSticky>Licenses &amp; License Utilisation %</S_thSticky>
              <S_thSticky>Weekly Active Users</S_thSticky>
              <S_thSticky>Monthly Active Users</S_thSticky>
            </S_tr>
            {props.orgsDataObj &&
              orgsDataObj.map(({ node }) => {
                return (
                  <S_tr key={node.ID}>
                    <S_thLeft>{node.Name}</S_thLeft>
                    <S_tdLeft>
                      {node.Simulations.pageInfo.totalCount}
                      {` `}
                      {node.Simulations.pageInfo.totalCount > 0 ? (
                        <Icon
                          type="video_editing"
                          size="tiny"
                          name="icon-complete"
                        />
                      ) : (
                        <Icon type="redesign" name="alert-error" size="tiny" />
                      )}
                    </S_tdLeft>
                    <S_tdLeft>
                      {node.Courses.pageInfo.totalCount}
                      {` `}
                      {node.Courses.pageInfo.totalCount > 0 ? (
                        <Icon
                          type="video_editing"
                          size="tiny"
                          name="icon-complete"
                        />
                      ) : (
                        <Icon type="redesign" name="alert-error" size="tiny" />
                      )}
                    </S_tdLeft>
                    <S_tdLeft>{node.TotalUsers}</S_tdLeft>
                    <S_tdLeft>{node.ActiveUsers}</S_tdLeft>
                    <S_tdLeft>{node.TotalAdmins}</S_tdLeft>
                    <S_tdLeft>{node.ActiveAdmins}</S_tdLeft>
                    <LicensesDataItem
                      licenses={node.Licenses}
                      activeUsers={node.ActiveUsers}
                    />
                    <PercentDataItem
                      number={node.DAU7DaysChange}
                      absolute={node.DAU7Days}
                    />
                    <PercentDataItem
                      number={node.MAUChange}
                      absolute={node.MAU}
                    />
                  </S_tr>
                );
              })}
          </S_tbody>
        </S_table>
      </S_scrollableWrapper>
    </StyledDataBlock>
  );
};

CxActivityByOrgTable.propTypes = {
  orgsDataObj: PropTypes.array.isRequired,
};

export default CxActivityByOrgTable;
