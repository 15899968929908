import { useQuery } from '@apollo/client';
import { useMemo, useEffect } from 'react';
import { createFilter } from 'react-select';
import PropTypes from 'prop-types';
import useUser from '../../../../../helpers/useUser';
import { READ_USERS_BY_TYPE } from './selectUser.query';
import Select from '../../Select';
import _ from 'lodash';
import { useSelectFilter } from '../../helpers/useSelectFilter';

export const SelectUser = ({ options, userType, loading, ...props }) => {
  const { OrganisationID } = useUser();
  const [filter, filterProps] = useSelectFilter();

  const filterBy = useMemo(() => {
    const baseFilter = {
      Organisations: { ID: { eq: OrganisationID } },
    };
    // if (userType !== undefined) {
    //   return {
    //     ...baseFilter,
    //     UserType: { eq: userType },
    //   };
    // }
    return baseFilter;
  }, [OrganisationID]);

  const { data: userData, loading: userLoading } = useQuery(
    READ_USERS_BY_TYPE,
    {
      variables: {
        filterBy,
        Name: filter,
      },
    }
  );

  const users = useMemo(() => {
    if (!userData) {
      return [];
    }
    return userData.readMembers.edges
      .filter(
        ({ node }) => userType === undefined || userType === node.UserType
      )
      .map(({ node }) => ({
        value: node.ID,
        label: node.Name,
        data: { email: node.Email },
      }));
  }, [userData, userType]);

  useEffect(() => {
    const { inputValue, ref } = filterProps;
    if (!userLoading && inputValue) {
      ref.current.focus();
      ref.current.onMenuOpen();
    }
    // I do not want this to activate with inputValue change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoading]);

  const allOptions = useMemo(() => [...options, ...users], [options, users]);

  return (
    <Select
      {...props}
      {...filterProps}
      filterOption={createFilter({
        stringify: (option) =>
          `${option.label} ${option.value} ${option?.data?.data?.email}`,
      })}
      options={allOptions}
      loading={userLoading || loading}
    />
  );
};

SelectUser.defaultProps = {
  options: [],
  loading: false,
  userType: undefined,
};

SelectUser.propTypes = {
  options: PropTypes.arrayOf(PropTypes.any),
  loading: PropTypes.bool,
  userType: PropTypes.oneOf([
    'user',
    'contentcreator',
    'admin',
    'super',
    'customeradmin',
  ]),
};

export default SelectUser;
