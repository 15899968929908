import styled, { css, DefaultTheme } from 'styled-components';

export const Container = styled.div`
  ${
    /**
     * @param {{
     *    theme: DefaultTheme,
     *    $horizontal?: boolean,
     * }} p
     */
    ({ theme: { spacing }, $horizontal }) => css`
      display: flex;
      margin-bottom: var(--content-padding);
      gap: ${spacing.sm};

      ${$horizontal
        ? css`
            flex-direction: row;
            align-items: stretch;
          `
        : css`
            flex-direction: column;

            &:last-child {
              margin-bottom: 0;
            }
          `}
    `
  }
`;
