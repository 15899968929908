import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GroupTabNav from '@base/components/GroupTabNav';
import { StyledPageContentInner } from '@base/components/StyledPage';
import UsersInGroupListSubForm from '@base/forms/UsersInGroupListSubForm';
import WithGroupPageTemplate from '@base/HOCs/templates/WithGroupPageTemplate';
import OrganisationInviteCodes from '@core/components/Signup/legacy/OrganisationInviteCodes';
import { useCurrentOrganisation } from '@core/helpers/useCurrentOrganisation';

const StyledInviteCodesWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 1.5rem;
`;

const GroupPage = ({ data, userOrganisationID, refetchGroup }) => {
  useCurrentOrganisation(data.readOneVirtiGroup.OrganisationID);

  return (
    <>
      <GroupTabNav groupID={data.readOneVirtiGroup.ID} />
      <StyledPageContentInner>
        <StyledInviteCodesWrapper>
          <OrganisationInviteCodes
            groupID={data.readOneVirtiGroup.ID}
            inviteCodeUser={data.readOneVirtiGroup.InviteCodeUser}
          />
        </StyledInviteCodesWrapper>
        <UsersInGroupListSubForm
          userOrganisationID={userOrganisationID}
          data={data}
          refetchGroup={refetchGroup}
          groupID={data.readOneVirtiGroup.ID}
        />
      </StyledPageContentInner>
    </>
  );
};

GroupPage.propTypes = {
  userOrganisationID: PropTypes.number.isRequired,
};

export default WithGroupPageTemplate(GroupPage, 'edit-group', false);
