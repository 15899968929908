import {
  PanelButtons,
  PanelContent,
  PanelTitle,
  QuickButton,
  QuickPanel,
} from '../QuickActions.styled';
import QuickIcon from './QuickIcon';

export const CourseAction = () => (
  <QuickPanel>
    <QuickIcon icon="course" $type="course" />
    <PanelContent>
      <PanelTitle>Interactive Courses</PanelTitle>
      <PanelButtons>
        <QuickButton to="/courses/new">Create</QuickButton>
        <QuickButton design="outline" to="/courses">
          Browse
        </QuickButton>
      </PanelButtons>
    </PanelContent>
  </QuickPanel>
);

export default CourseAction;
