import { MockIcon, MockButton } from '../../LoadingPlaceholder';
import DataItems from '../components/DataItems/DataItems';
import {
  StyledButtonsWrapper,
  StyledTitleWrapper,
} from '../DataListCard.styled';
import {
  CourseLoadingPlaceholder,
  MockCourseImage,
  MockTitle,
} from './Loading.styled';

const items = [...Array(4)].map(() => ({ data: <MockIcon /> }));

export const LoadingCourseCard = () => (
  <CourseLoadingPlaceholder>
    <MockCourseImage />
    <StyledTitleWrapper>
      <MockTitle />
    </StyledTitleWrapper>
    <DataItems items={items} />
    <StyledButtonsWrapper>
      <MockButton />
      <MockButton />
    </StyledButtonsWrapper>
  </CourseLoadingPlaceholder>
);

export default LoadingCourseCard;
