import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StyledPageContentInner } from '../components/StyledPage';
import DBSelect from '../components/DBSelect';
import withUser from '../HOCs/WithUser';
import StyledButton from '../components/buttons/Button';

import { useMutation } from '@apollo/client';
import { COPY_USER } from '../queries/CXQueries';
import withFlashMessaging from '../HOCs/WithFlashMessaging';
import { READ_MEMBERS, READ_MEMBERS_CX } from '../queries/UserQueries';
import { StyledHeader } from '../styled-components/StyledLoggedOutPage';
import { SEARCH_ORGANISATIONS } from '../apps/core/src/pages/admin/cx/cxDashboardPages.query';
import { READ_ORGANISATION_LICENSES } from '../queries/OrganisationQueries';

const CxMoveUser = (props) => {
  const [currentOrganisationFrom, setCurrentOrganisationFrom] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentOrganisationTo, setCurrentOrganisationTo] = useState(null);

  const [mutateCopyUser, { loading: mutating }] = useMutation(COPY_USER, {
    onCompleted: () => {
      props.addFlashMessage(
        `User ${currentUser.label} added to ${currentOrganisationTo.label}`
      );
    },
    onError: () => {
      props.addFlashMessage('Failed to copy user', 'error');
    },
    refetchQueries: [READ_ORGANISATION_LICENSES],
  });

  const StyledInputWrapper = styled.div`
    margin-bottom: 1rem;
  `;

  const StyledPageContentWrapper = styled.div`
    margin-top: 2rem;
  `;

  const buttonDisabled = !currentUser || !currentOrganisationTo;
  return (
    <StyledPageContentWrapper>
      <StyledPageContentInner>
        <StyledHeader>Add User to Organisation</StyledHeader>
        <StyledInputWrapper>
          <DBSelect
            dataQuery={SEARCH_ORGANISATIONS}
            filterVar="filter"
            queryDataPath="readOrganisations.edges"
            queryNestedObjectPath="node"
            onChange={(newOrgObj) => {
              setCurrentUser(null);
              setCurrentOrganisationTo(null);
              setCurrentOrganisationFrom(newOrgObj);
              console.log(newOrgObj);
            }}
            value={currentOrganisationFrom}
            htmlId="organisation-copy-from"
            label="Organisation to Select User From"
            placeholder="Select an organisation that the user already exists in..."
            labelField="Name"
            disabled={mutating}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <DBSelect
            dataQuery={currentOrganisationFrom ? READ_MEMBERS : READ_MEMBERS_CX}
            filterVar="filter"
            queryDataPath="readMembers.edges"
            queryNestedObjectPath="node"
            dataQueryVariables={
              currentOrganisationFrom
                ? {
                    organisationID: currentOrganisationFrom.value,
                  }
                : {}
            }
            disabled={mutating}
            onChange={(newOrgObj) => {
              setCurrentOrganisationTo(null);
              setCurrentUser(newOrgObj);
            }}
            value={currentUser}
            htmlId="user-list"
            label="User"
            placeholder="Select user..."
            labelField="Name"
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <DBSelect
            dataQuery={SEARCH_ORGANISATIONS}
            filterVar="filter"
            queryDataPath="readOrganisations.edges"
            queryNestedObjectPath="node"
            disabled={!currentUser || mutating}
            onChange={(newOrgObj) => setCurrentOrganisationTo(newOrgObj)}
            value={currentOrganisationTo}
            htmlId="organisation-copy-to"
            label="Organisation to Add User To"
            placeholder="Select the organisation that you want to add the user to..."
            labelField="Name"
          />
        </StyledInputWrapper>
        <div>
          <StyledButton
            disabled={buttonDisabled}
            loading={mutating}
            onClick={() => {
              mutateCopyUser({
                variables: {
                  OrganisationID: currentOrganisationTo.value,
                  MemberID: currentUser.value,
                },
              });
            }}
            target="_blank"
          >
            Add
          </StyledButton>
        </div>
      </StyledPageContentInner>
    </StyledPageContentWrapper>
  );
};

CxMoveUser.propTypes = {
  addFlashMessage: PropTypes.func.isRequired,
};

export default withFlashMessaging(withUser(CxMoveUser));
