import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { VirtualHumanSubmissionItem } from './VirtualHumanSubmissionItem';
import { DELETE_SUBMISSION_LOG } from '../AnalyticsVirtualHuman.query';
import { useMutation } from '@apollo/client';
import { READ_VIRTUAL_HUMAN_SESSIONS } from '../../../../pages/admin/virtual-humans/VirtualHumanAnalytics.query';
import {
  ConfirmationModal,
  SortableThV2,
  TableV2,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@virtidev/toolbox';
import VirtualHumanSubmissionLoadingRow from './VirtualHumanSubmissionLoadingRow';
import { useFlashMessage } from '../../../FlashMessage';
import tracker from '../../../../helpers/tracker';
import ScoreOverviewAnalyticsAreaModal from '@core/components/Analytics/VirtualHuman/ScoreOverviewAnalyticsAreaModal/ScoreOverviewAnalyticsAreaModal';
import useUser from '@core/helpers/useUser';
import useShouldShowVHScoreOverview from '@base/utility/VHHooks/API/useShouldShowVHScoreOverview';
import useGetVHHasEvaluationsOrObjectives from '@base/utility/VHHooks/API/useGetVHHasEvaluationsOrObjectives';

const VirtualHumanAnalyticsSubmissionsTable = (props) => {
  const { addFlashMessage } = useFlashMessage();
  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(false);

  const { hasEvaluationsOrObjectives } = useGetVHHasEvaluationsOrObjectives(
    props?.vhData?.ExternalID
  );
  const { shouldShowVHScoreOverview } = useShouldShowVHScoreOverview(
    props?.vhData?.ExternalID
  );

  let someScoresAreMedical =
    props?.vhData?.Type === 'medical' ||
    props.analyticsSubmissions?.some?.(
      (submission) => submission?.VirtualHuman?.Type === 'medical'
    );

  const [deleteSubmission] = useMutation(DELETE_SUBMISSION_LOG, {
    refetchQueries: [READ_VIRTUAL_HUMAN_SESSIONS],
  });

  const startDelete = useCallback((id) => {
    setDeleteId(id);
  }, []);

  const closeDelete = useCallback(() => {
    setDeleteId(null);
  }, []);

  const finishDelete = useCallback(async () => {
    setDeleting(true);
    await deleteSubmission({ variables: { IDs: [deleteId] } });

    const submission = props.analyticsSubmissions?.find?.(
      (item) => item?.ID === deleteId
    );

    tracker.track('debug', {
      type: 'delete_analytics_submission',
      virtual_human_id: props?.vhData?.ID,
      external_vh_id: props?.vhData?.ExternalID,
      submission_id: deleteId,
      assessment_id: submission?.PDAssessmentID,
    });

    setDeleting(false);
    setDeleteId(null);

    addFlashMessage(
      `The submission log has been deleted for the Virtual Human "${props.vhData?.Title}"`
    );
  }, [
    deleteId,
    deleteSubmission,
    addFlashMessage,
    props.vhData?.Title,
    props.vhData?.ExternalID,
    props.vhData?.ID,
    props.analyticsSubmissions,
  ]);

  const sortProps =
    props.handleSort && props.currentSort
      ? {
          onSort: props.handleSort,
          currentSort: props.currentSort,
        }
      : null;

  const [scoreOverviewScoreID, setScoreOverviewScoreID] = useState(null);
  const handleScoreOverviewOpen = useCallback((scoreID) => {
    setScoreOverviewScoreID(scoreID);
  }, []);
  const overviewScore = useMemo(() => {
    return (
      scoreOverviewScoreID &&
      props?.analyticsSubmissions.find(
        (submission) => submission?.ID === scoreOverviewScoreID
      )
    );
  }, [scoreOverviewScoreID, props?.analyticsSubmissions]);

  const handleHideModal = useCallback(() => {
    setScoreOverviewScoreID(null);
  }, []);
  const showScores = hasEvaluationsOrObjectives || props.showScores;
  const { UserType } = useUser();
  const showRegenerateButton = UserType !== 'user';

  return (
    <>
      <ConfirmationModal
        show={!!deleteId && !deleting}
        verificationText="delete"
        render={() => {
          const submission = props.analyticsSubmissions?.find?.(
            (item) => item?.ID === deleteId
          );
          if (!submission) {
            return;
          }
          const userMessage = submission?.Member?.Name
            ? ` from ${submission?.Member?.Name}`
            : '';

          return `Are you sure about deleting this submission log${userMessage} for the Virtual Human "${props.vhData?.Title}"?`;
        }}
        onHide={closeDelete}
        onConfirm={finishDelete}
      />
      {overviewScore && (
        <ScoreOverviewAnalyticsAreaModal
          showRegenerateButton={showRegenerateButton}
          SSID={props.vhData?.ID ?? overviewScore?.VirtualHuman?.ID}
          vhId={
            props.vhData?.ExternalID ?? overviewScore?.VirtualHuman?.ExternalID
          }
          vhHideScoresFromUser={props.vhData?.HideScoresFromUsers}
          analyticsSubmission={overviewScore}
          onHideModal={handleHideModal}
        />
      )}
      <TableV2>
        <Thead>
          <Tr>
            {props.showPosition && <Th>Position</Th>}
            {props.nameColumn === 'user' && <Th>User</Th>}
            {props.nameColumn === 'virtual-human' && <Th>Virtual Human</Th>}
            {sortProps ? (
              <SortableThV2 {...sortProps} sortField="Created">
                Date
              </SortableThV2>
            ) : (
              <Th>Date</Th>
            )}
            <Th $center={true}>Time taken</Th>
            {showScores && <Th $center={true}>Conversation score</Th>}
            {someScoresAreMedical && <Th $center={true}>Success</Th>}
            {shouldShowVHScoreOverview && <Th $center={true}>Report</Th>}
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.analyticsSubmissions &&
            props.analyticsSubmissions.length === 0 && (
              <Tr>
                <Td $center={true} colSpan="10">
                  No submissions found.
                </Td>
              </Tr>
            )}
          {props.analyticsSubmissions &&
            props.analyticsSubmissions.map((score, index) => {
              if (props.loading || (deleteId === score.ID && deleting)) {
                return (
                  <VirtualHumanSubmissionLoadingRow
                    key={score?.ID || index + 1}
                    showPosition={props.showPosition}
                    nameColumn={props.nameColumn}
                    medicalScores={someScoresAreMedical}
                  />
                );
              }
              const vhID = props.vhData?.ID ?? score.VirtualHuman?.ID ?? 'N/A';
              const ExternalID =
                props.vhData?.ExternalID ??
                score.VirtualHuman?.ExternalID ??
                'N/A';
              const memberID =
                props.memberData?.ID ?? score.Member?.ID ?? 'N/A';
              const analyticsLink = props.useUserAnalyticsLink
                ? `/users/${memberID}/analytics/virtual-humans/${vhID}`
                : `/virtual-humans/${vhID}/analytics/user/${memberID}`;
              const memberName =
                props.memberData?.Name ?? score.Member?.Name ?? 'N/A';
              const vhName =
                props.vhData?.Title ?? score.VirtualHuman?.Title ?? 'N/A';

              return (
                <VirtualHumanSubmissionItem
                  onScoreOverviewOpen={handleScoreOverviewOpen}
                  key={score.ID}
                  showPosition={props.showPosition}
                  position={props.offset + index + 1}
                  score={score}
                  nameColumn={props.nameColumn}
                  link={props.noLinks ? undefined : analyticsLink}
                  name={props.nameColumn === 'user' ? memberName : vhName}
                  medicalScores={someScoresAreMedical}
                  vhID={vhID}
                  ExternalID={ExternalID}
                  onDelete={startDelete}
                  showScore={showScores}
                  canDelete={props.canDelete}
                  isOwnLog={props.areOwnLogs}
                  shouldShowVHScoreOverview={shouldShowVHScoreOverview}
                />
              );
            })}
        </Tbody>
      </TableV2>
    </>
  );
};

VirtualHumanAnalyticsSubmissionsTable.defaultProps = {
  showPosition: false,
  useUserAnalyticsLink: false,
  canDelete: true,
  areOwnLogs: false,
};

VirtualHumanAnalyticsSubmissionsTable.propTypes = {
  analyticsSubmissions: PropTypes.arrayOf(PropTypes.object),
  showPosition: PropTypes.bool,
  nameColumn: PropTypes.string,
  offset: PropTypes.number,
  noLinks: PropTypes.bool,
  loading: PropTypes.bool,
  useUserAnalyticsLink: PropTypes.bool,
  vhType: PropTypes.bool,
  vhData: PropTypes.shape({
    ID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    Title: PropTypes.string,
    Type: PropTypes.string,
    ExternalID: PropTypes.any,
  }),
  memberData: PropTypes.shape({
    ID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    Name: PropTypes.string,
  }),
  handleSort: PropTypes.func,
  currentSort: PropTypes.object,
  canDelete: PropTypes.bool,
  areOwnLogs: PropTypes.bool,
  showScores: PropTypes.bool,
};

export default VirtualHumanAnalyticsSubmissionsTable;
