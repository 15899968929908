import { Modal } from '@virtidev/toolbox';
import styled from 'styled-components';

export const ResizedModal = styled(Modal)`
  .modal-content {
  }

  .modal-box {
    max-width: 600px;
  }
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;

  button:last-child {
    margin-left: auto;
  }
`;

export const LabelTooltipWrapper = styled.span`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const WarningText = styled.div`
  color: ${({ theme }) => theme.color.error};
`;
