import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';

import StyledCheckboxes from '../styled-components/StyledCheckboxes';
import LoadingIndicator from './LoadingIndicator';
import { READ_GROUP_NAMES } from '../queries/GroupQueries';
import StyledLabel from '../styled-components/StyledLabel';
import { GetFuncSortEdgesBy } from '../utility/Sort';
import withFlashMessaging from '../HOCs/WithFlashMessaging';
import withUser from '../HOCs/WithUser';
import { StyledLinkAsBtn } from '../styled-components/StyledLink';
import styled from 'styled-components';
import { useFlashMessage } from '../apps/core/src/components/FlashMessage';

const StyledTitleWrapper = styled.div``;
const StyledUndoBtn = styled(StyledLinkAsBtn)`
  position: absolute;
  right: 1.3rem;
  top: 0;
`;

/** this now hackily works differently for sims and users so  should be refactored */

const GroupCheckboxContent = (props) => {
  const { addFlashMessage } = useFlashMessage();

  const [mutateUpdate, { loading: updating }] = useMutation(
    props.updateMutation ?? props.addMutation, // hackily use wrong backup mutations to prevent breaking, backups shouldn't actually be used
    {
      onError: () => {
        addFlashMessage('Error updating the associated groups.', 'error');
      },
      update: props.apolloUpdateAfterUpdate,
    }
  );
  const [mutateAdd, { loading: adding }] = useMutation(
    props.addMutation ?? props.updateMutation,
    {
      onError: () => {
        addFlashMessage('Error adding the group association.', 'error');
      },
      update: props.apolloUpdateAfterAdd,
    }
  );
  const [mutateDelete, { loading: deleting }] = useMutation(
    props.removeMutation ?? props.updateMutation,
    {
      onError: () => {
        addFlashMessage('Error removing the group association.', 'error');
      },
      update: props.apolloUpdateAfterRemove,
    }
  );

  const mutating = updating || adding || deleting;

  const checkboxData = [...props.groupEdges]
    .sort(GetFuncSortEdgesBy('Name'))
    .map((groupEdge) => {
      return {
        checked: props.checkedGroupIDs.includes(groupEdge.node.ID),
        label: groupEdge.node.Name,
        htmlID: 'group-check-item-' + groupEdge.node.ID,
        ID: groupEdge.node.ID.toString(),
      };
    });

  const [checkboxHistory, setCheckboxHistory] = useState([
    props.checkedGroupIDs,
  ]);

  const addCheckboxHistory = (IDs) => {
    setCheckboxHistory([...checkboxHistory, IDs]);
  };
  const goBackOneInHistory = () => {
    setCheckboxHistory(checkboxHistory.slice(0, checkboxHistory.length - 1));
  };

  return (
    <StyledCheckboxes
      name="user-groups"
      title={
        <StyledTitleWrapper>
          <StyledLabel loading={mutating}>
            Give Access (
            {props.checkedGroupIDs.length === 0
              ? 'All'
              : props.checkedGroupIDs.length}
            ){' '}
          </StyledLabel>
          {/* requires update methodology to be able to use undo  */}
          {props.updateMutation && checkboxHistory.length > 1 && (
            <StyledUndoBtn
              disabled={mutating}
              onClick={(e) => {
                const undoToIDs = checkboxHistory[checkboxHistory.length - 2];
                mutateUpdate({
                  variables: {
                    ID: props.mainObjectID,
                    GroupIDs: undoToIDs,
                    ...props.mutationVariables,
                  },
                });
                goBackOneInHistory();
              }}
            >
              Undo
            </StyledUndoBtn>
          )}
        </StyledTitleWrapper>
      }
      disabled={mutating}
      loading={mutating}
      scrollableHeight={props.scrollableHeight ?? '19rem'}
      onCheckboxStateChange={({ newState, itemID, newValue }) => {
        const groupIDs = Object.keys(newState.checked).filter(
          (objID) => newState.checked[objID]
        );

        if (props.updateMutation) {
          const mutateVarData = {
            variables: {
              ID: props.mainObjectID,
              GroupIDs: groupIDs,
              ...props.mutationVariables,
            },
          };
          addCheckboxHistory(groupIDs);
          mutateUpdate(mutateVarData);
        } else if (props.addMutation && props.removeMutation) {
          // hackily accommodate use of add and delete instead of update
          if (newValue === true) {
            mutateAdd({
              variables: {
                GroupID: itemID,
                MemberID: props.mainObjectID,
                ...props.mutationVariables,
              },
            });
          } else {
            mutateDelete({
              variables: {
                GroupID: itemID,
                MemberID: props.mainObjectID,
                ...props.mutationVariables,
              },
            });
          }
        }
      }}
      separateChecked={true}
      separatedOptions={
        props.showEveryoneCheckbox
          ? [
              {
                label: 'Everyone',
                ID: 'none',
                name: 'groups-everyone',
                htmlID: 'groups-everyone',
                onChange: () => {
                  if (props.updateMutation) {
                    addCheckboxHistory([]);
                    mutateUpdate({
                      variables: {
                        ID: props.mainObjectID,
                        GroupIDs: [],
                        ...props.mutationVariables,
                      },
                    });
                  }
                },
                checked: props.checkedGroupIDs.length === 0,
                disabled: mutating || props.checkedGroupIDs.length === 0,
              },
            ]
          : []
      }
      data={checkboxData}
      greyFormBG={props.greyFormBG}
    />
  );
};

const ScrollableGroupCheckboxList = (props) => {
  const { loading, error, data } = useQuery(READ_GROUP_NAMES, {
    variables: {
      organisationID: props.userOrganisationID,
    },
  });

  if (props.objectNotYetExists) {
    return (
      <React.Fragment>
        <StyledLabel>Give Access</StyledLabel>
        <div style={{ lineHeight: '1.5rem' }}>
          You need to save before editing group memberships
        </div>
      </React.Fragment>
    );
  }
  if (loading) return <LoadingIndicator />;
  if (error) return <p>Error :(</p>;
  return (
    <GroupCheckboxContent {...props} groupEdges={data.readVirtiGroups.edges} />
  );
};

ScrollableGroupCheckboxList.defaultProps = {
  objectNotYetExists: false,
  greyFormBG: false,
  userOrganisationID: PropTypes.number.isRequired,
  mutationVariables: {},
};

ScrollableGroupCheckboxList.propTypes = {
  mainObjectID: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  updateMutation: PropTypes.object,
  addMutation: PropTypes.object,
  removeMutation: PropTypes.object,
  checkedGroupIDs: PropTypes.array.isRequired,
  mutationVariables: PropTypes.object,
  type: PropTypes.string.isRequired,
  greyFormBG: PropTypes.bool,
  objectNotYetExists: PropTypes.bool,
  showEveryoneCheckbox: PropTypes.bool,
  scrollableHeight: PropTypes.string,
  apolloUpdateAfterAdd: PropTypes.func,
  apolloUpdateAfterRemove: PropTypes.func,
  apolloUpdateAfterUpdate: PropTypes.func,
};

export default withUser(withFlashMessaging(ScrollableGroupCheckboxList));
