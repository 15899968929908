import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 0;
  background: none;
  border-radius: 8px;
  font-size: 1rem;
  padding: 0.8rem 1.2rem;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    background: #ebf8ff;
  }
`;
