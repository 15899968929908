import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';

import withUser from '../HOCs/WithUser';
import StyledPage, {
  StyledPageContentInner,
  StyledTopArea,
} from '../components/StyledPage';
import { READ_SIMULATIONS_PAGINATED } from '../queries/SimulationQueries';
import RedirectWithPrevState from '../components/RedirectWithPrevState';
import StyledTextInput from '../styled-components/StyledTextInput';
import PaginatedSimulationsList from '../components/PaginatedSimulationsList';
import StyledPageHeader from '../styled-components/StyledPageHeader';

const StyledSearch = styled(StyledTextInput)`
  margin-left: auto;
`;

class MySimulationsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFilter: '',
      typedFilter: '',
      publishStatus: '',
      activeUserGroupFilter: '',
      pagination: {
        limit: 12,
        page: 1,
      },
    };
    this._changeTypedFilter = this._changeTypedFilter.bind(this);
    this._changeDBFilter = _.debounce(this._changeDBFilter.bind(this), 500);
  }

  _changeFilter = (e) => {
    this._changeTypedFilter(e.target.value);
  };

  _changeTypedFilter = (newFilter) => {
    this.setState(
      {
        typedFilter: newFilter,
      },
      () => this._changeDBFilter(newFilter)
    );
  };

  _changeDBFilter = (newFilter) => {
    this.setState({
      activeFilter: newFilter,
      pagination: { ...this.state.pagination, page: 1 },
    });
  };

  render() {
    if (!this.props.userID) {
      return <RedirectWithPrevState to="/login" />;
    }
    return (
      <StyledPage pageKey="simulations">
        <StyledPageContentInner card={false}>
          <StyledTopArea gridCols="1fr 280px">
            <StyledPageHeader>My Simulations</StyledPageHeader>
            <div>
              <StyledSearch
                filterStyle
                placeholder="Search"
                value={this.state.typedFilter}
                id="filter"
                onChange={this._changeFilter}
              />
            </div>
          </StyledTopArea>
          <PaginatedSimulationsList
            dataQuery={READ_SIMULATIONS_PAGINATED}
            userMode="user"
            dataQueryVariables={{
              filter: {
                Title: { contains: this.state.activeFilter },
                Status: { eq: 'Published' },
                CoursesOnly: { eq: false },
              },
            }}
            queryName="readSimulations"
            paginationState={this.state.pagination}
            changePageCallback={(newPage) =>
              this.setState({
                pagination: { ...this.state.pagination, page: newPage },
              })
            }
            userOrganisationID={this.props.userOrganisationID}
          />
        </StyledPageContentInner>
      </StyledPage>
    );
  }
}

MySimulationsPage.propTypes = {
  userID: PropTypes.number,
  match: PropTypes.object.isRequired,
  userOrganisationID: PropTypes.number.isRequired,
};

export default withUser(MySimulationsPage);
