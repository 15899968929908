import React from 'react';
import PropTypes from 'prop-types';
import StyledTextInput from '../../styled-components/StyledTextInput';
import {
  StyledCustomisedInputEditWrapper,
  StyledCustomisedInputPreviewWrapper,
  StyledCustomisedInputEditInputWrapper,
  StyledCustomisedInputPreviewInputWrapper,
  StyledCustomisedInputHeader,
  StyledLabel,
} from '../../styled-components/StyledCustomisedInputs';

import { Icon, TextInput } from '@virtidev/toolbox';
import Details from '../../apps/core/src/components/FeedbackForms/Question/Details/Details';
import Label from '../../apps/core/src/components/form/Label';

const CustomisedInputText = (props) => {
  return (
    <>
      {props.editing && (
        <StyledCustomisedInputEditWrapper>
          <Details
            editing={props.editing}
            register={props.register}
            typeTitle="Short Answer
            Question"
            info="Respondents can answer with a single line of text."
            icon={'singlequestion'}
          >
            <StyledCustomisedInputEditInputWrapper>
              <Label>Placeholder</Label>
              <TextInput
                placeholder="Placeholder..."
                {...props.register('Placeholder')}
              />
            </StyledCustomisedInputEditInputWrapper>
            <StyledCustomisedInputEditInputWrapper>
              <Label>Character Limit</Label>
              <TextInput
                {...props.register('CharacterLimit')}
                type="number"
                step="1"
                min={0}
                pattern="\d*"
                disabled={props.disabled}
                max={9999999}
              />
            </StyledCustomisedInputEditInputWrapper>
          </Details>
        </StyledCustomisedInputEditWrapper>
      )}
      {!props.editing && (
        <StyledCustomisedInputPreviewWrapper>
          <StyledCustomisedInputPreviewInputWrapper>
            <StyledCustomisedInputHeader>
              <Icon icon="singlequestion" size="35px" color="#ccc" />
            </StyledCustomisedInputHeader>
            <StyledLabel>{props.question.Title}</StyledLabel>

            <StyledTextInput
              placeholder={props.question.Placeholder}
              value=""
              onChange={() => null}
            />
          </StyledCustomisedInputPreviewInputWrapper>
        </StyledCustomisedInputPreviewWrapper>
      )}
    </>
  );
};

CustomisedInputText.propTypes = {
  question: PropTypes.object,
  removeQuestion: PropTypes.func,
  disabled: PropTypes.bool,
  onQuestionEdit: PropTypes.func,
  otherQuestions: PropTypes.array,
  index: PropTypes.number,
};

export default CustomisedInputText;
