import styled, { css, DefaultTheme } from 'styled-components';

export const Container = styled.label`
  ${
    /**
     * @param {{ theme: DefaultTheme, $horizontal?: boolean }} p
     */
    ({ theme: { font }, $horizontal }) => css`
      display: flex;
      ${font.label}

      ${$horizontal
        ? css`
            flex-direction: row;
            align-items: center;
            margin-bottom: 0;
            margin-right: 16px;
          `
        : css`
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 16px;
          `}
    `
  }
`;
