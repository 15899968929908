import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { getResizeImage } from '../../../../helpers/imageResizing/getResizeImage';
import { Image } from '@virtidev/toolbox';

const PreviewImage = (props) => {
  const imageSrc = useMemo(() => {
    if (props.ImageMedia?.TusID) {
      return getResizeImage(props.ImageMedia.TusID);
    }
    return props.ThumbnailURL;
  }, [props.ImageMedia, props.ThumbnailURL]);

  return <Image src={imageSrc} />;
};

PreviewImage.propTypes = {
  ThumbnailURL: PropTypes.string,
  ImageMedia: PropTypes.object,
};

export default PreviewImage;
