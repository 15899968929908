import React, { useState } from 'react';
import PropTypes from 'prop-types';
import GroupTabNav from '../components/GroupTabNav';

import { StyledPageContentInner } from '../components/StyledPage';
import WithGroupPageTemplate from '../HOCs/templates/WithGroupPageTemplate';

import { READ_COURSES_PAGINATED_FOR_GROUP } from '../queries/CourseQueries';

import PaginatedCoursesList from '../components/PaginatedCoursesList';

const GroupCoursesPage = (props) => {
  const [pagination, setPagination] = useState({ limit: 12, page: 1 });

  return (
    <>
      <GroupTabNav groupID={props.data.readOneVirtiGroup.ID} />
      <StyledPageContentInner>
        <PaginatedCoursesList
          dataQuery={READ_COURSES_PAGINATED_FOR_GROUP}
          dataQueryVariables={{
            filter: {
              OrganisationGroups: {
                ID: { eq: props.data.readOneVirtiGroup.ID },
              },
            },
          }}
          queryName="readCourses"
          paginationState={pagination}
          changePageCallback={(newPage) =>
            setPagination({ ...pagination, page: newPage })
          }
        />
      </StyledPageContentInner>
    </>
  );
};

GroupCoursesPage.propTypes = {
  userOrganisationID: PropTypes.number.isRequired,
};

export default WithGroupPageTemplate(GroupCoursesPage, 'group-courses', false);
