import styled, { css } from 'styled-components';

const StyledInputWrapper = styled.div`
  margin-bottom: 2rem;
  ${props => props.gridArea && css`
    grid-area: ${props.gridArea};
  `}
`;

const StyledSections = styled.div`
`;
const StyledSection = styled.div`
  padding: 1.5rem var(--content-side-padding) 0 var(--content-side-padding);
  &:last-child {
    border-bottom: none;
  }
`;
const StyledSectionInner = styled.div`
  /* max-width: ${props => props.noMaxWidth ? 'initial' : 'var(--form-max-width);'}; */
`;
const StyledSubmitBtnWrapper = styled.div`
  grid-area: Button;
  text-align: right;
`
const StyledValidationMessagesWrapper = styled.div`
  grid-area: ValidationMessages;
`

export { StyledInputWrapper, StyledSections, StyledSection, StyledSectionInner, StyledSubmitBtnWrapper, StyledValidationMessagesWrapper }