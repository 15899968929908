import React, { useMemo } from 'react';

import withUser from '../WithUser';
import withFlashMessaging from '../WithFlashMessaging';

import StyledPage from '../../components/StyledPage';
import LoadingPageIndicator from '../../components/LoadingPageIndicator';
import PageLoadError from '../../components/PageLoadError';
import RedirectWithPrevState from '../../components/RedirectWithPrevState';
import DeleteGroupBtnInMenu from '../../components/buttons/DeleteGroupBtnInMenu';
import { READ_GROUP, EDIT_GROUP } from '../../queries/GroupQueries';
import { useMutation, useQuery } from '@apollo/client';
import HeaderArea from '../../components/HeaderArea';

const WithGroupPageTemplate = (
  WrappedComponent,
  pageKey = undefined,
  hasSaveBar = false
) => {
  const GroupPageTemplate = (props) => {
    const { data, loading, error, refetch } = useQuery(READ_GROUP, {
      variables: {
        ID: props.match.params.id,
      },
      onError: (err) => {
        props.addFlashMessage('There was an error loading the group.', 'error');
      },
    });

    const [editGroupName, { loading: editingGroupName }] = useMutation(
      EDIT_GROUP,
      {
        onError: (err) => {
          props.addFlashMessage(
            'There was an error updating the group name.',
            'error'
          );
        },
        onCompleted: () => {
          props.addFlashMessage('Group name successfully updated!');
        },
      }
    );

    const group = useMemo(() => data?.readOneVirtiGroup, [data]);

    if (!props.userID) {
      return <RedirectWithPrevState to="/login" />;
    }

    return (
      <StyledPage
        pageKey={pageKey}
        hasSaveBar={hasSaveBar}
        goBackToLinkText="all groups"
        goBackToLink="/groups"
      >
        {loading && <LoadingPageIndicator />}
        {error && <PageLoadError graphQLErrorObj={error}></PageLoadError>}
        {group ? (
          <>
            <HeaderArea
              showImg={false}
              title={group.Name}
              subtitle={group.Email}
              loading={loading || editingGroupName}
              groupID={group.ID}
              type="course"
              editTitleCallback={(newTitle) =>
                editGroupName({
                  variables: {
                    Input: {
                      ID: group.ID,
                      Name: newTitle,
                    },
                  },
                })
              }
              deleteGroupCallback={true}
              editTitleMenuButtons={
                <>
                  <DeleteGroupBtnInMenu
                    userOrganisationID={props.userOrganisationID}
                    groupID={group.ID}
                  />
                </>
              }
            />
            <WrappedComponent {...props} data={data} refetchGroup={refetch} />
          </>
        ) : (
          <PageLoadError message="No group found."></PageLoadError>
        )}
      </StyledPage>
    );
  };
  return withFlashMessaging(withUser(GroupPageTemplate));
};
export default WithGroupPageTemplate;
