import {
  forwardRef,
  ComponentType,
  ForwardRefExoticComponent,
  useMemo,
} from 'react';
import {
  useStateUrl as useStateUrlToolbox,
  StateUrlConfig,
  StateUrlResults,
} from '@virtidev/toolbox';

export const useStateUrl = useStateUrlToolbox;

/**
 * @param {Partial<Omit<StateUrlConfig, 'onChange'>>} options
 * @returns {(Component: ComponentType<any>) => ComponentType<any>}
 */
export const withStateUrl = (options) => (Component) => {
  /**
   * @type {ForwardRefExoticComponent<{
   *    stateUrl: { [key: string]: StateUrlResults }
   * }>}
   */
  const StateUrlProvider = forwardRef(
    ({ stateUrl: stateUrlProps, ...props }, ref) => {
      const stateManager = useStateUrl(options);

      const stateUrl = useMemo(
        () => ({
          ...stateUrlProps,
          [options.key ?? 'filter']: stateManager,
        }),
        [stateUrlProps, stateManager]
      );

      return <Component ref={ref} {...props} stateUrl={stateUrl} />;
    }
  );

  return StateUrlProvider;
};

export default useStateUrl;
