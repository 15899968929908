import { gql } from '@apollo/client';

export const CREATE_VH = gql`
  mutation copyVirtualHuman(
    $VirtualHumanID: ID!
    $OrganisationID: ID!
    $VHVoiceID: ID
    $EnvironmentID: ID
    $AvatarID: ID
    $Title: String
    $AdminTitle: String
    $Description: String
    $Locale: String
    $CreationMethod: String
    $VHDuplicateDataJSON: String
  ) {
    copyVirtualHuman(
      VirtualHumanID: $VirtualHumanID
      OrganisationID: $OrganisationID
      VHVoiceID: $VHVoiceID
      EnvironmentID: $EnvironmentID
      AvatarID: $AvatarID
      Title: $Title
      AdminTitle: $AdminTitle
      Description: $Description
      Locale: $Locale
      CreationMethod: $CreationMethod
      VHDuplicateDataJSON: $VHDuplicateDataJSON
    ) {
      ID
      Title
      ExternalID
      ThumbnailURL
      CreationMethod
      ImageMedia {
        ID
        TusID
      }
    }
  }
`;
