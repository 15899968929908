import React, { useEffect, useMemo, useState, FC } from 'react';
import toWebVTT from 'srt-webvtt';
import { supported_languages } from '../languages';

/**
 * @typedef {import('../../../../models/video-media.types').Subtitle} Subtitle
 */

/**
 * @type {FC<{
 *    subtitle: Subtitle,
 *    isDefault?: boolean,
 * }>}
 */
export const SubtitleTrack = ({ subtitle, isDefault }) => {
  const [vtt, setVtt] = useState('');

  useEffect(() => {
    if (!subtitle) {
      return;
    }
    const abort = new AbortController();

    fetch(subtitle.URL, { signal: abort.signal })
      .then((response) => response.blob())
      .then(async (blob) => {
        const url = await toWebVTT(blob);

        setVtt(url);
      })
      .catch(() => null);

    return () => {
      abort.abort('Unmounting component');
    };
  }, [setVtt, subtitle]);

  const lang = useMemo(() => {
    const language = supported_languages.find(
      (item) => item.label === subtitle.Language
    );

    return language?.value;
  }, [subtitle.Language]);

  return (
    <track
      label={subtitle.Language}
      kind={subtitle.Type || 'subtitles'}
      srcLang={lang}
      src={vtt}
      default={isDefault}
    />
  );
};

export default SubtitleTrack;
