import { gql } from '@apollo/client';

const pseudoFeedbackFormFragmentBase = `
  ID
  Title
  AdminTitle
  Description
  Type
  Trigger
  Hash
  OrganisationID
`;

const pseudoFeedbackFormPagesFragment = `
  Pages {
    nodes {
    ID
    Title
    SortOrder
    Questions {
      nodes {
        ID
        Title
        Length
        FirstLabel
        LastLabel
        Required
        Placeholder
        Type
        CharacterLimit
        SortOrder
        Options {
            nodes {
            ID
            Name
            Value
            }
          }
        }
      }
    }
  }
`;

const pseudoFeedbackFormSimulationsFragment = `

`;

const pseudoFeedbackFormVirtualHumansFragment = `
VirtualHumans {
  edges {
    node {
      ID
      Title
    }
  }
}
`;

const pseudoFeedbackFormSubmissionsFragment = `
  Submissions {
    edges {
      node {
        ID
        Created
        Member {
          Name
        }
      }
    }
  }
      `;

const pseudoFeedbackFormSubmissionsWithAnswersFragment = `
Submissions {
  edges {
    node {
      ID
      Created
      Answers {
        edges {
          node {
            ID
            QuestionID
            Answer
            Option {
              ID
              Name
              Value
            }
            Options {
              edges {
                node {
                  ID
                  Name
                  Value
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

// need to make this use an org id
export const READ_FEEDBACK_FORMS = gql`
  query readFeedbackFormsWithSubmissionCount(
    $filter: FeedbackFormFilterFields
    $sort: FeedbackFormSortFields
    $limit: Int
    $offset: Int
  ) {
    readFeedbackForms(
      filter: $filter
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      pageInfo {
        totalCount
      }
      nodes {
        ID
        Title
        AdminTitle
        Submissions {
          pageInfo {
            totalCount
          }
        }
      }
    }
  }
`;

export const READ_FEEDBACK_FORM = gql`
  query readOneFeedbackForm($ID: ID!) {
    readOneFeedbackForm(filter: { ID: { eq: $ID } }) {
      ${pseudoFeedbackFormFragmentBase}
      ${pseudoFeedbackFormPagesFragment}
      ${pseudoFeedbackFormSimulationsFragment}
      ${pseudoFeedbackFormVirtualHumansFragment}
      AllowMultipleSubmissions
      SimulationsBefore {
        edges {
          node {
            ID
            Title
          }
        }
      }
      SimulationsAfter {
        edges {
          node {
            ID
            Title
          }
        }
      }
      VirtualHumansBefore {
        edges {
          node {
            ID
            Title
          }
        }
      }
      VirtualHumansAfter {
        edges {
          node {
            ID
            Title
          }
        }
      }
      CoursesBefore {
        edges {
          node {
            ID
            Title
          }
        }
      }
      CoursesAfter {
        edges {
          node {
            ID
            Title
          }
        }
      }

    }
  }
`;

export const READ_FEEDBACK_FORM_WITH_SUBMISSIONS = gql`
  query readOneFeedbackFormWithAllSubmissions($ID: ID!, $submissionFilters: SubmissionFilterFields) {
    readOneFeedbackForm(filter: { ID: { eq: $ID } }) {
      ${pseudoFeedbackFormFragmentBase}
      ${pseudoFeedbackFormPagesFragment}
      ${pseudoFeedbackFormSimulationsFragment}
      ${pseudoFeedbackFormVirtualHumansFragment}

      SimulationsBefore {
        edges {
          node {
            ID
            Title
          }
        }
      }
      SimulationsAfter {
        edges {
          node {
            ID
            Title
          }
        }
      }
      VirtualHumansBefore {
        edges {
          node {
            ID
            Title
          }
        }
      }
      VirtualHumansAfter {
        edges {
          node {
            ID
            Title
          }
        }
      }
      CoursesBefore {
        edges {
          node {
            ID
            Title
          }
        }
      }
      CoursesAfter {
        edges {
          node {
            ID
            Title
          }
        }
      }

      Submissions(filter: $submissionFilters, limit: 1000) {
        pageInfo {
          totalCount
        }
        nodes {
          ID
          Created
          Trigger
          Answers {
            edges {
              node {
                ID
                QuestionID
                Answer
                LikertValue
                SentimentScore
                SentimentMagnitude
                Option {
                  ID
                  Name
                  Value
                }
                Options {
                  edges {
                    node {
                      ID
                      Name
                      Value
                    }
                  }
                }

              }
            }
          }
        }
      }
    }
  }
`;

export const READ_FEEDBACK_FORM_SUBMISSIONS = gql`
  query readOneFeedbackFormAnswers(
    $ID: ID!
    $submissionFilters: SubmissionFilterFields
  ) {
    readOneFeedbackForm(filter: { ID: { eq: $ID } }) {
      ID
      Submissions(filter: $submissionFilters, limit: 1000) {
        nodes {
          ID
          Created
          Answers {
            edges {
              node {
                ID
                QuestionID
                Answer
                SentimentScore
                SentimentMagnitude
                LikertValue
                Option {
                  ID
                  Name
                  Value
                }
                Options {
                  edges {
                    node {
                      ID
                      Name
                      Value
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const READ_FEEDBACK_FORM_SUBMISSIONS_PAGINATED = gql`
  query readOneFeedbackFormSubmissionsList($ID: ID!, $limit: Int!, $offset: Int!, $filter: SubmissionFilterFields, $sort: SubmissionSortFields) {
    readOneFeedbackForm(filter: { ID: { eq: $ID } }) {
      ${pseudoFeedbackFormFragmentBase}

      Submissions(limit: $limit, offset: $offset, filter: $filter, sort: $sort) {
        pageInfo {
          totalCount
        }
        nodes {
          ID
          Created
          Trigger
          Platform
          Member {
            ID
            Name
          }
          Simulation {
            ID
            Title
          }
          Course {
            ID
            Title
          }
          VirtualHuman {
            ID
            Title
          }
        }
      }
    }
  }
`;

export const EDIT_FEEDBACK_FORM = gql`
mutation updateFeedbackForm($Input: UpdateFeedbackFormInput!) {
  updateFeedbackForm(input: $Input) {
    ${pseudoFeedbackFormFragmentBase}
    ${pseudoFeedbackFormPagesFragment}
    ${pseudoFeedbackFormSubmissionsFragment}
  }
}
`;

export const EDIT_FEEDBACK_FORM_PAGE = gql`
  mutation updateFeedbackPage($Input: UpdateFeedbackPageInput!) {
    updateFeedbackPage(input: $Input) {
      ID
    }
  }
`;

export const EDIT_FEEDBACK_FORM_QUESTION = gql`
  mutation updateFeedbackQuestion($Input: UpdateFeedbackFormInput!) {
    updateFeedbackPage(input: $Input) {
      ID
    }
  }
`;

export const ADD_FEEDBACK_FORM = gql`
mutation createFeedbackForm($Input: CreateFeedbackFormInput!) {
  createFeedbackForm(input: $Input) {
    ${pseudoFeedbackFormFragmentBase}
    ${pseudoFeedbackFormPagesFragment}
    ${pseudoFeedbackFormSubmissionsFragment}
    ${pseudoFeedbackFormSimulationsFragment}
    ${pseudoFeedbackFormVirtualHumansFragment}

  }
}
`;

export const READ_FORM_SUBMISSION = gql`
  query readOneSubmission($ID: ID!) {
    readOneSubmission(filter: { ID: { eq: $ID } }) {
      ID
      Created
      Member {
        ID
        Name
      }
      FeedbackForm {
        ID

        Pages {
          nodes {
            Questions {
              nodes {
                ID
                Title
                Placeholder
                Type
                CharacterLimit
                Length
                FirstLabel
                LastLabel
                SortOrder
                Required
                Options {
                  nodes {
                    ID
                    Name
                    Value
                  }
                }
              }
            }
          }
        }
      }
      Answers {
        edges {
          node {
            ID
            QuestionID
            Answer
            LikertValue
            Option {
              ID
              Name
              Value
            }
            Options {
              edges {
                node {
                  ID
                  Name
                  Value
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const DELETE_FEEDBACK_FORM = gql`
  mutation deleteFeedbackForms($IDs: [ID]!) {
    deleteFeedbackForms(ids: $IDs)
  }
`;

export const DELETE_FEEDBACK_SUBMISSION = gql`
  mutation deleteSubmissions($IDs: [ID]!) {
    deleteSubmissions(ids: $IDs)
  }
`;

export const COPY_FEEDBACK_FORM = gql`
  mutation copyForm($FormID: ID!) {
    copyForm(FormID: $FormID) {
      ID
    }
  }
`;

export const DELETE_FEEDBACK_FORM_QUESTION = gql`
  mutation deleteFeedbackQuestions($IDs: [ID]!) {
    deleteFeedbackQuestions(ids: $IDs)
  }
`;
