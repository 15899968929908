import { useQuery } from '@apollo/client';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { OptionValue } from '@virtidev/toolbox';
import PropTypes from 'prop-types';
import { READ_VOICES } from './VoiceSelector.query';
import * as Styled from './VoiceSelector.styled';
import { useFeature } from '../../LaunchDarkly';
import ClickableVoice from '@core/components/VirtualHumans/VoiceSelector/components/ClickableVoice/ClickableVoice';

/** @type {(voice: VHVoice) => boolean} */
const isChild = (voice) => {
  return voice?.Age && Number(voice?.Age) < 18 ? true : false;
};

/** @type {(localeCode: string) => [string, string]} */
const getLocaleParts = (localeCode) => {
  const [languageCode, countryCode] = localeCode?.split('-') ?? [];
  return [languageCode, countryCode];
};

const supportedServices = ['elevenlabs', 'azure'];
const previewServices = ['deepgram', 'speechify'];
const elevenlabsStreamingServices = ['elevenlabs_streaming'];
const allServices = [
  ...supportedServices,
  ...previewServices,
  ...elevenlabsStreamingServices,
];
const elevenlabsSupportedLanguages = [
  'en',
  'ja',
  'zh',
  'de',
  'hi',
  'fr',
  'ko',
  'pt',
  'it',
  'es',
  'id',
  'nl',
  'tr',
  'fil',
  'pl',
  'sv',
  'bg',
  'ro',
  'ar',
  'cs',
  'el',
  'fi',
  'hr',
  'ms',
  'sk',
  'da',
  'ta',
  'uk',
];
/**
 * @typedef {import('../../../../../../apps/core/src/models/virtualhuman.types').VHVoice} VHVoice
 * @typedef {import('./VoiceSelector.types').VoiceWithViewData} VoiceWithViewData
 */

/**
 * @type {(nodes: VHVoice[], selectedVoice: VHVoice | undefined, includePreviewVoices: boolean, includeElevenlabsStreamingVoices: boolean, languageCode: string, gender: string) => VoiceWithViewData[]}
 */
const getSelectableVoices = function (
  nodes,
  selectedVoice,
  includePreviewVoices,
  includeElevenlabsStreamingVoices,
  languageCode,
  gender = ''
) {
  let filtered = nodes.filter((node) => {
    if (selectedVoice && node.ID === selectedVoice.ID) return true;
    if (!includePreviewVoices && previewServices.includes(node.Service))
      return false;
    if (
      !includeElevenlabsStreamingVoices &&
      elevenlabsStreamingServices.includes(node.Service)
    )
      return false;
    if (node.Deprecated) return false;
    if (!allServices.includes(node.Service)) return false;
    return true;
  });

  // Sort by gender, then by popularity, then by name
  filtered.sort((a, b) => {
    if (gender && a.Gender === gender && b.Gender !== gender) {
      return -1;
    }
    if (gender && b.Gender === gender && a.Gender !== gender) {
      return 1;
    }
    if (a.Featured && !b.Featured) {
      return -1;
    }
    if (!a.Featured && b.Featured) {
      return 1;
    }
    return a.Name.localeCompare(b.Name);
  });

  const accentCodes = new Set();

  /** @type {VoiceWithViewData[]} */
  filtered = filtered.map((node) => {
    const tags = [];
    if (node.Gender) {
      tags.push(node.Gender);
    }
    if (isChild(node)) {
      tags.push('child');
    }
    // differentiate voice services
    if (node.Service === 'azure') {
      tags.push('high performance');
    }
    // we have had elevenlabs_streaming in dev too
    if (node.Service.indexOf('elevenlabs') === 0) {
      tags.push('realistic');
    }
    if (node.Service.indexOf('_streaming') >= 0) {
      tags.push('streamed');
    }
    if (node.Featured) {
      tags.push('popular');
    }
    // show the country code but only if the voices language matches
    let accentCode = '';
    if (node.Locale && node.Locale.startsWith(languageCode + '-')) {
      [, accentCode] = getLocaleParts(node.Locale);
      tags.push(accentCode);
    }
    /** @type {VoiceWithViewData} */
    return {
      ...node,
      tags,
    };
  });

  return filtered;
};

/**
 * @type {FC<{
 *  voiceID?: string;
 *  voiceString?: string;
 *  onChange: (newVal: OptionValue) => void;
 *  filterLocaleCode?: string;
 *  disabled?: boolean;
 *  vhType: string;
 *  gender?: string;
 *  autoVoiceSelection?: boolean;
 * }>}
 */
const VoiceSelector = ({
  voiceID,
  voiceString,
  onChange,
  filterLocaleCode,
  disabled,
  vhType,
  gender = '',
  autoVoiceSelection = false,
}) => {
  const [languageCode, countryCode] = getLocaleParts(filterLocaleCode);
  const [includePreviewVoices] = useFeature(['vh-deepgram-elevenlabs-voices']);
  const useElevenlabsStreamingFeature = useFeature('elevenlabs-streaming');
  const includeElevenlabsStreamingVoices =
    useElevenlabsStreamingFeature && vhType !== 'branching';
  const includeElevenLabsVoices =
    elevenlabsSupportedLanguages.includes(languageCode);

  const {
    data: elevenlabsVoicesData,
    loading: elevenlabsVoicesLoading,
    error: elevenlabsVoicesError,
  } = useQuery(READ_VOICES, {
    variables: {
      // Since elevenlabs voices are multi-lingual, we don't need to filter them by locale
      filter: {
        Service: { eq: 'elevenlabs' },
      },
    },
    skip: !includeElevenLabsVoices,
  });

  const {
    data: elevenlabsStreamingVoicesData,
    loading: elevenlabsSreamingVoicesLoading,
    error: elevenlabsStreamingVoicesError,
  } = useQuery(READ_VOICES, {
    variables: {
      // Since elevenlabs_streaming voices are multi-lingual, we don't need to filter them by locale
      filter: {
        Service: { eq: 'elevenlabs_streaming' },
      },
    },
    skip: !includeElevenLabsVoices,
  });

  // since we don't have proper graphql filtering we currently have to grab both elevenlabs service types
  // and merge them together
  /** @type {VHVoice[]}} */
  const allElevenlabsVoiceNodes = useMemo(() => {
    /** @type {VHVoice[]}} */
    let voices = [];
    if (elevenlabsVoicesData?.readVoices?.nodes) {
      voices = voices.concat(elevenlabsVoicesData.readVoices.nodes);
    }
    if (elevenlabsStreamingVoicesData?.readVoices?.nodes) {
      voices = voices.concat(elevenlabsStreamingVoicesData.readVoices.nodes);
    }
    return voices;
  }, [elevenlabsVoicesData, elevenlabsStreamingVoicesData]);

  const {
    data: otherVoicesData,
    loading: otherVoicesLoading,
    error: otherVoicesError,
  } = useQuery(READ_VOICES, {
    variables: {
      filter: {
        Service: { ne: 'elevenlabs' }, // can't also remove elevenlabs_streaming so have to do that after
        Locale: languageCode ? { startswith: languageCode + '-' } : undefined,
      },
    },
  });

  /** @type {VHVoice[]}} */
  const otherVoiceNodes = useMemo(() => {
    return (
      otherVoicesData?.readVoices?.nodes?.filter(
        /** @type {(node: VHVoice) => boolean}} */ (node) =>
          node.Service !== 'elevenlabs_streaming'
      ) ?? []
    );
  }, [otherVoicesData]);

  const voices = useMemo(() => {
    if (otherVoiceNodes && allElevenlabsVoiceNodes) {
      return [...allElevenlabsVoiceNodes, ...otherVoiceNodes];
    }
    if (otherVoiceNodes && !includeElevenLabsVoices) {
      return [...otherVoiceNodes];
    }
  }, [allElevenlabsVoiceNodes, includeElevenLabsVoices, otherVoiceNodes]);
  const selectedVoice = useMemo(() => {
    let innerVoiceID = voiceID;
    // if no voice ID set (usually legacy) then use the voice string to find the right voice
    if ((!voiceID || voiceID === '0') && voiceString) {
      const voiceFromString = voices?.find(
        (voice) => voice.Code === voiceString.replace('google_', '')
      );
      if (voiceFromString) {
        innerVoiceID = voiceFromString.ID;
      }
    }
    return voices?.find((voice) => innerVoiceID === voice.ID);
  }, [voiceID, voiceString, voices]);

  const voicesOptions = useMemo(() => {
    return getSelectableVoices(
      voices ?? [],
      selectedVoice,
      includePreviewVoices,
      includeElevenlabsStreamingVoices,
      languageCode,
      gender
    );
  }, [
    voices,
    selectedVoice,
    includePreviewVoices,
    languageCode,
    gender,
    includeElevenlabsStreamingVoices,
  ]);

  const handleVoiceClick = useCallback(
    (voice) => {
      onChange(voice);
    },
    [onChange]
  );

  // When the voice options change, select the first item if we are in stealth mode
  useEffect(() => {
    if (
      autoVoiceSelection &&
      (!selectedVoice ||
        (selectedVoice &&
          !voicesOptions.find((voice) => voice.ID === selectedVoice.ID)))
    ) {
      onChange(voicesOptions[0]);
    }
  }, [voicesOptions, selectedVoice, autoVoiceSelection, onChange]);

  return (
    <Styled.Wrapper
      $hidden={autoVoiceSelection}
      aria-hidden={autoVoiceSelection}
    >
      <Styled.VoiceWrapper>
        {/* // don't use voiceID because in selectedVoice we account for legacy VHs
          // where there's only a voice string and no associated voice object
          value={selectedVoiceValue} */}
        {voicesOptions.map((voiceOption) => (
          <ClickableVoice
            disabled={disabled}
            key={voiceOption.ID}
            filterLocaleCode={filterLocaleCode}
            voice={voiceOption}
            onClick={handleVoiceClick}
            active={selectedVoice?.ID === voiceOption.ID}
          />
        ))}
      </Styled.VoiceWrapper>
    </Styled.Wrapper>
  );
};

VoiceSelector.propTypes = {
  voiceID: PropTypes.string,
  voiceString: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  filterLocaleCode: PropTypes.string,
  disabled: PropTypes.bool,
  vhType: PropTypes.string.isRequired,
  gender: PropTypes.string,
  autoVoiceSelection: PropTypes.bool,
};

export default VoiceSelector;
