import styled, { css } from 'styled-components';
import { Image as ToolboxImage } from '@virtidev/toolbox';
import LinkWithPrevState from '@base/components/LinkWithPrevState';

export const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  min-width: 250px;
`;

export const Gap = styled.div`
  min-width: 20px;
  width: 10vw;
  max-width: 200px;
  height: 1px;
`;

export const ImageContainer = styled.div`
  position: relative;
  min-width: 200px;
  width: 30vw;
  max-width: 600px;
  align-self: stretch;
`;

export const FormContainer = styled.div`
  min-width: 250px;
  width: 35vw;
  max-width: 700px;
`;

export const SuccessHeader = styled.h2`
  font-size: 3.5rem;
`;

export const Image = styled(ToolboxImage)`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  opacity: 1;
  transition: opacity 0.2s;
  transform: translateY(-50%);

  ${({ hidden }) =>
    hidden &&
    css`
      opacity: 0 !important;
    `}
`;

export const Logout = styled(LinkWithPrevState).attrs({ to: '/logout' })`
  display: block;
  text-align: end;
`;
