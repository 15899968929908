import styled, { css } from 'styled-components';
import Button from '../../../../../components/buttons/Button';

export const ScreenCaptureArea = styled.div`
  display: flex;
  grid-column-gap: 1rem;
`;
export const TogglesArea = styled.div``;
export const InputDeviceSelects = styled.div`
  display: grid;
  gap: 1rem;
`;
export const CaptureCanvasArea = styled.div`
  flex: 1;
`;
export const CaptureCanvasWrapper = styled.div`
  width: 100%;
`;
export const CaptureCanvas = styled.canvas`
  border-radius: 16px;
  background: black;
  width: 100%;
`;
export const CaptureVideo = styled.video`
  width: 100%;
  border-radius: 16px;
  background-color: #000;
  display: none;
`;
export const RecordButton = styled(Button)`
  > div > div {
    display: flex;
    gap: 0.5rem;
  }
  :hover {
    background-color: var(--delete-button-bg-color-hover);
    background-image: none;
  }
  :active {
    background-color: var(--delete-button-bg-color-active);
    background-image: none;
  }
  background-color: var(--delete-button-bg-color);
  color: var(--delete-button-text-color);
`;
// export const ScreenCaptureVideo = styled(VideoWithSrcObject)`
//   width: 100%;
//   background-color: #000;
// `;

export const ControlButtons = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
  justify-content: flex-end;
`;

export const HalloweenImage = styled.img`
  display: none;
`;
