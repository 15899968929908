import { gql } from '@apollo/client';

export const READ_MEMBERS_ONBOARDING_PAGINATED = gql`
  query readMembersPaginated(
    $limit: Int!
    $offset: Int!
    $organisationID: Int!
  ) {
    readMembers(
      offset: $offset
      limit: $limit
      filter: { Organisations: { ID: { eq: $organisationID } } }
    ) {
      pageInfo {
        totalCount
        hasNextPage
      }
      edges {
        node {
          Name
          ID
          OnboardProgress
          LastSeen
        }
      }
    }
  }
`;
