// sorts alphabetically
export const GetFuncSortEdgesBy = function (field, desc = false) {
  return function (edge1, edge2) {
    if (typeof (edge2.node[field]) !== 'string') {
      return -1;
    }
    if (typeof (edge1.node[field]) !== 'string') {
      return 1;
    }
    if (desc) {
      return edge1.node[field].toLowerCase() < edge2.node[field].toLowerCase() ? 1 : -1;
    }
    return edge1.node[field].toLowerCase() > edge2.node[field].toLowerCase() ? 1 : -1;
  }
}

export const GetFuncSortEdgesByNum = function (field, desc = false) {
  return function (edge1, edge2) {
    if (desc) {
      return edge1.node[field] < edge2.node[field] ? 1 : -1;
    }
    return edge1.node[field] > edge2.node[field] ? 1 : -1;
  }
}

// field can also represent an array index
export const GetFuncSortByNum = function (field) {
  return function (obj1, obj2) {
    return obj1[field] > obj2[field] ? 1 : -1;
  }
}