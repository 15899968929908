import _ from 'lodash';

/**
 * Returns the first value (if any - otherwise null) found in the object with the designated key (no priority over shallower nested values)
 * @param {object} obj The haystack object to search for the key's value
 * @param {string} keyToFind The key used in the search for the desired value
 */
export const GetFirstNestedWithKey = (obj, keyToFind) => {
  if (!obj) return null;
  if (obj.hasOwnProperty(keyToFind)) return obj[keyToFind];
  for (let key in obj) {
    let result = null;
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      result = GetFirstNestedWithKey(obj[key], keyToFind);
      if (result !== null) return result;
    }
  }
  return null;
};

export const GetObjectDifference = function (object, base) {
  function changes(object, base) {
    return _.transform(object, function (result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] =
          _.isObject(value) && _.isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  }
  return changes(object, base);
};

export const IsNonArrayObject = function (obj) {
  return obj ? obj && obj.constructor === Object : false;
};

// clones the object recursively, omitting the provided array of keys from any further nested objects
export const CloneAndOmitDeep = function (object, omitKeys, isArrayValue) {
  // array values have to be treated differently to objects with keys
  if (isArrayValue) {
    // if an array value 'object' is the 'value' (changed wording for semantics)
    let value = object;
    if (IsNonArrayObject(value)) {
      value = CloneAndOmitDeep(object, omitKeys);
    } else if (Array.isArray(object)) {
      // if array we need to loop through and recursively call its values
      value = [];
      for (let i = 0; i < object.length; i++) {
        value.push(CloneAndOmitDeep(object[i], omitKeys, true));
      }
    }
    return value;
  }

  let clone = {};
  for (let key in object) {
    if (!omitKeys.includes(key)) {
      if (IsNonArrayObject(object[key])) {
        clone[key] = CloneAndOmitDeep(object[key], omitKeys);
      } else if (Array.isArray(object[key])) {
        // if array we need to loop through and recursively call its values
        clone[key] = [];
        for (let i = 0; i < object[key].length; i++) {
          clone[key].push(CloneAndOmitDeep(object[key][i], omitKeys, true));
        }
      } else {
        clone[key] = object[key];
      }
    }
  }
  return clone;
};

function GetRoughSizeOfObject(object) {
  var objectList = [];
  var stack = [object];
  var bytes = 0;

  while (stack.length) {
    var value = stack.pop();

    if (typeof value === 'boolean') {
      bytes += 4;
    } else if (typeof value === 'string') {
      bytes += value.length * 2;
    } else if (typeof value === 'number') {
      bytes += 8;
    } else if (typeof value === 'object' && objectList.indexOf(value) === -1) {
      objectList.push(value);

      for (var i in value) {
        stack.push(value[i]);
      }
    }
  }
  return bytes;
}
