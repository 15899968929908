import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';


const StyledAlert = styled.div`
  background-color: var(--alert-message-bg-color-error);
  color: var(--alert-message-text-color-error);
  ${props => props.type === "warning" && css` 
    background-color: var(--alert-message-bg-color-warning);
    color: var(--alert-message-text-color-warning);

  `} 
  ${props => props.type === "success" && css` 
    background-color: var(--alert-message-bg-color-success);
  `} 
  font-size: var(--flash-message-font-size);
  line-height:1.2;
  margin-bottom: 2rem;
  width: 100%;
  padding: 1.2rem 1.2rem;
  border-radius: 0.2rem;
  align-items: center;
  display: grid;
  grid-template-columns: 1.5rem auto;

`

const Alert = (props) => {
  const icon = props.type === "error" || props.type === "warning" ? "exclamation-triangle" : "check-circle";
  return (
    <StyledAlert type={props.type}>
      <FontAwesomeIcon icon={icon} />
      <div>
        {props.children}
      </div>
    </StyledAlert>
  )
};

Alert.defaultProps = {
  type: "error",
};

Alert.propTypes = {
  type: PropTypes.string,
};




export default Alert