import {
  RegisterFormLogo,
  RegisterFormTitle,
} from '@core/components/Signup/RegisterForm/RegisterForm.styled';
import { rebrand } from '@core/templates/components/SplashPage/SplashPage.styled';
import styled, { css } from 'styled-components';

export const RegisterLoadingLogo = styled(RegisterFormLogo)`
  align-self: flex-start;
  justify-self: flex-start;
`;

export const RegisterLoadingContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media screen and (min-width: 992px) {
    justify-content: center;
    margin-bottom: 20%;
  }
`;

export const LoadingWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.lg};

    @media screen and (min-width: 992px) {
      padding: ${rebrand.page.splash.square.sm};
    }
  `}
`;

export const LoadingText = styled.div`
  @media screen and (min-width: 992px) {
    text-align: center;
  }
`;

export const RegisterLoadingTitle = styled(RegisterFormTitle)`
  @media screen and (min-width: 992px) {
    display: none;
  }
`;
