import { gql } from '@apollo/client';

export const VIRTUAL_HUMAN_USER_ANALYTICS = gql`
  query userVirtualHumanAnalytics($VirtualHumanID: ID!, $UserID: ID!) {
    userVirtualHumanAnalytics(
      VirtualHumanID: $VirtualHumanID
      UserID: $UserID
    ) {
      TotalViews
      TotalCompletions
      TotalTimeSpent
      AverageScore
      AverageDiagnosisScore
      AverageTimeSpent
      DailyTotalViewsDays
      DailyTotalViewsViews
      DailyTotalViewsScores
    }
  }
`;

export const READ_MEMBER = gql`
  query readMemberName($ID: ID!) {
    readOneMember(filter: { ID: { eq: $ID } }) {
      ID
      Name
    }
  }
`;
