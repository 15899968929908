import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  text-align: right;
  button:first-child {
    margin-right: 1rem;
  }
`;

export const Message = styled.div`
  font-size: 1rem;
  margin-bottom: 2rem;
  line-height: 2rem;
  position: relative;
  top: -0.6rem;
  max-height: 80vh;
  overflow: auto;

  > * {
    margin-top: 1rem;
    &:first-child {
      margin-top: 0;
    }
  }

  li {
    list-style: circle;
    margin-left: 2rem;
  }
`;

export const ConfirmationTextWrapper = styled.div`
  margin-top: -1rem;
  margin-bottom: 1rem;
`;

export const ConfirmationTextMessage = styled.div`
  margin-bottom: 1rem;
  font-style: italic;
`;
