import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import * as StyledCxVH from '../../CxVirtualHuman.styled';
import useGetVHData from '../../../../../../../../utility/VHHooks/useGetVHData';
import {
  GET_VIRTUAL_HUMANS,
  SEARCH_ORGANISATIONS,
} from './CxOrgVHLangList.query';
import DBSelect from '../../../../../../../../components/DBSelect';
import { StyledHeader } from '../../../../../../../../components/StyledLoggedOutPage';

const CxOrgVHLangList = () => {
  const [org, setOrg] = useState(null);
  const { data: languages, loading: loadingLanguages } = useGetVHData(
    `languages`,
    { skip: !org?.value, vhAPIEndpointOverride: org?.data?.VirtualHumanAPI }
  );
  const { data: VHDBVHs, loading: loadingVHs } = useGetVHData(
    `virtual-humans?organisationId=${org?.value}`,
    { skip: !org?.value, vhAPIEndpointOverride: org?.data?.VirtualHumanAPI }
  );
  const { data: VHs } = useQuery(GET_VIRTUAL_HUMANS, {
    skip: !org?.value,
    variables: {
      OrganisationID: org?.value ? org.value : -1,
    },
  });

  return (
    <StyledCxVH.Wrapper>
      <StyledHeader>VH Languages</StyledHeader>
      <div>N/A = none explicitly set. Probably a default English agent.</div>
      <div>
        <DBSelect
          dataQuery={SEARCH_ORGANISATIONS}
          filterVar="filter"
          queryDataPath="readOrganisations.edges"
          queryNestedObjectPath="node"
          onChange={(newOrgSelectObj, actualNode) => {
            setOrg(newOrgSelectObj);
          }}
          value={org}
          htmlId="organisation-lang-list-select"
          label="Organisation to View VH Languages"
          labelField="Name"
          dataField="VirtualHumanAPI"
        />
      </div>
      {(VHs?.readVirtualHumans?.nodes ?? []).map((vh) => {
        console.log(VHDBVHs?.data);
        const vhData = VHDBVHs?.data?.find(
          (vhDBVH) => vhDBVH.id === vh.ExternalID
        );
        const language = languages?.data?.find(
          (lang) => lang.id === vhData?.languageId
        );
        console.log(vhData?.languageId);
        return (
          <div key={vh.ID}>
            <div>ID: {vh.ID}</div>
            <div>Name: {vh.Title}</div>
            <div>
              ExternalID: {vh.ExternalID}{' '}
              <span style={{ color: !vhData?.id ? 'red' : 'initial' }}>
                {!vhData?.id
                  ? 'No vh with this org id and external id found in vh db'
                  : ''}
              </span>
            </div>
            <div>Language: {language?.BCP47Code ?? 'N/A'}</div>
          </div>
        );
      })}
    </StyledCxVH.Wrapper>
  );
};

export default CxOrgVHLangList;
