import useGetVHHasEvaluationsOrObjectives from '@base/utility/VHHooks/API/useGetVHHasEvaluationsOrObjectives';
import useGetVHData from '@base/utility/VHHooks/useGetVHData';

// note any logic changes here should also be reflected in the player functionality
// although the player uses whether or not a session report was generated for the check
// (can't really do that ideally for analytics here since that isn't particularly retroactive)

/** @type {(vhId: number) => {shouldShowVHScoreOverview: boolean; loading: boolean;}} */
const useShouldShowVHScoreOverview = (vhId) => {
  const { hasEvaluationsOrObjectives, loading } =
    useGetVHHasEvaluationsOrObjectives(vhId);
  const {
    data: vhDBVHResponse,
    loading: vhDBVHResponseLoading,
    error,
    refetch,
  } = useGetVHData(`patient/${vhId}`);

  return {
    shouldShowVHScoreOverview:
      hasEvaluationsOrObjectives || !!vhDBVHResponse?.data?.assessmentCriteria,
    loading: loading || vhDBVHResponseLoading,
  };
};

export default useShouldShowVHScoreOverview;
