import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledTooltipWrapper = styled.div`
  ${({ theme, subtle }) => css`
    position: relative;
    font-size: 1.6rem;
    padding: 0.65rem 0.4rem 0.4rem 0.4rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    color: ${theme.color.primary};
    ${subtle &&
    css`
      font-size: 1rem;
      color: var(--neutral-color-light);
      margin-top: 0.4rem;
    `}
  `}
`;
const StyledHoverDiv = styled.div`
  position: relative;
  cursor: help;
  display: flex;
  align-items: center;
  :hover > div {
    display: flex;
  }
`;

const tooltipSpace = '0.5rem';

const StyledTooltipInner = styled.div`
  ${({ side }) => css`
    position: absolute;
    font-size: 0.8rem;
    text-align: center;
    width: 14rem;
    display: none;
    justify-content: center;
    align-items: flex-start;
    z-index: 1;
    ${side === 'top' &&
    css`
      bottom: calc(100% + ${tooltipSpace});
      left: -6rem;
    `}
    ${side === 'bottom' &&
    css`
      top: calc(100% + ${tooltipSpace});
      left: -6rem;
    `}
    ${side === 'left' &&
    css`
      left: calc(-14rem - ${tooltipSpace});
      text-align: right;
    `}
    ${side === 'right' &&
    css`
      right: calc(-14rem - ${tooltipSpace});
      text-align: left;
    `}
  `}
`;
const StyledTooltip = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.color.primary};
    color: ${theme.color.contrast.primary};
    word-break: break-word;
    display: inline-block;
    border-radius: 5px;
    line-height: 1.3rem;
    padding: 0.4rem 0.6rem;
    text-align: center;
  `}
`;
const StyledIcon = styled(FontAwesomeIcon)``;

const HelpTooltip = (props) => {
  // console.log("props");
  // console.log(props);
  return (
    <StyledTooltipWrapper className={props.className} subtle={props.subtle}>
      <StyledHoverDiv>
        <StyledTooltipInner side={props.side}>
          <StyledTooltip>{props.text}</StyledTooltip>
        </StyledTooltipInner>
        <StyledIcon icon="question-circle" />
      </StyledHoverDiv>
    </StyledTooltipWrapper>
  );
};

HelpTooltip.defaultProps = {
  side: 'top',
};

HelpTooltip.propTypes = {
  text: PropTypes.string,
  side: PropTypes.string,
};

const StyledHoveringChildTooltipWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  max-width: 100%;
`;
const StyledHoveringChildTooltip = styled.div`
  /* position: absolute;
  font-size: 0.8rem;
  text-align: center;
  display: none;
  justify-content: center;
  align-items: flex-start;
  z-index: 1; */
  > div {
    position: relative;
    /* top: -2.2rem; */
    top: 2rem;
  }
  z-index: 3;
  text-align: center;
  display: none;
  position: absolute;
  pointer-events: none;
  background-color: #000000;
  word-break: break-word;
  display: inline-block;
  color: white;
  border-radius: 5px;
  line-height: 1.3rem;
  padding: 0.4rem 0.6rem;
  text-align: center;
  border-radius: 0;
  top: 0;
  display: none;
`;

export const HoveringChildTooltip = function (props) {
  return (
    <StyledHoveringChildTooltipWrapper className={props.className}>
      <StyledHoveringChildTooltip>{props.children}</StyledHoveringChildTooltip>
    </StyledHoveringChildTooltipWrapper>
  );
};

// main tooltip to be placed wherever (needs to be provided with display: flex on hover)
const StyledHoveringTooltip = styled.div`
  ${({ theme }) => css`
    position: absolute;
    bottom: 100%;
    left: 50%;
    text-align: center;
    margin: 0 auto;
    transform: translateX(-50%);
    display: none;
    justify-content: center;
    width: 19rem;
    z-index: 3;
    pointer-events: none;

    > div {
      position: relative;
      background-color: ${theme.color.primary};
      color: ${theme.color.contrast.primary};
      border-radius: 5px;
      line-height: 1.3rem;
      padding: 0.4rem 0.6rem;
    }
  `}
`;

export const HoveringTooltip = (props) => (
  <StyledHoveringTooltip className={props.className}>
    <div>{props.children}</div>
  </StyledHoveringTooltip>
);

export default HelpTooltip;
