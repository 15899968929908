import _ from 'lodash';
import { Titlebar } from '../../../components/Page';
import { PageHeader } from '../../../components/Page/components/PageHeader';
import VirtualHumansList from '../../../components/VirtualHumansList/VirtualHumansList';
import { withVirtualHumanAccess } from '../../../helpers/permissions';
import { withPageTemplate } from '../../../templates';

const VirtualHumansListPage = () => {
  return (
    <>
      <Titlebar>Virtual Humans</Titlebar>
      <VirtualHumansList />
    </>
  );
};

export default _.flowRight(
  withVirtualHumanAccess,
  withPageTemplate({ pageKey: 'virtual-humans' })
)(VirtualHumansListPage);
