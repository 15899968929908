import { useMutation } from '@apollo/client';
import { Button, Icon, Card } from '@virtidev/toolbox';
import { produce } from 'immer';
import React, { useCallback, useEffect, useMemo } from 'react';
import { READ_FEEDBACK_FORM } from '../../../../../../../queries/FeedbackFormQueries';

import {
  CREATE_FEEDBACK_PAGE,
  DELETE_FEEDBACK_PAGE,
  FEEDBACK_FORM_FRAGMENT,
} from './EditPages.query';
import { DeleteIcon, PageButton, PageList } from './EditPages.styled';
import EditPageTitle from './EditPageTitle/EditPageTitle';

export default function EditPages({
  feedbackForm,
  activePage,
  setActivePage,
  setLoading,
}) {
  const pages = useMemo(() => {
    if (feedbackForm) {
      return feedbackForm.Pages.nodes;
    }
    return null;
  }, [feedbackForm]);

  useEffect(() => {
    if (pages && !activePage) {
      setActivePage(pages[0]);
    }
  });

  const [createFeedbackPage] = useMutation(CREATE_FEEDBACK_PAGE, {
    onCompleted: () => {
      setLoading(false);
    },
    update: (cache, { data }) => {
      cache.evict({ fieldName: 'readOneFeedbackForm' });
    },
  });

  const handleClick = useCallback(() => {
    setLoading(true);
    createFeedbackPage({
      variables: {
        input: {
          Title: 'New Page',
          FeedbackFormID: feedbackForm.ID,
          SortOrder: pages.length,
        },
      },
    });
  }, [createFeedbackPage, feedbackForm?.ID, pages, setLoading]);

  const [deletePage] = useMutation(DELETE_FEEDBACK_PAGE, {
    onCompleted: () => {
      setLoading(false);
    },
    update: (cache, { data }) => {
      cache.evict({ fieldName: 'readOneFeedbackForm' });
    },
  });

  const handleDelete = (ID) => {
    setLoading(true);
    deletePage({
      variables: {
        ids: [ID],
      },
    });
  };

  return (
    <>
      <Card>
        <PageList>
          {!pages?.length && 'Add a page to start creating your form...'}
          {pages &&
            pages.map((page) => {
              return (
                <PageButton
                  key={page.ID}
                  active={page.ID === activePage?.ID}
                  onClick={() => setActivePage(page)}
                >
                  {page.Title}
                  <DeleteIcon
                    icon="bin"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleDelete(page.ID);
                    }}
                  />
                </PageButton>
              );
            })}
          <Button
            onClick={handleClick}
            style={{ width: '20px', padding: '10px 15px' }}
            color="turquoise"
            title="Add page"
          >
            <Icon icon="plus" color="#fff" size="10px" />
          </Button>
        </PageList>
      </Card>
      {activePage && (
        <EditPageTitle
          key={activePage?.ID}
          activePage={activePage}
          setLoading={setLoading}
        />
      )}
    </>
  );
}
