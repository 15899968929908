import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  StyledCustomisedInputEditWrapper,
  StyledCustomisedInputEditInputWrapper,
  StyledCustomisedInputHeader,
  StyledLabel,
} from '../../styled-components/StyledCustomisedInputs';

import Details from '../../apps/core/src/components/FeedbackForms/Question/Details/Details';
import { Icon, Select } from '@virtidev/toolbox';

export default function CustomisedInputLikert(props) {
  const [previewChecked, setPreviewChecked] = useState('');
  const [currentLength, setCurrentLength] = useState(
    props.question.Options.nodes.length - 1 || 6
  );

  const typeOptions = [
    { value: 'likelihood', label: 'Likelihood' },
    { value: 'agreement', label: 'Agreement' },
    { value: 'happiness', label: 'Happiness' },
    { value: 'custom', label: 'Custom' },
  ];

  const lengthOptions = [
    { value: 5, label: '5' },
    { value: 7, label: '7' },
    { value: 10, label: '10' },
    { value: 11, label: '11' },
  ];

  return (
    <>
      {props.editing && (
        <StyledCustomisedInputEditWrapper>
          <Details
            editing={props.editing}
            register={props.register}
            typeTitle="Linear Scale Question"
            info=""
            icon="likertscale"
          >
            <Select placeholder={'Select type'} options={typeOptions} />
            <br></br>
            <Select placeholder="Select scale length" options={lengthOptions} />

            {/* <StyledLikertScaleModel>
              <StyledTextInput
                maxLength={21}
                label="1"
                placeholder="eg. Strongly Disagree"
                value={props.question.Options.nodes[0].Name || ''}
                onChange={(e) => handleCustomChange(e.target.value, 0)}
                onBlur={() => {
                  if (!props.question.Options.nodes[0].Name.length) {
                    const value = `Option 1`;
                    handleCustomChange(value, 0);
                  }
                }}
              />
              <StyledNumberScale>
                <div>
                  {props.question.Options.nodes.reduce((acc, item, index) => {
                    if (index > 1) {
                      acc.push(index);
                    }
                    return acc;
                  }, [])}
                </div>
                <div>
                  {props.question.Options.nodes.reduce((acc, item, index) => {
                    if (index > 1) {
                      acc.push('○');
                    }
                    return acc;
                  }, [])}
                </div>
              </StyledNumberScale>
              <StyledTextInput
                maxLength={21}
                label={props?.question?.Options?.length}
                placeholder="eg. Strongly Agree"
                onChange={(e) =>
                  handleCustomChange(
                    e.target.value,
                    props.question.Options.nodes.length - 1
                  )
                }
                value={
                  props.question.Options.nodes[
                    props?.question?.Options.nodes.length - 1
                  ].Name || ''
                }
                onBlur={() => {
                  if (
                    !props.question.Options.nodes[
                      props?.question?.Options?.length - 1
                    ].Name.length
                  ) {
                    const value = `Option ${props.question.Options.nodes.length}`;
                    handleCustomChange(
                      value,
                      props.question.Options.nodes.length - 1
                    );
                  }
                }}
              />
            </StyledLikertScaleModel> */}
          </Details>
        </StyledCustomisedInputEditWrapper>
      )}
      {!props.editing && (
        <StyledCustomisedInputEditInputWrapper
          onClick={(e) => {
            e.preventDefault();
            props.setEditing(!props.editing);
          }}
        >
          <StyledCustomisedInputHeader>
            <Icon icon="likertscale" size="35px" color="#ccc" />
          </StyledCustomisedInputHeader>
          <StyledLabel>{props.question.Title}</StyledLabel>
          {/* <StyledLikertForm>
            <StyledOptionPoleText>
              {props.question.Options.nodes[0].Name}
            </StyledOptionPoleText>
            {props.question.Options.nodes.map((item, index) => {
              return (
                <StyledLikertFormOption key={item.ID}>
                  <label>{index + 1}</label>
                  <input
                    type="radio"
                    id={item.Name || 'radio' + index}
                    value={item.index}
                    checked={previewChecked === index}
                    onChange={() => {
                      setPreviewChecked(index);
                    }}
                  />
                </StyledLikertFormOption>
              );
            })}
            <StyledOptionPoleText>
              {
                props.question.Options.nodes[
                  props.question.Options.nodes.length - 1
                ]?.Name
              }
            </StyledOptionPoleText>
          </StyledLikertForm> */}
        </StyledCustomisedInputEditInputWrapper>
      )}
    </>
  );
}

CustomisedInputLikert.propTypes = {
  question: PropTypes.object,
  removeQuestion: PropTypes.func,
  disabled: PropTypes.bool,
  onQuestionEdit: PropTypes.func,
  otherQuestions: PropTypes.array,
  index: PropTypes.number,
};
