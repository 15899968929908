import React, { useCallback, useState } from 'react';
import { Button } from '@virtidev/toolbox';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import useUser from '../../apps/core/src/helpers/useUser';
import getConfig from '../../lib/config';
import { GetAccessToken } from '../../utility/LoginUtility';

export const UserExportButton = () => {
  const token = GetAccessToken();
  const { OrganisationID } = useUser();
  const [downloading, setDownloading] = useState(false);

  const downloadUsers = useCallback(() => {
    const url = `${getConfig(
      'REACT_APP_REMOTE_URI'
    )}export/users/${OrganisationID}`;

    setDownloading(true);
    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        const data = await res.blob();
        const filename = decodeURI(res.headers.get('Filename') || 'export.csv');

        saveAs(data, filename, {
          autoBom: true,
        });
        setDownloading(false);
      })
      .catch((err) => {
        console.error(err);
        setDownloading(false);
      });
  }, [token, OrganisationID]);

  return (
    <Button
      color="primary"
      icon="download"
      onClick={downloadUsers}
      loading={downloading}
    >
      Export
    </Button>
  );
};

UserExportButton.defaultProps = {
  disabled: false,
};

UserExportButton.propTypes = {
  filter: PropTypes.string,
};

export default UserExportButton;
