import { useMemo } from 'react';
import { useDrag } from 'react-dnd';
import { FALLBACK, Icon, Image } from '@virtidev/toolbox';
import {
  ImageContainer,
  SimulationTitle,
  SimulationAdminTitle,
  SimulationContainer,
} from './PreviewItem.styled';
import { getResizeImage } from '../../../../helpers/imageResizing/getResizeImage';
import { Link } from 'react-router-dom';

export const PreviewItem = ({ data, type, addItem }) => {
  const [{ dragging }, dragRef] = useDrag(
    () => ({
      type: 'ItemToModule',
      item: { data, type },
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult();
        if (item.data && dropResult) {
          addItem(item.data, dropResult);
        }
      },
      collect: (monitor) => ({
        dragging: monitor.isDragging(),
      }),
    }),
    [data]
  );

  const url = useMemo(() => {
    if (data.ImageMedia?.TusID) {
      return getResizeImage(data.ImageMedia.TusID);
    }
    if (data.Avatar?.ImageMedia?.TusID) {
      return getResizeImage(data.Avatar.ImageMedia.TusID);
    }
    return (
      data.ThumbnailURL ||
      data.ImageMedia?.URL ||
      data.FirstVideo?.nodes?.[0]?.PosterURL
    );
  }, [data]);

  const icon = useMemo(
    () => (type === 'VirtualHuman' ? 'virtual-human' : 'video'),
    [type]
  );

  const link = useMemo(() => {
    return type === 'VirtualHuman'
      ? `/virtual-humans/${data.ID}`
      : `/simulations/${data.ID}`;
  }, [type, data.ID]);

  return (
    <SimulationContainer ref={dragRef} dragging={dragging}>
      <ImageContainer>
        <Link to={link}>
          <Image src={url || FALLBACK} alt={data.Title} cover />
        </Link>
      </ImageContainer>
      <SimulationTitle>
        <Icon icon={icon} color="var(--secondary-color, #46beaf)" />{' '}
        <span>
          {data.Title}
          {data.AdminTitle && (
            <SimulationAdminTitle>{` (${data.AdminTitle})`}</SimulationAdminTitle>
          )}
        </span>
      </SimulationTitle>
      <Icon
        icon="move"
        type="outline"
        color="var(--neutral-color-light)"
        size="1.3rem"
      />
    </SimulationContainer>
  );
};

export default PreviewItem;
