import { useQuery } from '@apollo/client';
import { useMemo, useEffect, FC } from 'react';
import { OptionValue, SelectProps } from '@virtidev/toolbox';
import PropTypes from 'prop-types';
import { READ_TAGS } from './SelectTag.query';
import Select from '../../Select';
import _ from 'lodash';
import { useSelectFilter } from '../../helpers/useSelectFilter';
import { ContentTag } from '../../../../../models/tag.types';
import withController from '../../helpers/withController';
import withStateUrl from '../../helpers/withStateUrl';
import useUser from '@core/helpers/useUser';

/**
 * @type {FC<{
 *    options?: OptionValue[],
 *    loading?: boolean,
 *    value: OptionValue[] | OptionValue | string | string[],
 * } & Omit<SelectProps, "options">>}
 */
export const SelectTag = ({ options, loading, ...props }) => {
  const { OrganisationID } = useUser();
  const [filter, filterProps] = useSelectFilter();

  const { data: tagData, loading: tagLoading } = useQuery(READ_TAGS, {
    variables: {
      name: filter,
      orgId: OrganisationID,
    },
  });

  const tags = useMemo(() => {
    if (!tagData) {
      return [];
    }
    return tagData.readContentTags.nodes.map(
      /** @param {ContentTag} tag */
      (tag) => ({
        value: tag.ID,
        label: tag.Name,
      })
    );
  }, [tagData]);

  useEffect(() => {
    const { inputValue, ref } = filterProps;
    if (!tagLoading && inputValue) {
      ref.current.focus();
      ref.current.onMenuOpen();
    }
    // I do not want this to activate with inputValue change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagLoading]);

  const allOptions = useMemo(
    () => [...(options || []), ...tags],
    [options, tags]
  );

  return (
    <Select
      {...props}
      {...filterProps}
      options={allOptions}
      loading={tagLoading || loading}
    />
  );
};

SelectTag.defaultProps = {
  options: [],
  loading: false,
};

SelectTag.propTypes = {
  loading: PropTypes.bool,
};

export const ControlledSelectTag = withController(SelectTag);
export const StateUrlSelectTag = withStateUrl(SelectTag);
export default SelectTag;
