import { useQuery } from '@apollo/client';
import {
  Button,
  LoadingPlaceholder,
  SortableTh,
  Table,
  useStateUrl,
} from '@virtidev/toolbox';
import { useCallback, useMemo, useState } from 'react';
import { useDebouncedSave } from '../../form/hooks/useDebouncedSave';
import { usePageScroll } from '../../Page';
import PaginationControl from '../../PaginationControl';
import { usePaginationUrl } from '../../PaginationControl/helpers/usePaginationUrl';
import {
  ButtonWrapper,
  FiltersWrapper,
  PaginationControlWrapper,
  StyledTextInput,
  TableWrapper,
} from './OrgTable.styled';
import useSortUrl from '../../../helpers/useSortUrl';
import { StyledLinkAsBtn } from '../../../../../../styled-components/StyledLink';
import { READ_ORGANISATIONS } from './OrgTable.query';
import { StateUrlSelect } from '../../form/Select';
import CreateOrgForm from './CreateOrgForm/CreateOrgForm';
import { customerStageOptions } from '@core/components/Cx/OrgTable/customerStageOptions';

const sortOptions = [
  { value: 'Name:ASC' },
  { value: 'Name:DESC' },
  { value: 'ID:ASC' },
  { value: 'ID:DESC' },
  { value: 'CustomerStage:ASC' },
  { value: 'CustomerStage:DESC' },
  { value: 'Partner:ASC' },
  { value: 'Partner:DESC' },
  { value: 'Licenses:ASC' },
  { value: 'Licenses:DESC' },
];

export default function OrgTable({ openEditForm, viewAnalytics }) {
  const [addOrgFormOpen, setAddOrgFormOpen] = useState(false);
  const { scrollTop } = usePageScroll();
  const { sortField, sortDirection, updateSort } = useSortUrl(sortOptions);
  const { value: nameFilter, updateValue: setNameFilter } = useStateUrl({
    initialValue: '',
    key: 'name',
  });
  const { value: partnerFilter, updateValue: setPartnerFilter } = useStateUrl({
    initialValue: '',
    key: 'partner',
  });
  const { value: customerStageFilter, updateValue: setCustomerStageFilter } =
    useStateUrl({
      initialValue: '',
      key: 'stage',
    });

  const { page, pageSize, controlProps, resetPage } = usePaginationUrl({
    onChange: scrollTop,
    pageSize: 20,
  });

  const debouncedNameProps = useDebouncedSave(nameFilter, {
    onUpdate: (value) => {
      setNameFilter(value);
      resetPage();
    },
    enter: true,
  });

  const debouncedPartnerProps = useDebouncedSave(partnerFilter, {
    onUpdate: (value) => {
      setPartnerFilter(value);
      resetPage();
    },
    enter: true,
  });

  const variables = {
    limit: pageSize,
    offset: (page - 1) * pageSize,
    filter: {
      Name: nameFilter ? { contains: nameFilter } : undefined,
      Partner: partnerFilter ? { contains: partnerFilter } : undefined,
      CustomerStage: customerStageFilter
        ? { eq: customerStageFilter }
        : undefined,
    },
    sort: {
      [sortField]: sortDirection,
    },
  };

  const { data, loading, error } = useQuery(READ_ORGANISATIONS, {
    variables,
  });

  const orgs = useMemo(() => {
    if (!data && loading) {
      return [...Array(20)];
    }
    return data?.readOrganisations?.edges || [];
  }, [data, loading]);

  const handleSort = useCallback(
    (sortField, sortDirection) => {
      updateSort(sortField, sortDirection);
      resetPage();
    },
    [resetPage, updateSort]
  );

  const toggleAddOrgForm = useCallback(() => {
    setAddOrgFormOpen(!addOrgFormOpen);
  }, [addOrgFormOpen]);

  const handleCreate = useCallback(
    (organisation) => {
      setAddOrgFormOpen(false);
      openEditForm(organisation.ID);
    },
    [openEditForm]
  );

  return (
    <>
      <TableWrapper>
        {addOrgFormOpen && <CreateOrgForm onCreate={handleCreate} />}
        <FiltersWrapper>
          <StyledTextInput
            placeholder="Search by name..."
            disabled={loading}
            {...debouncedNameProps}
          />
          <StyledTextInput
            placeholder="Search by partner..."
            disabled={loading}
            {...debouncedPartnerProps}
          />
          <StateUrlSelect
            param="stage"
            disabled={loading}
            placeholder="Filter by stage..."
            options={customerStageOptions}
            onChange={resetPage}
            clearable
          />
          <Button
            onClick={toggleAddOrgForm}
            icon={addOrgFormOpen ? 'cross' : 'plus'}
          >
            {addOrgFormOpen ? 'Cancel' : 'New'}
          </Button>
        </FiltersWrapper>

        <Table>
          <thead>
            <tr>
              <SortableTh
                sortField="Name"
                onSort={handleSort}
                currentSort={{ sortField, sortDirection }}
              >
                Name
              </SortableTh>
              <SortableTh
                sortField="ID"
                onSort={handleSort}
                currentSort={{ sortField, sortDirection }}
              >
                Org ID
              </SortableTh>
              <SortableTh
                sortField="CustomerStage"
                onSort={handleSort}
                currentSort={{ sortField, sortDirection }}
              >
                Stage
              </SortableTh>
              <SortableTh
                sortField="Partner"
                onSort={handleSort}
                currentSort={{ sortField, sortDirection }}
              >
                Partner
              </SortableTh>
              <SortableTh
                sortField="Licenses"
                onSort={handleSort}
                currentSort={{ sortField, sortDirection }}
              >
                Licenses
              </SortableTh>
              <th>Users</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {orgs.map((item, i) => {
              if (loading) {
                return (
                  <tr key={i + 1}>
                    {[...Array(8)].map((_, j) => (
                      <td key={j + 1}>
                        <LoadingPlaceholder style={{ margin: 0 }} />
                      </td>
                    ))}
                  </tr>
                );
              }
              const { node: organisation } = item;
              return (
                <tr key={organisation.ID}>
                  <td>
                    <StyledLinkAsBtn
                      onClick={() => openEditForm(organisation.ID)}
                    >
                      {organisation.Name || '–'}
                    </StyledLinkAsBtn>
                  </td>
                  <td>{organisation.ID}</td>
                  <td>{organisation.CustomerStage}</td>
                  <td>{organisation.Partner}</td>
                  <td>{Number(organisation.Licenses)}</td>
                  <td>
                    {Number(organisation.TotalUsers) +
                      Number(organisation.TotalAdmins)}
                  </td>
                  <td>
                    <ButtonWrapper>
                      <Button
                        onClick={() => openEditForm(organisation.ID)}
                        size="small"
                      >
                        Edit
                      </Button>
                      <Button
                        onClick={() => viewAnalytics(organisation.ID)}
                        size="small"
                        color="pink"
                      >
                        Analytics
                      </Button>
                    </ButtonWrapper>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <PaginationControlWrapper>
          <PaginationControl
            {...controlProps}
            total={data?.readOrganisations?.pageInfo?.totalCount}
          />
        </PaginationControlWrapper>
      </TableWrapper>
    </>
  );
}
