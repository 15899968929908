import styled from 'styled-components';
import { Loading, TextInput } from '@virtidev/toolbox';

export const Textarea = styled(TextInput)``;

export const PositionedLoading = styled(Loading)`
  pointer-events: none;
  position: absolute;
  top: 5px;
  right: 5px;
`;

export const TextHolder = styled.div`
  position: relative;
  max-width: 100%;
`;
