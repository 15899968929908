import { useCallback, useState } from 'react';
import useGetVHCounts from '../../../../../../../components/VirtualHumanCreationWizard/useGetVHCounts';
import VHAvailabilityCounterPopulated from '../../../../../../../components/VirtualHumanCreationWizard/VHAvailabilityCounterPopulated/VHAvailabilityCounterPopulated';
import VHCreationWizard from '../../../../../../../components/VirtualHumanCreationWizard/VHCreationWizard';
import WithConfirmationBox from '../../../../../../../HOCs/WithConfirmationBox';
import useStateUrl from '../../../../helpers/useStateUrl';
import useUser from '../../../../helpers/useUser';
import Button from '../../../form/Button';
import { useDebouncedSave } from '../../../form/hooks/useDebouncedSave';
import Select, { SelectGroup } from '../../../form/Select';
import { usePageScroll } from '../../../Page';
import { usePaginationUrl } from '../../../PaginationControl/helpers/usePaginationUrl';
import {
  Searchbox,
  StyledCreateWrapper,
  StyledSelectWrapper,
} from './ActionList.styled';
import CreateVirtualHumanWithAiForm from '../../../VirtualHumans/CreateVirtualHumanWithAiForm/CreateVirtualHumanWithAiForm';
import CreateFreeformVirtualHumanWithAiForm from '../../../VirtualHumans/CreateFreeformVirtualHumanWithAiForm/CreateVirtualHumanWithAiForm';
import LegacyCreateVirtualHumanWithAiForm from '../../../VirtualHumans/CreateFreeformVirtualHumanWithAiForm/LegacyCreateVirtualHumanWithAiForm';
import { useFeature } from '../../../LaunchDarkly';
import tracker from '../../../../helpers/tracker';
import { StateUrlSelectTag } from '../../../form/Select/components/SelectTag/SelectTag';

const statuses = [
  { value: '', label: 'All statuses' },
  { value: 'Published', label: 'Published' },
  { value: 'Unpublished', label: 'Unpublished' },
];

const groupOptions = [
  { value: '', label: 'All groups' },
  { value: '0', label: '(Has no groups)' },
];

const assignmentOptions = [
  { value: 'created', label: 'Created by you' },
  { value: 'assigned', label: 'Assigned to you' },
];

export const ActionList = ({ loading, confirm }) => {
  const {
    canMakeFreeform,
    canMakeBranching,
    isLoading: loadingVHCounts,
    refetch: refetchVHCounts,
  } = useGetVHCounts();
  const disabledCreateVH =
    (!canMakeFreeform && !canMakeBranching) || loadingVHCounts;

  const { UserType, Organisation } = useUser();
  const { scrollTop } = usePageScroll();

  const [createModalVisible, setCreateModalVisible] = useState(false);
  const hideCreateModal = useCallback(() => {
    setCreateModalVisible(false);
  }, []);

  const [freeformAiCreateModalVisible, setFreeformAiCreateModalVisible] =
    useState(false);
  const hideFreeformAiCreateModal = useCallback(() => {
    setFreeformAiCreateModalVisible(false);
  }, []);

  const [aiCreateModalVisible, setAiCreateModalVisible] = useState(false);
  const hideAiCreateModal = useCallback(() => {
    setAiCreateModalVisible(false);
  }, []);

  const onVHCreated = useCallback(() => {
    refetchVHCounts();
    setCreateModalVisible(false);
    setFreeformAiCreateModalVisible(false);
    setAiCreateModalVisible(false);
  }, [refetchVHCounts]);

  const { resetPage } = usePaginationUrl({
    onChange: scrollTop,
  });

  const { value: filter, updateValue: setFilter } = useStateUrl({
    initialValue: '',
    onChange: scrollTop,
  });
  const {
    value: groupFilter,
    updateValue: setGroupFilter,
    reset: resetGroup,
  } = useStateUrl({
    initialValue: '',
    onChange: scrollTop,
    key: 'group',
  });

  const {
    value: statusFilter,
    updateValue: setStatusFilter,
    reset: resetStatus,
  } = useStateUrl({
    initialValue: '',
    onChange: scrollTop,
    key: 'status',
  });

  const {
    value: assignment,
    updateValue: setAssignment,
    reset: resetAssignment,
  } = useStateUrl({
    initialValue: '',
    onChange: scrollTop,
    key: 'assignment',
  });

  const handleFilterChange = useCallback(
    (value) => {
      resetPage();
      setFilter(value || '');
    },
    [setFilter, resetPage]
  );

  const handleGroupFilterChange = useCallback(
    (value) => {
      resetPage();

      if (value?.value) {
        setGroupFilter(value.value);
      } else {
        resetGroup();
      }
    },
    [setGroupFilter, resetGroup, resetPage]
  );

  const handleStatusFilterChange = useCallback(
    (value) => {
      resetPage();

      if (value?.value) {
        setStatusFilter(value.value);
      } else {
        resetStatus();
      }
    },
    [setStatusFilter, resetStatus, resetPage]
  );

  const handleAssignmentChange = useCallback(
    (value) => {
      resetPage();

      if (value?.value) {
        setAssignment(value.value);
      } else {
        resetAssignment();
      }
    },
    [setAssignment, resetAssignment, resetPage]
  );

  const debouncedProps = useDebouncedSave(filter, {
    onUpdate: handleFilterChange,
  });

  const handleCreateNewVHClick = useCallback(() => {
    tracker.track('vh_create_without_ai_button_click');
    setCreateModalVisible(true);
  }, []);
  const handleFreeformAICreateClick = useCallback(() => {
    tracker.track('vh_create_with_ai_generator_button_click');
    setFreeformAiCreateModalVisible(true);
  }, []);
  const handleAICreateClick = useCallback(() => {
    tracker.track('vh_create_with_ai_generator_button_click');
    setAiCreateModalVisible(true);
  }, []);

  const vhBiographyCombinedFeature = useFeature('vh-biography-combined');
  const legacyFreeformV2Feature = useFeature('vh-freeform-legacy-v2');
  const freeformV3Feature = useFeature('freeform-v-2');

  return (
    <>
      {createModalVisible && (
        <VHCreationWizard
          cancelAction={hideCreateModal}
          onVHCreated={onVHCreated}
        />
      )}
      {aiCreateModalVisible && (
        <CreateVirtualHumanWithAiForm
          show={true}
          onHide={hideAiCreateModal}
          onVHCreated={refetchVHCounts}
        />
      )}
      {freeformAiCreateModalVisible && vhBiographyCombinedFeature && (
        <CreateFreeformVirtualHumanWithAiForm
          show={true}
          onHide={hideFreeformAiCreateModal}
          onVHCreated={refetchVHCounts}
          canMakeFreeform={canMakeFreeform}
        />
      )}
      {freeformAiCreateModalVisible && !vhBiographyCombinedFeature && (
        <LegacyCreateVirtualHumanWithAiForm
          show={true}
          onHide={hideFreeformAiCreateModal}
          onVHCreated={refetchVHCounts}
          canMakeFreeform={canMakeFreeform}
        />
      )}
      <StyledSelectWrapper>
        <StateUrlSelectTag
          param="tag"
          multi
          onChange={resetPage}
          placeholder="Filter by tag..."
          options={[{ value: '0', label: '(No tags)' }]}
        />
      </StyledSelectWrapper>
      {UserType === 'contentcreator' && (
        <StyledSelectWrapper>
          <Select
            value={assignment}
            options={assignmentOptions}
            loading={loading}
            placeholder="Filter by assignment..."
            onChange={handleAssignmentChange}
            clearable
          />
        </StyledSelectWrapper>
      )}
      <StyledSelectWrapper>
        <Select
          value={statusFilter}
          options={statuses}
          loading={loading}
          placeholder="Filter by status..."
          onChange={handleStatusFilterChange}
        />
      </StyledSelectWrapper>
      {UserType !== 'contentcreator' && (
        <>
          <StyledSelectWrapper>
            <SelectGroup
              onChange={handleGroupFilterChange}
              value={groupFilter}
              options={groupOptions}
              loading={loading}
              placeholder="Filter by group..."
            />
          </StyledSelectWrapper>
          <Searchbox
            {...debouncedProps}
            disabled={loading}
            placeholder="Search..."
          />
        </>
      )}
      <StyledCreateWrapper>
        {(legacyFreeformV2Feature || Organisation?.Medical) && (
          <VHAvailabilityCounterPopulated />
        )}
        <Button
          color="secondary"
          disabled={disabledCreateVH}
          onClick={handleCreateNewVHClick}
        >
          Create New Virtual Human
        </Button>
        {freeformV3Feature && (
          <Button
            color="primary"
            disabled={disabledCreateVH}
            onClick={handleAICreateClick}
          >
            ✨ Create{' '}
            {freeformV3Feature && legacyFreeformV2Feature
              ? 'conversational VH '
              : ''}
            with AI
          </Button>
        )}
        {legacyFreeformV2Feature && (
          <Button
            color="primary"
            disabled={disabledCreateVH}
            onClick={handleFreeformAICreateClick}
          >
            ✨ Create{' '}
            {freeformV3Feature && legacyFreeformV2Feature ? 'freeform VH ' : ''}
            with AI
          </Button>
        )}
      </StyledCreateWrapper>
    </>
  );
};

export default WithConfirmationBox(ActionList);
