import React, { useCallback, useState } from 'react';
import getConfig from '../../../../../../../../lib/config';
import useSaveTusFile from '../../../../../../../../utility/VHHooks/API/useSaveTusFile';
import { useFlashMessage } from '../../../../FlashMessage';
import VHAIFileItem from './components/VHAIFileItem/VHAIFileItem';
import {
  VHAIFileList,
  VHAIFileListTitle,
  VHAIFilesContainer,
  VHAIFilesUploader,
} from '@core/components/VirtualHumans/CreateVirtualHumanWithAiForm/components/VHAIFiles/VHAIFiles.styled';
import { useController } from 'react-hook-form';
import VHAIFilePlaceholder from '@core/components/VirtualHumans/CreateVirtualHumanWithAiForm/components/VHAIFiles/components/VHAIFilePlaceholder';

const VHAIFiles = ({ name, onChange, files }) => {
  const { addFlashMessage } = useFlashMessage();
  const {
    field: { onChange: onChangeField, value: fileIds },
  } = useController({ name });

  const { saveTusFile, isLoading: isCreating } = useSaveTusFile({
    onComplete: (res) => {
      const file = res?.data?.data?.file;
      if (!file) {
        addFlashMessage('Failed to add file', 'error');
        return;
      }
      addFlashMessage('File successfully uploaded');

      const newFiles = [...files, file];
      onChange(newFiles);

      const newIds = [...(fileIds || []), file.id];
      onChangeField(newIds);
      stopLoading();
    },
    onError: (err) => addFlashMessage(err.message, 'error'),
  });
  const [loading, setLoading] = useState(false);

  const startLoading = useCallback(() => {
    console.log('sending');
    setLoading(true);
  }, []);

  const stopLoading = useCallback(() => {
    setLoading(false);
  }, []);

  const removeFile = useCallback(
    (fileId) => {
      const newIds = (fileIds || []).filter((id) => id !== fileId);
      onChangeField(newIds);

      const newFiles = files.filter((file) => file.id !== fileId);
      onChange(newFiles);
    },
    [fileIds, onChangeField, files, onChange]
  );

  const saveFile = useCallback(
    (upload, data) => {
      const file =
        upload.file instanceof (window || global).File ? upload.file : null;

      if (!file) {
        addFlashMessage('Unable to process provided file.', 'error');
        return;
      }
      saveTusFile({
        file,
        tusUrl: upload.url,
        tusId: upload.url?.split('/').slice(-1)[0].split('+')[0] ?? '',
        parseText: true,
      });
    },
    [addFlashMessage, saveTusFile]
  );

  return (
    <VHAIFilesContainer>
      <VHAIFilesUploader
        getUploadEndpoint={() =>
          getConfig('REACT_APP_TUS_VH_UPLOAD_ENDPOINT_OTHER_MEDIA')
        }
        acceptedTypes={['document']}
        onSuccess={saveFile}
        onSending={startLoading}
        loading={loading || isCreating}
        onError={(message) => console.error(message)}
        onComplete={stopLoading}
        onCancel={stopLoading}
        boxContent="Upload files"
        overlayContent="Drop your files here"
      />
      {(!!files.length || isCreating) && (
        <>
          <VHAIFileListTitle>Your uploaded files</VHAIFileListTitle>
          <VHAIFileList>
            {files.map((file) => {
              return (
                <VHAIFileItem key={file.id} file={file} onDelete={removeFile} />
              );
            })}
            {isCreating && <VHAIFilePlaceholder />}
          </VHAIFileList>
        </>
      )}
    </VHAIFilesContainer>
  );
};

export default VHAIFiles;
