import { useMemo } from 'react';

/** @type {{[key: string]: {OverallAnalysis: string; AreasOfImprovement: string; CustomAssessment: string; GeneratedWithAI: string; PerformanceOverview: string;}}} */
const translations = {
  'af-ZA': {
    OverallAnalysis: 'Algehele ontleding',
    AreasOfImprovement: 'Gebiede van verbetering',
    CustomAssessment: 'Pasgemaakte assessering',
    GeneratedWithAI: 'Gegenereer met KI',
    PerformanceOverview: 'Prestasie-oorsig',
  },
  'bg-BG': {
    OverallAnalysis: 'Общ анализ',
    AreasOfImprovement: 'Области за подобрение',
    CustomAssessment: 'Персонализирана оценка',
    GeneratedWithAI: 'Генерирано с ИИ',
    PerformanceOverview: 'Преглед на изпълнението',
  },
  'ca-ES': {
    OverallAnalysis: 'Anàlisi general',
    AreasOfImprovement: 'Àrees de millora',
    CustomAssessment: 'Avaluació personalitzada',
    GeneratedWithAI: 'Generat amb IA',
    PerformanceOverview: 'Visió general del rendiment',
  },
  'cs-CZ': {
    OverallAnalysis: 'Celková analýza',
    AreasOfImprovement: 'Oblasti zlepšení',
    CustomAssessment: 'Vlastní hodnocení',
    GeneratedWithAI: 'Generováno s AI',
    PerformanceOverview: 'Přehled výkonu',
  },
  'da-DK': {
    OverallAnalysis: 'Samlet analyse',
    AreasOfImprovement: 'Forbedringsområder',
    CustomAssessment: 'Tilpasset vurdering',
    GeneratedWithAI: 'Genereret med AI',
    PerformanceOverview: 'Præstationsoversigt',
  },
  'de-AT': {
    OverallAnalysis: 'Gesamtanalyse',
    AreasOfImprovement: 'Verbesserungsbereiche',
    CustomAssessment: 'Benutzerdefinierte Bewertung',
    GeneratedWithAI: 'Mit KI generiert',
    PerformanceOverview: 'Leistungsübersicht',
  },
  'de-CH': {
    OverallAnalysis: 'Gesamtanalyse',
    AreasOfImprovement: 'Verbesserungsbereiche',
    CustomAssessment: 'Benutzerdefinierte Bewertung',
    GeneratedWithAI: 'Mit KI generiert',
    PerformanceOverview: 'Leistungsübersicht',
  },
  'de-DE': {
    OverallAnalysis: 'Gesamtanalyse',
    AreasOfImprovement: 'Verbesserungsbereiche',
    CustomAssessment: 'Benutzerdefinierte Bewertung',
    GeneratedWithAI: 'Mit KI generiert',
    PerformanceOverview: 'Leistungsübersicht',
  },
  'el-GR': {
    OverallAnalysis: 'Συνολική ανάλυση',
    AreasOfImprovement: 'Τομείς βελτίωσης',
    CustomAssessment: 'Προσαρμοσμένη αξιολόγηση',
    GeneratedWithAI: 'Δημιουργήθηκε με ΤΝ',
    PerformanceOverview: 'Επισκόπηση απόδοσης',
  },
  'en-AU': {
    OverallAnalysis: 'Overall analysis',
    AreasOfImprovement: 'Areas of improvement',
    CustomAssessment: 'Custom assessment',
    GeneratedWithAI: 'Generated with AI',
    PerformanceOverview: 'Performance overview',
  },
  'en-CA': {
    OverallAnalysis: 'Overall analysis',
    AreasOfImprovement: 'Areas of improvement',
    CustomAssessment: 'Custom assessment',
    GeneratedWithAI: 'Generated with AI',
    PerformanceOverview: 'Performance overview',
  },
  'en-GB': {
    OverallAnalysis: 'Overall analysis',
    AreasOfImprovement: 'Areas of improvement',
    CustomAssessment: 'Custom assessment',
    GeneratedWithAI: 'Generated with AI',
    PerformanceOverview: 'Performance overview',
  },
  'en-GH': {
    OverallAnalysis: 'Overall analysis',
    AreasOfImprovement: 'Areas of improvement',
    CustomAssessment: 'Custom assessment',
    GeneratedWithAI: 'Generated with AI',
    PerformanceOverview: 'Performance overview',
  },
  'en-HK': {
    OverallAnalysis: 'Overall analysis',
    AreasOfImprovement: 'Areas of improvement',
    CustomAssessment: 'Custom assessment',
    GeneratedWithAI: 'Generated with AI',
    PerformanceOverview: 'Performance overview',
  },
  'en-IE': {
    OverallAnalysis: 'Overall analysis',
    AreasOfImprovement: 'Areas of improvement',
    CustomAssessment: 'Custom assessment',
    GeneratedWithAI: 'Generated with AI',
    PerformanceOverview: 'Performance overview',
  },
  'en-IN': {
    OverallAnalysis: 'Overall analysis',
    AreasOfImprovement: 'Areas of improvement',
    CustomAssessment: 'Custom assessment',
    GeneratedWithAI: 'Generated with AI',
    PerformanceOverview: 'Performance overview',
  },
  'en-KE': {
    OverallAnalysis: 'Overall analysis',
    AreasOfImprovement: 'Areas of improvement',
    CustomAssessment: 'Custom assessment',
    GeneratedWithAI: 'Generated with AI',
    PerformanceOverview: 'Performance overview',
  },
  'en-NG': {
    OverallAnalysis: 'Overall analysis',
    AreasOfImprovement: 'Areas of improvement',
    CustomAssessment: 'Custom assessment',
    GeneratedWithAI: 'Generated with AI',
    PerformanceOverview: 'Performance overview',
  },
  'en-NZ': {
    OverallAnalysis: 'Overall analysis',
    AreasOfImprovement: 'Areas of improvement',
    CustomAssessment: 'Custom assessment',
    GeneratedWithAI: 'Generated with AI',
    PerformanceOverview: 'Performance overview',
  },
  'en-PH': {
    OverallAnalysis: 'Overall analysis',
    AreasOfImprovement: 'Areas of improvement',
    CustomAssessment: 'Custom assessment',
    GeneratedWithAI: 'Generated with AI',
    PerformanceOverview: 'Performance overview',
  },
  'en-SG': {
    OverallAnalysis: 'Overall analysis',
    AreasOfImprovement: 'Areas of improvement',
    CustomAssessment: 'Custom assessment',
    GeneratedWithAI: 'Generated with AI',
    PerformanceOverview: 'Performance overview',
  },
  'en-TZ': {
    OverallAnalysis: 'Overall analysis',
    AreasOfImprovement: 'Areas of improvement',
    CustomAssessment: 'Custom assessment',
    GeneratedWithAI: 'Generated with AI',
    PerformanceOverview: 'Performance overview',
  },
  'en-US': {
    OverallAnalysis: 'Overall analysis',
    AreasOfImprovement: 'Areas of improvement',
    CustomAssessment: 'Custom assessment',
    GeneratedWithAI: 'Generated with AI',
    PerformanceOverview: 'Performance overview',
  },
  'en-ZA': {
    OverallAnalysis: 'Overall analysis',
    AreasOfImprovement: 'Areas of improvement',
    CustomAssessment: 'Custom assessment',
    GeneratedWithAI: 'Generated with AI',
    PerformanceOverview: 'Performance overview',
  },
  'es-AR': {
    OverallAnalysis: 'Análisis general',
    AreasOfImprovement: 'Áreas de mejora',
    CustomAssessment: 'Evaluación personalizada',
    GeneratedWithAI: 'Generado con IA',
    PerformanceOverview: 'Visión general del rendimiento',
  },
  'es-BO': {
    OverallAnalysis: 'Análisis general',
    AreasOfImprovement: 'Áreas de mejora',
    CustomAssessment: 'Evaluación personalizada',
    GeneratedWithAI: 'Generado con IA',
    PerformanceOverview: 'Visión general del rendimiento',
  },
  'es-CL': {
    OverallAnalysis: 'Análisis general',
    AreasOfImprovement: 'Áreas de mejora',
    CustomAssessment: 'Evaluación personalizada',
    GeneratedWithAI: 'Generado con IA',
    PerformanceOverview: 'Visión general del rendimiento',
  },
  'es-CO': {
    OverallAnalysis: 'Análisis general',
    AreasOfImprovement: 'Áreas de mejora',
    CustomAssessment: 'Evaluación personalizada',
    GeneratedWithAI: 'Generado con IA',
    PerformanceOverview: 'Visión general del rendimiento',
  },
  'es-CR': {
    OverallAnalysis: 'Análisis general',
    AreasOfImprovement: 'Áreas de mejora',
    CustomAssessment: 'Evaluación personalizada',
    GeneratedWithAI: 'Generado con IA',
    PerformanceOverview: 'Visión general del rendimiento',
  },
  'es-CU': {
    OverallAnalysis: 'Análisis general',
    AreasOfImprovement: 'Áreas de mejora',
    CustomAssessment: 'Evaluación personalizada',
    GeneratedWithAI: 'Generado con IA',
    PerformanceOverview: 'Visión general del rendimiento',
  },
  'es-DO': {
    OverallAnalysis: 'Análisis general',
    AreasOfImprovement: 'Áreas de mejora',
    CustomAssessment: 'Evaluación personalizada',
    GeneratedWithAI: 'Generado con IA',
    PerformanceOverview: 'Visión general del rendimiento',
  },
  'es-EC': {
    OverallAnalysis: 'Análisis general',
    AreasOfImprovement: 'Áreas de mejora',
    CustomAssessment: 'Evaluación personalizada',
    GeneratedWithAI: 'Generado con IA',
    PerformanceOverview: 'Visión general del rendimiento',
  },
  'es-ES': {
    OverallAnalysis: 'Análisis general',
    AreasOfImprovement: 'Áreas de mejora',
    CustomAssessment: 'Evaluación personalizada',
    GeneratedWithAI: 'Generado con IA',
    PerformanceOverview: 'Visión general del rendimiento',
  },
  'es-GQ': {
    OverallAnalysis: 'Análisis general',
    AreasOfImprovement: 'Áreas de mejora',
    CustomAssessment: 'Evaluación personalizada',
    GeneratedWithAI: 'Generado con IA',
    PerformanceOverview: 'Visión general del rendimiento',
  },
  'es-GT': {
    OverallAnalysis: 'Análisis general',
    AreasOfImprovement: 'Áreas de mejora',
    CustomAssessment: 'Evaluación personalizada',
    GeneratedWithAI: 'Generado con IA',
    PerformanceOverview: 'Visión general del rendimiento',
  },
  'es-HN': {
    OverallAnalysis: 'Análisis general',
    AreasOfImprovement: 'Áreas de mejora',
    CustomAssessment: 'Evaluación personalizada',
    GeneratedWithAI: 'Generado con IA',
    PerformanceOverview: 'Visión general del rendimiento',
  },
  'es-MX': {
    OverallAnalysis: 'Análisis general',
    AreasOfImprovement: 'Áreas de mejora',
    CustomAssessment: 'Evaluación personalizada',
    GeneratedWithAI: 'Generado con IA',
    PerformanceOverview: 'Visión general del rendimiento',
  },
  'es-NI': {
    OverallAnalysis: 'Análisis general',
    AreasOfImprovement: 'Áreas de mejora',
    CustomAssessment: 'Evaluación personalizada',
    GeneratedWithAI: 'Generado con IA',
    PerformanceOverview: 'Visión general del rendimiento',
  },
  'es-PA': {
    OverallAnalysis: 'Análisis general',
    AreasOfImprovement: 'Áreas de mejora',
    CustomAssessment: 'Evaluación personalizada',
    GeneratedWithAI: 'Generado con IA',
    PerformanceOverview: 'Visión general del rendimiento',
  },
  'es-PE': {
    OverallAnalysis: 'Análisis general',
    AreasOfImprovement: 'Áreas de mejora',
    CustomAssessment: 'Evaluación personalizada',
    GeneratedWithAI: 'Generado con IA',
    PerformanceOverview: 'Visión general del rendimiento',
  },
  'es-PR': {
    OverallAnalysis: 'Análisis general',
    AreasOfImprovement: 'Áreas de mejora',
    CustomAssessment: 'Evaluación personalizada',
    GeneratedWithAI: 'Generado con IA',
    PerformanceOverview: 'Visión general del rendimiento',
  },
  'es-PY': {
    OverallAnalysis: 'Análisis general',
    AreasOfImprovement: 'Áreas de mejora',
    CustomAssessment: 'Evaluación personalizada',
    GeneratedWithAI: 'Generado con IA',
    PerformanceOverview: 'Visión general del rendimiento',
  },
  'es-SV': {
    OverallAnalysis: 'Análisis general',
    AreasOfImprovement: 'Áreas de mejora',
    CustomAssessment: 'Evaluación personalizada',
    GeneratedWithAI: 'Generado con IA',
    PerformanceOverview: 'Visión general del rendimiento',
  },
  'es-US': {
    OverallAnalysis: 'Análisis general',
    AreasOfImprovement: 'Áreas de mejora',
    CustomAssessment: 'Evaluación personalizada',
    GeneratedWithAI: 'Generado con IA',
    PerformanceOverview: 'Visión general del rendimiento',
  },
  'es-UY': {
    OverallAnalysis: 'Análisis general',
    AreasOfImprovement: 'Áreas de mejora',
    CustomAssessment: 'Evaluación personalizada',
    GeneratedWithAI: 'Generado con IA',
    PerformanceOverview: 'Visión general del rendimiento',
  },
  'es-VE': {
    OverallAnalysis: 'Análisis general',
    AreasOfImprovement: 'Áreas de mejora',
    CustomAssessment: 'Evaluación personalizada',
    GeneratedWithAI: 'Generado con IA',
    PerformanceOverview: 'Visión general del rendimiento',
  },
  'fr-BE': {
    OverallAnalysis: 'Analyse générale',
    AreasOfImprovement: "Domaines d'amélioration",
    CustomAssessment: 'Évaluation personnalisée',
    GeneratedWithAI: 'Généré avec IA',
    PerformanceOverview: 'Vue d’ensemble des performances',
  },
  'fr-CA': {
    OverallAnalysis: 'Analyse générale',
    AreasOfImprovement: "Domaines d'amélioration",
    CustomAssessment: 'Évaluation personnalisée',
    GeneratedWithAI: 'Généré avec IA',
    PerformanceOverview: 'Vue d’ensemble des performances',
  },
  'fr-CH': {
    OverallAnalysis: 'Analyse générale',
    AreasOfImprovement: "Domaines d'amélioration",
    CustomAssessment: 'Évaluation personnalisée',
    GeneratedWithAI: 'Généré avec IA',
    PerformanceOverview: 'Vue d’ensemble des performances',
  },
  'fr-FR': {
    OverallAnalysis: 'Analyse générale',
    AreasOfImprovement: "Domaines d'amélioration",
    CustomAssessment: 'Évaluation personnalisée',
    GeneratedWithAI: 'Généré avec IA',
    PerformanceOverview: 'Vue d’ensemble des performances',
  },
  'gu-IN': {
    OverallAnalysis: 'કુલ વિશ્લેષણ',
    AreasOfImprovement: 'સુધારાના ક્ષેત્રો',
    CustomAssessment: 'કસ્ટમ મૂલ્યાંકન',
    GeneratedWithAI: 'એઆઇ સાથે ઉત્પન્ન',
    PerformanceOverview: 'કાર્યક્ષમતાની સમીક્ષા',
  },
  'hi-IN': {
    OverallAnalysis: 'समग्र विश्लेषण',
    AreasOfImprovement: 'सुधार के क्षेत्र',
    CustomAssessment: 'कस्टम मूल्यांकन',
    GeneratedWithAI: 'एआई के साथ उत्पन्न',
    PerformanceOverview: 'प्रदर्शन अवलोकन',
  },
  'hu-HU': {
    OverallAnalysis: 'Átfogó elemzés',
    AreasOfImprovement: 'Fejlesztési területek',
    CustomAssessment: 'Egyedi értékelés',
    GeneratedWithAI: 'AI által generált',
    PerformanceOverview: 'Teljesítmény áttekintése',
  },
  'id-ID': {
    OverallAnalysis: 'Analisis keseluruhan',
    AreasOfImprovement: 'Area perbaikan',
    CustomAssessment: 'Penilaian khusus',
    GeneratedWithAI: 'Dihasilkan dengan AI',
    PerformanceOverview: 'Ikhtisar kinerja',
  },
  'is-IS': {
    OverallAnalysis: 'Heildargreining',
    AreasOfImprovement: 'Svæði til úrbóta',
    CustomAssessment: 'Sérsniðin mat',
    GeneratedWithAI: 'Búið til með gervigreind',
    PerformanceOverview: 'Yfirlit yfir frammistöðu',
  },
  'it-CH': {
    OverallAnalysis: 'Analisi complessiva',
    AreasOfImprovement: 'Aree di miglioramento',
    CustomAssessment: 'Valutazione personalizzata',
    GeneratedWithAI: 'Generato con IA',
    PerformanceOverview: 'Panoramica delle prestazioni',
  },
  'it-IT': {
    OverallAnalysis: 'Analisi complessiva',
    AreasOfImprovement: 'Aree di miglioramento',
    CustomAssessment: 'Valutazione personalizzata',
    GeneratedWithAI: 'Generato con IA',
    PerformanceOverview: 'Panoramica delle prestazioni',
  },
  'ja-JP': {
    OverallAnalysis: '全体分析',
    AreasOfImprovement: '改善点',
    CustomAssessment: 'カスタム評価',
    GeneratedWithAI: 'AI で生成された',
    PerformanceOverview: 'パフォーマンスの概要',
  },
  'kn-IN': {
    OverallAnalysis: 'ಒಟ್ಟು ವಿಶ್ಲೇಷಣೆ',
    AreasOfImprovement: 'ಸುಧಾರಣೆಯ ಪ್ರದೇಶಗಳು',
    CustomAssessment: 'ಕಸ್ಟಮ್ ಮೌಲ್ಯಮಾಪನ',
    GeneratedWithAI: 'ಎಐ ಮೂಲಕ ಉತ್ಪಾದಿಸಲಾಗಿದೆ',
    PerformanceOverview: 'ಕಾರ್ಯಕ್ಷಮತೆ ವಿವರ',
  },
  'ko-KR': {
    OverallAnalysis: '종합 분석',
    AreasOfImprovement: '개선 분야',
    CustomAssessment: '맞춤형 평가',
    GeneratedWithAI: 'AI로 생성됨',
    PerformanceOverview: '성능 개요',
  },
  'lv-LV': {
    OverallAnalysis: 'Vispārēja analīze',
    AreasOfImprovement: 'Uzlabojumu jomas',
    CustomAssessment: 'Pielāgots novērtējums',
    GeneratedWithAI: 'Ģenerēts ar AI',
    PerformanceOverview: 'Veiktspējas pārskats',
  },
  'ml-IN': {
    OverallAnalysis: 'ആകെ വിശകലനം',
    AreasOfImprovement: 'മെച്ചപ്പെടുത്തേണ്ട മേഖലകൾ',
    CustomAssessment: 'ഇഷ്ടാനുസൃത വിലയിരുത്തൽ',
    GeneratedWithAI: 'AI ഉപയോഗിച്ച് സൃഷ്ടിച്ചു',
    PerformanceOverview: 'പ്രവർത്തനത്തെക്കുറിച്ചുള്ള അവലോകനം',
  },
  'ms-MY': {
    OverallAnalysis: 'Analisis keseluruhan',
    AreasOfImprovement: 'Bidang untuk diperbaiki',
    CustomAssessment: 'Penilaian tersuai',
    GeneratedWithAI: 'Dihasilkan dengan AI',
    PerformanceOverview: 'Gambaran Prestasi',
  },
  'nl-BE': {
    OverallAnalysis: 'Algemene analyse',
    AreasOfImprovement: 'Verbeteringsgebieden',
    CustomAssessment: 'Aangepaste beoordeling',
    GeneratedWithAI: 'Gegenereerd met AI',
    PerformanceOverview: 'Prestatieoverzicht',
  },
  'nl-NL': {
    OverallAnalysis: 'Algemene analyse',
    AreasOfImprovement: 'Verbeteringsgebieden',
    CustomAssessment: 'Aangepaste beoordeling',
    GeneratedWithAI: 'Gegenereerd met AI',
    PerformanceOverview: 'Prestatieoverzicht',
  },
  'pl-PL': {
    OverallAnalysis: 'Ogólna analiza',
    AreasOfImprovement: 'Obszary do poprawy',
    CustomAssessment: 'Ocena niestandardowa',
    GeneratedWithAI: 'Wygenerowano za pomocą AI',
    PerformanceOverview: 'Przegląd wydajności',
  },
  'pt-BR': {
    OverallAnalysis: 'Análise geral',
    AreasOfImprovement: 'Áreas de melhoria',
    CustomAssessment: 'Avaliação personalizada',
    GeneratedWithAI: 'Gerado com IA',
    PerformanceOverview: 'Visão geral de desempenho',
  },
  'pt-PT': {
    OverallAnalysis: 'Análise geral',
    AreasOfImprovement: 'Áreas de melhoria',
    CustomAssessment: 'Avaliação personalizada',
    GeneratedWithAI: 'Gerado com IA',
    PerformanceOverview: 'Visão geral de desempenho',
  },
  'ru-RU': {
    OverallAnalysis: 'Общий анализ',
    AreasOfImprovement: 'Области для улучшения',
    CustomAssessment: 'Пользовательская оценка',
    GeneratedWithAI: 'Сгенерировано ИИ',
    PerformanceOverview: 'Обзор производительности',
  },
  'sk-SK': {
    OverallAnalysis: 'Celková analýza',
    AreasOfImprovement: 'Oblasti zlepšenia',
    CustomAssessment: 'Vlastné hodnotenie',
    GeneratedWithAI: 'Generované s AI',
    PerformanceOverview: 'Prehľad výkonu',
  },
  'sr-RS': {
    OverallAnalysis: 'Општа анализа',
    AreasOfImprovement: 'Области за унапређење',
    CustomAssessment: 'Прилагођена процена',
    GeneratedWithAI: 'Генерисано са вештачком интелигенцијом',
    PerformanceOverview: 'Преглед перформанси',
  },
  'sv-SE': {
    OverallAnalysis: 'Övergripande analys',
    AreasOfImprovement: 'Förbättringsområden',
    CustomAssessment: 'Anpassad bedömning',
    GeneratedWithAI: 'Genererat med AI',
    PerformanceOverview: 'Prestandaöversikt',
  },
  'th-TH': {
    OverallAnalysis: 'การวิเคราะห์โดยรวม',
    AreasOfImprovement: 'พื้นที่สำหรับการปรับปรุง',
    CustomAssessment: 'การประเมินแบบกำหนดเอง',
    GeneratedWithAI: 'สร้างด้วย AI',
    PerformanceOverview: 'ภาพรวมประสิทธิภาพ',
  },
  'tr-TR': {
    OverallAnalysis: 'Genel analiz',
    AreasOfImprovement: 'İyileştirme alanları',
    CustomAssessment: 'Özel Değerlendirme',
    GeneratedWithAI: 'Yapay zeka ile oluşturulmuş',
    PerformanceOverview: 'Performans Özeti',
  },
  'uk-UA': {
    OverallAnalysis: 'Загальний аналіз',
    AreasOfImprovement: 'Області покращення',
    CustomAssessment: 'Індивідуальна оцінка',
    GeneratedWithAI: 'Згенеровано штучним інтелектом',
    PerformanceOverview: 'Огляд продуктивності',
  },
  'zh-CN': {
    OverallAnalysis: '综合分析',
    AreasOfImprovement: '改进领域',
    CustomAssessment: '自定义评估',
    GeneratedWithAI: '由AI生成',
    PerformanceOverview: '性能概览',
  },
  'zh-HK': {
    OverallAnalysis: '整體分析',
    AreasOfImprovement: '改進領域',
    CustomAssessment: '自訂評估',
    GeneratedWithAI: '由AI生成',
    PerformanceOverview: '性能概覽',
  },
  'zh-TW': {
    OverallAnalysis: '整體分析',
    AreasOfImprovement: '改進領域',
    CustomAssessment: '自訂評估',
    GeneratedWithAI: '由AI生成',
    PerformanceOverview: '性能概覽',
  },
  'fi-FI': {
    OverallAnalysis: 'Kokonaisanalyysi',
    AreasOfImprovement: 'Parannusalueet',
    CustomAssessment: 'Mukautettu arviointi',
    GeneratedWithAI: 'Luotu tekoälyllä',
    PerformanceOverview: 'Suorituskyvyn yleiskatsaus',
  },
  'vi-VN': {
    OverallAnalysis: 'Phân tích tổng thể',
    AreasOfImprovement: 'Các khu vực cần cải thiện',
    CustomAssessment: 'Đánh giá tùy chỉnh',
    GeneratedWithAI: 'Được tạo bởi AI',
    PerformanceOverview: 'Tổng quan hiệu suất',
  },
  'nb-NO': {
    OverallAnalysis: 'Totalanalyse',
    AreasOfImprovement: 'Forbedringsområder',
    CustomAssessment: 'Tilpasset vurdering',
    GeneratedWithAI: 'Generert med AI',
    PerformanceOverview: 'Ytelsesoversikt',
  },
  'en-SW': {
    OverallAnalysis: 'Overall analysis',
    AreasOfImprovement: 'Areas of improvement',
    CustomAssessment: 'Custom assessment',
    GeneratedWithAI: 'Generated with AI',
    PerformanceOverview: 'Performance overview',
  },
  'en-IT': {
    OverallAnalysis: 'Overall analysis',
    AreasOfImprovement: 'Areas of improvement',
    CustomAssessment: 'Custom assessment',
    GeneratedWithAI: 'Generated with AI',
    PerformanceOverview: 'Performance overview',
  },
};

/** @type {(bcp47Code: string)=> {OverallAnalysis: string; AreasOfImprovement: string; CustomAssessment: string; GeneratedWithAI: string; PerformanceOverview: string;}} */
export const useTranslatedReportHeaders = (bcp47Code) => {
  return useMemo(() => {
    return translations?.[bcp47Code] ?? translations['en-GB'];
  }, [bcp47Code]);
};
