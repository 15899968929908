import { pseudoOrganisationFragment } from './OrganisationQueries';
import { pseudoSimulationFragmentBase } from './SimulationQueries';
import { gql } from '@apollo/client';

export const CX_ANALYTICS = gql`
  query cxAnalytics {
    cxAnalytics {
      DAU
      DAA
      MAU
      MAA
      TotalUsers
      ActiveUsers
      Retention
      MAUMonthly
      MAAMonthly
      Licenses
    }
  }
`;

export const CX_ORGANISATION_ANALYTICS = gql`
  query cxOrganisationAnalytics($organisationID: ID!) {
    cxOrganisationAnalytics(OrganisationID: $organisationID) {
      DAU
      MAU
      DAA
      MAA
      TotalUsers
      ActiveUsers
      Retention
      MAUMonthly
      MAAMonthly
      Licenses
    }
  }
`;

export const COPY_SIMULATION = gql`
mutation copySimulation($OrganisationID: ID!, $SimulationID: ID!, $PublishStrategy: String = "DRAFT") {
  copySimulation(OrganisationID: $OrganisationID, SimulationID: $SimulationID, PublishStrategy: $PublishStrategy) {
    ${pseudoSimulationFragmentBase}
  }
}
`;

export const COPY_USER = gql`
  mutation addMemberToOrganisation($OrganisationID: ID!, $MemberID: ID!) {
    addMemberToOrganisation(
      OrganisationID: $OrganisationID
      MemberID: $MemberID
    ) {
      ID
      Name
      Organisations {
        edges {
          node {
            ID
            Name
          }
        }
      }
    }
  }
`;

export const ADD_ORGANISATION = gql`
  mutation createOrganisation($Input: CreateOrganisationInput!) {
    createOrganisation(input: $Input) {
      ${pseudoOrganisationFragment}
    }
  }
`;
