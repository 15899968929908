import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { sendGetRequest, sendPostRequest } from '../services/PDService';

export const fetchCondition = createAsyncThunk(
  'diagnosis/getCondition',
  async (patientId, { getState, dispatch }) => {
    const response = await sendGetRequest(
      'condition/' + patientId,
      getState().virtualHuman.data.ShareToken
    );
    return response.data.condition;
  }
);
export const checkDiagnosis = createAsyncThunk(
  'diagnosis/checkDiagnosis',
  async (formData, { getState, dispatch, rejectWithValue }) => {
    const chatSessionId = getState().chat.chatSessionId;
    const timeSpent = new Date().getTime() - getState().chat.startTime;
    formData = {
      ...formData,
      timeSpent,
      completedObjectives: JSON.stringify(getState().chat.completedObjectives),
      completedExamObjectives: JSON.stringify(
        getState().chat.completedExamObjectives
      ),
    };
    try {
      const response = await sendPostRequest(
        'diagnosis',
        formData,
        chatSessionId,
        false,
        getState().virtualHuman.data.ShareToken
      );
      const condition = getState().diagnosis.condition;
      if (!condition) {
        return response.data.success ? 'Correct.' : 'Incorrect';
      }
      if (typeof response.data.PDDiagnosisScore === 'undefined') {
        throw new Error('No PDDiagnosisScore');
      }
      return {
        diagnosisScore: response.data.PDDiagnosisScore,
        conversationScore: response.data.PDConversationScore,
        totalPointsPossible: response.data.PDTotalPointsPossible,
        hitObjectivesData: response.data.hitObjectivesData,
        objectives: response.data.objectives,
        actionObjectivesGroupedByHeadings:
          response.data.actionObjectivesGroupedByHeadings,
        timeSpent,
        message: response.data.success
          ? condition.successMessage
          : condition.failMessage,
        diagnosisGuess: formData.text ?? '',
        sessionReport: response.data.sessionReport,
      };
    } catch (e) {
      return rejectWithValue({
        error: 'Failed diagnosis request.',
        resendDiagnosisFormData: formData,
      });
    }
  }
);

const initialState = {
  fetchConditionStatus: 'idle',
  checkDiagnosisStatus: 'idle',
  condition: null,
};

const DiagnosisSlice = createSlice({
  name: 'diagnosis',
  initialState,
  reducers: {
    resetDiagnosis: (state, action) => {
      state.fetchConditionStatus = initialState.fetchConditionStatus;
      state.checkDiagnosisStatus = initialState.checkDiagnosisStatus;
      state.condition = initialState.condition;
    },
  },
  extraReducers: {
    [fetchCondition.pending]: (state, action) => {
      state.fetchConditionStatus = 'pending';
    },
    [fetchCondition.fulfilled]: (state, action) => {
      state.fetchConditionStatus = 'idle';
      state.condition = action.payload;
    },
    [checkDiagnosis.pending]: (state, action) => {
      state.checkDiagnosisStatus = 'pending';
    },
    [checkDiagnosis.fulfilled]: (state, action) => {
      state.checkDiagnosisStatus = 'idle';
    },
  },
});

// Destructure and export the plain action creators
export const { resetDiagnosis } = DiagnosisSlice.actions;

// export const selectDiagnosisSuccess = (state) => state.diagnosis.success;
// export const selectLoadingConditionStatus = (state) =>
//   state.fetchConditionStatus;
// export const selectLoadingCheckDiagnosisStatus = (state) =>
//   state.checkDiagnosisStatus;

export default DiagnosisSlice;

// // Define a thunk that dispatches those action creators
// const fetchWorkspaces = () => async dispatch => {
//   dispatch(chatLoading())
//   const response = await workspacesAPI.fetchAll()
//   dispatch(chatReceived(response.data))
// }

// const fetchWorkspaceById = createAsyncThunk(
//   'users/fetchByIdStatus',
//   async (userId, thunkAPI) => {
//     const response = await userAPI.fetchById(userId)
//     return response.data
//   }
// )

export const diagnosisPromptSelector = (state) =>
  state.diagnosis?.condition?.diagnosisPrompt;
export const diagnosisSuccessMessageSelector = (state) =>
  state.diagnosis?.condition?.successMessage;
export const diagnosisFailMessageSelector = (state) =>
  state.diagnosis?.condition?.failMessage;
