import { Tooltip } from '@virtidev/toolbox';
import styled, { css } from 'styled-components';

export const VHTypeIcon = styled.div`
  position: relative;
`;

export const VHTypeTooltip = styled(Tooltip)`
  border-radius: 50%;
  background: rgba(220, 220, 220, 0.4);
  width: 3rem;
  height: 3rem;
  :hover {
    background: rgba(220, 220, 220, 0.8);
  }
`;

export const FreeformV3Indicator = styled.div`
  ${({ theme }) => css`
    position: absolute;
    color: ${theme.color.white};
    right: 6px;
    top: 0;
    font-weight: ${theme.fontWeight.bold};
  `};
`;