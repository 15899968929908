import { useMutation } from '@apollo/client';
import { Button, Loading, TextInput } from '@virtidev/toolbox';
import { useCallback } from 'react';
import useFlashMessage from '../../../FlashMessage';
import Label from '../../../form/Label';
import { VR_PAIR } from '../../VRPairing.query';
import { ButtonContainer, Card, Header } from './VRPairingForm.styled';
import { Logout } from '@core/components/VRPairing/VRPairing.styled';

export const VRPairingForm = ({ onPaired }) => {
  const [pairCode, { loading }] = useMutation(VR_PAIR);
  const { addFlashMessage } = useFlashMessage();

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const form = new FormData(e.target);

      try {
        const { data } = await pairCode({
          variables: {
            Code: form.get('code'),
          },
        });

        if (Number(data.activateMagicCode.ID) > 0) {
          onPaired();
        }
      } catch (e) {
        console.error(e);
        addFlashMessage(
          'Failed to pair device, please double check your code.',
          'error'
        );
      }
    },
    [pairCode, onPaired, addFlashMessage]
  );

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <Header>Pair Your VR Device</Header>
        <Label>Enter your pairing code below</Label>
        <TextInput
          name="code"
          placeholder="Input code here..."
          disabled={loading}
        />
        <ButtonContainer>
          <Button color="primary" type="submit" disabled={loading}>
            Pair Now {loading && <Loading />}
          </Button>
          <Logout>Log out</Logout>
        </ButtonContainer>
      </Card>
    </form>
  );
};

export default VRPairingForm;
