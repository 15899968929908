import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  gap: 1rem;
  button {
    border-radius: 22px;
  }
`;

export const StyledLabel = styled.label`
  display: block;
  font-size: 0.7rem;
  font-weight: 500;
  margin-bottom: 8px;
`;

export const StyledLabelHeading = styled.label`
  text-transform: uppercase;
`;
