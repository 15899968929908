import gql from 'graphql-tag';

export const ADD_MEMBER_TO_GROUP = gql`
  mutation addMemberToGroup(
    $GroupID: ID!
    $MemberID: ID!
    $OrganisationID: ID!
  ) {
    addMemberToGroup(GroupID: $GroupID, MemberID: $MemberID) {
      ID
      Name
      OrganisationGroups(
        filter: { Organisation: { ID: { eq: $OrganisationID } } }
      ) {
        edges {
          node {
            ID
            Name
          }
        }
      }
    }
  }
`;
