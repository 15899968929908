import CardTooltipTags from '@core/components/DataListCard/components/CardTooltipTags/CardTooltipTags';
import { Card, Image, MockText } from '@virtidev/toolbox';
import styled, { css, DefaultTheme } from 'styled-components';

export const TagContent = styled.div`
  ${({ theme: { spacing } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${spacing.md};
  `}
`;

export const ListContainer = styled.div`
  ${({ theme: { spacing } }) => css`
    overflow: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: ${spacing.sm};
    padding-bottom: ${spacing.lg};
  `}
`;

const rowFragment = css`
  ${
    /** @param {{ theme: DefaultTheme, $type?: 'image' | 'video' }} p */
    ({ theme: { spacing }, $type }) => css`
      display: grid;
      grid-template-columns: ${$type === 'image'
        ? '5fr repeat(4, minmax(10%, 40px))'
        : '5fr repeat(6, minmax(10%, 40px))'};
      gap: ${spacing.sm};
      padding: ${spacing.md};
      align-items: center;
      justify-content: center;
    `
  }
`;

export const HeaderRow = styled.div`
  ${rowFragment}
  ${({ theme: { color, fontSize } }) => css`
    text-transform: uppercase;
    color: ${color.darkGrey};
    font-size: ${fontSize.sm};
  `}
`;

export const Header = styled.div`
  ${
    /** @param {{ $left?: boolean }} p */
    ({ $left }) => css`
      text-align: ${$left ? 'left' : 'center'};
    `
  }
`;

export const MediaRow = styled(Card)`
  ${({ $disabled }) => css`
    .body {
      ${rowFragment}
    }
    ${$disabled &&
    css`
      pointer-events: none;
      filter: grayscale(1);
    `}
  `}
`;

const detailFragment = css`
  ${
    /** @param {{ theme: DefaultTheme, $left?: boolean, $bold?: boolean }} p */
    ({ theme: { spacing, fontWeight }, $left, $bold }) => css`
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: ${spacing.md};
      padding: 0;

      justify-content: ${$left ? 'flex-start' : 'center'};
      font-weight: ${$bold ? fontWeight.partBold : fontWeight.regular};
    `
  }
`;

export const MediaDetail = styled.div`
  ${detailFragment}
`;

export const LoadingTextWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 14em;
  max-width: 100%;
`;

export const MediaLink = styled.button`
  ${detailFragment}
  ${({ theme: { font } }) => css`
    background: none;
    border: none;
    cursor: pointer;
    text-align: left;

    &:hover {
      text-decoration: underline;
    }
  `}
`;

export const MediaThumbnail = styled(Image)`
  ${({ theme: { borderRadius } }) => css`
    height: 4rem;
    width: calc(4rem / 9 * 16);
    border-radius: ${borderRadius.sm};
    margin: 0;
  `}
`;

export const MediaTooltipTags = styled(CardTooltipTags).attrs({ showCount: 0 })`
  ${({ theme }) => css`
    color: ${theme.color.darkGrey};
    background: ${theme.color.contrast.base};
    padding: 0 0.6rem;
  `}
`;

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > :first-child,
  > :last-child {
    border-radius: 0;
  }
`;

export const LoadingText = styled(MockText)`
  margin: 0;
`;
