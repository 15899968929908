import { gql } from '@apollo/client';

export const UPDATE_COURSE_FEEDBACK_FORMS = gql`
  mutation updateCourse($Input: UpdateCourseInput!) {
    updateCourse(input: $Input) {
      ID
      FeedbackFormBefore {
        ID
        Title
      }
      FeedbackFormAfter {
        ID
        Title
      }
    }
  }
`;

export const UPDATE_SIMULATION_FEEDBACK_FORMS = gql`
  mutation updateSimulation($Input: UpdateSimulationInput!) {
    updateSimulation(input: $Input) {
      ID
      FeedbackFormBefore {
        ID
        Title
      }
      FeedbackFormAfter {
        ID
        Title
      }
    }
  }
`;

export const UPDATE_VIRTUAL_HUMAN_FEEDBACK_FORMS = gql`
  mutation updateVirtualHuman($Input: UpdateVirtualHumanInput!) {
    updateVirtualHuman(input: $Input) {
      ID
      FeedbackFormBefore {
        ID
        Title
      }
      FeedbackFormAfter {
        ID
        Title
      }
    }
  }
`;

export const UPDATE_FEEDBACK_FORM_SHARING_OBJECTS = gql`
  mutation updateFeedbackFormSharingObjects(
    $FeedbackFormID: Int!
    $BeforeSimulationIDs: [Int]!
    $AfterSimulationIDs: [Int]!
    $BeforeVirtualHumanIDs: [Int]!
    $AfterVirtualHumanIDs: [Int]!
    $BeforeCourseIDs: [Int]!
    $AfterCourseIDs: [Int]!
  ) {
    updateFeedbackFormSharingObjects(
      FeedbackFormID: $FeedbackFormID
      BeforeSimulationIDs: $BeforeSimulationIDs
      AfterSimulationIDs: $AfterSimulationIDs
      BeforeVirtualHumanIDs: $BeforeVirtualHumanIDs
      AfterVirtualHumanIDs: $AfterVirtualHumanIDs
      BeforeCourseIDs: $BeforeCourseIDs
      AfterCourseIDs: $AfterCourseIDs
    ) {
      ID
      Title
      SimulationsBefore {
        edges {
          node {
            ID
            Title
          }
        }
      }
      SimulationsAfter {
        edges {
          node {
            ID
            Title
          }
        }
      }
      VirtualHumansBefore {
        edges {
          node {
            ID
            Title
          }
        }
      }
      VirtualHumansAfter {
        edges {
          node {
            ID
            Title
          }
        }
      }
      CoursesBefore {
        edges {
          node {
            ID
            Title
          }
        }
      }
      CoursesAfter {
        edges {
          node {
            ID
            Title
          }
        }
      }
    }
  }
`;
