import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getCircle, getGreyCircle } from '../utility/SimLogCircles';

const StyledOption = styled.div`
  display: grid;
  place-items: center start;
  padding: 0.8rem 0rem;
  grid-template-columns: 3fr 1fr;
  gap: 1.5rem;
  line-height: 1.3rem;
`;

const AnswerCircle = (props) => {
  return props.quiz
    ? getCircle(props.selected, props.correct)
    : getGreyCircle(props.selected);
};

const Note = styled.div`
  color: #999999;
  font-size: 0.9rem;
`;

export default function SimLogAnswerDetails(props) {
  const selectedOptions = props.options.reduce((carry, option) => {
    const answerIds = props.answers.map((answer) => {
      return answer.ID;
    });
    if (answerIds.includes(option.ID)) {
      carry.push({ ...option, selected: true });
    } else {
      carry.push({ ...option, selected: false });
    }
    return carry;
  }, []);

  const noSelected = !selectedOptions.find(({ selected }) => selected);

  return (
    <div>
      {noSelected && <Note>No options were selected</Note>}
      {selectedOptions.map((option) => {
        return (
          <StyledOption key={option.ID}>
            <div>{option.Text} </div>
            <AnswerCircle
              correct={option.Correct}
              quiz={props.quiz}
              selected={option.selected}
            />
          </StyledOption>
        );
      })}
    </div>
  );
}

SimLogAnswerDetails.propTypes = {
  answers: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
};
