import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import StyledPage from '../components/StyledPage';
import withUser from '../HOCs/WithUser';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import VirtualHumanManager from '../apps/core/src/components/VirtualHumanManager/VirtualHumanManager';
import { useDeepLink } from '../apps/core/src/components/DeepLink';
import RedirectWithPrevState from '../components/RedirectWithPrevState';

const PDWebPlayerPage = (props) => {
  const history = useHistory();
  const { ssVirtualHumanID, sharetoken } = useParams();
  const { pathname } = useLocation();

  const query = useMemo(() => {
    const search = new URLSearchParams({
      vhID: ssVirtualHumanID,
      content: 'true',
      public: 'true',
      organisationID: 'null',
    });

    return search.toString();
  }, [ssVirtualHumanID]);

  useDeepLink(`${pathname}?${query}`);

  // // track load counts so we can know to reload page to prevent chrome memory issues
  // useEffectOnlyOnce(() => {
  //   if (+sessionStorage.getItem('previousLoadCount') > 0) {
  //     sessionStorage.setItem('previousLoadCount', 0);
  //     window.location.reload();
  //   } else {
  //     sessionStorage.setItem(
  //       'previousLoadCount',
  //       +sessionStorage.getItem('previousLoadCount') + 1
  //     );
  //   }
  // });
  // const { data, loading, error } = useQuery(READ_VIRTUAL_HUMAN, {
  //   variables: {
  //     ID: props.match.params.patientId,
  //     organisationID: props.userOrganisationID,
  //   },
  // });

  const browseToVHList = useCallback(() => {
    history.push('/my-virtual-humans');
  }, [history]);

  if (!props.user.ID) {
    return <RedirectWithPrevState to="/login" />;
  }

  return (
    <StyledPage pageKey="pd-web-player" stretchFullHeight={true}>
      <VirtualHumanManager
        onComplete={browseToVHList}
        virtualHumanId={ssVirtualHumanID}
        sharetoken={sharetoken}
      />
    </StyledPage>
  );
};

PDWebPlayerPage.propTypes = {
  user: PropTypes.shape({
    ID: PropTypes.string.isRequired,
    Name: PropTypes.string.isRequired,
  }).isRequired,
  userOrganisationID: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default withUser(PDWebPlayerPage);
